import { combineReducers } from 'redux';
import gvcmPrtsInq from './axp/gvcmPrtsInq.reducer';
import ARV0101V01 from './arv/ARV0101V01.reducer';
import ARV0101V02 from './arv/ARV0101V02.reducer';
import AnrvTrnPrtsOld from './arv/AnrvTrnPrtsOld.reducer';
import ARV0101V03 from './arv/ARV0101V03.reducer';
import ARV0101V04 from './arv/ARV0101V04.reducer';
import ARV0101V05 from './arv/ARV0101V05.reducer';
import ARV0201V01 from './arv/ARV0201V01.reducer';
import ARV0201P01 from './arv/ARV0201P01.reducer';
import ARV0301V01 from './arv/ARV0301V01.reducer';
import ARV0401P01 from './arv/ARV0401P01.reducer';

import intClsaPrrVrf from './arv/intClsaPrrVrf.reducer';
import gndpIntAts from './arv/gndpIntAts.reducer';
import gndpIntAtsRcvAct from './arv/gndpIntAtsRcvAct.reducer';
import acctPayCmdAm from './ebk/acctPayCmdAm.reducer';
import PmnyAcnoBalAmBankCls from './ebk/PmnyAcnoBalAmBankCls.reducer';
import ExpdCmdTrdnInq from './ebk/ExpdCmdTrdnInq.reducer';
import TrsfCstd from './ebk/TrsfCstd.reducer';
import TsNaPrts from './ebk/TsNaPrts.reducer';
import EbkPrcRst from './ebk/EbkPrcRst.reducer';
import listUserAutMng from './cad/listUserAutMng.reducer';
import canStlMng from './brc/canStlMng.reducer';
import deepMng from './brc/deepMng.reducer';
import BRC0204M01 from './brc/BRC0204M01.reducer';
import combo from './cmm/combo.reducer';
import cadAvpPrts from './cad/cadApvPrts.reducer';
import cadBuyPrts from './cad/cadBuyPrts.reducer';
import cadClamPrts from './cad/cadClamPrts.reducer';
import cadNrqPrts from './cad/cadNrqPrts.reducer';
import zpbApvPrts from './zpb/zpbApvPrts.reducer';
import zpbExuPrts from './zpb/zpbExuPrts.reducer';
import zpbStlPts from './zpb/zpbStlPts.reducer';
import zpbLimBzct from './zpb/zpbLimBzct.reducer';
import ItpyPts from './fxp/ItpyPts.reducer';
import AcctXprPts from './fxp/AcctXprPts.reducer';
import XpradPts from './fxp/XpradPts.reducer';
import MmdaPts from './fxp/MmdaPts.reducer';
import MmdaItpyInq from './fxp/MmdaItpyInq.reducer';
import MmdaTrnPrtsInq from './fxp/MmdaTrnPrtsInq.reducer';
// import report from './cmm/report.reducer';
import GndpPts from './fxp/GndpPts.reducer';
import GndpTrnPrtsInq from './fxp/GndpTrnPrtsInq.reducer';
import FXP0101P01 from './fxp/FXP0101P01.reducer';
import FXP0101V02 from './fxp/FXP0101V02.reducer';
import FXP0101V03 from './fxp/FXP0101V03.reducer';
import FXP0104V01 from './fxp/FXP0104V01.reducer';
import FXP0401V01 from './fxp/FXP0401V01.reducer';
import FXP0402V01 from './fxp/FXP0402V01.reducer';
import FXP0403V01 from './fxp/FXP0403V01.reducer';
import FXP0105V01 from './fxp/FXP0105V01.reducer';
import FXP0501V01 from './fxp/FXP0501V01.reducer';
import chactFdAlocPrts from './axp/chactFdAlocPrts.reducer';
import fdAlocPrts from './axp/fdAlocPrts.reducer';
import AnexAcnoList from './axp/AnexAcnoList.reducer';
import AnexTrnPrtsList from './axp/AnexTrnPrtsList.reducer';
import AnexTrhsList from './axp/AnexTrhsList.reducer';
import AnexItexPayPrts from './axp/AnexItexPayPrts.reducer';
import AnexTrnPrtsListOld from './axp/AnexTrnPrtsListOld.reducer';
import AnexEcadIstActbal from './axp/AnexEcadIstActbal.reducer';
import AXP0101V04 from './axp/AXP0101V04.reducer';
import AXP0301V01 from './axp/AXP0301V01.reducer';
import AXP0401V02 from './axp/AXP0401V02.reducer';
import cadStlPrts from './cad/cadStlPrts.reducer';
import cadDfrPrts from './cad/cadDfrPrts.reducer';
import deptCpcadHis from './cad/deptCpcadHis.reducer';
import deptMng from './cad/deptMng.reducer';
import deptUsgPts from './cad/deptUsgPts.reducer';
import loginState from './cmm/login.reducer';
import cpcadIsuLdg from './cad/cpcadIsuLdg.reducer';
import scbhWeakCopPurPrts from './cad/scbhWeakCopPurPrts.reducer';
import weakCopRgs from './cad/weakCopRgs.reducer';
import cmmFileList from './cmm/file.reducer';
import EvdXpnAcnoList from './ils/EvdXpnAcnoList.reducer';
import EvdXpnTrnPrtsList from './ils/EvdXpnTrnPrtsList.reducer';
import RtrnAmRcvTrhs from './ils/RtrnAmRcvTrhs.reducer';
import EvdXpnImpExpdMmSht from './ils/EvdXpnImpExpdMmSht.reducer';
import EvdXpnTrhsList from './ils/EvdXpnTrhsList.reducer';
import ILS0301V01 from './ils/ILS0301V01.reducer';
import brcTdyCan from './brc/brcTdyCan.reducer';
import IstDeptCdMng from './sys/IstDeptCdMng.reducer';
import DeptGenActNo from './sys/DeptGenActNo.reducer';
import OfislMng from './sys/OfislMng.reducer';
import NtMng from './sys/NtMng.reducer';
import SYS0107M01 from './sys/SYS0107M01.reducer';
import zpbIsuMng from './zpb/zpbIsuRgr.reducer';
import cmmCdMng from './sys/cmmCdMng.reducer';
import notTdyCan from './brc/notTdyCan.reducer';
import cadStlActtrnPrts from './cad/cadStlActtrnPrts.reducer';
import etcAsstAtcntPts from './etc/etcAsstAtcntPts.reducer';
import etcAsstBznAccnBgtBssnRt from './etc/etcAsstBznAccnBgtBssnRt.reducer';
import etcAsstPrbtAnls from './etc/etcAsstPrbtAnls.reducer';
import siteMng from './sys/siteMng.reducer';
import GenDmdActNoMng from './sys/GenDmdActNoMng.reducer';
import cmmCdMngDetail from './sys/cmmCdMngDetail.reducer';
import abrvMm from './rpt/abrvMm.reducer';
import RPT0201V01 from './rpt/RPT0201V01.reducer';
import RPT0202V01 from './rpt/RPT0202V01.reducer';
import RPT0206V01 from './rpt/RPT0206V01.reducer';
import RPT0602V01 from './rpt/RPT0602V01.reducer';
import RPT0301V01 from './rpt/RPT0301V01.reducer';
import RPT0603V01 from './rpt/RPT0603V01.reducer';
import owprDlrpMnrr from './rpt/owprDlrpMnrr.reducer';
import zpbApvPrtsDetail from './zpb/zpbApvPrtsDetail.reducer';
import systemMng from './sys/systemMng.reducer';
import etcAsstPrbtTndc from './etc/etcAsstPrbtTndc.reducer';
import ARV0601V01 from './arv/ARV0601V01.reducer';
import etcPmnyActSumts from './etc/etcPmnyActSumts.reducer';
import ETC0201V01 from './etc/ETC0201V01.reducer';
import ETC0401V01 from './etc/ETC0401V01.reducer';
import ETC0502V01 from './etc/ETC0502V01.reducer';
import bnfCoDtenList from './brc/bnfCoDtenList.reducer';
import brc02070V03List from './brc/brc02070V03List.reducer';
import etcSumrPrts from './etc/etcSumrPrts.reducer';
import userDelLog from './sys/userDelLog.reducer';
import zpbTrnFmItStlPts from './zpb/zpbTrnFmItStlPts.reducer';
import zpbUserXuseAppLginHst from './zpb/zpbUserXuseAppLginHst.reducer';
import fdOpgHbokBkg from './rpt/fdOpgHbokBkg.reducer';
import anrvAnexCashFdDay from './rpt/anrvAnexCashFdDay.reducer';
import etcPayNtcd from './etc/etcPayNtcd.reducer';
import etcPrcPrts from './etc/etcPrcPrts.reducer';
import etcFdSumrPrtsa from './etc/etcFdSumrPrtsa.reducer';
import SYS0109M01 from './sys/SYS0109M01.reducer';
import brcSalaryTrnsEachIst from './brc/brcSalaryTrnsEachIst.reducer';
import brcSalaryTrnsIst from './brc/brcSalaryTrnsIst.reducer';
import SYS0110M01 from './sys/SYS0110M01.reducer';
import brcIstInfo from './brc/brcIstInfo.reducer';
import SYS0102M01 from './sys/SYS0102M01.reducer';
import brcSalaryTrnsParams from './brc/brcSalaryTrnsParams.reducer';
import etcPrcPrtsRcncSum from './etc/etc0405m01.reducer';
import etcFdtsPrts from './etc/etc0101m01.reducer';
import appvPrg from './rpt/appvPrg.reducer';
import RPT0402M01 from './rpt/RPT0402M01.reducer';
import zpbKataSmsTmsPrst from './zpb/zpbKataSmsTmsPrst.reducer';
import crossSellList from './brc/crossSellList.reducer';
import SYS0111M01 from './sys/SYS0111M01.reducer';
import SYS0108M01 from './sys/SYS0108M01.reducer';
import SYS0203V01 from './sys/SYS0203V01.reducer';
import SYS0206M01 from './sys/SYS0206M01.reducer';
import brcProposal from './brc/brc0210v01.reducer';
import RPT0102V01 from './rpt/RPT0102V01.reducer';
import SYS0204M01 from './sys/SYS0204M01.reducer';
import ARV0401V01 from './arv/ARV0401V01.reducer';
import BRC0209V01 from './brc/BRC0209V01.reducer';
import zpb0108m01 from './zpb/zpb0108m01.reducer';
import SYS0304M01 from './sys/SYS0304M01.reducer';
import zpb0201v01 from './zpb/zpb0201v01.reducer';
import SYS0209V01 from './sys/SYS0209V01.reducer';
import program from './cmm/program.reducer';
import RPT0403V01 from './rpt/RPT0403V01.reducer';
import RPT0204V01 from './rpt/RPT0204V01.reducer';
import { comboReducerList } from './cmm/combo2.reducer';
import zpb0207m01 from './zpb/zpb0207m01.reducer';
import SYS0306M01 from './sys/SYS0306M01.reducer';
import etc0501v01 from './etc/etc0501v01.reducer';
import SYS0307M01 from './sys/SYS0307M01.reducer';
import SYS0311M01 from './sys/SYS0311M01.reducer';
import SYS0311P01 from './sys/SYS0311P01.reducer';
import SYS0309M01 from './sys/SYS0309M01.reducer';
import SYS0310M01 from './sys/SYS0310M01.reducer';
import CallMsgAdd from './sys/CallMsgAdd.reducer';
import MenuHelp from './sys/MenuHelp.reducer';

import zpb0202v01 from './zpb/zpb0202v01.reducer';
import zpb0203v01 from './zpb/zpb0203v01.reducer';
import etc0501m01 from './etc/etc0501m01.reducer';
import RPT0601V01 from './rpt/RPT0601V01.reducer';
import mainState from './cmm/main.reducer';
import bookmarkState from './cmm/bookmark.reducer';
import etc0501m01Combo from './etc/etc0501m01Combo.reducer';
import FDS0101V01 from './fds/FDS0101V01.reducer';
import FDS0103V01 from './fds/FDS0103V01.reducer';
import FDS0104V01 from './fds/FDS0104V01.reducer';
import MainFDS from './fds/MainFDS.reducer';

import noticeState from './cmm/notice.reducer';
import zpbStlActTrnPrts from './zpb/zpbStlActTrnPrts.reducer';
import workState from './cmm/work.reducer';
import signupState from './cmm/signup.reducer';
import authNumberState from './cmm/authNumber.reducer';
import findPasswordState from './cmm/findPassword.reducer';
import etc0403v01 from './etc/etc0403v01.reducer';
import zpb0206v01 from './zpb/zpb0206v01.reducer';
import FDS0102M01 from './fds/FDS0102M01.reducer';
import RPT0104V01 from './rpt/RPT0104V01.reducer';
import RPT0105V01 from './rpt/RPT0105V01.reducer';
import findIdState from './cmm/findId.reducer';
import SYS0312M01 from './sys/SYS0312M01.reducer';
import menuState from './cmm/menu.reducer';
import RPT0401M01 from './rpt/RPT0401M01.reducer';
import changeDeptState from './cmm/changeDept.reducer';
import SYS0308M01 from './sys/SYS0308M01.reducer';
import SYS0205M01 from './sys/SYS0205M01.reducer';
import SYS0208V01 from './sys/SYS0208V01.reducer';
import SYS0201M01 from './sys/SYS0201M01.reducer';
import personState from './cmm/person.reducer';
import SYS0207M01 from './sys/SYS0207M01.reducer';
import menuMngState from './sys/MenuMng.reducer';
import zpb0102v01 from './zpb/zpb0102v01.reducer';
//import zpbPrtsCombo from './zpb/zpbPrtsCombo.reducer';

const rootReducer = combineReducers({
  loginState,
  mainState,
  bookmarkState,
  noticeState,
  MainFDS,
  workState,
  signupState,
  authNumberState,
  findPasswordState,
  findIdState,
  menuState,
  changeDeptState,
  personState,

  /* 세입 */
  ARV0101V01 /* 0001_세입계좌조회(다건) */,
  ARV0101V02 /* 0001_세입거래내역 */,
  ARV0101V03 /* 0001_세입거래명세 */,
  ARV0101V04 /* 0001_구좌경정/과오납 */,
  ARV0101V05 /* 0001_입출금집계 */,
  intClsaPrrVrf /* 0007_이자지급결산 사전검증 */,
  gndpIntAts /* 0008_보통예금 이자 자동이체 조회 */,
  gndpIntAtsRcvAct /* 0008_보통예금 이자 입금계좌 조회 */,
  AnrvTrnPrtsOld /* 0001_세입거래내역장조회(과거) */,
  ARV0601V01 /* 공금예금 이자 조회 */,
  ARV0201V01 /* 세입이체일정표 */,
  ARV0201P01 /* 세입이체일정표 상세 */,
  ARV0301V01 /* 보조금 교부내역 조회*/,
  ARV0401V01 /* 공금연동 보통예금 계좌조회 */,
  ARV0401P01 /* 공금연동 보통예금 계좌조회 팝업*/,

  /* 세출 */
  fdAlocPrts,
  chactFdAlocPrts,
  AnexAcnoList /* 0001_세출계좌현황 */,
  AnexTrnPrtsList /* 0001_세출거래내역장 */,
  AnexTrhsList /* 0001_세출거래명세 */,
  AnexItexPayPrts /* 0001_비목별 지급내역 */,
  AXP0301V01 /* 0003_반납(여입) 거래명세 */,
  AnexEcadIstActbal /*004 회계별 전기관 잔액 */,
  AXP0401V02 /*004 회계별 지급현황 */,
  gvcmPrtsInq /*005 정부보관금내역 조회 */,
  AnexTrnPrtsListOld /* 0006_세출거래내역장(과거) */,
  AXP0101V04 /**자금현황표 */,

  /* 일상경비 */
  EvdXpnAcnoList /* 0001_일상경비 계좌현황 */,
  EvdXpnTrnPrtsList /*0001_일상경비 거래내역장 */,
  EvdXpnTrhsList /*0001_일상경비 거래명세 */,
  RtrnAmRcvTrhs /*0001_일상경비 반납금입금 거래명세 */,
  EvdXpnImpExpdMmSht /*0002_일상경비 수입/지출월계표 및 잔액증명서 */,
  ILS0301V01 /*0003_보통예금계좌 지급요청 공문 */,

  /* e뱅킹 */
  EbkPrcRst /* 0001_처리결과 조회 */,
  TsNaPrts /* 0001_이체불능구 조회 */,
  TrsfCstd /* 0001_대체보관구 내역조회 */,
  ExpdCmdTrdnInq /* 0001_지출명령 반려조회*/,
  PmnyAcnoBalAmBankCls /* 0002_공급잔액(한도) 및 은행마감시간 조회 */,
  acctPayCmdAm /* 0003_회계별 지급명령금액 총괄 조회 */,

  /* 전자보고서 */
  abrvMm,
  appvPrg,
  owprDlrpMnrr,
  fdOpgHbokBkg /* 자금운용내역장 */,
  anrvAnexCashFdDay /* 세입세출외현금자금일계표 */,
  RPT0402M01,
  RPT0602V01 /* 회계별 세출자금수지현황 */,
  RPT0102V01,
  RPT0403V01 /* 전자보고서 지점별 관리 */,
  RPT0204V01 /* 세출 일,월,분기계표 */,
  RPT0201V01 /* 세입세출자금일계표(세출,기금)*/,
  RPT0202V01 /* 계좌별 세입세출자금일계표 */,
  RPT0206V01 /* 회계별자금배정 및 지출현황*/,
  RPT0301V01 /* 일상경비 일,월,분기계표 */,
  RPT0601V01,
  RPT0603V01 /* 공금성예금 평잔현황 */,
  RPT0104V01,
  RPT0105V01,
  RPT0401M01 /* 보고서 관리대장 */,

  /* 운용상품 */
  ItpyPts /* 이자지급현황 */,
  AcctXprPts /* 회계별 만기현황 */,
  XpradPts /* 만기도래현황 */,
  GndpPts /* 보통예금 조회 */,
  GndpTrnPrtsInq /* 보통예금 상세내역 조회 */,
  MmdaPts /*MMDA 현황 조회 */,
  MmdaItpyInq /* MMDA 이자지급 조회 */,
  MmdaTrnPrtsInq /* MMDA 거래내역 조회 */,
  FXP0101P01 /* 공금성 정기예금 현황 */,
  FXP0101V02 /* 전체명세 */,
  FXP0101V03 /* 해지예상 조회 */,
  FXP0401V01 /* 대사현황 모니터링 조회*/,
  FXP0402V01 /* 영업점별 대사현황*/,
  FXP0403V01 /* 제로페이 승인, 정산대사 현황 */,
  FXP0104V01 /* 정기예금현황 보고서*/,
  FXP0105V01 /* 정기예금 신규해지 명세 */,
  FXP0501V01 /* 공기업 정기예금 신규해지 명세 */,

  /* 제로페이 조회*/
  zpbApvPrts,
  zpbApvPrtsDetail,
  zpbExuPrts,
  zpbStlPts,
  zpbLimBzct,
  zpbIsuMng,
  zpbTrnFmItStlPts,
  zpb0108m01,
  zpb0102v01,
  //zpbPrtsCombo,

  /* 제로페이(관리자)*/
  zpbUserXuseAppLginHst,
  zpbKataSmsTmsPrst,
  zpb0201v01,
  zpb0207m01,
  zpb0202v01,
  zpb0203v01,
  zpbStlActTrnPrts,
  zpb0206v01,

  /* 법인카드 */
  listUserAutMng /* 0004_사용자권한 관리(사용자권한 수정) */,
  cadAvpPrts,
  cadBuyPrts,
  cadClamPrts,
  cadNrqPrts,
  cadStlPrts,
  cadDfrPrts,
  deptCpcadHis,
  deptMng,
  deptUsgPts,
  cpcadIsuLdg,
  scbhWeakCopPurPrts,
  weakCopRgs,
  cadStlActtrnPrts,
  /* 금고영업점 */
  brcTdyCan,
  notTdyCan,
  canStlMng,
  deepMng,
  bnfCoDtenList,
  brc02070V03List,
  BRC0209V01,
  brcIstInfo,
  brcSalaryTrnsParams,
  crossSellList,
  brcProposal,
  BRC0204M01,

  /* 기타 */
  etcPayNtcd,
  etcAsstAtcntPts,
  etcAsstBznAccnBgtBssnRt,
  etcAsstPrbtAnls,
  etcAsstPrbtTndc,
  etcPmnyActSumts,
  ETC0401V01,
  etcSumrPrts,
  etcPrcPrts,
  etcFdSumrPrtsa,
  etcPrcPrtsRcncSum,
  etcFdtsPrts,
  ETC0201V01,
  etc0501v01,
  etc0501m01,
  etc0501m01Combo,
  etc0403v01,
  ETC0502V01 /*마스킹관리*/,

  /* 사용자 */
  userDelLog,
  SYS0203V01,
  SYS0204M01,
  SYS0205M01,
  SYS0206M01,
  SYS0209V01,
  SYS0208V01,
  SYS0207M01,

  /* 시스템관리 */
  IstDeptCdMng,
  SYS0102M01,
  GenDmdActNoMng,
  DeptGenActNo,
  OfislMng,
  NtMng,
  SYS0111M01,
  SYS0107M01,
  SYS0108M01,
  SYS0109M01,
  SYS0110M01,
  cmmCdMng,
  cmmCdMngDetail,
  siteMng,
  systemMng,
  SYS0304M01,
  SYS0306M01,
  SYS0307M01,
  SYS0311M01,
  SYS0311P01,
  SYS0309M01,
  SYS0310M01,
  SYS0312M01,
  SYS0308M01,
  SYS0201M01,
  menuMngState,
  CallMsgAdd,
  MenuHelp,

  /* 공통 */
  combo,
  cmmFileList,
  program,
  ...comboReducerList(),
  // report,
  brcSalaryTrnsEachIst,
  brcSalaryTrnsIst,
  /* FDS */
  FDS0101V01,
  FDS0102M01,
  FDS0103V01,
  FDS0104V01,
});

export default rootReducer;
