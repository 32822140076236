import instance from '../../common/config/api';

/**
 * 기타 - 보조금 수익성 추세
 * @param {object} params
 * @returns
 */

export const fetchEtcAsstPrbtTndcList = (params) =>
  instance.post(`${process.env.REACT_APP_SERVER_URL}/scq/scq/etc/0003/selectEtcAsstPrbtTndc`, params);
