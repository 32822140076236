import dayjs from 'dayjs';
import { SUCCESS, REQUEST, FAILURE } from '../../../common/utils/action-type-utils';
import {
  fetchDeepMngListByAllBranch,
  fetchDeepMngListByBranch,
  updateDeepMngByAprvInit,
  updateDeepMngByConfirm,
  updateDeepMngBySave,
  fetchBbzdtInf,
} from '../../../lib/brc/deepMng.api';

// actions
const FETCH_DEEP_MNG_LIST = 'deepMng/FETCH_DEEP_MNG_LIST';
const FETCH_BBZDT_INF = 'deepMng/FETCH_BBZDT_INF';
const UPDATE_DEEP_MNG = 'deepMng/UPDATE_DEEP_MNG';
const CONFIRM_DEEP_MNG = 'deepMng/CONFIRM_DEEP_MNG';
const INIT_APRV_DEEP_MNG = 'deepMng/INIT_APRV_DEEP_MNG';
const SAVE_SEARCH_FORM_VALUES = 'deepMng/SAVE_SEARCH_FORM_VALUES';
const RESET_TDY_CAN_STATE = 'deepMng/RESET_TDY_CAN_STATE';

export const initFormValues = {
  sdt: dayjs().format('YYYY-MM-DD'),
  edt: dayjs().format('YYYY-MM-DD'),
};

// initial state
const initialState = {
  loading: false,
  updating: false,
  success: false,
  updateSuccess: false,
  inqDtSuccess: false,
  inqDtLoading: false,
  message: '',
  list: [],
  // dto: [],
  totalCount: 0,
  // 검색조건
  searchFormValues: {
    init: true,
    ...initFormValues,
  },
};

// reducers
export default function deepMng(state = initialState, action) {
  switch (action.type) {
    case SUCCESS(FETCH_DEEP_MNG_LIST): // 조회 성공
      return {
        ...state,
        success: true,
        loading: false,
        list: action.payload.data?.main,
        totalCount: action.payload?.main_cnt,
      };
    case REQUEST(FETCH_DEEP_MNG_LIST): // 조회 요청
      return {
        ...state,
        success: true,
        loading: true,
      };
    case FAILURE(FETCH_DEEP_MNG_LIST): // 조회실패
      return {
        ...initialState,
        loading: false,
        success: false,
        message: action.payload.response?.data?.message || action.payload.message,
      };
    case SUCCESS(UPDATE_DEEP_MNG):
    case SUCCESS(CONFIRM_DEEP_MNG):
    case SUCCESS(INIT_APRV_DEEP_MNG): // 성공
      return {
        ...state,
        updateSuccess: true,
        updating: false,
      };
    case REQUEST(UPDATE_DEEP_MNG):
    case REQUEST(CONFIRM_DEEP_MNG):
    case REQUEST(INIT_APRV_DEEP_MNG): // 요청
      return {
        ...state,
        updateSuccess: false,
        message: null,
        updating: true,
      };
    case FAILURE(UPDATE_DEEP_MNG):
    case FAILURE(CONFIRM_DEEP_MNG):
    case FAILURE(INIT_APRV_DEEP_MNG): // 실패
      return {
        ...initialState,
        updating: false,
        updateSuccess: false,
        message: action.payload.response?.data?.message,
      };
    case SAVE_SEARCH_FORM_VALUES:
      return {
        ...state,
        loading: false,
        updating: false,
        success: false,
        updateSuccess: false,
        message: '',
        searchFormValues: action.payload,
      };
    case RESET_TDY_CAN_STATE:
      return initialState;
    case SUCCESS(FETCH_BBZDT_INF): // 조회 성공
      console.log(action.payload);
      return {
        ...state,
        inqDtSuccess: true,
        inqDtLoading: false,
        sdt: String(action.payload?.data),
        edt: String(action.payload?.data),
      };
    case REQUEST(FETCH_BBZDT_INF): // 조회 요청
      return {
        ...state,
        inqDtLoading: true,
      };
    case FAILURE(FETCH_BBZDT_INF): // 조회실패
      return {
        ...state,
        sdt: dayjs().format('YYYY-MM-DD'),
        edt: dayjs().format('YYYY-MM-DD'),
        inqDtLoading: false,
        inqDtSuccess: false,
      };
    default:
      return state;
  }
}

/**
 * 금고영업점 > 공채 > 증서관리 (전지점)
 */
export const selectDeepMngByAllBranch = (searchParams) => {
  return {
    type: FETCH_DEEP_MNG_LIST,
    payload: fetchDeepMngListByAllBranch(searchParams),
  };
};

/**
 * 금고영업점 > 공채 > 증서관리 (지점별)
 */
export const selectDeepMngByBranch = (searchParams) => {
  return {
    type: FETCH_DEEP_MNG_LIST,
    payload: fetchDeepMngListByBranch(searchParams),
  };
};

/**
 * 금고영업점 > 공채 > 증서관리 저장
 */
export const updateDeepMng = (dto) => {
  return {
    type: UPDATE_DEEP_MNG,
    payload: updateDeepMngBySave(dto),
  };
};

/**
 * 금고영업점 > 공채 > 증서관리 구금고 책임자 확인
 */
export const confirmDeepMng = (dto) => {
  return {
    type: CONFIRM_DEEP_MNG,
    payload: updateDeepMngByConfirm(dto),
  };
};

/**
 * 금고영업점 > 공채 > 증서관리 구금고 승인초기화
 */
export const aprvInitDeepMng = (dto) => {
  return {
    type: INIT_APRV_DEEP_MNG,
    payload: updateDeepMngByAprvInit(dto),
  };
};

/**
 * 조회조건 저장
 */
export const saveSearchFormValues = (searchFormValues) => {
  return {
    type: SAVE_SEARCH_FORM_VALUES,
    payload: {
      ...searchFormValues,
      init: false,
    },
  };
};

/**
 * 화면 초기화
 */
export const resetState = () => {
  return {
    type: RESET_TDY_CAN_STATE,
  };
};

/**
 * 전 영업일 조회
 */
export const selectBbzdtInf = (searchParams) => {
  return {
    type: FETCH_BBZDT_INF,
    payload: fetchBbzdtInf(searchParams),
  };
};
