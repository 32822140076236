import {
  fetchCheckPwd,
  fetchAppMaskList,
  fetchApvMaskList,
  fetchAppMaskInfo,
  fetchApvMasInfo,
  fetchDeptUser,
} from '../../../lib/etc/ETC0006.api';
import { SUCCESS, REQUEST, FAILURE } from '../../../common/utils/action-type-utils';

// actions
const CHECK_PWD = 'ETC0502V01/CHECK_PWD';
const FETCH_APP_MASK_LIST = 'ETC0502V01/FETCH_APP_MASK_LIST';
const FETCH_APV_MASK_LIST = 'ETC0502V01/FETCH_APV_MASK_LIST';
const INSERT_APP_MASK_INFO = 'ETC0502V01/INSERT_APP_MASK_INFO';
const UPDATE_APV_MAS_INFO = 'ETC0502V01/UPDATE_APV_MAS_INFO';
const FETCH_DEPT_USER = 'ETC0502V01/FETCH_DEPT_USER';
const CLEAR_MESSAGE = 'ETC0502V01/CLEAR_MESSAGE';

// initial state
const initialState = {
  loading: false,
  success: false,
  message: '',
  list: [],
  totalCount: 0,
  resultCnt: 0,
  updateSuccess: false,
  updateLoading: false,
  updateMessage: '',
  checkLoading: false,
  checkSuccess: false,
  checkMessage: '',
  isValid: false,
  deptUserList: [],
  deptUserLoading: false,
  deptUserSuccess: false,
  deptUserMessage: '',
};

// reducers
export default function AnexTrhsList(state = initialState, action) {
  switch (action.type) {
    case SUCCESS(CHECK_PWD): // 조회 성공
      return {
        ...state,
        checkSuccess: true,
        checkLoading: false,
        isValid: action.payload?.data,
        resultCnt: 0,
      };
    case REQUEST(CHECK_PWD): // 조회 요청
      return {
        ...state,
        checkMessage: '',
        checkLoading: true,
      };
    case FAILURE(CHECK_PWD): // 조회실패
      return {
        ...state,
        checkLoading: false,
        checkSuccess: false,
        checkMessage: action.payload.response?.data?.message,
      };
    case SUCCESS(FETCH_APP_MASK_LIST): // 조회 성공
    case SUCCESS(FETCH_APV_MASK_LIST):
      return {
        ...state,
        success: true,
        loading: false,
        list: action.payload.data?.main,
        totalCount: action.payload?.main_cnt,
      };
    case REQUEST(FETCH_APP_MASK_LIST): // 조회 요청
    case REQUEST(FETCH_APV_MASK_LIST): // 조회 요청
      return {
        ...state,
        message: '',
        loading: true,
      };
    case FAILURE(FETCH_APP_MASK_LIST): // 조회실패
    case FAILURE(FETCH_APV_MASK_LIST): // 조회실패
      return {
        ...state,
        loading: false,
        success: false,
        message: action.payload.response?.data?.message,
      };
    case SUCCESS(INSERT_APP_MASK_INFO): // 갱신 성공
    case SUCCESS(UPDATE_APV_MAS_INFO): // 갱신 성공
      return {
        ...state,
        updateSuccess: true,
        updateLoading: false,
        resultCnt2: action.payload.data.result_cnt,
      };
    case REQUEST(INSERT_APP_MASK_INFO): // 갱신 요청
    case REQUEST(UPDATE_APV_MAS_INFO): // 갱신 요청
      return {
        ...state,
        updateLoading: true,
        updateMessage: '',
        resultCnt2: 0,
        updateSuccess: false,
      };
    case FAILURE(INSERT_APP_MASK_INFO): // 갱신 실패
    case FAILURE(UPDATE_APV_MAS_INFO): // 갱신 실패
      return {
        ...initialState,
        updateLoading: false,
        updateSuccess: false,
        resultCnt2: -1,
        updateMessage: action.payload.response?.data?.message,
      };
    case SUCCESS(FETCH_DEPT_USER): // 조회 성공
      return {
        ...state,
        deptUserSuccess: true,
        deptUserLoading: false,
        deptUserList: action.payload.data?.main,
      };
    case REQUEST(FETCH_DEPT_USER): // 조회 요청
      return {
        ...state,
        deptUserMessage: '',
        deptUserLoading: true,
      };
    case FAILURE(FETCH_DEPT_USER): // 조회실패
      return {
        ...state,
        deptUserLoading: false,
        deptUserSuccess: false,
        deptUserMessage: action.payload.response?.data?.message,
      };
    case CLEAR_MESSAGE:
      return {
        ...state,
        deptUserSuccess: false,
        deptUserMessage: '',
        updateSuccess: false,
        updateMessage: '',
        checkSuccess: false,
        checkMessage: '',
        success: false,
        message: '',
      };
    default:
      return state;
  }
}

/**
 * 비밀번호 확인
 */
export const checkPwd = (searchParams) => {
  return {
    type: CHECK_PWD,
    payload: fetchCheckPwd(searchParams),
  };
};

/**
 * 마스킹 해지 신청 목록 조회
 */
export const selectAppMaskList = (searchParams) => {
  return {
    type: FETCH_APP_MASK_LIST,
    payload: fetchAppMaskList(searchParams),
  };
};

/**
 * 마스킹 해지 신청 목록 조회(관리자)
 */
export const selectApvMaskList = (searchParams) => {
  return {
    type: FETCH_APV_MASK_LIST,
    payload: fetchApvMaskList(searchParams),
  };
};

/**
 * 마스크 해지신청 등록
 */
export const insertAppMaskInfo = (searchParams) => {
  return {
    type: INSERT_APP_MASK_INFO,
    payload: fetchAppMaskInfo(searchParams),
  };
};

/**
 * 마스크 해지 신청 승인/반려 처리
 */
export const updateApvMasInfo = (searchParams) => {
  return {
    type: UPDATE_APV_MAS_INFO,
    payload: fetchApvMasInfo(searchParams),
  };
};

/**
 * 결재자목록 조회
 */
export const selectDeptUser = (searchParams) => {
  return {
    type: FETCH_DEPT_USER,
    payload: fetchDeptUser(searchParams),
  };
};

export const clearMessage = () => {
  return {
    type: CLEAR_MESSAGE,
  };
};
