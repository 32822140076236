import { fetchDeptGenActNo, insertDeptGenActNo } from '../../../lib/sys/DeptGenActNo.api';
import { SUCCESS, REQUEST, FAILURE } from '../../../common/utils/action-type-utils';
import { fetchCommonComboList } from '../../../lib/cmm/combo.api';

// actions
const FETCH_DEPT_GEN_ACT_NO = 'DeptGenActNo/FETCH_DEPT_GEN_ACT_NO';
const INSERT_DEPT_GEN_ACT_NO = 'DeptGenActNo/INSERT_DEPT_GEN_ACT_NO';
const SAVE_SEARCH_FORM_VALUES = 'DeptGenActNo/SAVE_SEARCH_FORM_VALUES';
const RESET_STATE = 'DeptGenActNo/RESET_STATE';
const FETCH_ACT_TP_LIST = 'DeptGenActNo/FETCH_ACT_TP_LIST';

export const initFormValues = {
  cus_ist_cd_search: 'all', //기관명
  act_no: '', //계좌번호
  actno_use_dscd: 'all', //계좌사용구분
  act_tp: 'all',
};
// initial state
const initialState = {
  loading: false,
  success: false,
  message: '',
  list: [],
  details: [],
  totalCount: 0,
  updateSuccess: false,
  resultCnt: -1,
  //콤보-계좌타입
  actTpLoading: false,
  actTpSuccess: false,
  actTpList: [],
  searchFormValues: {
    init: true,
    ...initFormValues,
  },
};

// reducers
export default function DeptGenActNo(state = initialState, action) {
  switch (action.type) {
    case SUCCESS(FETCH_DEPT_GEN_ACT_NO): // 조회 성공
      return {
        ...state,
        success: true,
        loading: false,
        list: action.payload.data?.main,
        totalCount: action.payload?.main_cnt,
        updateSuccess: false,
      };
    case REQUEST(FETCH_DEPT_GEN_ACT_NO): // 조회 요청
      return {
        ...state,
        loading: true,
        updateSuccess: false,
      };
    case FAILURE(FETCH_DEPT_GEN_ACT_NO): // 조회실패
      return {
        ...state,
        list: [],
        loading: false,
        success: false,
        message: action.payload.response?.data?.message,
        updateSuccess: false,
      };
    case SUCCESS(INSERT_DEPT_GEN_ACT_NO): // 갱신 성공
      return {
        ...state,
        updateSuccess: true,
        loading: false,
        resultCnt: action.payload.data.resultCnt,
      };
    case REQUEST(INSERT_DEPT_GEN_ACT_NO): // 갱신 요청
      return {
        ...state,
        loading: true,
        updateSuccess: false,
      };
    case FAILURE(INSERT_DEPT_GEN_ACT_NO): // 갱신 실패
      return {
        ...state,
        loading: false,
        updateSuccess: false,
        resultCnt: -1,
        message: action.payload.response?.data?.message,
      };
    //계좌타입
    case SUCCESS(FETCH_ACT_TP_LIST): // 조회 성공
      return {
        ...state,
        actTpSuccess: true,
        actTpLoading: false,
        actTpList: action.payload.data?.subDto,
      };
    case REQUEST(FETCH_ACT_TP_LIST): // 조회 요청
      return {
        ...state,
        actTpLoading: true,
        actTpSuccess: false,
      };
    case FAILURE(FETCH_ACT_TP_LIST): // 조회실패
      return {
        ...state,
        actTpLoading: false,
        actTpSuccess: false,
        actTpList: [],
      };
    case SAVE_SEARCH_FORM_VALUES:
      return {
        ...state,
        searchFormValues: action.payload,
      };
    case RESET_STATE:
      return initialState;
    default:
      return { ...state, updateSuccess: false };
  }
}

/**
 *  일반계좌 조회
 */
export const selectDeptGenActNo = (searchParams) => {
  return {
    type: FETCH_DEPT_GEN_ACT_NO,
    payload: fetchDeptGenActNo(searchParams),
  };
};

/**
 * 일반계좌 저장
 */
export const updateDeptGenActNo = (dtos) => {
  return {
    type: INSERT_DEPT_GEN_ACT_NO,
    payload: insertDeptGenActNo(dtos),
  };
};

/**
 * 조회조건 저장
 */
export const saveSearchFormValues = (searchFormValues) => {
  return {
    type: SAVE_SEARCH_FORM_VALUES,
    payload: {
      ...searchFormValues,
      init: false,
    },
  };
};

//계좌타입 콤보 조회
export const selectActTpList = () => {
  return {
    type: FETCH_ACT_TP_LIST,
    payload: fetchCommonComboList({
      slt_box_no: '003',
      sys_cd: 'CC',
      cd_grp_nm: '계좌타입',
    }),
  };
};

/**
 * 상태초기화
 */
export const resetState = () => {
  return {
    type: RESET_STATE,
  };
};
