import { css, styled } from 'styled-components';
import WOToggleButton from '../common/WOToggleButton';
import { menuDatas } from '../../data/menuDatas';
import { Link, useNavigate } from 'react-router-dom';
import React, { memo, useEffect, useRef, useState } from 'react';
import WOIcon from '../common/WOIcon';
import WOIconButton from '../common/WOIconButton';
import useSession from '../../hooks/useSession';
import { useDispatch, useSelector } from 'react-redux';
import { resetBizFavtMsg, saveBizFavt, selectBizFavt } from '../../redux/modules/cmm/bookmark.reducer';
import useAlert from '../../hooks/useAlert';

const MenuList = ({ isOpen, isClose }) => {
  const session = useSession().getUserInfo();
  const dispatch = useDispatch();
  const { alert } = useAlert();
  const navigate = useNavigate();
  const [searchTerm, setSearchTerm] = useState(null); // 검색 값
  const [inputValue, setInputValue] = useState(''); // 검색 input 입력 값
  const [searchResultsCount, setSearchResultsCount] = useState(0); // 검색 결과 개수 상태
  const titleRefs = useRef({}); // title 참초
  const [activeAnchor, setActiveAnchor] = useState(null); // 활성화된 앵커
  const { loading: loadinbByMenu, success: successByMenu, list: menuList } = useSelector((state) => state.menuState);
  const {
    totalCount: totalCountByBookmark,
    updating,
    message,
    updatingSuccess,
    list: bookmarkList,
  } = useSelector((state) => state.bookmarkState);

  // 전체 스크롤 방지
  useEffect(() => {
    if (isOpen) {
      // 메뉴가 열리면 body에 스크롤 막는 클래스 추가
      document.body.classList.add('scroll-fix');
    } else {
      // 메뉴가 닫히면 클래스 제거
      document.body.classList.remove('scroll-fix');
    }
  }, [isOpen]);

  // 스크롤 위치에 따른 앵커 활성화 로직
  useEffect(() => {
    const menuWrapper = document.querySelector('.menu-wrapper');

    // 스크롤 이벤트
    const handleScroll = () => {
      // 화면 상단으로부터 현재 스크롤 위치
      const scrollY = menuWrapper.scrollTop;

      // 메뉴 데이터를 순회하며 화면에 보이는 메뉴 항목 찾기
      menuDatas.forEach((depth1) => {
        const element = titleRefs.current[depth1.title];

        if (element) {
          // 요소의 상단 위치와 화면의 스크롤 위치를 비교
          const position = element.offsetTop - 400;
          if (scrollY >= position) {
            setActiveAnchor(depth1.title);
          }
        }
      });
    };

    menuWrapper.addEventListener('scroll', handleScroll);

    return () => {
      // .menu-wrapper 요소가 존재하는 경우에만 removeEventListener를 호출
      if (menuWrapper) {
        menuWrapper.removeEventListener('scroll', handleScroll);
      }
    };
  }, []);

  // anchor 클릭시 스크롤 이동 로직
  // const scrollToTitle = (title) => {
  //   const element = titleRefs.current[title];
  //   if (element) {
  //     // 요소의 상단 위치에 266픽셀을 더함
  //     let position = element.offsetTop - 266;

  //     if (searchTerm) {
  //       position -= 34;
  //     }
  //     // 스크롤 이동
  //     document.querySelector('.menu-wrapper').scrollTo({
  //       top: position, // 상단 좌표를 position으로 설정
  //       left: 0, // 왼쪽 좌표를 0으로 설정
  //       behavior: 'smooth',
  //     });
  //   }
  // };

  // 검색 하이라이트 구현
  const handleInputChange = (e) => {
    const newInputValue = e.target.value;
    setInputValue(newInputValue); // 입력 값 업데이트
    setSearchTerm(newInputValue); // 검색어 업데이트
  };

  const handleSubmit = (e) => {
    e.preventDefault(); // 폼의 기본 제출 동작을 막음
    setSearchTerm(inputValue); // 입력된 값을 검색어로 설정
  };

  // 특수문자 이스케이프 처리
  function escapeRegExp(string) {
    return string.replace(/[.*+?^${}()|[\]\\]/g, '\\$&');
  }

  // 검색어 하이라이트를 위한 컴포넌트
  const HighlightText = ({ text, highlight }) => {
    highlight = highlight?.toUpperCase();
    // highlight가 null이거나 빈 문자열인 경우, 처리하지 않고 반환
    if (!highlight) {
      return <span>{text}</span>;
    }

    // 검색어에 특수 문자가 포함되어 있을 경우 이스케이프 처리
    const escapedHighlight = escapeRegExp(highlight);

    // text가 escapedHighlight를 포함하지 않는 경우, 원래 text를 반환
    if (!text?.includes(highlight)) {
      return <span>{text}</span>;
    }

    const parts = text.split(new RegExp(`(${escapedHighlight})`, 'gi'));
    return (
      <span>
        {parts.map((part, index) =>
          part.toLowerCase() === highlight.toLowerCase() ? (
            <span key={index} className="highlighted" style={highlightStyle}>
              {part}
            </span>
          ) : (
            part
          )
        )}
      </span>
    );
  };

  // 검색 결과 개수
  const countHighlightedElements = () => {
    return document.querySelectorAll('.search-result__inner > .search-link-group').length;
  };

  const updateSearchResultsCount = () => {
    // 검색 결과 개수 계산 로직
    const count = countHighlightedElements();
    setSearchResultsCount(count); // 계산된 개수를 상태에 저장
  };

  useEffect(() => {
    if (searchTerm) {
      updateSearchResultsCount(); // 검색어가 변경되었을 때 검색 결과 개수 업데이트
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [searchTerm]);

  // 하이라이트 텍스트 컬러
  const highlightStyle = {
    color: '#3284FF',
  };

  // isClose 함수 수정
  const handleClose = () => {
    // 검색 상태와 입력 값 초기화
    setSearchTerm(null);
    setInputValue('');

    // 원래의 isClose 함수 호출
    if (isClose) isClose();
  };

  // open 시 스크롤 초기화
  useEffect(() => {
    isOpen && // 스크롤 초기화
      document.querySelector('.menu-wrapper').scrollTo({
        top: 0, // 상단 좌표를 0으로 설정
        left: 0, // 왼쪽 좌표를 0으로 설정
        // behavior: 'smooth',
      });
  }, [isOpen]);

  // 입력 필드의 스타일을 결정하는 함수
  const inputStyle = (inputValue) => {
    return {
      backgroundColor: inputValue ? 'rgba(51, 51, 51, 0.05)' : 'transparent', // 입력값이 있으면 회색 배경, 없으면 투명
      padding: inputValue ? '12px' : '0px', // 입력값이 있으면 padding 적용
    };
  };

  // 검색 input 초기화
  const onReset = () => {
    setInputValue('');
    setSearchTerm(null);
    setSearchResultsCount(0);
  };

  /**
   * 즐겨찾기 토글
   */
  const handleToggleFavt = (checked, menu) => {
    // 저장
    dispatch(
      saveBizFavt({
        user_id: session.user_id,
        cre_user_id: session.user_id,
        favt_usg_yn: checked ? 'Y' : 'N',
        mnu_aut_cd: menu.mnu_aut_cd,
        mnu_seq_no: menu.mnu_seq_no,
        sys_seq_no: menu.sys_seq_no,
        use_yn: '1',
      })
    );
  };

  useEffect(() => {
    if (updatingSuccess === false && message) {
      alert(message);
      dispatch(resetBizFavtMsg());
    }
  }, [updatingSuccess, message, alert, dispatch]);

  useEffect(() => {
    // 자주쓰는 업무(즐겨찾기) 조회
    dispatch(
      selectBizFavt({
        user_id: session.user_id,
      })
    );
    return () => {
      dispatch(resetBizFavtMsg());
    };
  }, [dispatch, session.user_id]);

  useEffect(() => {
    if (updatingSuccess) {
      // 자주쓰는 업무(즐겨찾기) 조회
      dispatch(
        selectBizFavt({
          user_id: session.user_id,
        })
      );
    }
  }, [dispatch, session.user_id, updatingSuccess]);

  const handleKeyUp = (e) => {
    if (e.keyCode === 13 || e.key === 'Enter') {
      document.querySelectorAll('.highlighted').forEach((el, index) => {
        document.querySelector('.menu-wrapper').scrollTo({
          top: el.offsetTop - 500,
          left: 0,
          behavior: 'smooth',
        });
      });
    }
  };
  return (
    <>
      <Wrapper $isOpen={isOpen} className="menu-wrapper">
        <SearchArea $isInputOpen={inputValue}>
          <div className="search-wrap">
            <div className="inner">
              <div className="title-box">
                <div className="title">찾으시는 내용이 무엇인가요?</div>
              </div>
              <div className="search-box">
                <SearchForm onSubmit={handleSubmit}>
                  <label htmlFor="menu-search01">메뉴 검색 영역</label>
                  <WOIcon width={24} height={24} icon="search" />
                  <div className="input-wrap">
                    <div className="input">
                      {inputValue && <button type="button" className="clear-btn" onClick={onReset}></button>}
                      <input
                        type="text"
                        name="menu-search01"
                        id="menu-search01"
                        style={inputStyle(inputValue)}
                        value={inputValue}
                        placeholder="업무 키워드를 검색해보세요. 예시 : 세입"
                        onChange={handleInputChange}
                        onKeyUp={handleKeyUp}
                        autoComplete={'off'}
                      />
                    </div>
                    <div className="work">
                      자주 쓰는 업무 총&nbsp;<span>{totalCountByBookmark}</span>건
                    </div>
                  </div>
                </SearchForm>
                {searchTerm && (
                  <div className="search-num">
                    <span>"{searchTerm}"</span>
                    <span> 에 대해 총 {searchResultsCount}건이 검색되었습니다.</span>
                  </div>
                )}
              </div>
              {inputValue && (
                <SearchResult className="search-result-wrap">
                  {searchTerm && (
                    <div className={`search-result ${searchResultsCount === 0 ? 'inactive' : ''}`}>
                      <div className="search-result__inner">
                        {menuList
                          .filter((menu) => menu.mnu_aut_cd !== 'X')
                          .filter((menu) => menu.scrn_url)
                          .map((menu) => {
                            const lv2 = menuList.find((item) => item.mnu_seq_no === menu?.higrk_mnu_seq_no);
                            const lv1 = menuList.find((item) => item.mnu_seq_no === lv2?.higrk_mnu_seq_no);
                            return { ...menu, lv1MenuNm: lv1?.mnu_nm, lv2MenuNm: lv2?.mnu_nm };
                          })
                          .filter(
                            (menu) =>
                              menu.mnu_nm.includes(searchTerm.toUpperCase()) ||
                              menu.lv1MenuNm?.includes(searchTerm.toUpperCase()) ||
                              menu.lv2MenuNm?.includes(searchTerm.toUpperCase())
                          )
                          .map((menu) => (
                            // 검색결과
                            <div key={menu.mnu_seq_no} className="search-link-group">
                              {menu?.lv1MenuNm && (
                                <Link to={menu.scrn_url} state={{ init: true }}>
                                  {/* {menu.lv1MenuNm} */}
                                  <HighlightText text={menu.lv1MenuNm} highlight={searchTerm} />
                                </Link>
                              )}
                              {menu?.lv2MenuNm && (
                                <Link to={menu.scrn_url} state={{ init: true }}>
                                  {/* {menu.lv2MenuNm} */}
                                  <HighlightText text={menu.lv2MenuNm} highlight={searchTerm} />
                                </Link>
                              )}
                              <Link to={menu.scrn_url} state={{ init: true }}>
                                {/* {menu.mnu_nm} */}
                                <HighlightText text={menu.mnu_nm} highlight={searchTerm} />
                              </Link>
                            </div>
                          ))}
                      </div>
                    </div>
                  )}
                </SearchResult>
              )}
            </div>
          </div>
        </SearchArea>
        <MenuListArea>
          {menuList
            .filter((menu) => menu.mnu_lvl === 0)
            .filter((menu) => menu.mnu_aut_cd !== 'X')
            // 하위메뉴 없으면 표시 안되도록
            .filter(
              (menu) =>
                menuList
                  .filter((c) => c.sys_seq_no === menu.mnu_seq_no)
                  .filter((c) => c.scrn_url)
                  .filter((c) => c.mnu_aut_cd !== 'X').length > 0
            )
            .map((depth1, depth1Index) => (
              <MenuListWrap key={`menu-${depth1Index}`} ref={(el) => (titleRefs.current[depth1.mnu_nm] = el)}>
                <MenuListDepth1>
                  <MenuTitle>{depth1.mnu_nm}</MenuTitle>
                </MenuListDepth1>
                <MenuListBox>
                  {menuList
                    .filter((menu) => menu.sys_seq_no === depth1.sys_seq_no)
                    .filter((menu) => menu.mnu_lvl === 1)
                    .filter((menu) => menu.mnu_aut_cd !== 'X')
                    .map((column, columnIndex) => {
                      const depth2 = column;
                      const children = menuList
                        .filter((menu) => menu.mnu_aut_cd !== 'X')
                        .filter((menu) => menu.higrk_mnu_seq_no === column.mnu_seq_no)
                        // 권한 filter start
                        .map((lv2, _, lv2List) => {
                          let mnu_aut_cd = '';
                          if (lv2.frst_tab.length > 0) {
                            if (lv2.pgm_pth.includes('/' + lv2.frst_tab)) {
                              mnu_aut_cd = lv2.mnu_aut_cd;
                            } else {
                              mnu_aut_cd =
                                lv2List.find((item) => item.pgm_pth.includes('/' + lv2.frst_tab))?.mnu_aut_cd ?? '';
                            }
                          } else {
                            mnu_aut_cd = lv2.mnu_aut_cd;
                          }
                          return {
                            ...lv2,
                            mnu_aut_cd,
                          };
                        })
                        .filter((item) => item.mnu_aut_cd !== 'X');
                      // 권한 filter end;
                      return (
                        (children.length > 0 || depth2.scrn_url) && (
                          <MenuColumn key={`column-${depth1Index}-${columnIndex}`}>
                            <MenuListDepth2
                              key={depth2.mnu_seq_no}
                              to={children.length > 0 ? children.at(0)?.scrn_url : depth2.scrn_url}
                              as={children.length > 0 ? 'div' : ''}
                              className={children.length > 0 ? 'has-depth' : ''}
                              onClick={() => {
                                isClose?.();
                              }}>
                              <MenuTitle>
                                <HighlightText text={depth2.mnu_nm} highlight={searchTerm} />
                                {children.length === 0 && (
                                  <WOToggleButton
                                    width={16}
                                    height={16}
                                    icon="star"
                                    checked={bookmarkList.map((favt) => favt.mnu_seq_no).includes(depth2.mnu_seq_no)}
                                    onChange={(checked) => {
                                      handleToggleFavt(checked, depth2);
                                    }}
                                  />
                                )}
                              </MenuTitle>
                              {children.map((depth3, depth3Index) => {
                                return (
                                  <React.Fragment key={`depth3-${depth3.mnu_seq_no}-${depth3Index}`}>
                                    {depth3.frst_tab.length === 0 && (
                                      <MenuListDepth3
                                        key={`depth3-${depth3.mnu_seq_no}-${depth3Index}`}
                                        to={depth3.scrn_url}
                                        state={{ init: true }}
                                        onClick={() => isClose?.()}>
                                        <MenuTitle>
                                          <HighlightText text={depth3.mnu_nm} highlight={searchTerm} />
                                          <WOToggleButton
                                            width={16}
                                            height={16}
                                            icon="star"
                                            checked={bookmarkList
                                              .map((favt) => favt.mnu_seq_no)
                                              .includes(depth3.mnu_seq_no)}
                                            onChange={(checked) => {
                                              handleToggleFavt(checked, depth3);
                                            }}
                                          />
                                        </MenuTitle>
                                      </MenuListDepth3>
                                    )}

                                    {depth3.frst_tab.length > 0 && depth3.pgm_pth.includes('/' + depth3.frst_tab) && (
                                      <MenuListTabWrap key={`depth3-frst-${depth3.mnu_seq_no}-${depth3Index}`}>
                                        <div className="menu-wrap">
                                          {children
                                            .filter((menu) => menu.frst_tab === depth3.frst_tab)
                                            .map((tabItem) => (
                                              <MenuListTab
                                                key={`tabitem-${tabItem.mnu_seq_no}`}
                                                to={tabItem.scrn_url}
                                                state={{ init: true }}>
                                                <MenuTitle>
                                                  <HighlightText text={tabItem.mnu_nm} highlight={searchTerm} />
                                                </MenuTitle>
                                              </MenuListTab>
                                            ))}
                                        </div>
                                        <div className="btn-wrap">
                                          <WOToggleButton
                                            width={16}
                                            height={16}
                                            icon="star"
                                            checked={bookmarkList
                                              .map((favt) => favt.mnu_seq_no)
                                              .includes(depth3.mnu_seq_no)}
                                            onChange={(checked) => {
                                              handleToggleFavt(checked, depth3);
                                            }}
                                          />
                                        </div>
                                      </MenuListTabWrap>
                                    )}
                                  </React.Fragment>
                                );
                              })}
                            </MenuListDepth2>
                          </MenuColumn>
                        )
                      );
                    })}
                </MenuListBox>
              </MenuListWrap>
            ))}
        </MenuListArea>
        <BtnWrap>
          <WOIconButton width={24} height={24} icon="menu-close" onClick={handleClose} />
        </BtnWrap>
      </Wrapper>
    </>
  );
};

export default memo(MenuList);

const Wrapper = styled.div`
  position: fixed;
  top: 0;
  right: 0;
  width: 100vw;
  height: 100vh;
  background: linear-gradient(0deg, rgba(255, 255, 255, 0.2) 0%, rgba(255, 255, 255, 0.2) 100%),
    linear-gradient(180deg, #ebedff 100%, #ffeaea 100%);

  opacity: 0;
  visibility: hidden;
  transition: 0.5s;
  overflow: overlay;
  z-index: 4000;
  ${(props) =>
    props.$isOpen &&
    css`
      opacity: 1;
      visibility: visible;
    `};
`;

// 메뉴 리스트

const MenuListArea = styled.div`
  display: grid;
  grid-template-columns: repeat(4, calc(25% - 10px));
  column-gap: 13px;
  row-gap: 60px;
  width: 1240px;
  margin: 0 auto;

  padding: 240px 0px 93px;
`;

// 검색 영역

const SearchArea = styled.div`
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  background: #eff1ff;
  z-index: 10;

  ${(props) =>
    props.$isInputOpen &&
    css`
      box-shadow: 0px 4px 9px 0px rgba(0, 0, 0, 0.05);
      backdrop-filter: blur(18px);

      .search-wrap {
        background: linear-gradient(0, #ebedff 0, #fcf3fc 3584px);
      }

      .search-result-wrap {
        background: linear-gradient(0, #ebedff 0, #fcf3fc 3584px);
      }
    `}

  &:has(.search-num) {
    & + ${MenuListArea} {
    }
  }
  .inner {
    max-width: 1290px;
    margin: 0 auto;
    padding: 0 32px;
  }
  .search-wrap {
    padding: 50px 0 0;
    border-bottom: 1px solid #1d2e4b;
    .title-box {
      display: flex;
      justify-content: space-between;
      align-items: center;
      .title {
        color: #333;
        font-size: 28px;
        font-weight: 700;
        line-height: 28px;
      }
    }
    .search-box {
      position: relative;
      margin-top: 20px;
      margin-bottom: 23px;
    }
    .search-box:has(.search-num) {
      margin-bottom: 20px;
      .search-num {
        margin-top: 13px;
        font-size: 16px;
        font-weight: 400;
        line-height: 24px;

        span {
          color: var(--Blue-01);
          font-weight: 700;
          word-break: break-all;
          &:last-child {
            font-weight: 400;
            color: var(--warm-dark-10);
          }
        }
      }
    }
  }
  .anchor-wrap {
    height: 72px;
    margin-top: auto;
    .inner {
      display: flex;
      justify-content: space-between;
      align-items: center;
      height: 100%;
    }
    .anchor {
      font-size: 16px;
      font-weight: 500;
      line-height: 24px;
      color: rgba(119, 119, 119, 0.8);
      &.active {
        color: var(--W-Dark-11);
        font-weight: 700;
      }
    }
  }
`;

const SearchForm = styled.form`
  display: flex;
  align-items: center;
  gap: 0 12px;
  label {
    position: absolute;
    width: 1px;
    height: 1px;
    clip: rect(0, 0, 0, 0);
    overflow: hidden;
  }
  .input-wrap {
    position: relative;
    display: flex;
    align-items: center;
    justify-content: space-between;
    width: 100%;
    .input {
      position: relative;
    }
    input {
      width: 283px;
      height: 38px;
      padding: 0 28px 0 12px;
      border-radius: 8px;

      font-size: 16px;
      font-weight: 500;
      line-height: 24px;
      background: unset;
      color: #333;
      &:focus {
        outline: none;
      }
      &::placeholder {
        color: var(--W-Dark-04);
      }
    }
    .clear-btn {
      position: absolute;
      top: 50%;
      right: 12px;
      transform: translateY(-50%);
      width: 16px;
      height: 16px;
      background: url(/assets/images/icon/input-clear-black.svg) no-repeat center / cover;
    }
    .work {
      display: flex;
      align-items: center;
      justify-content: center;
      height: 36px;
      padding: 0px 16px;
      border-radius: 26px;
      background: var(--Grad-BG-02);
      color: var(--White);
      font-size: 14px;
      font-weight: 400;
      line-height: 15px;
      span {
        font-weight: 600;
      }
    }
  }

  input:valid + .search-result-box {
    display: block;
  }
`;

const MenuListWrap = styled.div``;
const MenuListBox = styled.div``;
const MenuColumn = styled.div`
  display: inline-block;
  height: fit-content;
  width: 100%;
  & + & {
    margin-top: 8px;
    &:has(.has-depth) {
      margin-top: 24px;
    }
  }
`;
const MenuTitle = styled.div`
  padding: 16px 12px;
  color: var(--W-Dark-11);
  font-size: 20px;
  font-weight: 700;
  line-height: 30px;
`;

const MenuListDepth1 = styled.div`
  padding-bottom: 8px;
  margin-bottom: 8px;
  border-bottom: 1px solid rgba(29, 46, 75, 0.5);
`;

const MenuListDepth2 = styled(Link)`
  display: block;
  & + & {
    margin-top: 4px;
  }

  ${MenuTitle} {
    display: flex;
    align-items: center;
    justify-content: space-between;
    width: 100%;
    padding: 6px 12px;
    color: var(--W-Dark-10);
    font-size: 16px;
    font-weight: 600;
    line-height: 24px;
    &:hover {
      text-decoration: underline;
    }
  }
  &.has-depth {
    padding-bottom: 16px;
    > ${MenuTitle} {
      &:hover {
        text-decoration: unset;
      }
    }
  }
`;
const MenuListDepth3 = styled(Link)`
  display: flex;
  justify-content: space-between;
  align-items: center;

  ${MenuTitle} {
    display: flex;
    align-items: center;
    justify-content: space-between;
    width: 100%;
    padding: 4px 12px;
    color: var(--W-Dark-10);
    font-size: 16px;
    font-weight: 400;
    line-height: 24px;
    &:hover {
      text-decoration: underline;
    }
    > span:empty {
      display: none;
    }
  }
`;
const MenuListTabWrap = styled.div`
  display: flex;
  width: 100%;

  .menu-wrap {
    flex: 1;
    display: flex;
    flex-direction: column;
    gap: 4px 0;
    background-color: rgba(255, 255, 255, 0.5);
    padding: 4px;
    border-radius: 8px 0px 8px 8px;
  }
  .btn-wrap {
    flex: 0 0 auto;
    height: fit-content;
    padding: 12px 12px 12px 8px;
    background-color: rgba(255, 255, 255, 0.5);
    border-radius: 0px 20px 20px 0px;
  }
`;
const MenuListTab = styled(Link)`
  display: block;
  ${MenuTitle} {
    padding: 4px 12px;
    font-weight: 400;
  }
`;

const BtnWrap = styled.div`
  position: fixed;
  top: 0;
  right: 0;
  width: 68px;
  height: 60px;
  border: 1px solid #8892a2;
  border-top: unset;
  border-right: unset;
  border-radius: 0 0 0 24px;
  z-index: 20;

  .icon-btn {
    position: absolute;
    top: 18px;
    right: 18px;
  }
`;

const SearchResult = styled.div`
  position: absolute;
  left: 0;
  margin-top: 1px;
  width: 100%;
  box-shadow: 0 4px 9px 0 rgba(0, 0, 0, 0.05);
  background: #eff1ff;

  .search-result {
    padding: 38px 0 36px;

    &.inactive {
      padding: 0;
    }
  }

  .search-result__inner {
    display: flex;
    flex-direction: column;
    gap: 16px;
    max-width: 1290px;
    max-height: 202px;
    margin: 0 auto;
    padding: 0 20px;
    overflow-y: auto;

    .search-link-group {
      display: flex;
      align-items: center;
      a {
        font-size: 16px;
        font-weight: 400;
        color: #555;

        > span {
          display: inline-block;
          vertical-align: top;
        }

        & + a:before {
          content: '';
          display: inline-flex;
          align-items: center;
          justify-content: center;
          width: 20px;
          height: 24px;
          vertical-align: top;
          background: url('/assets/images/icon/search-arrow-right.svg') no-repeat center / 20px 20px;
        }

        .highlighted {
          display: inline-block;
          color: rgb(50, 132, 255);
        }
      }
    }
  }
`;
