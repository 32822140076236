import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Outlet } from 'react-router';
import styled, { css } from 'styled-components';
import { fetchUserInfo } from '../../lib/cmm/CMMUser.api';
import { selectMenuList } from '../../redux/modules/cmm/menu.reducer';
import WOLoading from '../common/WOLoading';
import Lnb from './Lnb';
import Side from './Side';

const SubLayout = ({ user }) => {
  const dispatch = useDispatch();
  const [sideOpen, setSideOpen] = useState(false);
  const handleLayout = (sideOpen) => {
    setSideOpen(sideOpen);
  };
  const { loading, list: menuList } = useSelector((state) => state.menuState);

  useEffect(() => {
    const getSessionInfo = async () => {
      const { data: userInfo } = await fetchUserInfo();
      localStorage.setItem('user_info', JSON.stringify(userInfo));
    };
    getSessionInfo();
  }, []);

  useEffect(() => {
    if (menuList.length === 0) {
      dispatch(selectMenuList());
    }
  }, [dispatch, menuList.length]);

  return (
    <SubLayoutWrap>
      <Lnb menuList={menuList} />
      <Side
        user={user}
        onOpen={(sideOpen) => {
          handleLayout(sideOpen);
        }}
        isOpen={sideOpen}
        menuList={menuList}
        // active={true}
      />
      <Main $sideOpen={sideOpen}>
        {(loading || menuList.length === 0) && <WOLoading />}
        <Outlet context={{ sideOpen }} />
      </Main>
    </SubLayoutWrap>
  );
};

export default SubLayout;

const SubLayoutWrap = styled.div`
  height: 100%;
`;

// content

const Main = styled.div`
  height: 100%;

  transition: 0.5s;

  ${(props) =>
    props.$sideOpen &&
    css`
      padding-left: 294px;
    `}
`;
