import { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import { getSession } from '../redux/modules/cmm/login.reducer';

function useSession() {
  const initUserInfo = {
    aut_tp_cd: '',
    br_cd: '',
    br_emp_no: '',
    br_nm: '',
    cd_aut_tp_cd: '',
    cd_open_flag: '',
    cus_ist_cd: '',
    dept_cd: '',
    dept_nm: '',
    env_aut_tp_cd: '',
    gg_aut_tp_cd: '',
    ist_cd: '',
    ist_nm: '',
    ldap_cnt: '',
    login_ip: '',
    mnu_fix_yn: '',
    mnu_user_slt_yn: '',
    non_txitem_sav_yn: '',
    ocr_ist_cd: '',
    pwd_no: '',
    qna_cnt: '',
    sen_sso_aria: '',
    site01: '',
    site02: '',
    site03: '',
    site04: '',
    site05: '',
    site06: '',
    site07: '',
    site08: '',
    site09: '',
    site10: '',
    site_auth_cnt: '',
    use_yn: '',
    user_id: '',
    user_nm: '',
    user_posi: '',
    user_tp_cd: '',
    user_tp_seq_no: '',
  };
  // const navigate = useNavigate();
  // const dispatch = useDispatch();
  // const { cmSession } = useSelector((state) => state.loginState);

  // useEffect(() => {
  //   // console.log('useSession.useEffect');
  //   dispatch(getSession());
  // }, [dispatch]);

  /**
   * 사용자 정보 조회
   * @returns
   */
  const getUserInfo = () => {
    // console.log('getUserInfo');
    let userInfo = localStorage.getItem('user_info');
    if (!userInfo) {
      //window.location.href = '/page/login';
      userInfo = JSON.stringify(initUserInfo);
    }
    return JSON.parse(userInfo);
  };
  return { getUserInfo };
}

export default useSession;
