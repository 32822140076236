import dayjs from 'dayjs';

// actions
const SALARY_TRNS_SEARCHPRAMS = 'brcSalaryTrnsParams/SALARY_TRNS_SEARCHPRAMS';

// initial state
const initialState = {
  // defaultValues
  formData: {
    ydcs8a1_t8a01_inq_sdt: dayjs().format('YYYY-MM-DD'), // 기준일자
    ydcs8a1_t8a01_inq_edt: dayjs().format('YYYY-MM-DD'), // 기준일자
    br_cd: 'all',
  },
  formState: {
    br_cd_disabled: false,
  },
};

// reducers
export default function brcSalaryTrnsParams(state = initialState, action) {
  switch (action.type) {
    case SALARY_TRNS_SEARCHPRAMS: // 조회 성공
      return {
        ...state,
        formData: action.payload.searchParams,
        formState: { ...state.formState, br_cd_disabled: action.payload.brCdDisabled },
      };
    default:
      return state;
  }
}

/**
 * 공무원 급여이체 실적 - 공통 form 세팅함수
 */

// dispatch
export const setSalaryTrnsSearchParams = (searchParams, brCdDisabled) => {
  return {
    type: SALARY_TRNS_SEARCHPRAMS,
    payload: { searchParams, brCdDisabled },
  };
};
