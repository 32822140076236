import React, { useEffect, useState } from 'react';
import styled, { css } from 'styled-components';
import WOIcon from '../common/WOIcon';
import { Link } from 'react-router-dom';
import SecurityCenterPopup from '../../pages/cmm/SecurityCenterPopup';
import { fetchContacts } from '../../lib/sys/SYS0003.api';

const MainFooter = ({ islogin }) => {
  const [securityPopupOpen, setSecurityPopupOpen] = useState(false);
  const [ctaList, setCtaList] = useState([]);
  const [fdList, setFdList] = useState([]);
  const [userList, setUserList] = useState([]);

  useEffect(() => {
    fetchContacts().then((response) => {
      const {
        data: { sub },
      } = response;
      const cta = sub.find((item) => item.grp_cd === 'cta');
      const fd = sub.find((item) => item.grp_cd === 'fd');
      const user = sub.find((item) => item.grp_cd === 'user');
      setCtaList(cta.grp_cd_nm?.split(',').map((item) => item?.trim()));
      setFdList(fd.grp_cd_nm?.split(',').map((item) => item?.trim()));
      setUserList(user.grp_cd_nm?.split(',').map((item) => item?.trim()));
    });
  }, []);

  // view
  return (
    <>
      <FooterWrap className="footer" $islogin={islogin}>
        <div className="footer-top">
          <p className="footer__title">업무 관련 문의</p>
          <ul className="footer__list">
            <li className="footer__item">
              <div className="text-wrap">
                <span className="icon-area">
                  <WOIcon icon="call" width={10} height={10} />
                </span>
                <span className="text">세무관리</span>
              </div>
              <div className="call-wrap">
                {ctaList.map((item, index) => (
                  <a key={`cta-${index}`} href={`tel:${item}`} className="call">
                    {item}
                  </a>
                ))}
                {/* <a href={`tel:02) 6991-1585`} className="call">
                  02) 6991-1585
                </a>
                <a href={`tel:02) 6991-1587`} className="call">
                  02) 6991-1587
                </a> */}
              </div>
            </li>
            <li className="footer__item">
              <div className="text-wrap">
                <span className="icon-area">
                  <WOIcon icon="call" width={10} height={10} />
                </span>
                <span className="text">자금관리</span>
              </div>
              <div className="call-wrap">
                {fdList.map((item, index) => (
                  <a key={`fd-${index}`} href={`tel:${item}`} className="call">
                    {item}
                  </a>
                ))}
                {/* <a href={`tel:02) 6991-1585`} className="call">
                  02) 6991-1585
                </a>
                <a href={`tel:02) 6991-1586`} className="call">
                  02) 6991-1586
                </a>
                <a href={`tel:02) 6991-1588`} className="call">
                  02) 6991-1588
                </a> */}
              </div>
            </li>
            <li className="footer__item">
              <div className="text-wrap">
                <span className="icon-area">
                  <WOIcon icon="call" width={10} height={10} />
                </span>
                <span className="text">사용자관리</span>
              </div>
              <div className="call-wrap">
                {userList.map((item, index) => (
                  <a key={`user-${index}`} href={`tel:${item}`} className="call">
                    {item}
                  </a>
                ))}
                {/* <a href={`tel:02) 6992-2717`} className="call">
                  02) 6992-2717
                </a> */}
              </div>
            </li>
          </ul>
        </div>
        <div className="footer-bottom">
          <div className="inner">
            <div className="logo-wrap">
              <div className="logo"></div>
            </div>
            <div className="site-wrap">
              <div className="site-list">
                <Link to="https://spot.wooribank.com/pot/Dream?withyou=bp" className="site" target="_blank">
                  은행소개
                </Link>
                {/* <Link to="" className="site">
                신용정보활용체제
              </Link>
              <Link to="" className="site">
                보호금융상품등록부
              </Link> */}
                <Link to="https://spot.wooribank.com/pot/Dream?withyou=CMCOM0153" className="site" target="_blank">
                  영업점안내
                </Link>
                <Link to="https://spot.wooribank.com/pot/Dream?withyou=CQSCT0120" className="site" target="_blank">
                  개인신용정보관리보호
                </Link>
                {/* <Link to="" className="site">
                상품공시실
              </Link>
              <Link to="" className="site">
                고객광장
              </Link>
              <Link to="" className="site">
                사고신고
              </Link> */}
                {/* <button className="site" onClick={() => setSecurityPopupOpen(true)}>
                  보안센터
                </button> */}
                <Link to="/intro.html" className="site" target="_blank">
                  보안센터
                </Link>
                <Link to="https://spot.wooribank.com/pot/Dream?withyou=CQSCT0051" className="site" target="_blank">
                  개인정보처리방침
                </Link>
                <Link
                  to="https://spot.wooribank.com/pot/Dream?withyou=CQFNT0034&ARTICLE_ID=21295&BOARD_ID=B00205&bbsMode=view"
                  className="site"
                  target="_blank">
                  우리제로페이Biz서비스 이용약관
                </Link>
                <Link
                  to="https://spot.wooribank.com/pot/Dream?withyou=CQFNT0045&ARTICLE_ID=21126&BOARD_ID=B00230&bbsMode=view"
                  className="site"
                  target="_blank">
                  우리제로페이Biz 매뉴얼
                </Link>
                {/* <Link to="" className="site">
                전자민원접수
              </Link>
              <Link to="" className="site">
                웹접근성 이용안내
              </Link> */}
              </div>
              <div className="copy">COPYRIGHS WOORI BANK. ALL RIGHTS RESERVED.</div>
            </div>
          </div>
        </div>
      </FooterWrap>
      {securityPopupOpen && (
        <SecurityCenterPopup isOpen={securityPopupOpen} onClose={() => setSecurityPopupOpen(false)} />
      )}
    </>
  );
};

export default MainFooter;

const FooterWrap = styled.footer`
  position: relative;
  z-index: 850;
  width: 100%;
  background-color: rgba(232, 237, 249, 1);
  padding-left: ${(props) => (props.$islogin ? '480px;' : '400px;')};
  min-width: 1480px;
  .footer-top {
    display: flex;
    align-items: center;
    justify-content: space-between;
    max-width: 1080px;
    width: 100%;
    height: 60px;
    margin: 0 auto;
    padding: 0 10px;
    &::after {
      content: '';
      position: absolute;
      top: 60px;
      left: 0;
      width: 100%;
      height: 1px;
      background: #b9c7ea;
    }

    .footer {
      &__title {
        flex: 0 0 auto;
        font-size: 14px;
        font-weight: 700;
      }
      &__list {
        display: flex;
        flex-wrap: wrap;
        align-items: center;
        padding-left: 40px;
      }
      &__item {
        display: flex;
        align-items: center;
        & ~ .footer__item {
          &::before {
            content: '';
            display: block;
            width: 1px;
            height: 16px;
            margin: 0 20px;
            transform: rotate(22deg);
            background: #b9c7ea;
          }
        }
        .text-wrap {
          display: flex;
          align-items: center;
          .icon {
            position: absolute;
            top: 50%;
            left: 50%;
            transform: translate(-50%, -50%);
            &-area {
              display: block;
              position: relative;
              width: 18px;
              height: 18px;
              background-color: rgba(255, 255, 255, 0.43);
              border-radius: 100%;
              margin-right: 3px;
            }
          }
          .text {
            padding: 0 3px;
            color: #405076;
            font-weight: 700;
            font-size: 14px;
          }
        }

        .call-wrap {
          display: flex;
          .call {
            display: flex;
            align-items: center;
            color: #788ec2;
            padding: 0 3px;
            font-weight: 400;
            font-size: 14px;
            & ~ .call {
              margin-left: -6px;
              &:before {
                content: ', ';
                white-space: pre;
                display: block;
              }
            }
          }
        }
      }
    }
  }
  .footer-bottom {
    display: flex;
    align-items: center;
    max-width: 1080px;
    width: 100%;
    margin: 0 auto;
    padding: 40px 0;
    .inner {
      display: flex;
      /* justify-content: space-between; */
      width: 100%;
      margin: 0 auto;
      gap: 0 40px;
      .logo-wrap {
        flex: 0 0 auto;
        display: flex;
        flex-direction: column;
        gap: 12px 0;
        .logo {
          width: 36px;
          height: 36px;
          background: url(/assets/images/icon/footer_woori_logo.svg) no-repeat center / cover;
        }
      }
      .site-wrap {
        .site-list {
          display: flex;
          flex-wrap: wrap;
          width: 100%;
          gap: 0px 8px;
          .site {
            position: relative;
            color: var(--C-Dark-09);
            font-size: 14px;
            font-weight: 400;
            line-height: 21px;
            &::after {
              content: '';
              position: absolute;
              right: -5px;
              top: 4.5px;
              width: 1px;
              height: 12px;
              background: #666;
            }
            &:last-child {
              &::after {
                content: none;
              }
            }
            & + .site {
              position: relative;
              &:hover {
                text-decoration: underline;
              }
            }
          }
        }
        .copy {
          margin-top: 6px;
          color: var(--C-Dark-07);
          font-size: 12px;
          font-weight: 400;
          line-height: 18px;
        }
      }
    }
  }
  ${(props) =>
    props.$islogin &&
    css`
      @media screen and (max-width: 1580px) {
        min-width: 868px;
        padding-left: 400px;
        .footer-top {
          display: flex;
          align-items: flex-start;
          height: 83px;
          padding: 13px 32px;
          &::after {
            top: 83px;
          }
          .footer__title {
            flex: 0 0 auto;
          }
          .footer__list {
            align-items: flex-start;
            padding-left: 32px;
            .footer__item {
              position: relative;
              align-items: flex-start;
              & ~ .footer__item {
                position: relative;
                margin-left: 46px;
                &::before {
                  position: absolute;
                  top: 3px;
                  left: -21px;
                  margin: 0 0;
                }
              }
              .text-wrap {
                .icon-area {
                  margin-right: 6px;
                }
                .text {
                  padding: 0;
                }
              }
              .call-wrap {
                display: block;
                padding-left: 6px;
                .call {
                  padding: 0 0;
                  white-space: nowrap;
                  & ~ .call {
                    margin-left: 0;
                    &:before {
                      content: none;
                    }
                  }
                }
              }
            }
          }
        }

        .footer-bottom {
          display: flex;
          justify-content: center;
          padding: 40px 32px;
          .inner {
            align-items: flex-start;
            gap: 0px 40px;
          }
        }
      }
    `}
`;
