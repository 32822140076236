import instance from '../../common/config/api';

/**
 * 세입월계표(시청지점총괄) 보고서 출력
 * @param {object} params
 * @returns
 */
export const fetchAnrvMmRptHqGnrn = (params) =>
  instance.post(`${process.env.REACT_APP_SERVER_URL}/scq/scq/rpt/0001/selectAnrvMmRptHqGnrn`, params);

/**
 * 세입월계표(서울시청총괄) 총합+일반보고서 조회
 * @param {object} params
 * @returns
 */
export const fetchAnrvMmRptHqGnrnTotSum = (params) =>
  instance.post(`${process.env.REACT_APP_SERVER_URL}/scq/scq/rpt/0001/selectAnrvMmRptHqGnrnTotSum`, params);

/**
 * 세입월계표(시청지점총괄) 보고서 조회 일자 조회
 * @param {object} params
 * @returns
 */
export const fetchAnrvMmRptHqGnrnInqDt = (params) =>
  instance.post(`${process.env.REACT_APP_SERVER_URL}/scq/scq/rpt/0001/selectAnrvMmRptHqGnrnInqDt`, params);

/**
 * 세입월계표(시청지점총괄) 내역 조회
 * @param {object} params
 * @returns
 */
export const fetchAnrvMmRptHqGnrnPrts = (params) =>
  instance.post(`${process.env.REACT_APP_SERVER_URL}/scq/scq/rpt/0001/selectAnrvMmRptHqGnrnPrts`, params);
