import { fetchTrsfCstd } from '../../../lib/ebk/TrsfCstd.api';
import { SUCCESS, REQUEST, FAILURE } from '../../../common/utils/action-type-utils';
import dayjs from 'dayjs';
import { fetchCommonComboList } from '../../../lib/cmm/combo.api';

// actions
const FETCH_TRSF_CSTD = 'TrsfCstd/FETCH_TRSF_CSTD';

const FETCH_IST_INFO_LIST = 'TrsfCstd/FETCH_IST_INFO_LIST';
const FETCH_DEPT_INFO_LIST = 'TrsfCstd/FETCH_DEPT_INFO_LIST';
const FETCH_ACC_YR_LIST = 'TrsfCstd/FETCH_ACC_YR_LIST';

const RESET_INITIAL_STATE = 'TrsfCstd/RESET_INITIAL_STATE';
const SAVE_SEARCH_FORM_VALUES = 'TrsfCstd/SAVE_SEARCH_FORM_VALUES';

const CLEAR_MESSAGE = 'TrsfCstd/CLEAR_MESSAGE';
const SET_BEFORE_SEARCH_PARAM = 'TrsfCstd/SET_BEFORE_SEARCH_PARAM';

export const initFormValues = {
  dat_dis: '00', //자료구분
  fnd_dis: '', //자금구분
  cmd_dt_start: dayjs(), //지급일자
  cmd_dt_end: dayjs(), //지급일자
  acc_yr: dayjs().format('YYYY'), //회계연도
  cmd_no_start: '',
  cmd_no_end: '',
  cus_ist_cd: '6110000', //기관명
  cus_dept_cd: 'all', //부서명
  vgubun: '0',
};
// initial state
const initialState = {
  loading: false,
  success: true,
  message: '',
  list: [],
  totalCount: 0,
  beforeSearchParam: {},

  //콤보 - 기관명 072
  istInfoSuccess: false,
  istInfoLoading: false,
  istInfoList: [],

  //콤보 - 부서명 158
  deptInfoSuccess: false,
  deptInfoLoading: false,
  deptInfoList: [],

  //콤보 - 회계연도 025
  accYrSuccess: false,
  accYrLoading: false,
  accYrList: [],

  // 검색조건
  searchFormValues: {
    init: true,
    ...initFormValues,
  },
};

// reducers
export default function TrsfCstd(state = initialState, action) {
  switch (action.type) {
    case SUCCESS(FETCH_TRSF_CSTD): // 조회 성공
      return {
        ...state,
        success: true,
        loading: false,
        list: action.payload.data?.main,
        totalCount: action.payload.data?.mainCnt,
      };
    case REQUEST(FETCH_TRSF_CSTD): // 조회 요청
      return {
        ...state,
        loading: true,
      };
    case FAILURE(FETCH_TRSF_CSTD): // 조회실패
      return {
        ...state,
        list: [],
        loading: false,
        success: false,
        message: action.payload.response?.data?.message,
      };
    // 기관명
    case SUCCESS(FETCH_IST_INFO_LIST): // 조회 성공
      return {
        ...state,
        istInfoSuccess: true,
        istInfoLoading: false,
        istInfoList: action.payload.data?.subDto,
      };
    case REQUEST(FETCH_IST_INFO_LIST): // 조회 요청
      return {
        ...state,
        istInfoLoading: true,
        istInfoSuccess: false,
      };
    case FAILURE(FETCH_IST_INFO_LIST): // 조회실패
      return {
        istInfoLoading: false,
        istInfoSuccess: false,
        istInfoList: [],
      };
    // 부서명
    case SUCCESS(FETCH_DEPT_INFO_LIST): // 조회 성공
      return {
        ...state,
        deptInfoSuccess: true,
        deptInfoLoading: false,
        deptInfoList: action.payload.data?.subDto,
      };
    case REQUEST(FETCH_DEPT_INFO_LIST): // 조회 요청
      return {
        ...state,
        deptInfoLoading: true,
        deptInfoSuccess: false,
      };
    case FAILURE(FETCH_DEPT_INFO_LIST): // 조회실패
      return {
        ...state,
        deptInfoLoading: false,
        deptInfoSuccess: false,
        deptInfoList: [],
      };
    // 회계연도
    case SUCCESS(FETCH_ACC_YR_LIST): // 조회 성공
      return {
        ...state,
        accYrSuccess: true,
        accYrLoading: false,
        accYrList: action.payload.data?.subDto,
      };
    case REQUEST(FETCH_ACC_YR_LIST): // 조회 요청
      return {
        ...state,
        accYrLoading: true,
        accYrSuccess: false,
      };
    case FAILURE(FETCH_ACC_YR_LIST): // 조회실패
      return {
        ...state,
        accYrLoading: false,
        accYrSuccess: false,
        accYrList: [],
      };
    case SAVE_SEARCH_FORM_VALUES:
      return {
        ...state,
        istInfoSuccess: false,
        istInfoLoading: false,
        deptInfoSuccess: false,
        deptInfoLoading: false,
        accYrSuccess: false,
        accYrLoading: false,

        searchFormValues: action.payload,
      };
    case RESET_INITIAL_STATE:
      return initialState;
    case SET_BEFORE_SEARCH_PARAM:
      return {
        ...state,
        beforeSearchParam: action.payload,
      };
    case CLEAR_MESSAGE:
      return {
        ...state,
        message: '',
        detailsMessage: '',
      };
    default:
      return state;
  }
}

/**
 * 대체보관내역 조회
 */
export const selectTrsfCstd = (searchParams) => {
  return {
    type: FETCH_TRSF_CSTD,
    payload: fetchTrsfCstd(searchParams),
  };
};

/**
 * 메시지초기화
 */
export const clearMessage = () => {
  return {
    type: CLEAR_MESSAGE,
  };
};

/**
 *
 */
export const setBeforeSearchParam = (searchParams) => {
  return {
    type: SET_BEFORE_SEARCH_PARAM,
    payload: searchParams,
  };
};

//기관명 콤보 조회
export const selectIstInfoList = (searchParam) => {
  return {
    type: FETCH_IST_INFO_LIST,
    payload: fetchCommonComboList({ slt_box_no: '005', ...searchParam }),
  };
};

//부서명 콤보 조회
export const selectDeptInfoList = (searchParam) => {
  return {
    type: FETCH_DEPT_INFO_LIST,
    payload: fetchCommonComboList({ slt_box_no: '158', ...searchParam }),
  };
};

//회계연도 콤보 조회
export const selectAccYrList = () => {
  return {
    type: FETCH_ACC_YR_LIST,
    payload: fetchCommonComboList({ slt_box_no: '025' }),
  };
};

/**
 * 조회조건 저장
 */
export const saveSearchFormValues = (searchFormValues) => {
  return {
    type: SAVE_SEARCH_FORM_VALUES,
    payload: {
      ...searchFormValues,
      init: false,
    },
  };
};

/**
 * 상태초기화
 */
export const resetInitialState = () => {
  return {
    type: RESET_INITIAL_STATE,
  };
};
