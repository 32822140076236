import instance from '../../common/config/api';

/**
 * 금고영업점 - 지자체 정보관리 - 급여이체 점별 전체내역
 * @param {object} params
 * @returns
 */

export const fetchSalaryTrnsEachIstAllList = (params) =>
  instance.post(`${process.env.REACT_APP_SERVER_URL}/scq/scq/brc/0002/selectSalaryTrnsEachIstAllList`, params);
