import instance from '../../common/config/api';

/**
 * 세입 - 공금연동 보통예금 계좌 조회
 * @param {object} params
 * @returns
 */
export const fetchPmnyLnkGndpAcnoList = (params) =>
  instance.post(`${process.env.REACT_APP_SERVER_URL}/scq/scq/arv/0004/selectPmnyLnkGndpAcnoList`, params);

/**
 * 세입 - 희망번호 계좌조회(콤보셋팅)
 * @param {object} params
 * @returns
 */
export const fetchHopeActList = (params) =>
  instance.post(`${process.env.REACT_APP_SERVER_URL}/scq/scq/arv/0004/selectHopeNoAcnoList`, params);