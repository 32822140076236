import dayjs from 'dayjs';
import { SUCCESS, REQUEST, FAILURE } from '../../../common/utils/action-type-utils';
import { fetchCommonComboList } from '../../../lib/cmm/combo.api';
import {
  fetchAnrvMmGangnam,
  fetchAnrvMmYongsan,
  fetchSeoulAnrvMm,
  fetchSeoulAnrvMmByAct,
  fetchSeoulAnrvMmByDay,
} from '../../../lib/rpt/abrvMm.api';
import { fetchAppvPrg, fetchAppvPrgGuse } from '../../../lib/rpt/appvPrg.api';

// actions
const FETCH_SEOUL_ANRV_MM = 'abrvMm/FETCH_SEOUL_ANRV_MM';
const FETCH_SEOUL_ANRV_MM_BY_ACT = 'abrvMm/FETCH_SEOUL_ANRV_MM_BY_ACT';
const FETCH_SEOUL_ANRV_MM_BY_DAY = 'abrvMm/FETCH_SEOUL_ANRV_MM_BY_DAY';
const FETCH_ANRV_MM_GANGNAM = 'abrvMm/FETCH_ANRV_MM_GANGNAM';
const FETCH_ANRV_MM_YONGSAN = 'abrvMm/FETCH_ANRV_MM_YONGSAN';
// actions - combo
const FETCH_HGRN_IST_INFO_LIST = 'abrvMm/FETCH_HGRN_IST_INFO_LIST';
const FETCH_IST_INFO_LIST = 'abrvMm/FETCH_IST_INFO_LIST';
const FETCH_DEPT_INFO_LIST = 'abrvMm/FETCH_DEPT_INFO_LIST';
const FETCH_ACC_YR_LIST = 'abrvMm/FETCH_ACC_YR_LIST';
const FETCH_ACT_NO_LIST = 'abrvMm/FETCH_ACT_NO_LIST';
const SAVE_SEARCH_FORM_VALUES = 'abrvMm/SAVE_SEARCH_FORM_VALUES';
const RESET_STATE = 'abrvMm/RESET_STATE';
// action - 결제진행상태
const FETCH_APPV_PRG = 'abrvMm/FETCH_APPV_PRG';
const FETCH_APPV_PRG_GUSE = 'abrvMm/FETCH_APPV_PRG_GUSE';

export const initFormValues = {
  cd_ist_cd: '', //기관구분
  cus_ist_cd: '', //기관명
  cus_dept_cd: 'all', //부서명
  ydcs8a1_t8a02_acc_yr: dayjs().format('YYYY'), //회계연도
  ydcs8a1_t8a02_sigu_dscd: '3', // 시세/구세/계좌별
  ydcs8a1_t8a02_act_no: '', // 계좌번호
  ydcs8a1_t8a02_inq_mn: dayjs().format('YYYY-MM'), // 기준연월
  activeTab: '1',
};

// initial state
const initialState = {
  loading: false,
  success: false,
  message: '',
  common: {},
  head: {},
  list: [],
  listTitle: {
    agg_arvn: 0,
    agg_crc: 0,
    agg_owpay: 0,
    agg_owcrc: 0,
  },
  totalCount: 0,

  // 기관구분
  hgrnIstInfoSuccess: false,
  hgrnIstInfoLoading: false,
  hgrnIstInfoList: [],
  // 기관명
  istInfoSuccess: false,
  istInfoLoading: false,
  istInfoList: [],
  // 부서명
  deptInfoSuccess: false,
  deptInfoLoading: false,
  deptInfoList: [],
  // 회계연도
  accYrSuccess: false,
  accYrLoading: false,
  accYrList: [],
  // 계좌번호
  actNoSuccess: false,
  actNoLoading: false,
  actNoList: [],
  // 검색조건
  searchFormValues: {
    init: true,
    ...initFormValues,
  },
  // 결제진행상태
  appvPrgSuccess: false,
  appvPrgLoading: false,
  appvPrgList: [],
};

// reducers
export default function abrvMm(state = initialState, action) {
  switch (action.type) {
    case SUCCESS(FETCH_SEOUL_ANRV_MM): // 조회 성공
    case SUCCESS(FETCH_SEOUL_ANRV_MM_BY_ACT): // 조회 성공
    case SUCCESS(FETCH_SEOUL_ANRV_MM_BY_DAY): // 조회 성공
    case SUCCESS(FETCH_ANRV_MM_YONGSAN): // 조회 성공
      return {
        ...state,
        success: true,
        loading: false,
        list: action.payload.data?.output_data,
        totalCount: action.payload.data?.output_data_cnt,
        common: action.payload.data?.common,
        head: action.payload.data?.output_head,
        listTitle: {
          act_no: action.payload.data?.act_no,
          acctYy1: action.payload.data?.acctYy1,
          act_nm: action.payload.data?.act_nm,
          bas_mn: action.payload.data?.bas_mn,
          agg_arvn: action.payload.data?.agg_arvn,
          agg_crc: action.payload.data?.agg_crc,
          agg_owpay: action.payload.data?.agg_owpay,
          agg_owcrc: action.payload.data?.agg_owcrc,
          spr1: action.payload.data?.spr1,
        },
      };
    case REQUEST(FETCH_SEOUL_ANRV_MM): // 조회 요청
    case REQUEST(FETCH_SEOUL_ANRV_MM_BY_ACT): // 조회 요청
    case REQUEST(FETCH_SEOUL_ANRV_MM_BY_DAY): // 조회 요청
    case REQUEST(FETCH_ANRV_MM_YONGSAN): // 조회 요청
      return {
        ...state,
        success: false,
        loading: true,
        message: '',
      };
    case FAILURE(FETCH_SEOUL_ANRV_MM): // 조회실패
    case FAILURE(FETCH_SEOUL_ANRV_MM_BY_ACT): // 조회실패
    case FAILURE(FETCH_SEOUL_ANRV_MM_BY_DAY): // 조회실패
    case FAILURE(FETCH_ANRV_MM_YONGSAN): // 조회실패
      return {
        ...state,
        loading: false,
        success: false,
        message: action.payload.response?.data?.message,
        common: {},
        head: {},
        list: [],
        listTitle: {
          agg_arvn: 0,
          agg_crc: 0,
          agg_owpay: 0,
          agg_owcrc: 0,
        },
        totalCount: 0,
      };
    // 기관구분
    case SUCCESS(FETCH_HGRN_IST_INFO_LIST): // 조회 성공
      return {
        ...state,
        hgrnIstInfoSuccess: true,
        hgrnIstInfoLoading: false,
        hgrnIstInfoList: action.payload.data?.subDto,
      };
    case REQUEST(FETCH_HGRN_IST_INFO_LIST): // 조회 요청
      return {
        ...state,
        hgrnIstInfoLoading: true,
        hgrnIstInfoSuccess: false,
      };
    case FAILURE(FETCH_HGRN_IST_INFO_LIST): // 조회실패
      return {
        hgrnIstInfoLoading: false,
        hgrnIstInfoSuccess: false,
        hgrnIstInfoList: [],
      };
    // 기관명
    case SUCCESS(FETCH_IST_INFO_LIST): // 조회 성공
      return {
        ...state,
        istInfoSuccess: true,
        istInfoLoading: false,
        istInfoList: action.payload.data?.subDto,
      };
    case REQUEST(FETCH_IST_INFO_LIST): // 조회 요청
      return {
        ...state,
        istInfoLoading: true,
        istInfoSuccess: false,
      };
    case FAILURE(FETCH_IST_INFO_LIST): // 조회실패
      return {
        ...state,
        istInfoLoading: false,
        istInfoSuccess: false,
        istInfoList: [],
      };
    // 부서명
    case SUCCESS(FETCH_DEPT_INFO_LIST): // 조회 성공
      return {
        ...state,
        deptInfoSuccess: true,
        deptInfoLoading: false,
        deptInfoList: action.payload.data?.subDto,
      };
    case REQUEST(FETCH_DEPT_INFO_LIST): // 조회 요청
      return {
        ...state,
        deptInfoLoading: true,
        deptInfoSuccess: false,
      };
    case FAILURE(FETCH_DEPT_INFO_LIST): // 조회실패
      return {
        ...state,
        deptInfoSuccess: false,
        deptInfoLoading: false,
        deptInfoList: [],
      };
    // 회계연도
    case SUCCESS(FETCH_ACC_YR_LIST): // 조회 성공
      return {
        ...state,
        accYrSuccess: true,
        accYrLoading: false,
        accYrList: action.payload.data?.subDto,
      };
    case REQUEST(FETCH_ACC_YR_LIST): // 조회 요청
      return {
        ...state,
        accYrLoading: true,
        accYrSuccess: false,
      };
    case FAILURE(FETCH_ACC_YR_LIST): // 조회실패
      return {
        ...state,
        accYrLoading: false,
        accYrSuccess: false,
        accYrList: [],
      };
    // 계좌번호
    case SUCCESS(FETCH_ACT_NO_LIST): // 조회 성공
      return {
        ...state,
        actNoSuccess: true,
        actNoLoading: false,
        actNoList: action.payload.data?.subDto,
      };
    case REQUEST(FETCH_ACT_NO_LIST): // 조회 요청
      return {
        ...state,
        actNoLoading: true,
        actNoSuccess: false,
      };
    case FAILURE(FETCH_ACT_NO_LIST): // 조회실패
      return {
        ...state,
        actNoLoading: false,
        actNoSuccess: false,
        actNoList: [],
      };
    case SAVE_SEARCH_FORM_VALUES:
      return {
        ...state,
        // 기관구분
        hgrnIstInfoSuccess: false,
        hgrnIstInfoLoading: false,
        // 기관명
        istInfoSuccess: false,
        istInfoLoading: false,
        // 부서명
        deptInfoSuccess: false,
        deptInfoLoading: false,
        // 회계연도
        accYrSuccess: false,
        accYrLoading: false,
        // 계좌번호
        actNoSuccess: false,
        actNoLoading: false,
        searchFormValues: action.payload,
      };
    case RESET_STATE:
      return initialState;
    // 결제상태
    case SUCCESS(FETCH_APPV_PRG): // 조회 성공
    case SUCCESS(FETCH_APPV_PRG_GUSE): // 조회 성공
      return {
        ...state,
        appvPrgSuccess: true,
        appvPrgLoading: false,
        appvPrgList: action.payload.data?.main_find,
      };
    case REQUEST(FETCH_APPV_PRG): // 조회 요청
    case REQUEST(FETCH_APPV_PRG_GUSE): // 조회 요청
      return {
        ...state,
        appvPrgSuccess: false,
        appvPrgLoading: true,
        message: '',
        appvPrgList: [],
      };
    case FAILURE(FETCH_APPV_PRG): // 조회실패
    case FAILURE(FETCH_APPV_PRG_GUSE): // 조회실패
      return {
        ...initialState,
        appvPrgLoading: false,
        appvPrgSuccess: false,
        // message: action.payload.response?.data?.message,
      };
    default:
      return state;
  }
}

/**
 * (아답터) 서울시 세입월계표 조회
 */
export const selectSeoulAnrvMm = (searchParams) => {
  return {
    type: FETCH_SEOUL_ANRV_MM,
    payload: fetchSeoulAnrvMm(searchParams),
  };
};

/**
  // (아답터) 서울시 계좌별 세입월계표 조회
 */
export const selectSeoulAnrvMmByAct = (searchParams) => {
  return {
    type: FETCH_SEOUL_ANRV_MM_BY_ACT,
    payload: fetchSeoulAnrvMmByAct(searchParams),
  };
};

/**
 * 서울시 일자별 세입월계표 조회
 */
export const selectSeoulAnrvMmByDay = (searchParams) => {
  return {
    type: FETCH_SEOUL_ANRV_MM_BY_DAY,
    payload: fetchSeoulAnrvMmByDay(searchParams),
  };
};

/**
 * 세입월계표(신한_강남구청) 조회
 */
export const selectAnrvMmGangnam = (searchParams) => {
  return {
    type: FETCH_ANRV_MM_GANGNAM,
    payload: fetchAnrvMmGangnam(searchParams),
  };
};

/**
  용산구청 (세입)세입월계표 조회
 */
export const selectAnrvMmYongsan = (searchParams) => {
  return {
    type: FETCH_ANRV_MM_YONGSAN,
    payload: fetchAnrvMmYongsan(searchParams),
  };
};

/**
 * 기관구분 콤보 조회
 */
export const selectHgrnIstInfoComboList = (searchParams) => {
  return {
    type: FETCH_HGRN_IST_INFO_LIST,
    payload: fetchCommonComboList({ slt_box_no: '072', ...searchParams }),
  };
};

/**
 * 기관명 콤보 조회
 */
export const selectIstInfoComboList = (searchParams) => {
  return {
    type: FETCH_IST_INFO_LIST,
    payload: fetchCommonComboList({ slt_box_no: '074', ...searchParams }),
  };
};

/**
 * 부서명 콤보 조회
 */
export const selectDeptInfoComboList = (searchParams) => {
  return {
    type: FETCH_DEPT_INFO_LIST,
    payload: fetchCommonComboList({ slt_box_no: '042', ...searchParams }),
  };
};

/**
 * 회계연도 콤보 조회
 */
export const selectAccYrComboList = (searchParams) => {
  return {
    type: FETCH_ACC_YR_LIST,
    payload: fetchCommonComboList({ slt_box_no: '025', ...searchParams }),
  };
};

/**
 * 계좌번호 콤보 조회
 */
export const selectActNoComboList = (searchParams) => {
  return {
    type: FETCH_ACT_NO_LIST,
    payload: fetchCommonComboList({ slt_box_no: '920', ...searchParams }),
  };
};

/**
 * 조회조건 저장
 */
export const saveSearchFormValues = (searchFormValues) => {
  return {
    type: SAVE_SEARCH_FORM_VALUES,
    payload: {
      ...searchFormValues,
      init: false,
    },
  };
};

/**
 * 상태초기화
 */
export const resetState = () => {
  return {
    type: RESET_STATE,
  };
};

/**
 * 보고서 결재진행 상태 조회
 * @param {object} searchParams
 * @returns
 */
export const selectAppvPrg = (searchParams) => {
  return {
    type: FETCH_APPV_PRG,
    payload: fetchAppvPrg(searchParams),
  };
};

/**
 * 보고서 결재진행 상태 조회 구세, 보고서 조회시
 * @param {object} searchParams
 * @returns
 */
export const selectAppvPrgGuse = (searchParams) => {
  return {
    type: FETCH_APPV_PRG_GUSE,
    payload: fetchAppvPrgGuse(searchParams),
  };
};
