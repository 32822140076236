import instance from '../../common/config/api';

/**
 * 사이트 목록 조회
 * @param {object} params
 * @returns
 */
export const fetchSiteMngList = (params) =>
  instance.post(`${process.env.REACT_APP_SERVER_URL}/scq/scq/sys/0003/selectSite`, params);

/**
 * 사이트 저장
 * @param {array} dtos
 * @returns
 */
export const saveSiteMngList = (dtos) =>
  instance.post(`${process.env.REACT_APP_SERVER_URL}/scq/scq/sys/0003/saveSite`, {
    main: dtos,
    mainCnt: dtos.length,
  });
