import React, { useEffect, useState } from 'react';
import styled from 'styled-components';
import WOMainTabs from '../components/main/WOMainTabs';
import WOMenuTabs from '../components/main/WOMenuTabs';
import WOButton from '../components/common/WOButton';
import WOIcon from '../components/common/WOIcon';
import WOIconButton from '../components/common/WOIconButton';
import WOWorkMenuItem from '../components/main/WOWorkMenuItem';
import WOAreaMenuItem from '../components/main/WOAreaMenuItem';
import WOSelectedWorkList from '../components/main/WOSelectedWorkList';
import WOTabContent from '../components/common/WOTabContent';
import WOFavoriteWorkList from '../components/main/WOFavoriteWorkList';
import WOMainSearchForm from '../components/main/WOMainSearchForm';
import { useDispatch, useSelector } from 'react-redux';
import useSession from '../hooks/useSession';
import { selectBizChcMenuList } from '../redux/modules/cmm/main.reducer';
import { saveBizFavt, selectBizFavt } from '../redux/modules/cmm/bookmark.reducer';
import { selectCommonComboList } from '../redux/modules/cmm/combo.reducer';
import { lpad } from '../common/utils/utils';
import useAlert from '../hooks/useAlert';
import { delSelectedWorkList, setSelectedWorkList } from '../redux/modules/cmm/work.reducer';
import { useNavigate } from 'react-router-dom';
import WOComboBox from '../components/common/WOComboBox';
import WOMainTotalSearchForm from '../components/main/WOMainTotalSearchForm';

const WOMainSelectWorkArea = ({ user }) => {
  const session = useSession().getUserInfo();
  const dispatch = useDispatch();
  const { alert } = useAlert();
  // const { confirm } = useConfirm();
  const navigate = useNavigate();
  const [selectedMainTab, setSelectedMainTab] = useState('tab1');
  const [selectedTab, setSelectedTab] = useState('tab1-1');
  const [tabs, setTabs] = useState([]);
  const [filterMenuName, setFilterMenuName] = useState('');
  const [checkedWorkList, setCheckedWorkList] = useState([]);
  // 선택된 업무
  // const [selectedWorkList, setSelectedWorkList] = useState([]);
  const [workDetailList, setWorkDetailList] = useState([]);
  // 선택된 기관
  const [selectedIstInfo, setSelectedIstInfo] = useState();

  const { /* loading, success, message, */ list: menuList } = useSelector((state) => state.mainState);
  const { /* loading, success, message, */ list: selectedWorkList } = useSelector((state) => state.workState);
  const { /* loading, success, message, */ list: favtList, updatingSuccess } = useSelector(
    (state) => state.bookmarkState
  );

  // 기관명
  const {
    options: { CB907: istInfoList = [] },
  } = useSelector((state) => state.combo);

  const handleTabChange = (tabId) => {
    setSelectedTab(tabId);
  };

  /**
   * 업무선택 검색
   * @param {string} text
   */
  const handleChangeFilterMenuName = (text) => {
    setFilterMenuName(text);
  };

  /**
   * 즐겨찾기 토글
   */
  const handleToggleFavt = (checked, menu) => {
    // 저장
    dispatch(
      saveBizFavt({
        user_id: session.user_id,
        cre_user_id: session.user_id,
        favt_usg_yn: checked ? 'Y' : 'N',
        mnu_aut_cd: menu.mnu_aut_cd,
        mnu_seq_no: menu.mnu_seq_no,
        sys_seq_no: menu.sys_seq_no,
        use_yn: '1',
      })
    );
  };

  /**
   * 기관선택
   * @param {object} istInfo
   */
  const handleIstInfoSelect = (istInfo) => {
    setSelectedIstInfo(istInfo);
  };

  /**
   * 업무선택
   */
  const handleSelectWork = async () => {
    if (!selectedIstInfo && user?.id === 1) {
      // 우리은행 총괄 관리자만 기관선택
      alert('기관을 선택해주세요');
      return;
    }
    const selectedMenuList = menuList
      .filter((menu) => checkedWorkList.includes(menu.mnu_seq_no))
      .map((selected) => {
        return {
          ...selected,
          sys_nm: selected.mnu_lvl === 1 ? '' : tabs.find((tab) => tab.id === selected.sys_seq_no)?.title,
          higrk_mnu_nm: menuList.find((menu) => menu.mnu_seq_no === selected.higrk_mnu_seq_no)?.mnu_nm,
          ist_no: selectedIstInfo?.code,
          ist_nm: selectedIstInfo?.codenm,
        };
      });

    // const favoriteIds = favtList.map((favt) => favt.mnu_seq_no);
    // const favoriteCount = favtList.length;
    // const onlySelectedWorkCount = selectedMenuList.filter((menu) => !favoriteIds.includes(menu.mnu_seq_no)).length;
    // if (30 < favoriteCount + onlySelectedWorkCount) {
    //   alert('선택된 업무 는 최대 30개까지 가능합니다. 선택된 업무 삭제 후 추가해 주세요');
    //   return;
    // }
    if (selectedMenuList.length === 0) {
      return;
    }
    const selectedMenuIds = selectedMenuList.map((sel) => sel.mnu_seq_no);
    const prevList = selectedWorkList
      .filter((item) => !selectedMenuIds.includes(item.mnu_seq_no))
      .map((item) => ({ ...item, ist_no: selectedIstInfo?.code, ist_nm: selectedIstInfo?.codenm }));
    dispatch(setSelectedWorkList([...prevList, ...selectedMenuList]));
    // 선택된 업무 초기화
    setCheckedWorkList([]);
  };

  /**
   * 업무 체크시
   */
  const handleCheckWork = (_, id) => {
    const checkMenu = menuList.find((menu) => menu.mnu_seq_no === id);
    if (!checkMenu.scrn_url) {
      return;
    }
    const firstTab = menuList.find((menu) => checkMenu.frst_tab && menu.pgm_pth.includes(checkMenu.frst_tab));
    const isChecked = checkedWorkList.includes(id) || checkedWorkList.includes(firstTab?.mnu_seq_no);
    if (!isChecked) {
      setCheckedWorkList((prevList) => [...prevList, firstTab ? firstTab.mnu_seq_no : id]);
    } else {
      setCheckedWorkList((prevList) => prevList.filter((item) => item !== (firstTab ? firstTab.mnu_seq_no : id)));
    }
  };

  /**
   * 선택된 업무 제거
   */
  const handleRemoveSelectWork = (id) => {
    dispatch(delSelectedWorkList(id));
  };

  /**
   * 업무조회
   */
  const handleDoWork = () => {
    if (selectedWorkList?.length > 0) {
      const firstSelectedWork = selectedWorkList.at(0);
      navigate(firstSelectedWork.scrn_url, { state: { istNo: firstSelectedWork.ist_no } });
    }
  };

  useEffect(() => {
    if (menuList.length > 0) {
      setTabs(
        menuList
          .filter((menu) => menu.mnu_lvl === 0)
          // .filter((menu) => menu.sys_cd !== 'CC')
          .filter((menu) => menu.mnu_aut_cd !== 'X')
          .filter((menu) => menu.mnu_nm !== '시스템관리')
          .map((menu) => ({
            id: menu.sys_seq_no,
            title: menu.mnu_nm,
            icon: menu.icon_pth_nm.replaceAll('main_', 'main_tab_'),
          }))
      );
    }
    setWorkDetailList(menuList);
  }, [menuList]);

  useEffect(() => {
    if (tabs.length > 0) {
      setSelectedTab(tabs.at(0).id);
    }
  }, [tabs]);

  useEffect(() => {
    // 업무선택 메뉴조회
    dispatch(
      selectBizChcMenuList({
        user_id: session.user_id,
        user_tp_seq_no: session.user_tp_seq_no,
      })
    );
    // 기관 조회
    dispatch(
      selectCommonComboList({
        slt_box_no: '907',
        cus_ist_cd: '',
        aut_tp_cd: '',
      })
    );
  }, [dispatch, session.user_id, session.user_tp_seq_no]);

  return (
    <Wrapper>
      <TabArea>
        {/* 메인 탭 영역 */}
        <WOMainTabs
          selectedTabId={selectedMainTab}
          onChange={(selectedTabID) => setSelectedMainTab(selectedTabID)}
          tabs={[
            {
              id: 'tab1',
              name: '📂  자주 쓰는 업무',
            },
            {
              id: 'tab2',
              name: '📋 업무 선택',
            },
          ]}
        />
        <WOMainTotalSearchForm placeholder="업무 키워드를 검색해보세요. 예시 : 세입" menuList={menuList} />
      </TabArea>
      {/* 자주 쓰는 업무 */}
      <WOTabContent id={'tab1'} activeTabId={selectedMainTab}>
        <FavoriteWorkArea>
          <div className="list-wrap">
            {favtList.map((favt) => (
              <WOFavoriteWorkList
                key={`favt-${favt.mnu_seq_no}`}
                list={
                  favt.mnu_lvl === 1
                    ? [
                        { id: 2, title: favt.higrk_mnu_nm, path: favt.scrn_url },
                        { id: 3, title: favt.mnu_nm, path: favt.scrn_url },
                      ]
                    : [
                        { id: 1, title: favt.sys_nm, path: favt.scrn_url },
                        { id: 2, title: favt.higrk_mnu_nm, path: favt.scrn_url },
                        { id: 3, title: favt.mnu_nm, path: favt.scrn_url },
                      ]
                }
                checked={true}
                onChange={() => {
                  handleToggleFavt(false, favt);
                }}
              />
            ))}
          </div>
          <BtnWrap>
            <div className="left-box"></div>
            <div className="right-box">
              <WOButton
                size="md"
                variant="primary"
                endIcon={<WOIcon width={16} height={16} icon="plus-white" />}
                onClick={() => setSelectedMainTab('tab2')}>
                자주 쓰는 업무 추가
              </WOButton>
            </div>
          </BtnWrap>
        </FavoriteWorkArea>
      </WOTabContent>
      {/* 업무 선택 */}
      <WOTabContent id={'tab2'} activeTabId={selectedMainTab}>
        {/* 메뉴 탭 영역 */}
        <WOMenuTabs onChange={handleTabChange} tabs={tabs} selectedTabId={selectedTab} />
        {/* 업무 선택 영역 */}
        <WorkSelectArea>
          {/* 업무 선택 */}
          <WorkSelectWrap>
            <WorkSelectBox>
              <WorkSelect>
                <TitleWrap>
                  <div className="tit">업무 상세 선택</div>
                </TitleWrap>
                <SelectList>
                  {workDetailList
                    .filter((menu) => menu.sys_seq_no === selectedTab)
                    .filter((menu) => menu.mnu_lvl === 1)
                    .filter((menu) => menu.mnu_aut_cd !== 'X')
                    .filter((menu) => (filterMenuName.length > 0 ? menu.mnu_nm.includes(filterMenuName) : true))
                    .map((lv1Menu) => {
                      const lv2MenuList = workDetailList
                        .filter((menu) => menu.mnu_lvl === 2)
                        .filter((menu) => menu.mnu_aut_cd !== 'X')
                        .filter((menu) => menu.higrk_mnu_seq_no === lv1Menu.mnu_seq_no)
                        .filter((menu) => (filterMenuName.length > 0 ? menu.mnu_nm.includes(filterMenuName) : true));
                      return (
                        <WOWorkMenuItem
                          key={lv1Menu.mnu_seq_no}
                          id={lv1Menu.mnu_seq_no}
                          title={lv1Menu.mnu_nm}
                          hasChildren={lv2MenuList.length > 0}
                          value={checkedWorkList.includes(lv1Menu.mnu_seq_no)}
                          onChange={handleCheckWork}>
                          {lv2MenuList.map((lv2Menu) => {
                            let type = 'depth';
                            if (lv2Menu.frst_tab && lv2Menu.pgm_pth.includes(lv2Menu.frst_tab)) {
                              type = 'firstTab';
                            } else if (lv2Menu.frst_tab) {
                              type = 'tab';
                            }
                            return (
                              <WOWorkMenuItem
                                key={`menulv2-${lv2Menu.mnu_seq_no}`}
                                id={lv2Menu.mnu_seq_no}
                                title={lv2Menu.mnu_nm}
                                type={type}
                                firstTab={lv2Menu.frst_tab || ''}
                                value={checkedWorkList.includes(lv2Menu.mnu_seq_no)}
                                onChange={handleCheckWork}
                              />
                            );
                          })}
                        </WOWorkMenuItem>
                      );
                    })}
                </SelectList>
              </WorkSelect>
              {user?.id === 1 && (
                <AreaSelect>
                  <TitleWrap>
                    <div className="tit">기관 선택</div>
                  </TitleWrap>
                  <SelectList $area={true}>
                    {istInfoList.map((istInfo) => (
                      <WOAreaMenuItem
                        key={istInfo.code}
                        title={istInfo.codenm}
                        hasChildren={false}
                        selected={istInfo.code === selectedIstInfo?.code}
                        onChange={() => handleIstInfoSelect(istInfo)}
                      />
                    ))}
                  </SelectList>
                </AreaSelect>
              )}
            </WorkSelectBox>
            <BtnWrap>
              <div className="left-box">
                <WOIconButton width={18} height={18} icon="refresh" onClick={() => setCheckedWorkList([])} />
                <div className="txt">
                  총 <span>{lpad(checkedWorkList.length, 2, '0')}</span>건
                </div>
              </div>
              <div className="right-box">
                <WOButton
                  variant="outlined"
                  size="md"
                  color="#3284FF"
                  endIcon={<WOIcon width={16} height={16} icon="arr-blue-16" />}
                  onClick={handleSelectWork}>
                  업무 선택
                </WOButton>
              </div>
            </BtnWrap>
          </WorkSelectWrap>
          {/* 선택된 업무 */}
          <WorkSelectedWrap>
            <Container>
              <TopArea>
                <div className="title-wrap">
                  <div className="tit">선택된 업무</div>
                  <div className="num">
                    총 <span>{lpad(selectedWorkList.length, 2, '0')}</span>건
                  </div>
                </div>
                <div className="list-wrap">
                  <TopNotice>
                    <WOIcon icon="star" width={16} height={16} /> 을 선택해 자주 쓰는 업무를 추가해주세요.
                  </TopNotice>
                  {selectedWorkList.map((selectedWork, index) => (
                    <WOSelectedWorkList
                      key={`work-${index}`}
                      workPath={[
                        { id: 1, title: selectedWork.sys_nm, path: selectedWork.scrn_url, istNo: selectedWork.ist_no },
                        {
                          id: 2,
                          title: selectedWork.higrk_mnu_nm,
                          path: selectedWork.scrn_url,
                          istNo: selectedWork.ist_no,
                        },
                        { id: 3, title: selectedWork.mnu_nm, path: selectedWork.scrn_url, istNo: selectedWork.ist_no },
                      ]}
                      checked={favtList.map((favt) => favt.mnu_seq_no).includes(selectedWork.mnu_seq_no)}
                      readOnly={true}
                      onToggle={(checked) => handleToggleFavt(checked, selectedWork)}
                      onClose={() => handleRemoveSelectWork(selectedWork.mnu_seq_no)}
                      istName={selectedWork.ist_nm}
                    />
                  ))}
                </div>
              </TopArea>
              <ButtonArea>
                <WOIconButton width={18} height={18} icon="refresh" onClick={() => dispatch(setSelectedWorkList([]))} />
                <WOButton variant={'primary'} size={'md'} width={'100%'} onClick={handleDoWork}>
                  업무 조회
                </WOButton>
              </ButtonArea>
            </Container>
          </WorkSelectedWrap>
        </WorkSelectArea>
      </WOTabContent>
    </Wrapper>
  );
};

export default WOMainSelectWorkArea;

const Wrapper = styled.div`
  min-width: 990px;
  width: 990px;
`;

const TabArea = styled.div`
  position: relative;
  display: flex;
  align-items: center;
  margin-bottom: 16px;
  .tooltip {
    width: 157px;
    height: 28px;
    margin-left: 12px;
    margin-bottom: 11px;
    background: url(/assets/images/etc/main-select-tooltip.svg) no-repeat center / cover;
  }
  .search-form {
    margin-left: auto;
    position: absolute;
    top: 0px;
    right: 0;
    z-index: 100;
  }
`;

// 자주 쓰는 업무

const FavoriteWorkArea = styled.div`
  height: 611px;
  margin-top: -17px;
  .list-wrap {
    padding-top: 12px;
    height: 570px;
    overflow-y: auto;
    border-top: 1px solid var(--C-Dark-05);
  }
`;

// 업무 선택
const WorkSelectArea = styled.div`
  display: flex;
  justify-content: space-between;
  gap: 0 40px;
  height: 533px;
  margin-top: 40px;
`;

const WorkSelectWrap = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
  padding: 6px 0 11px;
`;
const WorkSelectBox = styled.div`
  display: flex;
  gap: 0 32px;
  height: 100%;
`;

const WorkSelect = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
`;

const AreaSelect = styled.div`
  display: flex;
  flex-direction: column;
  flex: 0 0 auto;
  width: 246px;
`;

const SelectList = styled.div`
  height: 100%;
  border-top: 1px solid var(--C-Dark-05);
  overflow-y: auto;
  max-height: 405px;
  ${(props) =>
    props.$area &&
    `
    position:relative
  `}
`;

const TitleWrap = styled.div`
  flex: 0 0 auto;
  display: flex;
  align-items: center;
  justify-content: space-between;
  height: 54px;
  padding: 0 16px;
  .tit {
    color: #333;
    font-size: 16px;
    font-weight: 700;
    line-height: 24px;
  }
  .search {
  }
`;

const Container = styled.div`
  display: flex;
  height: 533px;
  flex-direction: column;
  justify-content: space-between;
`;

const TopArea = styled.div`
  display: flex;
  flex-direction: column;
  gap: 10px 0;
`;

const ButtonArea = styled.div`
  display: flex;
  padding: 12px 20px 23px 20px;
  gap: 0 12px;
  align-items: center;
`;

const BtnWrap = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 12px 16px;
  border-top: 1px solid var(--C-Dark-05);
  .left-box {
    display: flex;
    align-items: center;
    gap: 0 8px;
    .txt {
      color: #333;
      font-size: 14px;
      font-weight: 400;
      line-height: 21px;
    }
  }
  .right-box {
    display: flex;
    align-items: center;
  }
`;

const WorkSelectedWrap = styled.div`
  position: relative;
  flex: 0 0 auto;
  width: 296px;
  border-radius: 16px;
  background: #fff;
  box-shadow: 0px 1px 4px 0px rgba(0, 0, 0, 0.15);
  border: 1px solid transparent;
  background-image: linear-gradient(#fff, #fff), var(--Grad-BG-02);
  background-origin: border-box;
  background-clip: content-box, border-box;
  .title-wrap {
    display: flex;
    justify-content: space-between;
    padding: 20px 24px 0;
    .tit {
      color: #333;
      font-size: 16px;
      font-weight: 700;
      line-height: 24px;
    }
    .num {
      margin-top: auto;
      color: #333;
      font-size: 14px;
      font-weight: 400;
      line-height: 21px;
    }
  }
  .list-wrap {
    max-height: 402px;
    overflow-y: auto;
  }
  &::before {
    content: '';
    position: absolute;
    left: -12px;
    top: 50%;
    transform: translateY(-50%);
    width: 24px;
    height: 24px;
    background: url(./assets/images/icon/main-selected-work-icon.svg) no-repeat center / cover;
  }
`;

const TopNotice = styled.div`
  display: flex;
  align-items: center;
  height: 32px;
  font-size: 12px;
  font-weight: 500;
  color: #566479;
  padding: 0 20px 0 24px;
  background: #f5f6fa;

  .icon {
    margin-right: 2px;
  }
`;
