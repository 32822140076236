import instance from '../../common/config/api';

/**
 * 결재 건수 조회
 * @param {object} params
 * @returns
 */
export const fetchAppvCnt = () =>
  instance.post(`${process.env.REACT_APP_SERVER_URL}/scq/scq/cmm/user/selectAppvCnt`, {});

/**
 * 문서 건수 조회
 * @param {object} params
 * @returns
 */
export const fetchPapsCnt = () =>
  instance.post(`${process.env.REACT_APP_SERVER_URL}/scq/scq/cmm/user/selectPapsCnt`, {});
