import { SUCCESS, REQUEST, FAILURE } from '../../../common/utils/action-type-utils';
import { fetchZpbPbinAcctChrgNoRgsList, saveZpbPbinAcctChrgNoRgsList } from './../../../lib/zpb/zpb0203v01.api';
import dayjs from 'dayjs';
import { fetchCommonComboList } from '../../../lib/cmm/combo.api';

// actions
const FETCH_PBIN_ACCT_CHRG_NO_RGS = 'zpb0203v01/FETCH_PBIN_ACCT_CHRG_NO_RGS';
const SAVE_PBIN_ACCT_CHRG_NO_RGS = 'zpb0203v01/SAVE_PBIN_ACCT_CHRG_NO_RGS';
const FETCH_IST_INFO_LIST = 'zpb0203v01/FETCH_IST_INFO_LIST';
const RESET_STATE = 'zpb0202v01/RESET_STATE';
const SAVE_SEARCH_FORM_VALUES = 'zpb0202v01/SAVE_SEARCH_FORM_VALUES';
const FETCH_DEPT_NM_LIST = 'zpb0203v01/FETCH_DEPT_NM_LIST';
const FETCH_IST_INFO_LIST_005 = 'zpb0201v01/FETCH_IST_INFO_LIST_005';
export const initFormValues = {
  biz_no: '', // 기관명
  // API는 작업중
  bzno: '', // 사업자번호
  upche_gbn_cd: '5', // 사용자구분
};

// initial state
const initialState = {
  loading: false,
  success: false,
  message: '',
  list: [],
  totalCount: 0,
  updateSuccess: false,
  resultCnt: -1,
  bznmList: [],
  bznmLoading: false,
  bznmSuccess: true,
  deptNmList: [],
  deptNmLoading: false,
  deptNmSuccess: false,
  resultMessage: '',
  bznmList005: [],
  bznmLoading005: false,
  bznmSuccess005: true,
  searchFormValues: {
    init: true,
    ...initFormValues,
  },
};

// reducers
export default function zpb0203v01(state = initialState, action) {
  switch (action.type) {
    case SUCCESS(FETCH_PBIN_ACCT_CHRG_NO_RGS): // 조회 성공
      return {
        ...state,
        success: true,
        loading: false,
        list: action.payload.data?.subDto,
        totalCount: action.payload.data?.subDtoCnt,
        updateSuccess: false,
      };
    case REQUEST(FETCH_PBIN_ACCT_CHRG_NO_RGS): // 조회 요청
      return {
        ...state,
        loading: true,
        success: false,
        updateSuccess: false,
      };
    case FAILURE(FETCH_PBIN_ACCT_CHRG_NO_RGS): // 조회실패
      return {
        ...initialState,
        loading: false,
        success: false,
        message: action.payload.response?.data?.message,
        updateSuccess: false,
      };
    case SUCCESS(SAVE_PBIN_ACCT_CHRG_NO_RGS): // 저장 성공
      return {
        ...state,
        updateSuccess: true,
        loading: false,
        resultCnt: action.payload.data.resultCnt,
        resultMessage: action.payload.data,
      };
    case REQUEST(SAVE_PBIN_ACCT_CHRG_NO_RGS): // 저장 요청
      return {
        ...state,
        message: '',
        loading: true,
        updateSuccess: false,
      };
    case FAILURE(SAVE_PBIN_ACCT_CHRG_NO_RGS): // 저장 실패
      return {
        ...initialState,
        loading: false,
        updateSuccess: false,
        resultCnt: -1,
        message: action.payload.response?.data?.message,
        resultMessage: '',
      };
    case REQUEST(FETCH_IST_INFO_LIST): // 조회 요청
      return {
        ...state,
        bznmLoading: true,
        bznmSuccess: false,
      };
    case SUCCESS(FETCH_IST_INFO_LIST): // 조회 성공
      return {
        ...state,
        bznmSuccess: true,
        bznmLoading: false,
        bznmList: action.payload.data?.subDto,
      };
    case FAILURE(FETCH_IST_INFO_LIST): // 조회실패
      return {
        bznmLoading: false,
        bznmSuccess: false,
        bznmList: [],
      };
    case REQUEST(FETCH_DEPT_NM_LIST): // 조회 요청
      return {
        ...state,
        deptNmLoading: true,
        deptNmSuccess: false,
      };
    case SUCCESS(FETCH_DEPT_NM_LIST): // 조회 성공
      return {
        ...state,
        deptNmSuccess: true,
        deptNmLoading: false,
        deptNmList: action.payload.data?.subDto,
      };
    case FAILURE(FETCH_DEPT_NM_LIST): // 조회실패
      return {
        deptNmLoading: false,
        deptNmSuccess: false,
        deptNmList: [],
      };
    case SAVE_SEARCH_FORM_VALUES:
      return {
        ...state,
        // 콤보 - 기관
        bznmSuccess: false,
        bznmLoading: false,
        deptNmSuccess: false,
        deptNmLoading: false,
        searchFormValues: action.payload,
      };
    case SUCCESS(FETCH_IST_INFO_LIST_005): // 조회 성공
      return {
        ...state,
        bznmSuccess005: true,
        bznmLoading005: false,
        bznmList005: action.payload.data?.subDto,
      };
    case REQUEST(FETCH_IST_INFO_LIST_005): // 조회 요청
      return {
        ...state,
        bznmLoading005: true,
        bznmSuccess005: false,
      };
    case FAILURE(FETCH_IST_INFO_LIST_005): // 조회실패
      return {
        bznmLoading005: false,
        bznmSuccess005: false,
        bznmList005: [],
      };
    case RESET_STATE:
      return initialState;
    default:
      return state;
  }
}

/**
 * 제로페이 - 공공기관 회계담당 번호등록
 */
export const selectPbinAcctChrgNoRgsList = (searchParams) => {
  return {
    type: FETCH_PBIN_ACCT_CHRG_NO_RGS,
    payload: fetchZpbPbinAcctChrgNoRgsList(searchParams),
  };
};

/**
 * 제로페이 - 공공기관 회계담당 번호등록 - 등록/수정/삭제
 */
export const savePbinAcctChrgNoRgsList = (dtos) => {
  return {
    type: SAVE_PBIN_ACCT_CHRG_NO_RGS,
    payload: saveZpbPbinAcctChrgNoRgsList(dtos),
  };
};

/**
 * 기관명 콤보 조회
 */
export const selectIstInfoComboList = (searchParams) => {
  return {
    type: FETCH_IST_INFO_LIST,
    payload: fetchCommonComboList({ slt_box_no: '901', ...searchParams }),
  };
};

/**
 * 부서명 콤보 조회
 */
export const selectDeptNmComboList = (searchParams) => {
  return {
    type: FETCH_DEPT_NM_LIST,
    payload: fetchCommonComboList({ slt_box_no: '902', ...searchParams }),
  };
};

/**
 * 기관명 005콤보 조회
 */
export const selectIstInfoComboList005 = (searchParams) => {
  return {
    type: FETCH_IST_INFO_LIST_005,
    payload: fetchCommonComboList({ slt_box_no: '005', ...searchParams }),
  };
};

/**
 * 조회조건 저장
 */
export const saveSearchFormValues = (searchFormValues) => {
  return {
    type: SAVE_SEARCH_FORM_VALUES,
    payload: {
      ...searchFormValues,
      init: false,
    },
  };
};
/**
 * 초기화
 */
export const resetZpb0203v01 = () => {
  return {
    type: RESET_STATE,
  };
};
