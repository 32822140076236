import { fetchDbSysdate, fetchListGndpActInf, fetchGndpActTrnPrts, fetchTrhs } from '../../../lib/etc/ETC0002.api';
import dayjs from 'dayjs';
import { SUCCESS, REQUEST, FAILURE } from '../../../common/utils/action-type-utils';

// actions
const FETCH_DB_SYSDATE = 'ETC0201V01/FETCH_DB_SYSDATE';
const FETCH_FETCH_LIST_GNDP_ACT_INF = 'ETC0201V01/FETCH_FETCH_LIST_GNDP_ACT_INF';
const FETCH_GNDP_ACT_TRN_PRTS = 'ETC0201V01/FETCH_GNDP_ACT_TRN_PRTS';
const FETCH_TRHS = 'ETC0201V01/FETCH_TRHS';
const RESET_STATE = 'ETC0201V01/RESET_STATE';
const SAVE_SEARCH_FORM_VALUES = 'ETC0201V01/SAVE_SEARCH_FORM_VALUES';

export const initFormValues = {
  ist_cd: '000',
  act_no: '1006001367380',
  ydcs8a1_t8a12_inq_sdt: dayjs(),
  ydcs8a1_t8a12_inq_edt: dayjs(),
  ydcs8a1_t8a12_trn_inq_mtd: '2',
  ydcs8a1_t8a12_rcvpy_dscd: '3',
};
// initial state
const initialState = {
  loading: false,
  success: true,
  message: '',
  list: [],

  dbSysdate: {},
  dbSysdateLoading: false,
  dbSysdateSuccess: true,

  gndpActInf: [],
  gndpActInfLoading: false,
  gndpActInfSuccess: true,
  gndpActInfMessage: '',

  gndpActTrnPrts: [],
  gndpActTrnPrtsLoading: false,
  gndpActTrnPrtsSuccess: true,
  gndpActTrnPrtsMessage: '',

  trhs: [],
  trhsLoading: false,
  trhsSuccess: true,
  trhsMessage: '',

  actOgbrMnbrNm: '',
  totalCount: 0,

  istInfoList: [
    {
      code: '000',
      codenm: '광명시청',
      br_cd: '084383',
      cus_ist_cd: '6110000',
      order: '01',
    },
  ],
  actNoList: [
    {
      code: '1006001367380',
      codenm: '1006-001-367380 (1618-06700-000)',
    },
    {
      code: '1006101367325',
      codenm: '1006-101-367325 (1618-08888-000)',
    },
    {
      code: '1006301367381',
      codenm: '1006-301-367381 (1618-06701-000)',
    },
    {
      code: '1006801367524',
      codenm: '1006-801-367524 (1618-02222-000)',
    },
    {
      code: '1006201367396',
      codenm: '1006-201-367396 (1618-03333-000)',
    },
    {
      code: '1006601367382',
      codenm: '1006-601-367382 (1618-06702-000)',
    },
  ],
  rcvpyDscdOptions: [
    {
      code: '3',
      codenm: '입지급',
    },
    {
      code: '1',
      codenm: '입금',
    },
    {
      code: '2',
      codenm: '지급',
    },
  ],
  trnInqMtdOptions: [
    {
      code: '2',
      codenm: '최근 거래내역 순',
    },
    {
      code: '1',
      codenm: '과거 거래내역 순',
    },
  ],
  searchFormValues: {
    init: true,
    ...initFormValues,
  },
};

// reducers
export default function ETC0201V01(state = initialState, action) {
  switch (action.type) {
    case SUCCESS(FETCH_DB_SYSDATE): // 조회 성공
      return {
        ...state,
        dbSysdateSuccess: true,
        dbSysdateLoading: false,
        dbSysdate: action.payload.data?.output_sysdate,
      };
    case REQUEST(FETCH_DB_SYSDATE): // 조회 요청
      return {
        ...state,
        dbSysdateLoading: true,
      };
    case FAILURE(FETCH_DB_SYSDATE): // 조회실패
      return {
        ...state,
        dbSysdateLoading: false,
        dbSysdateSuccess: false,
      };
    case SUCCESS(FETCH_FETCH_LIST_GNDP_ACT_INF): // 조회 성공
      return {
        ...state,
        gndpActInfSuccess: true,
        gndpActInfLoading: false,
        gndpActInf: action.payload.data?.output_data,
      };
    case REQUEST(FETCH_FETCH_LIST_GNDP_ACT_INF): // 조회 요청
      return {
        ...state,
        gndpActInfLoading: true,
      };
    case FAILURE(FETCH_FETCH_LIST_GNDP_ACT_INF): // 조회실패
      return {
        ...state,
        gndpActInfLoading: false,
        gndpActInfSuccess: false,
        gndpActInfMessage: action.payload.response?.data?.message,
      };
    case SUCCESS(FETCH_GNDP_ACT_TRN_PRTS): // 조회 성공
      return {
        ...state,
        gndpActTrnPrtsSuccess: true,
        gndpActTrnPrtsLoading: false,
        gndpActTrnPrts: action.payload.data?.output_data,
      };
    case REQUEST(FETCH_GNDP_ACT_TRN_PRTS): // 조회 요청
      return {
        ...state,
        gndpActTrnPrtsLoading: true,
      };
    case FAILURE(FETCH_GNDP_ACT_TRN_PRTS): // 조회실패
      return {
        ...state,
        gndpActTrnPrtsLoading: false,
        gndpActTrnPrtsSuccess: false,
        gndpActTrnPrtsMessage: action.payload.response?.data?.message,
      };
    case SUCCESS(FETCH_TRHS): // 조회 성공
      return {
        ...state,
        trhsSuccess: true,
        trhsLoading: false,
        trhs: action.payload.data?.output_data,
      };
    case REQUEST(FETCH_TRHS): // 조회 요청
      return {
        ...state,
        loading: true,
      };
    case FAILURE(FETCH_TRHS): // 조회실패
      return {
        ...state,
        loading: false,
        success: false,
        //message: action.payload.response?.data?.message,
      };
    case SAVE_SEARCH_FORM_VALUES:
      return {
        searchFormValues: action.payload,
      };
    case RESET_STATE:
      return initialState;
    default:
      return state;
  }
}

/**
 * DB 서버시간 조회
 */
export const selectDbSysdate = (searchParams) => {
  return {
    type: FETCH_DB_SYSDATE,
    payload: fetchDbSysdate(searchParams),
  };
};

/**
 * 보통예금 계좌정보조회
 */
export const selectListGndpActInf = (searchParams) => {
  return {
    type: FETCH_FETCH_LIST_GNDP_ACT_INF,
    payload: fetchListGndpActInf(searchParams),
  };
};

/**
 * 보통예금 계좌거래내역 조회
 */
export const selectGndpActTrnPrts = (searchParams) => {
  return {
    type: FETCH_GNDP_ACT_TRN_PRTS,
    payload: fetchGndpActTrnPrts(searchParams),
  };
};

/**
 * 거래명세 조회
 */
export const selectTrhs = (searchParams) => {
  return {
    type: FETCH_TRHS,
    payload: fetchTrhs(searchParams),
  };
};
/**
 * 조회조건 저장
 */
export const saveSearchFormValues = (searchFormValues) => {
  return {
    type: SAVE_SEARCH_FORM_VALUES,
    payload: {
      ...searchFormValues,
      init: false,
    },
  };
};

/**
 * 승인내역 상태초기화
 */
export const resetETC0201V01 = () => {
  return {
    type: RESET_STATE,
  };
};
