import instance from '../../common/config/api';

// const process = {
//   env: {
//     REACT_APP_SERVER_URL: 'http://tims.wooribank.com',
//   },
// };

/**
 * 로그인
 * @param {object} params
 * @returns
 */
export const login = (params) =>
  instance.post(`${process.env.REACT_APP_SERVER_URL}/scq/scq/cmm/session/loginPc`, params, {
    headers: { 'Content-Type': 'application/x-www-form-urlencoded' },
  });

/**
 * 로그인
 * @param {object} params
 * @returns
 */
export const loginForAdmin = (params) =>
  instance.post(`${process.env.REACT_APP_SERVER_URL}/scq/scq/cmm/session/login`, params);

/**
 * 로그아웃
 * @param {object} params
 * @returns
 */
export const logout = () =>
  instance.post(`${process.env.REACT_APP_SERVER_URL}/scq/scq/cmm/session/logout`, { mchrBycl: 'PC' });
