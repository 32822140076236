import { SUCCESS, REQUEST, FAILURE } from '../../../common/utils/action-type-utils';
import { fetchMenu } from '../../../lib/cmm/menu.api';

// actions
const FETCH_MENU = 'menuState/FETCH_MENU';
const RESET_MENU_LIST = 'menuState/RESET_MENU_LIST';

// initial state
const initialState = {
  loading: false,
  success: false,
  message: '',
  list: [],
  totalCount: 0,
};

// reducers
export default function menuState(state = initialState, action) {
  switch (action.type) {
    case SUCCESS(FETCH_MENU): // 조회 성공
      return {
        ...state,
        success: true,
        loading: false,
        list: action.payload.data?.g_menu_all,
        totalCount: action.payload?.data?.g_menu_all_cnt,
      };
    case REQUEST(FETCH_MENU): // 조회 요청
      return {
        ...state,
        loading: true,
        success: false,
        message: '',
      };
    case FAILURE(FETCH_MENU): // 조회실패
      return {
        ...initialState,
        loading: false,
        success: false,
        message: action.payload.response?.data?.message,
      };
    case RESET_MENU_LIST:
      return initialState;
    default:
      return state;
  }
}

/**
 * 메뉴조회
 */
export const selectMenuList = () => {
  return {
    type: FETCH_MENU,
    payload: fetchMenu(),
  };
};

/** 메뉴 초기화 */
export const resetMenuList = () => {
  return {
    type: RESET_MENU_LIST,
  };
};
