import { SUCCESS, REQUEST, FAILURE } from '../../../common/utils/action-type-utils';
import { fetchEtcSumrPrtsList } from '../../../lib/etc/etc0402v01';
import dayjs from 'dayjs';
import { fetchCommonComboList } from '../../../lib/cmm/combo.api';
// actions
const FETCH_SUMRPRTS = 'etcSumrPrts/FETCH_SUMRPRTS';
const RESET_STATE = 'etcSumrPrts/RESET_STATE';
const SAVE_SEARCH_FORM_VALUES = 'etcSumrPrts/SAVE_SEARCH_FORM_VALUES';
//기관
const FETCH_IST_INFO_LIST = 'etcSumrPrts/FETCH_IST_INFO_LIST';

export const initFormValues = {
  br_cd: 'all', // 기관구분
  gb: 'all', // 판매구분
  ydcs8a1_t8a01_inq_sdt: dayjs().add(-2, 'day').format('YYYY-MM-DD'), // 거래일자
  ydcs8a1_t8a01_inq_edt: dayjs().format('YYYY-MM-DD'), // 거래일자
};

// initial state
const initialState = {
  loading: false,
  success: false,
  message: '',
  list: [],
  totalCount: 0,
  //콤보 - 기관명
  istInfoList: [],
  istInfoLoading: false,
  istInfoSuccess: true,
  initFormValues: initFormValues,
  etc0402v01gbComboList: [
    { id: '1', label: '서울사랑상품권' },
    { id: '2', label: '정책금융상품권' },
    { id: '3', label: '대량법인서울사랑상품권' },
    { id: '4', label: '비플제로페이' },
  ],
  etc0402v01brCdComboList: [
    { id: '020689', label: '종로구청' },
    { id: '020661', label: '중구청' },
    { id: '020688', label: '용산구청' },
    { id: '020511', label: '성동구청' },
    { id: '020828', label: '중랑구청' },
    { id: '020691', label: '성북구청' },
    { id: '020815', label: '서대문구청' },
    { id: '020549', label: '마포구청' },
    { id: '020824', label: '양천구청' },
    { id: '020823', label: '강서구청' },
    { id: '020822', label: '영등포구청' },
    { id: '020821', label: '관악구청' },
    { id: '020774', label: '송파구청' },
    { id: '020379', label: '강동구청' },
    { id: '020853', label: '금천구청' },
    { id: '020812', label: '본청' },
  ],
  searchFormValues: {
    init: true,
    ...initFormValues,
  },
};

// reducers
export default function etcSumrPrts(state = initialState, action) {
  switch (action.type) {
    case SUCCESS(FETCH_SUMRPRTS): // 조회 성공
      return {
        ...state,
        success: true,
        loading: false,
        list: action.payload.data?.subDto,
        totalCount: action.payload?.subDtoCnt,
      };
    case REQUEST(FETCH_SUMRPRTS): // 조회 요청
      return {
        ...state,
        loading: true,
        success: false,
      };
    case FAILURE(FETCH_SUMRPRTS): // 조회실패
      return {
        ...initialState,
        loading: false,
        success: false,
        message: action.payload.response?.data?.message,
      };
    case SUCCESS(FETCH_IST_INFO_LIST): // 조회 성공
      return {
        ...state,
        istInfoSuccess: true,
        istInfoLoading: false,
        istInfoList: action.payload.data?.subDto,
      };
    case REQUEST(FETCH_IST_INFO_LIST): // 조회 요청
      return {
        ...state,
        istInfoLoading: true,
        istInfoSuccess: false,
      };
    case FAILURE(FETCH_IST_INFO_LIST): // 조회실패
      return {
        istInfoLoading: false,
        istInfoSuccess: false,
        istInfoList: [],
      };
    case SAVE_SEARCH_FORM_VALUES:
      return {
        ...state,
        // 콤보 - 기관
        istInfoSuccess: false,
        istInfoLoading: false,
        searchFormValues: action.payload,
      };
    case RESET_STATE:
      return initialState;
    default:
      return state;
  }
}

/**
 * 기타 - 서울페이플러스 - 집계내역조회
 */
export const selectEtcSumrPrtsList = (searchParams) => {
  return {
    type: FETCH_SUMRPRTS,
    payload: fetchEtcSumrPrtsList(searchParams),
  };
};

/**
 * 기관명 콤보 조회
 */
export const selectIstInfoComboList = (searchParams) => {
  return {
    type: FETCH_IST_INFO_LIST,
    payload: fetchCommonComboList({ slt_box_no: '005', ...searchParams }),
  };
};
/**
 * 조회조건 저장
 */
export const saveSearchFormValues = (searchFormValues) => {
  return {
    type: SAVE_SEARCH_FORM_VALUES,
    payload: {
      ...searchFormValues,
      init: false,
    },
  };
};

/**
 * 집계내역 상태초기화
 */
export const resetEtcSumrPrts = () => {
  return {
    type: RESET_STATE,
  };
};
