import instance from '../../common/config/api';

const prefix = `${process.env.REACT_APP_SERVER_URL}/scq/scq/sys/0001`;

/**
 * 산하기관 행정동 관리 조회
 * @param {object} params
 * @returns
 */
export const fetchUninIst = (params) => instance.post(`${prefix}/selectUninIst`, params);

/**
 * 산하기관 갱신
 * @param {array} dtos
 * @returns
 */
export const saveUninIst = (dtos) => instance.post(`${prefix}/insertUninIst`, { subDto: dtos });
