/** TODO: 하드코딩된거(공통코드로 변경필요) */

import dayjs from 'dayjs';

/**
 * 제로페이 - 결제현황조회 콤보 - 구분
 */

export const zpbStlPtsCategoryComboList = [
  {
    id: '1',
    label: '기간별',
    type: 'period',
  },
  {
    id: '2',
    label: '요일별',
    type: 'day',
  },
  {
    id: '3',
    label: '시간별',
    type: 'hour',
  },
  {
    id: '4',
    label: '금액별',
    type: 'amount',
  },
  {
    id: '5',
    label: '업종별',
    type: 'industry',
  },
  {
    id: '6',
    label: '기관/부서별',
    type: 'department',
  },
  {
    id: '7',
    label: '사용 지역별',
    type: 'region',
  },
];

/**
 * 제로페이 - 결제현황조회 콤보 - 사용자구분
 */

export const zpbUpcheGbnCdComboList = [
  { id: '2', label: '법인(개인사업자 포함)' },
  { id: '3', label: '지자체(시청, 구청 등)' },
  { id: '4', label: '보조금사업자' },
  { id: '5', label: '공공기관' },
];

/**
 * 제로페이 - 결제현황조회 콤보 - 사용자구분
 */

export const zpbStlPtsInqDisComboList = [
  {
    id: 'M',
    label: '월간',
    type: 'monthly',
  },
  {
    id: 'D',
    label: '일간',
    type: 'daily',
  },
  {
    id: 'Q',
    label: '분기간',
    type: 'quarterly',
  },
  {
    id: 'Y',
    label: '년간',
    type: 'yearly',
  },
];

/**
 * 제로페이(관리자) - 사용자조회 - 권한 및 수신여부 설정 라디오
 */

export const userBalInqYnRadioList = [
  {
    name: 'radio01',
    id: 1,
    label: 'Y',
    value: 'Y',
  },
  {
    name: 'radio01',
    id: 2,
    label: 'N',
    value: 'N',
  },
];

export const slfDpsYnRadioList = [
  {
    name: 'radio02',
    id: 3,
    label: 'Y',
    value: 'Y',
  },
  {
    name: 'radio02',
    id: 4,
    label: 'N',
    value: 'N',
  },
];

export const smsDpsYnRadioList = [
  {
    name: 'radio03',
    id: 5,
    label: 'Y',
    value: 'Y',
  },
  {
    name: 'radio03',
    id: 6,
    label: 'N',
    value: 'N',
  },
];
export const smsNonDpsYnRadioList = [
  {
    name: 'radio04',
    id: 7,
    label: 'Y',
    value: 'Y',
  },
  {
    name: 'radio04',
    id: 8,
    label: 'N',
    value: 'N',
  },
];

/**
 * 제로페이(관리자) - 공지함 팝업
 */

// OS유형 콤보
export const recpTgtDtlsList = [
  { id: 'APPM03001', label: '전체' },
  { id: 'APPM03002', label: 'Android' },
  { id: 'APPM03003', label: 'iOS' },
];

// 공지유형 콤보
export const bltnTycdList = [
  { id: '', label: '전체' },
  { id: 'APPM01001', label: '공지' },
  { id: 'APPM01002', label: '앱업데이트' },
  { id: 'APPM01003', label: '사용자구분별공지' },
];

// 수신대상 OS유형 콤보
export const recpTgtTycdList = [
  { id: 'APPM02001', label: '전체' },
  { id: 'APPM02002', label: 'OS별' },
];

// 수신대상세부 버전조건
export const recpTrmVerTycdList = [
  { id: 'APPM04001', label: '전체' },
  { id: 'APPM04002', label: '특정' },
  { id: 'APPM04003', label: '이상' },
  { id: 'APPM04004', label: '이하' },
];

// 종일알림여부
export const bltnAddayList = [
  {
    name: 'radio01',
    id: 1,
    label: 'Y',
    value: 'Y',
  },
  {
    name: 'radio01',
    id: 2,
    label: 'N',
    value: 'N',
  },
];

// Android & iOS APP 배포 버전 동일여부
export const appVerEquRadioList = [
  {
    name: 'radio02',
    id: 3,
    label: 'Y',
    value: 'Y',
  },
  {
    name: 'radio02',
    id: 4,
    label: 'N',
    value: 'N',
  },
];

// 게시여부
export const bltnRadioList = [
  {
    name: 'radio03',
    id: 5,
    label: 'Y',
    value: 'Y',
  },
  {
    name: 'radio03',
    id: 6,
    label: 'N',
    value: 'N',
  },
];

const createTimeComboList = () => {
  const max = 25;
  const times = [];
  for (let i = 0; i < max; i++) {
    const time = i >= 10 ? i : `0${i}`;
    const label = `${time}시`;
    times.push({ id: String(time), label: String(label) });
  }
  return times;
};

export const bltnTm = createTimeComboList();

/**
 * 기타 - 보조금사업 계좌수 및 예산점유율
 */

// 조회구분
export const etcAsstBznAccnBgtBssnRtGubun2ComboList = [
  {
    id: '0',
    label: '전체',
  },
  {
    id: '30702',
    label: '[30702]민간경상사업보조',
  },
  {
    id: '30703',
    label: '[30703]민간단체법정운영비보조',
  },
  {
    id: '30704',
    label: '[30704]민간행사사업보조',
  },
  {
    id: '30705',
    label: '[30705]민간위탁금',
  },
  {
    id: '40201',
    label: '[40201]민간자본보조',
  },
  {
    id: '40202',
    label: '[40202]민간대행사업비',
  },
];

export const etcAsstBznAccnBgtBssnRtGubun3ComboList = [
  {
    id: '9',
    label: '전체',
  },
  {
    id: '1',
    label: '상반기',
  },
  {
    id: '2',
    label: '하반기',
  },
];

const createYearComboList = (minYear) => {
  let years = [];
  const currentYear = dayjs().year();
  for (let year = currentYear; year >= minYear; year--) {
    years.push({ id: String(year), label: String(year) });
  }
  return years;
};

export const etcAsstBznAccnBgtBssnRtYearComboList = createYearComboList(2015);

// 기간
export const etcAsstBznAccnBgtBssnRtGubunComboList = [
  { id: '1', label: '월별' },
  { id: '2', label: '년/반기별' },
  { id: '3', label: '일별' },
];

// 기타 - 공금계좌 집계조회
export const etc0202v01actNoComboList = [
  { id: '161801111000', label: '1618-01111-000 ( 상수도특별회계 )' },
  { id: '161802222000', label: '1618-02222-000 ( 하수도특별회계 )' },
  { id: '161803333000', label: '1618-03333-000 ( 하수도원인자부담금 )' },
  { id: '161803888000', label: '1618-03888-000 ( 상수도(정수과) )' },
  { id: '161804444000', label: '1618-04444-000 ( 물이용부담금 )' },
  { id: '161805555000', label: '1618-05555-000 ( 상수도특별회계 )' },
  { id: '161806000000', label: '1618-06000-000 ( 하수도원인자부담금 )' },
  { id: '161806666000', label: '1618-06666-000 ( 하수도특별회계 )' },
  { id: '161806700000', label: '1618-06700-000 ( 상수도세입세출외현금 )' },
  { id: '161806701000', label: '1618-06701-000 ( 정수과세입세출외현금 )' },
  { id: '161806702000', label: '1618-06702-000 ( 하수도세입세출외현금 )' },
  { id: '161807777000', label: '1618-07777-000 ( 물이용부담금 )' },
  { id: '161808888000', label: '1618-08888-000 ( 상수도(정수과) )' },
  // 테스트 데이터 추가
  // { id: '1618055304126', label: '1618-055304-126(테스트 데이터1)' },
  // { id: '1618038387248', label: '1618-038387-248(테스트 데이터2)' },
  { id: '1618011110003', label: '1618-011110-003(테스트 계좌번호)' },
];

// 기타 - 서울페이플러스 - 집계내역조회
export const etc0402v01brCdComboList = [
  { id: '020689', label: '종로구청' },
  { id: '020661', label: '중구청' },
  { id: '020688', label: '용산구청' },
  { id: '020511', label: '성동구청' },
  { id: '020828', label: '중랑구청' },
  { id: '020691', label: '성북구청' },
  { id: '020815', label: '서대문구청' },
  { id: '020549', label: '마포구청' },
  { id: '020824', label: '양천구청' },
  { id: '020823', label: '강서구청' },
  { id: '020822', label: '영등포구청' },
  { id: '020821', label: '관악구청' },
  { id: '020774', label: '송파구청' },
  { id: '020379', label: '강동구청' },
  { id: '020853', label: '금천구청' },
  { id: '020812', label: '본청' },
];

export const etc0402v01gbComboList = [
  { id: '1', label: '서울사랑상품권' },
  { id: '2', label: '정책금융상품권' },
  { id: '3', label: '대량법인서울사랑상품권' },
  { id: '4', label: '비플제로페이' },
];

// 기타 - 서울페이플러스 - 계좌거래명세
export const etc0403v01brCdComboList = [
  { id: '020689', label: '종로구청', cusIstCd: '3000000' },
  { id: '020661', label: '중구청', cusIstCd: '3010000' },
  { id: '020688', label: '용산구청', cusIstCd: '3020000' },
  { id: '020828', label: '중랑구청', cusIstCd: '3060000' },
  { id: '020691', label: '성북구청', cusIstCd: '3070000' },
  { id: '020815', label: '서대문구청', cusIstCd: '3120000' },
  { id: '020549', label: '마포구청', cusIstCd: '3130000' },
  { id: '020824', label: '양천구청', cusIstCd: '3140000' },
  { id: '020823', label: '강서구청', cusIstCd: '3150000' },
  { id: '020822', label: '영등포구청', cusIstCd: '3180000' },
  { id: '020821', label: '관악구청', cusIstCd: '3200000' },
  { id: '020774', label: '송파구청', cusIstCd: '3230000' },
  { id: '020379', label: '강동구청', cusIstCd: '3240000' },
  { id: '020853', label: '금천구청', cusIstCd: '3170000' },
  { id: '020812', label: '시청', cusIstCd: '6110000' },
];

// 기타 - 손해배상금 - 집계표 (라디오)
export const etc0501v01RadioList = [
  {
    id: '1',
    label: '당행지점별',
    name: 'radio01',
    value: '당행지점별',
  },
  {
    id: '2',
    label: '수납기관',
    name: 'radio01',
    value: '수납기관',
  },
  {
    id: '3',
    label: '징수기관',
    name: 'radio01',
    value: '징수기관',
  },
  {
    id: '4',
    label: '년간',
    name: 'radio01',
    value: '년간',
  },
];

// 기타 - 손해배상금 - 집계표 (구분1)
export const gbn1List = [
  { id: '1', label: '건수집계' },
  { id: '2', label: '금액집계' },
];

// 기타 - 손해배상금 - 집계표 (구분2)
export const ocrynList = [
  { id: 'all', label: '전체' },
  { id: 'O', label: 'OCR' },
  { id: 'R', label: '비OCR' },
];

// 기타 - 손해배상금 - 집계표 (출력구분)
export const gbList = [
  { id: '1', label: '상세' },
  { id: '2', label: '집계' },
];

// 금고영업점

export const brc0207brCdComboList = [
  { id: '020812', label: '서울시청금융센터' },
  { id: '020825', label: '영동금융센터 ' },
  { id: '020379', label: '강동구청지점' },
  { id: '020823', label: '강서구청지점' },
  { id: '020821', label: '관악구청지점' },
  { id: '020818', label: '구로구청지점' },
  { id: '020853', label: '금천구청지점' },
  { id: '020683', label: '도봉구청지점' },
  { id: '020692', label: '청량리중앙금융센터' },
  { id: '020819', label: '노량진금융센터 ' },
  { id: '020549', label: '마포구청지점' },
  { id: '020815', label: '서대문구청지점' },
  { id: '020691', label: '성북구청지점' },
  { id: '020774', label: '송파구청지점' },
  { id: '020824', label: '양천구청지점' },
  { id: '020822', label: '영등포구청지점' },
  { id: '020796', label: '응암동금융센터 ' },
  { id: '020689', label: '종로구청지점' },
  { id: '020661', label: '중구청지점' },
  { id: '020828', label: '중랑구청지점' },
  { id: '020813', label: '수유동금융센터' },
  { id: '020827', label: '광진구청지점' },
  { id: '020547', label: '노원구청지점' },
  { id: '020511', label: '성동구청지점' },
  { id: '020688', label: '용산구청지점' },
  { id: '999999', label: '기타영업점' },
];
