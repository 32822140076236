import instance from '../../common/config/api';

/**
 * 기타 - 서울페이플러스 - 집계내역조회
 * @param {object} params
 * @returns
 */

export const fetchEtcSumrPrtsList = (params) =>
  instance.post(`${process.env.REACT_APP_SERVER_URL}/scq/scq/etc/0004/selectEtcSumrPrts`, params);
