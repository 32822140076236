import { SUCCESS, REQUEST, FAILURE } from '../../../common/utils/action-type-utils';
import { deleteCmmFileList, fetchCmmFileList, uploadCmmFileList } from '../../../lib/cmm/file.api';

// actions
const FETCH_CMM_FILE_LIST = 'cmmFileList/FETCH_CMM_FILE_LIST';
const UPLOAD_CMM_FILE_LIST = 'cmmFileList/UPLOAD_CMM_FILE_LIST';
const DELETE_CMM_FILE_LIST = 'cmmFileList/DELETE_CMM_FILE_LIST';
const CHANGE_CMM_FILE_SIZE = 'cmmFileList/CHANGE_CMM_FILE_SIZE';
const RESET_CMM_FILE_LIST = 'cmmFileList/RESET_CMM_FILE_LIST';
const RESET_MESSAGE = 'cmmFileList/RESET_MESSAGE';

// initial state
const initialState = {
  loading: false,
  success: false,
  message: '',
  fileList: [],
  fileCount: 0,
  fileUploadedSize: 0,
  uploading: false,
  uploadSuccess: false,
  uploadMessage: '',
};

// reducers
export default function cmmFileList(state = initialState, action) {
  switch (action.type) {
    case SUCCESS(FETCH_CMM_FILE_LIST): // 조회 성공
      return {
        ...state,
        success: true,
        loading: false,
        fileList: action.payload.data?.file,
        fileCount: action.payload.data?.file_cnt,
      };
    case REQUEST(FETCH_CMM_FILE_LIST): // 조회 요청
      return {
        ...state,
        loading: true,
        success: false,
        message: '',
      };
    case FAILURE(FETCH_CMM_FILE_LIST): // 조회 실패
      return {
        ...initialState,
        loading: false,
        success: false,
        fileList: [],
        fileCount: 0,
        message: action.payload.response?.data?.message,
      };
    case SUCCESS(DELETE_CMM_FILE_LIST): // 삭제 성공
    case SUCCESS(UPLOAD_CMM_FILE_LIST): // 업로드 성공
      return {
        ...state,
        uploading: false,
        uploadSuccess: true,
      };
    case REQUEST(DELETE_CMM_FILE_LIST): // 삭제 요청
    case REQUEST(UPLOAD_CMM_FILE_LIST): // 업로드 요청
      return {
        ...state,
        uploading: true,
        uploadMessage: '',
        uploadSuccess: false,
        fileUploadedSize: 0,
      };
    case FAILURE(DELETE_CMM_FILE_LIST): // 삭제 실패
    case FAILURE(UPLOAD_CMM_FILE_LIST): // 업로드 실패
      return {
        ...state,
        uploading: false,
        uploadSuccess: false,
        fileUploadedSize: 0,
        uploadMessage: action.payload.response?.data?.message,
      };
    case CHANGE_CMM_FILE_SIZE:
      return {
        ...state,
        fileUploadedSize: action.payload,
      };
    case RESET_CMM_FILE_LIST:
      return initialState;
    case RESET_MESSAGE:
      return {
        ...state,
        uploadMessage: '',
        uploadSuccess: false,
        uploading: false,
      };
    default:
      return state;
  }
}

/**
 * 공통 - 파일 목록 조회
 */
export const selectCmmFileList = (searchParams) => {
  return {
    type: FETCH_CMM_FILE_LIST,
    payload: fetchCmmFileList(searchParams),
  };
};

/**
 * 공통 - 파일 멀티 업로드
 */
export const uploadCmmFiles = (formData) => async (dispatch) => {
  const onUploadProgress = (progressEvent) => {
    dispatch(changeUploadedFileSize(progressEvent.loaded));
  };

  return dispatch({
    type: UPLOAD_CMM_FILE_LIST,
    payload: uploadCmmFileList(formData, onUploadProgress),
  });
};

/**
 * 공통 - 파일 멀티 삭제
 */
export const deleteCmmFiles = (dtos) => {
  return {
    type: DELETE_CMM_FILE_LIST,
    payload: deleteCmmFileList(dtos),
  };
};

/**
 * 파일 목록 초기화
 */
export const resetCmmFiles = () => {
  return {
    type: RESET_CMM_FILE_LIST,
  };
};

/**
 * 응답메시지 초기화
 */
export const resetMessage = () => {
  return {
    type: RESET_MESSAGE,
  };
};

const changeUploadedFileSize = (size) => {
  return {
    type: CHANGE_CMM_FILE_SIZE,
    payload: size,
  };
};
