import React from 'react';
import ReactDOM from 'react-dom/client';
import './index.css';
import App from './App';
import { BrowserRouter } from 'react-router-dom';
import reportWebVitals from './reportWebVitals';
import GlobalStyle from './GlobalStyles';
import { legacy_createStore as createStore, applyMiddleware } from 'redux';
import rootReducer from './redux/modules';
import promiseMiddleware from 'redux-promise-middleware';
import thunkMiddleware from 'redux-thunk';
import { ErrorBoundary } from 'react-error-boundary';

import { Provider } from 'react-redux';
import GlobalStyleCustom from './GlobalStyleCustom';
import { resetSessionExpireTime } from './redux/modules/cmm/main.reducer';

const root = ReactDOM.createRoot(document.getElementById('root'));

const sessionTimeCheck = (store) => (next) => (action) => {
  if (action?.type !== 'mainState/RESET_SESSION_EXPIRE_TIME') {
    store.dispatch(resetSessionExpireTime());
    return next(action);
  } else {
    return next(action);
  }
};

const store = createStore(rootReducer, applyMiddleware(thunkMiddleware, promiseMiddleware, sessionTimeCheck));

root.render(
  <>
    {/* <React.StrictMode> */}
    <GlobalStyle />
    <GlobalStyleCustom />
    {/* <ErrorBoundary
      fallback={
        <div style={{ background: 'red' }}>
          error
          <br />
          <a href="/">home</a>
        </div>
      }> */}
    <BrowserRouter>
      <Provider store={store}>
        <App />
      </Provider>
    </BrowserRouter>
    {/* </ErrorBoundary> */}
    {/* </React.StrictMode> */}
  </>
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
// reportWebVitals(console.log);
