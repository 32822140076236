import instance from '../../common/config/api';

/**
 * 금고영업점 - 지자체 정보관리 - 급여이체 전체내역
 * @param {object} params
 * @returns
 */

export const fetchSalaryTrnsIstAllList = (params) =>
  instance.post(`${process.env.REACT_APP_SERVER_URL}/scq/scq/brc/0002/selectSalaryTrnsIstAllList`, params);

export const fetchSalaryTrnsIstAllList2 = (params) =>
  instance.post(`${process.env.REACT_APP_SERVER_URL}/scq/scq/brc/0002/selectSalaryTrnsIstAllList`, params);
