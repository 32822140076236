import instance from '../../common/config/api';

/**
 * 법인카드-매입내역
 * @param {object} params
 * @returns
 */
export const fetchCadBuyPrtsList = (params) =>
  instance.post(`${process.env.REACT_APP_SERVER_URL}/scq/scq/cad/0001/selectListBuyPrts`, params);

/**
 * 법인카드-매입 증빙장표 증빙회수
 * @param {object} params
 * @returns
 */
export const insertListBuyPrtsEvdcSht = (params) =>
  instance.post(`${process.env.REACT_APP_SERVER_URL}/scq/scq/cad/0001/insertListBuyPrtsEvdcSht`, params);
