import React, { useCallback, useEffect, useRef, useState } from 'react';
import styled from 'styled-components';
import WOButton from '../components/common/WOButton';
import MainFooter from '../components/layout/MainFooter';
import WelcomBox from '../views/login/WelcomBox';
import { useNavigate, useSearchParams } from 'react-router-dom';
import * as S from '../styles/subPage.styled';
import { Controller, useForm } from 'react-hook-form';
import { useDispatch, useSelector } from 'react-redux';
import { sendLogin, sendLoginForAdmin, sendLogout } from '../redux/modules/cmm/login.reducer';
import useAlert from '../hooks/useAlert';
import WOLoginInput from '../components/common/WOLoginInput';
import WOIcon from '../components/common/WOIcon';
import WOModal, { ButtonRightArea, ModalButtonArea, ModalContentArea } from '../components/common/WOModal';
import { yupResolver } from '@hookform/resolvers/yup';
import * as yup from 'yup';
import { resetAuthNumberState, sendAuthNumberToHpNo } from '../redux/modules/cmm/authNumber.reducer';
import { checkAuthNumber } from '../lib/cmm/signup.api';
import { findUserId, resetFindUserId } from '../redux/modules/cmm/findId.reducer';
import ChangeDeptPopup from './cmm/changeDeptPopup';
import ChangePasswordPopup from './cmm/changePasswordPopup';
import LoginChangePasswordPopup from './cmm/LoginChangePasswordPopup';
import { lpad } from '../common/utils/utils';
import AdminAuthPopup from './cmm/AdminAuthPopup';

function Login() {
  const { alert } = useAlert();
  const dispatch = useDispatch();
  const { loading, success, message, messageId, cmSession, menuList } = useSelector((state) => state.loginState);
  const {
    loadingByAuthSend,
    successByAuthSend,
    message: messageByAuthSend,
  } = useSelector((state) => state.authNumberState);
  const passwordRef = useRef();
  // 인증만료시간
  const timer = useRef(null);
  // 5분
  const defaultInterval = 300;
  const [time, setTime] = useState(defaultInterval);
  const navigate = useNavigate();
  const [searchParams] = useSearchParams();
  const { handleSubmit, control, watch } = useForm({
    defaultValues: {
      mchrBycl: 'PC',
      user_id: '',
      pwd_no: '',
    },
  });

  // 아이디 찾기
  const [isOpen, setIsOpen] = useState(false);

  // 비밀번호 재설정
  const [isOpen2, setIsOpen2] = useState(false);

  // 비밀번호 재설정(초기비밀번호, 3개월 비밀번호 변경시)
  const [isOpen4, setIsOpen4] = useState(false);

  // 부서 변경
  const [isOpen3, setIsOpen3] = useState(false);

  // 회원가입 승인
  const [isApprovalOpen, setIsApprovalOpen] = useState(false);

  // 관리자 인증번호
  const [isAdminAuthOpen, setIsAdminAuthOpen] = useState(false);

  /**
   * 로그인
   */
  const handleOnSubmit = (userInfo) => {
    const json = window.npPfsCtrl.toJson('loginForm');
    if (window.isVirtualMachine === 'VDI') {
      dispatch(sendLoginForAdmin(userInfo));
    } else {
      dispatch(sendLogin(`s=${encodeURI(JSON.stringify(json))}`));
    }
  };

  /** 로그인 콜백 */
  useEffect(() => {
    if (!loading && success) {
      const userInfo = cmSession?.at(0);
      localStorage.setItem('user_info', JSON.stringify(userInfo));
      localStorage.setItem('menu_list', JSON.stringify(menuList.filter((menu) => menu.mnu_aut_cd !== 'X')));

      // 세션 만료시 다시 로그인 하면 전 화면으로
      const redirectUrl = searchParams.get('redirect');
      if (redirectUrl) {
        navigate(redirectUrl);
      } else {
        navigate('/');
      }
    }
  }, [cmSession, messageId, message, menuList, loading, success, searchParams, navigate]);

  // 키보드 보안 설정
  const [formRef, setFormRef] = useState(null);
  useEffect(() => {
    if (formRef && window.isVirtualMachine === 'NONE') {
      window.npPfsCtrl.RescanField();
    }
  }, [formRef]);

  useEffect(() => {
    if (!success && message && !loading) {
      debugger;
      if (messageId === 'PAPE1015' || messageId === 'TPGE0003') {
        setIsApprovalOpen(true);
      } else if (messageId === 'PAPE1032' || messageId === 'PAPE1031') {
        alert(message).then(() => {
          setIsOpen4(true);
        });
      } else if (messageId === 'PAPE1011') {
        alert(message).then(() => {
          window.location.reload();
        });
      } else {
        alert(message);
      }
    }
    if (!success && !loading && messageId === 'WL0001') {
      setIsAdminAuthOpen(true);
    }
  }, [success, message, loading, alert, messageId]);

  const setTimer = () => {
    if (timer.current) {
      clearInterval(timer.current);
    }
    setTime(defaultInterval);
    timer.current = setInterval(() => {
      setTime((prev) => prev - 1);
    }, 1000);
  };

  useEffect(() => {
    if (time < 0) {
      if (timer.current) {
        clearInterval(timer.current);
        setTime(0);
      }
    }
  }, [time]);

  const password = watch('pwd_no');
  useEffect(() => {
    if (passwordRef.current) {
      passwordRef.current.value = password;
    }
  }, [password]);

  /**
   * 인증번호 전송
   */
  const handleSendAuthNo = (searchParams) => {
    dispatch(sendAuthNumberToHpNo(searchParams));
  };

  useEffect(() => {
    if (!loadingByAuthSend && successByAuthSend) {
      alert('인증번호가 전송되었습니다.\n전송된 인증번호를 입력해주세요.', '인증번호 전송');
      setTimer();
    } else if (!loadingByAuthSend && !successByAuthSend && messageByAuthSend) {
      alert(messageByAuthSend, '인증번호 전송');
    }
  }, [loadingByAuthSend, successByAuthSend, messageByAuthSend, alert]);

  // 아이디 찾기 팝업 닫기
  const handleFindIdPopupClose = useCallback(() => {
    setIsOpen(false);
    dispatch(resetAuthNumberState());
    dispatch(resetFindUserId());
    clearInterval(timer.current);
    setTime(defaultInterval);
  }, [dispatch]);

  // 아이디 찾기 start
  const idSchema = yup.object().shape({
    user_nm: yup.string().required(),
    brdt: yup.string().length(6).required(),
    hp_no: yup.string().required(),
  });
  const {
    handleSubmit: handleIdSubmit,
    control: idControl,
    formState: { errors: idErrors, dirtyFields: idDirtyFields },
    getValues: getIdValues,
    setError,
    reset,
  } = useForm({
    defaultValues: {
      user_nm: '',
      brdt: '',
      hp_no: '',
      auth_no: '',
    },
    resolver: yupResolver(idSchema),
  });

  const {
    updating: updateById,
    updatingSuccess: updatingSuccessById,
    message: messageById,
    pmnySafUserId,
  } = useSelector((state) => state.findIdState);

  /**
   * 정보입력 다음버튼 활성화 확인
   */
  const checkIdFindDisabled = () => {
    const fields = ['user_nm', 'brdt', 'hp_no', 'auth_no'];
    const dirtyFields = [];
    for (let key in idDirtyFields) {
      dirtyFields.push(key);
    }
    return fields.length !== dirtyFields.length;
  };

  /**
   * 정보입력 얼럿 유효성 검사
   */
  const checkEmptyInput = () => {
    let msg = '';

    if (getIdValues().user_nm === '') {
      msg = '이름을 입력해 주세요.';
    } else if (getIdValues().brdt === '') {
      msg = '생년월일을 입력해 주세요.';
    } else if (getIdValues().hp_no === '') {
      msg = '핸드폰번호를 입력해 주세요.';
    } else if (getIdValues().auth_no === '') {
      msg = '인증번호를 입력해 주세요.';
    }
    msg !== '' && alert(msg);
  };

  /**
   * ID 찾기
   * @param {object} dto
   */
  const handleFindId = async (dto) => {
    // 아이디 찾기
    dispatch(findUserId({ ...dto, crtf_no: dto.auth_no }));
  };

  useEffect(() => {
    if (updatingSuccessById !== null) {
      if (updatingSuccessById) {
        // alert('카카오톡으로 아이디를 전송했습니다.');
        alert(`아이디는 [${pmnySafUserId}] 입니다.`);
        handleFindIdPopupClose();
        reset();
        dispatch(resetAuthNumberState());
      } else {
        alert(messageById);
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [alert, updatingSuccessById, messageById]);
  // 아이디 찾기 end

  useEffect(() => {
    console.log('isVirtualMachine', window.isVirtualMachine);
    return () => {
      dispatch(resetFindUserId());
    };
  }, [dispatch]);

  return (
    <Wrap className="login">
      <WelcomBox />
      <div className="login__inner">
        <div className="login__container">
          <div className="login__budle">
            <ul className="icon-list">
              <li className="icon">
                <img src="/assets/images/icon/login-logo-1.svg" alt="" />
              </li>
              <li className="icon">
                <img src="/assets/images/icon/login-logo-2.svg" alt="" />
              </li>
              <li className="icon">
                <img src="/assets/images/icon/login-logo-3.svg" alt="" />
              </li>
              <li className="icon">
                <img src="/assets/images/icon/login-logo-4.svg" alt="" />
              </li>
              <li className="icon">
                <img src="/assets/images/icon/login-logo-5.svg" alt="" />
              </li>
              <li className="icon">
                <img src="/assets/images/icon/login-logo-6.svg" alt="" />
              </li>
              <li className="icon">
                <img src="/assets/images/icon/login-logo-7.svg" alt="" />
              </li>
            </ul>
            <h3 className="title">
              금고업무 통합관리 시스템에
              <br />
              오신것을 환영합니다.
            </h3>
            <FormArea name="loginForm" ref={setFormRef} onSubmit={handleSubmit(handleOnSubmit)}>
              <InputArea>
                <Controller
                  control={control}
                  name={'user_id'}
                  render={({ field: { onChange, name, value } }) => (
                    <WOLoginInput
                      id="user-id-input"
                      label="아이디"
                      name={name}
                      value={value}
                      maxLength={13}
                      onChange={onChange}
                      placeholder="아이디를 입력해 주세요."
                    />
                  )}
                />
                <Controller
                  control={control}
                  name={'pwd_no'}
                  render={({ field: { onChange, name, value } }) => (
                    <WOLoginInput
                      id="pwd-no-input"
                      label="비밀번호"
                      name={name}
                      value={value}
                      onChange={onChange}
                      maxLength={16}
                      type={'password'}
                      npkencrypt={'on'}
                      placeholder="비밀번호를 입력해주세요."
                    />
                  )}
                />
              </InputArea>
              <WOButton type="submit" variant="primary" size="lg">
                로그인
              </WOButton>
            </FormArea>
            <LoginSupportArea>
              <button type="button" className="text-btn" onClick={() => navigate('/page/signup')}>
                회원가입
              </button>
              <div className="flex ml-auto align-center">
                <button
                  type="button"
                  className="text-btn"
                  onClick={() => {
                    setIsOpen(true);
                    reset();
                  }}>
                  아이디 찾기
                </button>
                <button type="button" className="text-btn" onClick={() => setIsOpen2(true)}>
                  비밀번호 재설정
                </button>
                <button type="button" className="text-btn" onClick={() => setIsOpen3(true)}>
                  부서변경
                </button>
              </div>
            </LoginSupportArea>
          </div>
        </div>
      </div>
      <MainFooter islogin={true} />

      {/* 아이디 찾기 모달 */}
      <WOModal direction="center" height={456} isOpen={isOpen} title={'아이디 찾기'} onClose={handleFindIdPopupClose}>
        <ModalContentArea $gap={8}>
          <S.LoginModalDescript>
            * 휴대폰 인증으로 아이디를 찾을 수 있습니다. <br />
            인증한 휴대폰 번호로 아이디가 발송됩니다.
          </S.LoginModalDescript>
          <Controller
            control={idControl}
            name={'user_nm'}
            render={({ field: { onChange, value } }) => (
              <WOLoginInput
                id="input03"
                label="이름"
                name="input03"
                maxLength={15}
                styleType={'popup'}
                placeholder="이름을 입력해주세요"
                onChange={onChange}
                value={value}
              />
            )}
          />
          <Controller
            control={idControl}
            name={'brdt'}
            render={({ field: { onChange, name, value } }) => (
              <WOLoginInput
                id="input04"
                label="생년월일"
                name="input04"
                styleType={'popup'}
                type={'number'}
                maxLength={6}
                placeholder="생년월일 6자리를 입력해 주세요. (예시 yymmdd)"
                onChange={onChange}
                value={value}
                error={!!idErrors.brdt}
              />
            )}
          />
          <Controller
            control={idControl}
            name={'hp_no'}
            render={({ field: { onChange, name, value } }) => (
              <WOLoginInput
                type={'number'}
                id="input05"
                label="핸드폰번호"
                name="input05"
                placeholder="핸드폰번호를 입력해주세요."
                maxLength={11}
                button={
                  <AuthBtn
                    type="button"
                    className="auth-btn"
                    onClick={() =>
                      handleSendAuthNo({
                        hp_no: getIdValues('hp_no'),
                        menu_nm: 'ID',
                        brdt: getIdValues('brdt'),
                        user_nm: getIdValues('user_nm'),
                      })
                    }>
                    인증번호 전송
                  </AuthBtn>
                }
                onChange={onChange}
                value={value}
              />
            )}
          />
          <Controller
            control={idControl}
            name={'auth_no'}
            render={({ field: { onChange, name, value } }) => (
              <WOLoginInput
                type="number"
                id="input06"
                label="인증번호"
                name="input06"
                maxLength={6}
                styleType={'popup'}
                placeholder="인증번호를 입력해 주세요."
                time={`${lpad(Math.floor(time / 60), 2, '0')}:${lpad(time % 60, 2, '0')}`}
                onChange={onChange}
                value={value}
                error={!!idErrors.auth_no}
              />
            )}
          />
        </ModalContentArea>
        <ModalButtonArea $nonBoxShadow={true}>
          <ButtonRightArea>
            <WOButton variant={'secondary'} size={'md'} onClick={handleFindIdPopupClose}>
              취소
            </WOButton>
            {checkIdFindDisabled() ? (
              <WOButton
                variant={'primary'}
                size={'md'}
                customClass={'disabled'}
                onClick={() => {
                  checkEmptyInput();
                }}>
                아이디 찾기
              </WOButton>
            ) : (
              <WOButton
                variant={'primary'}
                size={'md'}
                disabled={checkIdFindDisabled()}
                onClick={() => {
                  handleIdSubmit(handleFindId)(getIdValues());
                }}>
                아이디 찾기
              </WOButton>
            )}
          </ButtonRightArea>
        </ModalButtonArea>
      </WOModal>

      {/* 비밀번호 변경 모달 */}
      <ChangePasswordPopup isOpen={isOpen2} onClose={() => setIsOpen2(false)} />

      {/* 비밀번호 변경 모달(3개월 비밀번호 변경 또는 초기비밀번호시) */}
      <LoginChangePasswordPopup
        title={messageId === 'PAPE1031' ? '기존 비밀번호 재설정' : '초기 비밀번호 재설정'}
        isOpen={isOpen4}
        onClose={() => setIsOpen4(false)}
      />

      {/* 부서 변경 모달 */}
      <ChangeDeptPopup isOpen={isOpen3} onClose={() => setIsOpen3(false)} />

      {/* s: 회원가입 승인 모달*/}
      <WOModal
        isOpen={isApprovalOpen}
        direction="center"
        title={`회원가입 승인/반려`}
        height="auto"
        onClose={() => setIsApprovalOpen(false)}>
        <ModalContentArea>
          {/* s: 진행중 */}
          {messageId === 'PAPE1015' && (
            <S.SignAcceptBoxWrap $type={'approval'}>
              <span>시구청 총괄 관리자 승인</span>
              <p>진행중</p>
              <ImageWrap>
                <WOIcon icon={'document'} width={108} height={92} />
              </ImageWrap>
              <LoadingWrap>
                <img src="/assets/images/etc/rolling.svg" className="rolling-animation" alt="loading..." />
              </LoadingWrap>
            </S.SignAcceptBoxWrap>
          )}
          {/* e: 진행중 */}
          {/* s: 승인불가 */}
          {messageId === 'TPGE0003' && (
            <S.SignAcceptBoxWrap $type={'approval'} className="reject">
              <span>시구청 총괄 관리자 승인</span>
              <p>
                승인불가
                <span>{message.replaceAll(']', '').replaceAll('[', '')}</span>
              </p>
              <ImageWrap className="type-other">
                <WOIcon icon={'document-red'} width={108} height={92} />
              </ImageWrap>
            </S.SignAcceptBoxWrap>
          )}
          {/* e: 승인불가 */}
        </ModalContentArea>
        <ModalButtonArea $noneShadow>
          <ButtonRightArea>
            <WOButton variant={'primary'} size={'md'} onClick={() => setIsApprovalOpen(false)}>
              확인
            </WOButton>
          </ButtonRightArea>
        </ModalButtonArea>
      </WOModal>

      {/* 관리자 인증번호 확인 */}
      <AdminAuthPopup
        isOpen={isAdminAuthOpen}
        onClose={() => {
          setIsAdminAuthOpen(false);
          dispatch(sendLogout());
        }}
      />
    </Wrap>
  );
}

export default Login;

const Wrap = styled.div`
  width: 100%;
  height: calc(100% - 60px);
  background-color: #fff;
  min-width: 1268px;
  min-height: 500px;
  @media screen and (max-width: 1580px) {
    height: calc(100% - 82px);
  }
  .login {
    &__inner {
      width: 100%;
      height: 100%;
      padding-left: 480px;
      display: flex;
      align-content: stretch;
      @media screen and (max-width: 1580px) {
        padding-left: 400px;
      }
    }

    &__container {
      width: 100%;
      display: flex;
      align-items: center;
      justify-content: center;
    }
    &__budle {
      width: 400px;
      .icon-list {
        display: flex;
        align-items: center;
        height: 42px;
        margin-bottom: 12px;
        .icon {
          width: 32px;
          height: 32px;
          &:first-child {
            margin-right: 10px;
          }
          &:nth-child(n + 2) {
            filter: drop-shadow(4px 0px 4px rgba(0, 0, 0, 0.15));
            opacity: 0.3;
          }
          &:nth-child(n + 3) {
            margin-left: -9px;
          }
          img {
            width: 100%;
            height: auto;
          }
        }
      }

      .title {
        padding: 0 0 36px;
        font-size: 24px;
        color: #333;
        font-weight: 700;
        line-height: 36px; /* 36px */
      }
    }
  }

  // notice
  .notice-bar {
    right: 86px;
  }
`;

const AuthBtn = styled.button`
  display: flex;
  align-items: center;
  width: fit-content;
  height: 32px;
  padding: 0px 16px;
  color: var(--C-Dark-10);
  font-size: 12px;
  font-weight: 700;
  border-radius: 6px;
  background: var(--C-Dark-03, #e5e5ed);
  &:disabled {
    color: var(--C-Dark-06, #9ea6b2);
  }
`;

const FormArea = styled.form`
  .btn {
    margin: 36px 0 16px;
    width: 100%;
    height: 52px;
    font-size: 18px;
  }
  .input + .input {
    margin-top: 12px;
  }
`;

const LoginSupportArea = styled.div`
  display: flex;
  align-items: center;
  margin: 0 -2px;
  .text-btn {
    color: var(--C-Dark-08);
    font-size: 14px;
    font-weight: 400;
    text-decoration-line: none;

    & + .text-btn {
      position: relative;
      margin-left: 16px;
      &::before {
        content: '';
        position: absolute;
        top: 50%;
        left: -8px;
        transform: translateY(-50%);
        width: 2px;
        height: 2px;
        background: var(--C-Dark-08);
      }
    }
  }
  .divider {
    display: block;
    width: 2px;
    height: 2px;
    background-color: var(--C-Dark-08);
    margin: 0 4px;
  }
  .bullet {
    color: var(--C-Dark-08);
    padding: 0 7px;
  }
`;

const InputArea = styled.div`
  display: flex;
  flex-direction: column;
  gap: 12px 0;
`;

const ImageWrap = styled.div`
  position: absolute;
  top: 32px;
  right: 42px;

  &.type-other {
    top: auto;
    bottom: 16px;
  }
`;

const LoadingWrap = styled.div`
  position: absolute;
  top: 52px;
  right: 53px;

  .checkmark-animation {
    animation: checkmark 0.5s ease forwards;
  }

  @keyframes checkmark {
    0% {
      transform: scale(0);
      opacity: 0;
    }
    50% {
      transform: scale(1.2);
      opacity: 1;
    }
    100% {
      transform: scale(1);
    }
  }

  .rolling-animation {
    animation: rotate360 2s linear infinite;
  }

  @keyframes rolling {
    from {
      transform: rotate(0deg);
    }
    to {
      transform: rotate(360deg);
    }
  }
`;
