import instance from '../../common/config/api';

const prefix = `${process.env.REACT_APP_SERVER_URL}/scq/scq/etc/0006`;

/* 마스킹 관리 */

/**
 * 비밀번호 확인
 */
export const fetchCheckPwd = (params) => instance.post(`${prefix}/selectCheckPwd`, params);

/**
 * 마스킹 해지 신청 목록 조회
 */
export const fetchAppMaskList = (params) => instance.post(`${prefix}/selectAppMaskList`, params);

/**
 * 마스킹 해지 신청 목록 조회(관리자)
 */
export const fetchApvMaskList = (params) => instance.post(`${prefix}/selectApvMaskList`, params);

/**
 * 마스크 해지신청 등록
 */
export const fetchAppMaskInfo = (params) => instance.post(`${prefix}/insertAppMaskInfo`, params);

/**
 * 마스크 해지 신청 승인/반려 처리
 */
export const fetchApvMasInfo = (params) => instance.post(`${prefix}/updateApvMasInfo`, params);

export const fetchDeptUser = (params) => instance.post(`${prefix}/selectUserByDeptList`, params);

/* 마스킹 관리 End */
