import React, { useCallback, useEffect, useMemo, useState } from 'react';
import styled, { css } from 'styled-components';
import WOCollapseFDS from '../../views/main/WOCollapseFDS';
import { Fade } from '@mui/material';
import WOExpandFDS from '../../views/main/WOExpandFDS';
import { useDispatch, useSelector } from 'react-redux';
import useSession from '../../hooks/useSession';
import dayjs from 'dayjs';
import useAlert from '../../hooks/useAlert';
import {
  selectAbvTrnPcptGatTotCnt,
  selectAbvTrnPcptGatTop3,
  selectAbvTrnPcptGatLevCnt,
  selectAbvTrnPcptGatDtls,
  selectAbvTrnPcptGatEvt,
  selectAbvTrnPcptGatRltmEvt,
  changeCusIstCd,
  selectUserEst,
  selectAbvTrnPcptGatItemDtls,
} from '../../redux/modules/fds/MainFDS.reducer';
import { selectCommonComboList } from '../../redux/modules/cmm/combo.reducer';
import { useForm } from 'react-hook-form';
import { isEmptyObject } from '../../common/utils/utils';
import { Severity, OccurenceTitle } from '../../common/enum/cmm/occurence';

const MainFDS = ({ user }) => {
  // logic
  const { alert } = useAlert();
  const [isOpen, setIsOpen] = useState(false);
  const dispatch = useDispatch();
  const today = dayjs().format('YYYY.MM.DD');
  const session = useSession().getUserInfo();

  const [mapInfo, setMapInfo] = useState({
    cityCode: '',
    cityName: '',
    ci: '',
  });

  // grid
  const {
    loading,
    loading2,
    loading3,
    loading4,
    loading5,
    loading6,
    list,
    success,
    success2,
    success3,
    success4,
    success5,
    success6,
    message,
    message2,
    message3,
    message4,
    message5,
    message6,
    totalCount,
    totCnt,
    top3,
    levelCnt,
    details,
    detailsTotal,
    cus_ist_cd,
    chartData,
  } = useSelector((state) => state.MainFDS);

  /************  COMBO  ***********/
  // 기관코드(7)
  const {
    success: { CB919: istInfoSuccess = false },
    loading: { CB919: istInfoLoading = false },
    options: { CB919: istInfoList = [] },
  } = useSelector((state) => state.combo);

  const allOption = useMemo(() => ({ code: 'all', codenm: '전체' }), []);
  /************  COMBO END ***********/

  const toggleOpen = () => {
    const result = !isOpen;
    setIsOpen(result);
  };

  //const [cusIstCd, setCusIstCd] = useState('all');

  const { control, setValue, getValues, reset, watch } = useForm({
    defaultValues: {
      cus_ist_cd: cus_ist_cd,
      act_dis_cd: '',
      // 기본정보
      // 아이디
      // 성명
      // 생년월일
      // 휴대폰 번호
      // 소속구청/부서
      // 법인카드 권한
      // 비밀번호
    },
  });

  const changeMapInfo = useCallback((istInfo) => {
    const json = {
      cityName: istInfo?.codenm ? istInfo?.codenm : '',
      cityCode: istInfo?.map_nm ? istInfo?.map_nm : istInfo?.sido_cd === '11' ? 'seoul' : '',
      ci: istInfo?.ci_nm ? istInfo?.ci_nm : '',
    };
    setMapInfo(json);
  }, []);

  //기관명 변경 시
  const handleOnIstInfoChange = useCallback(
    (val) => {
      const ist_cd = val.replaceAll('all', '');
      const dept_cd = ![1, 2].includes(user?.id) ? session.dept_cd : '';
      const param = { cus_ist_cd: ist_cd, dept_cd };
      dispatch(changeCusIstCd(val));
      dispatch(selectAbvTrnPcptGatTotCnt(param));
      dispatch(selectAbvTrnPcptGatTop3(param));
      dispatch(selectAbvTrnPcptGatLevCnt(param));
      dispatch(selectAbvTrnPcptGatDtls(param));
      dispatch(selectAbvTrnPcptGatEvt(param));
      dispatch(selectAbvTrnPcptGatRltmEvt(param));
      dispatch(selectAbvTrnPcptGatItemDtls({ ...param, fds_policy_cd: '01' }));

      if (ist_cd === '') {
        changeMapInfo({
          codenm: '',
          sido_cd: '',
          map_nm: 'korea',
          ci_nm: '',
        });
      } else {
        const istInfo = istInfoList.find((item) => item.code === val);
        changeMapInfo(istInfo);
      }
    },
    [changeMapInfo, dispatch, istInfoList, session.dept_cd, user?.id]
  );

  useEffect(() => {
    if (dispatch) {
      // 기관명 콤보 조회
      dispatch(
        selectCommonComboList({
          slt_box_no: '919',
          cus_ist_cd: session.cus_ist_cd,
          //dept_cd: session.dept_cd,
          aut_tp_cd: session.aut_tp_cd,
        })
      );
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [dispatch]);

  ///TOP3 조회 콜백
  useEffect(() => {
    if (!loading2 && success2 && top3?.length > 0) {
    }
  }, [loading2, success2, top3?.length]);

  useEffect(() => {
    // 총괄 관리자, 시금고 관리자, 시금고 담당자 세팅
    // setUser(userList[0]);
  }, []);

  //기관명 조회 콜백
  useEffect(() => {
    if (!istInfoLoading && istInfoSuccess && istInfoList?.length > 0 && dispatch) {
      const istInfo = session?.cus_ist_cd
        ? istInfoList?.at(0)
        : istInfoList.find((item) => item.br_cd === session.br_cd);
      const dept_cd = ![1, 2].includes(user?.id) ? session.dept_cd : '';

      if (user?.id === 1) {
        dispatch(changeCusIstCd('all'));

        changeMapInfo({
          codenm: '',
          map_nm: 'korea',
          ci_nm: '',
        });
      } else {
        dispatch(changeCusIstCd(istInfo?.code));
        changeMapInfo(istInfo);
      }
      const param = { cus_ist_cd: user?.id !== 1 ? istInfo?.code : '', dept_cd };
      dispatch(selectAbvTrnPcptGatTotCnt(param));
      dispatch(selectAbvTrnPcptGatTop3(param));
      dispatch(selectAbvTrnPcptGatLevCnt(param));
      dispatch(selectAbvTrnPcptGatDtls(param));
      dispatch(selectAbvTrnPcptGatEvt(param));
      dispatch(selectAbvTrnPcptGatRltmEvt(param));
      //dispatch(selectUserEst({}));
    }
  }, [
    changeMapInfo,
    dispatch,
    istInfoList,
    istInfoLoading,
    istInfoSuccess,
    session.br_cd,
    session?.cus_ist_cd,
    session.dept_cd,
    session.user_id,
    user?.id,
  ]);

  useEffect(() => {
    if (!success && message) {
      alert(message);
    }
  }, [success, message, alert]);

  useEffect(() => {
    if (!success2 && message2) {
      alert(message2);
    }
  }, [alert, message2, success2]);

  useEffect(() => {
    if (!success3 && message3) {
      alert(message3);
    }
  }, [alert, message3, success3]);

  useEffect(() => {
    if (!success4 && message4) {
      alert(message4);
    }
  }, [alert, message4, success4]);

  useEffect(() => {
    if (!success5 && message5) {
      alert(message5);
    }
  }, [alert, message5, success5]);

  useEffect(() => {
    if (!success6 && message6) {
      alert(message6);
    }
  }, [alert, message6, success6]);

  // view
  return (
    <Wrapper $isOpen={isOpen}>
      <Wrap>
        <Inner $isOpen={isOpen}>
          <Fade in={!isOpen} timeout={1000}>
            <Container className="collapse" $isOpen={isOpen}>
              <WOCollapseFDS
                user={user}
                istInfoList={istInfoList}
                handleOnIstInfoChange={handleOnIstInfoChange}
                mapInfo={mapInfo}
              />
            </Container>
          </Fade>
          <Fade in={isOpen} timeout={1000}>
            <Container className="expand" $isOpen={isOpen}>
              <WOExpandFDS
                user={user}
                istInfoList={istInfoList}
                handleOnIstInfoChange={handleOnIstInfoChange}
                mapInfo={mapInfo}
              />
            </Container>
          </Fade>
        </Inner>
      </Wrap>
      <OpenBtn type="button" className="open-btn" onClick={toggleOpen}></OpenBtn>
    </Wrapper>
  );
};

export default MainFDS;

const Wrapper = styled.div`
  position: fixed;
  top: 0;
  left: 80px;
  height: 100vh;
  z-index: 900;
  transition: width 0.5s;
  ${(props) =>
    props.$isOpen &&
    css`
      @media screen and (max-width: 1280px) {
        max-width: calc(100vw - 112px);
        padding-bottom: 12px;
      }
    `};
`;
const Wrap = styled.div`
  width: inherit;
  height: 100%;
  overflow-x: auto;
`;
const Inner = styled.div`
  height: 100%;
  background: linear-gradient(0deg, #3a3d64 0%, #1d2e4b 100%);
  color: #fff;
  overflow: hidden;
  transition: 0.5s;
  ${(props) =>
    props.$isOpen
      ? css`
          width: 1160px;
          position: relative;
        `
      : css`
          width: 400px;
        `};
`;

const Container = styled.div`
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
  ${(props) =>
    props.$isOpen &&
    css`
      &.expand {
        position: absolute;
        top: 0;
        left: 0;
      }
    `};
`;

const OpenBtn = styled.button`
  position: absolute;
  top: 50%;
  right: -20px;
  width: 20px;
  height: 75px;
  background: url(/assets/images/sidebar-button-black.png) no-repeat center / cover;
  border-radius: 0 24px 24px 0;
  transition: 0.5s;
`;
