import instance from '../../common/config/api';

/**
 * 제로페이(관리자) - 카카오톡 전송내역 조회
 * @param {object} params
 * @returns
 */

export const fetchZpbKataSmsTmsPrstList = (params) =>
  instance.post(`${process.env.REACT_APP_SERVER_URL}/scq/scq/zpb/0002/selectKataSmsTmsPrstList`, params);
