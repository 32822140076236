import { SUCCESS, REQUEST, FAILURE } from '../../../common/utils/action-type-utils';
import { fetchMenuPrsnByUserList, fetchUserList, saveMenuPrsnByUserList } from '../../../lib/sys/SYS0310M01.api';
import { fetchSpcMenuAuthList, saveSpcMenuAuthList } from '../../../lib/sys/SYS0311M01.api';

// actions
const FETCH_MENU_PRSN_BY_USER_LIST = 'SYS0310M01/FETCH_MENU_PRSN_BY_USER_LIST';
const FETCH_USER_LIST = 'SYS0310M01/FETCH_USER_LIST';
const SAVE_MENU_PRSN_BY_USER_LIST = 'SYS0310M01/SAVE_MENU_PRSN_BY_USER_LIST';

// initial state
const initialState = {
  loadingByUsers: false,
  loadingByMenus: false,
  successByUsers: false,
  successByMenus: false,
  message: '',
  userlist: [],
  menuList: [],
  totalCountByUsers: 0,
  totalCountByMenus: 0,
  updating: false,
  updateSuccess: false,
  resultCnt: -1,
};

// reducers
export default function SYS0310M01(state = initialState, action) {
  switch (action.type) {
    case SUCCESS(FETCH_USER_LIST): // 조회 성공
      return {
        ...state,
        successByUsers: true,
        loadingByUsers: false,
        userlist: action.payload.data?.main,
        totalCountByUsers: action.payload?.data?.totalCount,
        updateSuccess: false,
      };
    case REQUEST(FETCH_USER_LIST): // 조회 요청
      return {
        ...state,
        message: '',
        loadingByUsers: true,
        successByUsers: false,
        updateSuccess: false,
      };
    case FAILURE(FETCH_USER_LIST): // 조회실패
      return {
        ...initialState,
        loadingByUsers: false,
        successByUsers: false,
        message: action.payload.response?.data?.message,
        updateSuccess: false,
      };
    case SUCCESS(FETCH_MENU_PRSN_BY_USER_LIST): // 조회 성공
      return {
        ...state,
        successByMenus: true,
        loadingByMenus: false,
        menuList: action.payload.data?.sub01,
        totalCountByMenus: action.payload?.data?.sub_cnt,
        updateSuccess: false,
      };
    case REQUEST(FETCH_MENU_PRSN_BY_USER_LIST): // 조회 요청
      return {
        ...state,
        message: '',
        loadingByMenus: true,
        successByMenus: false,
        updateSuccess: false,
      };
    case FAILURE(FETCH_MENU_PRSN_BY_USER_LIST): // 조회실패
      return {
        ...initialState,
        loadingByMenus: false,
        successByMenus: false,
        message: action.payload.response?.data?.message,
        updateSuccess: false,
      };
    case SUCCESS(SAVE_MENU_PRSN_BY_USER_LIST): // 저장 성공
      return {
        ...state,
        updateSuccess: true,
        updating: false,
        resultCnt: action.payload.data.resultCnt,
      };
    case REQUEST(SAVE_MENU_PRSN_BY_USER_LIST): // 저장 요청
      return {
        ...state,
        message: '',
        updating: true,
        updateSuccess: false,
      };
    case FAILURE(SAVE_MENU_PRSN_BY_USER_LIST): // 저장 실패
      return {
        ...initialState,
        updating: false,
        updateSuccess: false,
        resultCnt: -1,
        message: action.payload.response?.data?.message,
      };
    default:
      return state;
  }
}

/**
 * 사용자별 메뉴권한 조회
 */
export const selectMenuPrsnByUserList = (searchParams) => {
  return {
    type: FETCH_MENU_PRSN_BY_USER_LIST,
    payload: fetchMenuPrsnByUserList(searchParams),
  };
};

/**
 * 사용자목록 조회
 */
export const selectUserList = (searchParams) => {
  return {
    type: FETCH_USER_LIST,
    payload: fetchUserList(searchParams),
  };
};

/**
 * 사용자별메뉴관리 저장
 */
export const saveListMenuPrsnByUser = (dtos) => {
  return {
    type: SAVE_MENU_PRSN_BY_USER_LIST,
    payload: saveMenuPrsnByUserList(dtos),
  };
};
