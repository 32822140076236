import React from 'react';
import styled from 'styled-components';

const WelcomBox = () => {
  return (
    <Wrap className="login__welcome-box">
      <div className="title-area">
        <span className="text">안녕하세요</span>
        <span className="text">금고업무 통합관리 시스템입니다.</span>
        <span className="sub-text">로그인 후 이용이 가능합니다. </span>
      </div>
      <div className="info-area">
        <div className="image-area">
          <div className='side'>
            <img className='side-left' src="/assets/images/login-side-image-left.svg" alt="우리은행" width={'208px'} height={'240px'} />
            <img className='side-right' src="/assets/images/login-side-image-right.svg" alt="우리은행" width={'265px'} height={'500px'} />
          </div>
          
          <img src="/assets/images/login_main_text_icon.svg" alt="우리은행" height="38px" />
        </div>
      </div>
    </Wrap>
  );
};

export default WelcomBox;

const Wrap = styled.div`
  display: flex;
  flex-direction: column;
  min-width: 480px;
  width: 480px;
  height: 100vh;
  position: fixed;
  z-index: 900;
  padding: 36px 36px 38px;
  background: linear-gradient(0deg, #3a3d64 0%, #1d2e4b 100%);
  @media screen and (max-width: 1580px) {
    min-width: 400px;
    width: 400px;
  }
  .title-area {
    .text,
    .sub-text {
      display: block;
    }
    .text {
      color: var(--White);
      font-size: 18px;
      font-weight: 700;
    }
    .sub-text {
      color: var(--C-Dark-04);
      font-size: 14px;
      font-weight: 400;
      padding-top: 12px;
    }
  }
  .info-area {
    margin-top: auto;
    gap: 16px;
    address {
      color: var(--C-Dark-06);
      font-size: 12px;
      font-weight: 400;
      & + address {
        padding-top: 4px;
      }
    }
    .image-area {
      display: flex;
      align-items: center;
      gap: 18px;
      padding-top: 16px;
      .side{
        display : flex;
        position: absolute;
        left: 0;
        min-height: 480px;
        &-left {
          position: relative;
          bottom: -68px;
          @media screen and (max-width: 1580px) {
            min-width: 150px;
            width: 150px;
            bottom: -98px;
          }
        }

        &-right {
          position: relative;
          bottom: 193px;
          left: 3px;
          opacity : 0.7;
          mix-blend-mode : luminosity;
          @media screen and (max-width: 1580px) {
            min-width: 250px;
            width: 250px;
            bottom: 127px;
          }
        }
       
       
      }
    }
  }
`;
