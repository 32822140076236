import instance from '../../common/config/api';

/**
 * 기타 - 손해배상금 - Data Correction
 * @param {object} params
 * @returns
 */

// 교환회부일자
export const fetchEtcExchageDate = () =>
  instance.post(`${process.env.REACT_APP_SERVER_URL}/scq/scq/etc/0005/selectExchageDate`);

// 목록
export const fetchEtcLosIdmnList = (params) =>
  instance.post(`${process.env.REACT_APP_SERVER_URL}/scq/scq/etc/0005/selectLosIdmnList`, params);

// 등록/수정/삭제
export const saveEtcLosIdmnInfo = (dtos) =>
  instance.post(`${process.env.REACT_APP_SERVER_URL}/scq/scq/etc/0005/insertLosIdmnInfo`, {
    subDto: dtos,
    subDtoCnt: dtos.length,
  });

// 영수증 출력
export const fetchEtcLosIdmnOupList = (params) =>
  instance.post(`${process.env.REACT_APP_SERVER_URL}/scq/scq/etc/0005/selectLosIdmnOupList`, params);

/**
 * 콤보 보음
 */

// 징수기관 콤보
export const fetchLevyIstList = (params) =>
  instance.post(`${process.env.REACT_APP_SERVER_URL}/scq/scq/etc/0005/selectLevyIst`, params);

// 수납기관 콤보
export const fetchRepIstList = (params) =>
  instance.post(`${process.env.REACT_APP_SERVER_URL}/scq/scq/etc/0005/selectRepIst`, params);

// 영업점 콤보
export const fetchBrList = (params) =>
  instance.post(`${process.env.REACT_APP_SERVER_URL}/scq/scq/etc/0005/selectBr`, params);
