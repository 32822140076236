import { fetchPmnyLnkGndpAcnoList, fetchHopeActList } from '../../../lib/arv/ARV0401V01.api';
import { SUCCESS, REQUEST, FAILURE } from '../../../common/utils/action-type-utils';
import dayjs from 'dayjs';
import { fetchCommonComboList } from '../../../lib/cmm/combo.api';

// actions
const FETCH_PMNYLNKGNDP_ACNO_LIST = 'ARV0401V01/FETCH_PMNYLNKGNDP_ACNO_LIST';
const FETCH_HOPE_ACT_LIST = 'ARV0401V01/FETCH_HOPE_ACT_LIST';

const RESET_INITIAL_STATE = 'ARV0401V01/RESET_INITIAL_STATE';
const SAVE_SEARCH_FORM_VALUES = 'ARV0401V01/SAVE_SEARCH_FORM_VALUES';

const FETCH_IST_INFO_LIST = 'ARV0401V01/FETCH_IST_INFO_LIST';

const SET_BEFORE_SEARCH_PARAM = 'ARV0401V01/SET_BEFORE_SEARCH_PARAM';
const CLEAR_MESSAGE = 'ARV0401V01/CLEAR_MESSAGE';

export const initFormValues = {
  cus_ist_cd: '',
  ydcs8a1_t8a11_inq_br: '',
};

// initial state
const initialState = {
  loading: false,
  success: true,
  message: '',
  list: [],
  hopeNoAcnoList: [],
  totalCount: 0,
  beforeSearchParam: {},

  //콤보 - 기관명 005
  istInfoSuccess: false,
  istInfoLoading: false,
  istInfoList: [],

  // 검색조건
  searchFormValues: {
    init: true,
    ...initFormValues,
  },
};

// reducers
export default function PmnyLnkGndpAcnoList(state = initialState, action) {
  switch (action.type) {
    case SUCCESS(FETCH_PMNYLNKGNDP_ACNO_LIST): // 조회 성공
      return {
        ...state,
        success: true,
        loading: false,
        list: action.payload.data?.output_data,
        totalCount: action.payload?.output_data_cnt,
      };
    case REQUEST(FETCH_PMNYLNKGNDP_ACNO_LIST): // 조회 요청
      return {
        ...state,
        success: false,
        loading: true,
      };
    case FAILURE(FETCH_PMNYLNKGNDP_ACNO_LIST): // 조회실패
      return {
        ...state,
        list: [],
        loading: false,
        success: false,
        message: action.payload.response?.data?.message,
      };
    case SUCCESS(FETCH_HOPE_ACT_LIST): // 조회 성공
      return {
        ...state,
        hopeNoAcnoList: action.payload.data?.output_data,
      };
    case REQUEST(FETCH_HOPE_ACT_LIST): // 조회 요청
      return {
        ...state,
      };
    case FAILURE(FETCH_HOPE_ACT_LIST): // 조회실패
      return {
        ...state,
        hopeNoAcnoList: [],
        message: action.payload.response?.data?.message,
      };
    // 기관명
    case SUCCESS(FETCH_IST_INFO_LIST): // 조회 성공
      return {
        ...state,
        istInfoSuccess: true,
        istInfoLoading: false,
        istInfoList: action.payload.data?.subDto,
      };
    case REQUEST(FETCH_IST_INFO_LIST): // 조회 요청
      return {
        ...state,
        istInfoLoading: true,
        istInfoSuccess: false,
      };
    case FAILURE(FETCH_IST_INFO_LIST): // 조회실패
      return {
        istInfoLoading: false,
        istInfoSuccess: false,
        istInfoList: [],
      };
    case SAVE_SEARCH_FORM_VALUES:
      return {
        ...state,
        istInfoSuccess: false,
        istInfoLoading: false,

        searchFormValues: action.payload,
      };
    case RESET_INITIAL_STATE:
      return initialState;
    case SET_BEFORE_SEARCH_PARAM:
      return {
        ...state,
        beforeSearchParam: action.payload,
      };
    case CLEAR_MESSAGE:
      return {
        ...state,
        message: '',
        success: false,
      };
    default:
      return state;
  }
}

/**
 * 세입 - 공금연동 보통예금 계좌조회
 */
export const selectPmnyLnkGndpAcnoList = (searchParams) => {
  return {
    type: FETCH_PMNYLNKGNDP_ACNO_LIST,
    payload: fetchPmnyLnkGndpAcnoList(searchParams),
  };
};

/**
 * 세입 - 희망계좌번호 목록조회(콤보셋팅용)
 */
export const selectHopeNoAcnoList = (searchParams) => {
  return {
    type: FETCH_HOPE_ACT_LIST,
    payload: fetchHopeActList(searchParams),
  };
};

/**
 * 메시지초기화
 */
export const clearMessage = () => {
  return {
    type: CLEAR_MESSAGE,
  };
};

/**
 *
 */
export const setBeforeSearchParam = (searchParams) => {
  return {
    type: SET_BEFORE_SEARCH_PARAM,
    payload: searchParams,
  };
};

//기관명 콤보 조회
export const selectIstInfoList = (searchParam) => {
  return {
    type: FETCH_IST_INFO_LIST,
    payload: fetchCommonComboList({ slt_box_no: '005', ...searchParam }),
  };
};

/**
 * 조회조건 저장
 */
export const saveSearchFormValues = (searchFormValues) => {
  return {
    type: SAVE_SEARCH_FORM_VALUES,
    payload: {
      ...searchFormValues,
      init: false,
    },
  };
};

/**
 * 상태초기화
 */
export const resetInitialState = () => {
  return {
    type: RESET_INITIAL_STATE,
  };
};
