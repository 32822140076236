import instance from '../../common/config/api';

/**
 * 시스템공통 - 공지사항관리
 * @param {object} params
 * @returns
 */

export const fetchNtList = (params) =>
  instance.post(`${process.env.REACT_APP_SERVER_URL}/scq/scq/sys/0001/selectNotice`, params);

export const fetchNtPkNmgt = (params) =>
  instance.post(`${process.env.REACT_APP_SERVER_URL}/scq/scq/sys/0001/selectNoticePkNmgt`, params);

export const insertNotice = (dtos) =>
  instance.post(`${process.env.REACT_APP_SERVER_URL}/scq/scq/sys/0001/insertNotice`, {
    subDto: dtos,
  });

/**
 * 공지사항 배너 및 목록 조회
 * @param {object} params
 * @returns
 */
export const fetchNoticeForMain = (params) =>
  instance.post(`${process.env.REACT_APP_SERVER_URL}/scq/scq/sys/0001/selectNoticeForMain`, params);

/**
 * 메뉴관리-저장
 */

export const saveMenuList = (dtos) =>
  instance.post(`${process.env.REACT_APP_SERVER_URL}/scq/scq/sys/0003/saveMenuList`, {
    main: dtos,
  });
