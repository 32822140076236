import instance from '../../common/config/api';

/**
 * 운용상품 - MMDA 현황
 * @param {object} params
 * @returns
 */

export const fetchMmdaPtsList = (params) =>
  instance.post(`${process.env.REACT_APP_SERVER_URL}/scq/scq/fxp/0003/selectMmdaPts`, params);

/**
 * 운용상품 - MMDA 계좌 조회
 * @param {object} params
 * @returns
 */

export const fetchMMDAAcno = (params) =>
  instance.post(`${process.env.REACT_APP_SERVER_URL}/scq/scq/fxp/0003/selectMMDAAcno`, params);

/**
 * 운용상품 - MMDA 전체 계좌현황 조회(DB)
 * @param {object} params
 * @returns
 */

export const fetchMmdaPtsAllList = (params) =>
  instance.post(`${process.env.REACT_APP_SERVER_URL}/scq/scq/fxp/0003/selectAllMmdaActNo`, params);
