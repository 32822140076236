import { fetchNtList, fetchNtPkNmgt, insertNotice } from '../../../lib/sys/NtMng.api';
import { SUCCESS, REQUEST, FAILURE } from '../../../common/utils/action-type-utils';
import dayjs from 'dayjs';

// actions
const FETCH_NT_LIST = 'NtMng/fetchNtList';
const FETCH_NT_PK_NMGT = 'NtMng/fetchNtPkNmgt';
const INSERT_NOTICE = 'NtMng/insertNotice';
const SAVE_SEARCH_FORM_VALUES = 'NtMng/SAVE_SEARCH_FORM_VALUES';
const RESET_STATE = 'NtMng/RESET_STATE';

export const initFormValues = {
  anc_titl: '', //제목
  anc_sta_dt: dayjs().add(-12, 'M').format('YYYY-MM-DD'), //게시기간 시작일
  anc_end_dt: dayjs().format('YYYY-MM-DD'), //게시기간 종료일
};

// initial state
const initialState = {
  loading: false,
  success: true,
  message: '',
  list: [],
  details: [],
  totalCount: 0,
  updateSuccess: false,
  updating: false,
  mainPk: '',
  searchFormValues: {
    init: true,
    ...initFormValues,
  },
};

// reducers
export default function NtMng(state = initialState, action) {
  switch (action.type) {
    case SUCCESS(FETCH_NT_LIST): // 조회 성공
      return {
        ...state,
        success: true,
        loading: false,
        list: action.payload.data?.main,
        updateSuccess: false,
        totalCount: action.payload?.main_cnt,
      };
    case REQUEST(FETCH_NT_LIST): // 조회 요청
      return {
        ...state,
        updateSuccess: false,
        loading: true,
      };
    case FAILURE(FETCH_NT_LIST): // 조회실패
      return {
        ...initialState,
        loading: false,
        success: false,
        updateSuccess: false,
        message: action.payload.response?.data?.message,
      };
    case SUCCESS(FETCH_NT_PK_NMGT): // 채번 성공
      return {
        ...state,
        success: true,
        loading: false,
        mainPk: action.payload.data?.main_pk[0].pk,
        mainPkCnt: action.payload?.main_cnt,
        updateSuccess: false,
      };
    case REQUEST(FETCH_NT_PK_NMGT): // 채번 요청
      return {
        ...state,
        loading: true,
        updateSuccess: false,
      };
    case FAILURE(FETCH_NT_PK_NMGT): // 채번 실패
      return {
        ...initialState,
        loading: false,
        success: false,
        message: action.payload.response?.data?.message,
        updateSuccess: false,
      };
    case SUCCESS(INSERT_NOTICE): // 등록/수정 성공
      return {
        ...state,
        updateSuccess: true,
        updating: false,
        resultCnt: action.payload.data.resultCnt,
      };
    case REQUEST(INSERT_NOTICE): // 등록/수정 요청
      return {
        ...state,
        updating: true,
        updateSuccess: false,
      };
    case FAILURE(INSERT_NOTICE): // 등록/수정 실패
      return {
        ...initialState,
        updating: false,
        updateSuccess: false,
        resultCnt: -1,
        message: action.payload.response?.data?.message,
      };
    case SAVE_SEARCH_FORM_VALUES:
      return {
        ...state,
        searchFormValues: action.payload,
      };
    case RESET_STATE:
      return initialState;
    default:
      return { ...state, updateSuccess: false };
  }
}

/**
 * 공지사항 조회
 */
export const selectNtList = (searchParams) => {
  return {
    type: FETCH_NT_LIST,
    payload: fetchNtList(searchParams),
  };
};

/**
 * 공지사항 pk채번
 */
export const selectNtPkNmgt = (searchParams) => {
  return {
    type: FETCH_NT_PK_NMGT,
    payload: fetchNtPkNmgt(searchParams),
  };
};

/**
 * 공지사항 등록/수정
 */
export const updateNotice = (dtos) => {
  return {
    type: INSERT_NOTICE,
    payload: insertNotice(dtos),
  };
};

/**
 * 조회조건 저장
 */
export const saveSearchFormValues = (searchFormValues) => {
  return {
    type: SAVE_SEARCH_FORM_VALUES,
    payload: {
      ...searchFormValues,
      init: false,
    },
  };
};

/**
 * 상태초기화
 */
export const resetState = () => {
  return {
    type: RESET_STATE,
  };
};
