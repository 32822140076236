import { SUCCESS, REQUEST, FAILURE } from '../../../common/utils/action-type-utils';
import { fetchUninIst, saveUninIst } from '../../../lib/sys/SYS0001.api';
import { fetchCommonComboList } from '../../../lib/cmm/combo.api';

// actions
const FETCH_UNIN_IST = 'SYS0111M01/FETCH_UNIN_IST';
const SAVE_UNIN_IST = 'SYS0111M01/SAVE_UNIN_IST';
const FETCH_IST_GB_LIST = 'SYS0111M01/FETCH_IST_GB_LIST';

// initial state
const initialState = {
  loading: false,
  success: true,
  message: '',
  list: [],
  totalCount: 0,
  updating: false,
  updateSuccess: false,
  resultCnt: -1,

  //콤보 - 기관구분 003
  istGbSuccess: false,
  istGbLoading: false,
  istGbList: [],
};

// reducers
export default function SYS0111M01(state = initialState, action) {
  switch (action.type) {
    case SUCCESS(FETCH_UNIN_IST): // 조회 성공
      return {
        ...state,
        success: true,
        loading: false,
        list: action.payload.data?.main,
        totalCount: action.payload?.data?.mainCnt,
        updateSuccess: false,
      };
    case REQUEST(FETCH_UNIN_IST): // 조회 요청
      return {
        ...state,
        message: '',
        loading: true,
        success: false,
        updateSuccess: false,
      };
    case FAILURE(FETCH_UNIN_IST): // 조회실패
      return {
        ...state,
        list: [],
        loading: false,
        success: false,
        message: action.payload.response?.data?.message,
        updateSuccess: false,
      };
    case SUCCESS(SAVE_UNIN_IST): // 저장 성공
      return {
        ...state,
        updateSuccess: true,
        updating: false,
        resultCnt: action.payload.data.resultCnt,
      };
    case REQUEST(SAVE_UNIN_IST): // 저장 요청
      return {
        ...state,
        message: '',
        updating: true,
        updateSuccess: false,
      };
    case FAILURE(SAVE_UNIN_IST): // 저장 실패
      return {
        ...state,
        updating: false,
        updateSuccess: false,
        resultCnt: -1,
        message: action.payload.response?.data?.message,
      };
    // 시세/구세
    case SUCCESS(FETCH_IST_GB_LIST): // 조회 성공
      return {
        ...state,
        istGbSuccess: true,
        istGbLoading: false,
        istGbList: action.payload.data?.subDto,
      };
    case REQUEST(FETCH_IST_GB_LIST): // 조회 요청
      return {
        ...state,
        istGbLoading: true,
        istGbSuccess: false,
      };
    case FAILURE(FETCH_IST_GB_LIST): // 조회실패
      return {
        ...state,
        istGbLoading: false,
        istGbSuccess: false,
        istGbList: [],
      };
    default:
      return state;
  }
}

/**
 * 산하기관 행정동 관리 조회
 */
export const selectUninIst = (searchParams) => {
  return {
    type: FETCH_UNIN_IST,
    payload: fetchUninIst(searchParams),
  };
};

/**
 *  산하기관 갱신
 */
export const insertUninIst = (dtos) => {
  return {
    type: SAVE_UNIN_IST,
    payload: saveUninIst(dtos),
  };
};

//시세/구세 콤보 조회
export const selectIstGbList = () => {
  return {
    type: FETCH_IST_GB_LIST,
    payload: fetchCommonComboList({
      slt_box_no: '003',
      cd_grp_nm: '기관구분',
      sys_cd: 'CC',
    }),
  };
};
