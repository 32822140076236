import { SUCCESS, REQUEST, FAILURE } from '../../../common/utils/action-type-utils';
import { fetchPmnyFdsRuleList } from '../../../lib/fds/FDS0001.api';

// actions
const FETCH_PMNY_FDS_RULE_LIST = 'FDS0103V01/FETCH_PMNY_FDS_RULE_LIST';
const SAVE_SEARCH_FORM_VALUES = 'FDS0103V01/SAVE_SEARCH_FORM_VALUES';
const RESET_STATE = 'FDS0103V01/RESET_STATE';

export const initFormValues = {
  // 검색 영역
  rule_cd: 'all', //분야
  lev_cd: 'all', //등급
  use_yn: 'all', //활성화여부
};

// initial state
const initialState = {
  loading: false,
  success: false,
  message: '',
  list: [],
  totalCount: 0,
  // 검색조건
  searchFormValues: {
    init: true,
    ...initFormValues,
  },
};

// reducers
export default function FDS0103V01(state = initialState, action) {
  switch (action.type) {
    case SUCCESS(FETCH_PMNY_FDS_RULE_LIST): // 조회 성공
      return {
        ...state,
        success: true,
        loading: false,
        list: action.payload.data?.main,
        totalCount: action.payload?.data?.main_cnt,
      };
    case REQUEST(FETCH_PMNY_FDS_RULE_LIST): // 조회 요청
      return {
        ...state,
        loading: true,
        success: false,
        message: '',
      };
    case FAILURE(FETCH_PMNY_FDS_RULE_LIST): // 조회실패
      return {
        ...initialState,
        loading: false,
        success: false,
        message: action.payload.response?.data?.message,
      };
    case SAVE_SEARCH_FORM_VALUES:
      return {
        ...state,
        searchFormValues: action.payload,
      };
    case RESET_STATE:
      return initialState;
    default:
      return state;
  }
}

/**
 * 룰리스트 조회
 */
export const selectPmnyFdsRuleList = (searchParams) => {
  return {
    type: FETCH_PMNY_FDS_RULE_LIST,
    payload: fetchPmnyFdsRuleList(searchParams),
  };
};

/**
 * 조회조건 저장
 */
export const saveSearchFormValues = (searchFormValues) => {
  return {
    type: SAVE_SEARCH_FORM_VALUES,
    payload: {
      ...searchFormValues,
      init: false,
    },
  };
};

/**
 * 상태초기화
 */
export const resetState = () => {
  return {
    type: RESET_STATE,
  };
};
