import instance from '../../common/config/api';

/**
 * 시스템공통 - 질문답변게시판(Q&A)
 * @param {object} params
 * @returns
 */

export const fetcQnaList = (params) =>
  instance.post(`${process.env.REACT_APP_SERVER_URL}/scq/scq/sys/0001/selectQna`, params);

export const fetchQnaPkNmgt = (params) =>
  instance.post(`${process.env.REACT_APP_SERVER_URL}/scq/scq/sys/0001/selectQnaPkNmgt`, params);

export const insertQna = (dtos) =>
  instance.post(`${process.env.REACT_APP_SERVER_URL}/scq/scq/sys/0001/insertQna`, {
    subDto: dtos,
  });
