import instance from '../../common/config/api';

const prefix = `${process.env.REACT_APP_SERVER_URL}/scq/scq/axp/0005`;
/**
 * 세출 - 정부보관금 내역조회
 * @param {object} params
 * @returns
 */
export const fetchGvcmPrtsInqList = (params) => instance.post(`${prefix}/selectAnexGovCstdAmPrts`, params);

/**
 * 세출 - 세입세출현금계좌 조회
 * @param {object} params
 * @returns
 */
export const fetchRvexCsfActList = (params) => instance.post(`${prefix}/selectRvexCsfActList`, params);

/**
 * 세출 - 정부보관금 지급예상조회
 * @param {object} params
 * @returns
 */
export const fetchAnexGvcmPayFrcsAm = (params) => instance.post(`${prefix}/selectAnexGvcmPayFrcsAm`, params);
