import React, { useEffect } from 'react';
import { Outlet } from 'react-router';
import styled from 'styled-components';
import MainHeader from './MainHeader';
import MainFooter from './MainFooter';
import Lnb from './Lnb';
import MainFDS from './MainFDS';

import { useDispatch, useSelector } from 'react-redux';
import { selectMenuList } from '../../redux/modules/cmm/menu.reducer';
import useDefaultMenu from '../../hooks/useMenu';
import { fetchUserInfo } from '../../lib/cmm/CMMUser.api';
import WOLoading from '../common/WOLoading';

const MainLayout = ({ user }) => {
  const dispatch = useDispatch();

  const defaultMenuList = useDefaultMenu().getMenuList();
  const { loading, list: menuList, success, message } = useSelector((state) => state.menuState);

  useEffect(() => {
    const getSessionInfo = async () => {
      const { data: userInfo } = await fetchUserInfo();
      localStorage.setItem('user_info', JSON.stringify(userInfo));
    };
    getSessionInfo();
  }, []);

  useEffect(() => {
    if (menuList.length === 0) {
      dispatch(selectMenuList());
    }
  }, [dispatch, menuList.length]);

  return (
    <>
      {(loading || menuList.length === 0) && <WOLoading />}
      <MainLayoutWrap>
        <MainHeader />
        <Lnb menuList={menuList.length === 0 ? defaultMenuList : menuList} />
        <MainFDS user={user} />
        <Main>
          <Outlet />
        </Main>
        <MainFooter />
      </MainLayoutWrap>
    </>
  );
};

export default MainLayout;

const MainLayoutWrap = styled.div`
  width: 100%;
  height: calc(100% - 60px);
  padding-left: 80px;
  background-color: #fff;
`;

// content

const Main = styled.main`
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 50px 0;
  min-height: 100%;
  padding: 109px 40px 40px 500px;
  background-color: #fff;
  min-width: 1480px;

  .main-select-work-area {
    margin-bottom: 20px;
  }
`;
