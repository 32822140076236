import instance from '../../common/config/api';

const prefix = `${process.env.REACT_APP_SERVER_URL}/scq/scq/fxp/0002`;

/**
 * DB 서버시간 조회
 * @param {object} params
 * @returns
 */
export const fetchDbSysdate = (params) => instance.post(`${prefix}/selectDbSysdate`, params);

/**
 * 보통예금 계좌정보조회
 * @param {object} params
 * @returns
 */
export const fetchGndpActInf = (params) => instance.post(`${prefix}/selectGndpActInf`, params);

/**
 * 보통예금 계좌거래내역 조회
 * @param {object} params
 * @returns
 */
export const fetchGndpActTrnPrts = (params) => instance.post(`${prefix}/selectGndpActTrnPrts`, params);

/**
 * 거래명세 조회
 * @param {object} params
 * @returns
 */
export const fetchTrhs = (params) => instance.post(`${prefix}/selectTrhs`, params);

/**
 * 계좌 개설점/관리점 조회
 * @param {object} params
 * @returns
 */
export const fetchActOgbrMnbrNm = (params) => instance.post(`${prefix}/selectActOgbrMnbrNm`, params);
