import { SUCCESS, REQUEST, FAILURE } from '../../../common/utils/action-type-utils';
import { dataFormat } from '../../../common/utils/utils';
import {
  ZPB0106V01_Category_ChartOptions,
  zpbStlPtsChartOptions,
  zpbStlPtsInitSmorData,
} from '../../../data/initalData';
import { zpbStlPtsCategoryComboList, zpbStlPtsInqDisComboList, zpbUpcheGbnCdComboList } from '../../../data/const';
import dayjs from 'dayjs';
import { fetchCommonComboList } from '../../../lib/cmm/combo.api';

import {
  fetchByAmStlPts,
  fetchByAmStlPtsSmor,
  fetchByBzctStlPts,
  fetchByBzctStlPtsSmor,
  fetchByIstStlPts,
  fetchByIstStlPtsList,
  fetchByIstStlPtsSmor,
  fetchByPrdStlPts,
  fetchByPrdStlPtsRk,
  fetchByPrdStlPtsSmor,
  fetchByRgnStlPts,
  fetchByRgnStlPtsSmor,
  fetchByTimStlPts,
  fetchByTimStlPtsSmor,
  fetchByWkdStlPts,
  fetchByWkdStlPtsSmor,
  fetchIstUseStlPtsList,
} from '../../../lib/zpb/zpbStlPts.api';
// 차트 데이터 payload 세팅
const getChartPayload = async (searchParams, category) => {
  switch (category.id) {
    case '1': // 기간별
      const result1 = await fetchByPrdStlPts(searchParams);
      return { result: result1, category };
    case '2': // 요일별
      const result2 = await fetchByWkdStlPts(searchParams);
      return { result: result2, category };
    case '3': // 시간별
      const result3 = await fetchByTimStlPts(searchParams);
      return { result: result3, category };
    case '4': // 금액별
      const result4 = await fetchByAmStlPts(searchParams);
      return { result: result4, category };
    case '5': // 업종별
      const result5 = await fetchByBzctStlPts(searchParams);
      return { result: result5, category };
    case '6': // 기관/부서별
      const result6 = await fetchByIstStlPts(searchParams);
      return { result: result6, category };
    case '7': // 지역별
      const result7 = await fetchByRgnStlPts(searchParams);
      return { result: result7, category };
    default:
      return { result: result1, category };
  }
};

// 요약 데이터 payload 세팅
const getSmorPayload = async (searchParams, category) => {
  switch (category.id) {
    case '1': // 기간별
      const result1 = await fetchByPrdStlPtsSmor(searchParams);
      return { result: result1, category };
    case '2': // 요일별
      const result2 = await fetchByWkdStlPtsSmor(searchParams);
      return { result: result2, category };
    case '3': // 시간별
      const result3 = await fetchByTimStlPtsSmor(searchParams);
      return { result: result3, category };
    case '4': // 금액별
      const result4 = await fetchByAmStlPtsSmor(searchParams);
      return { result: result4, category };
    case '5': // 업종별
      const result5 = await fetchByBzctStlPtsSmor(searchParams);
      return { result: result5, category };
    case '6': // 기관/부서별
      const result6 = await fetchByIstStlPtsSmor(searchParams);
      return { result: result6, category };
    case '7': // 지역별
      const result7 = await fetchByRgnStlPtsSmor(searchParams);
      return { result: result7, category };
    default:
      return { result: result1, category };
  }
};

// 차트 데이터 가공
const getChartData = (data, category) => {
  // category.type : 'period', 'day' ...

  const xTitle =
    category.type === 'amount'
      ? 'dis_nm'
      : category.type === 'industry'
      ? 'bzct_nm'
      : category.type === 'department'
      ? 'dept_nm'
      : category.type === 'region'
      ? 'local'
      : 'apv_dt';
  const inqDisNm =
    category.type2 === 'M' ? '월' : category.type2 === 'Q' ? '분기' : category.type2 === 'D' ? '일' : '년';
  const categories = data.subDto.map((item) => item[xTitle]); // x축 타이틀
  zpbStlPtsChartOptions.series[0].name = inqDisNm + ' 총 결제금액';
  zpbStlPtsChartOptions.series[1].name = inqDisNm + ' 최고결제 금액';
  let chartOptions =
    category.type === 'period' ? zpbStlPtsChartOptions : ZPB0106V01_Category_ChartOptions[category.type];

  const columnDatas = data.subDto.map((item) => ({
    y: item.apv_sum_am,
    additionalData: item.apv_tot_cnt,
    additionalSuffix: '건',
  })); // 월 총 결제금액
  const lineDatas = data.subDto.map((item) => ({
    y: item.apv_max_am,
    biz_nm: item.biz_nm,
    max_apv_dept_nm: item.max_apv_dept_nm,
    max_apv_user_id: item.max_apv_user_id,
  })); // 월 최고결제 금액

  let resultData = {};

  switch (category.id) {
    case '3': // 시간별
      resultData = {
        ...chartOptions,
        xAxis: chartOptions.xAxis.map((xAxiItem) => ({ ...xAxiItem, categories })),
        series: chartOptions.series.map((serieItem) =>
          serieItem.type === 'column'
            ? { ...serieItem, data: data.subDto.map((item) => item.apv_sum_am) }
            : { ...serieItem, data: data.subDto.map((item) => item.cnt) }
        ),
      };
      break;
    case '4': // 금액별
    case '5': // 업종별
    case '6': // 기관/부서별
    case '7': // 지역별
      const secondDataName = category.id === '6' ? 'exu_sum_am' : 'cnt';
      resultData = {
        ...chartOptions,
        xAxis: chartOptions.xAxis.map((xAxiItem) => ({ ...xAxiItem, categories })),
        series: chartOptions.series.map((serieItem, index) =>
          index === 0
            ? { ...serieItem, data: data.subDto.map((item) => item.apv_sum_am) }
            : { ...serieItem, data: data.subDto.map((item) => item[secondDataName]) }
        ),
      };
      break;
    default:
      resultData = {
        ...chartOptions,
        xAxis: chartOptions.xAxis.map((xAxiItem) => ({ ...xAxiItem, categories })),
        series: chartOptions.series.map((serieItem) =>
          serieItem.type === 'column' ? { ...serieItem, data: columnDatas } : { ...serieItem, data: lineDatas }
        ),
      };
      break;
  }

  return resultData;
};

// 요약 데이터 가공
const getSmorData = (data, category) => {
  switch (category.id) {
    case '1': // 기간별
      return [
        {
          id: 1,
          title: '기간내 총 결제금액',
          value: dataFormat.number(data.sum_apv_sum_am),
          unit: '원',
          isHigh: false,
        },
        {
          id: 2,
          title: '기간내 최고결제금액',
          value: dataFormat.number(data.max_apv_sum_am),
          unit: '원',
          isHigh: true,
        },
      ];
    case '2': // 요일별
      return [
        {
          id: 1,
          title: '기간내 총 결제금액',
          value: dataFormat.number(data.sum_apv_sum_am),
          unit: '원',
          isHigh: false,
        },
        {
          id: 2,
          title: '일 최고결제금액',
          value: dataFormat.number(data.max_apv_sum_am),
          unit: '원',
          isHigh: true,
        },
      ];
    case '3': // 시간별
      return [
        {
          id: 1,
          title: '기간내 총 결제금액',
          value: dataFormat.number(data.sum_apv_sum_am),
          unit: '원',
          isHigh: false,
        },
        {
          id: 2,
          title: '시간내 최고결제금액',
          value: dataFormat.number(data.max_apv_sum_am),
          unit: '원',
          isHigh: true,
        },
        {
          id: 3,
          title: '기간내 누적결제건수',
          value: dataFormat.number(data.cnt),
          unit: '건',
          isHigh: false,
        },
      ];
    case '4': // 금액별
      return [
        {
          id: 1,
          title: '기간내 총 결제건수',
          value: dataFormat.number(data.cnt),
          unit: '건',
          isHigh: false,
        },
        {
          id: 2,
          title: '기간내 최다결제 구간',
          value: data.dis_nm,
          isHigh: true,
        },
      ];
    case '5': // 업종별
      return [
        {
          id: 1,
          title: '기간내 총 결제건수',
          value: dataFormat.number(data.cnt),
          unit: '건',
          isHigh: false,
        },
        {
          id: 2,
          title: '기간내 총결제건수(상위10개)',
          value: dataFormat.number(data.top_ten_cnt),
          unit: '건',
          isHigh: true,
        },
        {
          id: 3,
          title: '기간내 최고 결제 업종명',
          value: data.max_apv_sum_bzct,
          isHigh: true,
        },
      ];
    case '6': // 기관/부서별
      return [
        {
          id: 1,
          title: '기간내 총 결제금액',
          value: dataFormat.number(data.sum_apv_sum_am),
          unit: '원',
          isHigh: false,
        },
        {
          id: 2,
          title: '기간내 총집행금액',
          value: dataFormat.number(data.sum_exu_sum_am),
          unit: '원',
          isHigh: true,
        },
      ];
    case '7': // 지역별
      return [
        {
          id: 1,
          title: '기간내 총 결제금액',
          value: dataFormat.number(data.sum_apv_sum_am),
          unit: '원',
          isHigh: false,
        },
        {
          id: 2,
          title: '기간내 최고결제 행정구역',
          value: data.max_apv_sum_local,
          isHigh: true,
        },
      ];
    default:
      return zpbStlPtsInitSmorData;
  }
};

// actions
const FETCH_BY_PRD_STL_PTS = 'zpbStlPts/FETCH_BY_PRD_STL_PTS';
const FETCH_BY_PRD_STL_PTS_SMOR = 'zpbStlPts/FETCH_BY_PRD_STL_PTS_SMOR';
const FETCH_BY_PRD_STL_PTS_RK = 'zpbStlPts/FETCH_BY_PRD_STL_PTS_RK';
// 기관/부서별
const FETCH_BY_IST_STL_PTS = 'zpbStlPts/FETCH_BY_IST_STL_PTS'; // 목록
const FETCH_BY_IST_USE_STL_PTS = 'zpbStlPts/FETCH_BY_IST_USE_STL_PTS'; // 사용현황
//const RESET_MSG = 'zpbStlPts/RESET_MSG';

//기관
const FETCH_IST_INFO_LIST = 'zpbStlPts/FETCH_IST_INFO_LIST';
const FETCH_IST_INFO_LIST_005 = 'zpbStlPts/FETCH_IST_INFO_LIST_005';
//부서
const FETCH_DEPT_CD_LIST = 'zpbStlPts/FETCH_DEPT_CD_LIST';
const SAVE_SEARCH_FORM_VALUES = 'zpbStlPts/SAVE_SEARCH_FORM_VALUES';
const RESET_STATE = 'zpbStlPts/RESET_STATE';

/**
 * 날짜 생성 함수
 */
const createDate = (date, type, step) => {
  return dayjs(date).add(step, type).format('YYYY-MM-DD');
};

export const initFormValues = {
  category: '1', // 구분 (default: 기간별)
  pbam_acno: '', // 결제계좌
  bzno: '', // 사업자번호
  inq_dis: 'D', // 조회기간 (default: 월간) => 너무 느려서 일간으로 변경함
  st_date: createDate(dayjs().add(-1, 'day').format('YYYY-MM-DD'), 'day', -14), // 조회 시작일
  ed_date: dayjs().add(-1, 'day').format('YYYY-MM-DD'), // 조회 종료일
  dept_nm: 'all', // 부서명
  sysRoleDscd: '1', // 권한구분 (1인경우 모두 조회, 아닌경우 user_id로 검색권한 검증)
  local: 'all', // 사용 지역별일 경우
  mrch_inq_dis: 'A', // A:최고사용금액순 C: 최다사용건수
};

// initial state
const initialState = {
  loading: false,
  success: false,
  message: '',
  chart: [], // 기간별 차트
  summary: [], // 기간별 요약
  rankList: [], // 랭킹
  istUseStlList: [], // 기관/부서별 사용현황
  totalCount: 0,
  chartLoading: false,

  istList: [], // 기관/부서별 목록
  istLoading: false,
  istSuccess: false,
  istMessage: '',
  //콤보 - 기관명
  bznmList: [],
  bznmLoading: false,
  bznmSuccess: true,
  bznmList005: [],
  bznmLoading005: false,
  bznmSuccess005: true,
  // 부서명
  deptNmSuccess: false,
  deptNmLoading: false,
  deptNmList: [],
  //구분 list
  localList: [
    { id: 'all', label: '전체' },
    { id: '서울', label: '서울' },
    { id: '경기도', label: '경기도' },
    { id: '인천', label: '인천' },
    { id: '강원도', label: '강원도' },
    { id: '충청북도', label: '충청북도' },
    { id: '대전', label: '대전' },
    { id: '경상북도', label: '경상북도' },
    { id: '대구', label: '대구' },
    { id: '울산', label: '울산' },
    { id: '부산', label: '부산' },
    { id: '경상남도', label: '경상남도' },
    { id: '전라북도', label: '전라북도' },
    { id: '전라남도', label: '전라남도' },
    { id: '광주', label: '광주' },
    { id: '제주도', label: '제주도' },
  ],
  zpbStlPtsCategoryComboList: zpbStlPtsCategoryComboList,
  zpbStlPtsInqDisComboList: zpbStlPtsInqDisComboList,
  zpbUpcheGbnCdComboList: zpbUpcheGbnCdComboList,
  searchFormValues: {
    init: true,
    ...initFormValues,
  },
};

// reducers
export default function zpbStlPts(state = initialState, action) {
  switch (action.type) {
    case SUCCESS(FETCH_BY_PRD_STL_PTS): // 조회 성공 - 기간별 차트
      return {
        ...state,
        success: true,
        loading: false,
        chart: getChartData(action.payload.result.data, action.payload.category),
        totalCount: action.payload.result.data?.subDtoCnt,
        chartLoading: false,
      };
    case REQUEST(FETCH_BY_PRD_STL_PTS): // 조회 요청 - 기간별 차트
      return {
        ...state,
        loading: true,
        success: false,
        chartLoading: true,
      };
    case FAILURE(FETCH_BY_PRD_STL_PTS): // 조회실패 - 기간별 차트
      return {
        ...state,
        loading: false,
        success: false,
        message: action.payload.response?.data?.message,
        chartLoading: false,
      };
    case SUCCESS(FETCH_BY_PRD_STL_PTS_SMOR): // 조회 성공 - 기간별 요약
      return {
        ...state,
        success: true,
        loading: false,
        summary: getSmorData(action.payload.result.data, action.payload.category),
        totalCount: action.payload.result.data?.subDtoCnt,
      };
    case REQUEST(FETCH_BY_PRD_STL_PTS_SMOR): // 조회 요청 - 기간별 요약
      return {
        ...state,
        loading: true,
        success: false,
      };
    case FAILURE(FETCH_BY_PRD_STL_PTS_SMOR): // 조회실패 - 기간별 요약
      return {
        ...state,
        loading: false,
        success: false,
        message: action.payload.response?.data?.message,
      };
    case SUCCESS(FETCH_BY_PRD_STL_PTS_RK): // 조회 성공 - 랭킹
      return {
        ...state,
        success: true,
        loading: false,
        rankList: action.payload.data.subDto,
        totalCount: action.payload.data?.subDtoCnt,
      };
    case REQUEST(FETCH_BY_PRD_STL_PTS_RK): // 조회 요청 - 랭킹
      return {
        ...state,
        loading: true,
        success: false,
      };
    case FAILURE(FETCH_BY_PRD_STL_PTS_RK): // 조회실패 - 랭킹
      return {
        ...state,
        loading: false,
        success: false,
        message: action.payload.response?.data?.message,
      };
    case SUCCESS(FETCH_BY_IST_STL_PTS): // 조회 성공 - 기관/부서별 목록 (팝업)
      return {
        ...state,
        istSuccess: true,
        istLoading: false,
        istList: action.payload.data.subDto,
        totalCount: action.payload.data?.subDtoCnt,
      };
    case REQUEST(FETCH_BY_IST_STL_PTS): // 조회 요청 - 기관/부서별 목록 (팝업)
      return {
        ...state,
        istLoading: true,
        istSuccess: false,
      };
    case FAILURE(FETCH_BY_IST_STL_PTS): // 조회실패 - 기관/부서별 목록 (팝업)
      return {
        ...state,
        istLoading: false,
        istSuccess: false,
        istMessage: action.payload.response?.data?.message,
      };
    case SUCCESS(FETCH_BY_IST_USE_STL_PTS): // 조회 성공 - 기관/부서별 사용현황
      return {
        ...state,
        success: true,
        loading: false,
        istUseStlList: action.payload.data.subDto,
        totalCount: action.payload.data?.subDtoCnt,
      };
    case REQUEST(FETCH_BY_IST_USE_STL_PTS): // 조회 요청 - 기관/부서별 사용현황
      return {
        ...state,
        loading: true,
        success: false,
      };
    case FAILURE(FETCH_BY_IST_USE_STL_PTS): // 조회실패 - 기관/부서별 사용현황
      return {
        ...state,
        loading: false,
        success: false,
        message: action.payload.response?.data?.message,
      };
    case SUCCESS(FETCH_IST_INFO_LIST): // 조회 성공
      return {
        ...state,
        bznmSuccess: true,
        bznmLoading: false,
        bznmList: action.payload.data?.subDto,
      };
    case REQUEST(FETCH_IST_INFO_LIST): // 조회 요청
      return {
        ...state,
        bznmLoading: true,
        bznmSuccess: false,
      };
    case FAILURE(FETCH_IST_INFO_LIST): // 조회실패
      return {
        bznmLoading: false,
        bznmSuccess: false,
        bznmList: [],
      };
    case SUCCESS(FETCH_IST_INFO_LIST_005): // 조회 성공
      return {
        ...state,
        bznmSuccess005: true,
        bznmLoading005: false,
        bznmList005: action.payload.data?.subDto,
      };
    case REQUEST(FETCH_IST_INFO_LIST_005): // 조회 요청
      return {
        ...state,
        bznmLoading005: true,
        bznmSuccess005: false,
      };
    case FAILURE(FETCH_IST_INFO_LIST_005): // 조회실패
      return {
        bznmLoading005: false,
        bznmSuccess005: false,
        bznmList005: [],
      };
    // 부서명
    case SUCCESS(FETCH_DEPT_CD_LIST): // 조회 성공
      return {
        ...state,
        deptNmSuccess: true,
        deptNmLoading: false,
        deptNmList: action.payload.data?.subDto,
      };
    case REQUEST(FETCH_DEPT_CD_LIST): // 조회 요청
      return {
        ...state,
        deptNmLoading: true,
        deptNmSuccess: false,
      };
    case FAILURE(FETCH_DEPT_CD_LIST): // 조회실패
      return {
        ...state,
        deptNmLoading: false,
        deptNmSuccess: false,
        deptNmList: [],
      };
    case SAVE_SEARCH_FORM_VALUES:
      return {
        ...state,
        // 콤보 - 기관
        bznmSuccess: false,
        bznmLoading: false,
        bznmSuccess005: false,
        bznmLoading005: false,
        // 콤보 - 부서명
        deptNmSuccess: false,
        deptNmLoading: false,
        searchFormValues: action.payload,
      };
    case RESET_STATE:
      return initialState;
    default:
      return state;
  }
}

/**
 * 제로페이 - 결제현황조회 (기간별 차트 데이터)
 */
export const selectByPrdStlPts = (searchParams, category) => {
  return {
    type: FETCH_BY_PRD_STL_PTS,
    payload: getChartPayload(searchParams, category),
  };
};

/**
 * 제로페이 - 결제현황조회 (기간별 요약 데이터)
 */
export const selectByPrdStlPtsSmor = (searchParams, category) => {
  return {
    type: FETCH_BY_PRD_STL_PTS_SMOR,
    payload: getSmorPayload(searchParams, category),
  };
};

/**
 * 제로페이 - 결제현황조회 (랭킹)
 */
export const selectByPrdStlPtsRk = (searchParams) => {
  return {
    type: FETCH_BY_PRD_STL_PTS_RK,
    payload: fetchByPrdStlPtsRk(searchParams),
  };
};

/**
 * 제로페이 - 결제현황조회 (기관/부서별 목록 (팝업))
 */
export const selectByIstStlPtsList = (searchParams) => {
  return {
    type: FETCH_BY_IST_STL_PTS,
    payload: fetchByIstStlPtsList(searchParams),
  };
};

/**
 * 제로페이 - 결제현황조회 (기관/부서별 사용현황)
 */
export const selectByIstUseStlPtsList = (searchParams) => {
  return {
    type: FETCH_BY_IST_USE_STL_PTS,
    payload: fetchIstUseStlPtsList(searchParams),
  };
};
/**
 * 기관명 콤보 조회
 */
export const selectIstInfoComboList = (searchParams) => {
  return {
    type: FETCH_IST_INFO_LIST,
    payload: fetchCommonComboList({ slt_box_no: '901', ...searchParams }),
  };
};

/**
 * 기관명 005콤보 조회
 */
export const selectIstInfoComboList005 = (searchParams) => {
  return {
    type: FETCH_IST_INFO_LIST_005,
    payload: fetchCommonComboList({ slt_box_no: '005', ...searchParams }),
  };
};
/**
 * 부서명 콤보 조회
 */
export const selectDeptCdComboList = (searchParams) => {
  return {
    type: FETCH_DEPT_CD_LIST,
    payload: fetchCommonComboList({ slt_box_no: '902', ...searchParams }),
  };
};

/**
 * 조회조건 저장
 */
export const saveSearchFormValues = (searchFormValues) => {
  return {
    type: SAVE_SEARCH_FORM_VALUES,
    payload: {
      ...searchFormValues,
      init: false,
    },
  };
};

/**
 * 초기화
 */
export const resetZpbStlPts = () => {
  return {
    type: RESET_STATE,
  };
};
