import instance from '../../common/config/api';

/**
 * 시스템공통 - 사용자이력조회
 * @param {object} params
 * @returns
 */

export const fetchUserAcsLog = (params) =>
  instance.post(`${process.env.REACT_APP_SERVER_URL}/scq/scq/sys/0002/selectUserAcsLog`, params);
