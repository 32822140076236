import { fetchActNoList, insertActNo, updateEndMoveAut, updateEndwCopyAut } from '../../../lib/sys/SYS0102M01.api';
import { SUCCESS, REQUEST, FAILURE } from '../../../common/utils/action-type-utils';

// actions
const FETCH_ACT_NO_LIST = 'SYS0102M01/FETCH_ACT_NO_LIST';
const INSERT_ACT_NO = 'SYS0102M01/INSERT_ACT_NO';
const UPDATE_END_MOVE = 'SYS0102M01/UPDATE_END_MOVE';
const UPDATE_END_COPY = 'SYS0102M01/UPDATE_END_COPY';
const SAVE_SEARCH_FORM_VALUES = 'SYS0102M01/SAVE_SEARCH_FORM_VALUES';
const RESET_STATE = 'SYS0102M01/RESET_STATE';

export const initFormValues = {
  ist_cd: '000', //기관명
  actno_use_dscd: 'all', //계좌사용구분
  rel_flag: 'all', //연결계좌 여부
  pmdp_act_dscd: 'all', //세입/세출구분
  axpn_act_dscd: 'all', //세출계좌구분
  arvn_act_dscd: 'all', //시구세구분
};

// initial state
const initialState = {
  loading: false,
  success: false,
  message: '',
  list: [],
  details: [],
  totalCount: 0,
  updateSuccess: false,
  resultCnt: -1,
  subList: [],
  endMoveSuccess: false,
  endCopySuccess: false,
  // 검색조건
  searchFormValues: {
    init: true,
    ...initFormValues,
  },
};

// reducers
export default function SYS0102M01(state = initialState, action) {
  switch (action.type) {
    case SUCCESS(FETCH_ACT_NO_LIST): // 조회 성공
      return {
        ...state,
        success: true,
        loading: false,
        list: action.payload.data?.main,
        totalCount: action.payload?.main_cnt,
        updateSuccess: false,
      };
    case REQUEST(FETCH_ACT_NO_LIST): // 조회 요청
      return {
        ...state,
        loading: true,
        message: '',
        updateSuccess: false,
      };
    case FAILURE(FETCH_ACT_NO_LIST): // 조회실패
      return {
        ...initialState,
        loading: false,
        success: false,
        message: action.payload.response?.data?.message,
        updateSuccess: false,
      };
    case SUCCESS(INSERT_ACT_NO): // 갱신 성공
      return {
        ...state,
        updateSuccess: true,
        loading: false,
        resultCnt: action.payload.data.resultCnt,
      };
    case REQUEST(INSERT_ACT_NO): // 갱신 요청
      return {
        ...state,
        loading: true,
        updateSuccess: false,
      };
    case FAILURE(INSERT_ACT_NO): // 갱신 실패
      return {
        ...initialState,
        loading: false,
        updateSuccess: false,
        resultCnt: -1,
        message: action.payload.response?.data?.message,
      };
    case SUCCESS(UPDATE_END_MOVE): // 기금권한 이관 성공
      return {
        ...state,
        endMoveSuccess: true,
        loading: false,
        resultCnt: action.payload.data.resultCnt,
      };
    case REQUEST(UPDATE_END_MOVE): // 기금권한 이관 요청
      return {
        ...state,
        loading: true,
        endMoveSuccess: false,
      };
    case FAILURE(UPDATE_END_MOVE): // 기금권한 이관 실패
      return {
        ...initialState,
        loading: false,
        endMoveSuccess: false,
        resultCnt: -1,
        message: action.payload.response?.data?.message,
      };
    case SUCCESS(UPDATE_END_COPY): // 기금권한 복사 성공
      return {
        ...state,
        endCopySuccess: true,
        loading: false,
        resultCnt: action.payload.data.resultCnt,
      };
    case REQUEST(UPDATE_END_COPY): // 기금권한 복사 요청
      return {
        ...state,
        loading: true,
        endCopySuccess: false,
      };
    case FAILURE(UPDATE_END_COPY): // 기금권한 복사 실패
      return {
        ...initialState,
        loading: false,
        endCopySuccess: false,
        resultCnt: -1,
        message: action.payload.response?.data?.message,
      };
    case SAVE_SEARCH_FORM_VALUES:
      return {
        ...state,
        searchFormValues: action.payload,
      };
    case RESET_STATE:
      return initialState;
    default:
      return { ...state, updateSuccess: false, endMoveSuccess: false, endCopySuccess: false };
  }
}

/**
 * 계좌원장 조회
 */
export const selectActNoList = (searchParams) => {
  return {
    type: FETCH_ACT_NO_LIST,
    payload: fetchActNoList(searchParams),
  };
};

/**
 * 계좌원장 저장
 */
export const updateActNo = (dtos) => {
  return {
    type: INSERT_ACT_NO,
    payload: insertActNo(dtos),
  };
};

/**
 * 기금권한 이관
 */
export const updateEndMove = (dtos) => {
  return {
    type: UPDATE_END_MOVE,
    payload: updateEndMoveAut(dtos),
  };
};

/**
 * 기금권한 복사
 */
export const updateEndCopy = (dtos) => {
  return {
    type: UPDATE_END_COPY,
    payload: updateEndwCopyAut(dtos),
  };
};

/**
 * 조회조건 저장
 */
export const saveSearchFormValues = (searchFormValues) => {
  return {
    type: SAVE_SEARCH_FORM_VALUES,
    payload: {
      ...searchFormValues,
      init: false,
    },
  };
};

/**
 * 상태초기화
 */
export const resetState = () => {
  return {
    type: RESET_STATE,
  };
};
