import { SUCCESS, REQUEST, FAILURE } from '../../../common/utils/action-type-utils';
import { fetchEtcAsstPrbtTndcList } from '../../../lib/etc/etcAsstPrbtTndc';
import dayjs from 'dayjs';
import { fetchCommonComboList } from '../../../lib/cmm/combo.api';
// actions
const FETCH_ASSTPRBTTNDC = 'etcAsstPrbtTndc/FETCH_ASSTPRBTTNDC';
const SAVE_SEARCH_FORM_VALUES = 'etcAsstPrbtTndc/SAVE_SEARCH_FORM_VALUES';
const RESET_STATE = 'etcAsstPrbtTndc/RESET_STATE';
const FETCH_BR_CD_LIST = 'etcAsstPrbtTndc/SAVE_SEARCH_FORM_VALUES';

export const initFormValues = {
  acc_yr: dayjs().format('YYYY'), // 기준연도
  ydcs8a1_t8a01_inq_seg1: 'all', // 상품구분
  ydcs8a1_t8a01_inq_seg3: '1', // 항목구분
  // ydcs8a1_t8a01_inq_seg: 'all', // 점출력구분
  // ydcs8a1_t8a01_inq_seg: userGb === 1 ? 'all' : userGb.toString(), // 점출력구분
  br_cd: '', // 영업점코드 (점출력구분 값이 '개별영업점'인 경우 값 추가)
};

const allOption = { id: 'all', label: '전체' };
// initial state
const initialState = {
  loading: false,
  success: false,
  message: '',
  list: [],
  totalCount: 0,
  ydcs8a1T8a01InqSeg1List: [
    { ...allOption },
    { id: '2', label: '보조금관리통장' },
    { id: '3', label: '복지시설통장' },
    { id: '4', label: '보육시설통장' },
  ],
  ydcs8a1T8a01InqSeg3List: [
    { id: '1', label: '순이자이익' },
    { id: '5', label: '영업수익' },
    { id: '4', label: '위험조정영업수익' },
    { id: '6', label: '공헌수익' },
    { id: '2', label: '위험조정이익(RAR)' },
  ],
  ydcs8a1T8a01InqSegList: [{ ...allOption }, { id: '2', label: '시구청영업점' }, { id: '3', label: '개별영업점' }],
  searchFormValues: {
    init: true,
    ...initFormValues,
  },
  brCdLoading: false,
  brCdList: [],
  brCdSuccess: false,
};

// reducers
export default function etcAsstPrbtTndc(state = initialState, action) {
  switch (action.type) {
    case SUCCESS(FETCH_ASSTPRBTTNDC): // 조회 성공
      return {
        ...state,
        success: true,
        loading: false,
        list: action.payload.data?.subDto,
        totalCount: action.payload?.subDtoCnt,
      };
    case REQUEST(FETCH_ASSTPRBTTNDC): // 조회 요청
      return {
        ...state,
        loading: true,
        success: false,
      };
    case FAILURE(FETCH_ASSTPRBTTNDC): // 조회실패
      return {
        ...initialState,
        loading: false,
        success: false,
        message: action.payload.response?.data?.message,
      };
    case SUCCESS(FETCH_BR_CD_LIST): // 조회 성공
      return {
        ...state,
        brCdSuccess: true,
        brCdLoading: false,
        brCdList: action.payload.data?.subDto,
      };
    case REQUEST(FETCH_BR_CD_LIST): // 조회 요청
      return {
        ...state,
        brCdLoading: true,
        brCdSuccess: false,
      };
    case FAILURE(FETCH_BR_CD_LIST): // 조회실패
      return {
        ...state,
        brCdLoading: false,
        brCdSuccess: false,
        brCdList: [],
      };
    case RESET_STATE:
      return initialState;
    case SAVE_SEARCH_FORM_VALUES:
      return {
        ...state,
        // 콤보 - 기관
        brCdSuccess: false,
        brCdLoading: false,
        searchFormValues: action.payload,
      };
    default:
      return state;
  }
}

/**
 * 기타 - 보조금 수익성 추세
 */
export const selectEtcAsstPrbtTndcList = (searchParams) => {
  return {
    type: FETCH_ASSTPRBTTNDC,
    payload: fetchEtcAsstPrbtTndcList(searchParams),
  };
};

/**
 * 조회조건 저장
 */
export const saveSearchFormValues = (searchFormValues) => {
  return {
    type: SAVE_SEARCH_FORM_VALUES,
    payload: {
      ...searchFormValues,
      init: false,
    },
  };
};

/**
 * 보조금 수익성 추세 상태초기화
 */
export const resetEtcAsstPrbtTndc = () => {
  return {
    type: RESET_STATE,
  };
};

/**
 * 부서명 콤보 조회
 */
export const selectDeptCdComboList = (searchParams) => {
  return {
    type: FETCH_BR_CD_LIST,
    payload: fetchCommonComboList({ slt_box_no: '201', ...searchParams }),
  };
};
