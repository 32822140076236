import { SUCCESS, REQUEST, FAILURE } from '../../../common/utils/action-type-utils';
import { fetchProposalList, fetchProposalPkNmgt, insertProposalList } from '../../../lib/brc/brc0210v01.api';
import dayjs from 'dayjs';

// actions
const FETCH_PROPOSAL = 'brcProposal/FETCH_PROPOSAL';
const UPDATE_PROPOSAL = 'brcProposal/UPDATE_PROPOSAL';
const UPDATE_INIT = 'brcProposal/UPDATE_INIT';
const FETCH_PROPOSAL_PK_NMGT = 'brcProposal/FETCH_PROPOSAL_PK_NMGT';

const RESET_STATE = 'brcProposal/RESET_STATE';
const SAVE_SEARCH_FORM_VALUES = 'brcProposal/SAVE_SEARCH_FORM_VALUES';

export const initFormValues = {
  sch_gubun: '1', // 검색조건
  sch_txt: '', // 텍스트
  sch_sta_dt: dayjs().add(-6, 'month').format('YYYY-MM-DD'), // 작성기간
  sch_end_dt: dayjs().format('YYYY-MM-DD'), // 작성기간
  asw_sts_cd: 'all', // 답변상태
  gb: '1',
};

// initial state
const initialState = {
  loading: false,
  success: false,
  message: '',
  list: [],
  totalCount: 0,
  updateLoading: false, // 등록/수정
  updateSuccess: false, // 등록/수정
  updateCount: false, // 등록/수정
  mainPk: '', // 채번
  mainPkCnt: 0, // 채번
  schGubunList: [
    { id: '1', label: '제목' },
    { id: '2', label: '제목+내용' },
    { id: '3', label: '작성자명' },
  ],
  searchFormValues: {
    init: true,
    ...initFormValues,
  },
};

// reducers
export default function brcProposal(state = initialState, action) {
  switch (action.type) {
    case SUCCESS(FETCH_PROPOSAL): // 조회 성공
      return {
        ...state,
        success: true,
        loading: false,
        list: action.payload.data?.main,
        totalCount: action.payload.data?.main_cnt,
      };
    case REQUEST(FETCH_PROPOSAL): // 조회 요청
      return {
        ...state,
        loading: true,
        success: false,
      };
    case FAILURE(FETCH_PROPOSAL): // 조회실패
      return {
        ...initialState,
        loading: false,
        success: false,
        message: action.payload.response?.data?.message,
      };
    case SUCCESS(UPDATE_PROPOSAL): // 등록/수정 성공
      return {
        ...state,
        updateSuccess: true,
        updateLoading: false,
        updateCount: action.payload.data?.resultCnt,
      };
    case REQUEST(UPDATE_PROPOSAL): // 등록/수정 요청
      return {
        ...state,
        updateLoading: true,
        updateSuccess: false,
      };
    case FAILURE(UPDATE_PROPOSAL): // 등록/수정실패
      return {
        ...state,
        updateLoading: false,
        updateSuccess: false,
        message: action.payload.response?.data?.message,
      };
    case UPDATE_INIT: // 등록/수정실패 초기화
      return {
        ...state,
        updateLoading: false,
        updateSuccess: false,
      };
    case SUCCESS(FETCH_PROPOSAL_PK_NMGT): // 채번 성공
      return {
        ...state,
        success: true,
        loading: false,
        mainPk: action.payload.data?.main_pk[0].pk,
        mainPkCnt: action.payload?.main_cnt,
      };
    case REQUEST(FETCH_PROPOSAL_PK_NMGT): // 채번 요청
      return {
        ...state,
        loading: true,
      };
    case FAILURE(FETCH_PROPOSAL_PK_NMGT): // 채번 실패
      return {
        ...state,
        loading: false,
        success: false,
        message: action.payload.response?.data?.message,
      };
    case SAVE_SEARCH_FORM_VALUES:
      return {
        ...state,
        searchFormValues: action.payload,
      };
    case RESET_STATE:
      return initialState;
    default:
      return state;
  }
}

/**
 * 금고영업점 - 지자체 정보관리 - 지자체 정보 등록 (조회)
 */
export const selectBrcProposalList = (searchParams) => {
  return {
    type: FETCH_PROPOSAL,
    payload: fetchProposalList(searchParams),
  };
};

/**
 * 금고영업점 - 지자체 정보관리 - 지자체 정보 등록 (등록/수정)
 */
export const updateBrcProposalList = (dto) => {
  return {
    type: UPDATE_PROPOSAL,
    payload: insertProposalList(dto),
  };
};

export const initUpdateBrcProposalList = () => {
  return {
    type: UPDATE_INIT,
  };
};

/**
 * 금고영업점 - 지자체 정보관리 - 지자체 정보 등록 pk채번
 */
export const selectBrcProposalPkNmgt = () => {
  return {
    type: FETCH_PROPOSAL_PK_NMGT,
    payload: fetchProposalPkNmgt(),
  };
};

/**
 * 조회조건 저장
 */
export const saveSearchFormValues = (searchFormValues) => {
  return {
    type: SAVE_SEARCH_FORM_VALUES,
    payload: {
      ...searchFormValues,
      init: false,
    },
  };
};
/**
 * 초기화
 */
export const resetBrcProposal = () => {
  return {
    type: RESET_STATE,
  };
};
