import instance from '../../common/config/api';

/**
 * 기타 - 서울페이플러스 - 처리내역조회 (관리자)
 * @param {object} params
 * @returns
 */
export const fetchEtcPrcPrtsRcncSum = (params) =>
  instance.post(`${process.env.REACT_APP_SERVER_URL}/scq/scq/etc/0004/selectEtcPrcPrtsRcncSum`, params);

/**
 * 기타 - 서울페이플러스 - 처리내역조회 (관리자) 등록 - 10억 이하
 * @param {object} params
 * @returns
 */
export const insertCitSfWdrReqTrn = (params) =>
  instance.post(`${process.env.REACT_APP_SERVER_URL}/scq/scq/etc/0004/insertCitSfWdrReqTrn`, params);

/**
 * 기타 - 서울페이플러스 - 처리내역조회 (관리자) 등록 - 10억 이상
 * @param {object} params
 * @returns
 */
export const insertCitSfWdrReqTrnAbv10Full = (params) =>
  instance.post(`${process.env.REACT_APP_SERVER_URL}/scq/scq/etc/0004/insertCitSfWdrReqTrnAbv10Full`, params);

/**
 * 기타 - 서울페이플러스 - 처리내역조회 (관리자) 저장
 * @param {object} params
 * @returns
 */
export const updateCitSfWdrReqTrn = (params) =>
  instance.post(`${process.env.REACT_APP_SERVER_URL}/scq/scq/etc/0004/updateCitSfWdrReqTrn`, params);
