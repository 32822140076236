import { fetchGvcmPrtsInqList, fetchRvexCsfActList, fetchAnexGvcmPayFrcsAm } from '../../../lib/axp/gvcmPrtsInq.api';
import { SUCCESS, REQUEST, FAILURE } from '../../../common/utils/action-type-utils';
import dayjs from 'dayjs';
import { fetchCommonComboList } from '../../../lib/cmm/combo.api';

// actions
const FETCH_GVCM_PRTS_INQ_LIST = 'gvcmPrtsInq/FETCH_GVCM_PRTS_LIST';
const FETCH_RVEX_CSF_ACT_LIST = 'gvcmPrtsInq/FETCH_RVEX_CSF_ACT_LIST';
const FETCH_ANEX_GVCM_PAY_FRCS_AM = 'gvcmPrtsInq/FETCH_ANEX_GVCM_PAY_FRCS_AM';

const FETCH_IST_INFO_LIST = 'gvcmPrtsInq/FETCH_IST_INFO_LIST';

const CLEAR_DETAILS = 'gvcmPrtsInq/CLEAR_DETAILS';
const CLEAR_MESSAGE = 'gvcmPrtsInq/CLEAR_MESSAGE';
const SET_BEFORE_SEARCH_PARAM = 'gvcmPrtsInq/SET_BEFORE_SEARCH_PARAM';

const RESET_INITIAL_STATE = 'gvcmPrtsInq/RESET_INITIAL_STATE';
const SAVE_SEARCH_FORM_VALUES = 'gvcmPrtsInq/SAVE_SEARCH_FORM_VALUES';

export const initFormValues = {
  cus_ist_cd: '6110000',
  vpmny_acno: '',
  inq_dt: dayjs().add(-1, 'day'),
  vcus_psbz_no: '',
  vtrn_dtf: dayjs().add(-1, 'day').format('YYYY.MM.DD'),
  vtrn_dtt: dayjs().add(-1, 'day').format('YYYY.MM.DD'),
  vgubun: '0',
  pmdp_act_dscd: '02', //01=세입, 02=세출
  dateState: 1,

  // 검색조건
};

// initial state
const initialState = {
  loading: false,
  success: false,
  message: '',
  list: [],
  totalCount: 0,

  details: '',
  detailsLoading: false,
  detailsSuccess: false,
  detailsMessage: '',
  beforeSearchParam: {},

  //콤보 - 기관명 005
  istInfoSuccess: false,
  istInfoLoading: false,
  istInfoList: [],

  actNoList: [],
  actNoLoading: false,
  actNoSuccess: false,

  searchFormValues: {
    init: true,
    ...initFormValues,
  },
};

// reducers
export default function gvcmPrtsInq(state = initialState, action) {
  switch (action.type) {
    case SUCCESS(FETCH_GVCM_PRTS_INQ_LIST): // 조회 성공
      return {
        ...state,
        success: true,
        loading: false,
        list: action.payload.data?.main,
        totalCount: action.payload?.main_cnt,
      };
    case REQUEST(FETCH_GVCM_PRTS_INQ_LIST): // 조회 요청
      return {
        ...state,
        message: '',
        loading: true,
      };
    case FAILURE(FETCH_GVCM_PRTS_INQ_LIST): // 조회실패
      return {
        ...state,
        list: [],
        loading: false,
        success: false,
        message: action.payload.response?.data?.message || action.payload.message,
      };
    case SUCCESS(FETCH_ANEX_GVCM_PAY_FRCS_AM): // 조회 성공
      return {
        ...state,
        detailsSuccess: true,
        detailsLoading: false,
        details: action.payload.data?.output_data?.at(0),
      };
    case REQUEST(FETCH_ANEX_GVCM_PAY_FRCS_AM): // 조회 요청
      return {
        ...state,
        detailsMessage: '',
        detailsLoading: true,
      };
    case FAILURE(FETCH_ANEX_GVCM_PAY_FRCS_AM): // 조회실패
      return {
        ...state,
        details: [],
        detailsLoading: false,
        detailsSuccess: false,
        detailsMessage: action.payload.response?.data?.message || action.payload.message,
      };
    case SUCCESS(FETCH_RVEX_CSF_ACT_LIST): // 조회 성공
      return {
        ...state,
        actNoSuccess: true,
        actNoLoading: false,
        actNoList: action.payload.data?.subDto,
      };
    case REQUEST(FETCH_RVEX_CSF_ACT_LIST): // 조회 요청
      return {
        ...state,
        actNoLoading: true,
      };
    case FAILURE(FETCH_RVEX_CSF_ACT_LIST): // 조회실패
      return {
        ...state,
        actNoList: [],
        actNoLoading: false,
        actNoSuccess: false,
      };
    // 기관명
    case SUCCESS(FETCH_IST_INFO_LIST): // 조회 성공
      return {
        ...state,
        istInfoSuccess: true,
        istInfoLoading: false,
        istInfoList: action.payload.data?.subDto,
      };
    case REQUEST(FETCH_IST_INFO_LIST): // 조회 요청
      return {
        ...state,
        istInfoLoading: true,
        istInfoSuccess: false,
      };
    case FAILURE(FETCH_IST_INFO_LIST): // 조회실패
      return {
        istInfoLoading: false,
        istInfoSuccess: false,
        istInfoList: [],
      };
    case SAVE_SEARCH_FORM_VALUES:
      return {
        ...state,
        istInfoSuccess: false,
        istInfoLoading: false,

        searchFormValues: action.payload,
      };
    case RESET_INITIAL_STATE:
      return initialState;
    case CLEAR_DETAILS:
      return {
        ...state,
        details: [],
      };
    case SET_BEFORE_SEARCH_PARAM:
      return {
        ...state,
        beforeSearchParam: action.payload,
      };
    case CLEAR_MESSAGE:
      return {
        ...state,
        message: '',
        success: false,
        listCommon: {},
      };
    default:
      return state;
  }
}

/**
 * 정부보관금 내역조회
 */
export const selectGvcmPrts = (searchParams) => {
  return {
    type: FETCH_GVCM_PRTS_INQ_LIST,
    payload: fetchGvcmPrtsInqList(searchParams),
  };
};

/**
 * 세입세출 현금계좌 조회
 */
export const selectRvexCsfActList = (searchParams) => {
  return {
    type: FETCH_RVEX_CSF_ACT_LIST,
    payload: fetchRvexCsfActList(searchParams),
  };
};

/**
 * 지급예상 조회
 */
export const selectAnexGvcmPayFrcsAm = (searchParams) => {
  return {
    type: FETCH_ANEX_GVCM_PAY_FRCS_AM,
    payload: fetchAnexGvcmPayFrcsAm(searchParams),
  };
};

export const clearDetails = () => {
  return {
    type: CLEAR_DETAILS,
  };
};

/**
 * 메시지초기화
 */
export const clearMessage = () => {
  return {
    type: CLEAR_MESSAGE,
  };
};

/**
 *
 */
export const setBeforeSearchParam = (searchParams) => {
  return {
    type: SET_BEFORE_SEARCH_PARAM,
    payload: searchParams,
  };
};

//기관명 콤보 조회
export const selectIstInfoList = (searchParam) => {
  return {
    type: FETCH_IST_INFO_LIST,
    payload: fetchCommonComboList({ slt_box_no: '005', ...searchParam }),
  };
};

/**
 * 조회조건 저장
 */
export const saveSearchFormValues = (searchFormValues) => {
  return {
    type: SAVE_SEARCH_FORM_VALUES,
    payload: {
      ...searchFormValues,
      init: false,
    },
  };
};

/**
 * 상태초기화
 */
export const resetInitialState = () => {
  return {
    type: RESET_INITIAL_STATE,
  };
};
