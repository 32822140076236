import React, { useCallback, useEffect, useMemo, useState } from 'react';
import { useSelector } from 'react-redux';
import styled, { css } from 'styled-components';
import { dataFormat } from '../../common/utils/utils';
import { districtDatas } from '../../data/common';
import { changeCusIstCd } from '../../redux/modules/fds/MainFDS.reducer';
import WOAlarm from '../common/WOAlarm';
import WOIcon from '../common/WOIcon';
import { Link } from 'react-router-dom';

/**
 * props: cityCode
 * seoul - svg코드 반환
 * seoul 이외 지역은 cityCode로 지정된 텍스트로 파일명을 찾아 이미지 태그로 반환(/assets/images/map/{cityCode}.svg)
 * 서울 제외 총 17개 지역으로 구분
 * 리스트 : busan, chungbuk, chungnam, daegu, daejeon, gangwon, gwangju, gyeongbuk, gyeonggi, gyeongnam, incheon, jeju, *         jeonbuk, jeonnam, sejong, ulsan, korea(전체지도)
 */

function WOMap({ isExpand, totCnt, top3, levelCnt, bymnPsg, mapInfo, handleOnIstInfoChange, user, istInfoList }) {
  //console.log(mapInfo);
  const [selectedDistrict, setSelectedDistrict] = useState(null);

  // grid
  const { cus_ist_cd } = useSelector((state) => state.MainFDS);

  const [istCd, setIstCd] = useState('all');

  useEffect(() => {
    let istCd = istInfoList?.find((item) => item.code === cus_ist_cd)?.cus_ist_cd;
    istCd = istCd ? istCd : cus_ist_cd;
    setIstCd(istCd);
  }, [cus_ist_cd, istInfoList, mapInfo]);

  const handleDistrictClick = useCallback(
    (id) => {
      if (user?.id === 1) {
        setSelectedDistrict(id);
        changeCusIstCd(id);
        handleOnIstInfoChange(id);
      }
    },
    [handleOnIstInfoChange, user?.id]
  );

  const alarmList = useMemo(
    () => [
      {
        id: 1,
        severity: 'warning',
        label: '상',
        count: (() => {
          const selectedItem = levelCnt?.find((val) => val.fds_lev_nm === '상');
          return selectedItem ? dataFormat.number(selectedItem.cnt) : 0;
        })(),
      },
      {
        id: 2,
        severity: 'advisory',
        label: '중',
        count: (() => {
          const selectedItem = levelCnt?.find((val) => val.fds_lev_nm === '중');
          return selectedItem ? dataFormat.number(selectedItem.cnt) : 0;
        })(),
      },
      {
        id: 3,
        severity: 'caution',
        label: '하',
        count: (() => {
          const selectedItem = levelCnt?.find((val) => val.fds_lev_nm === '하');
          return selectedItem ? dataFormat.number(selectedItem.cnt) : 0;
        })(),
      },
    ],
    [levelCnt]
  );

  return (
    <Wrap $isExpand={isExpand} className="map-wrap">
      <MapDistrict>
        {mapInfo.ci && mapInfo.cityCode !== 'korea' && (
          <div className="city__identity">
            {/* 아이콘 */}
            <WOIcon width={32} height={32} icon={`ci/${mapInfo.ci}`} />
          </div>
        )}
        {/* 구청명  */}
        <div className="city__name">{mapInfo.cityName ?? ''}</div>
      </MapDistrict>
      {!isExpand && (
        <WarningArea>
          <div className="total">
            <span className="total__title">이상감지(당일)</span>
            <span className="total__text">총 {dataFormat.number(totCnt)}건</span>
          </div>
        </WarningArea>
      )}
      <MapSvg $isExpand={isExpand}>
        {mapInfo?.cityCode && mapInfo.cityCode === 'seoul' ? (
          <svg width={'268'} height={'210'} viewBox="0 0 300 235" fill="none" xmlns="http://www.w3.org/2000/svg">
            <g clipPath="url(#clip0_194_32673)">
              {districtDatas.map((data, index) => (
                <path
                  key={index}
                  id={data.id}
                  onClick={() => handleDistrictClick(data.id)}
                  d={data.d}
                  fill={istCd === data.id ? '#FF5D5D' : '#BDD7FF'}
                  fillOpacity={istCd === data.id ? '0.5' : '0.2'}
                />
              ))}
            </g>
            <defs>
              <path id="clip0_194_32673">
                <rect width="300" height="235" fill="white" />
              </path>
            </defs>
          </svg>
        ) : mapInfo.cityCode.length > 0 ? (
          <img src={'/assets/images/map/' + mapInfo?.cityCode + '.svg'} alt={mapInfo?.cityCode} className="other-svg" />
        ) : (
          <img src={'/assets/images/map/korea.svg'} alt={'전국'} className="other-svg" />
        )}
      </MapSvg>

      {!isExpand && (
        <>
          <div className="notice"></div>
          <Link to="/page/FDS0101V01?search=auto" state={{ mainProps: { fds_ist_cd: cus_ist_cd } }}>
            <AlarmWrap className="alarm-wrap">
              {alarmList?.map((alarm) => (
                <WOAlarm key={alarm.id} severity={alarm.severity} label={alarm.label} count={alarm.count} />
              ))}
            </AlarmWrap>
          </Link>
        </>
      )}
      {isExpand && (
        <>
          <DetectionWrap>
            <div className="total-detection">
              <span className="tit">이상감지(당일)</span>
              <span className="num">{dataFormat.number(totCnt)}건</span>
            </div>

            <div className="detection-title">TOP 3</div>
            <ul className="detection-list">
              {top3?.map((item, index) => (
                <React.Fragment key={index}>
                  <li className="detection-item purple-dot">
                    <span className="tit">{item.fds_grp_nm}</span>
                    <span className="num ">{dataFormat.number(item.cnt)}</span>
                  </li>
                </React.Fragment>
              ))}
            </ul>
          </DetectionWrap>
          <div className="notice"></div>
          <Link to="/page/FDS0101V01?search=auto" state={{ mainProps: { fds_ist_cd: cus_ist_cd } }}>
            <AlarmWrap className="alarm-wrap">
              {alarmList.map((alarm) => (
                <WOAlarm key={alarm.id} severity={alarm.severity} label={alarm.label} count={alarm.count} />
              ))}
            </AlarmWrap>
          </Link>
        </>
      )}
    </Wrap>
  );
}

export default WOMap;

const Wrap = styled.div`
  position: relative;
  display: flex;
  flex-direction: column;
  height: 325px;
  border-radius: 16px;
  background: #0e213f;
  padding: 20px 8px 8px;
  > a {
    width: 100%;
    z-index: 10;
  }
  ${(props) =>
    props.$isExpand &&
    css`
      display: flex;
      align-items: center;
      padding: 20px;
      height: 297px;
      ${MapSvg} ~.notice {
        text-align: left;
      }
    `}
`;

const WarningArea = styled.div`
  display: flex;
  align-items: center;
  margin-left: auto;
  z-index: 20;
  .total {
    text-align: right;
    padding-right: 20px;
    > span {
      display: block;
    }
    &__title {
      color: #fff;
      font-size: 14px;
      font-weight: 400;
      line-height: 21px;
    }
    &__text {
      color: #fc8686;
      font-size: 18px;
      font-weight: 600;
    }
  }
`;

const AlarmWrap = styled.div`
  display: flex;
  width: 100%;
  z-index: 500;
  > .alarm {
    flex: 1;
    min-width: auto;
  }
`;

const MapSvg = styled.div`
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  ${(props) => {
    if (props.$isExpand) {
      return css`
        width: 288px;
        text-align: left;
        > svg,
        .other-svg {
          margin-top: 20px;
          margin-left: 20px;
        }
      `;
    } else {
      return css`
        > svg,
        .other-svg {
          margin-top: 61px;
        }
      `;
    }
  }}

  text-align: center;
  path {
    cursor: pointer;
  }
  & ~ .notice {
    width: 100%;
    margin: auto 0 12px;
    font-size: 12px;
    font-weight: 400;
    line-height: 18px;
    color: #9ea6b2;
    text-align: center;
    ${(props) =>
      props.$isExpand &&
      css`
        text-align: right;
      `}
  }

  .other-svg {
    display: inline-block;
    width: 268px;
    height: 210px;
    -webkit-user-drag: none;
`;

// isExpand인 경우 노출
const DetectionWrap = styled.div`
  position: absolute;
  right: 20px;
  display: flex;
  flex-direction: column;
  width: 185px;
  margin: 0 12px;
  .total-detection {
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding: 4px 0 8px;
    text-align: center;
    border-bottom: 1px solid rgba(217, 217, 217, 0.2);

    .tit {
      display: block;
      font-size: 14px;
      font-weight: 400;
      line-height: 21px;
      color: #fff;
    }
    .num {
      display: block;
      font-size: 18px;
      font-weight: 600;
      line-height: 27px;
      color: #fc8686;
    }
  }
  .detection-title {
    font-size: 14px;
    font-weight: 400;
    color: #9ea6b2;
    padding: 4px 0;
    margin-top: 8px;
  }
  .detection-list {
    .detection-item {
      position: relative;
      display: flex;
      justify-content: space-between;
      align-items: center;
      padding: 4px 0px 4px 8px;
      &::before {
        content: '';
        position: absolute;
        top: 50%;
        left: 0;
        transform: translateY(-50%);
        width: 4px;
        height: 4px;
        border-radius: 50%;
        background: #249a9a;
      }
      &.purple-dot {
        &::before {
          background: #a964a6;
        }
      }
      &.grey-dot {
        &::before {
          background: #949494;
        }
      }
      &.blue-dot {
        &::before {
          background: #5c80db;
        }
      }
      .tit {
        display: block;
        font-size: 12px;
        font-weight: 400;
        line-height: 18px;
        & + .num {
          margin-left: auto;
        }
      }
      .num {
        display: block;
        font-size: 14px;
        font-weight: 500;
        line-height: 21px;
      }
    }
  }

  ${AlarmWrap} {
    margin-top: auto;
  }
`;

const MapDistrict = styled.div`
  position: absolute;
  top: 20px;
  left: 20px;
  display: flex;
  align-items: center;
  gap: 4px;
  z-index: 20;
  .city__identity {
    display: flex;
    gap: 4px;
  }

  .city__name {
    font-size: 14px;
    font-weight: 400;
    line-height: 32px;
  }
`;
