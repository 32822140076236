import { fetchOfislList, insertOfisl, fetchOfislPkNmgt } from '../../../lib/sys/OfislMng.api';
import { SUCCESS, REQUEST, FAILURE } from '../../../common/utils/action-type-utils';

// actions
const FETCH_OFISL_LIST = 'OfislMng/FETCH_OFISL_LIST';
const INSERT_OFISL = 'OfislMng/INSERT_OFISL';
const FETCH_OFISL_PK_NMGT = 'OfislMng/FETCH_OFISL_PK_NMGT';
const SAVE_SEARCH_FORM_VALUES = 'IstDeptCdMng/SAVE_SEARCH_FORM_VALUES';
const RESET_STATE = 'IstDeptCdMng/RESET_STATE';
export const initFormValues = {
  cus_ist_cd_search: 'all', //기관명
  dept_cd_search: 'all', //부서명
};
// initial state
const initialState = {
  loading: false,
  success: true,
  message: '',
  list: [],
  details: [],
  totalCount: 0,
  updateSuccess: false,
  resultCnt: -1,
  mainPk: '', // 검색조건
  searchFormValues: {
    init: true,
    ...initFormValues,
  },
};

// reducers
export default function OfislMng(state = initialState, action) {
  switch (action.type) {
    case SUCCESS(FETCH_OFISL_LIST): // 조회 성공
      return {
        ...state,
        success: true,
        loading: false,
        list: action.payload.data?.main,
        totalCount: action.payload?.main_cnt,
        updateSuccess: false,
      };
    case REQUEST(FETCH_OFISL_LIST): // 조회 요청
      return {
        ...state,
        loading: true,
        updateSuccess: false,
      };
    case FAILURE(FETCH_OFISL_LIST): // 조회실패
      return {
        ...initialState,
        loading: false,
        success: false,
        message: action.payload.response?.data?.message,
        updateSuccess: false,
      };
    case SUCCESS(INSERT_OFISL): // 갱신 성공
      return {
        ...state,
        updateSuccess: true,
        loading: false,
        resultCnt: action.payload.data.resultCnt,
      };
    case REQUEST(INSERT_OFISL): // 갱신 요청
      return {
        ...state,
        loading: true,
        updateSuccess: false,
      };
    case FAILURE(INSERT_OFISL): // 갱신 실패
      return {
        ...initialState,
        loading: false,
        updateSuccess: false,
        resultCnt: -1,
        message: action.payload.response?.data?.message,
      };
    case SUCCESS(FETCH_OFISL_PK_NMGT): // 채번 성공
      return {
        ...state,
        success: true,
        loading: false,
        mainPk: action.payload.data?.main_pk[0].pk,
        mainPkCnt: action.payload?.main_cnt,
        updateSuccess: false,
      };
    case REQUEST(FETCH_OFISL_PK_NMGT): // 채번 요청
      return {
        ...state,
        loading: true,
        updateSuccess: false,
      };
    case FAILURE(FETCH_OFISL_PK_NMGT): // 채번 실패
      return {
        ...initialState,
        loading: false,
        success: false,
        message: action.payload.response?.data?.message,
        updateSuccess: false,
      };
    case SAVE_SEARCH_FORM_VALUES:
      return {
        ...state,
        searchFormValues: action.payload,
      };
    case RESET_STATE:
      return initialState;
    default:
      return { ...state, updateSuccess: false };
  }
}

/**
 *  직인목록 조회
 */
export const selectOfislList = (searchParams) => {
  return {
    type: FETCH_OFISL_LIST,
    payload: fetchOfislList(searchParams),
  };
};

/**
 * 직인 저장
 */
export const updateOfisl = (dtos) => {
  return {
    type: INSERT_OFISL,
    payload: insertOfisl(dtos),
  };
};

/**
 *  직인 PK 채번
 */
export const selectOfislPkNmgt = (searchParams) => {
  return {
    type: FETCH_OFISL_PK_NMGT,
    payload: fetchOfislPkNmgt(searchParams),
  };
};

/**
 * 조회조건 저장
 */
export const saveSearchFormValues = (searchFormValues) => {
  return {
    type: SAVE_SEARCH_FORM_VALUES,
    payload: {
      ...searchFormValues,
      init: false,
    },
  };
};

/**
 * 상태초기화
 */
export const resetState = () => {
  return {
    type: RESET_STATE,
  };
};
