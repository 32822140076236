import instance from '../../common/config/api';

/**
 * 시스템관리 - 직인관리
 * @param {object} params
 * @returns
 */

export const fetchOfislList = (params) =>
  instance.post(`${process.env.REACT_APP_SERVER_URL}/scq/scq/sys/0001/selectOfisl`, params);

export const insertOfisl = (dtos) =>
  instance.post(`${process.env.REACT_APP_SERVER_URL}/scq/scq/sys/0001/insertOfisl`, {
    subDto: dtos,
  });

export const fetchOfislPkNmgt = (params) =>
  instance.post(`${process.env.REACT_APP_SERVER_URL}/scq/scq/sys/0001/selectOfislPkNmgt`, params);
