import { SUCCESS, REQUEST, FAILURE } from '../../../common/utils/action-type-utils';
import { fetchCodeGroupDetailList, fetchCodeGroupList, saveCodeGroupSubList } from '../../../lib/sys/cmmCdMng.api';

// actions
const FETCH_CMM_CD_MNG_DETAIL_LIST = 'cmmCdMngDetail/FETCH_CMM_CD_MNG_DETAIL_LIST';
const SAVE_CMM_CD_MNG_DETAIL_LIST = 'cmmCdMngDetail/SAVE_CMM_CD_MNG_DETAIL_LIST';

// initial state
const initialState = {
  loading: false,
  success: true,
  message: '',
  list: [],
  totalCount: 0,
  updateSuccess: false,
  resultCnt: -1,
};

// reducers
export default function cmmCdMngDetail(state = initialState, action) {
  switch (action.type) {
    case SUCCESS(FETCH_CMM_CD_MNG_DETAIL_LIST): // 조회 성공
      return {
        ...state,
        success: true,
        loading: false,
        list: action.payload.data?.sub,
        totalCount: action.payload?.subCnt,
        updateSuccess: false,
      };
    case REQUEST(FETCH_CMM_CD_MNG_DETAIL_LIST): // 조회 요청
      return {
        ...state,
        message: '',
        loading: true,
        success: false,
        updateSuccess: false,
      };
    case FAILURE(FETCH_CMM_CD_MNG_DETAIL_LIST): // 조회실패
      return {
        ...initialState,
        loading: false,
        success: false,
        message: action.payload.response?.data?.message,
        updateSuccess: false,
      };
    case SUCCESS(SAVE_CMM_CD_MNG_DETAIL_LIST): // 저장 성공
      return {
        ...state,
        updateSuccess: true,
        loading: false,
        resultCnt: action.payload.data.resultCnt,
      };
    case REQUEST(SAVE_CMM_CD_MNG_DETAIL_LIST): // 저장 요청
      return {
        ...state,
        message: '',
        loading: true,
        updateSuccess: false,
      };
    case FAILURE(SAVE_CMM_CD_MNG_DETAIL_LIST): // 저장 실패
      return {
        ...initialState,
        loading: false,
        updateSuccess: false,
        resultCnt: -1,
        message: action.payload.response?.data?.message,
      };
    default:
      return { ...state, updateSuccess: false };
  }
}

/**
 * 공통코드그룹상세 목록 조회
 */
export const selectListCmmCdGroupDetail = (searchParams) => {
  return {
    type: FETCH_CMM_CD_MNG_DETAIL_LIST,
    payload: fetchCodeGroupDetailList(searchParams),
  };
};

/**
 * 공통코드그룹상세 목록 저장
 */
export const saveListCmmCdGroupDetail = (dtos) => {
  return {
    type: SAVE_CMM_CD_MNG_DETAIL_LIST,
    payload: saveCodeGroupSubList(dtos),
  };
};
