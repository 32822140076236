import { SUCCESS, REQUEST, FAILURE } from '../../../common/utils/action-type-utils';
import { addZpbAnc, fetchZpbAncDtl, fetchZpbAncList, fetchZpbAncPk, fixZpbAnc } from '../../../lib/zpb/zpb0207m01.api';
import { removeZpbAnc } from './../../../lib/zpb/zpb0207m01.api';
import { bltnTycdList, recpTgtDtlsList, zpbUpcheGbnCdComboList } from '../../../data/const';
import dayjs from 'dayjs';

// actions
const FETCH_ANC = 'zpbAnc/FETCH_ANC';
const FETCH_ANC_DTL = 'zpbAnc/FETCH_ANC_DTL';
const FETCH_ANC_PK = 'zpbAnc/FETCH_ANC_PK';
const INSERT_ANC = 'zpbAnc/INSERT_ANC';
const UPDATE_ANC = 'zpbAnc/UPDATE_ANC';
const DELETE_ANC = 'zpbAnc/DELETE_ANC';
const UPDATE_INIT = 'zpbAnc/UPDATE_INIT';

const SAVE_SEARCH_FORM_VALUES = 'zpbAnc/SAVE_SEARCH_FORM_VALUES';
const RESET_STATE = 'zpbAnc/RESET_STATE';

export const initFormValues = {
  bltn_tycd: '', // 공지유형
  recp_tgt_dtls_tycd: 'APPM03001', // os유형 (APPM03001, APPM03002, APPM03003)
  bltn_stdt: dayjs().add(-1, 'month').format('YYYY-MM-DD'), // 공지기간
  bltn_eddt: dayjs().add(1, 'month').format('YYYY-MM-DD'), // 공지기간
  search_key: '1', // 검색구분
  search_val: '', // 검색텍스트
  upche_gbn_cd: '5', // 사용자구분
  pageNum: 1,
  pageCount: 100,
};

// initial state
const initialState = {
  loading: false,
  success: false,
  message: '',
  list: [],
  detailLoading: false,
  detailSuccess: false,
  detail: {}, // 상세
  totalCount: 0,
  updateLoading: false, // 등록/수정/삭제
  updateSuccess: false, // 등록/수정/삭제
  updateCount: false, // 등록/수정/삭제
  mainPk: '', // 채번
  mainPkCnt: 0, // 채번
  updateResultMsg: '',
  bltnTycdList: bltnTycdList, //공지유형
  recpTgtDtlsList: recpTgtDtlsList, //os유형
  searchKeyList: [
    { id: '1', label: '제목' },
    { id: '2', label: '내용' },
    { id: '3', label: '등록자' },
  ],
  zpbUpcheGbnCdComboList: zpbUpcheGbnCdComboList,
  searchFormValues: {
    init: true,
    ...initFormValues,
  },
};

// reducers
export default function zpb0207m01(state = initialState, action) {
  switch (action.type) {
    case SUCCESS(FETCH_ANC): // 조회 성공
      return {
        ...state,
        success: true,
        loading: false,
        list: action.payload.data?.main,
        totalCount: action.payload.data?.totMainCnt,
      };
    case REQUEST(FETCH_ANC): // 조회 요청
      return {
        ...state,
        loading: true,
        success: false,
      };
    case FAILURE(FETCH_ANC): // 조회 실패
      return {
        ...state,
        loading: false,
        success: false,
        message: action.payload.response?.data?.message,
      };
    case SUCCESS(FETCH_ANC_DTL): // 조회 성공 (상세)
      return {
        ...state,
        detailSuccess: true,
        detailLoading: false,
        detail: action.payload.data,
      };
    case REQUEST(FETCH_ANC_DTL): // 조회 요청 (상세)
      return {
        ...state,
        detailLoading: true,
        detailSuccess: false,
      };
    case FAILURE(FETCH_ANC_DTL): // 조회 실패 (상세)
      return {
        ...state,
        detailLoading: false,
        detailSuccess: false,
        message: action.payload.response?.data?.message,
      };
    // CUD--
    case SUCCESS(INSERT_ANC): // 등록 성공
      return {
        ...state,
        updateSuccess: true,
        updateLoading: false,
        updateCount: action.payload.data?.resultCnt,
        updateResultMsg: action.payload.data?.resultMsg,
      };
    case REQUEST(INSERT_ANC): // 등록 요청
      return {
        ...state,
        updateLoading: true,
        updateSuccess: false,
      };
    case FAILURE(INSERT_ANC): // 등록 실패
      return {
        ...state,
        updateLoading: false,
        updateSuccess: false,
        message: action.payload.response?.data?.message,
        updateResultMsg: action.payload.data?.resultMsg,
      };
    case SUCCESS(UPDATE_ANC): // 수정 성공
      return {
        ...state,
        updateSuccess: true,
        updateLoading: false,
        updateCount: action.payload.data?.resultCnt,
        updateResultMsg: action.payload.data?.resultMsg,
      };
    case REQUEST(UPDATE_ANC): // 수정 요청
      return {
        ...state,
        updateLoading: true,
        updateSuccess: false,
      };
    case FAILURE(UPDATE_ANC): // 수정 실패
      return {
        ...state,
        updateLoading: false,
        updateSuccess: false,
        message: action.payload.response?.data?.message,
      };
    case SUCCESS(DELETE_ANC): // 삭제 성공
      return {
        ...state,
        updateSuccess: true,
        updateLoading: false,
        updateCount: action.payload.data?.resultCnt,
      };
    case REQUEST(DELETE_ANC): // 삭제 요청
      return {
        ...state,
        updateLoading: true,
        updateSuccess: false,
      };
    case FAILURE(DELETE_ANC): // 삭제 실패
      return {
        ...state,
        updateLoading: false,
        updateSuccess: false,
        message: action.payload.response?.data?.message,
      };
    case UPDATE_INIT: // 등록/수정 실패 초기화
      return {
        ...state,
        updateLoading: false,
        updateSuccess: false,
        detailLoading: false,
        detailSuccess: false,
        detail: {},
        mainPk: '',
        mainPkCnt: 0,
      };
    // --CUD
    case SUCCESS(FETCH_ANC_PK): // 채번 성공
      return {
        ...state,
        success: true,
        loading: false,
        mainPk: action.payload.data?.bltn_srno_pk,
        mainPkCnt: action.payload?.dup_cnt,
      };
    case REQUEST(FETCH_ANC_PK): // 채번 요청
      return {
        ...state,
        loading: true,
      };
    case FAILURE(FETCH_ANC_PK): // 채번 실패
      return {
        ...state,
        loading: false,
        success: false,
        message: action.payload.response?.data?.message,
      };
    case SAVE_SEARCH_FORM_VALUES:
      return {
        ...state,
        searchFormValues: action.payload,
      };
    case RESET_STATE:
      return initialState;
    default:
      return state;
  }
}

/**
 * 제로페이 - 공지함 (조회)
 */
export const selectZpbAncList = (searchParams) => {
  return {
    type: FETCH_ANC,
    payload: fetchZpbAncList(searchParams),
  };
};

/**
 * 제로페이 - 공지함 (상세조회)
 */
export const selectZpbAncDtl = (searchParams) => {
  return {
    type: FETCH_ANC_DTL,
    payload: fetchZpbAncDtl(searchParams),
  };
};

/**
 * 제로페이 - 공지함 (등록)
 */
export const insertZpbAnc = (param) => {
  return {
    type: INSERT_ANC,
    payload: addZpbAnc(param),
  };
};

/**
 * 제로페이 - 공지함 (수정)
 */
export const updateZpbAnc = (param) => {
  return {
    type: UPDATE_ANC,
    payload: fixZpbAnc(param),
  };
};

/**
 * 제로페이 - 공지함 (삭제)
 */
export const deleteZpbAnc = (param) => {
  return {
    type: DELETE_ANC,
    payload: removeZpbAnc(param),
  };
};

/**
 * 제로페이 - 공지함 등록 pk채번
 */
export const selectZpbAncPk = () => {
  return {
    type: FETCH_ANC_PK,
    payload: fetchZpbAncPk(),
  };
};

/**
 * 제로페이 - 공지함 등록,수정 초기화
 */
export const initZpbAnc = () => {
  return {
    type: UPDATE_INIT,
  };
};
/**
 * 조회조건 저장
 */
export const saveSearchFormValues = (searchFormValues) => {
  return {
    type: SAVE_SEARCH_FORM_VALUES,
    payload: {
      ...searchFormValues,
      init: false,
    },
  };
};
/**
 * 초기화
 */
export const resetZpb0207m01 = () => {
  return {
    type: RESET_STATE,
  };
};
