import { SUCCESS, REQUEST, FAILURE } from '../../../common/utils/action-type-utils';
import { fetchUserIpMngList, insertIpMng } from '../../../lib/sys/SYS0204M01.api';
import dayjs from 'dayjs';
import { fetchCommonComboList } from '../../../lib/cmm/combo.api';

// actions
const FETCH_USER_IP_MNG_LIST = 'SYS0204M01/FETCH_USER_IP_MNG_LIST';
const INSERT_IP_MNG = 'SYS0204M01/INSERT_IP_MNG';
const SAVE_SEARCH_FORM_VALUES = 'SYS0203V01/SAVE_SEARCH_FORM_VALUES';
const RESET_STATE = 'SYS0203V01/RESET_STATE';
const FETCH_IST_INFO_LIST = 'SYS0203V01/FETCH_IST_INFO_LIST';
const FETCH_BR_CD_LIST = 'SYS0203V01/FETCH_BR_CD_LIST';

export const initFormValues = {
  // 검색 영역
  sch_gubun: '1', //검색구분(기관 or 영업점)
  cus_ist_cd_search: 'all', //부서명
  br_cd_search: 'all', //영업점명
  sch_sta_dt: dayjs().add(-7, 'day').format('YYYY-MM-DD'), //등록기간 시작일
  sch_end_dt: dayjs().format('YYYY-MM-DD'), //등록기간 시작일
  use_yn: 'all', //사용유무
};

// initial state
const initialState = {
  loading: false,
  success: false,
  message: '',
  list: [],
  details: [],
  totalCount: 0,
  updating: false,
  updateSuccess: false,
  resultCnt: -1,
  // 콤보 - 기관명
  istInfoSuccess: false,
  istInfoLoading: false,
  istCdList: [],
  // 콤보 - 영업점
  brNmSuccess: false,
  brNmLoading: false,
  brNmList: [],
  // 검색조건
  searchFormValues: {
    init: true,
    ...initFormValues,
  },
};

// reducers
export default function SYS0204M01(state = initialState, action) {
  switch (action.type) {
    case SUCCESS(FETCH_USER_IP_MNG_LIST): // 조회 성공
      return {
        ...state,
        success: true,
        loading: false,
        list: action.payload.data?.main,
        totalCount: action.payload?.main_cnt,
        updateSuccess: false,
      };
    case REQUEST(FETCH_USER_IP_MNG_LIST): // 조회 요청
      return {
        ...state,
        loading: true,
        message: '',
        updateSuccess: false,
      };
    case FAILURE(FETCH_USER_IP_MNG_LIST): // 조회실패
      return {
        ...initialState,
        loading: false,
        success: false,
        message: action.payload.response?.data?.message,
        updateSuccess: false,
      };
    case SUCCESS(INSERT_IP_MNG): // 갱신 성공
      return {
        ...state,
        updateSuccess: true,
        updating: false,
        resultCnt: action.payload.data.resultCnt,
      };
    case REQUEST(INSERT_IP_MNG): // 갱신 요청
      return {
        ...state,
        updating: true,
        updateSuccess: false,
      };
    case FAILURE(INSERT_IP_MNG): // 갱신 실패
      return {
        ...initialState,
        updating: false,
        updateSuccess: false,
        resultCnt: -1,
        message: action.payload.response?.data?.message,
      };
    // 기관명
    case SUCCESS(FETCH_IST_INFO_LIST): // 조회 성공
      return {
        ...state,
        istInfoSuccess: true,
        istInfoLoading: false,
        istCdList: action.payload.data?.subDto,
      };
    case REQUEST(FETCH_IST_INFO_LIST): // 조회 요청
      return {
        ...state,
        istInfoLoading: true,
        istInfoSuccess: false,
      };
    case FAILURE(FETCH_IST_INFO_LIST): // 조회실패
      return {
        istInfoLoading: false,
        istInfoSuccess: false,
        istCdList: [],
      };
    // 영업점
    case SUCCESS(FETCH_BR_CD_LIST): // 조회 성공
      return {
        ...state,
        brNmSuccess: true,
        brNmLoading: false,
        brNmList: action.payload.data?.subDto,
      };
    case REQUEST(FETCH_BR_CD_LIST): // 조회 요청
      return {
        ...state,
        brNmLoading: true,
        brNmSuccess: false,
      };
    case FAILURE(FETCH_BR_CD_LIST): // 조회실패
      return {
        ...state,
        brNmLoading: false,
        brNmSuccess: false,
        brNmList: [],
      };
    case SAVE_SEARCH_FORM_VALUES:
      return {
        ...state,
        searchFormValues: action.payload,
      };
    case RESET_STATE:
      return initialState;
    default:
      return { ...state, updateSuccess: false };
  }
}

/**
 * IP관리 조회
 */
export const selectUserIpMngList = (searchParams) => {
  return {
    type: FETCH_USER_IP_MNG_LIST,
    payload: fetchUserIpMngList(searchParams),
  };
};

/**
 * 수정내용 저장
 */
export const updateIpMng = (dtos) => {
  return {
    type: INSERT_IP_MNG,
    payload: insertIpMng(dtos),
  };
};

/**
 * 조회조건 저장
 */
export const saveSearchFormValues = (searchFormValues) => {
  return {
    type: SAVE_SEARCH_FORM_VALUES,
    payload: {
      ...searchFormValues,
      init: false,
    },
  };
};

/**
 * 상태초기화
 */
export const resetState = () => {
  return {
    type: RESET_STATE,
  };
};

/**
 * 기관명 콤보 조회
 */
export const selectIstInfoComboList = (searchParams) => {
  return {
    type: FETCH_IST_INFO_LIST,
    payload: fetchCommonComboList({ slt_box_no: '005', ...searchParams }),
  };
};

/**
 * 부서명 콤보 조회
 */
export const selectBrCdComboList = (searchParams) => {
  return {
    type: FETCH_BR_CD_LIST,
    payload: fetchCommonComboList({ slt_box_no: '201', ...searchParams }),
  };
};
