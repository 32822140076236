import { fetchAnrvTrhsList, fetchAnrvTrhsDtl } from '../../../lib/arv/ARV0001.api';
import { fetchBrNmList } from '../../../lib/cmm/brNm.api';
import { SUCCESS, REQUEST, FAILURE } from '../../../common/utils/action-type-utils';
import dayjs from 'dayjs';
import { fetchCommonComboList } from '../../../lib/cmm/combo.api';

// actions
const FETCH_ANRV_TRHS_LIST = 'ARV0101V03/FETCH_ANRV_TRHS_LIST';
const FETCH_ANRV_TRHS_LIST_CONTINUE = 'ARV0101V03/FETCH_ANRV_TRHS_LIST_CONTINUE';
const FETCH_ANRV_TRHS_DTL = 'ARV0101V03/FETCH_ANRV_TRHS_DTL';
const FETCH_BR_NM_LIST = 'ARV0101V03/FETCH_BR_NM_LIST';

const CLEAR_MESSAGE = 'ARV0101V03/CLEAR_MESSAGE';
const SET_BEFORE_SEARCH_PARAM = 'ARV0101V03/SET_BEFORE_SEARCH_PARAM';

const FETCH_HRGN_IST_INFO_LIST = 'ARV0101V03/FETCH_HRGN_IST_INFO_LIST';
const FETCH_IST_INFO_LIST = 'ARV0101V03/FETCH_IST_INFO_LIST';
const FETCH_DEPT_INFO_LIST = 'ARV0101V03/FETCH_DEPT_INFO_LIST';
const FETCH_ACC_YR_LIST = 'ARV0101V03/FETCH_ACC_YR_LIST';
const FETCH_ARVN_ACT_DSCD_LIST = 'ARV0101V03/FETCH_ARVN_ACT_DSCD_LIST';
const FETCH_ACT_NO_LIST = 'ARV0101V03/FETCH_ACT_NO_LIST';
const FETCH_INQ_SEG_LIST = 'ARV0101V03/FETCH_INQ_SEG_LIST';

const RESET_INITIAL_STATE = 'ARV0101V03/RESET_INITIAL_STATE';
const SAVE_SEARCH_FORM_VALUES = 'ARV0101V03/SAVE_SEARCH_FORM_VALUES';

export const initFormValues = {
  cd_ist_cd: '', //기관구분
  cus_ist_cd: '', //기관명
  cus_dept_cd: 'all', //부서명
  ydcs8a1_t8a01_acc_yr: dayjs().format('YYYY'), //회계연도
  arvn_act_dscd: 'all', //시세/구세
  ydcs8a1_t8a01_act_no: '', //세입계좌
  ydcs8a1_t8a01_inq_sdt: dayjs().add(-1, 'M'), //조회기간
  ydcs8a1_t8a01_inq_edt: dayjs(),
  ydcs8a1_t8a01_inq_seg: '1', //조회구분
  pmdp_act_dscd: '01', //01 - 세입 , 02 - 세출
};

// initial state
const initialState = {
  loading: false,
  success: false,
  message: '',
  list: [],
  listTitle: {},
  listHead: {},
  listCommon: {},
  details: [],
  detailsHead: {},
  detailsSuccess: false,
  detailsLoading: false,
  detailsMessage: '',
  brNmList: [],
  totalCount: 0,
  beforeSearchParam: {},

  // 콤보 - 기관구분 072
  hgrnIstInfoSuccess: false,
  hgrnIstInfoLoading: false,
  hgrnIstInfoList: [],

  //콤보 - 기관명 074
  istInfoSuccess: false,
  istInfoLoading: false,
  istInfoList: [],

  //콤보 - 부서명 042
  deptInfoSuccess: false,
  deptInfoLoading: false,
  deptInfoList: [],

  //콤보 - 계좌번호 920
  actNoSuccess: false,
  actNoLoading: false,
  actNoList: [],

  //콤보 - 회계연도 025
  accYrSuccess: false,
  accYrLoading: false,
  accYrList: [],

  //콤보 - 시세/구세 003
  arvnActDscdSuccess: false,
  arvnActDscdLoading: false,
  arvnActDscdList: [],

  //콤보- 조회구분
  inqSegLoading: false,
  inqSegSuccess: false,
  inqSegList: [],

  // 검색조건
  searchFormValues: {
    init: true,
    ...initFormValues,
  },
};

// reducers
export default function ARV0101V03(state = initialState, action) {
  switch (action.type) {
    case SUCCESS(FETCH_ANRV_TRHS_LIST): // 조회 성공
      return {
        ...state,
        success: true,
        loading: false,
        list: action.payload.data?.output_data,
        listTitle: action.payload.data?.output_title,
        listHead: action.payload.data?.output_head,
        listCommon: action.payload.data?.common,
        totalCount: action.payload?.output_data_cnt,
      };
    case REQUEST(FETCH_ANRV_TRHS_LIST): // 조회 요청
      return {
        ...state,
        message: '',
        loading: true,
      };
    case FAILURE(FETCH_ANRV_TRHS_LIST): // 조회실패
      return {
        ...state,
        list: [],
        loading: false,
        success: false,
        message: action.payload.response?.data?.message,
      };
    case SUCCESS(FETCH_ANRV_TRHS_LIST_CONTINUE): // 조회 성공
      return {
        ...state,
        success: true,
        loading: false,
        list: [...state.list, ...action.payload.data?.output_data],
        listTitle: action.payload.data?.output_title,
        listHead: action.payload.data?.output_head,
        listCommon: action.payload.data?.common,
        totalCount: action.payload?.output_data_cnt,
      };
    case REQUEST(FETCH_ANRV_TRHS_LIST_CONTINUE): // 조회 요청
      return {
        ...state,
        message: '',
        loading: true,
      };
    case FAILURE(FETCH_ANRV_TRHS_LIST_CONTINUE): // 조회실패
      return {
        ...state,
        list: [],
        loading: false,
        success: false,
        message: action.payload.response?.data?.message,
      };
    case SUCCESS(FETCH_ANRV_TRHS_DTL): // 조회 성공
      return {
        ...state,
        details: action.payload.data?.output_data[0],
        detailsHead: action.payload.data?.output_head,
        detailsSuccess: true,
        detailsLoading: false,
      };
    case REQUEST(FETCH_ANRV_TRHS_DTL): // 조회 요청
      return {
        ...state,
        detailsMessage: '',
        detailsLoading: true,
      };
    case FAILURE(FETCH_ANRV_TRHS_DTL): // 조회실패
      return {
        ...state,
        details: [],
        detailsHead: {},
        detailsMessage: action.payload.response?.data?.message,
        detailsSuccess: false,
        detailsLoading: false,
      };
    case SUCCESS(FETCH_BR_NM_LIST): // 조회 성공
      return {
        ...state,
        brNmList: action.payload.data?.subDto,
      };
    case REQUEST(FETCH_BR_NM_LIST): // 조회 요청
      return {
        ...state,
      };
    case FAILURE(FETCH_BR_NM_LIST): // 조회실패
      return {
        ...state,
        brNmList: [],
        message: action.payload.response?.data?.message,
      };
    // 기관구분
    case SUCCESS(FETCH_HRGN_IST_INFO_LIST): // 조회 성공
      return {
        ...state,
        hgrnIstInfoSuccess: true,
        hgrnIstInfoLoading: false,
        hgrnIstInfoList: action.payload.data?.subDto,
      };
    case REQUEST(FETCH_HRGN_IST_INFO_LIST): // 조회 요청
      return {
        ...state,
        hgrnIstInfoLoading: true,
        hgrnIstInfoSuccess: false,
      };
    case FAILURE(FETCH_HRGN_IST_INFO_LIST): // 조회실패
      return {
        hgrnIstInfoLoading: false,
        hgrnIstInfoSuccess: false,
        hgrnIstInfoList: [],
      };
    // 기관명
    case SUCCESS(FETCH_IST_INFO_LIST): // 조회 성공
      return {
        ...state,
        istInfoSuccess: true,
        istInfoLoading: false,
        istInfoList: action.payload.data?.subDto,
      };
    case REQUEST(FETCH_IST_INFO_LIST): // 조회 요청
      return {
        ...state,
        istInfoLoading: true,
        istInfoSuccess: false,
      };
    case FAILURE(FETCH_IST_INFO_LIST): // 조회실패
      return {
        istInfoLoading: false,
        istInfoSuccess: false,
        istInfoList: [],
      };
    // 부서명
    case SUCCESS(FETCH_DEPT_INFO_LIST): // 조회 성공
      return {
        ...state,
        deptInfoSuccess: true,
        deptInfoLoading: false,
        deptInfoList: action.payload.data?.subDto,
      };
    case REQUEST(FETCH_DEPT_INFO_LIST): // 조회 요청
      return {
        ...state,
        deptInfoLoading: true,
        deptInfoSuccess: false,
      };
    case FAILURE(FETCH_DEPT_INFO_LIST): // 조회실패
      return {
        ...state,
        deptInfoLoading: false,
        deptInfoSuccess: false,
        deptInfoList: [],
      };
    // 계좌번호
    case SUCCESS(FETCH_ACT_NO_LIST): // 조회 성공
      return {
        ...state,
        actNoSuccess: true,
        actNoLoading: false,
        actNoList: action.payload.data?.subDto,
      };
    case REQUEST(FETCH_ACT_NO_LIST): // 조회 요청
      return {
        ...state,
        actNoLoading: true,
        actNoSuccess: false,
      };
    case FAILURE(FETCH_ACT_NO_LIST): // 조회실패
      return {
        ...state,
        actNoLoading: false,
        actNoSuccess: false,
        actNoList: [],
      };
    // 시세/구세
    case SUCCESS(FETCH_ARVN_ACT_DSCD_LIST): // 조회 성공
      return {
        ...state,
        arvnActDscdSuccess: true,
        arvnActDscdLoading: false,
        arvnActDscdList: action.payload.data?.subDto,
      };
    case REQUEST(FETCH_ARVN_ACT_DSCD_LIST): // 조회 요청
      return {
        ...state,
        arvnActDscdLoading: true,
        arvnActDscdSuccess: false,
      };
    case FAILURE(FETCH_ARVN_ACT_DSCD_LIST): // 조회실패
      return {
        ...state,
        arvnActDscdLoading: false,
        arvnActDscdSuccess: false,
        arvnActDscdList: [],
      };
    // 회계연도
    case SUCCESS(FETCH_ACC_YR_LIST): // 조회 성공
      return {
        ...state,
        accYrSuccess: true,
        accYrLoading: false,
        accYrList: action.payload.data?.subDto,
      };
    case REQUEST(FETCH_ACC_YR_LIST): // 조회 요청
      return {
        ...state,
        accYrLoading: true,
        accYrSuccess: false,
      };
    case FAILURE(FETCH_ACC_YR_LIST): // 조회실패
      return {
        ...state,
        accYrLoading: false,
        accYrSuccess: false,
        accYrList: [],
      };
    // 조회구분
    case SUCCESS(FETCH_INQ_SEG_LIST): // 조회 성공
      return {
        ...state,
        inqSegSuccess: true,
        inqSegLoading: false,
        inqSegList: action.payload.data?.subDto,
      };
    case REQUEST(FETCH_INQ_SEG_LIST): // 조회 요청
      return {
        ...state,
        inqSegLoading: true,
        inqSegSuccess: false,
      };
    case FAILURE(FETCH_INQ_SEG_LIST): // 조회실패
      return {
        ...state,
        inqSegLoading: false,
        inqSegSuccess: false,
        inqSegList: [],
      };
    case SAVE_SEARCH_FORM_VALUES:
      return {
        ...state,
        hgrnIstInfoSuccess: false,
        hgrnIstInfoLoading: false,
        istInfoSuccess: false,
        istInfoLoading: false,
        deptInfoSuccess: false,
        deptInfoLoading: false,
        actNoLoading: false,
        actNoSuccess: false,
        accYrSuccess: false,
        accYrLoading: false,
        arvnActDscdSuccess: false,
        arvnActDscdLoading: false,
        inqSegLoading: false,
        inqSegSuccess: false,
        searchFormValues: action.payload,
      };
    case RESET_INITIAL_STATE:
      return initialState;
    case SET_BEFORE_SEARCH_PARAM:
      return {
        ...state,
        beforeSearchParam: action.payload,
      };
    case CLEAR_MESSAGE:
      return {
        ...state,
        message: '',
        detailsMessage: '',
        listCommon: {},
        success: false,
        detailsSuccess: false,
      };
    default:
      return state;
  }
}

/**
 * 세입거래명세 조회
 */
export const selectAnrvTrhsList = (searchParams) => {
  return {
    type: searchParams?.isContinue ? FETCH_ANRV_TRHS_LIST_CONTINUE : FETCH_ANRV_TRHS_LIST,
    payload: fetchAnrvTrhsList(searchParams),
  };
};

/**
 * 세입거래명세상세내역 조회
 */
export const selectAnrvTrhsDtl = (searchParams) => {
  return {
    type: FETCH_ANRV_TRHS_DTL,
    payload: fetchAnrvTrhsDtl(searchParams),
  };
};

/**
 * 거래점명조회
 */
export const selectBrNmList = () => {
  return {
    type: FETCH_BR_NM_LIST,
    payload: fetchBrNmList(),
  };
};

/**
 * 메시지초기화
 */
export const clearMessage = () => {
  return {
    type: CLEAR_MESSAGE,
  };
};

/**
 *
 */
export const setBeforeSearchParam = (searchParams) => {
  return {
    type: SET_BEFORE_SEARCH_PARAM,
    payload: searchParams,
  };
};

//기관구분 콤보 조회
export const selectHgrnIstInfoList = (searchParam) => {
  return {
    type: FETCH_HRGN_IST_INFO_LIST,
    payload: fetchCommonComboList({ slt_box_no: '072', ...searchParam }),
  };
};

//기관명 콤보 조회
export const selectIstInfoList = (searchParam) => {
  return {
    type: FETCH_IST_INFO_LIST,
    payload: fetchCommonComboList({ slt_box_no: '074', ...searchParam }),
  };
};

//부서명 콤보 조회
export const selectDeptInfoList = (searchParam) => {
  return {
    type: FETCH_DEPT_INFO_LIST,
    payload: fetchCommonComboList({ slt_box_no: '042', ...searchParam }),
  };
};

//회계연도 콤보 조회
export const selectAccYrList = () => {
  return {
    type: FETCH_ACC_YR_LIST,
    payload: fetchCommonComboList({ slt_box_no: '025' }),
  };
};

//계좌번호 콤보 조회
export const selectActNoList = (searchParam) => {
  return {
    type: FETCH_ACT_NO_LIST,
    payload: fetchCommonComboList({ slt_box_no: '920', ...searchParam }),
  };
};

//시세/구세 콤보 조회
export const selectArvnActDscdList = () => {
  return {
    type: FETCH_ARVN_ACT_DSCD_LIST,
    payload: fetchCommonComboList({
      slt_box_no: '003',
      cd_grp_nm: '세입계좌구분코드',
      sys_cd: 'GW',
    }),
  };
};

//조회구분 콤보 조회
export const selectInqSegList = () => {
  return {
    type: FETCH_INQ_SEG_LIST,
    payload: fetchCommonComboList({
      slt_box_no: '003',
      cd_grp_nm: '거래명세조회구분',
      sys_cd: 'CC',
    }),
  };
};

/**
 * 조회조건 저장
 */
export const saveSearchFormValues = (searchFormValues) => {
  return {
    type: SAVE_SEARCH_FORM_VALUES,
    payload: {
      ...searchFormValues,
      init: false,
    },
  };
};

/**
 * 상태초기화
 */
export const resetInitialState = () => {
  return {
    type: RESET_INITIAL_STATE,
  };
};
