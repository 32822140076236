import { useCallback, useEffect, useMemo, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import WOButton from '../../components/common/WOButton';
import WOModal, { ButtonRightArea, ModalButtonArea, ModalContentArea } from '../../components/common/WOModal';
import * as yup from 'yup';
import { yupResolver } from '@hookform/resolvers/yup';
import { Controller, useForm } from 'react-hook-form';
import { changeUserDeptChg, resetChangeDeptState } from '../../redux/modules/cmm/changeDept.reducer';
import { selectDeptCd, selectIstCd } from '../../redux/modules/cmm/signup.reducer';
import * as S from '../../styles/subPage.styled';
import WOLoginInput from '../../components/common/WOLoginInput';
import WOComboBox from '../../components/common/WOComboBox';
import WORadioGroup from '../../components/common/WORadioGroup';
import useAlert from '../../hooks/useAlert';
import useSession from '../../hooks/useSession';

function ChangeDeptPopup({ isOpen, onClose, istCd }) {
  // 부서 변경
  const { alert } = useAlert();
  const [isOpen3, setIsOpen3] = useState(isOpen);
  const dispatch = useDispatch();

  const cdAuthOptions = useMemo(
    () => [
      {
        name: 'radio01',
        id: 1,
        label: '법인카드 권한',
        value: 'Y',
      },
      {
        name: 'radio01',
        id: 2,
        label: '법인카드 미권한',
        value: 'N',
      },
    ],
    []
  );
  const {
    // updating,
    updatingSuccess,
    message: messageByDept,
    messageId,
  } = useSelector((state) => state.changeDeptState);
  const { istNoList: istInfoList, deptNoList: deptCdList } = useSelector((state) => state.signupState);
  const deptSchema = yup.object().shape({
    user_nm: yup.string().required(),
    user_id: yup.string().required(),
    afch_cus_ist_cd: yup.string().required(),
    afch_dept_cd: yup.string().required(),
    cd_auth_yn: yup.string().required(),
    pwd_no: yup.string().required(),
  });
  const {
    handleSubmit: handleDeptSubmit,
    control: deptControl,
    formState: { errors: deptErrors, dirtyFields: deptDirtyFields },
    getValues: getDeptValues,
    reset: resetDept,
    clearErrors: clearDeptErrors,
  } = useForm({
    defaultValues: {
      user_id: '',
      user_nm: '',
      pwd_no: '',
      afch_cus_ist_cd: istCd,
      afch_dept_cd: '',
      cd_auth_yn: '',
    },
    resolver: yupResolver(deptSchema),
  });

  /**
   * 부서변경
   * @param {object} dto
   *
   *
   */
  const handleChangeDept = async (dto) => {
    dispatch(changeUserDeptChg(dto));

    //dispatch(changeUserDeptChg(dto));
  };
  /**
   * 팝업 닫기
   */
  const handleOnClose = () => {
    resetDept();
    setIsOpen3(false);
    onClose?.();
  };

  /**
   * 기관명 변경
   */
  const handleOnIstInfoChange = useCallback(
    (insNo) => {
      // 부서명 콤보 조회
      dispatch(selectDeptCd(insNo));
    },
    [dispatch]
  );

  /**
   * 승인요청 활성화 확인
   */
  const checkChangeDeptDisabled = () => {
    const fields = ['user_id', 'user_nm', 'pwd_no', 'afch_cus_ist_cd', 'afch_dept_cd', 'cd_auth_yn'];
    const dirtyFields = [];
    for (let key in deptDirtyFields) {
      dirtyFields.push(key);
    }

    let len = fields.length;
    if (istCd) {
      len--;
    }

    return len !== dirtyFields.length;
  };

  /**
   * 정보입력 얼럿 유효성 검사
   */
  const checkEmptyInput = () => {
    let msg = '';

    if (getDeptValues().user_id === '') {
      msg = '아이디를 입력해 주세요.';
    } else if (getDeptValues().user_nm === '') {
      msg = '이름을 입력해 주세요.';
    } else if (getDeptValues().pwd_no === '') {
      msg = '비밀번호를 입력해 주세요.';
    } else if (getDeptValues().afch_cus_ist_cd === '') {
      msg = '소속 기관을 선택해 주세요.';
    } else if (getDeptValues().afch_dept_cd === '') {
      msg = '소속 부서를 선택해 주세요.';
    } else if (getDeptValues().cd_auth_yn === '') {
      msg = '법인 카드 권한 유무를 선택해 주세요.';
    }
    msg !== '' && alert(msg);
  };

  useEffect(() => {
    if (dispatch) {
      // 기관명 콤보 조회
      dispatch(selectIstCd());

      // 부서명 콤보 조회
      if (istCd) {
        dispatch(selectDeptCd(istCd));
      }
    }
  }, [dispatch, istCd]);

  useEffect(() => {
    if (updatingSuccess !== null) {
      if (updatingSuccess) {
        alert('부서변경이 요청되었습니다');
        setIsOpen3(false);
      } else {
        alert(messageByDept);
      }
    }
  }, [alert, messageByDept, updatingSuccess]);

  useEffect(() => {
    if (!isOpen3) {
      dispatch(resetChangeDeptState());
      resetDept();
      clearDeptErrors();
    }
  }, [clearDeptErrors, dispatch, isOpen3, resetDept]);

  useEffect(() => {
    setIsOpen3(isOpen);
  }, [isOpen]);

  return (
    <>
      {/* 부서 변경 모달 */}
      <WOModal
        type={'main'}
        direction="center"
        height={516}
        isOpen={isOpen3}
        title={'부서변경'}
        onClose={handleOnClose}>
        <ModalContentArea $gap={20}>
          <S.Container $gap={8}>
            <S.LoginModalDescript>* 관리자 승인 완료 시 부서 변경이 완료됩니다.</S.LoginModalDescript>
            <Controller
              control={deptControl}
              name={'user_id'}
              render={({ field: { onChange, name, value } }) => (
                <WOLoginInput
                  id="input14"
                  label="아이디"
                  maxLength={13}
                  name="input14"
                  styleType={'popup'}
                  placeholder="아이디를 입력해 주세요."
                  onChange={onChange}
                  value={value}
                  error={!!deptErrors.user_id /* || messageId === 'PAPE1013'*/}
                />
              )}
            />
            <Controller
              control={deptControl}
              name={'user_nm'}
              render={({ field: { onChange, name, value } }) => (
                <WOLoginInput
                  id="input15"
                  label="이름"
                  name="input15"
                  styleType={'popup'}
                  placeholder="이름을 입력해 주세요."
                  onChange={onChange}
                  value={value}
                  maxLength={15}
                  error={!!deptErrors.user_nm || messageByDept?.includes('이름')}
                />
              )}
            />
            <Controller
              control={deptControl}
              name={'pwd_no'}
              render={({ field: { onChange, name, value } }) => (
                <WOLoginInput
                  type={'password'}
                  id="input16"
                  label="비밀번호"
                  styleType={'popup'}
                  name="input16"
                  maxLength={16}
                  placeholder="비밀번호를 입력해 주세요."
                  onChange={onChange}
                  value={value}
                  error={!!deptErrors.pwd_no || messageByDept?.includes('비밀번호')}
                />
              )}
            />
          </S.Container>
          <S.ModalTitleline />
          <S.Container $gap={8}>
            <S.Container $direction={'row'}>
              <Controller
                control={deptControl}
                name={'afch_cus_ist_cd'}
                render={({ field: { onChange, name, value } }) => (
                  <WOComboBox
                    label={''}
                    optionList={istInfoList?.map((option) => ({
                      id: option.code,
                      label: option.codenm,
                    }))}
                    onChange={(val) => {
                      onChange(val);
                      handleOnIstInfoChange(val);
                    }}
                    defaultValue={value}
                    placeholder="소속 기관 선택"
                    isAutoComplete={true}
                    type={'type01'}
                    height={48}
                    isError={!!deptErrors.afch_cus_ist_cd || messageId === 'TPGE0003'}
                    disabled={Boolean(istCd)}
                  />
                )}
              />
              <Controller
                control={deptControl}
                name={'afch_dept_cd'}
                render={({ field: { onChange, name, value } }) => (
                  <WOComboBox
                    label={''}
                    optionList={deptCdList.map((option) => ({
                      id: option.code,
                      label: option.codenm,
                      value: option.code,
                    }))}
                    onChange={(val) => {
                      onChange(val);
                    }}
                    defaultValue={value}
                    placeholder="변경 부서 선택"
                    isAutoComplete={true}
                    type={'type01'}
                    height={48}
                    isError={!!deptErrors.afch_dept_cd || messageId === 'TPGE0003'}
                  />
                )}
              />
            </S.Container>
          </S.Container>
          <S.Container>
            <Controller
              control={deptControl}
              name={'cd_auth_yn'}
              render={({ field: { onChange, name, value } }) => (
                <WORadioGroup
                  radioItems={cdAuthOptions}
                  onChange={(value) => {
                    onChange(value.value);
                  }}
                  defaultCheckedValue={cdAuthOptions.find((item) => item.value === value)}
                  styleType={'type01'}
                />
              )}
            />
          </S.Container>
        </ModalContentArea>
        <ModalButtonArea $nonBoxShadow={true}>
          <ButtonRightArea>
            <WOButton variant={'secondary'} size={'md'} onClick={handleOnClose}>
              취소
            </WOButton>
            {checkChangeDeptDisabled() ? (
              <WOButton
                variant={'primary'}
                size={'md'}
                customClass={'disabled'}
                onClick={() => {
                  checkEmptyInput();
                }}>
                승인요청
              </WOButton>
            ) : (
              <WOButton
                variant={'primary'}
                size={'md'}
                disabled={checkChangeDeptDisabled()}
                onClick={() => {
                  handleDeptSubmit(handleChangeDept)(getDeptValues());
                }}>
                승인요청
              </WOButton>
            )}
          </ButtonRightArea>
        </ModalButtonArea>
      </WOModal>
    </>
  );
}

export default ChangeDeptPopup;
