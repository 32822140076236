import { SUCCESS, REQUEST, FAILURE } from '../../../common/utils/action-type-utils';
import { fetchAnexDayMmQr, fetchMoActGbn, fetchDayRpt680, fetchDayRpt170 } from '../../../lib/rpt/RPT0002.api';

// actions
const FETCH_ANEX_DAY_MM_QR = 'RPT0204V01/FETCH_ANEX_DAY_MM_QR';
const FETCH_MO_ACT_GBN = 'RPT0204V01/FETCH_MO_ACT_GBN';
const FETCH_DAY_RPT_680 = 'RPT0204V01/FETCH_DAY_RPT_680';
const FETCH_DAY_RPT_170 = 'RPT0204V01/FETCH_DAY_RPT_170';

// initial state
const initialState = {
  loading: false,
  success: false,
  message: '',
  listTitle: {},
  listTitle01: {},
  list01: [],
  list02: [],
  list03: [],
  listTail: {},
  common: {},
  totalCount: 0,
  head: {},
  moYn: '',
  moYnLoading: false,
  moYnSuccess: false,
  moYnMessage: '',
};

// reducers
export default function RPT0204V01(state = initialState, action) {
  switch (action.type) {
    case SUCCESS(FETCH_ANEX_DAY_MM_QR): // 조회 성공
      return {
        ...state,
        success: true,
        loading: false,
        list01: action.payload.data?.output_data_01,
        list02: action.payload.data?.output_data_02,
        list03: action.payload.data?.output_data_03,
        listTitle: action.payload.data?.output_title,
        listTitle01: action.payload.data?.output_title_01,
        common: action.payload.data?.common,
        head: action.payload.data?.output_head,
      };
    case REQUEST(FETCH_ANEX_DAY_MM_QR): // 조회 요청
      return {
        ...state,
        message: '',
        loading: true,
        success: false,
      };
    case FAILURE(FETCH_ANEX_DAY_MM_QR): // 조회실패
      return {
        ...state,
        listTitle: {},
        listTitle01: {},
        list01: [],
        list02: [],
        list03: [],
        common: {},
        head: {},
        loading: false,
        success: false,
        message: action.payload.response?.data?.message,
      };
    case SUCCESS(FETCH_MO_ACT_GBN): // 조회 성공
      return {
        ...state,
        moYn: action.payload.data?.mo_yn,
        moYnSuccess: true,
        moYnLoading: false,
      };
    case REQUEST(FETCH_MO_ACT_GBN): // 조회 요청
      return {
        ...state,
        moYnMessage: '',
        moYnLoading: true,
        moYnSuccess: false,
      };
    case FAILURE(FETCH_MO_ACT_GBN): // 조회실패
      return {
        ...state,
        moYn: '',
        moYnLoading: false,
        moYnSuccess: false,
        moYnMessage: action.payload.response?.data?.message,
      };
    case SUCCESS(FETCH_DAY_RPT_680): // 조회 성공
      return {
        ...state,
        success: true,
        loading: false,
        list01: action.payload.data?.output_data_01,
        listTitle: action.payload.data?.output_title,
        common: action.payload.data?.common,
        head: action.payload.data?.output_head,
      };
    case REQUEST(FETCH_DAY_RPT_680): // 조회 요청
      return {
        ...state,
        message: '',
        loading: true,
        success: false,
      };
    case FAILURE(FETCH_DAY_RPT_680): // 조회실패
      return {
        ...state,
        listTitle: {},
        list01: [],
        common: {},
        head: {},
        loading: false,
        success: false,
        message: action.payload.response?.data?.message,
      };
    case SUCCESS(FETCH_DAY_RPT_170): // 조회 성공
      return {
        ...state,
        success: true,
        loading: false,
        list01: action.payload.data?.output_data_01,
        listTitle: action.payload.data?.output_title,
        common: action.payload.data?.common,
        head: action.payload.data?.output_head,
      };
    case REQUEST(FETCH_DAY_RPT_170): // 조회 요청
      return {
        ...state,
        message: '',
        loading: true,
        success: false,
      };
    case FAILURE(FETCH_DAY_RPT_170): // 조회실패
      return {
        ...state,
        listTitle: {},
        list01: [],
        common: {},
        head: {},
        loading: false,
        success: false,
        message: action.payload.response?.data?.message,
      };
    default:
      return state;
  }
}

/**
 * 세출보고서 - 세출 일〮월〮분기계표 조회
 */
export const selectAnexDayMmQr = (searchParams) => {
  return {
    type: FETCH_ANEX_DAY_MM_QR,
    payload: fetchAnexDayMmQr(searchParams),
  };
};

/**
 * 세출보고서 - 모계좌여부 구분 확인 조회
 */
export const selectMoActGbn = (searchParams) => {
  return {
    type: FETCH_MO_ACT_GBN,
    payload: fetchMoActGbn(searchParams),
  };
};

/**
 * 강남구청 신한은행 과거내역 조회
 */
export const selectDayRpt680 = (searchParams) => {
  return {
    type: FETCH_DAY_RPT_680,
    payload: fetchDayRpt680(searchParams),
  };
};

/**
 * 용산구청 신한은행 과거내역 조회
 */
export const selectDayRpt170 = (searchParams) => {
  return {
    type: FETCH_DAY_RPT_170,
    payload: fetchDayRpt170(searchParams),
  };
};
