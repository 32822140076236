import instance from '../../common/config/api';

/**
 * 제로페이 - 공지함
 * @param {object} params
 * @returns
 */

export const fetchZpbAncList = (params) =>
  instance.post(`${process.env.REACT_APP_SERVER_URL}/scq/scq/zpb/0002/selectZpayAncList`, params);

/**
 * 제로페이 - 공지함 (상세조회)
 * @param {object} params
 * @returns
 */

export const fetchZpbAncDtl = (params) =>
  instance.post(`${process.env.REACT_APP_SERVER_URL}/scq/scq/zpb/0002/selectZpayAncDtl`, params);

/**
 * 제로페이 - 공지함 (등록)
 * @param {object} params
 * @returns
 */

export const addZpbAnc = (params) =>
  instance.post(`${process.env.REACT_APP_SERVER_URL}/scq/scq/zpb/0002/insertZpayAnc`, params);

/**
 * 제로페이 - 공지함 (수정)
 * @param {object} params
 * @returns
 */

export const fixZpbAnc = (params) =>
  instance.post(`${process.env.REACT_APP_SERVER_URL}/scq/scq/zpb/0002/updateZpayAnc`, params);

/**
 * 제로페이 - 공지함 (삭제)
 * @param {object} params
 * @returns
 */

export const removeZpbAnc = (params) =>
  instance.post(`${process.env.REACT_APP_SERVER_URL}/scq/scq/zpb/0002/deleteZpayAnc`, params);

/**
 * 제로페이 - 공지함 pk채번
 */

export const fetchZpbAncPk = () =>
  instance.post(`${process.env.REACT_APP_SERVER_URL}/scq/scq/zpb/0002/selectZpayAncPk`);
