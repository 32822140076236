import instance from '../../common/config/api';

const prefix = `${process.env.REACT_APP_SERVER_URL}/scq/scq/cmm/user`;

/**
 * 사용자 설정 조회
 */
export const fetchUserEst = (params) => instance.post(`${prefix}/selectUserEst`, params);

/**
 * 사용자 정보 조회
 */
export const fetchUserInfo = () =>
  instance.post(`${process.env.REACT_APP_SERVER_URL}/scq/scq/cmm/user/getSessUser`, {});
