import dayjs from 'dayjs';
import { SUCCESS, REQUEST, FAILURE } from '../../../common/utils/action-type-utils';
import { fetchAnexEcadPayPts } from '../../../lib/axp/AXP0004.api';
import { fetchCommonComboList } from '../../../lib/cmm/combo.api';

// actions
const FETCH_ANEX_ECAD_PAY_PTS = 'AXP0401V02/FETCH_ANEX_ECAD_PAY_PTS';

const CLEAR_MESSAGE = 'AXP0401V02/CLEAR_MESSAGE';
const SET_BEFORE_SEARCH_PARAM = 'AXP0401V02/SET_BEFORE_SEARCH_PARAM';

const FETCH_IST_INFO_LIST = 'AnexEcadIstActbal/FETCH_IST_INFO_LIST';
const FETCH_ACC_YR_LIST = 'AnexEcadIstActbal/FETCH_ACC_YR_LIST';
const FETCH_ARVN_ACT_DSCD_LIST = 'AnexEcadIstActbal/FETCH_ARVN_ACT_DSCD_LIST';
const FETCH_ACC_NO_LIST = 'AnexEcadIstActbal/FETCH_ACT_NO_LIST';
const AGN_ACT_DSCD_LIST = 'AnexItexPayPrts/AGN_ACT_DSCD_LIST';

const RESET_INITIAL_STATE = 'AnexEcadIstActbal/RESET_INITIAL_STATE';
const SAVE_SEARCH_FORM_VALUES = 'AnexEcadIstActbal/SAVE_SEARCH_FORM_VALUES';

export const initFormValues = {
  cus_ist_cd: '6110000', //기관명
  ydcs8a1_t8a01_acc_no: '', //세출회계
  arvn_act_dscd: 'all', //시세/구세
  ydcs8a1_t8a01_acc_yr: 'all', //회계연도
  ydcs8a1_t8a01_inq_sdt: dayjs(), //기준일자
  agn_act_dscd: 'all', //대행계좌구분
  pmdp_act_dscd: '02', //01=세입, 02=세출
};

// initial state
const initialState = {
  loading: false,
  success: true,
  message: '',
  list: [],
  totalCount: 0,
  beforeSearchParam: {},

  //콤보 - 기관명 005
  istInfoSuccess: false,
  istInfoLoading: false,
  istInfoList: [],

  //콤보 - 세출회계 017
  accNoSuccess: false,
  accNoLoading: false,
  accNoList: [],

  //콤보 - 회계연도 025
  accYrSuccess: false,
  accYrLoading: false,
  accYrList: [],

  //콤보 - 시세/구세 003
  arvnActDscdSuccess: false,
  arvnActDscdLoading: false,
  arvnActDscdList: [],

  /*대행계좌 구분 */
  agnActDscdLoading: false,
  agnActDscdSuccess: false,
  agnActDscdList: [],

  // 검색조건
  searchFormValues: {
    init: true,
    ...initFormValues,
  },
};

// reducers
export default function AXP0401V02(state = initialState, action) {
  switch (action.type) {
    case SUCCESS(FETCH_ANEX_ECAD_PAY_PTS): // 조회 성공
      return {
        ...state,
        success: true,
        loading: false,
        list: action.payload.data?.output_data,
        totalCount: action.payload?.output_data_cnt,
      };
    case REQUEST(FETCH_ANEX_ECAD_PAY_PTS): // 조회 요청
      return {
        ...state,
        success: true,
        loading: true,
      };
    case FAILURE(FETCH_ANEX_ECAD_PAY_PTS): // 조회실패
      return {
        ...state,
        list: [],
        loading: false,
        success: false,
        message: action.payload.response?.data?.message || action.payload.message,
      };
    // 기관명
    case SUCCESS(FETCH_IST_INFO_LIST): // 조회 성공
      return {
        ...state,
        istInfoSuccess: true,
        istInfoLoading: false,
        istInfoList: action.payload.data?.subDto,
      };
    case REQUEST(FETCH_IST_INFO_LIST): // 조회 요청
      return {
        ...state,
        istInfoLoading: true,
        istInfoSuccess: false,
      };
    case FAILURE(FETCH_IST_INFO_LIST): // 조회실패
      return {
        istInfoLoading: false,
        istInfoSuccess: false,
        istInfoList: [],
      };
    // 세출회계
    case SUCCESS(FETCH_ACC_NO_LIST): // 조회 성공
      return {
        ...state,
        accNoSuccess: true,
        accNoLoading: false,
        accNoList: action.payload.data?.subDto,
      };
    case REQUEST(FETCH_ACC_NO_LIST): // 조회 요청
      return {
        ...state,
        accNoLoading: true,
        accNoSuccess: false,
      };
    case FAILURE(FETCH_ACC_NO_LIST): // 조회실패
      return {
        ...state,
        accNoLoading: false,
        accNoSuccess: false,
        accNoList: [],
      };
    // 시세/구세
    case SUCCESS(FETCH_ARVN_ACT_DSCD_LIST): // 조회 성공
      return {
        ...state,
        arvnActDscdSuccess: true,
        arvnActDscdLoading: false,
        arvnActDscdList: action.payload.data?.subDto,
      };
    case REQUEST(FETCH_ARVN_ACT_DSCD_LIST): // 조회 요청
      return {
        ...state,
        arvnActDscdLoading: true,
        arvnActDscdSuccess: false,
      };
    case FAILURE(FETCH_ARVN_ACT_DSCD_LIST): // 조회실패
      return {
        ...state,
        arvnActDscdLoading: false,
        arvnActDscdSuccess: false,
        arvnActDscdList: [],
      };
    // 회계연도
    case SUCCESS(FETCH_ACC_YR_LIST): // 조회 성공
      return {
        ...state,
        accYrSuccess: true,
        accYrLoading: false,
        accYrList: action.payload.data?.subDto,
      };
    case REQUEST(FETCH_ACC_YR_LIST): // 조회 요청
      return {
        ...state,
        accYrLoading: true,
        accYrSuccess: false,
      };
    case FAILURE(FETCH_ACC_YR_LIST): // 조회실패
      return {
        ...state,
        accYrLoading: false,
        accYrSuccess: false,
        accYrList: [],
      };
    // 대행계좌구분
    case SUCCESS(AGN_ACT_DSCD_LIST): // 조회 성공
      return {
        ...state,
        agnActDscdSuccess: true,
        agnActDscdLoading: false,
        agnActDscdList: action.payload.data?.subDto,
      };
    case REQUEST(AGN_ACT_DSCD_LIST): // 조회 요청
      return {
        ...state,
        agnActDscdLoading: true,
        agnActDscdSuccess: false,
      };
    case FAILURE(AGN_ACT_DSCD_LIST): // 조회실패
      return {
        ...state,
        agnActDscdLoading: false,
        agnActDscdSuccess: false,
        agnActDscdList: [],
      };
    case SAVE_SEARCH_FORM_VALUES:
      return {
        ...state,
        istInfoSuccess: false,
        istInfoLoading: false,
        accNoLoading: false,
        accNoSuccess: false,
        accYrSuccess: false,
        accYrLoading: false,
        arvnActDscdSuccess: false,
        arvnActDscdLoading: false,
        agnActDscdSuccess: false,
        agnActDscdLoading: false,
        searchFormValues: action.payload,
      };
    case RESET_INITIAL_STATE:
      return initialState;
    case SET_BEFORE_SEARCH_PARAM:
      return {
        ...state,
        beforeSearchParam: action.payload,
      };
    case CLEAR_MESSAGE:
      return {
        ...state,
        message: '',
        success: true,
      };
    default:
      return state;
  }
}

/**
 * 회계별 지급현황 조회
 */
export const selectAnexEcadPayPts = (searchParams) => {
  return {
    type: FETCH_ANEX_ECAD_PAY_PTS,
    payload: fetchAnexEcadPayPts(searchParams),
  };
};

export const clearMessage = () => {
  return {
    type: CLEAR_MESSAGE,
  };
};

/**
 *
 */
export const setBeforeSearchParam = (searchParams) => {
  return {
    type: SET_BEFORE_SEARCH_PARAM,
    payload: searchParams,
  };
};

//기관명 콤보 조회
export const selectIstInfoList = (searchParam) => {
  return {
    type: FETCH_IST_INFO_LIST,
    payload: fetchCommonComboList({ slt_box_no: '005', ...searchParam }),
  };
};

//회계연도 콤보 조회
export const selectAccYrList = () => {
  return {
    type: FETCH_ACC_YR_LIST,
    payload: fetchCommonComboList({ slt_box_no: '025' }),
  };
};

//세출회계 콤보 조회
export const selectAccNoList = (searchParam) => {
  return {
    type: FETCH_ACC_NO_LIST,
    payload: fetchCommonComboList({ slt_box_no: '017', ...searchParam }),
  };
};

//시세/구세 콤보 조회
export const selectArvnActDscdList = () => {
  return {
    type: FETCH_ARVN_ACT_DSCD_LIST,
    payload: fetchCommonComboList({
      slt_box_no: '003',
      cd_grp_nm: '세입계좌구분코드',
      sys_cd: 'GW',
    }),
  };
};

//대행계좌 구분코드
export const selectAgnActDscdList = () => {
  return {
    type: AGN_ACT_DSCD_LIST,
    payload: fetchCommonComboList({
      slt_box_no: '003',
      cd_grp_nm: '대행계좌구분코드',
      sys_cd: 'GW',
    }),
  };
};

/**
 * 조회조건 저장
 */
export const saveSearchFormValues = (searchFormValues) => {
  return {
    type: SAVE_SEARCH_FORM_VALUES,
    payload: {
      ...searchFormValues,
      init: false,
    },
  };
};

/**
 * 상태초기화
 */
export const resetInitialState = () => {
  return {
    type: RESET_INITIAL_STATE,
  };
};
