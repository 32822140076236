import { fetchPmnyLstmFxpdPts, fetchCancFrcsAndFrcsInt, fetchAcnoCst } from '../../../lib/fxp/FXP0001.api';
import { SUCCESS, REQUEST, FAILURE } from '../../../common/utils/action-type-utils';
import dayjs from 'dayjs';
import { fetchCommonComboList } from '../../../lib/cmm/combo.api';

// actions
const FETCH_PMNY_LSTM_FXPD_PTS = 'FXP0101P01/FETCH_PMNY_LSTM_FXPD_PTS';
const FETCH_CANC_FRCS_AND_FRCS_INT = 'FXP0101P01/FETCH_CANC_FRCS_AND_FRCS_INT';
const FETCH_ACNO_CST = 'FXP0101P01/FETCH_ACNO_CST';

const FETCH_IST_INFO_LIST = 'FXP0101P01/FETCH_IST_INFO_LIST';
const FETCH_DEPT_INFO_LIST = 'FXP0101P01/FETCH_DEPT_INFO_LIST';
const FETCH_ACT_NO_LIST = 'FXP0101P01/FETCH_ACT_NO_LIST';

const RESET_INITIAL_STATE = 'FXP0101P01/RESET_INITIAL_STATE';
const SAVE_SEARCH_FORM_VALUES = 'FXP0101P01/SAVE_SEARCH_FORM_VALUES';

const CLEAR_MESSAGE = 'FXP0101P01/CLEAR_MESSAGE';
const SET_BEFORE_SEARCH_PARAM = 'FXP0101P01/SET_BEFORE_SEARCH_PARAM';
const CLEAR_DETAILS = 'FXP0101P01/CLEAR_DETAILS';

export const initFormValues = {
  cus_ist_cd: '6110000',
  cus_dept_cd: 'all',
  ydcs8a1_t8a11_inq_sdt: dayjs().format('YYYY.MM.DD'),
  axpn_act_dscd: 'all',
  ydcs8a1_t8a11_act_no: 'all',
  ydcs8a1_t8a11_act_dscd: '0',
  pmdp_act_dscd: '02',
  selectedDpactNo: '',
  pgm_dis: '138K-01', //IF ID
  pgm_dis2: '138K-07',
};

// initial state
const initialState = {
  loading: false,
  success: false,
  message: '',
  list: [],
  outputTitle: {},
  lists: [],
  totalCount: 0,
  detailsLoading: false,
  detailsSuccess: false,
  detailsMessage: '',
  details: [],
  actNmListLoading: false,
  actNmListSuccess: false,
  actNmListMessage: '',
  actNmList: [],
  beforeSearchParam: {},

  //콤보 - 기관명 005
  istInfoSuccess: false,
  istInfoLoading: false,
  istInfoList: [],

  //콤보 - 부서명 039
  deptInfoSuccess: false,
  deptInfoLoading: false,
  deptInfoList: [],

  //콤보 - 계좌번호 065
  actNoSuccess: false,
  actNoLoading: false,
  actNoList: [],

  //콤보 - 회계연도 025
  accYrSuccess: false,
  accYrLoading: false,
  accYrList: [],

  //콤보 - 시세/구세 003
  arvnActDscdSuccess: false,
  arvnActDscdLoading: false,
  arvnActDscdList: [],

  // 검색조건
  searchFormValues: {
    init: true,
    ...initFormValues,
  },
};

// reducers
export default function FXP0101P01(state = initialState, action) {
  switch (action.type) {
    case SUCCESS(FETCH_PMNY_LSTM_FXPD_PTS): // 조회 성공
      return {
        ...state,
        success: true,
        loading: false,
        list: action.payload.data?.output_data,
        outputTitle: action.payload.data?.output_title,
        totalCount: action.payload.data?.output_data_cnt,
      };
    case REQUEST(FETCH_PMNY_LSTM_FXPD_PTS): // 조회 요청
      return {
        ...state,
        message: '',
        loading: true,
      };
    case FAILURE(FETCH_PMNY_LSTM_FXPD_PTS): // 조회실패
      return {
        ...state,
        list: [],
        loading: false,
        success: false,
        message: action.payload.response?.data?.message,
      };
    case SUCCESS(FETCH_CANC_FRCS_AND_FRCS_INT): // 조회 성공
      return {
        ...state,
        detailsSuccess: true,
        detailsLoading: false,
        details: action.payload.data?.output_data[0],
      };
    case REQUEST(FETCH_CANC_FRCS_AND_FRCS_INT): // 조회 요청
      return {
        ...state,
        detailsMessage: '',
        detailsLoading: true,
      };
    case FAILURE(FETCH_CANC_FRCS_AND_FRCS_INT): // 조회실패
      return {
        ...state,
        details: [],
        detailsLoading: false,
        detailsSuccess: false,
        detailsMessage: action.payload.response?.data?.message,
      };
    case SUCCESS(FETCH_ACNO_CST): // 조회 성공
      return {
        ...state,
        actNmListSuccess: true,
        actNmListLoading: false,
        actNmList: action.payload.data?.output_data_server,
      };
    case REQUEST(FETCH_ACNO_CST): // 조회 요청
      return {
        ...state,
        actNmListMessage: '',
        actNmListLoading: true,
      };
    case FAILURE(FETCH_ACNO_CST): // 조회실패
      return {
        ...state,
        actNmList: [],
        actNmListLoading: false,
        actNmListSuccess: false,
        actNmListMessage: action.payload.response?.data?.message,
      };
    // 기관명
    case SUCCESS(FETCH_IST_INFO_LIST): // 조회 성공
      return {
        ...state,
        istInfoSuccess: true,
        istInfoLoading: false,
        istInfoList: action.payload.data?.subDto,
      };
    case REQUEST(FETCH_IST_INFO_LIST): // 조회 요청
      return {
        ...state,
        istInfoLoading: true,
        istInfoSuccess: false,
      };
    case FAILURE(FETCH_IST_INFO_LIST): // 조회실패
      return {
        istInfoLoading: false,
        istInfoSuccess: false,
        istInfoList: [],
      };
    // 부서명
    case SUCCESS(FETCH_DEPT_INFO_LIST): // 조회 성공
      return {
        ...state,
        deptInfoSuccess: true,
        deptInfoLoading: false,
        deptInfoList: action.payload.data?.subDto,
      };
    case REQUEST(FETCH_DEPT_INFO_LIST): // 조회 요청
      return {
        ...state,
        deptInfoLoading: true,
        deptInfoSuccess: false,
      };
    case FAILURE(FETCH_DEPT_INFO_LIST): // 조회실패
      return {
        ...state,
        deptInfoLoading: false,
        deptInfoSuccess: false,
        deptInfoList: [],
      };
    // 계좌번호
    case SUCCESS(FETCH_ACT_NO_LIST): // 조회 성공
      return {
        ...state,
        actNoSuccess: true,
        actNoLoading: false,
        actNoList: action.payload.data?.subDto,
      };
    case REQUEST(FETCH_ACT_NO_LIST): // 조회 요청
      return {
        ...state,
        actNoLoading: true,
        actNoSuccess: false,
      };
    case FAILURE(FETCH_ACT_NO_LIST): // 조회실패
      return {
        ...state,
        actNoLoading: false,
        actNoSuccess: false,
        actNoList: [],
      };
    case SAVE_SEARCH_FORM_VALUES:
      return {
        ...state,
        istInfoSuccess: false,
        istInfoLoading: false,
        deptInfoSuccess: false,
        deptInfoLoading: false,
        actNoSuccess: false,
        actNoLoading: false,

        searchFormValues: action.payload,
      };
    case RESET_INITIAL_STATE:
      return initialState;
    case SET_BEFORE_SEARCH_PARAM:
      return {
        ...state,
        beforeSearchParam: action.payload,
      };
    case CLEAR_MESSAGE:
      return {
        ...state,
        message: '',
        detailsMessage: '',
        actNmListMessage: '',
        success: false,
        detailsSuccess: false,
      };
    case CLEAR_DETAILS:
      return {
        ...state,
        details: [],
        detailsMessage: '',
        detailsSuccess: false,
        detailsLoading: false,
      };
    default:
      return state;
  }
}

/**
 * 공금성 정기예금 현황조회
 */
export const selectPmnyLstmFxpdPts = (searchParams) => {
  return {
    type: FETCH_PMNY_LSTM_FXPD_PTS,
    payload: fetchPmnyLstmFxpdPts(searchParams),
  };
};

/**
 * 해지예상 조회
 */
export const selectCancFrcsAndFrcsInt = (searchParams) => {
  return {
    type: FETCH_CANC_FRCS_AND_FRCS_INT,
    payload: fetchCancFrcsAndFrcsInt(searchParams),
  };
};

/**
 * 계좌명 조립
 */
export const selectAcnoCst = (searchParams) => {
  return {
    type: FETCH_ACNO_CST,
    payload: fetchAcnoCst(searchParams),
  };
};

/**
 * 메시지초기화
 */
export const clearMessage = () => {
  return {
    type: CLEAR_MESSAGE,
  };
};

/**
 *
 */
export const setBeforeSearchParam = (searchParams) => {
  return {
    type: SET_BEFORE_SEARCH_PARAM,
    payload: searchParams,
  };
};

export const clearDetails = () => {
  return {
    type: CLEAR_DETAILS,
  };
};

//기관명 콤보 조회
export const selectIstInfoList = (searchParam) => {
  return {
    type: FETCH_IST_INFO_LIST,
    payload: fetchCommonComboList({ slt_box_no: '005', ...searchParam }),
  };
};

//부서명 콤보 조회
export const selectDeptInfoList = (searchParam) => {
  return {
    type: FETCH_DEPT_INFO_LIST,
    payload: fetchCommonComboList({ slt_box_no: '039', ...searchParam }),
  };
};

//계좌번호 콤보 조회
export const selectActNoList = (searchParam) => {
  return {
    type: FETCH_ACT_NO_LIST,
    payload: fetchCommonComboList({ slt_box_no: '065', ...searchParam }),
  };
};

/**
 * 조회조건 저장
 */
export const saveSearchFormValues = (searchFormValues) => {
  return {
    type: SAVE_SEARCH_FORM_VALUES,
    payload: {
      ...searchFormValues,
      init: false,
    },
  };
};

/**
 * 상태초기화
 */
export const resetInitialState = () => {
  return {
    type: RESET_INITIAL_STATE,
  };
};
