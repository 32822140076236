import { SUCCESS, REQUEST, FAILURE } from '../../../common/utils/action-type-utils';
import {
  fetchDetAmtList,
  fetchDetAmtDtlList,
  updateAprvDetAmtChgReq,
  mergeDetAmtReq,
  fetchLastChgAmt,
} from '../../../lib/fds/FDS0004.api';
import { fetchCheckPwd } from '../../../lib/etc/ETC0006.api';
import dayjs from 'dayjs';

// actions
const FETCH_DET_AMT_LIST = 'FDS0102M01/FETCH_DET_AMT_LIST';
const FETCH_DET_AMT_DT_LIST = 'FDS0102M01/FETCH_DET_AMT_DT_LIST';
const RESET_DETAIL = 'FDS0102M01/RESET_DETAIL';
const SAVE_APRV_DET_AMT_CHG_REQ = 'FDS0102M01/SAVE_APRV_DET_AMT_CHG_REQ';
const MERGE_DET_AMT_REQ = 'FDS0102M01/MERGE_DET_AMT_REQ';
const FETCH_CHECK_PWD = 'FDS0102M01/FETCH_CHECK_PWD';
const FETCH_LAST_CHG_AMT = 'FDS0102M01/FETCH_LAST_CHG_AMT';
const SAVE_SEARCH_FORM_VALUES = 'FDS0102M01/SAVE_SEARCH_FORM_VALUES';
const RESET_STATE = 'FDS0102M01/RESET_STATE';

export const initFormValues = {
  // 검색 영역
  prog_stat_cd: 'all', //상태
  fds_ist_cd: 'all', //기관구분
  acc_type_cd: 'all', //계좌
  start_app_dt: dayjs().add(-30, 'days').format('YYYY-MM-DD'), //신청일자
  end_app_dt: dayjs().format('YYYY-MM-DD'), //신청일자
};

// initial state
const initialState = {
  loading: false,
  success: false,
  message: '',
  list: [],
  totalCount: 0,
  detailLoading: false,
  detailSuccess: false,
  detailMessage: '',
  detail: {},
  updating: false,
  updateSuccess: false,
  updateMessage: '',
  chkPwdLoading: false,
  chkPwdSuccess: false,
  chkPwdMessage: '',
  chkPwd: false,
  lastChgAmtLoading: false,
  lastChgAmtSuccess: false,
  lastChgAmtMessage: '',
  lastChgAmt: {},
  // 검색조건
  searchFormValues: {
    init: true,
    ...initFormValues,
  },
};

// reducers
export default function FDS0102M01(state = initialState, action) {
  switch (action.type) {
    case SUCCESS(FETCH_DET_AMT_LIST): // 조회 성공
      return {
        ...state,
        success: true,
        loading: false,
        list: action.payload.data?.main,
        totalCount: action.payload?.data?.main_cnt,
      };
    case REQUEST(FETCH_DET_AMT_LIST): // 조회 요청
      return {
        ...state,
        loading: true,
        success: false,
        message: '',
      };
    case FAILURE(FETCH_DET_AMT_LIST): // 조회실패
      return {
        ...initialState,
        loading: false,
        success: false,
        message: action.payload.response?.data?.message,
      };
    case SUCCESS(FETCH_DET_AMT_DT_LIST): // 상세조회 성공
      return {
        ...state,
        detailSuccess: true,
        detailLoading: false,
        detail: action.payload.data?.main[0],
      };
    case REQUEST(FETCH_DET_AMT_DT_LIST): // 상세조회 요청
      return {
        ...state,
        detailLoading: true,
        detailSuccess: false,
        detailMessage: '',
      };
    case FAILURE(FETCH_DET_AMT_DT_LIST): // 상세조회실패
      return {
        ...initialState,
        detailLoading: false,
        detailSuccess: false,
        detailMessage: action.payload.response?.data?.message,
      };
    case SUCCESS(SAVE_APRV_DET_AMT_CHG_REQ): // 승인/반려 성공
    case SUCCESS(MERGE_DET_AMT_REQ): // 신청/취소 요청 성공
      return {
        ...state,
        updateSuccess: true,
        updating: false,
      };
    case REQUEST(SAVE_APRV_DET_AMT_CHG_REQ): // 승인/반려 요청
    case REQUEST(MERGE_DET_AMT_REQ): // 신청/취소 요청 요청
      return {
        ...state,
        updating: true,
        updateSuccess: false,
        updateMessage: '',
      };
    case FAILURE(SAVE_APRV_DET_AMT_CHG_REQ): // 승인/반려 실패
    case FAILURE(MERGE_DET_AMT_REQ): // 신청/취소 요청 실패
      return {
        ...initialState,
        updating: false,
        updateSuccess: false,
        updateMessage: action.payload.response?.data?.message,
      };
    case SUCCESS(FETCH_CHECK_PWD): // 비밀번호 확인 성공
      return {
        ...state,
        chkPwdLoading: false,
        chkPwdSuccess: true,
        chkPwd: action.payload.data?.main,
      };
    case REQUEST(FETCH_CHECK_PWD): // 비밀번호 확인 요청
      return {
        ...state,
        chkPwdLoading: true,
        chkPwdSuccess: false,
        chkPwdMessage: '',
      };
    case FAILURE(FETCH_CHECK_PWD): // 비밀번호 확인 실패
      return {
        ...initialState,
        chkPwdLoading: false,
        chkPwdSuccess: false,
        chkPwdMessage: action.payload.response?.data?.message,
      };
    case SUCCESS(FETCH_LAST_CHG_AMT): // 변경전금액 조회 성공
      return {
        ...state,
        lastChgAmtSuccess: true,
        lastChgAmtLoading: false,
        lastChgAmt: action.payload.data,
      };
    case REQUEST(FETCH_LAST_CHG_AMT): // 변경전금액 조회 요청
      return {
        ...state,
        lastChgAmtLoading: true,
        lastChgAmtSuccess: false,
        lastChgAmtMessage: '',
      };
    case FAILURE(FETCH_LAST_CHG_AMT): // 변경전금액 조회실패
      return {
        ...initialState,
        lastChgAmtLoading: false,
        lastChgAmtSuccess: false,
        lastChgAmtMessage: action.payload.response?.data?.message,
      };
    case RESET_DETAIL:
      return {
        ...state,
        detail: {},
      };
    case SAVE_SEARCH_FORM_VALUES:
      return {
        ...state,
        searchFormValues: action.payload,
      };
    case RESET_STATE:
      return initialState;
    default:
      return state;
  }
}

/**
 * 탐지금액변경 목록 조회
 */
export const selectDetAmtList = (searchParams) => {
  return {
    type: FETCH_DET_AMT_LIST,
    payload: fetchDetAmtList(searchParams),
  };
};

/**
 * 탐지금액변경 상세 조회
 */
export const selectDetAmtDtlList = (searchParams) => {
  return {
    type: FETCH_DET_AMT_DT_LIST,
    payload: fetchDetAmtDtlList(searchParams),
  };
};

/**
 * 상세 데이터 reset
 */
export const resetDetail = () => {
  return {
    type: RESET_DETAIL,
  };
};

/**
 * 탐지금액 변경 승인/반려 처리
 */
export const saveAprvDetAmtChgReq = (searchParams) => {
  return {
    type: SAVE_APRV_DET_AMT_CHG_REQ,
    payload: updateAprvDetAmtChgReq(searchParams),
  };
};

/**
 * 탐지금액 변경 취소 요청
 */
export const saveDetAmtReq = (searchParams) => {
  return {
    type: MERGE_DET_AMT_REQ,
    payload: mergeDetAmtReq(searchParams),
  };
};

/**
 * 비밀번호 확인 요청
 */
export const selectCheckPwd = (searchParams) => {
  return {
    type: FETCH_CHECK_PWD,
    payload: fetchCheckPwd(searchParams),
  };
};

/**
 * 변경전 금액 조회
 */
export const selectLastChgAmt = (searchParams) => {
  return {
    type: FETCH_LAST_CHG_AMT,
    payload: fetchLastChgAmt(searchParams),
  };
};

/**
 * 조회조건 저장
 */
export const saveSearchFormValues = (searchFormValues) => {
  return {
    type: SAVE_SEARCH_FORM_VALUES,
    payload: {
      ...searchFormValues,
      init: false,
    },
  };
};

/**
 * 상태초기화
 */
export const resetState = () => {
  return {
    type: RESET_STATE,
  };
};
