import instance from '../../common/config/api';

const prefix = `${process.env.REACT_APP_SERVER_URL}/scq/scq/fds/0001`;

/**
 * 검출현황 조회
 */
export const fetchAbvTrnPcptGatList = (params) => instance.post(`${prefix}/selectAbvTrnPcptGatList`, params);

/**
 * 룰리스트 조회
 */
export const fetchPmnyFdsRuleList = (params) => instance.post(`${prefix}/selectPmnyFdsRuleList`, params);

/* 메인화면 */

/**
 * 이상감지 건수 조회
 */
export const fetchAbvTrnPcptGatTotCnt = (params) => instance.post(`${prefix}/selectAbvTrnPcptGatTotCnt`, params);

/**
 * 이상감지 TOP3 조회
 */
export const fetchAbvTrnPcptGatTop3 = (params) => instance.post(`${prefix}/selectAbvTrnPcptGatTop3`, params);

/**
 * 이상감지 상중하 건수 조회
 */
export const fetchAbvTrnPcptGatLevCnt = (params) => instance.post(`${prefix}/selectAbvTrnPcptGatLevCnt`, params);

/**
 * 일별 추이_30일기준 (차트) 조회
 */
export const fetchAbvTrnPcptGatBymnPsg = (params) => instance.post(`${prefix}/selectAbvTrnPcptGatBymnPsg`, params);

/**
 * 세부항목발생건수 및 상세내역
 */
export const fetchAbvTrnPcptGatDtls = (params) => instance.post(`${prefix}/selectAbvTrnPcptGatDtls`, params);

/**
 * 금일기준거래 이벤트
 */
export const fetchAbvTrnPcptGatEvt = (params) => instance.post(`${prefix}/selectAbvTrnPcptGatEvt`, params);

/**
 * 실시간 이벤트
 */
export const fetchAbvTrnPcptGatRltmEvt = (params) => instance.post(`${prefix}/selectAbvTrnPcptGatRltmEvt`, params);

/**
 * 이상거래탐지수집 세부발생항목 조회
 */
export const fetchAbvTrnPcptGatItemDtls = (params) => instance.post(`${prefix}/selectAbvTrnPcptGatItemDtls`, params);

/* 메인화면 END */
