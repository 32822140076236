import {
  fetchDbSysdate,
  fetchGndpActInf,
  fetchGndpActTrnPrts,
  fetchTrhs,
  fetchActOgbrMnbrNm,
} from '../../../lib/fxp/GndpTrnPrtsInq.api';

import { SUCCESS, REQUEST, FAILURE } from '../../../common/utils/action-type-utils';

// actions
const FETCH_DB_SYSDATE = 'ARV0401P01/FETCH_DB_SYSDATE';
const FETCH_GNDP_ACT_INF = 'ARV0401P01/FETCH_GNDP_ACT_INF';
const FETCH_GNDP_ACT_TRN_PRTS = 'ARV0401P01/FETCH_GNDP_ACT_TRN_PRTS';
const FETCH_TRHS = 'ARV0401P01/FETCH_TRHS';
const FETCH_ACT_OGBR_MNBR_NM = 'ARV0401P01/FETCH_ACT_OGBR_MNBR_NM';
const RESET_INITIAL_STATE = 'ARV0401P01/RESET_INITIAL_STATE';

// initial state
const initialState = {
  loading: false,
  success: false,
  message: '',
  list: [],

  dbSysdate: {},
  dbSysdateLoading: false,
  dbSysdateSuccess: false,

  gndpActInf: [],
  gndpActInfLoading: false,
  gndpActInfSuccess: false,
  gndpActInfMessage: '',

  gndpActTrnPrts: [],
  gndpActTrnPrtsLoading: false,
  gndpActTrnPrtsSuccess: false,
  gndpActTrnPrtsMessage: '',

  trhs: [],
  trhsLoading: false,
  trhsSuccess: false,
  trhsMessage: '',

  actOgbrMnbrNm: '',
  totalCount: 0,
};

// reducers
export default function ARV0401P01(state = initialState, action) {
  switch (action.type) {
    case SUCCESS(FETCH_DB_SYSDATE): // 조회 성공
      return {
        ...state,
        dbSysdateSuccess: true,
        dbSysdateLoading: false,
        dbSysdate: action.payload.data?.output_sysdate,
      };
    case REQUEST(FETCH_DB_SYSDATE): // 조회 요청
      return {
        ...state,
        gndpActTrnPrts: [],
        trhs: [],
        dbSysdateLoading: true,
      };
    case FAILURE(FETCH_DB_SYSDATE): // 조회실패
      return {
        ...state,
        dbSysdate: {},
        dbSysdateLoading: false,
        dbSysdateSuccess: false,
      };
    case SUCCESS(FETCH_GNDP_ACT_INF): // 조회 성공
      return {
        ...state,
        gndpActInfSuccess: true,
        gndpActInfLoading: false,
        gndpActInf: action.payload.data?.output_data_01,
      };
    case REQUEST(FETCH_GNDP_ACT_INF): // 조회 요청
      return {
        ...state,
        gndpActInfLoading: true,
      };
    case FAILURE(FETCH_GNDP_ACT_INF): // 조회실패
      return {
        ...state,
        gndpActInf: [],
        gndpActInfLoading: false,
        gndpActInfSuccess: false,
        gndpActInfMessage: action.payload.response?.data?.message,
      };
    case SUCCESS(FETCH_GNDP_ACT_TRN_PRTS): // 조회 성공
      return {
        ...state,
        gndpActTrnPrtsSuccess: true,
        gndpActTrnPrtsLoading: false,
        gndpActTrnPrts: action.payload.data?.output_data,
      };
    case REQUEST(FETCH_GNDP_ACT_TRN_PRTS): // 조회 요청
      return {
        ...state,
        gndpActTrnPrtsLoading: true,
      };
    case FAILURE(FETCH_GNDP_ACT_TRN_PRTS): // 조회실패
      return {
        ...state,
        gndpActTrnPrts: [],
        gndpActTrnPrtsLoading: false,
        gndpActTrnPrtsSuccess: false,
        gndpActTrnPrtsMessage: action.payload.response?.data?.message,
      };
    case SUCCESS(FETCH_TRHS): // 조회 성공
      return {
        ...state,
        trhsSuccess: true,
        trhsLoading: false,
        trhs: action.payload.data?.output_data_s,
      };
    case REQUEST(FETCH_TRHS): // 조회 요청
      return {
        ...state,
        loading: true,
      };
    case FAILURE(FETCH_TRHS): // 조회실패
      return {
        ...state,
        trhs: [],
        loading: false,
        success: false,
        //message: action.payload.response?.data?.message,
      };
    case SUCCESS(FETCH_ACT_OGBR_MNBR_NM): // 조회 성공
      return {
        ...state,
        success: true,
        loading: false,
        list: action.payload.data?.subDto,
        totalCount: action.payload?.subDtoCnt,
      };
    case REQUEST(FETCH_ACT_OGBR_MNBR_NM): // 조회 요청
      return {
        ...state,
        loading: true,
      };
    case FAILURE(FETCH_ACT_OGBR_MNBR_NM): // 조회실패
      return {
        ...state,
        list: [],
        loading: false,
        success: false,
        message: action.payload.response?.data?.message,
      };
    case RESET_INITIAL_STATE:
      return initialState;
    default:
      return state;
  }
}

/**
 * DB 서버시간 조회
 */
export const selectDbSysdate = (searchParams) => {
  return {
    type: FETCH_DB_SYSDATE,
    payload: fetchDbSysdate(searchParams),
  };
};

/**
 * 보통예금 계좌정보조회
 */
export const selectGndpActInf = (searchParams) => {
  return {
    type: FETCH_GNDP_ACT_INF,
    payload: fetchGndpActInf(searchParams),
  };
};

/**
 * 보통예금 계좌거래내역 조회
 */
export const selectGndpActTrnPrts = (searchParams) => {
  return {
    type: FETCH_GNDP_ACT_TRN_PRTS,
    payload: fetchGndpActTrnPrts(searchParams),
  };
};

/**
 * 거래명세 조회
 */
export const selectTrhs = (searchParams) => {
  return {
    type: FETCH_TRHS,
    payload: fetchTrhs(searchParams),
  };
};

/**
 * 계좌 개설점/관리점 조회
 */
export const selectActOgbrMnbrNm = (searchParams) => {
  return {
    type: FETCH_ACT_OGBR_MNBR_NM,
    payload: fetchActOgbrMnbrNm(searchParams),
  };
};

/**
 * 상태초기화
 */
export const resetInitialState = () => {
  return {
    type: RESET_INITIAL_STATE,
  };
};
