import dayjs from 'dayjs';
import { SUCCESS, REQUEST, FAILURE } from '../../../common/utils/action-type-utils';
import {
  fetchPbamClsaIntHisList,
  fetchPbamClsaIntRcvPrtsList,
  fetchPbamIntHisList,
} from '../../../lib/arv/ARV0601V01.api';
import { fetchCommonComboList } from '../../../lib/cmm/combo.api';

// actions
const FETCH_PBAM_INT_HIS_LIST = 'ARV0601V01/FETCH_PBAM_INT_HIS_LIST';
const FETCH_PBAM_CLSA_INT_HIS_LIST = 'ARV0601V01/FETCH_PBAM_CLSA_INT_HIS_LIST';
const FETCH_PBAM_CLSA_INT_RCV_PRTS_LIST = 'ARV0601V01/FETCH_PBAM_CLSA_INT_RCV_PRTS_LIST';

const FETCH_IST_INFO_LIST = 'ARV0601V01/FETCH_IST_INFO_LIST';
const FETCH_DEPT_INFO_LIST = 'ARV0601V01/FETCH_DEPT_INFO_LIST';
const FETCH_ACC_YR_LIST = 'ARV0601V01/FETCH_ACC_YR_LIST';
const FETCH_AXP_ACC_NO_LIST = 'ARV0601V01/FETCH_AXP_ACC_NO_LIST';
const SAVE_SEARCH_FORM_VALUES = 'ARV0601V01/SAVE_SEARCH_FORM_VALUES';
const RESET_STATE = 'ARV0601V01/RESET_STATE';

export const initFormValues = {
  arvn_act_dscd: 'all',
  axpn_act_cd: '',
  br_cd: '',
  cus_dept_cd: 'all',
  cus_ist_cd: 'all',
  inq_edt: dayjs().format('YYYY-MM-DD'),
  inq_sdt: dayjs().format('YYYY-MM-DD'),
  mn: '00',
  pgm_dis: '',
  ydcs8a1_t8a01_act_no: '',
  ydcs8a1_t8a02_acc_yr: '',
  ydcs8a1_t8a02_act_no: '', // 세출계좌
  yyyy: '',
  agn_act_dscd: 'all',
  act_type: '1',
  search_type: '1',
  check_dt: false,
};

// initial state
const initialState = {
  loading: false,
  success: null,
  message: '',
  list: [],
  totalCount: 0,
  // 콤보 - 기관명
  istInfoSuccess: false,
  istInfoLoading: false,
  istInfoList: [],
  // 콤보 - 부서명
  deptInfoSuccess: false,
  deptInfoLoading: false,
  deptInfoList: [],
  // 콤보 - 회계연도
  accYrSuccess: false,
  accYrLoading: false,
  accYrList: [],
  // 콤보 - 계좌목록 (세출,세입)
  axpAccNoSuccess: false,
  axpAccNoLoading: false,
  axpAccNoList: [],
  // 검색조건
  searchFormValues: {
    init: true,
    ...initFormValues,
  },
};

// reducers
export default function ARV0601V01(state = initialState, action) {
  switch (action.type) {
    case SUCCESS(FETCH_PBAM_INT_HIS_LIST): // 조회 성공
    case SUCCESS(FETCH_PBAM_CLSA_INT_HIS_LIST): // 조회 성공
    case SUCCESS(FETCH_PBAM_CLSA_INT_RCV_PRTS_LIST): // 조회 성공
      return {
        ...state,
        success: true,
        loading: false,
        list: action.payload.data?.output_data,
        totalCount: action.payload?.data?.output_data_cnt,
      };
    case REQUEST(FETCH_PBAM_INT_HIS_LIST): // 조회 요청
    case REQUEST(FETCH_PBAM_CLSA_INT_HIS_LIST): // 조회 요청
    case REQUEST(FETCH_PBAM_CLSA_INT_RCV_PRTS_LIST): // 조회 요청
      return {
        ...state,
        loading: true,
        success: null,
      };
    case FAILURE(FETCH_PBAM_INT_HIS_LIST): // 조회실패
    case FAILURE(FETCH_PBAM_CLSA_INT_HIS_LIST): // 조회실패
    case FAILURE(FETCH_PBAM_CLSA_INT_RCV_PRTS_LIST): // 조회실패
      return {
        ...initialState,
        list: [],
        message: action.payload.response?.data?.message,
      };
    // 기관명
    case SUCCESS(FETCH_IST_INFO_LIST): // 조회 성공
      return {
        ...state,
        istInfoSuccess: true,
        istInfoLoading: false,
        istInfoList: action.payload.data?.subDto,
      };
    case REQUEST(FETCH_IST_INFO_LIST): // 조회 요청
      return {
        ...state,
        istInfoLoading: true,
        istInfoSuccess: false,
      };
    case FAILURE(FETCH_IST_INFO_LIST): // 조회실패
      return {
        istInfoLoading: false,
        istInfoSuccess: false,
        istInfoList: [],
      };
    // 부서명
    case SUCCESS(FETCH_DEPT_INFO_LIST): // 조회 성공
      return {
        ...state,
        deptInfoSuccess: true,
        deptInfoLoading: false,
        deptInfoList: action.payload.data?.subDto,
      };
    case REQUEST(FETCH_DEPT_INFO_LIST): // 조회 요청
      return {
        ...state,
        deptInfoLoading: true,
        deptInfoSuccess: false,
      };
    case FAILURE(FETCH_DEPT_INFO_LIST): // 조회실패
      return {
        deptInfoLoading: false,
        deptInfoSuccess: false,
        deptInfoList: [],
      };
    // 회계연도
    case SUCCESS(FETCH_ACC_YR_LIST): // 조회 성공
      return {
        ...state,
        accYrSuccess: true,
        accYrLoading: false,
        accYrList: action.payload.data?.subDto,
      };
    case REQUEST(FETCH_ACC_YR_LIST): // 조회 요청
      return {
        ...state,
        accYrLoading: true,
        accYrSuccess: false,
      };
    case FAILURE(FETCH_ACC_YR_LIST): // 조회실패
      return {
        accYrLoading: false,
        accYrSuccess: false,
        accYrList: [],
      };
    // 계좌목록 (세출,세입)
    case SUCCESS(FETCH_AXP_ACC_NO_LIST): // 조회 성공
      return {
        ...state,
        axpAccNoSuccess: true,
        axpAccNoLoading: false,
        axpAccNoList: action.payload.data?.subDto,
      };
    case REQUEST(FETCH_AXP_ACC_NO_LIST): // 조회 요청
      return {
        ...state,
        axpAccNoLoading: true,
        axpAccNoSuccess: false,
      };
    case FAILURE(FETCH_AXP_ACC_NO_LIST): // 조회실패
      return {
        axpAccNoLoading: false,
        axpAccNoSuccess: false,
        axpAccNoList: [],
      };
    case SAVE_SEARCH_FORM_VALUES:
      return {
        ...state,
        // 콤보 - 기관
        istInfoSuccess: false,
        istInfoLoading: false,
        // 콤보 - 부서명
        deptInfoSuccess: false,
        deptInfoLoading: false,
        // 콤보 - 회계연도
        accYrSuccess: false,
        accYrLoading: false,
        // 콤보 - 계좌목록 (세출,세입)
        axpAccNoSuccess: false,
        axpAccNoLoading: false,
        searchFormValues: action.payload,
      };
    case RESET_STATE:
      return initialState;
    default:
      return state;
  }
}

/**
 * 공금예금 이자 조회 - 공금예금 이자명세조회
 */
export const selectPbamIntHis = (searchParams) => {
  return {
    type: FETCH_PBAM_INT_HIS_LIST,
    payload: fetchPbamIntHisList(searchParams),
  };
};

/**
 * 공금예금 이자 조회 - 공금예금 결산이자명세 조회
 */
export const selectPbamClsaIntHis = (searchParams) => {
  return {
    type: FETCH_PBAM_CLSA_INT_HIS_LIST,
    payload: fetchPbamClsaIntHisList(searchParams),
  };
};

/**
 * 공금예금 이자 조회 - 입금내역
 */
export const selectPbamClsaIntRcvPrts = (searchParams) => {
  return {
    type: FETCH_PBAM_CLSA_INT_RCV_PRTS_LIST,
    payload: fetchPbamClsaIntRcvPrtsList(searchParams),
  };
};

/**
 * 기관명 콤보 조회
 */
export const selectIstInfoComboList = (searchParams) => {
  return {
    type: FETCH_IST_INFO_LIST,
    payload: fetchCommonComboList({ slt_box_no: '005', ...searchParams }),
  };
};

/**
 * 부서명 콤보 조회
 */
export const selectDeptInfoComboList = (searchParams) => {
  return {
    type: FETCH_DEPT_INFO_LIST,
    payload: fetchCommonComboList({ slt_box_no: '039', ...searchParams }),
  };
};

/**
 * 회계연도 콤보 조회
 */
export const selectAccYrComboList = () => {
  return {
    type: FETCH_ACC_YR_LIST,
    payload: fetchCommonComboList({ slt_box_no: '142' }),
  };
};

/**
 * 계좌번호 콤보 조회
 */
export const selectAxpAccNoComboList = (searchParams) => {
  return {
    type: FETCH_AXP_ACC_NO_LIST,
    payload: fetchCommonComboList({ slt_box_no: '143', ...searchParams }),
  };
};

/**
 * 조회조건 저장
 */
export const saveSearchFormValues = (searchFormValues) => {
  return {
    type: SAVE_SEARCH_FORM_VALUES,
    payload: {
      ...searchFormValues,
      init: false,
    },
  };
};

/**
 * 상태초기화
 */
export const resetState = () => {
  return {
    type: RESET_STATE,
  };
};
