import instance from '../../common/config/api';

/**
 * 사용자유형별 메뉴권한 조회
 * @param {object} params
 * @returns
 */
export const fetchMenuAutByUserTpList = (params) =>
  instance.post(`${process.env.REACT_APP_SERVER_URL}/scq/scq/sys/0003/selectMenuByUserTpList`, params);

/**
 * 사용자유형 조회
 * @returns
 */
export const fetchUserTpList = (dtos) =>
  instance.post(`${process.env.REACT_APP_SERVER_URL}/scq/scq/sys/0003/selectUserTpList2`);

/**
 * 사용자유형별 메뉴권한 수정
 * @returns
 */
export const mergeMenuAutByUserTp = (dtos) =>
  instance.post(`${process.env.REACT_APP_SERVER_URL}/scq/scq/sys/0003/saveMenuByUserTp`, {
    main: dtos,
    mainCnt: dtos.length,
  });
