import dayjs from 'dayjs';
import { SUCCESS, REQUEST, FAILURE } from '../../../common/utils/action-type-utils';
import { fetchCommonComboList } from '../../../lib/cmm/combo.api';
import { fetchSMChrgNoRgList, updateSMChrgNoRgList } from '../../../lib/zpb/apb0108m01';

// actions
const FETCH_SMCHRG_NO_RG_LIST = 'zpb0108m01/FETCH_SMCHRG_NO_RG_LIST';
const SAVE_SMCHRG_NO_RG_LIST = 'zpb0108m01/SAVE_SMCHRG_NO_RG_LIST';

const FETCH_IST_INFO_LIST = 'zpb0108m01/FETCH_IST_INFO_LIST';
const FETCH_DEPT_INFO_LIST = 'zpb0108m01/FETCH_DEPT_INFO_LIST';
const FETCH_ACC_YR_LIST = 'zpb0108m01/FETCH_ACC_YR_LIST';
const FETCH_ACT_NO_LIST = 'zpb0108m01/FETCH_ACT_NO_LIST';
const SAVE_SEARCH_FORM_VALUES = 'zpb0108m01/SAVE_SEARCH_FORM_VALUES';
const RESET_STATE = 'zpb0108m01/RESET_STATE';

export const initFormValues = {
  cus_ist_cd: '',
  ydcs8a1_t8a01_acc_yr: dayjs().format('YYYY'),
  ydcs8a1_t8a01_act_no: '',
};

// initial state
const initialState = {
  loading: false,
  success: false,
  message: '',
  list: [],
  totalCount: 0,
  updateSuccess: false,
  resultCnt: -1,
  // 기관명
  istInfoSuccess: false,
  istInfoLoading: false,
  istInfoList: [],
  // 부서명
  deptInfoSuccess: false,
  deptInfoLoading: false,
  deptInfoList: [],
  // 회계연도
  accYrSuccess: false,
  accYrLoading: false,
  accYrList: [],
  // 일상경비계좌
  actNoSuccess: false,
  actNoLoading: false,
  actNoList: [],
  resultMessage: '',
  // 검색조건
  searchFormValues: {
    init: true,
    ...initFormValues,
  },
};

// reducers
export default function zpb0108m01(state = initialState, action) {
  switch (action.type) {
    case SUCCESS(FETCH_SMCHRG_NO_RG_LIST): // 조회 성공
      return {
        ...state,
        success: true,
        loading: false,
        list: action.payload.data?.subDto,
        totalCount: action.payload.data?.subDtoCnt,
        updateSuccess: false,
      };
    case REQUEST(FETCH_SMCHRG_NO_RG_LIST): // 조회 요청
      return {
        ...state,
        message: '',
        loading: true,
        success: false,
        updateSuccess: false,
      };
    case FAILURE(FETCH_SMCHRG_NO_RG_LIST): // 조회실패
      return {
        ...state,
        loading: false,
        success: false,
        message: action.payload.response?.data?.message,
        updateSuccess: false,
      };
    case SUCCESS(SAVE_SMCHRG_NO_RG_LIST): // 저장 성공
      return {
        ...state,
        updateSuccess: true,
        loading: false,
        resultCnt: action.payload.data.resultCnt,
        resultMessage: action.payload.data,
      };
    case REQUEST(SAVE_SMCHRG_NO_RG_LIST): // 저장 요청
      return {
        ...state,
        message: '',
        loading: true,
        updateSuccess: false,
        resultMessage: '',
      };
    case FAILURE(SAVE_SMCHRG_NO_RG_LIST): // 저장 실패
      return {
        ...state,
        loading: false,
        updateSuccess: false,
        resultCnt: -1,
        message: action.payload.response?.data?.message,
      };
    // 기관명
    case SUCCESS(FETCH_IST_INFO_LIST): // 조회 성공
      return {
        ...state,
        istInfoSuccess: true,
        istInfoLoading: false,
        istInfoList: action.payload.data?.subDto,
      };
    case REQUEST(FETCH_IST_INFO_LIST): // 조회 요청
      return {
        ...state,
        istInfoLoading: true,
        istInfoSuccess: false,
      };
    case FAILURE(FETCH_IST_INFO_LIST): // 조회실패
      return {
        istInfoLoading: false,
        istInfoSuccess: false,
        istInfoList: [],
      };
    // 부서명
    case SUCCESS(FETCH_DEPT_INFO_LIST): // 조회 성공
      return {
        ...state,
        deptInfoSuccess: true,
        deptInfoLoading: false,
        deptInfoList: action.payload.data?.subDto,
      };
    case REQUEST(FETCH_DEPT_INFO_LIST): // 조회 요청
      return {
        ...state,
        deptInfoLoading: true,
        deptInfoSuccess: false,
      };
    case FAILURE(FETCH_DEPT_INFO_LIST): // 조회실패
      return {
        ...state,
        deptInfoSuccess: false,
        deptInfoLoading: false,
        deptInfoList: [],
      };
    // 회계연도
    case SUCCESS(FETCH_ACC_YR_LIST): // 조회 성공
      return {
        ...state,
        accYrSuccess: true,
        accYrLoading: false,
        accYrList: action.payload.data?.subDto,
      };
    case REQUEST(FETCH_ACC_YR_LIST): // 조회 요청
      return {
        ...state,
        accYrLoading: true,
        accYrSuccess: false,
      };
    case FAILURE(FETCH_ACC_YR_LIST): // 조회실패
      return {
        ...state,
        accYrLoading: false,
        accYrSuccess: false,
        accYrList: [],
      };
    // 일상경비계좌
    case SUCCESS(FETCH_ACT_NO_LIST): // 조회 성공
      return {
        ...state,
        actNoSuccess: true,
        actNoLoading: false,
        actNoList: action.payload.data?.subDto,
      };
    case REQUEST(FETCH_ACT_NO_LIST): // 조회 요청
      return {
        ...state,
        actNoLoading: true,
        actNoSuccess: false,
      };
    case FAILURE(FETCH_ACT_NO_LIST): // 조회실패
      return {
        ...state,
        actNoLoading: false,
        actNoSuccess: false,
        actNoList: [],
      };
    case SAVE_SEARCH_FORM_VALUES:
      return {
        ...state,
        // 기관명
        istInfoSuccess: false,
        istInfoLoading: false,
        // 부서명
        deptInfoSuccess: false,
        deptInfoLoading: false,
        // 회계연도
        accYrSuccess: false,
        accYrLoading: false,
        // 일상경비계좌
        actNoSuccess: false,
        actNoLoading: false,
        searchFormValues: action.payload,
      };
    case RESET_STATE:
      return initialState;
    default:
      return state;
  }
}

/**
 * 제로페이 - 서무담당 번호 등록(조회)
 */
export const selectSMChrgNoRgList = (searchParams) => {
  return {
    type: FETCH_SMCHRG_NO_RG_LIST,
    payload: fetchSMChrgNoRgList(searchParams),
  };
};

/**
 * 제로페이 - 서무담당 번호 저장
 */
export const saveSMChrgNoRgList = (dtos) => {
  return {
    type: SAVE_SMCHRG_NO_RG_LIST,
    payload: updateSMChrgNoRgList(dtos),
  };
};

/**
 * 기관명 콤보 조회
 */
export const selectIstInfoComboList = (searchParams) => {
  return {
    type: FETCH_IST_INFO_LIST,
    payload: fetchCommonComboList({ slt_box_no: '005', ...searchParams }),
  };
};

/**
 * 부서명 콤보 조회
 */
export const selectDeptInfoComboList = (searchParams) => {
  return {
    type: FETCH_DEPT_INFO_LIST,
    payload: fetchCommonComboList({ slt_box_no: '199', ...searchParams }),
  };
};

/**
 * 회계연도 콤보 조회
 */
export const selectAccYrComboList = (searchParams) => {
  return {
    type: FETCH_ACC_YR_LIST,
    payload: fetchCommonComboList({ slt_box_no: '025', ...searchParams }),
  };
};

/**
 * 일상경비계좌 콤보 조회
 */
export const selectActNoComboList = (searchParams) => {
  return {
    type: FETCH_ACT_NO_LIST,
    payload: fetchCommonComboList({ slt_box_no: '196', ...searchParams }),
  };
};

/**
 * 조회조건 저장
 */
export const saveSearchFormValues = (searchFormValues) => {
  return {
    type: SAVE_SEARCH_FORM_VALUES,
    payload: {
      ...searchFormValues,
      init: false,
    },
  };
};

/**
 * 상태초기화
 */
export const resetState = () => {
  return {
    type: RESET_STATE,
  };
};
