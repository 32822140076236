import { fetchBrCdManage, updateRptDt } from '../../../lib/rpt/RPT0403V01.api';
import { SUCCESS, REQUEST, FAILURE } from '../../../common/utils/action-type-utils';

// actions
const FETCH_BRCD_MANAGE = 'RPT0403V01/fetchBrCdManage';
const UPDATE_RP_DT = 'RPT0403V01/updateRptDt';
const SAVE_SEARCH_FORM_VALUES = 'RPT0403V01/SAVE_SEARCH_FORM_VALUES';
const RESET_STATE = 'RPT0403V01/RESET_STATE';

export const initFormValues = {
  // 검색 영역
  ydcs8a1_t8a01_inq_seg: 'all', //점출력구분
  br_cd: '', //영업점코드
  gubun2: 'all', //보고서종류
  rpt_date: '', //수정일자
  rpt_yn: '',
};

// initial state
const initialState = {
  loading: false,
  success: true,
  message: '',
  list: [],
  totalCount: 0,
  updateSuccess: false,
  updating: false,
  // 검색조건
  searchFormValues: {
    init: true,
    ...initFormValues,
  },
};

// reducers
export default function RPT0403V01(state = initialState, action) {
  switch (action.type) {
    case SUCCESS(FETCH_BRCD_MANAGE): // 조회 성공
      return {
        ...state,
        success: true,
        loading: false,
        list: action.payload.data?.main,
        updateSuccess: false,
        totalCount: action.payload?.main_cnt,
      };
    case REQUEST(FETCH_BRCD_MANAGE): // 조회 요청
      return {
        ...state,
        updateSuccess: false,
        loading: true,
      };
    case FAILURE(FETCH_BRCD_MANAGE): // 조회실패
      return {
        ...initialState,
        loading: false,
        success: false,
        updateSuccess: false,
        message: action.payload.response?.data?.message,
      };
    case SUCCESS(UPDATE_RP_DT): // 보고일자수정 성공
      return {
        ...state,
        updateSuccess: true,
        updating: false,
        resultCnt: action.payload.data.resultCnt,
      };
    case REQUEST(UPDATE_RP_DT): // 보고일자수정 요청
      return {
        ...state,
        updating: true,
        updateSuccess: false,
      };
    case FAILURE(UPDATE_RP_DT): // 보고일자수정 실패
      return {
        ...initialState,
        updating: false,
        updateSuccess: false,
        resultCnt: -1,
        message: action.payload.response?.data?.message,
      };
    case SAVE_SEARCH_FORM_VALUES:
      return {
        ...state,
        searchFormValues: action.payload,
      };
    case RESET_STATE:
      return initialState;
    default:
      return { ...state, updateSuccess: false };
  }
}

/**
 * 조회
 */
export const selectBrCdManage = (searchParams) => {
  return {
    type: FETCH_BRCD_MANAGE,
    payload: fetchBrCdManage(searchParams),
  };
};

/**
 * 보고일자수정
 */
export const updateRptDtAll = (dtos) => {
  return {
    type: UPDATE_RP_DT,
    payload: updateRptDt(dtos),
  };
};

/**
 * 조회조건 저장
 */
export const saveSearchFormValues = (searchFormValues) => {
  return {
    type: SAVE_SEARCH_FORM_VALUES,
    payload: {
      ...searchFormValues,
      init: false,
    },
  };
};

/**
 * 상태초기화
 */
export const resetState = () => {
  return {
    type: RESET_STATE,
  };
};
