import React, { useState } from 'react';
import styled, { css } from 'styled-components';
import WOCheckbox from '../common/WOCheckbox';

const WOWorkMenuItem = ({ title, hasChildren, children, type, id, value, onChange }) => {
  const [depthOepn, setDepthOpen] = useState(false);

  const handelDepthOpen = () => {
    setDepthOpen(!depthOepn);
  };

  /**
   * 체크 선택변경시
   */
  const handleOnChange = (e, id) => {
    onChange?.(e?.target?.checked, id);
  };

  const childrenCount = React.Children.count(children);

  return (
    <Wrappper>
      <WorkMenuItem onClick={hasChildren ? handelDepthOpen : () => {}}>
        <LeftArea>
          <Icon $depthOepn={depthOepn} $hasChildren={hasChildren} $type={type} />
          <Title
            onClick={() => {
              // console.log(checkboxRef.current);
              handleOnChange(null, id);
            }}>
            {title}
          </Title>
        </LeftArea>
        <RightArea>
          {!hasChildren && (
            <>
              {type === 'tab' ? (
                <>
                  {/* <Line /> */}
                  <Line />
                </>
              ) : (
                <>
                  <WOCheckbox
                    blind
                    item={{
                      id: id,
                      name: 'input01-01',
                    }}
                    value={value}
                    onChange={(e) => handleOnChange(e, id)}
                  />
                </>
              )}
            </>
          )}
        </RightArea>
      </WorkMenuItem>
      {children && (
        <WorkMenuChilren $depthOepn={depthOepn} $childrenCount={childrenCount - 1}>
          {children}
        </WorkMenuChilren>
      )}
    </Wrappper>
  );
};

export default WOWorkMenuItem;

const Wrappper = styled.div`
  height: auto;
`;

const WorkMenuItem = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  width: 100%;
  height: 32px;
  padding: 0 16px;
  &:hover {
    background: var(--C-Dark-01);
  }

  ${(props) =>
    props.$hasChildren &&
    css`
      height: 36px;
    `}
`;

const WorkMenuChilren = styled.div`
  height: 0;
  overflow: hidden;
  ${(props) =>
    props.$depthOepn &&
    css`
      height: auto;
    `}
`;

const LeftArea = styled.div`
  display: flex;
  align-items: center;
  gap: 0 6px;
`;
const RightArea = styled.div`
  display: flex;
  align-items: center;
  gap: 0 4px;
`;

const Line = styled.div`
  width: 1px;
  height: 32px;
  margin: 0 7.5px;
  background: var(--C-Dark-05);

  & + & {
    width: 18px;
  }
`;

const Icon = styled.div`
  width: 16px;
  height: 16px;
  background: url(/assets/images/icon/folder-color.svg) no-repeat center / cover;
  ${(props) =>
    props.$hasChildren &&
    css`
      background: url(/assets/images/icon/folder-color-close.svg) no-repeat center / cover;
      ${props.$depthOepn &&
      css`
        background: url(/assets/images/icon/folder-color-open.svg) no-repeat center / cover;
      `}
    `}
  ${(props) =>
    (props.$type === 'depth' || props.$type === 'tab' || props.$type === 'firstTab') &&
    css`
      background: unset;
    `}
`;

const Title = styled.div`
  color: #333;
  font-size: 14px;
  font-weight: 400;
  line-height: 21px;
  ${(props) =>
    props.$hasChildren &&
    css`
      font-weight: 500;
    `}
`;
