import instance from '../../common/config/api';

/**
 * 고객기관명 조회
 * @param {object} params
 * @returns
 */
export const fetchInstInfoList = (params) =>
  instance.post(`${process.env.REACT_APP_SERVER_URL}/scq/scq/cmm/istinfo/selectIstInfoList`, params);

/**
 * 계좌목록 조회(세출)
 * @param {object} params
 * @returns
 */
export const fetchActNoList = (params) =>
  instance.post(`${process.env.REACT_APP_SERVER_URL}/scq/scq/cmm/0000/ActNoList`, params);

/**
 * 공통콤보 조회
 * @param {object} params
 * @returns
 */
export const fetchCommonComboList = (params) =>
  instance.post(`${process.env.REACT_APP_SERVER_URL}/scq/scq/cmm/0000/CmmComboList`, params);
