import { Route, Routes } from 'react-router-dom';
import Layout from './components/layout/Layout';
import SubLayout from './components/layout/SubLayout';
import { useCallback, useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
// import { selectListPgm } from './redux/modules/cmm/program.reducer';
// import GvcmPrtsInq from './pages/axp/gvcmPrtsInq';
// import ARV0101V01 from './pages/arv/ARV0101V01';
// import ARV0101V02 from './pages/arv/ARV0101V02';
// import ARV0101V03 from './pages/arv/ARV0101V03';
// import ARV0101V04 from './pages/arv/ARV0101V04';
// import ARV0101V05 from './pages/arv/ARV0101V05';
// import ARV0301V01 from './pages/arv/ARV0301V01';
// import GndpIntAts from './pages/arv/GndpIntAts';
// import IntClsaPrrVrf from './pages/arv/IntClsaPrrVrf';
// import Sample from './pages/sample/Sample';
// import AcctPayCmdAm from './pages/ebk/AcctPayCmdAm';
// import PmnyAcnoBalAmBankCls from './pages/ebk/PmnyAcnoBalAmBankCls';
// import ExpdCmdTrdnInq from './pages/ebk/ExpdCmdTrdnInq';
// import TrsfCstd from './pages/ebk/TrsfCstd';
// import TsNaPrts from './pages/ebk/TsNaPrts';
// import EbkPrcRst from './pages/ebk/EbkPrcRst';
// import ListUserAutMng from './pages/cad/ListUserAutMng';
// import CanStlMng from './pages/brc/canStlMng';
// import CadApvPrts from './pages/cad/CadApvPrts';
// import CadBuyPrts from './pages/cad/CadBuyPrts';
// import ItpyPts from './pages/fxp/ItpyPts';
// import AcctXprPts from './pages/fxp/AcctXprPts';
// import XpradPts from './pages/fxp/XpradPts';
// import GndpPts from './pages/fxp/GndpPts';
// import GndpTrnPrtsInq from './pages/fxp/GndpTrnPrtsInq';
// import MmdaPts from './pages/fxp/MmdaPts';
// import MmdaItpyInq from './pages/fxp/MmdaItpyInq';
// import FXP0101P01 from './pages/fxp/FXP0101P01';
// import FXP0101V02 from './pages/fxp/FXP0101V02';
// import FXP0101V03 from './pages/fxp/FXP0101V03';
// import FXP0401V01 from './pages/fxp/FXP0401V01';
// import FXP0402V01 from './pages/fxp/FXP0402V01';
// import FXP0104V01 from './pages/fxp/FXP0104V01';
// import FXP0403V01 from './pages/fxp/FXP0403V01';
// import AnrvTrnPrtsOld from './pages/arv/AnrvTrnPrtsOld';
// import RPT0602V01 from './pages/rpt/RPT0602V01';
// import RPT0603V01 from './pages/rpt/RPT0603V01';
// import CadClamPrts from './pages/cad/CadClamPrts';
// import CadNrqPrts from './pages/cad/CadNrqPrts';
// import ZpbApvPrts from './pages/zpb/ZpbApvPrts';
// import ZpbExuPrts from './pages/zpb/ZpbExuPrts';
// import ZpbApvExuNacrd from './pages/zpb/ZpbApvExuNacrd';
// import ZpbIsuRgr from './pages/zpb/ZpbIsuRgr';
// import ZpbLimBzct from './pages/zpb/ZpbLimBzct';
// import ZpbStlActTrnPrts from './pages/zpb/ZpbStlActTrnPrts';
// import ZpbStlPts from './pages/zpb/ZpbStlPts';
// import ZPB0205V01 from './pages/zpb/ZPB0205V01';
// import ZPB0107V01 from './pages/zpb/ZPB0107V01';
// import ZPB0204V01 from './pages/zpb/ZPB0204V01';
// import TdyCan from './pages/brc/TdyCan';
// import NotTdyCan from './pages/brc/NotTdyCan';
// import DeepMng from './pages/brc/DeedMng';
// import BRC0204M01 from './pages/brc/BRC0204M01';
import ReportPage from './pages/cmm/reportPage';
// import FdAlocPrts from './pages/axp/FdAlocPrts';
// import ChactFdAlocPrts from './pages/axp/ChactFdAlocPrts';
// import AnexEcadIstActbal from './pages/axp/AnexEcadIstActbal';
import MainLayout from './components/layout/MainLayout';
import Main from './pages/Main';
// import CadStlPrts from './pages/cad/CadStlPrts';
// import CadDfrPrts from './pages/cad/CadDfrPrts';
// import CadStlActtrnPrts from './pages/cad/CadStlActtrnPrts';
// import DeptMng from './pages/cad/DeptMng';
// import DeptCpcadHis from './pages/cad/DeptCpcadHis';
// import DeptUsgPts from './pages/cad/DeptUsgPts';
// import CpcadIsuLdg from './pages/cad/CpcadIsuLdg';
// import ScbhWeakCopPurPrts from './pages/cad/ScbhWeakCopPurPrts';
// import WeakCopRgs from './pages/cad/WeakCopRgs';
// import AnexAcnoList from './pages/axp/AnexAcnoList';
// import AnexTrnPrtsList from './pages/axp/AnexTrnPrtsList';
// import AnexTrhsList from './pages/axp/AnexTrhsList';
// import AnexItexPayPrts from './pages/axp/AnexItexPayPrts';
// import AnexTrnPrtsListOld from './pages/axp/AnexTrnPrtsListOld';
// import AXP0301V01 from './pages/axp/AXP0301V01';
// import AXP0401V02 from './pages/axp/AXP0401V02';
// import AXP0101V04 from './pages/axp/AXP0101V04';
// import EvdXpnAcnoList from './pages/ils/EvdXpnAcnoList';
// import EvdXpnTrnPrtsList from './pages/ils/EvdXpnTrnPrtsList';
// import EvdXpnTrhsList from './pages/ils/EvdXpnTrhsList';
// import EvdXpnImpExpdMmSht from './pages/ils/EvdXpnImpExpdMmSht';
// import RtrnAmRcvTrhs from './pages/ils/RtrnAmRcvTrhs';
// import IstDeptCdMng from './pages/sys/IstDeptCdMng';
// import DeptGenActNo from './pages/sys/DeptGenActNo';
import Error404 from './pages/cmm/error404';
import Login from './pages/Login';
import Signup from './pages/Signup';
import SsoLogin from './pages/SsoLogin';
// import SYS0107M01 from './pages/sys/SYS0107M01';
// import LcsbInfMng from './pages/brc/lcsbInfMng';
// import NtMng from './pages/sys/NtMng';
// import CmmCdMng from './pages/sys/CmmCdMng';
// import EtcAsstPrbtAnls from './pages/etc/EtcAsstPrbtAnls';
// import EtcAsstPrbtTndc from './pages/etc/EtcAsstPrbtTndc';
// import EtcAsstAtcntPts from './pages/etc/EtcAsstAtcntPts';
// import EtcAsstBznAccnBgtBssnRt from './pages/etc/EtcAsstBznAccnBgtBssnRt';
// import SiteMng from './pages/sys/SiteMng';
// import OfislMng from './pages/sys/OfislMng';
// import GenDmdActNoMng from './pages/sys/GenDmdActNoMng';
// import SystemMng from './pages/sys/SystemMng';
// import OwprDlrpMnrr from './pages/rpt/OwprDlrpMnrr';
// import ETC0402V01 from './pages/etc/ETC0402V01';
// import ETC0101M01 from './pages/etc/ETC0101M01';
// import ETC0102V01 from './pages/etc/ETC0102V01';
// import ETC0201V01 from './pages/etc/ETC0201V01';
// import ETC0202V01 from './pages/etc/ETC0202V01';
// import ETC0403V01 from './pages/etc/ETC0403V01';
// import ETC0406V01 from './pages/etc/ETC0406V01';
// import ETC0404V01 from './pages/etc/ETC0404V01';
// import ETC0405M01 from './pages/etc/ETC0405M01';
// import ETC0502V01 from './pages/etc/ETC0502V01';
// import ARV0601V01 from './pages/arv/ARV0601V01';
// import ETC0401V01 from './pages/etc/ETC0401V01';
// import BRC0206V02 from './pages/brc/BRC0206V02';
// import BRC0207V03 from './pages/brc/BRC0207V03';
// import BRC0207V01 from './pages/brc/BRC0207V01';
// import BRC0207V02 from './pages/brc/BRC0207V02';
// import UserDelLog from './pages/sys/UserDelLog';
// import ZPB0108M01 from './pages/zpb/ZPB0108M01';
// import SYS0304M01 from './pages/sys/SYS0304M01';
// import FdOpgHbokBkg from './pages/rpt/FdOpgHbokBkg';
// import AnrvAnexCashFdDay from './pages/rpt/AnrvAnexCashFdDay';
// import ARV0201V01 from './pages/arv/ARV0201V01';
// import SYS0109M01 from './pages/sys/SYS0109M01';
// import SYS0110M01 from './pages/sys/SYS0110M01';
// import BRC0203V01 from './pages/brc/BRC0203V01';
// import SYS0102M01 from './pages/sys/SYS0102M01';
// import CrossSellList from './pages/brc/CrossSellList';
// import SYS0108M01 from './pages/sys/SYS0108M01';
// import SYS0203V01 from './pages/sys/SYS0203V01';
// import ARV0401V01 from './pages/arv/ARV0401V01';
// import SYS0206M01 from './pages/sys/SYS0206M01';
// import BRC0210V01 from './pages/brc/BRC0210V01';
// import RPT0102V01 from './pages/rpt/RPT0102V01';
// import SYS0204M01 from './pages/sys/SYS0204M01';
// import BRC0209V01 from './pages/brc/BRC0209V01';
// import SYS0306M01 from './pages/sys/SYS0306M01';
// import SYS0307M01 from './pages/sys/SYS0307M01';
// import SYS0308M01 from './pages/sys/SYS0308M01';
// import SYS0309M01 from './pages/sys/SYS0309M01';
// import SYS0310M01 from './pages/sys/SYS0310M01';
// import SYS0311M01 from './pages/sys/SYS0311M01';
// import SYS0312M01 from './pages/sys/SYS0312M01';
// import ETC0501V01 from './pages/etc/ETC0501V01';
// import ZPB0201V01 from './pages/zpb/ZPB0201V01';
// import SYS0209V01 from './pages/sys/SYS0209V01';
// import RPT0201V01 from './pages/rpt/RPT0201V01';
// import RPT0202V01 from './pages/rpt/RPT0202V01';
// import RPT0403V01 from './pages/rpt/RPT0403V01';
// import RPT0402M01 from './pages/rpt/RPT0402M01.jsx';
// import RPT0204V01 from './pages/rpt/RPT0204V01';
// import RPT0301V01 from './pages/rpt/RPT0301V01';
// import ZPB0207M01 from './pages/zpb/ZPB0207M01';
// import ZPB0202V01 from './pages/zpb/ZPB0202V01';
// import ZPB0203V01 from './pages/zpb/ZPB0203V01';
// import ETC0501M01 from './pages/etc/ETC0501M01';
// import RPT0601V01 from './pages/rpt/RPT0601V01';
// import FDS0101V01 from './pages/fds/FDS0101V01';
// import FDS0103V01 from './pages/fds/FDS0103V01';
// import ZPB0206V01 from './pages/zpb/ZPB0206V01';
// import FDS0102M01 from './pages/fds/FDS0102M01';
// import RPT0104V01 from './pages/rpt/RPT0104V01';
// import RPT0105V01 from './pages/rpt/RPT0105V01';
// import RPT0401M01 from './pages/rpt/RPT0401M01';
// import SYS0205M01 from './pages/sys/SYS0205M01';
// import SYS0208V01 from './pages/sys/SYS0208V01';
// import SYS0207M01 from './pages/sys/SYS0207M01';
// import SYS0201M01 from './pages/sys/SYS0201M01';
// import ReportTest from './pages/sample/ReportTest';
// import ILS0301V01 from './pages/ils/ILS0301V01';
// import MenuMng from './pages/sys/MenuMng';
// import FDS0104V01 from './pages/fds/FDS0104V01';
// import RPT0101V01 from './pages/rpt/RPT0101V01';

function App() {
  const dispatch = useDispatch();
  const [programList, setProgramList] = useState([]);
  // const { loading, list, success, message } = useSelector((state) => state.program);
  const { cmSession, menuList: sessionMenuList } = useSelector((state) => state.loginState);
  const { list: menuList } = useSelector((state) => state.menuState);
  const [user, setUser] = useState();

  const dynamicImport = async (programPath) => {
    const module = await import(`.${programPath}`);
    const ProgramComponent = module.default;
    return <ProgramComponent />;
  };

  // 사용자 권한 확인
  const checkUserType = useCallback((autTpCd, userTpCd) => {
    if (autTpCd === '00' && (userTpCd === 'WL0001' || userTpCd === 'WL0002' || userTpCd === 'WL1000')) {
      return {
        id: 1,
        type: '우리은행 총괄',
        group: '우리은행 총괄',
      };
    } else if (autTpCd === '20' && (userTpCd === 'SL1006' || userTpCd === 'SL1100')) {
      return {
        id: 2,
        type: '시금고 총괄 관리자 ',
        group: '시금고 총괄 관리자 ',
      };
    } else if (autTpCd === '30' && userTpCd === 'SL0004') {
      return {
        id: 3,
        type: '시금고 담당자 ',
        group: '시금고 담당자 ',
      };
    } /* if (autTpCd === '30' && userTpCd === 'TODO') */ else {
      return {
        id: 4,
        type: '산하기관',
        group: '산하기관',
      };
    }
  }, []);

  // useEffect(() => {
  //   if (list.length > 0) {
  //     list.forEach((prog) => {
  //       dynamicImport(prog.pgm_pth).then((component) => {
  //         setProgramList((prev) => [...prev, { ...prog, element: component }]);
  //       });
  //     });
  //   }
  // }, [list]);

  // useEffect(() => {
  //   console.log('programList', programList);
  // }, [programList]);

  useEffect(() => {
    const userInfo = JSON.parse(localStorage.getItem('user_info'));
    if (userInfo?.user_id) {
      const userType = checkUserType(userInfo?.aut_tp_cd, userInfo?.user_tp_cd);
      // 사용자유형
      setUser(userType);
    }
  }, [checkUserType, cmSession]);

  useEffect(() => {
    if (sessionMenuList.length > 0) {
      sessionMenuList
        .filter((menu) => menu.mnu_aut_cd !== 'X')
        .forEach((menu) => {
          if (menu.pgm_pth) {
            dynamicImport(menu.pgm_pth?.trim()).then((component) => {
              setProgramList((prev) => [...prev, { ...menu, element: component }]);
            });
          }
        });
    }
  }, [sessionMenuList]);

  useEffect(() => {
    if (menuList.length > 0) {
      setProgramList([]);
      menuList
        .filter((menu) => menu.mnu_aut_cd !== 'X')
        .forEach((menu) => {
          if (menu.pgm_pth) {
            dynamicImport(menu.pgm_pth?.trim()).then((component) => {
              setProgramList((prev) => [...prev, { ...menu, element: component }]);
            });
          }
        });
    }
  }, [menuList]);

  useEffect(() => {
    const localMenuList = JSON.parse(localStorage.getItem('menu_list'));
    if (localMenuList?.length > 0) {
      setProgramList([]);
      localMenuList
        .filter((menu) => menu.mnu_aut_cd !== 'X')
        .forEach((menu) => {
          if (menu.pgm_pth) {
            dynamicImport(menu.pgm_pth?.trim()).then((component) => {
              setProgramList((prev) => [...prev, { ...menu, element: component }]);
            });
          }
        });
    }
  }, []);

  return (
    <Layout>
      <Routes>
        <Route path="/" element={<MainLayout user={user} />}>
          <Route path="/" element={<Main user={user} />} />
        </Route>

        {/* <Route path="/" element={<MainLayout user={user} />}>
          <Route path="/main-01" element={<Main user={userList[0]} />} />
        </Route>
        <Route path="/" element={<MainLayout user={userList[1]} />}>
          <Route path="/main-02" element={<Main user={userList[1]} />} />
        </Route>
        <Route path="/" element={<MainLayout user={userList[2]} />}>
          <Route path="/main-03" element={<Main user={userList[2]} />} />
        </Route>
        <Route path="/" element={<MainLayout user={userList[3]} />}>
          <Route path="/main-04" element={<Main user={userList[3]} />} />
        </Route> */}
        <Route path="/page/SsoLogin" element={<SsoLogin />} />
        <Route path="/page/login" element={<Login />} />
        <Route path="/page/Signup" element={<Signup />} />
        <Route path="/page/ReportPage" element={<ReportPage />} />
        <Route path="/" element={<SubLayout user={user} />}>
          {programList
            .filter((menu) => menu.scrn_url !== '')
            .map((menu, index) => {
              return <Route path={menu.scrn_url?.trim()} key={index} element={menu.element} />;
            })}

          {/* <Route path="/page/ReportTest" element={<ReportTest />} />
          <Route path="/page/ARV0101V01" element={<ARV0101V01 />} />
          <Route path="/page/ARV0101V02" element={<ARV0101V02 />} />
          <Route path="/page/ARV0101V04" element={<ARV0101V04 />} />
          <Route path="/page/ARV0101V03" element={<ARV0101V03 />} />
          <Route path="/page/ARV0101V05" element={<ARV0101V05 />} />
          <Route path="/page/AnrvTrnPrtsOld" element={<AnrvTrnPrtsOld />} />
          <Route path="/page/ARV0601V01" element={<ARV0601V01 />} />
          <Route path="/page/ARV0201V01" element={<ARV0201V01 />} />
          <Route path="/page/ARV0401V01" element={<ARV0401V01 />} />
          <Route path="/page/ARV0301V01" element={<ARV0301V01 />} />
          <Route path="/page/AnexAcnoList" element={<AnexAcnoList />} />
          <Route path="/page/AnexTrnPrtsList" element={<AnexTrnPrtsList />} />
          <Route path="/page/AnexTrhsList" element={<AnexTrhsList />} />
          <Route path="/page/AnexTrnPrtsListOld" element={<AnexTrnPrtsListOld />} />
          <Route path="/page/AnexItexPayPrts" element={<AnexItexPayPrts />} />
          <Route path="/page/AnexEcadIstActbal" element={<AnexEcadIstActbal />} />
          <Route path="/page/AXP0301V01" element={<AXP0301V01 />} />
          <Route path="/page/AXP0401V02" element={<AXP0401V02 />} />
          <Route path="/page/EvdXpnAcnoList" element={<EvdXpnAcnoList />} />
          <Route path="/page/EvdXpnTrnPrtsList" element={<EvdXpnTrnPrtsList />} />
          <Route path="/page/EvdXpnTrhsList" element={<EvdXpnTrhsList />} />
          <Route path="/page/EvdXpnImpExpdMmSht" element={<EvdXpnImpExpdMmSht />} />
          <Route path="/page/RtrnAmRcvTrhs" element={<RtrnAmRcvTrhs />} />
          <Route path="/page/AcctPayCmdAm" element={<AcctPayCmdAm />} />
          <Route path="/page/PmnyAcnoBalAmBankCls" element={<PmnyAcnoBalAmBankCls />} />
          <Route path="/page/ExpdCmdTrdnInq" element={<ExpdCmdTrdnInq />} />
          <Route path="/page/TrsfCstd" element={<TrsfCstd />} />
          <Route path="/page/TsNaPrts" element={<TsNaPrts />} />
          <Route path="/page/EbkPrcRst" element={<EbkPrcRst />} />
          <Route path="/ListUserAutMng" element={<ListUserAutMng />} />
          <Route path="/GndpIntAts" element={<GndpIntAts />} />
          <Route path="/GvcmPrtsInq" element={<GvcmPrtsInq />} />
          <Route path="/CanStlMng" element={<CanStlMng />} />
          <Route path="/CadApvPrts" element={<CadApvPrts />} />
          <Route path="/CadBuyPrts" element={<CadBuyPrts />} />
          <Route path="/CadClamPrts" element={<CadClamPrts />} />
          <Route path="/CadNrqPrts" element={<CadNrqPrts />} />
          <Route path="/CadStlPrts" element={<CadStlPrts />} />
          <Route path="/CadDfrPrts" element={<CadDfrPrts />} />
          <Route path="/CadStlActtrnPrts" element={<CadStlActtrnPrts />} />
          <Route path="/DeptMng" element={<DeptMng />} />
          <Route path="/DeptCpcadHis" element={<DeptCpcadHis />} />
          <Route path="/DeptUsgPts" element={<DeptUsgPts />} />
          <Route path="/CpcadIsuLdg" element={<CpcadIsuLdg />} />
          <Route path="/ScbhWeakCopPurPrts" element={<ScbhWeakCopPurPrts />} />
          <Route path="/page/AnexAcnoList" element={<AnexAcnoList />} />
          <Route path="/page/AnexTrnPrtsList" element={<AnexTrnPrtsList />} />
          <Route path="/page/AnexTrhsList" element={<AnexTrhsList />} />
          <Route path="/page/IntClsaPrrVrf" element={<IntClsaPrrVrf />} />
          <Route path="/page/ListUserAutMng" element={<ListUserAutMng />} />
          <Route path="/page/GndpIntAts" element={<GndpIntAts />} />
          <Route path="/page/GvcmPrtsInq" element={<GvcmPrtsInq />} />
          <Route path="/page/CanStlMng" element={<CanStlMng />} />
          <Route path="/page/CadApvPrts" element={<CadApvPrts />} />
          <Route path="/page/CadBuyPrts" element={<CadBuyPrts />} />
          <Route path="/page/CadClamPrts" element={<CadClamPrts />} />
          <Route path="/page/CadNrqPrts" element={<CadNrqPrts />} />
          <Route path="/page/CadStlPrts" element={<CadStlPrts />} />
          <Route path="/page/CadDfrPrts" element={<CadDfrPrts />} />
          <Route path="/page/CadStlActtrnPrts" element={<CadStlActtrnPrts />} />
          <Route path="/page/RPT0101V01" element={<RPT0101V01 />} />
          <Route path="/page/OwprDlrpMnrr" element={<OwprDlrpMnrr />} />
          <Route path="/page/FdOpgHbokBkg" element={<FdOpgHbokBkg />} />
          <Route path="/page/AnrvAnexCashFdDay" element={<AnrvAnexCashFdDay />} />
          <Route path="/page/RPT0201V01" element={<RPT0201V01 />} />
          <Route path="/page/RPT0202V01" element={<RPT0202V01 />} />
          <Route path="/page/RPT0602V01" element={<RPT0602V01 />} />
          <Route path="/page/RPT0102V01" element={<RPT0102V01 />} />
          <Route path="/page/RPT0403V01" element={<RPT0403V01 />} />
          <Route path="/page/RPT0402M01" element={<RPT0402M01 />} />
          <Route path="/page/RPT0204V01" element={<RPT0204V01 />} />
          <Route path="/page/RPT0301V01" element={<RPT0301V01 />} />
          <Route path="/page/RPT0601V01" element={<RPT0601V01 />} />
          <Route path="/page/RPT0603V01" element={<RPT0603V01 />} />
          <Route path="/page/RPT0104V01" element={<RPT0104V01 />} />
          <Route path="/page/RPT0105V01" element={<RPT0105V01 />} />
          <Route path="/page/DeptMng" element={<DeptMng />} />
          <Route path="/page/DeptCpcadHis" element={<DeptCpcadHis />} />
          <Route path="/page/DeptUsgPts" element={<DeptUsgPts />} />
          <Route path="/page/CpcadIsuLdg" element={<CpcadIsuLdg />} />
          <Route path="/page/ScbhWeakCopPurPrts" element={<ScbhWeakCopPurPrts />} />
          <Route path="/page/WeakCopRgs" element={<WeakCopRgs />} />
          <Route path="/page/FXP0101P01" element={<FXP0101P01 />} />
          <Route path="/page/ItpyPts" element={<ItpyPts />} />
          <Route path="/page/AcctXprPts" element={<AcctXprPts />} />
          <Route path="/page/XpradPts" element={<XpradPts />} />
          <Route path="/page/GndpPts" element={<GndpPts />} />
          <Route path="/page/GndpTrnPrtsInq" element={<GndpTrnPrtsInq />} />
          <Route path="/page/MmdaPts" element={<MmdaPts />} />
          <Route path="/page/MmdaItpyInq" element={<MmdaItpyInq />} />
          <Route path="/page/FXP0101P01" element={<FXP0101P01 />} />
          <Route path="/page/FXP0101V02" element={<FXP0101V02 />} />
          <Route path="/page/FXP0101V03" element={<FXP0101V03 />} />
          <Route path="/page/FXP0401V01" element={<FXP0401V01 />} />
          <Route path="/page/FXP0402V01" element={<FXP0402V01 />} />
          <Route path="/page/FXP0104V01" element={<FXP0104V01 />} />
          <Route path="/page/FXP0403V01" element={<FXP0403V01 />} />
          <Route path="/page/ZpbApvPrts" element={<ZpbApvPrts />} />
          <Route path="/page/ZpbExuPrts" element={<ZpbExuPrts />} />
          <Route path="/page/ZpbApvExuNacrd" element={<ZpbApvExuNacrd />} />
          <Route path="/page/ZpbIsuRgr" element={<ZpbIsuRgr />} />
          <Route path="/page/ZpbLimBzct" element={<ZpbLimBzct />} />
          <Route path="/page/ZpbStlActTrnPrts" element={<ZpbStlActTrnPrts />} />
          <Route path="/page/ZpbStlPts" element={<ZpbStlPts />} />
          <Route path="/page/ZPB0107V01" element={<ZPB0107V01 />} />
          <Route path="/page/ZPB0108M01" element={<ZPB0108M01 />} />
          <Route path="/page/ZPB0201V01" element={<ZPB0201V01 />} />
          <Route path="/page/ZPB0202V01" element={<ZPB0202V01 />} />
          <Route path="/page/ZPB0204V01" element={<ZPB0204V01 />} />
          <Route path="/page/ZPB0205V01" element={<ZPB0205V01 />} />
          <Route path="/page/ZPB0207M01" element={<ZPB0207M01 />} />
          <Route path="/page/ZPB0203V01" element={<ZPB0203V01 />} />
          <Route path="/page/ZPB0206V01" element={<ZPB0206V01 />} />
          <Route path="/page/TdyCan" element={<TdyCan />} />
          <Route path="/page/NotTdyCan" element={<NotTdyCan />} />
          <Route path="/page/DeepMng" element={<DeepMng />} />
          <Route path="/page/BRC0203V01" element={<BRC0203V01 />} />
          <Route path="/page/LcsbInfMng" element={<LcsbInfMng />} />
          <Route path="/page/BRC0210V01" element={<BRC0210V01 />} />
          <Route path="/page/FdAlocPrts" element={<FdAlocPrts />} />
          <Route path="/page/ChactFdAlocPrts" element={<ChactFdAlocPrts />} />
          <Route path="/page/BRC0206V02" element={<BRC0206V02 />} />
          <Route path="/page/BRC0207V03" element={<BRC0207V03 />} />
          <Route path="/page/BRC0207V01" element={<BRC0207V01 />} />
          <Route path="/page/BRC0207V02" element={<BRC0207V02 />} />
          <Route path="/page/BRC0209V01" element={<BRC0209V01 />} />
          <Route path="/page/BRC0204M01" element={<BRC0204M01 />} />
          <Route path="/page/CrossSellList" element={<CrossSellList />} />
          <Route path="/page/ETC0101M01" element={<ETC0101M01 />} />
          <Route path="/page/ETC0102V01" element={<ETC0102V01 />} />
          <Route path="/page/ETC0401V01" element={<ETC0401V01 />} />
          <Route path="/page/ETC0404V01" element={<ETC0404V01 />} />
          <Route path="/page/ETC0405M01" element={<ETC0405M01 />} />
          <Route path="/page/ETC0406V01" element={<ETC0406V01 />} />
          <Route path="/page/ETC0403V01" element={<ETC0403V01 />} />
          <Route path="/page/ETC0501V01" element={<ETC0501V01 />} />
          <Route path="/page/ETC0201V01" element={<ETC0201V01 />} />
          <Route path="/page/ETC0202V01" element={<ETC0202V01 />} />
          <Route path="/page/EtcAsstPrbtAnls" element={<EtcAsstPrbtAnls />} />
          <Route path="/page/EtcAsstPrbtTndc" element={<EtcAsstPrbtTndc />} />
          <Route path="/page/EtcAsstAtcntPts" element={<EtcAsstAtcntPts />} />
          <Route path="/page/EtcAsstBznAccnBgtBssnRt" element={<EtcAsstBznAccnBgtBssnRt />} />
          <Route path="/page/ETC0402V01" element={<ETC0402V01 />} />
          <Route path="/page/ETC0501M01" element={<ETC0501M01 />} />
          <Route path="/page/ETC0502V01" element={<ETC0502V01 user={user} />} />
          <Route path="/page/IstDeptCdMng" element={<IstDeptCdMng />} />
          <Route path="/page/SYS0102M01" element={<SYS0102M01 />} />
          <Route path="/page/GenDmdActNoMng" element={<GenDmdActNoMng />} />
          <Route path="/page/SYS0304M01" element={<SYS0304M01 />} />
          <Route path="/page/SYS0306M01" element={<SYS0306M01 />} />
          <Route path="/page/SYS0307M01" element={<SYS0307M01 />} />
          <Route path="/page/SYS0308M01" element={<SYS0308M01 />} />
          <Route path="/page/SYS0309M01" element={<SYS0309M01 />} />
          <Route path="/page/SYS0310M01" element={<SYS0310M01 />} />
          <Route path="/page/SYS0311M01" element={<SYS0311M01 />} />
          <Route path="/page/SYS0312M01" element={<SYS0312M01 />} />
          <Route path="/page/DeptGenActNo" element={<DeptGenActNo />} />
          <Route path="/page/OfislMng" element={<OfislMng />} />
          <Route path="/page/NtMng" element={<NtMng />} />
          <Route path="/page/SYS0107M01" element={<SYS0107M01 />} />
          <Route path="/page/SYS0108M01" element={<SYS0108M01 />} />
          <Route path="/page/SYS0109M01" element={<SYS0109M01 />} />
          <Route path="/page/SYS0110M01" element={<SYS0110M01 />} />
          <Route path="/page/CmmCdMng" element={<CmmCdMng />} />
          <Route path="/page/SiteMng" element={<SiteMng />} />
          <Route path="/page/SystemMng" element={<SystemMng />} />
          <Route path="/page/UserDelLog" element={<UserDelLog />} />
          <Route path="/page/SYS0203V01" element={<SYS0203V01 />} />
          <Route path="/page/SYS0204M01" element={<SYS0204M01 />} />
          <Route path="/page/SYS0206M01" element={<SYS0206M01 />} />
          <Route path="/page/SYS0209V01" element={<SYS0209V01 />} />
          <Route path="/page/FDS0101V01" element={<FDS0101V01 />} />
          <Route path="/page/FDS0102M01" element={<FDS0102M01 />} />
          <Route path="/page/FDS0103V01" element={<FDS0103V01 />} />
          <Route path="/page/RPT0401M01" element={<RPT0401M01 />} />
          <Route path="/page/SYS0205M01" element={<SYS0205M01 />} />
          <Route path="/page/SYS0208V01" element={<SYS0208V01 />} />
          <Route path="/page/SYS0207M01" element={<SYS0207M01 />} />
          <Route path="/page/SYS0201M01" element={<SYS0201M01 />} />
          <Route path="/page/ILS0301V01" element={<ILS0301V01 user={user} />} />
          <Route path="/page/MenuMng" element={<MenuMng />} />
          <Route path="/page/AXP0101V04" element={<AXP0101V04 />} />
          <Route path="/page/FDS0104V01" element={<FDS0104V01 />} /> */}

          <Route path="*" element={<Error404 />} />
        </Route>
      </Routes>
    </Layout>
  );
}

export default App;
