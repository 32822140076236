import { SUCCESS, REQUEST, FAILURE } from '../../../common/utils/action-type-utils';
import { fetchEtcPmnyActSumtsList } from '../../../lib/etc/etc0202v01';
import dayjs from 'dayjs';
import { etc0202v01actNoComboList } from '../../../data/const';
import { fetchCommonComboList } from '../../../lib/cmm/combo.api';

// actions
const FETCH_PMNYACTSUMTS = 'etcPmnyActSumts/FETCH_PMNYACTSUMTS';
const SAVE_SEARCH_FORM_VALUES = 'etcPmnyActSumts/SAVE_SEARCH_FORM_VALUES';
const RESET_STATE = 'etcPmnyActSumts/RESET_STATE';
//회계연도
const FETCH_ACC_YR_LIST = 'zpbApvPrts/FETCH_ACC_YR_LIST';

export const initFormValues = {
  cus_ist_cd: '000', // 기관명
  act_no: etc0202v01actNoComboList[0].id, // 세출계좌
  ydcs8a1_t8a01_acc_yr: dayjs().format('YYYY'), // 회계연도
  sdt: dayjs().format('YYYY-MM-DD'), // 조회기간
  edt: dayjs().format('YYYY-MM-DD'), // 조회기간
};

// initial state
const initialState = {
  loading: false,
  success: false,
  message: '',
  list: [],
  totalCount: 0,
  accYrSuccess: false,
  accYrLoading: false,
  accYrList: [],
  etc0202v01actNoComboList: etc0202v01actNoComboList,
  searchFormValues: {
    init: true,
    ...initFormValues,
  },
};

// reducers
export default function etcPmnyActSumts(state = initialState, action) {
  switch (action.type) {
    case SUCCESS(FETCH_PMNYACTSUMTS): // 조회 성공
      return {
        ...state,
        success: true,
        loading: false,
        list: action.payload.data?.output_data,
        totalCount: action.payload?.output_data_cnt,
      };
    case REQUEST(FETCH_PMNYACTSUMTS): // 조회 요청
      return {
        ...state,
        loading: true,
        success: false,
      };
    case FAILURE(FETCH_PMNYACTSUMTS): // 조회실패
      return {
        ...initialState,
        loading: false,
        success: false,
        message: action.payload.response?.data?.message,
      };
    // 회계연도
    case SUCCESS(FETCH_ACC_YR_LIST): // 조회 성공
      return {
        ...state,
        accYrSuccess: true,
        accYrLoading: false,
        accYrList: action.payload.data?.subDto,
      };
    case REQUEST(FETCH_ACC_YR_LIST): // 조회 요청
      return {
        ...state,
        accYrLoading: true,
        accYrSuccess: false,
      };
    case FAILURE(FETCH_ACC_YR_LIST): // 조회실패
      return {
        ...state,
        accYrLoading: false,
        accYrSuccess: false,
        accYrList: [],
      };
    case SAVE_SEARCH_FORM_VALUES:
      return {
        ...state,
        searchFormValues: action.payload,
      };
    case RESET_STATE:
      return initialState;
    default:
      return state;
  }
}

/**
 * 기타 - 공금계좌 집계조회
 */
export const selectEtcPmnyActSumtsList = (searchParams) => {
  return {
    type: FETCH_PMNYACTSUMTS,
    payload: fetchEtcPmnyActSumtsList(searchParams),
  };
};

/**
 * 회계연도 콤보 조회
 */
export const selectAccYrComboList = (searchParams) => {
  return {
    type: FETCH_ACC_YR_LIST,
    payload: fetchCommonComboList({ slt_box_no: '133', ...searchParams }),
  };
};

/**
 * 조회조건 저장
 */
export const saveSearchFormValues = (searchFormValues) => {
  return {
    type: SAVE_SEARCH_FORM_VALUES,
    payload: {
      ...searchFormValues,
      init: false,
    },
  };
};

/**
 * 광명시금고 공금계좌 집계조회
 */
export const resetEtcPmnyActSumts = () => {
  return {
    type: RESET_STATE,
  };
};
