import dayjs from 'dayjs';
import { SUCCESS, REQUEST, FAILURE } from '../../../common/utils/action-type-utils';
import { fetchCommonComboList } from '../../../lib/cmm/combo.api';
import { fetchOwprDlrpMnrrList } from '../../../lib/rpt/owprDlrpMnrr.api';

// actions
const FETCH_OWPR_DLRP_MNRR_LIST = 'owprDlrpMnrr/FETCH_OWPR_DLRP_MNRR_LIST';

const FETCH_IST_INFO_LIST = 'owprDlrpMnrr/FETCH_IST_INFO_LIST';
const FETCH_ACCT_NO_LIST = 'owprDlrpMnrr/FETCH_ACCT_NO_LIST';
const FETCH_IST_NO_LIST = 'owprDlrpMnrr/FETCH_IST_NO_LIST';
const FETCH_ACT_NO_LIST = 'owprDlrpMnrr/FETCH_ACT_NO_LIST';
const SAVE_SEARCH_FORM_VALUES = 'owprDlrpMnrr/SAVE_SEARCH_FORM_VALUES';
const RESET_STATE = 'owprDlrpMnrr/RESET_STATE';

export const initFormValues = {
  cus_ist_cd: '',
  ydcs8a1_t8a02_ist_no: '',
  ydcs8a1_t8a02_acc_yr: '',
  ydcs8a1_t8a02_dt_mn_dis: '1',
  ydcs8a1_t8a02_inq_mn: '',
  ydcs8a1_t8a02_inq_dt: dayjs().format('YYYY-MM-DD'),
  ydcs8a1_t8a02_inq_bas_cd: '1',
  ydcs8a1_t8a02_acc_dscd: '',
  ydcs8a1_t8a02_act_no: '',
};

// initial state
const initialState = {
  loading: false,
  success: true,
  message: '',
  list: [],
  totalCount: 0,
  common: {},

  // 콤보 - 기관명
  istInfoSuccess: false,
  istInfoLoading: false,
  istInfoList: [],
  // 콤보 - 회계번호목록
  acctNoSuccess: false,
  acctNoLoading: false,
  acctNoList: [],
  // 콤보 - 기관조회
  istNoSuccess: false,
  istNoLoading: false,
  istNoList: [],
  // 콤보 - 세입계좌
  actNoSuccess: false,
  actNoLoading: false,
  actNoList: [],
  // 검색조건
  searchFormValues: {
    init: true,
    ...initFormValues,
  },
};

// reducers
export default function owprDlrpMnrr(state = initialState, action) {
  switch (action.type) {
    case SUCCESS(FETCH_OWPR_DLRP_MNRR_LIST): // 조회 성공
      return {
        ...state,
        success: true,
        loading: false,
        list: action.payload.data?.output_data,
        totalCount: action.payload?.data?.output_data_cnt,
        common: action.payload.data?.common,
      };
    case REQUEST(FETCH_OWPR_DLRP_MNRR_LIST): // 조회 요청
      return {
        ...state,
        loading: true,
        success: false,
      };
    case FAILURE(FETCH_OWPR_DLRP_MNRR_LIST): // 조회실패
      return {
        ...state,
        loading: false,
        success: false,
        message: action.payload.response?.data?.message,
      };

    // 기관명
    case SUCCESS(FETCH_IST_INFO_LIST): // 조회 성공
      return {
        ...state,
        istInfoSuccess: true,
        istInfoLoading: false,
        istInfoList: action.payload.data?.subDto,
      };
    case REQUEST(FETCH_IST_INFO_LIST): // 조회 요청
      return {
        ...state,
        istInfoLoading: true,
        istInfoSuccess: false,
      };
    case FAILURE(FETCH_IST_INFO_LIST): // 조회실패
      return {
        istInfoLoading: false,
        istInfoSuccess: false,
        istInfoList: [],
      };
    // 회계번호목록
    case SUCCESS(FETCH_ACCT_NO_LIST): // 조회 성공
      return {
        ...state,
        acctNoSuccess: true,
        acctNoLoading: false,
        acctNoList: action.payload.data?.subDto,
      };
    case REQUEST(FETCH_ACCT_NO_LIST): // 조회 요청
      return {
        ...state,
        acctNoLoading: true,
        acctNoSuccess: false,
      };
    case FAILURE(FETCH_ACCT_NO_LIST): // 조회실패
      return {
        ...state,
        acctNoSuccess: false,
        acctNoLoading: false,
        acctNoList: [],
      };
    // 기관조회
    case SUCCESS(FETCH_IST_NO_LIST): // 조회 성공
      return {
        ...state,
        istNoSuccess: true,
        istNoLoading: false,
        istNoList: action.payload.data?.subDto,
      };
    case REQUEST(FETCH_IST_NO_LIST): // 조회 요청
      return {
        ...state,
        istNoLoading: true,
        istNoSuccess: false,
      };
    case FAILURE(FETCH_IST_NO_LIST): // 조회실패
      return {
        ...state,
        istNoLoading: false,
        istNoSuccess: false,
        istNoList: [],
      };
    // 세입계좌
    case SUCCESS(FETCH_ACT_NO_LIST): // 조회 성공
      return {
        ...state,
        actNoSuccess: true,
        actNoLoading: false,
        actNoList: action.payload.data?.subDto,
      };
    case REQUEST(FETCH_ACT_NO_LIST): // 조회 요청
      return {
        ...state,
        actNoLoading: true,
        actNoSuccess: false,
      };
    case FAILURE(FETCH_ACT_NO_LIST): // 조회실패
      return {
        ...state,
        actNoLoading: false,
        actNoSuccess: false,
        actNoList: [],
      };
    case SAVE_SEARCH_FORM_VALUES:
      return {
        ...state,
        // 기관명
        istInfoSuccess: false,
        istInfoLoading: false,
        // 회계번호목록
        acctNoSuccess: false,
        acctNoLoading: false,
        // 기관조회
        istNoSuccess: false,
        istNoLoading: false,
        // 세입계좌
        actNoSuccess: false,
        actNoLoading: false,
        searchFormValues: action.payload,
      };
    case RESET_STATE:
      return initialState;
    default:
      return state;
  }
}

/**
 * 세입보고서 - 과오납환급 일보월보 조회
 */
export const selectOwprDlrpMnrrList = (searchParams) => {
  return {
    type: FETCH_OWPR_DLRP_MNRR_LIST,
    payload: fetchOwprDlrpMnrrList(searchParams),
  };
};

/**
 * 기관명 콤보 조회
 */
export const selectIstInfoComboList = (searchParams) => {
  return {
    type: FETCH_IST_INFO_LIST,
    payload: fetchCommonComboList({ slt_box_no: '005', ...searchParams }),
  };
};

/**
 * 회계번호목록 콤보 조회
 */
export const selectAcctNoComboList = (searchParams) => {
  return {
    type: FETCH_ACCT_NO_LIST,
    payload: fetchCommonComboList({ slt_box_no: '016', ...searchParams }),
  };
};

/**
 * 기관 콤보 조회
 */
export const selectIstNoComboList = (searchParams) => {
  return {
    type: FETCH_IST_NO_LIST,
    payload: fetchCommonComboList({ slt_box_no: '023', ...searchParams }),
  };
};

/**
 * 세입계좌 콤보 조회
 */
export const selectActNoComboList = (searchParams) => {
  return {
    type: FETCH_ACT_NO_LIST,
    payload: fetchCommonComboList({ slt_box_no: '920', ...searchParams }),
  };
};

/**
 * 조회조건 저장
 */
export const saveSearchFormValues = (searchFormValues) => {
  return {
    type: SAVE_SEARCH_FORM_VALUES,
    payload: {
      ...searchFormValues,
      init: false,
    },
  };
};

/**
 * 상태초기화
 */
export const resetState = () => {
  return {
    type: RESET_STATE,
  };
};
