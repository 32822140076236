import { fetchPapsCntlCnfCrypt } from '../../../lib/sys/SYS0108M01.api';
import { SUCCESS, REQUEST, FAILURE } from '../../../common/utils/action-type-utils';

// actions
const FETCH_PAPES_CNTL_CNF_CRYPT = 'SYS0108M01/FETCH_PAPES_CNTL_CNF_CRYPT';

// initial state
const initialState = {
  loading: false,
  success: true,
  message: '',
  hexDecData: '',
};

// reducers
export default function SYS0108M01(state = initialState, action) {
  switch (action.type) {
    case SUCCESS(FETCH_PAPES_CNTL_CNF_CRYPT): // 조회 성공
      return {
        ...state,
        success: true,
        loading: false,
        hexDecData: action.payload.data?.hexDecData,
      };
    case REQUEST(FETCH_PAPES_CNTL_CNF_CRYPT): // 조회 요청
      return {
        ...state,
        loading: true,
      };
    case FAILURE(FETCH_PAPES_CNTL_CNF_CRYPT): // 조회실패
      return {
        ...initialState,
        loading: false,
        success: false,
        message: action.payload.response?.data?.message,
      };
    default:
      return { ...state };
  }
}

/**
 * 복호화 결과 조회
 */
export const selectPapsCntlCnfCrypt = (searchParams) => {
  return {
    type: FETCH_PAPES_CNTL_CNF_CRYPT,
    payload: fetchPapsCntlCnfCrypt(searchParams),
  };
};
