import { login, loginForAdmin, logout } from '../../../lib/cmm/login.api';
import { SUCCESS, REQUEST, FAILURE } from '../../../common/utils/action-type-utils';
import { setSelectedWorkList } from './work.reducer';
import { resetMenuList } from './menu.reducer';

// actions
const REQUEST_LOGIN = 'login/REQUEST_LOGIN';
const REQUEST_LOGOUT = 'login/REQUEST_LOGOUT';
const REQUEST_SYS_LOGOUT = 'login/REQUEST_SYS_LOGOUT';
const GET_SESSION = 'login/GET_SESSION';
const RESET_STATE = 'login/RESET_STATE';

// initial state
const initialState = {
  hpNo: '',
  loading: false,
  success: false,
  successLogout: false,
  message: '',
  messageId: '',
  menuList: [],
  cmSession: [],
  login_output_param: {},
};

// reducers
export default function loginState(state = initialState, action) {
  switch (action.type) {
    case SUCCESS(REQUEST_LOGIN): // 로그인 성공
      return {
        ...state,
        success: action.payload.data.errCode ? false : true,
        message: action.payload.data.errMsg,
        messageId: action.payload.data.errCode,
        loading: false,
        hpNo: action.payload?.data?.hpNo,
        cmSession: action.payload?.data?.g_cmsession,
        menuList: action.payload?.data?.g_menu_all,
        sessionId: action.payload?.data?.sessionId,
        login_output_param: action.payload?.data?.login_output_param,
      };
    case REQUEST(REQUEST_LOGIN): // 로그인 요청
      return {
        ...state,
        success: false,
        loading: true,
        cmSession: [],
        menuList: [],
        message: '',
      };
    case FAILURE(REQUEST_LOGIN): // 로그인 실패
      return {
        ...initialState,
        loading: false,
        success: false,
        cmSession: [],
        menuList: [],
        message: action.payload.response?.data?.message,
        messageId: action.payload.response?.data?.messageId,
      };
    case SUCCESS(REQUEST_LOGOUT): // 로그아웃 성공
      localStorage.removeItem('user_info');
      localStorage.removeItem('menu_list');
      return {
        ...initialState,
        successLogout: true,
        loading: false,
      };
    case SUCCESS(REQUEST_SYS_LOGOUT): // 회원관리 - 로그아웃 성공
      return {
        ...initialState,
        successLogout: true,
        loading: false,
      };
    case REQUEST(REQUEST_LOGOUT): // 로그아웃 요청
    case REQUEST(REQUEST_SYS_LOGOUT): // 회원관리 - 로그아웃 요청
      return {
        ...state,
        successLogout: false,
        loading: true,
      };
    case FAILURE(REQUEST_LOGOUT): // 로그아웃 실패
    case FAILURE(REQUEST_SYS_LOGOUT): // 회원관리 - 로그아웃 실패
      return {
        ...initialState,
        loading: false,
        successLogout: false,
        message: action.payload.response?.data?.message,
      };
    case GET_SESSION: // 세션 조회
      const userInfo = JSON.parse(localStorage.getItem('user_info')) ?? {};
      return {
        ...state,
        cmSession: [userInfo],
      };
    case RESET_STATE:
      return initialState;
    default:
      return state;
  }
}

/**
 * Login 요청
 */
export const sendLogin = (userInfo) => {
  return {
    type: REQUEST_LOGIN,
    payload: login(userInfo),
  };
};

/**
 * Login 요청
 */
export const sendLoginForAdmin = (userInfo) => {
  return {
    type: REQUEST_LOGIN,
    payload: loginForAdmin(userInfo),
  };
};

/**
 * Logout 요청
 */
export const sendLogout = () => async (dispatch) => {
  dispatch(setSelectedWorkList([]));
  dispatch(resetMenuList());
  return dispatch({
    type: REQUEST_LOGOUT,
    payload: logout(),
  });
};

/**
 * 회원관리 - Logout 요청
 */
export const sendSysLogout = () => async (dispatch) => {
  dispatch(setSelectedWorkList([]));
  return dispatch({
    type: REQUEST_SYS_LOGOUT,
    payload: logout(),
  });
};

export const getSession = () => {
  return {
    type: GET_SESSION,
  };
};

export const resetState = () => {
  return {
    type: RESET_STATE,
  };
};
