import { Autocomplete, FormControl, MenuItem, Select, TextField } from '@mui/material';
import React, { memo, useEffect, useMemo, useState } from 'react';
import styled, { css, createGlobalStyle } from 'styled-components';
import { inputStyle, labelStyle, inputReadonlyStyle } from '../../styles/common.styled';
import WOLabel from './WOLabel';
// import _ from 'lodash';

// Type type = 'filter'

const WOComboBox = ({
  label,
  labelColor,
  optionList,
  isAutoComplete,
  defaultValue,
  disabled,
  readOnly,
  isError,
  width,
  height,
  type,
  isHorizontal,
  onChange,
  placeholder,
  isPadding,
  disabledValue,
}) => {
  // logic
  // defaultValue: { id: 1, label: 'data1' }

  const initValue = useMemo(() => ({ id: '', label: placeholder ?? '선택' }), [placeholder]);
  const [value, setValue] = useState(initValue);
  const [inputValue, setInputValue] = useState('');

  const handleSelectChange = (event) => {
    const { value } = event.target;
    const selectedItem = optionList?.find((item) => item.id === value);
    setValue(selectedItem);
    onChange?.(value);
  };

  const handleAutoSelectChange = (_, newValue) => {
    if (!newValue) return;
    if (disabledValue) {
      if (newValue.id !== disabledValue) {
        setValue(newValue);
        onChange?.(newValue.id);
      }
    } else {
      setValue(newValue);
      onChange?.(newValue.id);
    }
  };
  const handleAutoSelectInputChange = (_, newInputValue) => {
    if (disabledValue) return;
    setInputValue(newInputValue);
  };

  useEffect(() => {
    if (optionList.length === 0) {
      setValue(initValue);
      setInputValue(initValue?.label);
    }
    if (optionList && optionList.length > 0) {
      const item = optionList.find((option) => option.id === defaultValue) || initValue;
      setValue(item);
      setInputValue(item?.label);
    }
  }, [defaultValue, isAutoComplete, optionList, onChange, initValue]);

  // useEffect(() => {
  //   debugger;
  //   console.log('optionList', optionList.length, _.uniqBy(optionList, 'id').length);
  // }, [optionList]);

  // view
  return (
    <>
      {isAutoComplete ? (
        <>
          <AutocompleteCustom
            id="combo-box"
            className={`select-wrap autocomplete ${readOnly ? 'readonly' : ''}`}
            width={width}
            $height={height}
            // options={_.uniqBy(optionList ?? [], 'label')}
            options={optionList ?? []}
            $isHorizontal={isHorizontal}
            $color={labelColor}
            renderInput={(params) => <TextField error={isError} {...params} label={label} placeholder={placeholder} />}
            // freeSolo
            autoHighlight
            value={value}
            inputValue={inputValue}
            disabled={disabled}
            readOnly={readOnly}
            $type={type}
            $isPadding={isPadding}
            filterOptions={(options, state) => {
              // 계죄번호, 사업자번호 하이픈 제거 조회기능 추가
              const { inputValue } = state;
              return options.filter(
                (option) => option.label?.replaceAll('-', '').includes(inputValue) || option.label?.includes(inputValue)
              );
            }}
            onChange={handleAutoSelectChange}
            onInputChange={handleAutoSelectInputChange}
            isOptionEqualToValue={(option, value) => {
              return option?.id === value?.id;
            }}
            // PopperComponent={WOComboPopper}
            // open={true}
          />
          <GlobalStyle />
        </>
      ) : (
        <SelectWrap width={width} className="select-wrap" $type={type} $isHorizontal={isHorizontal} $height={height}>
          <FormControl disabled={disabled}>
            {label && <WOLabel label={label} color={labelColor} />}
            <SelectCustom
              labelId={label}
              id="select"
              className={`select ${readOnly ? 'read-only' : ''}`}
              label={label}
              value={value?.id}
              inputProps={{ readOnly }}
              error={isError}
              onChange={handleSelectChange}
              // defaultOpen={true}
            >
              {optionList.map((option, index) => (
                <MenuItem key={index} value={option.id}>
                  {option.label}
                </MenuItem>
              ))}
            </SelectCustom>
          </FormControl>
        </SelectWrap>
      )}
    </>
  );
};

export default memo(WOComboBox);

const AutocompleteCustom = styled(Autocomplete)`
  width: ${(props) => `${props.width ? `${props.width}px` : '100%'}`};
  &.autocomplete {
    .MuiFormControl-root {
      display: flex;
      flex-direction: column;
      gap: 4px;
    }
    // label
    .MuiFormLabel-root {
      ${labelStyle}
    }

    // inputWrap
    .MuiInputBase-root {
      ${inputStyle}
      ${(props) =>
        props.$type === 'type01' &&
        css`
          .MuiOutlinedInput-input {
            color: var(--W-Dark-11);
            font-size: 14px;
            font-weight: 400;
            line-height: 21px;
          }
          ::placeholder {
            color: var(--C-Dark-06, #9ea6b2);
            opacity: 1;
          }
        `}
    }
    &.readonly {
      .MuiInputBase-root {
        ${inputReadonlyStyle}
        ${(props) =>
          props.$isPadding &&
          css`
            .MuiInputBase-input {
              padding-left: 6px;
            }
          `}
      }
    }
    ${(props) =>
      props.$type === 'filter' &&
      css`
        flex: 0 0 auto;
        .MuiInputBase-root {
          background: var(--White);
          height: 26px;
          .MuiInputBase-input {
            box-sizing: border-box;
            height: 26px;
            padding: 6px 26px 6px 6px;
            padding-right: 26px !important;
            font-size: 12px;
            font-weight: 400;
          }

          .MuiSelect-icon {
            width: 14px;
            height: 14px;
            right: 6px;
          }
        }
      `}
    ${(props) =>
      props.$type === 'type01' &&
      css`
        .MuiOutlinedInput-input {
          color: #9ea6b2;
          font-size: 14px;
          font-weight: 400;
          line-height: 21px;
        }
      `}
      ${(props) =>
      props.$type === 'main-search' &&
      css`
        width: 300px;
        margin-left: auto;
        .MuiInputBase-root {
          height: 36px;
          border-radius: 8px;
          background: #f5f6fa;
          .MuiInputBase-input {
            height: 36px;
            &::placeholder {
              color: #9ea6b2;
            }
          }
          .MuiAutocomplete-endAdornment {
            right: 12px;
            .MuiAutocomplete-popupIndicator {
              width: 16px;
              height: 16px;
              background: url(/assets/images/icon/search.svg) no-repeat center / cover;
              &.MuiAutocomplete-popupIndicatorOpen {
                transform: rotate(0);
              }
            }
          }
        }
      `}
  }

  .MuiOutlinedInput-notchedOutline {
    display: none;
  }

  // Horzontal
  ${(props) =>
    props.$isHorizontal &&
    css`
      &.autocomplete {
        .MuiFormControl-root {
          flex-direction: row;
          gap: 2px;
        }
        .MuiFormLabel-root {
          min-width: 60px;
          width: 60px;
        }
      }
    `}
`;

const SelectWrap = styled.div`
  width: ${(props) => `${props.width ? `${props.width}px` : '100%'}`};

  display: flex;
  flex-direction: column;

  .MuiFormControl-root {
    width: 100%;
    gap: 4px;
  }

  ${(props) =>
    props.$type === 'filter' &&
    css`
      width: fit-content;
      height: fit-content;
      .select.MuiInputBase-root {
        background: var(--White);
        height: 26px;
        .MuiInputBase-input {
          display: flex;
          align-items: center;
          box-sizing: border-box;
          height: 26px;
          padding: 6px 32px 6px 6px;
          padding-right: 32px !important;
        }
        .MuiSelect-icon {
          width: 14px;
          height: 14px;
          right: 6px;
        }
      }
    `}

  // Horzontal
  ${(props) =>
    props.$isHorizontal &&
    css`
      .MuiFormControl-root {
        flex-direction: row;
        gap: 2px;
      }
      .MuiFormLabel-root {
        min-width: 60px;
        width: 60px;
      }
    `}
`;

const SelectCustom = styled(Select)`
  // inputWrap

  &.MuiInputBase-root {
    ${inputStyle}
    .MuiSelect-select {
      cursor: default;
    }
    &.read-only {
      .MuiSvgIcon-root {
        display: none;
      }
    }
  }

  .MuiOutlinedInput-notchedOutline {
    display: none;
  }
`;

const GlobalStyle = createGlobalStyle`
  .MuiDialog-root[role="presentation"]{
    z-index: 1290;
    & ~ .MuiPopper-root {
      z-index: 1300;
    }
  }

`;
