import instance from '../../common/config/api';

/**
 * 금고영업점 - 문서조회
 * @param {object} params
 * @returns
 */

//문서조회 화면
export const fetchDcmtList = (params) =>
  instance.post(`${process.env.REACT_APP_SERVER_URL}/scq/scq/brc/0003/selectDcmtList`, params);

//문서등록 팝업
export const fetchPapsMkPkNmgt = (params) =>
  instance.post(`${process.env.REACT_APP_SERVER_URL}/scq/scq/brc/0003/selectPapsMkPkNmgt`, params);

export const fetchPapsMkDeptNm = (params) =>
  instance.post(`${process.env.REACT_APP_SERVER_URL}/scq/scq/brc/0003/selectPapsMkDeptNm`, params);

export const fetchWbkOrgt = (params) =>
  instance.post(`${process.env.REACT_APP_SERVER_URL}/scq/scq/brc/0003/selectWbkOrgt`, params);

export const fetchSulOrgt = (params) =>
  instance.post(`${process.env.REACT_APP_SERVER_URL}/scq/scq/brc/0003/selectSulOrgt`, params);

export const insertPapsMk = (dtos) =>
  instance.post(`${process.env.REACT_APP_SERVER_URL}/scq/scq/brc/0003/insertPapsMk`, dtos);

export const insertPapsDpsDept = (dtos) =>
  instance.post(`${process.env.REACT_APP_SERVER_URL}/scq/scq/brc/0003/insertPapsDpsDept`, {
    subDto: dtos,
  });

//문서상세 팝업
export const fetchPapsDtl = (params) =>
  instance.post(`${process.env.REACT_APP_SERVER_URL}/scq/scq/brc/0003/selectPapsDtl`, params);

export const updateDpsPapsCnf = (dto) =>
  instance.post(`${process.env.REACT_APP_SERVER_URL}/scq/scq/brc/0003/saveDpsPapsCnf`, dto);

export const fetchPapsDpsDeptDtl = (params) =>
  instance.post(`${process.env.REACT_APP_SERVER_URL}/scq/scq/brc/0003/selectPapsDpsDeptDtl`, params);
