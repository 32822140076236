import React, { useCallback, useEffect, useState } from 'react';
import styled, { css } from 'styled-components';
import { Link, useNavigate } from 'react-router-dom';
import WOMenuIconButton from '../common/WOMenuIconButton';
import useSession from '../../hooks/useSession';

const Lnb = ({ onChange, menuList = [] }) => {
  const [isOpen, setIsOpen] = useState(false);
  const [isIconHover, setIsIconHover] = useState(false);
  const [isMenuHover, setIsMenuHover] = useState(false);
  const [activeMenu, setActiveMenu] = useState(null);
  const navigate = useNavigate();
  const { cus_ist_cd } = useSession().getUserInfo();
  const [isOpenSubMenuList, setIsOpenSubMenuList] = useState([]);
  const initialIsOpenSubMenu = (data) => data.map((item) => ({ id: item.id, isOpen: false }));

  const [subMenuList, setSubMenuList] = useState([]);

  const toggleIsOpen = useCallback(
    (isLnbOpen) => {
      setIsOpen(isLnbOpen);
      onChange && onChange(isLnbOpen);
    },
    [onChange]
  );

  const handleHover = useCallback(
    (isIconHover, menu) => {
      setActiveMenu({ ...menu, id: menu.mnu_seq_no });
      setIsIconHover(isIconHover);
      isIconHover && toggleIsOpen(true);
    },
    [toggleIsOpen]
  );

  const handleSubMenuListOpen = (subMenu) => {
    setIsOpenSubMenuList((prev) =>
      prev.map((item) => (item.id === subMenu.id ? { ...item, isOpen: !item.isOpen } : item))
    );
  };

  const handleLogoError = useCallback((e) => {
    const target = e.target;
    target.src = '/assets/images/logo-woori-bank.svg';
  }, []);

  useEffect(() => {
    toggleIsOpen(!isIconHover && !isMenuHover ? false : true);
  }, [isIconHover, isMenuHover, toggleIsOpen]);

  useEffect(() => {
    if (menuList.length > 0) {
      setSubMenuList(
        menuList
          .filter((menu) => menu.mnu_lvl === 1)
          .filter((menu) => menu.mnu_aut_cd !== 'X')
          .map((menu) => ({
            id: menu.mnu_seq_no,
            parentId: menu.higrk_mnu_seq_no,
            parentCd: menu.sys_cd,
            title: menu.mnu_nm,
            scrn_url: menu.scrn_url,
            children: menuList
              .filter((item) => item.higrk_mnu_seq_no === menu.mnu_seq_no)
              .map((lv2, _, lv2List) => {
                let mnu_aut_cd = '';
                if (lv2.frst_tab?.length > 0) {
                  if (lv2.pgm_pth.includes('/' + lv2.frst_tab)) {
                    mnu_aut_cd = lv2.mnu_aut_cd;
                  } else {
                    mnu_aut_cd = lv2List.find((item) => item.pgm_pth.includes('/' + lv2.frst_tab))?.mnu_aut_cd ?? '';
                  }
                } else {
                  mnu_aut_cd = lv2.mnu_aut_cd;
                }
                return {
                  mnu_aut_cd,
                  id: lv2.mnu_seq_no,
                  title: lv2.mnu_nm,
                  path: lv2.scrn_url,
                };
              })
              .filter((item) => item.mnu_aut_cd !== 'X'),
          }))
      );
    }
  }, [menuList]);

  useEffect(() => {
    setIsOpenSubMenuList(initialIsOpenSubMenu(subMenuList));
  }, [subMenuList]);

  // useEffect(() => {
  //   console.log('menuList test', menuList);
  // }, [menuList]);

  // useEffect(() => {
  //   return () => {
  //     // setSubMenuList([]);
  //   };
  // }, []);

  // view
  return (
    <Wrap className="lnb-wrap">
      {/* START: 메뉴바 */}
      <MenuWrap className="lnb">
        <Logo
          src={cus_ist_cd ? `/assets/images/icon/logo/logo-${cus_ist_cd}.svg` : '/assets/images/logo-woori-bank.svg'}
          alt=""
          onClick={() => {
            window.location.href = '/';
          }}
          $user={cus_ist_cd}
          onError={handleLogoError}
        />
        <IConGroup>
          {menuList
            // .filter((menu) => menu.use_yn === '1')
            .filter((menu) => menu.mnu_lvl === 0)
            .filter((menu) => menu.mnu_aut_cd !== 'X')
            // 하위메뉴 없으면 표시 안되도록
            .filter(
              (menu) =>
                menuList
                  .filter((c) => c.sys_seq_no === menu.mnu_seq_no)
                  .filter((c) => c.scrn_url)
                  .filter((c) => c.mnu_aut_cd !== 'X').length > 0
            )
            .map((menu) => ({ ...menu, id: menu.sys_seq_no, imageName: menu.icon_pth_nm, title: menu.mnu_nm }))
            .sort((prev, next) => prev.sort_seq_no - next.sort_seq_no)
            .map((menu) => {
              // 시스템 관리
              if (menu.title === '시스템관리') {
                return (
                  <SystemButton
                    key={menu.id}
                    onMouseOver={() => handleHover(true, menu)}
                    onMouseOut={() => handleHover(false, menu)}>
                    {menu.title}
                  </SystemButton>
                );
              } else {
                // 나머지 항목들
                return (
                  <WOMenuIconButton
                    key={menu.id}
                    imageFile={`/assets/images/menu/${menu.imageName}`}
                    text={menu.title}
                    activeMenu={activeMenu}
                    isMenuOpen={isOpen}
                    onHover={(isIconHover) => handleHover(isIconHover, menu)}
                  />
                );
              }
            })}
        </IConGroup>
      </MenuWrap>
      {/* END: 메뉴바 */}
      {/* START: 메뉴 클릭시 보여질 콘텐츠 */}
      <MenuContentWrap
        className="lnb-menu"
        $isOpen={isOpen}
        onMouseOver={() => setIsMenuHover(true)}
        onMouseOut={() => setIsMenuHover(false)}>
        {activeMenu && (
          <MenuContentInnerWrap>
            <MenuContentHeader>
              <span className="title">{`# ${activeMenu.title}`}</span>
              <span className="title city"></span>
            </MenuContentHeader>
            <nav>
              {subMenuList
                .filter((subMenu) => activeMenu.id === subMenu.parentId)
                .map((subMenu, index) => (
                  <SubMenuList key={`subMenu-${index}`}>
                    {(subMenu?.children?.length > 0 || subMenu.scrn_url) && (
                      <li
                        onClick={() => {
                          if (subMenu?.children?.length === 0 && subMenu.scrn_url) {
                            setIsOpen(false);
                            navigate(subMenu.scrn_url, { state: { init: true } });
                          }
                        }}>
                        <button
                          type="button"
                          className="content-button"
                          onClick={() => {
                            handleSubMenuListOpen(subMenu);
                          }}>
                          {subMenu.title}
                        </button>
                        <div>
                          <SubMenuTwoDepthList
                            $isOpen={isOpenSubMenuList.find((item) => item.id === subMenu.id)?.isOpen}
                            $itemLength={subMenu?.children?.length}>
                            {subMenu?.children.map((item) => (
                              <li key={item.id}>
                                <Link
                                  to={item.path}
                                  state={{ init: true }}
                                  onClick={() => setIsOpen(false)}
                                  className="content-inner-button">
                                  {item.title}
                                </Link>
                              </li>
                            ))}
                          </SubMenuTwoDepthList>
                        </div>
                      </li>
                    )}
                  </SubMenuList>
                ))}
            </nav>
          </MenuContentInnerWrap>
        )}
      </MenuContentWrap>
      {/* END: 메뉴 클릭시 보여질 콘텐츠 */}
      <i className="back-drop" />
    </Wrap>
  );
};

export default Lnb;

const itemHeight = 38;

const Wrap = styled.div`
  width: 80px;
  height: 100%;
  display: flex;
  position: fixed;
  left: 0;
  top: 0;
  z-index: 1000;
`;

// menu
const MenuWrap = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
  min-width: 80px;
  height: 100%;

  padding-top: 22px;
  background: linear-gradient(0deg, rgba(0, 0, 0, 0.1) 0%, rgba(0, 0, 0, 0.1) 100%),
    linear-gradient(6deg, #8fb7ea -0.26%, #8e99ff 89.59%);
`;

const Logo = styled.img`
  flex: 0 0 auto;
  display: block;
  width: 36px;
  height: 36px;
  cursor: pointer;
  ${(props) =>
    props.$user
      ? ''
      : `
  width:42px;
  height:39px;
`}
  margin: 0 auto 20px;
   {
    /*
  background: url(/assets/images/logo-woori-bank.svg) no-repeat center / cover;
  ${(props) =>
      props.src
        ? `background: url(${props.src}) no-repeat center / cover; `
        : `background: url(/assets/images/logo-woori-bank.svg) no-repeat center / cover;`};
    */
  }
`;

const IConGroup = styled.div`
  display: flex;
  flex-direction: column;
  height: 100%;
  padding: 23px 0 0px;
  overflow-y: auto;
  &::-webkit-scrollbar {
    width: 10px;
  }
`;

const SystemButton = styled.button`
  position: relative;
  width: 100%;
  margin-top: auto;
  padding: 10px 0;
  font-size: 13px;
  font-weight: 600;
  color: #fff;
  &:before {
    content: '';
    display: block;
    position: absolute;
    top: 0;
    left: 5px;
    right: 5px;
    height: 1px;
    opacity: 0.6;
    background: #fff;
  }
  &:hover {
    background: rgba(255, 255, 255, 0.3);
    &::before {
      display: none;
    }
  }
`;

// menu-contents
const MenuContentWrap = styled.div`
  position: absolute;
  top: 0;
  left: 80px;
  height: 100vh;
  overflow-x: hidden;
  background: linear-gradient(0deg, #3a3d64 0%, #1d2e4b 100%);
  transition: 0.5s;
  color: #fff;

  // 딤처리
  & + .back-drop {
    display: block;
    position: fixed;
    z-index: -1;
    top: 0;
    bottom: 0;
    left: 80px;
    right: 0;
    background-color: rgba(0, 0, 0, 0.5);
    transition: all 225ms cubic-bezier(0.4, 0, 0.2, 1) 0ms;
  }
  ${(props) =>
    props.$isOpen
      ? css`
          width: 294px;
          & + .back-drop {
            visibility: visible;
            opacity: 1;
          }
        `
      : css`
          width: 0;
          & + .back-drop {
            visibility: hidden;
            opacity: 0;
          }
        `};
`;

const MenuContentInnerWrap = styled.div`
  width: 294px;
`;

const MenuContentHeader = styled.div`
  padding: 40.5px 28px 66.5px;
  display: flex;
  align-items: center;
  .title {
    display: block;
    font-size: 18px;
    font-weight: 600;
  }
  .city {
    font-size: 20px;
    font-weight: 700;
    margin-left: auto;
  }
`;

// 1depth
const SubMenuList = styled.ul`
  padding: 0 28px;
  .content-button {
    color: #fff;
    text-align: left;
    display: block;
    padding: 10px 18px 10px 24px;
    width: 100%;
    background: url('/assets/images/lnb-right.svg') no-repeat 2px 14px/14px 16px;
  }
`;

// 2depth
const SubMenuTwoDepthList = styled.ul`
  overflow: hidden;
  /* transition: 0.5s; */
  height: auto;
  ${(props) => (props.$isOpen && props.$itemLength ? `height: auto; padding: 4px 0 12px;` : `height: 0; padding: 0;`)};
  li {
    &:first-of-type {
      border-top-right-radius: 12px;
      border-top-left-radius: 12px;
      overflow: hidden;
    }
    &:last-of-type {
      border-bottom-right-radius: 12px;
      border-bottom-left-radius: 12px;
      overflow: hidden;
    }
  }
  .content-inner-button {
    display: block;
    width: 100%;

    padding: 8px 24px;
    font-size: 14px;
    text-align: left;
    color: rgba(255, 255, 255, 0.6);
    background-color: rgba(0, 0, 0, 0.1);
  }
`;
