import { SUCCESS, REQUEST, FAILURE } from '../../../common/utils/action-type-utils';
import { fetchZpbExuPrtsList, saveZpbExuPrtsKkoTms, saveZpbExuPrtsSmsTms } from '../../../lib/zpb/zpbExuPrts.api';
import dayjs from 'dayjs';
import { fetchCommonComboList } from '../../../lib/cmm/combo.api';

// actions
const FETCH_EXU_PRTS_LIST = 'zpbExuPrts/FETCH_EXU_PRTS_LIST';
const INSERT_EXU_PRTS_KKOTMS = 'zpbExuPrts/INSERT_EXU_PRTS_KKOTMS';
const INSERT_EXU_PRTS_SMSTMS = 'zpbExuPrts/INSERT_EXU_PRTS_SMSTMS';
const RESET_STATE = 'zpbApvPrts/RESET_STATE';
const SAVE_SEARCH_FORM_VALUES = 'zpbExuPrts/SAVE_SEARCH_FORM_VALUES';

//기관
const FETCH_IST_INFO_LIST = 'zpbExuPrts/FETCH_IST_INFO_LIST';
//부서
const FETCH_DEPT_CD_LIST = 'zpbExuPrts/FETCH_DEPT_CD_LIST';
//사업자번호
const FETCH_BIZ_NO_LIST = 'zpbExuPrts/FETCH_BIZ_NO_LIST';
//회계연도
const FETCH_ACC_YR_LIST = 'zpbExuPrts/FETCH_ACC_YR_LIST';
//계좌번호
const FETCH_ACT_NO_LIST = 'zpbExuPrts/FETCH_ACT_NO_LIST';

export const initFormValues = {
  /*지자체*/
  cus_ist_cd: '', //기관명
  cus_dept_cd: 'all', //부서명
  biz_no: 'all', //사업자번호
  ydcs8a1_t8a01_acc_yr: dayjs().format('YYYY'), //회계연도
  ydcs8a1_t8a01_act_no: 'all', //계좌번호
  ydcs8a1_t8a01_inq_sdt: dayjs().format('YYYY-MM-DD'), //승인기간
  ydcs8a1_t8a01_inq_edt: dayjs().format('YYYY-MM-DD'), //승인기간
  apv_dis: 'all', //승인구분
  gb2: '1', // 일자구분
};

// initial state
const initialState = {
  loading: false,
  success: false,
  message: '',
  list: [],
  totalCount: 0,
  // 카카오, sms전송
  updateLoading: false,
  updateSuccess: false,
  resultCnt: -1,
  resultCnt2: -1,
  //콤보 - 기관명
  istInfoList: [],
  istInfoLoading: false,
  istInfoSuccess: true,
  // 부서명
  deptCdSuccess: false,
  deptCdLoading: false,
  deptCdList: [],
  // 사업자번호
  bizNoSuccess: false,
  bizNoLoading: false,
  bizNoList: [],
  // 회계연도
  accYrLoading: false,
  accYrList: [],
  accYrSuccess: false,
  //계좌번호
  actNoLoading: false,
  actNoList: [],
  actNoSuccess: false,
  searchFormValues: {
    init: true,
    ...initFormValues,
  },
};

// reducers
export default function zpbExuPrts(state = initialState, action) {
  switch (action.type) {
    case SUCCESS(FETCH_EXU_PRTS_LIST): // 조회 성공
      return {
        ...state,
        success: true,
        loading: false,
        list: action.payload.data?.subDto,
        totalCount: action.payload?.subDtoCnt,
      };
    case REQUEST(FETCH_EXU_PRTS_LIST): // 조회 요청
      return {
        ...state,
        // list: [],
        loading: true,
        success: false,
      };
    case FAILURE(FETCH_EXU_PRTS_LIST): // 조회실패
      return {
        ...state,
        loading: false,
        success: false,
        message: action.payload.response?.data?.message,
      };
    case SUCCESS(INSERT_EXU_PRTS_KKOTMS): // 전송 성공(카카오톡)
      return {
        ...state,
        updateSuccess: true,
        updateLoading: false,
        resultCnt: action.payload.data.resultCnt,
      };
    case REQUEST(INSERT_EXU_PRTS_KKOTMS): // 전송 요청(카카오톡)
      return {
        ...state,
        message: '',
        updateLoading: true,
        updateSuccess: false,
      };
    case FAILURE(INSERT_EXU_PRTS_KKOTMS): // 전송 실패(카카오톡)
      return {
        ...state,
        updateLoading: false,
        updateSuccess: false,
        resultCnt: -1,
        message: action.payload.response?.data?.message,
      };
    case SUCCESS(INSERT_EXU_PRTS_SMSTMS): // 전송 성공(sms)
      return {
        ...state,
        updateSuccess: true,
        updateLoading: false,
        resultCnt: action.payload.data.resultCnt,
      };
    case REQUEST(INSERT_EXU_PRTS_SMSTMS): // 전송 요청(sms)
      return {
        ...state,
        message: '',
        updateLoading: true,
        updateSuccess: false,
      };
    case FAILURE(INSERT_EXU_PRTS_SMSTMS): // 전송 실패(sms)
      return {
        ...state,
        updateLoading: false,
        updateSuccess: false,
        resultCnt2: -1,
        message: action.payload.response?.data?.message,
      };
    case SUCCESS(FETCH_IST_INFO_LIST): // 조회 성공
      return {
        ...state,
        istInfoSuccess: true,
        istInfoLoading: false,
        istInfoList: action.payload.data?.subDto,
      };
    case REQUEST(FETCH_IST_INFO_LIST): // 조회 요청
      return {
        ...state,
        istInfoLoading: true,
        istInfoSuccess: false,
      };
    case FAILURE(FETCH_IST_INFO_LIST): // 조회실패
      return {
        istInfoLoading: false,
        istInfoSuccess: false,
        istInfoList: [],
      };
    // 부서명
    case SUCCESS(FETCH_DEPT_CD_LIST): // 조회 성공
      return {
        ...state,
        deptCdSuccess: true,
        deptCdLoading: false,
        deptCdList: action.payload.data?.subDto,
      };
    case REQUEST(FETCH_DEPT_CD_LIST): // 조회 요청
      return {
        ...state,
        deptCdLoading: true,
        deptCdSuccess: false,
      };
    case FAILURE(FETCH_DEPT_CD_LIST): // 조회실패
      return {
        ...state,
        deptCdLoading: false,
        deptCdSuccess: false,
        deptCdList: [],
      };
    // 사업자 번호
    case SUCCESS(FETCH_BIZ_NO_LIST): // 조회 성공
      return {
        ...state,
        bizNoSuccess: true,
        bizNoLoading: false,
        bizNoList: action.payload.data?.subDto,
      };
    case REQUEST(FETCH_BIZ_NO_LIST): // 조회 요청
      return {
        ...state,
        bizNoLoading: true,
        bizNoSuccess: false,
      };
    case FAILURE(FETCH_BIZ_NO_LIST): // 조회실패
      return {
        ...state,
        bizNoLoading: false,
        bizNoSuccess: false,
        bizNoList: [],
      };
    // 회계연도
    case SUCCESS(FETCH_ACC_YR_LIST): // 조회 성공
      return {
        ...state,
        accYrSuccess: true,
        accYrLoading: false,
        accYrList: action.payload.data?.subDto,
      };
    case REQUEST(FETCH_ACC_YR_LIST): // 조회 요청
      return {
        ...state,
        accYrLoading: true,
        accYrSuccess: false,
      };
    case FAILURE(FETCH_ACC_YR_LIST): // 조회실패
      return {
        ...state,
        accYrLoading: false,
        accYrSuccess: false,
        accYrList: [],
      };
    //계좌번호
    case SUCCESS(FETCH_ACT_NO_LIST): // 조회 성공
      return {
        ...state,
        actNoSuccess: true,
        actNoLoading: false,
        actNoList: action.payload.data?.subDto,
      };
    case REQUEST(FETCH_ACT_NO_LIST): // 조회 요청
      return {
        ...state,
        actNoLoading: true,
        actNoSuccess: false,
      };
    case FAILURE(FETCH_ACT_NO_LIST): // 조회실패
      return {
        ...state,
        actNoLoading: false,
        actNoSuccess: false,
        actNoList: [],
      };
    case SAVE_SEARCH_FORM_VALUES:
      return {
        ...state,
        // 콤보 - 기관
        istInfoSuccess: false,
        istInfoLoading: false,
        // 콤보 - 부서명
        deptCdSuccess: false,
        deptCdLoading: false,
        // 콤보 - 사업자 번호
        bizNoSuccess: false,
        bizNoLoading: false,
        // 콤보 - 계좌번호
        mbrNoSuccess: false,
        mbrNoLoading: false,
        // 콤보 -회계연도
        accYrSuccess: false,
        actNoLoading: false,
        searchFormValues: action.payload,
      };
    case RESET_STATE:
      return initialState;

    default:
      return state;
  }
}

/**
 * 제로페이 - 집행내역
 */
export const selectListExuPrts = (searchParams) => {
  return {
    type: FETCH_EXU_PRTS_LIST,
    payload: fetchZpbExuPrtsList(searchParams),
  };
};

/**
 * 제로페이 - 집행내역 (카카오톡전송)
 */
export const insertZpbExuPrtsKkoTms = (dtos) => {
  return {
    type: INSERT_EXU_PRTS_KKOTMS,
    payload: saveZpbExuPrtsKkoTms(dtos),
  };
};

/**
 * 제로페이 - 집행내역 (sms전송)
 */
export const insertZpbExuPrtsSmsTms = (dtos) => {
  return {
    type: INSERT_EXU_PRTS_KKOTMS,
    payload: saveZpbExuPrtsSmsTms(dtos),
  };
};
/**
 * 기관명 콤보 조회
 */
export const selectIstInfoComboList = (searchParams) => {
  return {
    type: FETCH_IST_INFO_LIST,
    payload: fetchCommonComboList({ slt_box_no: '005', ...searchParams }),
  };
};
/**
 * 부서명 콤보 조회
 */
export const selectDeptCdComboList = (searchParams) => {
  return {
    type: FETCH_DEPT_CD_LIST,
    payload: fetchCommonComboList({ slt_box_no: '199', ...searchParams }),
  };
};
/**
 * 사업자 번호 콤보 조회
 */
export const selectBizNoComboList = (searchParams) => {
  return {
    type: FETCH_BIZ_NO_LIST,
    payload: fetchCommonComboList({ slt_box_no: '044', ...searchParams }),
  };
};
/**
 * 회계연도 콤보 조회
 */
export const selectAccYrComboList = (searchParams) => {
  return {
    type: FETCH_ACC_YR_LIST,
    payload: fetchCommonComboList({ slt_box_no: '025', ...searchParams }),
  };
};
/**
 * 계좌번호 콤보 조회
 */
export const selectActNoComboList = (searchParams) => {
  return {
    type: FETCH_ACT_NO_LIST,
    payload: fetchCommonComboList({ slt_box_no: '196', ...searchParams }),
  };
};

/**
 * 조회조건 저장
 */
export const saveSearchFormValues = (searchFormValues) => {
  return {
    type: SAVE_SEARCH_FORM_VALUES,
    payload: {
      ...searchFormValues,
      init: false,
    },
  };
};

/**
 * 승인내역 상태초기화
 */
export const resetZpbExuPrts = () => {
  return {
    type: RESET_STATE,
  };
};
