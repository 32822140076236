import instance from '../../common/config/api';

/**
 * 연초보정 버튼 활성화여부
 */
export const fetchYearBjYn = () =>
  instance.post(`${process.env.REACT_APP_SERVER_URL}/scq/scq/rpt/0004/selectYearBjYn `);

/**
 * 보고서 관리대장에 보여질 기관 조회(콤보)
 * @param {object} params
 * @returns
 */
export const fetchRptMgtCb = (params) =>
  instance.post(`${process.env.REACT_APP_SERVER_URL}/scq/scq/rpt/0004/selectRptMgtCb  `, params);

/**
 * 보고서 상세 내역 조회
 * @param {object} params
 * @returns
 */
export const fetchApvMngDt = (params) =>
  instance.post(`${process.env.REACT_APP_SERVER_URL}/scq/scq/rpt/0004/selectApvMngDt   `, params);

/**
 * 연초보정
 * @returns
 */
export const updateYearBj = () => instance.post(`${process.env.REACT_APP_SERVER_URL}/scq/scq/rpt/0004/updateYearBj `);

/**
 * 일괄초기화
 * @param {object} params
 * @returns
 */
export const updateBatClear = (dtos) =>
  instance.post(`${process.env.REACT_APP_SERVER_URL}/scq/scq/rpt/0004/updateBatClear  `, {
    subDto: dtos,
    subDtoCnt: dtos.length,
  });

/**
 * 일괄승인
 * @param {object} params
 * @returns
 */
export const updateBatApn = (dtos) =>
  instance.post(`${process.env.REACT_APP_SERVER_URL}/scq/scq/rpt/0004/updateBatApn   `, {
    subDto: dtos,
    subDtoCnt: dtos.length,
  });

/**
 * 전자보고서 관리대장(팝업)
 * @param {object} params
 * @returns
 */
export const fetchRptMngRgr = (params) =>
  instance.post(`${process.env.REACT_APP_SERVER_URL}/scq/scq/rpt/0004/selectRptMngRgr`, params);

/**
 * 전자보고서 관리- 보고서 관리대장(페이지)
 * @param {object} params
 * @returns
 */
export const fetchRptMngRgrList = (params) =>
  instance.post(`${process.env.REACT_APP_SERVER_URL}/scq/scq/rpt/0004/selectrRptMngRgr `, params);

/**
 * 보고서 관리대장 상세 조회
 * @param {object} params
 * @returns
 */
export const fetchRptMngRgrSub = (params) =>
  instance.post(`${process.env.REACT_APP_SERVER_URL}/scq/scq/rpt/0004/selectrRptMngRgrSub`, params);

/**
 * 보고서별 계좌 맵핑내역 조회
 * @param {object} params
 * @returns
 */
export const fetchRptAcnoMapping = (params) =>
  instance.post(`${process.env.REACT_APP_SERVER_URL}/scq/scq/rpt/0004/selectRptAcnoMapping`, params);

/**
 * 보고서 관리대장 PK 조회
 * @returns
 */
export const fetchRptMngRgrPkNmgt = () =>
  instance.post(`${process.env.REACT_APP_SERVER_URL}/scq/scq/rpt/0004/selectRptMngRgrPkNmgt`);

/**
 * 보고서관리 전체 저장
 * @param {object} params
 * @returns
 */
export const updateRptMngRgr = (params) =>
  instance.post(`${process.env.REACT_APP_SERVER_URL}/scq/scq/rpt/0004/saveAllRptMngRgr`, params);
