import { SUCCESS, REQUEST, FAILURE } from '../../../common/utils/action-type-utils';
import { fetchZpbTrnFmItStlPtsList } from '../../../lib/zpb/zpb0107v01';
import dayjs from 'dayjs';
import { fetchCommonComboList } from '../../../lib/cmm/combo.api';

// actions
const FETCH_TRNFMITSTLPTS = 'zpbTrnFmItStlPts/FETCH_TRNFMITSTLPTS';
//기관
const FETCH_IST_INFO_LIST = 'zpbTrnFmItStlPts/FETCH_IST_INFO_LIST';
const RESET_STATE = 'zpbTrnFmItStlPts/RESET_STATE';
const SAVE_SEARCH_FORM_VALUES = 'zpbTrnFmItStlPts/SAVE_SEARCH_FORM_VALUES';
const FETCH_IST_INFO_LIST_005 = 'zpbTrnFmItStlPts/FETCH_IST_INFO_LIST_005';
export const initFormValues = {
  bznm: 'all', // 기관명
  st_date: dayjs().format('YYYY-MM-DD'), // 조회기간
  ed_date: dayjs().format('YYYY-MM-DD'), // 조회기간,
};

// initial state
const initialState = {
  loading: false,
  success: false,
  message: '',
  list: [],
  totalCount: 0,
  //콤보 - 기관명
  bznmList: [],
  bznmLoading: false,
  bznmSuccess: true,
  bznmList005: [],
  bznmLoading005: false,
  bznmSuccess005: true,
  zpbUpcheGbnCdComboList: [
    { id: '2', label: '법인(개인사업자 포함)' },
    { id: '3', label: '지자체(시청, 구청 등)' },
    { id: '4', label: '보조금사업자' },
    { id: '5', label: '공공기관' },
  ],
  searchFormValues: {
    init: true,
    ...initFormValues,
  },
};

// reducers
export default function zpbTrnFmItStlPts(state = initialState, action) {
  switch (action.type) {
    case SUCCESS(FETCH_TRNFMITSTLPTS): // 조회 성공
      return {
        ...state,
        success: true,
        loading: false,
        list: action.payload.data?.subDto,
        totalCount: action.payload?.subDtoCnt,
      };
    case REQUEST(FETCH_TRNFMITSTLPTS): // 조회 요청
      return {
        ...state,
        loading: true,
        success: false,
      };
    case FAILURE(FETCH_TRNFMITSTLPTS): // 조회실패
      return {
        ...initialState,
        loading: false,
        success: false,
        message: action.payload.response?.data?.message,
      };
    case SUCCESS(FETCH_IST_INFO_LIST): // 조회 성공
      return {
        ...state,
        bznmSuccess: true,
        bznmLoading: false,
        bznmList: action.payload.data?.subDto,
      };
    case REQUEST(FETCH_IST_INFO_LIST): // 조회 요청
      return {
        ...state,
        bznmLoading: true,
        bznmSuccess: false,
      };
    case FAILURE(FETCH_IST_INFO_LIST): // 조회실패
      return {
        bznmLoading: false,
        bznmSuccess: false,
        bznmList: [],
      };
    case SUCCESS(FETCH_IST_INFO_LIST_005): // 조회 성공
      return {
        ...state,
        bznmSuccess005: true,
        bznmLoading005: false,
        bznmList005: action.payload.data?.subDto,
      };
    case REQUEST(FETCH_IST_INFO_LIST_005): // 조회 요청
      return {
        ...state,
        bznmLoading005: true,
        bznmSuccess005: false,
      };
    case FAILURE(FETCH_IST_INFO_LIST_005): // 조회실패
      return {
        bznmLoading005: false,
        bznmSuccess005: false,
        bznmList005: [],
      };
    case SAVE_SEARCH_FORM_VALUES:
      return {
        ...state,
        // 콤보 - 기관
        bznmSuccess: false,
        bznmLoading: false,
        bznmSuccess005: false,
        bznmLoading005: false,
        // 콤보 - 부서명
        deptNmSuccess: false,
        deptNmLoading: false,
        searchFormValues: action.payload,
      };
    case RESET_STATE:
      return initialState;
    default:
      return state;
  }
}

/**
 * 제로페이 - 일자/결제방식별 거래량 조회
 */
export const selectTrnFmItStlPtsList = (searchParams) => {
  return {
    type: FETCH_TRNFMITSTLPTS,
    payload: fetchZpbTrnFmItStlPtsList(searchParams),
  };
};
/**
 * 기관명 콤보 조회
 */
export const selectIstInfoComboList = (searchParams) => {
  return {
    type: FETCH_IST_INFO_LIST,
    payload: fetchCommonComboList({ slt_box_no: '901', ...searchParams }),
  };
};

/**
 * 기관명 005콤보 조회
 */
export const selectIstInfoComboList005 = (searchParams) => {
  return {
    type: FETCH_IST_INFO_LIST_005,
    payload: fetchCommonComboList({ slt_box_no: '005', ...searchParams }),
  };
};
/**
 * 조회조건 저장
 */
export const saveSearchFormValues = (searchFormValues) => {
  return {
    type: SAVE_SEARCH_FORM_VALUES,
    payload: {
      ...searchFormValues,
      init: false,
    },
  };
};

/**
 * 초기화
 */
export const resetZpbTrnFmItStlPts = () => {
  return {
    type: RESET_STATE,
  };
};
