import instance from '../../common/config/api';

/**
 * 메뉴권한관리 사용자 조회
 * @param {object} params
 * @returns
 */
export const fetchUserList = (params) =>
  instance.post(`${process.env.REACT_APP_SERVER_URL}/scq/scq/sys/0002/selectUserList`, params);

/**
 * 메뉴권한 - 사용자별  메뉴조회
 * @param {object} params
 * @returns
 */
export const fetchMenuByUser = (params) =>
  instance.post(`${process.env.REACT_APP_SERVER_URL}/scq/scq/sys/0003/selectMenuByUser`, params);

/**
 * 메뉴권한 - 저장
 * @param {object} params
 * @returns
 */
export const mergeMenuAutByUser = (dtos) =>
  instance.post(`${process.env.REACT_APP_SERVER_URL}/scq/scq/sys/0003/mergeMenuAutByUser`, {
    main: dtos,
    mainCnt: dtos.length,
  });
