import instance from '../../common/config/api';

/**
 * 법인카드 - 부서(계좌)명세 조회
 * @param {object} params
 * @returns
 */
export const fetchDeptMngListByDept = (params) =>
  instance.post(`${process.env.REACT_APP_SERVER_URL}/scq/scq/cad/0004/selectDeptMng`, params);

/**
 * 법인카드 - 카드명세 조회
 * @param {object} params
 * @returns
 */
export const fetchDeptMngListByCard = (params) =>
  instance.post(`${process.env.REACT_APP_SERVER_URL}/scq/scq/cad/0004/selectCadMng`, params);

/**
 * 법인카드 - 부서(계좌)명세 수정
 * @param {object} dtos
 * @returns
 */
export const updateDeptMngListByDept = (dtos) =>
  instance.post(`${process.env.REACT_APP_SERVER_URL}/scq/scq/cad/0004/updateListDeptMng`, {
    subDto: dtos,
    subDtoCnt: dtos.length,
  });

/**
 * 법인카드 - 카드명세 수정
 * @param {object} dtos
 * @returns
 */
export const updateDeptMngListByCard = (dtos) =>
  instance.post(`${process.env.REACT_APP_SERVER_URL}/scq/scq/cad/0004/updateListCadMng`, {
    subDto: dtos,
    subDtoCnt: dtos.length,
  });
