import instance from '../../common/config/api';

/**
 * 카드결제계좌 거래내역 조회 (결제 계좌정보 조회)
 * @param {object} params
 * @returns
 */
export const fetchListStlActInf = (params) =>
  instance.post(`${process.env.REACT_APP_SERVER_URL}/scq/scq/cad/0003/selectListStlActInf`, params);

/**
 * 카드결제계좌 거래내역 조회 (결제 계좌거래내역 조회)
 * @param {object} params
 * @returns
 */
export const fetchListStlActTrnPrts = (params) =>
  instance.post(`${process.env.REACT_APP_SERVER_URL}/scq/scq/cad/0003/selectListStlActTrnPrts`, params);

/**
 * 카드결제계좌 거래내역 조회 (거래명세 건수 및 거래명세 조회)
 * @param {object} params
 * @returns
 */
export const fetchListStlActTrhs = (params) =>
  instance.post(`${process.env.REACT_APP_SERVER_URL}/scq/scq/cad/0003/selectListStlActTrhs`, params);

/**
 * 카드결제계좌 거래내역 조회 ( DB 현재 시각 조회 )
 * @returns
 */
export const fetchDbCtTm = (params) =>
  instance.post(`${process.env.REACT_APP_SERVER_URL}/scq/scq/cad/0003/selectDbCtTm `);

/**
 * 카드결제계좌 거래내역 조회 ( 개설점/관리점 조회 )
 * @param {object} params
 * @returns
 */
export const fetchOpnMngBr = (params) =>
  instance.post(`${process.env.REACT_APP_SERVER_URL}/scq/scq/cad/0003/selectOpnMngBr`, params);
