import { SUCCESS, REQUEST, FAILURE } from '../../../common/utils/action-type-utils';
import { fetchEtcAsstBznAccnBgtBssnRtList } from '../../../lib/etc/etcAsstBznAccnBgtBssnRt';
import dayjs from 'dayjs';
import { fetchCommonComboList } from '../../../lib/cmm/combo.api';

// actions
const FETCH_ASSTBZNACCNBGTBSSNRT = 'etcAsstBznAccnBgtBssnRt/FETCH_ASSTBZNACCNBGTBSSNRT';
const RESET_STATE = 'etcAsstBznAccnBgtBssnRt/RESET_STATE';
const SAVE_SEARCH_FORM_VALUES = 'etcAsstBznAccnBgtBssnRt/SAVE_SEARCH_FORM_VALUES';
const FETCH_IST_INFO_LIST = 'etcAsstBznAccnBgtBssnRt/FETCH_IST_INFO_LIST';

export const initFormValues = {
  gubun: '2', // 기간 (1: 월별 , 2: 년/반기별, 3: 일별)
  start_dt: dayjs().add(-1, 'day').format('YYYY-MM-DD'), // 기간: 일별 시
  start_dt_b: '',
  end_dt: dayjs().add(-1, 'day').format('YYYY-MM-DD'), // 기간: 일별 시
  end_dt_b: '',
  ydcs8a1_t8a01_acc_yr: dayjs().format('YYYY'), // 기간: 년/반기별 시
  ydcs8a1_t8a01_acc_yr2: '',
  gubun3: '9', // 기간: 년/반기별 시 (상반기,하반기)
  yyyymm: dayjs().add(-1, 'month').format('YYYY-MM'), // 기간: 월별 시 선택 월 (202310)
  yyyymm2: '',
  gubun2: '0', // 조회구분
};

// initial state
const initialState = {
  loading: false,
  success: false,
  message: '',
  list: [],
  totalCount: 0,
  //콤보 - 기관명 005
  istInfoSuccess: false,
  istInfoLoading: false,
  istInfoList: [],
  searchFormValues: {
    init: true,
    ...initFormValues,
  },
  initFormValues: initFormValues,
};

// reducers
export default function etcAsstBznAccnBgtBssnRt(state = initialState, action) {
  switch (action.type) {
    case SUCCESS(FETCH_ASSTBZNACCNBGTBSSNRT): // 조회 성공
      return {
        ...state,
        success: true,
        loading: false,
        list: action.payload.data?.subDto,
        totalCount: action.payload?.subDtoCnt,
      };
    case REQUEST(FETCH_ASSTBZNACCNBGTBSSNRT): // 조회 요청
      return {
        ...state,
        loading: true,
        success: false,
      };
    case FAILURE(FETCH_ASSTBZNACCNBGTBSSNRT): // 조회실패
      return {
        ...initialState,
        loading: false,
        success: false,
        message: action.payload.response?.data?.message,
      };
    // 기관명
    case SUCCESS(FETCH_IST_INFO_LIST): // 조회 성공
      return {
        ...state,
        istInfoSuccess: true,
        istInfoLoading: false,
        istInfoList: action.payload.data?.subDto,
      };
    case REQUEST(FETCH_IST_INFO_LIST): // 조회 요청
      return {
        ...state,
        istInfoLoading: true,
        istInfoSuccess: false,
      };
    case FAILURE(FETCH_IST_INFO_LIST): // 조회실패
      return {
        ...state,
        istInfoLoading: false,
        istInfoSuccess: false,
        istInfoList: [],
      };
    // 부서명
    case RESET_STATE:
      return initialState;
    case SAVE_SEARCH_FORM_VALUES:
      return {
        ...state,
        searchFormValues: action.payload,
      };
    default:
      return state;
  }
}

/**
 * 기타 - 보조금사업 계좌수 및 예산점유율
 */
export const selectEtcAsstBznAccnBgtBssnRtList = (searchParams) => {
  return {
    type: FETCH_ASSTBZNACCNBGTBSSNRT,
    payload: fetchEtcAsstBznAccnBgtBssnRtList(searchParams),
  };
};

/**
 * 조회조건 저장
 */
export const saveSearchFormValues = (searchFormValues) => {
  return {
    type: SAVE_SEARCH_FORM_VALUES,
    payload: {
      ...searchFormValues,
      init: false,
    },
  };
};

//기관명 콤보 조회
export const selectIstInfoList = (searchParam) => {
  return {
    type: FETCH_IST_INFO_LIST,
    payload: fetchCommonComboList({ slt_box_no: '005', ...searchParam }),
  };
};

/**
 *  상태초기화
 */
export const resetEtcAsstBznAccnBgtBssnRt = () => {
  return {
    type: RESET_STATE,
  };
};
