function useDefaultMenu() {
  const initMenuList = [];
  /**
   * 사용자 정보 조회
   * @returns
   */
  const getMenuList = () => {
    // console.log('getUserInfo');
    let menuList = localStorage.getItem('menu_list');
    if (!menuList) {
      menuList = JSON.stringify(initMenuList);
    }
    return JSON.parse(menuList);
  };
  return { getMenuList };
}

export default useDefaultMenu;
