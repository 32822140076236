import { fetchSpcUser, insertSpcUser } from '../../../lib/sys/SYS0206M01.api';
import { SUCCESS, REQUEST, FAILURE } from '../../../common/utils/action-type-utils';

// actions
const FETCH_SPC_USER = 'SYS0206M01/FETCH_SPC_USER';
const INSERT_SPC_USER = 'SYS0206M01/INSERT_SPC_USER';
const SAVE_SEARCH_FORM_VALUES = 'SYS0206M01/SAVE_SEARCH_FORM_VALUES';
const RESET_STATE = 'SYS0206M01/RESET_STATE';

export const initFormValues = {
  // 검색 영역
  br_yn_search: 'all', //사용자구분
};

// initial state
const initialState = {
  loading: false,
  success: false,
  message: '',
  list: [],
  details: [],
  totalCount: 0,
  updating: false,
  updateSuccess: false,
  resultCnt: -1,

  // 검색조건
  searchFormValues: {
    init: true,
    ...initFormValues,
  },
};

// reducers
export default function SYS0206M01(state = initialState, action) {
  switch (action.type) {
    case SUCCESS(FETCH_SPC_USER): // 조회 성공
      return {
        ...state,
        success: true,
        loading: false,
        list: action.payload.data?.main,
        totalCount: action.payload?.main_cnt,
        updateSuccess: false,
      };
    case REQUEST(FETCH_SPC_USER): // 조회 요청
      return {
        ...state,
        loading: true,
        message: '',
        updateSuccess: false,
      };
    case FAILURE(FETCH_SPC_USER): // 조회실패
      return {
        ...initialState,
        loading: false,
        success: false,
        message: action.payload.response?.data?.message,
        updateSuccess: false,
      };
    case SUCCESS(INSERT_SPC_USER): // 갱신 성공
      return {
        ...state,
        updateSuccess: true,
        updating: false,
        resultCnt: action.payload.data.resultCnt,
      };
    case REQUEST(INSERT_SPC_USER): // 갱신 요청
      return {
        ...state,
        updating: true,
        updateSuccess: false,
      };
    case FAILURE(INSERT_SPC_USER): // 갱신 실패
      return {
        ...initialState,
        updating: false,
        updateSuccess: false,
        resultCnt: -1,
        message: action.payload.response?.data?.message,
      };
    case SAVE_SEARCH_FORM_VALUES:
      return {
        ...state,
        searchFormValues: action.payload,
      };
    case RESET_STATE:
      return initialState;
    default:
      return { ...state, updateSuccess: false };
  }
}

/**
 * 비공금영업점사용자 조회
 */
export const selectSpcUser = (searchParams) => {
  return {
    type: FETCH_SPC_USER,
    payload: fetchSpcUser(searchParams),
  };
};

/**
 * 비공금영업점사용자 저장
 */
export const saveSpcUser = (dtos) => {
  return {
    type: INSERT_SPC_USER,
    payload: insertSpcUser(dtos),
  };
};

/**
 * 조회조건 저장
 */
export const saveSearchFormValues = (searchFormValues) => {
  return {
    type: SAVE_SEARCH_FORM_VALUES,
    payload: {
      ...searchFormValues,
      init: false,
    },
  };
};

/**
 * 상태초기화
 */
export const resetState = () => {
  return {
    type: RESET_STATE,
  };
};
