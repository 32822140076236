import instance from '../../common/config/api';

const prefix = `${process.env.REACT_APP_SERVER_URL}/scq/scq/sys/0003`;
/**
 * 메뉴조회
 * @param {object} params
 * @returns
 */
export const fetchMenuList = (params) => instance.post(`${prefix}/selectMenuList`, params);

/**
 * 연락처
 */
export const fetchContacts = () => instance.post(`${prefix}/selectCodeTelList`, {});

/**
 * 메뉴구분 조회
 */
export const fetchMenuDisList = (params) => instance.post(`${prefix}/selectMenuDis`, params);

/**
 * 메뉴도움말리스트 조회
 */
export const fetchMenuListByHelp = (params) => instance.post(`${prefix}/selectMenuListByHelp`, params);

/**
 * 메뉴도움말상세 조회
 */
export const fetchMenuHelpDtl = (params) => instance.post(`${prefix}/selectMenuHelpDtl`, params);

/**
 * 메뉴도움말 저장
 */
export const insertMenuHelpDtl = (params) => instance.post(`${prefix}/saveMenuHelpDtl`, params);
