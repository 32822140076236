import WOButton from '../../components/common/WOButton';
import WOComboBox from '../../components/common/WOComboBox';
import WOMap from '../../components/main/WOMap';
import WOOccurrenceItem from '../../components/main/WOOccurrenceItem';
import WOBorderBox from '../../components/common/WOBorderBox';
import styled, { css } from 'styled-components';
import WOIconButton from '../../components/common/WOIconButton';
import WOEventItem from '../../components/main/WOEventItem';
import WOChart from '../../components/common/WOChart';
import WOTabs from './../../components/common/WOTabs';
import { useCallback, useEffect, useMemo, useState } from 'react';
import WOTabContent from '../../components/common/WOTabContent';
import WOAbnormalityCard from '../../components/main/WOAbnormalityCard';
import { fdsComboStyle } from '../../styles/common.styled';
import { useDispatch, useSelector } from 'react-redux';
import { sendLogout } from '../../redux/modules/cmm/login.reducer';
import { Link, useNavigate } from 'react-router-dom';
import dayjs from 'dayjs';
import useSession from '../../hooks/useSession';
import { dataFormat } from '../../common/utils/utils';
import { Controller, useForm } from 'react-hook-form';
import { selectAbvTrnPcptGatEvt, selectAbvTrnPcptGatItemDtls } from '../../redux/modules/fds/MainFDS.reducer';
import { Severity } from '../../common/enum/cmm/occurence';
import { selectAppvCnt, selectPapsCnt } from '../../redux/modules/cmm/person.reducer';
import LogoutArea from './LogoutArea';
import UserSettingPopup from '../../pages/cmm/userSettingPopup';
import 'swiper/css/grid';
import { Swiper, SwiperSlide } from 'swiper/react';
import { Grid } from 'swiper';
import WOSlider from '../../components/common/WOSlider';

const WOExpandFDS = ({ user, istInfoList, handleOnIstInfoChange, mapInfo }) => {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const {
    user_nm,
    br_nm,
    user_tp_cd,
    dept_nm,
    qna_cnt,
    cus_ist_cd: istCd,
    ist_nm,
    dept_cd,
  } = useSession().getUserInfo();
  const today = dayjs().format('YYYY.MM.DD');
  const { list: menuList } = useSelector((state) => state.menuState);
  const [activeTabID, setActiveTabID] = useState(1);
  const [selectedOccurence, setSelectedOccurence] = useState(null);

  // grid
  const {
    totCnt,
    top3,
    levelCnt,
    cus_ist_cd,
    chartData,
    charDataSort,
    details,
    dataList,
    liveEventList,
    todaysEventList,
  } = useSelector((state) => state.MainFDS);

  /************  COMBO  ***********/
  // 기관코드(7)[005]
  // const {
  //   success: { CB005: istInfoSuccess },
  //   loading: { CB005: istInfoLoading },
  //   options: { CB005: istInfoList },
  // } = useSelector((state) => state.combo);

  const allOption = useMemo(() => ({ code: 'all', codenm: '전체' }), []);
  /************  COMBO END ***********/

  // 사용자 설정
  const [isOpen, setIsOpen] = useState(false);

  // 비밀번호 재설정
  const [isOpen2, setIsOpen2] = useState(false);

  // 부서 변경
  const [isOpen3, setIsOpen3] = useState(false);

  const handleClose = () => {
    setIsOpen(false);
  };

  const transactionEventData = useMemo(
    () =>
      todaysEventList.map((item, index) => ({
        id: 1,
        fdsAccType: item.act_dis_cd,
        accountType: (() => {
          let type;
          switch (item.act_dis_cd) {
            case '01':
              type = '보통예금';
              break;
            case '02':
              type = '일상경비';
              break;
            case '03':
              type = '공금계좌';
              break;
            default:
              type = '';
          }
          return type;
        })(),
        source: item.ist_nm,
        time: dayjs(item.fds_dtct_dt).format('YYYY.MM.DD HH:mm:ss'),
        //manager: '김기관 담당자',
        fdsPolicyType: item.fds_policy_cd,
        transactionType: item.grp_nm,
        fds_ist_cd: item.fds_ist_cd,
      })),
    [todaysEventList]
  );

  const transactionEventList = [
    {
      id: 1,
      type: 'all',
      name: '전체',
    },
    {
      id: 2,
      type: '01',
      name: '보통예금',
    },
    {
      id: 3,
      type: '02',
      name: '일상경비',
    },
    {
      id: 4,
      type: '03',
      name: '공금계좌',
    },
  ];

  const { control } = useForm({
    defaultValues: {
      cus_ist_cd: 'all',
      act_dis_cd: '',
      // 기본정보
      // 아이디
      // 성명
      // 생년월일
      // 휴대폰 번호
      // 소속구청/부서
      // 법인카드 권한
      // 비밀번호
    },
  });

  const fdsChartOptions = useMemo(
    () => ({
      chart: {
        width: 528,
      },
      title: {
        text: '',
      },
      xAxis: {
        categories: (() => {
          let days = [];
          chartData.forEach((item, index) => {
            days.push(item.day);
          });
          return days;
        })(),
      },
      yAxis: {
        title: {
          text: '',
        },
      },
      tooltip: {
        valueSuffix: '건',
      },
      plotOptions: {
        series: {
          borderRadius: 0,
        },
      },
      colors: ['#FC8686', '#249A9A', '#A964A6', '#5C80DB'],
      series: [
        {
          type: 'line',
          step: 'center',
          name: '탐지건수',
          data: (() => {
            let counts = [];
            chartData.forEach((item) => {
              const top1_cnt = item.top1_cnt ? Number(item.top1_cnt) : 0;
              const top2_cnt = item.top2_cnt ? Number(item.top2_cnt) : 0;
              const top3_cnt = item.top3_cnt ? Number(item.top3_cnt) : 0;
              let totCnt = top1_cnt + top2_cnt + top3_cnt;
              counts.push(totCnt);
            });
            return counts;
          })(),
          marker: {
            lineWidth: 2,
            fillColor: 'white',
          },
        },
        ...charDataSort,
      ],
      legend: {
        layout: 'horizontal',
        floating: true,
        align: 'center',
        verticalAlign: 'bottom',
        // width: 260,
        itemDistance: 4, // legend 간 간격
        x: 0,
        y: 22,
        symbolPadding: 4,
        symbolWidth: 14, // line타입 심볼의 사이즈
        symbolHeight: 4, // 심볼 사이즈
      },
    }),
    [charDataSort, chartData]
  );

  //fdsChartOptions.series.push()

  const occurrenceList = useMemo(
    () =>
      (() => {
        let arr = [];
        let index = 1;

        for (let key in details) {
          const data = details[key];

          let totalInfo = data.find((item) => item.fds_ist_cd === '');
          let level = totalInfo?.fds_lev_val;
          let cnt = totalInfo?.grp_cnt;

          const json = {
            id: index,
            fds_policy_cd: data?.at(0).grp_cd,
            title: data?.at(0).grp_cd_nm,
            count: cnt,
            severity: Severity.getSeverity(level),
            detailList: (() => {
              let arr = [];
              const filterData = data.filter((item) => item.fds_ist_cd !== '');
              for (let i = 0; i < filterData?.length; i++) {
                if (i >= 5) {
                  break;
                } else {
                  const json = {
                    id: i + 1,
                    location: filterData.at(i).ist_nm,
                    count: dataFormat.number(filterData.at(i).fds_ist_cnt),
                  };
                  arr.push(json);
                }
              }
              return arr;
            })(),
            // dataList: (() => {
            //   if (dataList[key]) {
            //     //debugger;
            //     return dataList[key].map((item, idx) => {
            //       return {
            //         id: idx + 1,
            //         agency: item.ist_nm ? item.ist_nm : '-',
            //         account: dataFormat.account1(item.fds_acno),
            //         amount: dataFormat.number(item.pay_am),
            //         date: dayjs(item.fds_dtct_dt).format('YYYY.MM.DD'),
            //         briefs: item.pay_rmk,
            //       };
            //     });
            //   } else {
            //     //return [{}];
            //     return [];
            //   }
            // })(),
          };
          arr.push(json);
          index++;
        }
        return arr;
      })(),
    [details]
  );

  const occurrenceNoAreaList = useMemo(() => {
    let arr = [];
    let index = 1;
    for (let key in details) {
      //const data = details[key];
      const detailsData = details[key];

      let totalInfo = detailsData.find((item) => item.fds_ist_cd === '');
      let level = totalInfo?.fds_lev_val;
      let cnt = totalInfo?.grp_cnt;

      const json = {
        id: index,
        fds_policy_cd: detailsData?.at(0).grp_cd,
        title: detailsData?.at(0).grp_cd_nm,
        count: cnt,
        severity: Severity.getSeverity(level),
        // dataList: (() => {
        //   let arr = [];
        //   let idx = 0;
        //   for (let i = 1; i < data?.length; i++) {
        //     const json = {
        //       id: idx + 1,
        //       agency: data.at(i).ist_nm ? data.at(i).ist_nm : '-',
        //       account: dataFormat.account1(data.at(i).fds_acno),
        //       amount: dataFormat.number(data.at(i).pay_am),
        //       date: dayjs(data.at(i).fds_dtct_dt).format('YYYY.MM.DD'),
        //       briefs: data.at(i).pay_rmk,
        //     };
        //     arr.push(json);
        //     idx++;
        //   }
        //   return arr;
        // })(),
      };
      arr.push(json);
      index++;
    }
    return arr;
  }, [details]);

  const dataListArea = useMemo(() => {
    let arr = [];
    let idx = 0;

    for (let i = 0; i < dataList?.length; i++) {
      const json = {
        id: idx + 1,
        agency: dataList.at(i).ist_nm ? dataList.at(i).ist_nm : '-',
        account: dataFormat.account1(dataList.at(i).fds_acno),
        amount: dataFormat.number(dataList.at(i).pay_am),
        date: dayjs(dataList.at(i).fds_dtct_dt).format('YYYY.MM.DD'),
        briefs: dataList.at(i).pay_rmk,
      };
      arr.push(json);
      idx++;
    }
    return arr;
  }, [dataList]);

  const liveEventListTable = useMemo(
    () =>
      liveEventList.map((item, index) => ({
        id: index + 1,
        title: item.fds_policy_nm,
        source: item.pay_rmk,
        time: item.fds_dtct_dt ? dayjs(item.fds_dtct_dt).format('YYYY.MM.DD HH:mm:ss') : '',
        isRead: false,
        severity: Severity.getSeverity(item.fds_lev_nm),
        fds_ist_cd: item.fds_ist_cd,
      })),
    [liveEventList]
  );

  const handleOnTabChange = useCallback(
    (val) => {
      dispatch(
        selectAbvTrnPcptGatEvt({
          cus_ist_cd: cus_ist_cd.replaceAll('all', ''),
          act_dis_cd: val.replaceAll('all', ''),
        })
      );
    },
    [cus_ist_cd, dispatch]
  );

  const handleOccurenceClick = (selectedItem) => {
    setSelectedOccurence(selectedItem);

    const ist_cd = cus_ist_cd.replaceAll('all', '');
    const deptCd = ![1, 2].includes(user?.id) ? dept_cd : '';

    const { fds_policy_cd } = selectedItem;

    dispatch(selectAbvTrnPcptGatItemDtls({ cus_ist_cd: ist_cd, fds_policy_cd, dept_cd: deptCd }));

    //selectedItem.fds_policy_id;
  };

  /**
   * 로그아웃
   */
  const handleLogout = () => {
    dispatch(sendLogout());
    navigate('/page/login');
  };

  const handleUserPopupOpen = () => {
    setIsOpen(true);
  };

  useEffect(() => {
    user?.id && setSelectedOccurence(occurrenceNoAreaList[0]);
  }, [occurrenceNoAreaList, user]);

  const { appvCnt, docCnt } = useSelector((state) => state.personState);

  // useState(() => {
  //   // 승인건수
  //   dispatch(selectAppvCnt());
  //   // 문서건수
  //   dispatch(selectPapsCnt());
  // }, [dispatch]);

  // view
  return (
    <>
      <Wrap>
        <GradientWrap>
          <InfoWrap>
            <div className="info__name">
              <span className="bold">{user_nm}님</span>
              <span className="area">
                {/* 20240329 : 
            - 우리은행 총괄로 접속했을때는 우리은행 "이름"
            - 기관으로 접속시 기관명 "이름"
          */}
                {user_tp_cd?.substring(0, 1) === 'W' ? br_nm : `${ist_nm} ${dept_nm}`}
              </span>
            </div>
            <LogoutArea onLogout={handleLogout} />
            <div className="info__document">
              <ul className="info__document-list">
                {menuList
                  .filter((menu) => menu.mnu_aut_cd !== 'X')
                  .find((menu) => menu.scrn_url.includes('SYS0107M01')) && (
                  <li className="info__document-item" onClick={() => navigate('/page/SYS0107M01')}>
                    Q&A {user?.id === 1 && <>{dataFormat.number(qna_cnt)}건</>}
                  </li>
                )}
                {menuList
                  .filter((menu) => menu.mnu_aut_cd !== 'X')
                  .find((menu) => menu.scrn_url.includes('BRC0209V01')) && (
                  <li className="info__document-item" onClick={() => navigate('/page/BRC0209V01')}>
                    문서 {dataFormat.number(docCnt)}건
                  </li>
                )}
                {menuList
                  .filter((menu) => menu.mnu_aut_cd !== 'X')
                  .find((menu) => menu.scrn_url.includes('RPT0402M01')) && (
                  <li className="info__document-item" onClick={() => navigate('/page/RPT0402M01')}>
                    결재 {dataFormat.number(appvCnt)}건
                  </li>
                )}
              </ul>
              <WOButton variant="personal" onClick={handleUserPopupOpen}>
                사용자 설정
              </WOButton>
            </div>
          </InfoWrap>
        </GradientWrap>

        <ContentWrap>
          {/* START: map영역 */}
          <Content>
            <MapArea>
              <div className="map__title-area">
                <span className="sub-text">Today. {today}</span>
                <div className="status">
                  <div className="status__text">FDS 현황을 확인해 보세요!</div>
                  {/* {user?.id === 1 ? (
                    <WOComboBox optionList={areaOptionList} width={160} defaultValue={user && areaOptionList[0].id} />
                  ) : (
                    <WOComboBox
                      optionList={areaOptionList}
                      width={160}
                      readOnly={true}
                      defaultValue={user && areaOptionList[1].id}
                    />
                  )} */}
                  <Controller
                    /* 기관명 */
                    control={control}
                    name={'cus_ist_cd'}
                    render={({ field: { onChange, value } }) => (
                      <WOComboBox
                        type={'fds'}
                        width={160}
                        optionList={[allOption, ...istInfoList]?.map((option) => ({
                          id: option.code,
                          label: option.codenm,
                          value: option.code,
                        }))}
                        onChange={(val) => {
                          onChange(val);
                          handleOnIstInfoChange(val);
                        }}
                        defaultValue={cus_ist_cd}
                        isAutoComplete={true}
                        disabled={user?.id !== 1}
                      />
                    )}
                  />
                </div>
              </div>
              <div className="map__data">
                <WOMap
                  isExpand={true}
                  totCnt={totCnt}
                  top3={top3}
                  levelCnt={levelCnt}
                  mapInfo={mapInfo}
                  handleOnIstInfoChange={handleOnIstInfoChange}
                  user={user}
                  istInfoList={istInfoList}
                />
                <ChartWrap>
                  <ChartTitle>
                    <SubTitle>일별 추이</SubTitle>
                    {/* <span className="sub-text">{today} 기준</span> */}
                    <span className="sub-text">최근 15일 기준</span>
                  </ChartTitle>
                  <WOChart options={fdsChartOptions} type={'fds'} />
                </ChartWrap>
              </div>
            </MapArea>
          </Content>
          {/* END: map영역 */}
          {/* START: 세부 발생항목 영역 */}
          <Content>
            <OccurrenceArea className={`user-${user && user?.id}`} $user={user}>
              <div className="occurrence__detail">
                <DetailItemBox $user={user?.id}>
                  <div className="top_area">
                    <SubTitle>
                      세부 발생 항목<span className="sub-text">최근 7일 기준</span>
                    </SubTitle>
                    <Link to={'/page/FDS0101V01'} state={{ mainProps: { fds_ist_cd: cus_ist_cd } }} className="more">
                      더보기
                      <WOIconButton icon={'more'} width={16} height={16} onClick={() => {}} />
                    </Link>
                  </div>
                  <div className="occurrence__content">
                    {user?.id === 1 && (
                      <div className="occurrence__detail-list">
                        <WOSlider
                          isNavigation={true}
                          spaceBetween={8}
                          slidesPerGroup={4}
                          slidesPerView={'4.25'}
                          height={250}>
                          {occurrenceList.map((occurrence) => (
                            <CustomSwiperSlide type={'occurrence'} $user={user} key={occurrence.id}>
                              <WOOccurrenceItem
                                key={occurrence.id}
                                item={occurrence}
                                isActive={selectedOccurence && selectedOccurence.id === occurrence.id}
                                onClick={handleOccurenceClick}
                                $user={user?.id}
                              />
                            </CustomSwiperSlide>
                          ))}
                        </WOSlider>
                        <Swiper></Swiper>
                      </div>
                    )}

                    {user?.id !== 1 && (
                      <div className="occurrence__detail-list">
                        {occurrenceList.map((occurrence) => (
                          <div $user={user} key={occurrence.id}>
                            <WOOccurrenceItem
                              key={occurrence.id}
                              item={occurrence}
                              isActive={selectedOccurence && selectedOccurence.id === occurrence.id}
                              onClick={handleOccurenceClick}
                              $user={user?.id}
                            />
                          </div>
                        ))}
                      </div>
                    )}
                  </div>
                </DetailItemBox>
                <div className="occurrence__detail-list">
                  {selectedOccurence && (
                    <WOAbnormalityCard
                      title={selectedOccurence.title}
                      count={selectedOccurence.count}
                      severity={selectedOccurence.severity}
                      dataList={dataListArea}
                      $user={user?.id}
                    />
                  )}
                </div>
              </div>
            </OccurrenceArea>
          </Content>
          {/* END: 세부 발생항목 영역 */}
          {/* START: 실시간 이벤트 영역 */}
          <Content className="event">
            <LiveEventArea>
              <div className="top_area">
                <SubTitle>
                  실시간 이벤트<span className="sub-text">최근 3일 기준</span>
                </SubTitle>
                <Link
                  to={'/page/FDS0101V01'}
                  className="more"
                  state={{ mainProps: { fds_ist_cd: cus_ist_cd, type: 'live' } }}>
                  더보기
                  <WOIconButton icon={'more'} width={16} height={16} onClick={() => {}} />
                </Link>
              </div>
              <WOBorderBox height="264" marginTop={12}>
                <ul>
                  {liveEventListTable.map((event) => (
                    <WOEventItem key={event.id} event={event} type="live" />
                  ))}
                </ul>
              </WOBorderBox>
            </LiveEventArea>
            <TransactionEventArea>
              <div className="top_area">
                <SubTitle>금일 기준 거래 이벤트</SubTitle>
                <Link
                  to={'/page/FDS0101V01'}
                  className="more"
                  state={{ mainProps: { fds_ist_cd: cus_ist_cd, type: 'today' } }}>
                  더보기
                  <WOIconButton icon={'more'} width={16} height={16} onClick={() => {}} />
                </Link>
              </div>
              <WOBorderBox height="264" marginTop={12}>
                <WOTabs
                  activeTabID={activeTabID}
                  onChange={(selectedTabID) => {
                    setActiveTabID(selectedTabID);
                    handleOnTabChange(transactionEventList[selectedTabID - 1].type);
                  }}
                  tabs={transactionEventList}
                  type="type2"
                />

                {transactionEventList.map((event) => (
                  <WOTabContent key={event.id} id={event.id} activeTabId={activeTabID}>
                    <ul className="transaction-list">
                      {transactionEventData.map((item, index) => (
                        <WOEventItem key={index} event={item} istCd={cus_ist_cd} type="transaction" />
                      ))}
                    </ul>
                  </WOTabContent>
                ))}
              </WOBorderBox>
            </TransactionEventArea>
          </Content>
          {/* END: 실시간 이벤트 영역 */}
        </ContentWrap>
      </Wrap>
      <UserSettingPopup isOpen={isOpen} onClose={() => setIsOpen(false)} />
    </>
  );
};

export default WOExpandFDS;

const Wrap = styled.div`
  height: 100%;
  overflow: hidden;
  overflow-y: auto;
  display: flex;
  flex-direction: column;
  &::-webkit-scrollbar-track {
    background: rgba(237, 237, 243, 0); /* 스크롤바 트랙 색상 */
  }
`;
const GradientWrap = styled.div`
  width: fit-content;
  margin-left: auto;
  border: 1px solid transparent;
  border-radius: 0px 0px 0px 16px;
  background-image: linear-gradient(#2d3658, #2d3658), linear-gradient(343deg, #b0ebcc -1.81%, #99a3ff 46.2%);
  background-origin: border-box;
  background-clip: content-box, border-box;
`;
const InfoWrap = styled.div`
  width: 100%;
  height: 100%;
  position: relative;
  padding: 24px 32px;
  font-size: 14px;
  font-weight: 400;
  .bold {
    font-weight: 700;
  }
  width: 400px;
  /* height: 120px; */
  border-radius: 0px 0px 0px 16px;
  background: linear-gradient(5deg, rgba(176, 235, 203, 0.1) 6.12%, rgba(153, 163, 255, 0.1) 68.68%);
  z-index: 10;
  /* &:before {
    content: '';
    display: block;
    position: absolute;
    left: 0;
    right: 0;
    top: 0;
    bottom: 0;
    background: url('/assets/images/menu/fds-border.svg') no-repeat center / cover;
    pointer-events: none;
  } */

  .info {
    &__name {
      width: 260px;
      font-size: 20px;
      font-weight: 400;
      line-height: 24px;
      display: flex;
      flex-direction: column;
      gap: 6px 0;
      .area {
        word-break: break-all;
        font-size: 14px;
        font-weight: 400;
        color: #ededf3;
        line-height: 21px;
      }
      .bold {
        flex: 0 0 auto;
        line-height: 30px;
      }
      span {
        line-height: 24px;
      }
    }
    &__noti {
      position: absolute;
      right: 32px;
      top: 52px;
      display: flex;
      align-items: center;
      line-height: 30px;
      gap: 8px;
      color: #99a3ff;
    }
    &__document {
      margin-top: 6px;
      display: flex;
      justify-content: space-between;
      align-items: center;
      &-list {
        display: flex;
        font-size: 14px;
      }
      &-item {
        display: flex;
        align-items: center;
        cursor: pointer;
        font-size: 14px;
        font-weight: 400;
        color: #9ea6b2;
        & + .info__document-item {
          &::before {
            content: '';
            display: block;
            width: 1px;
            height: 12px;
            margin: 0 10px;
            background-color: #86909f;
          }
        }
      }
    }
  }
  .btn {
    padding: 2px 8px;
    color: #c9c9d0;
    font-weight: 400;
  }
`;

const DetailItemBox = styled.div`
  ${(props) =>
    props.$user !== 1 &&
    css`
      width: 100%;
      max-width: 528px;
    `}
`;

const ContentWrap = styled.div`
  position: relative;
  padding: 0 40px 40px;
  margin-top: -80px;
`;

const MapArea = styled.div`
  .map {
    &__title-area {
      width: calc(50% - 12px);
      max-width: 528px;
    }
    &__data {
      display: flex;
      align-items: center;
      gap: 24px;

      // 컴포넌트
      .map-wrap {
        width: 100%;
        max-width: 528px;
      }
    }
  }
  .sub-text {
    display: inline-block;
    font-size: 14px;
    font-weight: 400;
    color: #c9c9d0;
  }

  .status {
    display: flex;
    align-items: center;
    justify-content: space-between;
    margin-top: 2px;
    margin-bottom: 36px;
    &__text {
      font-size: 28px;
      font-weight: 700;
    }
  }

  ${fdsComboStyle}
`;

const SubTitle = styled.h3`
  // margin-bottom: 12px;
  font-size: 18px;
  font-weight: 600;
  line-height: 27px;

  .sub-text {
    color: var(--Blue-04);
    font-size: 12px;
    font-weight: 400;
    margin-left: 4px;
  }
`;

const OccurrenceArea = styled.div`
  width: 100%;
  display: flex;
  align-items: center;
  gap: 24px;
  align-items: flex-end;
  .occurrence {
    &__detail {
      width: 100%;
      display: flex;
      gap: 0 24px;
      align-items: flex-end;
      justify-content: space-between;

      .top_area {
        display: flex;
        align-items: center;
        justify-content: space-between;
        margin-bottom: 12px;

        .more {
          display: flex;
          color: #bdd7ff;
          font-size: 12px;
          font-weight: 400;
          line-height: 18px;
        }
      }

      &-list {
        position: relative;
        width: 100%;
        height: 250px;
        display: flex;
        align-items: stretch;
        gap: 8px;
        &:has(.swiper) {
          gap: 0;
        }
        .occurrence-item {
          width: 161px;
        }

        .swiper {
          margin-top: 0;

          & ~ button:disabled {
            display: none;
          }

          & ~ button.next {
            top: 50%;
            margin-top: -12px;
            right: -12px;
            z-index: 9999;

            .icon {
              width: 24px;
              height: 24px;
              background: url('/assets/images/icon/fds-arrow-right.svg') no-repeat center / cover;
            }
          }

          & ~ button.prev {
            top: 50%;
            margin-top: -12px;
            left: -12px;
            z-index: 9999;
            .icon {
              transform: rotate(360deg);
              width: 24px;
              height: 24px;
              background: url('/assets/images/icon/fds-arrow-right.svg') no-repeat center / cover;
            }
          }
        }
      }
    }
    &__content {
      display: flex;
      align-items: stretch;
      width: 712px;
      height: 250px;
      gap: 24px;
    }
    &__account {
      width: 100%;
      /* min-width: 344px; */
    }
  }

  ${(props) =>
    props.$user?.id !== 1
      ? css`
          .occurrence {
            &__content {
              width: 530px;
            }
            &__detail {
              &-list {
                flex-wrap: wrap;
                .occurrence-item {
                  width: 170px;
                  height: 120px;
                }
              }
            }
            &__account {
              max-width: 336px;
              .account-list {
                width: 100%;
              }
            }
          }
        `
      : css``}
`;

const LiveEventArea = styled.div`
  width: 100%;
  .top_area {
    display: flex;
    align-items: flex-end;
    justify-content: space-between;
    .more {
      display: flex;
      color: #bdd7ff;
      font-size: 12px;
      font-weight: 400;
      line-height: 18px;
    }
  }
`;

const TransactionEventArea = styled.div`
  width: 100%;

  .border-box {
    overflow: hidden;
  }
  .top_area {
    display: flex;
    align-items: flex-end;
    justify-content: space-between;
    .more {
      display: flex;
      color: #bdd7ff;
      font-size: 12px;
      font-weight: 400;
      line-height: 18px;
    }
  }
  .transaction {
    &-list {
      margin-top: 10px;
      height: 100%;
      padding: 8px 0;
      overflow-y: auto;
    }

    .status {
      color: #fff;
    }
  }

  .tab {
    width: 100%;
    display: flex;
    margin-top: 10px;
    > button {
      flex: 1;
    }
  }
`;

const Content = styled.div`
  &.event {
    display: flex;
    align-items: center;
    gap: 24px;
  }
  & + & {
    padding-top: 24px;
  }
`;

const ChartWrap = styled.div`
  width: 100%;
`;

const ChartTitle = styled.div`
  display: flex;
  align-items: flex-end;
  gap: 4px;
  padding-bottom: 18px;
  ${SubTitle} {
    margin-bottom: 0;
  }
  .sub-text {
    color: var(--Blue-04);
    font-size: 12px;
    font-weight: 400;
  }
`;

const CustomSwiperSlide = styled(SwiperSlide)``;
