import axios from 'axios';
import instance from '../../common/config/api';

/**
 * 파일 확장자 추출
 */
export const getFileExt = (fileName) => {
  const firstIndex = fileName.lastIndexOf('.');
  if (firstIndex < 0) {
    return '';
  }
  const lastIndex = fileName.length + 1;
  return fileName.substring(firstIndex + 1, lastIndex);
};

/**
 * base64 to blob
 * @param {string} b64Data
 * @param {string} contentType
 * @param {number} sliceSize
 * @returns
 */
export const b64toBlob = (b64Data, contentType = '', sliceSize = 512) => {
  const byteCharacters = atob(b64Data);
  const byteArrays = [];

  for (let offset = 0; offset < byteCharacters.length; offset += sliceSize) {
    const slice = byteCharacters.slice(offset, offset + sliceSize);

    const byteNumbers = new Array(slice.length);
    for (let i = 0; i < slice.length; i++) {
      byteNumbers[i] = slice.charCodeAt(i);
    }

    const byteArray = new Uint8Array(byteNumbers);
    byteArrays.push(byteArray);
  }

  const blob = new Blob(byteArrays, { type: contentType });
  return blob;
};

/**
 * 문서 위변조 확인 키 생성
 * @param {string} docId
 * @returns
 */
export const makeDocCheckSum = async (docId) => {
  const response = await instance.post(`${process.env.REACT_APP_SERVER_URL}/scq/scq/cmm/util/hexEnc`, { input: docId });
  return response.data?.ecc_enc;
};

export const dataFormat = {
  //계좌번호1 [ 0000-00000-000-0 ]
  account1: (value) =>
    value && value?.length === 13
      ? `${value.substr(0, 4)}-${value.substr(4, 5)}-${value.substr(9, 3)}-${value.substr(value.length - 1)}`
      : '',

  //계좌번호2 [ 0000-000-000000 ]
  account2: (value) =>
    value && value?.length === 13
      ? `${value.substr(0, 4)}-${value.substr(4, 3)}-${value.substr(7, value.length - 1)}`
      : '',

  account3: (value) =>
    value && value?.length === 16 ? `${value.substr(0, 3)}-${value.substr(3, value.length - 1)}` : '',

  //계좌번호4 [ 0000-00000-000 ]
  account4: (value) =>
    value && [12, 13].includes(value?.length)
      ? `${value.substr(0, 4)}-${value.substr(4, 5)}-${value.substr(9, 3)}`
      : '',

  //시간 [ HH:MM:SS , HH:MM ]
  time: (value) => {
    if (value) {
      if (value?.length === 6) {
        return `${value.substr(0, 2)}:${value.substr(2, 2)}:${value.substr(4, 2)}`;
      } else if (value?.length === 4) {
        return `${value.substr(0, 2)}:${value.substr(2, 2)}`;
      } else {
        return '';
      }
    } else {
      return '';
    }
  },
  //사업자번호 [ 000-00-00000 ]
  biz_no: (value) =>
    value && value?.length === 10
      ? `${value.substr(0, 3)}-${value.substr(3, 2)}-${value.substr(5, value.length - 1)}`
      : '',

  //숫자 [ 0,000,000 ]
  number: (value) => (value ? value.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ',') : '0'),

  //상태 [ C : '취소' , A : "정정", E : "기타" ]
  sts: (value) => {
    if (value) {
      switch (value) {
        case 'C':
          return '취소';
        case 'A':
          return '정정';
        case 'E':
          return '기타';
        default:
          return '';
      }
    } else {
      return '';
    }
  },
  //입지구분 [ 입금 : 1,8 , 지급 : 나머지 ]
  trmod_dscd: (value) => {
    if (value) {
      return value === 1 || value === 8 ? '입금' : '지급';
    } else {
      return '';
    }
  },

  //핸드폰번호 [010-1111-2222]
  tel_no: (value) =>
    value && value?.length === 11
      ? `${value.substr(0, 3)}-${value.substr(3, 4)}-${value.substr(7, value.length - 1)}`
      : value?.length === 10
      ? `${value.substr(0, 3)}-${value.substr(3, 3)}-${value.substr(6, value.length - 1)}`
      : value,

  //시/구세구분
  arvnActDscd: (value) => (value ? (value === 'all' ? '전체' : value === '01' ? '시세' : '구세') : ''),
  agnActDscd: (value) => {
    if (value) {
      switch (value) {
        case 'all':
          return '전체';
        case '01':
          return '모계좌';
        case '02':
          return '자계좌';
        case '03':
          return '손자계좌';
        default:
          return '';
      }
    } else {
      return '';
    }
  },
  dpactStcd: (value) => {
    if (value) {
      switch (value) {
        case 'all':
          return '전체';
        case '01':
          return '정상(활동계좌)';
        case '51':
          return '해지';
        case '61':
          return '신규취소';
        case '91':
          return '연도폐쇄';
        default:
          return '';
      }
    } else {
      return '';
    }
  },
  inqSeg: (value) => {
    if (value) {
      switch (value) {
        case '1':
          return '전체거래내역';
        case '2':
          return '과오납제외 전체거래내역';
        default:
          return '';
      }
    } else {
      return '';
    }
  },
  axpnActDscd: (value) => {
    if (value) {
      switch (value) {
        case 'all':
          return '전체';
        case '01':
          return '일반회계';
        case '02':
          return '세입세출외현금';
        case '03':
          return '일상경비(과)';
        case '04':
          return '일상경비(사업소/동사무소)';
        case '07':
          return '기금';
        case '11':
          return '특별회계';
        default:
          return '';
      }
    } else {
      return '';
    }
  },
};

//객체( {} )가 빈값인지 확인
export const isEmptyObject = (obj) => {
  return obj.constructor === Object && Object.keys(obj).length === 0 ? true : false;
};

/**
 * 리포트 PDF로 다운로드
 * @param {string} fileName
 * @param {string} templateName
 * @param {object} jsonData
 * @param {object} reportParams
 */
export const downloadClipReportForPdf = (fileName, templateName, jsonData, reportParams) => {
  // make body
  let formData = `template=${templateName}&jsonData=${encodeURI(
    JSON.stringify(jsonData).replaceAll('%', '^^').replaceAll('&', '@@')
  )}`;
  const params = [];
  const reportParamsAndColor = { bgcolor: process.env.REACT_APP_REPORT_BG_COLOR, ...reportParams };
  for (let key in reportParamsAndColor) {
    params.push(`${key}=${encodeURI(reportParamsAndColor[key])}`);
  }
  const ozParams = params.join('&');
  if (ozParams.length > 0) {
    formData = formData + `&${ozParams}`;
  }
  // send
  axios
    .post(`${process.env.REACT_APP_REPORT_SERVER_URL}/rpt/exportForPDF.jsp`, formData, { responseType: 'blob' })
    .then((response) => {
      const blob = response?.data;
      const url = window.URL.createObjectURL(blob);
      const a = document.createElement('a');
      a.href = url;
      // origin file name
      a.download = fileName;
      a.click();
      setTimeout((_) => {
        window.URL.revokeObjectURL(url);
      }, 60000);
      a.remove();
    })
    .catch((err) => {
      console.log('err: ', err);
    });
};

/**
 * LPAD
 * @param {string} text
 * @param {number} padLength
 * @param {string} padString
 * @returns
 */
export const lpad = (text, padLength, padString) => {
  let arrTxt = String(text);
  while (arrTxt.length < padLength) {
    arrTxt = padString + arrTxt;
  }
  return arrTxt;
};

/**
 * List 데이터 변경
 * @param {array} list
 * @param {string} id
 * @param {object} newRow
 * @returns
 */
export const updateRows = (list, id, newRow) => {
  const oldRowIndex = list.findIndex((item) => item[id] === newRow[id]);
  if (oldRowIndex === -1) {
    return [];
  }
  const prevList = list.slice(0, oldRowIndex);
  const nextList = list.slice(oldRowIndex + 1);
  return [...prevList, newRow, ...nextList];
};

/**
 * 엑셀 subtitle 문자열 생성
 * @param {object} searchInfo 조회조건. name과 label 한쌍으로 이루어져야함. [ { name : '' , label : '' } ... ] name이 Array인 경우 ex.조회시작일,종료일 구분자를 넣을 수 있음. default구분자는'~' [ { name : ['', ''], label : '', separator : '~'}, ... ]
 * @param {object} json 값 목록. key를 첫번째 파라미터인 조회조건(searchInfo)의 name과 맞춰야함 { key : value, ... }
 * @param {array} exception 예외 항목 지정. 배열(['', '']) 혹은 문자열('')
 * @param {array} noLabel 라벨 없는 항목 지정. 배열(['', '']) 혹은 문자열('')
 * @returns
 */
export const makeExcelSubtitle = (searchInfo, json, exception, noLabel) => {
  let searchCondition = '';

  //예외항목때문에 forEach 자체 index를 사용하지 않고 따로 선언해서 사용
  let index = 0;

  searchInfo.forEach((value) => {
    //예외항목 건너뛰기
    if (exception) {
      if (typeof exception === 'object') {
        //배열일결우
        if (exception instanceof Array) {
          if (value.name instanceof Array) {
            let result = false;
            value.name?.forEach((innerValue) => {
              if (exception.includes(innerValue)) {
                result = true;
              }
            });
            if (result) {
              return;
            }
          } else {
            if (exception.includes(value.name)) {
              return;
            }
          }
          //객체일경우
        } else {
        }
        //문자열일경우
      } else {
        if (exception === value.name) {
          return;
        }
      }
    }

    //5번째마다 줄바꿈
    if (index !== 0) {
      if ((index + 1) % 4 === 1) {
        searchCondition += `\n`;
      } else {
        searchCondition += `   `;
      }
    }

    //범위 항목
    if (value.name instanceof Array) {
      if (!isNoLabel(noLabel, value)) {
        searchCondition += `${value.label} :`;
      }
      if (json[value.name[0]] === '당일분' || json[value.name[0]] === '당월분') {
        //세출 자금배정내역(당일분, 당월분)
        searchCondition += `${json[value.name[1]]}(${json[value.name[0]]})`;
      } else {
        value.name?.forEach((innerValue, innerIndex) => {
          searchCondition += json[innerValue];
          if (innerIndex === 0) {
            const separator = innerValue?.seperator ?? '~';
            searchCondition += separator;
          }
        });
      }
    } else {
      if (!isNoLabel(noLabel, value)) {
        searchCondition += `${value.label} :${json[value.name]}`;
      } else {
        searchCondition += `${json[value.name]}`;
      }
    }
    console.log(searchCondition);
    index++;
  });

  return searchCondition;
};

const isNoLabel = (noLabel, value) => {
  if (noLabel) {
    if (typeof noLabel === 'object') {
      //배열일결우
      if (noLabel instanceof Array) {
        if (value.name instanceof Array) {
          let result = false;
          value.name?.forEach((innerValue) => {
            if (noLabel.includes(innerValue)) {
              result = true;
            }
          });
          return result;
        } else {
          if (noLabel.includes(value.name)) {
            return true;
          }
        }

        //객체일경우
      } else {
      }
      //문자열일경우
    } else {
      if (noLabel === value.name) {
        return true;
      }
    }
  }

  return false;
};

// 부서 콤보 권한 설정
export const authSetting = (aut_tp_cd, user_tp_cd, dept_cd, br_cd) => {
  const searchDeptParams = {};
  if (aut_tp_cd.substring(0, 1) === '3') {
    searchDeptParams.dept_cd = dept_cd;
  } else {
    if (user_tp_cd === 'WL0001' || user_tp_cd === 'WL0002' || user_tp_cd === 'WL0003' || user_tp_cd === 'WL1000') {
      searchDeptParams.br_cd = '';
    } else {
      searchDeptParams.br_cd = br_cd;
    }
  }
  return searchDeptParams;
};

export const escapeChars = (str) => {
  if (typeof str === 'string') {
    return (str = str
      .replaceAll('&', '&amp;')
      .replaceAll('<', '&lt;')
      .replaceAll('>', '&gt;')
      .replaceAll('>', '&gt;')
      .replaceAll('"', '&quot;')
      .replaceAll("'", '&#39')
      .replaceAll(' ', '&nbsp;'));
  }
  return;
};
