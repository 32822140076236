import instance from '../../common/config/api';

/**
 * 제로페이(관리자) - 사용자 이력 조회
 * @param {object} params
 * @returns
 */

export const fetchZpbUserHstList = (params) =>
  instance.post(`${process.env.REACT_APP_SERVER_URL}/scq/scq/zpb/0002/selectUserHst`, params);
