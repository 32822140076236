import instance from '../../common/config/api';

/**
 * 보고서 결재진행 상태 조회
 * @param {object} params
 * @returns
 */
export const fetchAppvPrg = (params) =>
  instance.post(`${process.env.REACT_APP_SERVER_URL}/scq/scq/rpt/0001/selectAppvPrg`, params);

/**
 * 보고서 결재진행 상태 조회 (구세 세입 월계표 전용)
 * @param {object} params
 * @returns
 */
export const fetchAppvPrgGuse = (params) =>
  instance.post(`${process.env.REACT_APP_SERVER_URL}/scq/scq/rpt/0001/selectAppvPrgGuse`, params);
