import styled, { css } from 'styled-components';
import React from 'react';
import { dataFormat } from '../../common/utils/utils';

const WOOccurrenceItem = ({ item, isActive, width, onClick, $user, type }) => {
  const handleClick = () => {
    onClick && onClick(item);
  };
  return (
    <BoxWrap
      type="button"
      className={`occurrence-item ${isActive ? 'active' : ''}`}
      $width={width}
      key={item.id}
      onClick={handleClick}>
      <Container user={$user} $type={type}>
        <TitleWrap user={$user} $type={type}>
          <span className={`count block text-center ${item.severity}`}>
            {item.count >= 10 ? dataFormat.number(item.count) : `0${item.count}`}건
          </span>
          <span className="title block text-center">{item.title}</span>
        </TitleWrap>
        {/* <LocationWrap className="location" $isOpen={isOpen}> */}
        {item.detailList && item.detailList.length > 0 && $user === 1 && (
          <LocationWrap className="location">
            {item.detailList.map((detail) => (
              <li className="location__item" key={detail.id}>
                <span className="title">{detail.location}</span>
                <span className="count">{detail.count}건</span>
              </li>
            ))}
          </LocationWrap>
        )}
      </Container>
    </BoxWrap>
  );
};

export default WOOccurrenceItem;

const BoxWrap = styled.button`
  display: flex;
  flex-direction: column;
  width: 100%;
  height: 100%;
  border-radius: 12px;
  background: rgba(255, 255, 255, 0.05);

  &.active {
    border: 1px solid transparent;
    border-radius: 12px;
    background-image: linear-gradient(#2d3658, #2d3658), linear-gradient(343deg, #b0ebcc -1.81%, #99a3ff 46.2%);
    background-origin: border-box;
    background-clip: content-box, border-box;
  }

  color: #fff;
`;

const Container = styled.div`
  width: 100%;
  height: 100%;
  display: flex;
  padding: 16px 12px;
  ${(props) =>
    props.$type === 'type01' &&
    css`
      padding: unset;
    `}
  flex-direction: column;
  justify-content: ${(props) => (props.user === 1 ? 'space-between' : 'center')};
`;

const TitleWrap = styled.div`
  width: 100%;
  .title {
    font-size: 14px;
    font-weight: 400;
    ${(props) =>
      props.user !== 1 &&
      props.$type !== 'type01' &&
      css`
        margin-top: 8px;
      `}
  }
  .count {
    font-size: 20px;
    font-weight: 700;

    &.caution {
      color: #fab530;
    }
    &.advisory {
      color: #ff8f5f;
    }
    &.warning {
      color: #f96162;
    }
  }
`;

const LocationWrap = styled.ul`
  width: 100%;
  .location {
    &__item {
      display: flex;
      justify-content: space-between;
      align-items: center;
      padding: 2px 0;
      .title,
      .count {
        font-size: 12px;
        font-weight: 400;
        display: block;
      }
      .title {
        padding-right: 10px;
      }
    }
  }

  ${(props) =>
    props.$isOpen &&
    css`
      /* position: relative; */
      /* display: flex; */
      /* justify-content: space-between; */
      /* flex-wrap: wrap; */
      /* padding: 24px 32px; */
    `}
`;
