import { SUCCESS, REQUEST, FAILURE } from '../../../common/utils/action-type-utils';
import { fetchMenuBySite, saveMenuBySite } from '../../../lib/sys/SYS0307M01.api';

// actions
const FETCH_MENU_BY_SITE_LIST = 'SYS0307M01/FETCH_MENU_BY_SITE_LIST';
const SAVE_MENU_BY_SITE_LIST = 'SYS0307M01/SAVE_MENU_BY_SITE_LIST';

// initial state
const initialState = {
  loading: false,
  success: true,
  message: '',
  list: [],
  totalCount: 0,
  updating: false,
  updateSuccess: false,
  resultCnt: -1,
};

// reducers
export default function SYS0307M01(state = initialState, action) {
  switch (action.type) {
    case SUCCESS(FETCH_MENU_BY_SITE_LIST): // 조회 성공
      return {
        ...state,
        success: true,
        loading: false,
        list: action.payload.data?.main,
        totalCount: action.payload?.data?.mainCnt,
        updateSuccess: false,
      };
    case REQUEST(FETCH_MENU_BY_SITE_LIST): // 조회 요청
      return {
        ...state,
        message: '',
        loading: true,
        success: false,
        updateSuccess: false,
      };
    case FAILURE(FETCH_MENU_BY_SITE_LIST): // 조회실패
      return {
        ...initialState,
        loading: false,
        success: false,
        message: action.payload.response?.data?.message,
        updateSuccess: false,
      };
    case SUCCESS(SAVE_MENU_BY_SITE_LIST): // 저장 성공
      return {
        ...state,
        updateSuccess: true,
        updating: false,
        resultCnt: action.payload.data.resultCnt,
      };
    case REQUEST(SAVE_MENU_BY_SITE_LIST): // 저장 요청
      return {
        ...state,
        message: '',
        updating: true,
        updateSuccess: false,
      };
    case FAILURE(SAVE_MENU_BY_SITE_LIST): // 저장 실패
      return {
        ...initialState,
        updating: false,
        updateSuccess: false,
        resultCnt: -1,
        message: action.payload.response?.data?.message,
      };
    default:
      return state;
  }
}

/**
 * 사이트별 메뉴 조회
 */
export const selectMenuBySiteList = (searchParams) => {
  return {
    type: FETCH_MENU_BY_SITE_LIST,
    payload: fetchMenuBySite(searchParams),
  };
};

/**
 * 사이트별 메뉴 갱신
 */
export const saveListMenuBySiteList = (dtos) => {
  return {
    type: SAVE_MENU_BY_SITE_LIST,
    payload: saveMenuBySite(dtos),
  };
};
