import { SUCCESS, REQUEST, FAILURE } from '../../../common/utils/action-type-utils';
import { fetchNoticeForMain } from '../../../lib/sys/NtMng.api';

// actions
const FETCH_NOTICE_FOR_MAIN = 'noticeState/FETCH_NOTICE_FOR_MAIN';

// initial state
const initialState = {
  loading: false,
  success: false,
  message: '',
  list: [],
  totalCount: 0,
};

// reducers
export default function noticeState(state = initialState, action) {
  switch (action.type) {
    case SUCCESS(FETCH_NOTICE_FOR_MAIN): // 조회 성공
      return {
        ...state,
        success: true,
        loading: false,
        list: action.payload.data?.main,
        totalCount: action.payload?.data.main_cnt,
      };
    case REQUEST(FETCH_NOTICE_FOR_MAIN): // 조회 요청
      return {
        ...state,
        loading: true,
        success: false,
        message: '',
      };
    case FAILURE(FETCH_NOTICE_FOR_MAIN): // 조회실패
      return {
        ...initialState,
        loading: false,
        success: false,
        message: action.payload.response?.data?.message,
      };
    default:
      return state;
  }
}

/**
 * 업무선택 메뉴조회
 */
export const selectNoticeForMain = (searchParams) => {
  return {
    type: FETCH_NOTICE_FOR_MAIN,
    payload: fetchNoticeForMain(searchParams),
  };
};
