import { SUCCESS, REQUEST, FAILURE } from '../../../common/utils/action-type-utils';
import { fetchAppvCnt, fetchPapsCnt } from '../../../lib/cmm/person.api';

// actions
const FETCH_APPV_CNT = 'noticeState/FETCH_APPV_CNT';
const FETCH_PAPS_CNT = 'noticeState/FETCH_PAPS_CNT';

// initial state
const initialState = {
  loading: false,
  success: false,
  message: '',
  appvCnt: 0,
  docCnt: 0,
};

// reducers
export default function personState(state = initialState, action) {
  switch (action.type) {
    case SUCCESS(FETCH_APPV_CNT): // 조회 성공
      return {
        ...state,
        success: true,
        loading: false,
        appvCnt: action.payload.data?.doc_cnt,
      };
    case REQUEST(FETCH_APPV_CNT): // 조회 요청
      return {
        ...state,
        loading: true,
        success: false,
        message: '',
      };
    case FAILURE(FETCH_APPV_CNT): // 조회실패
      return {
        ...initialState,
        loading: false,
        success: false,
        message: action.payload.response?.data?.message,
      };
    case SUCCESS(FETCH_PAPS_CNT): // 조회 성공
      return {
        ...state,
        success: true,
        loading: false,
        docCnt: action.payload.data?.doc_cnt,
        appvCnt: action.payload.data?.appv_cnt,
      };
    case REQUEST(FETCH_PAPS_CNT): // 조회 요청
      return {
        ...state,
        loading: true,
        success: false,
        message: '',
      };
    case FAILURE(FETCH_PAPS_CNT): // 조회실패
      return {
        ...initialState,
        loading: false,
        success: false,
        message: action.payload.response?.data?.message,
      };
    default:
      return state;
  }
}

/**
 * 결재 건수 조회
 */
export const selectAppvCnt = () => {
  return {
    type: FETCH_APPV_CNT,
    payload: fetchAppvCnt({}),
  };
};

/**
 * 문서 건수 조회
 */
export const selectPapsCnt = () => {
  return {
    type: FETCH_PAPS_CNT,
    payload: fetchPapsCnt({}),
  };
};
