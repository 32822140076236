import instance from '../../common/config/api';

/**
 * 시스템관리 - 사용자 - 부서별 사용량 집계 
 * @param {object} params
 * @returns
 */

export const fetchUserLogSt = (params) =>
  instance.post(`${process.env.REACT_APP_SERVER_URL}/scq/scq/sys/0002/selectUserLogSt`, params);
export const fetchUserLogStDtl = (params) =>
  instance.post(`${process.env.REACT_APP_SERVER_URL}/scq/scq/sys/0002/selectUserLogStDtl`, params);

