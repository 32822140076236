import instance from '../../common/config/api';

const PREFIX = `${process.env.REACT_APP_SERVER_URL}/scq/scq/fxp/0001`;

/**
 * 운용상품 - 공금성 정기예금 현황조회
 * @param {object} params
 * @returns
 */

export const fetchPmnyLstmFxpdPts = (params) => instance.post(`${PREFIX}/selectPmnyLstmFxpdPts`, params);

/**
 * 운용상품 - 공금성정기예금 계좌조회
 * @param {object} params
 * @returns
 */

export const fetchActNo = (params) => instance.post(`${PREFIX}/selectActNo`, params);

/**
 * 운용상품 - 계좌명 조립
 * @param {object} params
 * @returns
 */

export const fetchAcnoCst = (params) => instance.post(`${PREFIX}/selectAcnoCst`, params);

/**
 * 운용상품 - 해지일자 조립
 * @param {object} params
 * @returns
 */

export const fetchCancDtCst = (params) => instance.post(`${PREFIX}/selectCancDtCst`, params);

/**
 * 운용상품 - 해지계좌 조립
 * @param {object} params
 * @returns
 */

export const fetchCancAcnoCst = (params) => instance.post(`${PREFIX}/selectCancAcnoCst`, params);

/**
 * 운용상품 - 해지예상 조회
 * @param {object} params
 * @returns
 */

export const fetchCancFrcsAndFrcsInt = (params) => instance.post(`${PREFIX}/selectCancFrcsAndFrcsInt`, params);

/**
 * 운용상품 - 전체명세조회
 * @param {object} params
 * @returns
 */

export const fetchAllHis = (params) => instance.post(`${PREFIX}/selectAllHis`, params);

/**
 * 운용상품 - 이자지급 명세 조회
 * @param {object} params
 * @returns
 */

export const fetchItpyHis = (params) => instance.post(`${PREFIX}/selectItpyHis`, params);

/**
 * 운용상품 - 이자지급현황
 * @param {object} params
 * @returns
 */

export const fetchItpyPts = (params) => instance.post(`${PREFIX}/selectItpyPts`, params);

/**
 * 운용상품 - 회계별 만기현황
 * @param {object} params
 * @returns
 */

export const fetchAcctXprPts = (params) => instance.post(`${PREFIX}/selectAcctXprPts`, params);

/**
 * 운용상품 - 만기도래현황
 * @param {object} params
 * @returns
 */

export const fetchXpradPts = (params) => instance.post(`${PREFIX}/selectXpradPts`, params);

/**
 * 운용상품 - 정기예금현황 보고서
 * @param {object} params
 * @returns
 */

export const fetchFxpdPtsRpt = (params) => instance.post(`${PREFIX}/selectFxpdPtsRpt`, params);

/**
 * 운용상품 - 서울시정기예금 신규/해지 전체조회
 * @param {object} params
 * @returns
 */

export const fetchTXOUFpdpsOCTHAll = (params) => instance.post(`${PREFIX}/selectTXOUFpdpsOCTHAll`, params);

/**
 * 운용상품 - 공기업 정기예금 신규/해지 전체조회
 * @param {object} params
 * @returns
 */

export const fetchTXOUFpdpsOCTH = (params) => instance.post(`${PREFIX}/selectTXOUFpdpsOCTH`, params);
