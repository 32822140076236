import { fetchExpdCmdTrdn, fetchExpdCmdTrdnDtl } from '../../../lib/ebk/ExpdCmdTrdnInq.api';
import { SUCCESS, REQUEST, FAILURE } from '../../../common/utils/action-type-utils';
import dayjs from 'dayjs';
import { fetchCommonComboList } from '../../../lib/cmm/combo.api';

// actions
const FETCH_EXPD_CMD_TRDN = 'ExpdCmdTrdnInq/FETCH_EXPD_CMD_TRDN';
const FETCH_EXPD_CMD_TRDN_DTL = 'ExpdCmdTrdnInq/FETCH_EXPD_CMD_TRDN_DTL';

const FETCH_IST_INFO_LIST = 'TrsfCstd/FETCH_IST_INFO_LIST';
const FETCH_DEPT_INFO_LIST = 'TrsfCstd/FETCH_DEPT_INFO_LIST';

const RESET_INITIAL_STATE = 'TrsfCstd/RESET_INITIAL_STATE';
const SAVE_SEARCH_FORM_VALUES = 'TrsfCstd/SAVE_SEARCH_FORM_VALUES';

const CLEAR_MESSAGE = 'ExpdCmdTrdnInq/CLEAR_MESSAGE';
const SET_BEFORE_SEARCH_PARAM = 'TrsfCstd/SET_BEFORE_SEARCH_PARAM';

export const initFormValues = {
  cmd_dt: dayjs().format('YYYYMMDD'),
  cmd_dt_start: dayjs(),
  cmd_dt_end: dayjs(),
  cus_ist_cd: 'all',
  cus_dept_cd: 'all', //부서명
  fnd_dis: 'all',
  cmd_dt_dtl: '',
  cmd_no_dtl: '',
  trnDis: '0',
};

// initial state
const initialState = {
  loading: false,
  loading2: false,
  success: false,
  message: '',
  success2: false,
  message2: '',
  list: [],
  list2: [],
  totalCount: 0,
  totalCount2: 0,
  beforeSearchParam: {},

  //콤보 - 기관명 005
  istInfoSuccess: false,
  istInfoLoading: false,
  istInfoList: [],

  //콤보 - 부서명 158
  deptInfoSuccess: false,
  deptInfoLoading: false,
  deptInfoList: [],

  //콤보 - 회계연도 025
  accYrSuccess: false,
  accYrLoading: false,
  accYrList: [],

  // 검색조건
  searchFormValues: {
    init: true,
    ...initFormValues,
  },
};

// reducers
export default function ExpdCmdTrdnInq(state = initialState, action) {
  switch (action.type) {
    case SUCCESS(FETCH_EXPD_CMD_TRDN): // 조회 성공
      return {
        ...state,
        success: true,
        loading: false,
        list: action.payload.data?.main,
        totalCount: action.payload?.mainCnt,
      };
    case REQUEST(FETCH_EXPD_CMD_TRDN): // 조회 요청
      return {
        ...state,
        loading: true,
      };
    case FAILURE(FETCH_EXPD_CMD_TRDN): // 조회실패
      return {
        ...state,
        list: [],
        loading: false,
        success: false,
        message: action.payload.response?.data?.message,
      };
    case SUCCESS(FETCH_EXPD_CMD_TRDN_DTL): // 조회 성공
      return {
        ...state,
        success2: true,
        loading2: false,
        list2: action.payload.data?.sub,
        totalCount2: action.payload?.data?.totalCnt,
      };
    case REQUEST(FETCH_EXPD_CMD_TRDN_DTL): // 조회 요청
      return {
        ...state,
        loading2: true,
      };
    case FAILURE(FETCH_EXPD_CMD_TRDN_DTL): // 조회실패
      return {
        ...state,
        list2: [],
        totalCount2: [],
        loading2: false,
        success2: false,
        message2: action.payload.response?.data?.message,
      };
    // 기관명
    case SUCCESS(FETCH_IST_INFO_LIST): // 조회 성공
      return {
        ...state,
        istInfoSuccess: true,
        istInfoLoading: false,
        istInfoList: action.payload.data?.subDto,
      };
    case REQUEST(FETCH_IST_INFO_LIST): // 조회 요청
      return {
        ...state,
        istInfoLoading: true,
        istInfoSuccess: false,
      };
    case FAILURE(FETCH_IST_INFO_LIST): // 조회실패
      return {
        istInfoLoading: false,
        istInfoSuccess: false,
        istInfoList: [],
      };
    // 부서명
    case SUCCESS(FETCH_DEPT_INFO_LIST): // 조회 성공
      return {
        ...state,
        deptInfoSuccess: true,
        deptInfoLoading: false,
        deptInfoList: action.payload.data?.subDto,
      };
    case REQUEST(FETCH_DEPT_INFO_LIST): // 조회 요청
      return {
        ...state,
        deptInfoLoading: true,
        deptInfoSuccess: false,
      };
    case FAILURE(FETCH_DEPT_INFO_LIST): // 조회실패
      return {
        ...state,
        deptInfoLoading: false,
        deptInfoSuccess: false,
        deptInfoList: [],
      };
    case SAVE_SEARCH_FORM_VALUES:
      return {
        ...state,
        istInfoSuccess: false,
        istInfoLoading: false,
        deptInfoSuccess: false,
        deptInfoLoading: false,

        searchFormValues: action.payload,
      };
    case RESET_INITIAL_STATE:
      return initialState;
    case SET_BEFORE_SEARCH_PARAM:
      return {
        ...state,
        beforeSearchParam: action.payload,
      };
    case CLEAR_MESSAGE:
      return {
        ...state,
        message: '',
        message2: '',
        success: '',
        success2: '',
      };
    default:
      return state;
  }
}

/**
 * 지출명령 반려조회
 */
export const selectExpdCmdTrdn = (searchParams) => {
  return {
    type: FETCH_EXPD_CMD_TRDN,
    payload: fetchExpdCmdTrdn(searchParams),
  };
};

/**
 * 지출명령 반려조회
 */
export const selectExpdCmdTrdnDtl = (searchParams) => {
  return {
    type: FETCH_EXPD_CMD_TRDN_DTL,
    payload: fetchExpdCmdTrdnDtl(searchParams),
  };
};

/**
 * 메시지초기화
 */
export const clearMessage = () => {
  return {
    type: CLEAR_MESSAGE,
  };
};

/**
 *
 */
export const setBeforeSearchParam = (searchParams) => {
  return {
    type: SET_BEFORE_SEARCH_PARAM,
    payload: searchParams,
  };
};

//기관명 콤보 조회
export const selectIstInfoList = (searchParam) => {
  return {
    type: FETCH_IST_INFO_LIST,
    payload: fetchCommonComboList({ slt_box_no: '005', ...searchParam }),
  };
};

//부서명 콤보 조회
export const selectDeptInfoList = (searchParam) => {
  return {
    type: FETCH_DEPT_INFO_LIST,
    payload: fetchCommonComboList({ slt_box_no: '158', ...searchParam }),
  };
};

/**
 * 조회조건 저장
 */
export const saveSearchFormValues = (searchFormValues) => {
  return {
    type: SAVE_SEARCH_FORM_VALUES,
    payload: {
      ...searchFormValues,
      init: false,
    },
  };
};

/**
 * 상태초기화
 */
export const resetInitialState = () => {
  return {
    type: RESET_INITIAL_STATE,
  };
};
