import instance from '../../common/config/api';

/**
 * 일상경비 - 일상경비 수입/지출월계표 및 잔액증명서 조회
 * @param {object} params
 * @returns
 */

export const fetchEvdXpnImpExpdMmSht = (params) =>
  instance.post(`${process.env.REACT_APP_SERVER_URL}/scq/scq/ils/0002/selectEvdXpnImpExpdMmSht`, params);

/**
 * 일상경비 - 확인여부 저장
 * @param {object} params
 * @returns
 */

export const saveCnfYn = (params) =>
  instance.post(`${process.env.REACT_APP_SERVER_URL}/scq/scq/ils/0002/saveCnfYn`, params);
