import React from 'react';
import styled, { css } from 'styled-components';
import { disabledIconStyle } from '../../styles/common.styled';

// type type = 'button' | 'submit'
// type VariantType = 'text' | 'primary' |'secondary' | 'outlined' | 'fillOutlined' |'notice' | 'gradient' | 'help' | 'box' | 'personal' | 'expand'
// expandType : 확대하기, 축소하기만 적용
// type size = 'sm' | 'md' | 'lg' ('primary' |'secondary' | 'outlined' 용도)
// type severityType = 'error' | 'warning' | info | 'success'

const WOButton = ({
  type,
  variant,
  size,
  width,
  severity,
  disabled,
  startIcon,
  endIcon,
  children,
  onClick,
  color,
  customClass,
}) => {
  return (
    <Button
      type={type || 'button'}
      className={customClass ? `btn ${customClass}` : 'btn'}
      onClick={onClick}
      disabled={!!disabled}
      $variant={variant || (!severity && 'primary')}
      $size={size || 'sm'}
      $severity={severity || ''}
      $color={color}
      $width={width}
      $customClass={customClass}>
      {startIcon && <span className="icon-wrap start">{startIcon}</span>}
      {children}
      {endIcon && <span className="icon-wrap end">{endIcon}</span>}
    </Button>
  );
};

export default WOButton;

const iconDisabledStyle = css`
  &:disabled {
    .icon-wrap .icon {
      ${disabledIconStyle}
    }
  }
`;

const iconIntervalStyle = css`
  .icon-wrap {
    &.start {
      margin-left: -4px;
    }
    &.end {
      margin-right: -4px;
    }
  }
`;

const Button = styled.button`
  display: flex;
  align-items: center;
  justify-content: center;
  font-weight: 700;
  border-radius: 4px;
  // width : fit-content;
  width: ${(props) => (props.$width ? props.$width : 'fit-content')};

  // default size
  font-size: 12px;
  height: 24px;
  padding: 2px 8px;

  // default variant
  background-color: #0e213f;
  color: #fff;

  & + .btn {
    margin-left: 10px;
  }

  .icon-wrap {
    display: flex;
    align-items: center;
    &.start {
      padding-right: 4px;
      margin-left: -2px; // 4, 4
    }
    &.end {
      padding-left: 4px;
      margin-right: -2px;
    }
  }

  // variant
  ${(props) =>
    props.$variant === 'primary' &&
    css`
      &:hover {
        background-color: #263752;
      }

      &:disabled {
        background-color: #3c4b63;
        color: #86909f;
        .icon-wrap .icon {
          filter: brightness(0) saturate(100%) invert(61%) sepia(8%) saturate(527%) hue-rotate(177deg) brightness(91%)
            contrast(95%);
        }
      }
    `}

  ${(props) =>
    props.$customClass === 'disabled' &&
    css`
      &:hover {
        background-color: #263752;
      }

      &.disabled {
        background-color: #3c4b63;
        color: #86909f;
        .icon-wrap .icon {
          filter: brightness(0) saturate(100%) invert(61%) sepia(8%) saturate(527%) hue-rotate(177deg) brightness(91%)
            contrast(95%);
        }
      }
    `}
    
  ${(props) =>
    props.$variant === 'secondary' &&
    css`
      background-color: #e5e5ed;
      color: #263752;

      &:hover {
        background-color: #ededf3;
      }

      &:disabled {
        background-color: #e5e5ed;
        color: #9ea6b2;
      }

      ${iconDisabledStyle}
    `}

  ${(props) =>
    props.$variant === 'outlined' &&
    css`
      background-color: transparent;
      color: #263752;
      border: 1px solid #d7d7de;

      &:hover {
        background-color: #f5f6fa;
      }

      &:disabled {
        background-color: transparent;
        color: #9ea6b2;
      }

      ${iconDisabledStyle}
    `}

  // size
  ${(props) =>
    props.$size === 'md' &&
    css`
      font-size: 14px;
      min-width: 80px;
      height: 32px;
      padding: 4px 12px;
      ${iconIntervalStyle}
    `}
  ${(props) =>
    props.$size === 'lg' &&
    css`
      font-size: 16px;
      min-width: 116px;
      height: 40px;
      padding: 8px 16px;
      ${iconIntervalStyle}
    `}

  // variant (TODO: 디자인 확정후 수정, hover 임시)
  ${(props) =>
    props.$variant === 'text' &&
    css`
      background-color: transparent;
    `}
  ${(props) =>
    props.$variant === 'notice' &&
    css`
      width: 140px;
      height: 48px;
      padding: 16px 28px 16px 32px;
      font-size: 16px;
      border-radius: 80px;
      background-color: #f6f6fa;
      color: #333;

      &:hover {
        background-color: #f6f6fa;
      }
      .icon-wrap {
        &.end {
          margin-right: 0;
          padding-left: 10px;
        }
      }

      ${iconDisabledStyle}
    `}
  ${(props) =>
    props.$variant === 'gradient' &&
    css`
      height: 32px;
      padding: 4px 16px;
      font-size: 14px;
      background: linear-gradient(343deg, #b0ebcc -1.81%, #99a3ff 46.2%);
      color: #fff;
    `}
  ${(props) =>
    props.$variant === 'help' &&
    css`
      min-width: 53px;
      height: 24px;
      padding: 4px 8px;
      font-size: 14px;
      border: 1px solid #3c4b63;
      background-color: transparent;
      color: #86909f;

      &:hover {
        background-color: transparent;
      }
    `}
  ${(props) =>
    props.$variant === 'fillOutlined' &&
    css`
      height: 32px;
      padding: 4px 12px;
      font-size: 12px;
      border: 1px solid #e5e5ed;
      background-color: #f5f6fa;
      color: #3c4b63;

      &:hover {
        background-color: #f5f6fa;
      }
    `}
  ${(props) =>
    props.$variant === 'box' &&
    css`
      height: 40px;
      padding: 12px 16px;
      border-radius: 12px;
      box-shadow: 0px 8px 12px 0px rgba(0, 0, 0, 0.08);
      background-color: #fff;

      color: #0e213f;
      font-size: 16px;
      font-weight: 500;

      .icon-wrap {
        &.start {
          padding-right: 6px;
          margin-left: 0;
        }
      }
    `}

  ${(props) =>
    props.$variant === 'personal' &&
    css`
      height: 22px;
      padding: 2px 8px;
      color: #c9c9d0;

      font-size: 12px;
      font-weight: 700;
      border-radius: 4px;
    `}

    ${(props) =>
    props.$variant === 'expand' &&
    css`
      font-weight: 400;
      background: var(--Grad-BG-02);
    `}

    // severityType
    ${(props) =>
    props.$severity === 'error' &&
    css`
      min-width: 40px;
      width: 40px;
      height: 24px;
      padding: 4px;
      background-color: transparent;
      font-size: 12px;
      font-weight: 600;
      color: #ff5d5d;
      border: 1px solid rgba(14, 33, 63, 0.1);
      border-radius: 12px;
    `}
    
    // color
    ${(props) =>
    props.$color &&
    css`
      color: ${props.$color};
    `}

    &.other-btn {
    line-height: 1;
    background: #fff;
    padding: 2px 8px;
    text-align: justify;
  }
`;
