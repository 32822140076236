import instance from '../../common/config/api';

/**
 * 시스템공통 - 법인카드 사업자관리
 * @param {object} params
 * @returns
 */

export const fetchCardBiz = (params) =>
  instance.post(`${process.env.REACT_APP_SERVER_URL}/scq/scq/sys/0001/selectCardBiz`, params);

export const insertCardBiz = (dtos) =>
  instance.post(`${process.env.REACT_APP_SERVER_URL}/scq/scq/sys/0001/insertCardBiz`, {
    subDto: dtos,
  });
