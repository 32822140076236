import { SUCCESS, REQUEST, FAILURE } from '../../../common/utils/action-type-utils';
import { fetchZpbUserHstList } from '../../../lib/zpb/zpb0206v01.api';
import dayjs from 'dayjs';

// actions
const FETCH_USERHST = 'zpb0206v01/FETCH_USERHST';
const RESET_STATE = 'zpb0206v01/RESET_STATE';
const SAVE_SEARCH_FORM_VALUES = 'zpb0206v01/SAVE_SEARCH_FORM_VALUES';

export const initFormValues = {
  sdt_dt: dayjs().format('YYYY-MM-DD'), // 조회 시작일
  edt_dt: dayjs().format('YYYY-MM-DD'), // 조회 종료일
  user_id: '',
  //0516 정은미 / 기관공금고객부 요청으로 기존 사용자해지 -> 전체로 요청함.
  gubun: '',
};

// initial state
const initialState = {
  loading: false,
  success: false,
  message: '',
  list: [],
  totalCount: 0,
  gubunList: [
    { id: '', label: '전체' },
    { id: '1', label: '사용자 해지' },
    { id: '2', label: '사용자 해지 취소' },
    { id: '3', label: '사용자 정보 비밀번호 변경' },
    { id: '4', label: '사용자 정보 변경 처리' },
  ],
  searchFormValues: {
    init: true,
    ...initFormValues,
  },
};

// reducers
export default function zpb0206v01(state = initialState, action) {
  switch (action.type) {
    case SUCCESS(FETCH_USERHST): // 조회 성공
      return {
        ...state,
        success: true,
        loading: false,
        list: action.payload.data?.subDto,
        totalCount: action.payload?.subDtoCnt,
      };
    case REQUEST(FETCH_USERHST): // 조회 요청
      return {
        ...state,
        loading: true,
        success: false,
      };
    case FAILURE(FETCH_USERHST): // 조회실패
      return {
        ...initialState,
        loading: false,
        success: false,
        message: action.payload.response?.data?.message,
      };
    case SAVE_SEARCH_FORM_VALUES:
      return {
        ...state,
        // 콤보 - 기관
        searchFormValues: action.payload,
      };
    case RESET_STATE:
      return initialState;
    default:
      return state;
  }
}

/**
 * 제로페이 - // 사용자 이력 조회
 */
export const selectUserHstList = (searchParams) => {
  return {
    type: FETCH_USERHST,
    payload: fetchZpbUserHstList(searchParams),
  };
};
/**
 * 조회조건 저장
 */
export const saveSearchFormValues = (searchFormValues) => {
  return {
    type: SAVE_SEARCH_FORM_VALUES,
    payload: {
      ...searchFormValues,
      init: false,
    },
  };
};
/**
 * 초기화
 */
export const resetZpb0206v01 = () => {
  return {
    type: RESET_STATE,
  };
};
