import { SUCCESS, REQUEST, FAILURE } from '../../../common/utils/action-type-utils';
import {
  fetchEtcPrcPrtsRcncSum,
  insertCitSfWdrReqTrn,
  insertCitSfWdrReqTrnAbv10Full,
  updateCitSfWdrReqTrn,
} from '../../../lib/etc/etc0405m01.api';
import dayjs from 'dayjs';

// actions
const FETCH_PRC_PRTS_RCNC_SUM = 'etc0405m01/FETCH_PRC_PRTS_RCNC_SUM';
const INSERT_CIT_SF_WDR_REQ_TRN = 'etc0405m01/INSERT_CIT_SF_WDR_REQ_TRN';
const INSERT10_CIT_SF_WDR_REQ_TRN = 'etc0405m01/INSERT10_CIT_SF_WDR_REQ_TRN';
const UPDATE_CIT_SF_WDR_REQ_TRN = 'etc0405m01/UPDATE_CIT_SF_WDR_REQ_TRN';

const SAVE_SEARCH_FORM_VALUES = 'etc0405m01/SAVE_SEARCH_FORM_VALUES';
const RESET_STATE = 'etc0405m01/RESET_STATE';

export const initFormValues = {
  sdt: dayjs().format('YYYY-MM-DD'), // 기간
  gb: '',
  br_cd: '',
};

// initial state
const initialState = {
  loading: false,
  success: false,
  message: '',
  detail: null,
  insertLoading: false, // 등록
  insertSuccess: false, // 등록
  insertCount: 0, // 등록
  insert10Loading: false, // 등록 - 10억 이상
  insert10Success: false, // 등록 - 10억 이상
  insert10Count: 0, // 등록 - 10억 이상
  updateLoading: false, // 등록
  updateSuccess: false, // 등록
  updateCount: 0, // 저장
  searchFormValues: {
    init: true,
    ...initFormValues,
  },
};

// reducers
export default function etcPrcPrtsRcncSum(state = initialState, action) {
  switch (action.type) {
    case SUCCESS(FETCH_PRC_PRTS_RCNC_SUM): // 조회 성공
      return {
        ...state,
        success: true,
        loading: false,
        detail: action.payload.data,
      };
    case REQUEST(FETCH_PRC_PRTS_RCNC_SUM): // 조회 요청
      return {
        ...state,
        loading: true,
        success: false,
      };
    case FAILURE(FETCH_PRC_PRTS_RCNC_SUM): // 조회 실패
      return {
        ...initialState,
        loading: false,
        success: false,
        message: action.payload.response?.data?.message,
      };
    case SUCCESS(INSERT_CIT_SF_WDR_REQ_TRN): // 등록 성공
      return {
        ...state,
        insertSuccess: true,
        insertLoading: false,
        insertCount: action.payload.data?.resultCnt,
      };
    case REQUEST(INSERT_CIT_SF_WDR_REQ_TRN): // 등록 요청
      return {
        ...state,
        insertLoading: true,
        insertSuccess: false,
      };
    case FAILURE(INSERT_CIT_SF_WDR_REQ_TRN): // 등록 실패
      return {
        ...initialState,
        insertLoading: false,
        insertSuccess: false,
        message: action.payload.response?.data?.message,
      };
    case SUCCESS(INSERT10_CIT_SF_WDR_REQ_TRN): // 10억 이상 등록 성공
      return {
        ...state,
        insert10Success: true,
        insert10Loading: false,
        insert10Count: action.payload.data?.resultCnt,
      };
    case REQUEST(INSERT10_CIT_SF_WDR_REQ_TRN): // 10억 이상 등록 요청
      return {
        ...state,
        insert10Loading: true,
        insert10Success: false,
      };
    case FAILURE(INSERT10_CIT_SF_WDR_REQ_TRN): // 10억 이상 등록 실패
      return {
        ...initialState,
        insert10Loading: false,
        insert10Success: false,
        message: action.payload.response?.data?.message,
      };
    case SUCCESS(UPDATE_CIT_SF_WDR_REQ_TRN): // 저장 성공
      return {
        ...state,
        updateSuccess: true,
        updateLoading: false,
        updateCount: action.payload.data?.resultCnt,
      };
    case REQUEST(UPDATE_CIT_SF_WDR_REQ_TRN): // 저장 요청
      return {
        ...state,
        updateLoading: true,
        updateSuccess: false,
      };
    case FAILURE(UPDATE_CIT_SF_WDR_REQ_TRN): // 저장 실패
      return {
        ...initialState,
        updateLoading: false,
        updateSuccess: false,
        message: action.payload.response?.data?.message,
      };
    case SAVE_SEARCH_FORM_VALUES:
      return {
        ...state,
        searchFormValues: action.payload,
      };
    case RESET_STATE:
      return initialState;
    default:
      return state;
  }
}

/**
 * 기타 - 서울페이플러스 - 처리내역조회 (관리자)
 */
export const selectEtcPrcPrtsRcncSumDetail = (searchParams) => {
  return {
    type: FETCH_PRC_PRTS_RCNC_SUM,
    payload: fetchEtcPrcPrtsRcncSum(searchParams),
  };
};

/**
 * 기타 - 서울페이플러스 - 처리내역조회 (관리자) 등록
 */
export const registerCitSfWdrReqTrn = (searchParams) => {
  return {
    type: INSERT_CIT_SF_WDR_REQ_TRN,
    payload: insertCitSfWdrReqTrn(searchParams),
  };
};

/**
 * 기타 - 서울페이플러스 - 처리내역조회 (관리자) 등록 - 10억 이상
 */
export const register10CitSfWdrReqTrn = (searchParams) => {
  return {
    type: INSERT10_CIT_SF_WDR_REQ_TRN,
    payload: insertCitSfWdrReqTrnAbv10Full(searchParams),
  };
};

/**
 * 기타 - 서울페이플러스 - 처리내역조회 (관리자) 저장
 */
export const updateCitSfWdrReqTrnItem = (searchParams) => {
  return {
    type: UPDATE_CIT_SF_WDR_REQ_TRN,
    payload: updateCitSfWdrReqTrn(searchParams),
  };
};

/**
 * 조회조건 저장
 */
export const saveSearchFormValues = (searchFormValues) => {
  return {
    type: SAVE_SEARCH_FORM_VALUES,
    payload: {
      ...searchFormValues,
      init: false,
    },
  };
};

/**
 * 처리내역(관리자)
 */
export const resetEtcPrcPrtsRcncSum = () => {
  return {
    type: RESET_STATE,
  };
};
