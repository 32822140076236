import { SUCCESS, REQUEST, FAILURE } from '../../../common/utils/action-type-utils';
import { fetchFdOpgHbokBkgList, fetchFdOpgHbokBkgGg } from '../../../lib/rpt/fdOpgHbokBkg.api';

// actions
const FETCH_FD_OPG_HBOK_BKG_LIST = 'fdOpgHbokBkg/FETCH_FD_OPG_HBOK_BKG_LIST';
const FETCH_FD_OPG_HBOK_BKG_GG = 'fdOpgHbokBkg/FETCH_FD_OPG_HBOK_BKG_GG';
const RESET_REPORT = 'fdOpgHbokBkg/RESET_REPORT';

// initial state
const initialState = {
  loading: false,
  success: false,
  message: '',
  list: [],
  totalCount: 0,
  common: {},
  head: {},
};

// reducers
export default function fdOpgHbokBkg(state = initialState, action) {
  switch (action.type) {
    case SUCCESS(FETCH_FD_OPG_HBOK_BKG_LIST): // 조회 성공
    case SUCCESS(FETCH_FD_OPG_HBOK_BKG_GG): // 기금 조회 성공
      return {
        ...state,
        success: true,
        loading: false,
        list: action.payload.data?.output_data,
        totalCount: action.payload?.data?.output_data_cnt,
        common: action.payload.data?.common,
        head: action.payload.data?.output_head,
      };
    case REQUEST(FETCH_FD_OPG_HBOK_BKG_LIST): // 조회 요청
    case REQUEST(FETCH_FD_OPG_HBOK_BKG_GG): // 기금 조회 요청
      return {
        ...state,
        loading: true,
        success: false,
        message: '',
      };
    case FAILURE(FETCH_FD_OPG_HBOK_BKG_LIST): // 조회실패
    case FAILURE(FETCH_FD_OPG_HBOK_BKG_GG): // 기금 조회실패
      return {
        ...initialState,
        loading: false,
        success: false,
        message: action.payload.response?.data?.message,
      };
    case RESET_REPORT:
      return initialState;
    default:
      return state;
  }
}

/**
 * 세출보고서 - 자금운용내역장 조회
 */
export const selectFdOpgHbokBkgList = (searchParams) => {
  return {
    type: FETCH_FD_OPG_HBOK_BKG_LIST,
    payload: fetchFdOpgHbokBkgList(searchParams),
  };
};

/**
 * 세출보고서 - 자금운용내역장(기금) 조회
 */
export const selectFdOpgHbokBkgGg = (searchParams) => {
  return {
    type: FETCH_FD_OPG_HBOK_BKG_GG,
    payload: fetchFdOpgHbokBkgGg(searchParams),
  };
};

/**
 * 보고서 초기화
 * @returns
 */
export const resetReport = () => {
  return {
    type: RESET_REPORT,
  };
};
