import React, { useCallback, useEffect, useMemo, useRef, useState } from 'react';
import { styled } from 'styled-components';
import WOButton from '../components/common/WOButton';
import MainFooter from '../components/layout/MainFooter';
import WelcomBox from '../views/login/WelcomBox';
import { HeaderWrap } from '../components/layout/MainHeader';
import * as S from '../styles/subPage.styled';
import WOCheckbox from '../components/common/WOCheckbox';
import WOComboBox from '../components/common/WOComboBox';
import WOFormColumn from '../components/common/WOFormColumn';
import WOIcon from '../components/common/WOIcon';
import WORadioGroup from '../components/common/WORadioGroup';
import { vaildationTextStyle, vaildationTextWrap } from '../styles/common.styled';
import WOLoginInput from '../components/common/WOLoginInput';
import WOValidationCheck from '../components/common/WOValidationCheck';
import { Controller, useForm } from 'react-hook-form';
import { useDispatch, useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import {
  checkDupId,
  resetSignup,
  selectDeptCd,
  selectIstCd,
  signupUser,
  clearMessage,
} from '../redux/modules/cmm/signup.reducer';
import useAlert from '../hooks/useAlert';
import { yupResolver } from '@hookform/resolvers/yup';
import * as yup from 'yup';
import {
  checkAuthNumberByAuthNo,
  resetAuthNumberState,
  sendAuthNumberToHpNo,
} from '../redux/modules/cmm/authNumber.reducer';
import WOModal, { ButtonRightArea, ModalButtonArea, ModalContentArea } from '../components/common/WOModal';
import WOEditTable from '../components/common/WOEditTable';
import WOLoginInputCustom from '../components/common/WOLoginInputCustom';

const Signup = () => {
  const { alert } = useAlert();
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const authInputRef = useRef();
  // 개인정보 수집 이용 동의(필수)
  const [isAgreeOpen, setIsAgreeOpen] = useState(false);
  // 인증만료시간
  const timer = useRef(null);
  // 5분
  const defaultInterval = 300;
  const [time, setTime] = useState(defaultInterval);
  const signupTabList = [
    {
      id: 1,
      num: '1',
      title: '약관동의',
    },
    {
      id: 2,
      num: '2',
      title: '정보입력',
    },
    {
      id: 3,
      num: '3',
      title: 'ID/PW입력',
    },
    {
      id: 4,
      num: '4',
      title: '가입신청완료',
    },
  ];
  const euraOptions = useMemo(
    () => [
      {
        id: 1,
        label: '동의하지 않음',
        name: 'rdo',
        value: 'N',
      },
      { id: 2, label: '동의함', name: 'rdo', value: 'Y' },
    ],
    []
  );
  // 탭 리스트
  const [selectedTab, setSelectedTab] = useState(signupTabList[0].id);
  // 정보입력 1,2 탭
  const [stepTwoTap, setStepTwoTap] = useState(1);
  // 승인 여부
  const [pass] = useState(false);
  // 중복 아이디 통과여부
  const [passDupUserId, setPassDupUserId] = useState(false);
  const [passwordValid, setPasswordValid] = useState({ lowcase: '', number: '', specialChar: '', length: '' });

  const cdAutYnOptions = useMemo(
    () => [
      {
        name: 'radio01',
        id: 1,
        label: '법인카드 권한',
        value: 'Y',
      },
      {
        name: 'radio02',
        id: 2,
        label: '법인카드 미권한',
        value: 'N',
      },
    ],
    []
  );

  const {
    istNoList: istInfoList,
    deptNoList: deptCdList,
    updating: updatingBySignup,
    updatingSuccess: updatingSuccessBySignup,
    message: messageBySignup,
    passDupId,
    loadingByDupCheck,
    successByDupCheck,
  } = useSelector((state) => state.signupState);

  const {
    loadingByAuthSend,
    successByAuthSend,
    message: messageByAuthSend,
    successByAuthCheck,
    isAuth,
  } = useSelector((state) => state.authNumberState);

  // 약관동의
  const {
    control: step1Control,
    setValue,
    getValues,
    watch,
  } = useForm({
    defaultValues: {
      inf_col_agr_yn: '',
    },
  });

  const step21Schema = yup.object().shape({
    user_nm: yup.string().required(),
    brdt: yup.string().length(6).required(),
  });

  // 정보입력 첫번째
  const {
    handleSubmit: step21Submit,
    control: step21Control,
    getValues: getStep21Value,
    formState: { errors: step21Errors, dirtyFields: firstDirtyFields },
  } = useForm({
    defaultValues: {
      user_nm: '',
      brdt: '',
      hp_no: '',
      cus_ist_cd: '',
      dept_cd: '',
      cd_aut_yn: '',
      auth_no: '',
    },
    resolver: yupResolver(step21Schema),
  });

  const step22Schema = yup.object().shape({
    user_id: yup
      .string()
      .matches(/^[(A-z)|1234567890\-_]{5,13}$/, '5~13자의 영문, 숫자와 특수기호(_)(-) 만 사용 가능합니다')
      .required(),
    pwd_no: yup
      .string()
      .matches(
        /^(?=.*[a-z])(?=.*[0-9])(?=.*[~!@#$%])[A-Za-z0-9~!@#$%]{8,}$/,
        '비밀번호 조건을 확인하세요.\n- 8자 이상\n- 소문자, 특수문자, 숫자 포함\n- 특수문자는 ~ ! @ # $ % 만 가능'
      )
      .required('비밀번호 조건을 확인하세요.'),
    confirm_pwd_no: yup
      .string()
      .oneOf([yup.ref('pwd_no'), null], '비밀번호가 일치하지 않습니다.')
      .required('비밀번호가 일치하지 않습니다.'),
  });

  // 정보입력 두번째
  const {
    handleSubmit: step22Submit,
    control: step22Control,
    getValues: getStep22Value,
    setError,
    clearErrors,
    formState: { errors },
  } = useForm({
    defaultValues: {
      user_id: '',
      pwd_no: '',
      confirm_pwd_no: '',
    },
    resolver: yupResolver(step22Schema),
  });

  useEffect(() => {
    if (time < 0) {
      if (timer.current) {
        clearInterval(timer.current);
        setTime(0);
      }
    }
  }, [time]);

  /**
   * 다음
   */
  function handleNextClick() {
    if (selectedTab === 1) {
      const infColAgrYn = getValues('inf_col_agr_yn');
      if (infColAgrYn !== 'Y') {
        alert('개인정보 수집이용 동의를 확인 후 동의체크시 회원가입 진행 가능합니다.');
        return;
      }
    }

    if (selectedTab === 2) {
      const crtfNo = getStep21Value('auth_no');
      const hpNo = getStep21Value('hp_no');
      dispatch(
        checkAuthNumberByAuthNo({
          crtf_no: crtfNo,
          hp_no: hpNo,
        })
      );
      return;
    }
    if (selectedTab === 3) {
      dispatch(
        signupUser({
          ...getValues(),
          ...getStep21Value(),
          ...getStep22Value(),
        })
      );
    }
    if (selectedTab !== 3 && selectedTab < signupTabList.length) {
      setSelectedTab(selectedTab + 1);
    }
  }

  /**
   * 정보입력 첫번째 다음버튼 활성화 확인
   */
  const checkInfoFirstNextDisabled = () => {
    const fields = ['user_nm', 'brdt', 'hp_no', 'cus_ist_cd', 'dept_cd', 'cd_aut_yn', 'auth_no'];
    const dirtyFields = [];
    for (let key in firstDirtyFields) {
      dirtyFields.push(key);
    }
    return fields.length !== dirtyFields.length;
  };

  /**
   * 정보입력 얼럿 유효성 검사
   */
  const checkEmptyInput = () => {
    let msg = '';

    if (getStep21Value().user_nm === '') {
      msg = '이름을 입력해 주세요.';
    } else if (getStep21Value().brdt === '') {
      msg = '생년월일을 입력해 주세요.';
    } else if (getStep21Value().hp_no === '') {
      msg = '핸드폰번호를 입력해 주세요.';
    } else if (getStep21Value().auth_no === '') {
      msg = '인증번호를 입력해 주세요.';
    } else if (getStep21Value().cus_ist_cd === '') {
      msg = '소속 기관을 선택해 주세요.';
    } else if (getStep21Value().dept_cd === '') {
      msg = '소속 부서를 선택해 주세요.';
    } else if (getStep21Value().cd_aut_yn === '') {
      msg = '법인 카드 권한 유무를 선택해 주세요.';
    }
    msg !== '' && alert(msg);
  };

  useEffect(() => {
    if (successByAuthCheck) {
      // 검증확인
      if (selectedTab === 2) {
        if (isAuth) {
          // setStepTwoTap(2);
          setSelectedTab(3);
        } else {
          alert('인증번호 불일치');
        }
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [alert, isAuth, successByAuthCheck]);

  const handleClick = (stepTwo) => {
    if (stepTwo === 'stepTwo') {
      const currStepTwo = stepTwoTap - 1;
      if (currStepTwo > 0) {
        setStepTwoTap(currStepTwo);
      } else {
        setSelectedTab(selectedTab - 1);
      }
      return;
    }
    if (selectedTab === 1) {
      navigate('/page/login');
    } else if (selectedTab <= signupTabList.length) {
      setSelectedTab(selectedTab - 1);
    }
  };

  /**
   * 패스워드 확인
   */
  const handleOnChangePassword = (text) => {
    const valid = {};
    const matchLowcase = /[a-z]/;
    const matchNumber = /[1234567890]/;
    const matchSpecialChar = /[!@#$%^&*()_+\-=\[\]{};':"\\|,.<>\/?\s]/;
    valid.lowcase = matchLowcase.test(text) ? 'pass' : 'error';
    valid.number = matchNumber.test(text) ? 'pass' : 'error';
    valid.specialChar = matchSpecialChar.test(text) ? 'pass' : 'error';
    valid.length = text.length >= 8 ? 'pass' : 'error';
    setPasswordValid(valid);
  };

  /**
   * 아이디 확인
   */
  const handleOnChangeId = (text) => {
    const valid = /^[(A-z)|1234567890\-_]{5,13}$/;
    if (valid.test(text)) {
      clearErrors('user_id');
      return true;
    } else {
      setError('user_id', { message: '5~13자의 영문, 숫자와 특수기호(_)(-) 만 사용 가능합니다' });
      return false;
    }
  };

  useEffect(() => {
    setPassDupUserId(passDupId);
    if (passDupId) {
      alert('사용가능한 아이디 입니다.');
    }
  }, [alert, passDupId]);

  /**
   * 중복확인
   */
  const handleCheckDup = () => {
    const user_id = getStep22Value('user_id');

    if (handleOnChangeId(user_id)) {
      dispatch(checkDupId({ user_id }));
    }
  };

  /**
   * 인증번호 전송
   */
  const handleSendAuthNo = () => {
    const hpNo = getStep21Value('hp_no');
    dispatch(
      sendAuthNumberToHpNo({
        hp_no: hpNo,
      })
    );
  };

  useEffect(() => {
    if (!loadingByAuthSend && successByAuthSend) {
      alert(
        '카카오톡으로 인증번호가 전송되었습니다.\n인증번호를 입력해주세요.\n카카오톡 미 가입시 SMS문자로 전송됩니다.',
        '인증번호 전송'
      );
      // setTimer();
      authInputRef.current.startTimer();
    } else if (!loadingByAuthSend && !successByAuthSend && messageByAuthSend) {
      alert(messageByAuthSend, '인증번호 전송');
    }
  }, [loadingByAuthSend, successByAuthSend, messageByAuthSend, alert]);

  /**
   * 회원가입 완료
   */
  const handleSignupFin = () => {
    dispatch(resetAuthNumberState());
    dispatch(resetSignup());
    navigate('/page/login');
  };

  useEffect(() => {
    if (successByDupCheck !== null && !successByDupCheck && !passDupId) {
      alert('사용중인 아이디입니다.');
    }
  }, [alert, loadingByDupCheck, passDupId, successByDupCheck]);

  /**
   * 기관명 변경
   */
  const handleOnIstInfoChange = useCallback(
    (insNo) => {
      // 부서명 콤보 조회
      dispatch(selectDeptCd(insNo));
    },
    [dispatch]
  );

  useEffect(() => {
    if (dispatch) {
      // 기관명 콤보 조회
      dispatch(selectIstCd());
    }
    return () => {
      dispatch(resetSignup());
    };
  }, [dispatch]);

  useEffect(() => {
    if (errors.user_id) {
      alert(errors.user_id.message, '아이디');
      clearErrors('user_id');
    } else if (errors.pwd_no) {
      alert(errors.pwd_no.message, '비밀번호');
      clearErrors('pwd_no');
    } else if (errors.confirm_pwd_no) {
      alert(errors.confirm_pwd_no.message, '비밀번호 확인');
      clearErrors('confirm_pwd_no');
    }
  }, [errors, alert, clearErrors]);

  useEffect(() => {
    if (!updatingBySignup && updatingSuccessBySignup) {
      setSelectedTab(4);
    } else if (!updatingBySignup && !updatingSuccessBySignup && messageBySignup) {
      alert(messageBySignup);
      dispatch(clearMessage());
    }
  }, [updatingBySignup, updatingSuccessBySignup, messageBySignup, selectedTab, alert, dispatch]);

  const [formRef, setFormRef] = useState(null);
  useEffect(() => {
    if (formRef) {
      // window.npPfsCtrl.RescanField();
    }
  }, [formRef]);

  return (
    <Wrap>
      <HeaderWrap>{/* <Notice text={'👉2023년 2월 은행연합회 공시 COFIX (신규취급) 👉한국'} /> */}</HeaderWrap>
      {/* lnb */}
      <WelcomBox />
      <S.SignContentWrap>
        <div className="content-box">
          <S.Container $gap={'unset'}>
            {/* 타이틀 영역 */}
            <S.SignTitleArea>
              {selectedTab === 1 && (
                <h3 className="title">
                  필수항목 및 선택항목 약관에
                  <br /> 동의해 주세요.
                </h3>
              )}
              {selectedTab === 2 && (
                <>
                  <h3 className="title">정확하게 정보를 입력해 주세요.</h3>
                  <h3 className="description__sub">정확한 정보를 입력하지 않으면 승인이 거절될 수 있습니다.</h3>
                </>
              )}
              {selectedTab === 3 && (
                <>
                  <h3 className="title">승인절차가 필요합니다.</h3>
                  <h3 className="description__sub">관리자 승인 완료 시 회원가입이 완료 됩니다.</h3>
                </>
              )}
              {selectedTab === 4 && (
                <>
                  <h3 className="title">회원가입이 완료 되었습니다.</h3>
                  <h3 className="description__sub">로그인화면으로 이동하여 로그인해 주세요.</h3>
                </>
              )}
            </S.SignTitleArea>

            {/* 서브타이블 영역 */}
            <SignSubTitleArea>
              {signupTabList.map((tab) => (
                <TabItem key={tab.id} $isActive={selectedTab === tab.id}>
                  <span>{tab.num}</span>
                  <p>{tab.title}</p>
                </TabItem>
              ))}
            </SignSubTitleArea>

            {/* 약관동의 */}
            {selectedTab === 1 && (
              <>
                {/* form 영역 */}
                <S.SignFormArea $gap={12} $mgTop={36}>
                  {/* 전체동의 */}
                  <S.SignCheckBoxWrap $isBorder={true}>
                    <WOCheckbox
                      round={true}
                      fontWght={true}
                      item={{
                        name: 'check01',
                        id: 'check01-01',
                        label: '전체동의',
                      }}
                      onChange={(e) => {
                        setValue('inf_col_agr_yn', e.target.checked ? 'Y' : 'N');
                      }}
                      value={watch('inf_col_agr_yn') === 'Y'}
                    />
                  </S.SignCheckBoxWrap>

                  <S.Container $gap={4}>
                    {/* [필수] 개인정보 수집이용 동의 */}
                    <S.SignCheckBoxWrap $height={35}>
                      <Controller
                        control={step1Control}
                        name={'inf_col_agr_yn'}
                        render={({ field: { onChange, name, value } }) => (
                          <WOCheckbox
                            round={true}
                            item={{
                              name: 'check02',
                              id: 'check02-02',
                              label: '[필수] 개인정보 수집이용 동의',
                            }}
                            onChange={(e) => {
                              onChange(e.target.checked ? 'Y' : 'N');
                            }}
                            value={value === 'Y'}
                          />
                        )}
                      />
                      <WOIcon
                        icon="arrow-gray"
                        width={14}
                        height={14}
                        onClick={() => {
                          setIsAgreeOpen(true);
                        }}
                      />
                    </S.SignCheckBoxWrap>

                    {/* [선택] 근무처 정보제공동의 */}
                    {/* <S.SignCheckBoxWrap $height={35}>
                      <Controller
                        control={step1Control}
                        name={'inf_col_agr_yn'}
                        render={({ field: { onChange, name, value } }) => (
                          <WOCheckbox
                            round={true}
                            item={{
                              name: 'check03',
                              id: 'check03-03',
                              label: '[선택] 근무처 정보제공동의',
                            }}
                          />
                        )}
                      />
                      <WOIcon icon="arrow-gray" width={14} height={14} />
                    </S.SignCheckBoxWrap> */}
                  </S.Container>
                </S.SignFormArea>
                {/* 버튼 영역 */}
                <S.SignButtonArea $mgTop={36}>
                  <S.Container $direction={'row'} $gap={12}>
                    <WOButton variant="secondary" size="lg" isMargin={true} onClick={handleClick}>
                      이전
                    </WOButton>
                    <WOButton
                      variant="primary"
                      size="lg"
                      onClick={handleNextClick}
                      // disabled={watch('inf_col_agr_yn') !== 'Y'}
                    >
                      다음
                    </WOButton>
                  </S.Container>
                </S.SignButtonArea>
              </>
            )}

            {/* 정보입력 */}
            {selectedTab === 2 && (
              <>
                {/* form 영역 */}
                <S.SignFormArea $gap={20} $mgTop={36}>
                  <Controller
                    control={step21Control}
                    name={'user_nm'}
                    render={({ field: { onChange, name, value } }) => (
                      <WOLoginInput
                        id="input01-01"
                        label="이름"
                        name="input01"
                        maxLength={15}
                        placeholder="이름을 입력해 주세요."
                        onChange={onChange}
                        value={value}
                        error={!!step21Errors.user_nm}
                      />
                    )}
                  />
                  <Controller
                    control={step21Control}
                    name={'brdt'}
                    render={({ field: { onChange, name, value } }) => (
                      <WOLoginInput
                        id="input01-01"
                        label="생년월일"
                        name="input01"
                        type={'number'}
                        maxLength={6}
                        placeholder="생년월일 6자리를 입력해 주세요. (주민등록번호 앞 6자리)"
                        onChange={onChange}
                        value={value}
                        error={!!step21Errors.brdt}
                      />
                    )}
                  />
                  <Controller
                    control={step21Control}
                    name={'hp_no'}
                    render={({ field: { onChange, name, value } }) => (
                      <WOLoginInput
                        type={'number'}
                        id="input01-03"
                        label="핸드폰번호"
                        name="input01"
                        placeholder="핸드폰번호를 입력해주세요."
                        maxLength={11}
                        button={
                          <AuthBtn type="button" className="auth-btn" onClick={handleSendAuthNo}>
                            인증번호 전송
                          </AuthBtn>
                        }
                        onChange={onChange}
                        value={value}
                      />
                    )}
                  />
                  <Controller
                    control={step21Control}
                    name={'auth_no'}
                    render={({ field: { onChange, name, value } }) => (
                      <WOLoginInputCustom
                        type={'number'}
                        id="input01-01"
                        label="인증번호"
                        name="input01"
                        placeholder="인증번호를 입력해 주세요."
                        maxLength={6}
                        // time={`${lpad(Math.floor(time / 60), 2, '0')}:${lpad(time % 60, 2, '0')}`}
                        onChange={onChange}
                        value={value}
                        ref={authInputRef}
                      />
                    )}
                  />
                  <WOFormColumn fullWidth={true}>
                    <Controller
                      control={step21Control}
                      name={'cus_ist_cd'}
                      render={({ field: { onChange, name, value } }) => (
                        <WOComboBox
                          label={''}
                          optionList={istInfoList?.map((option) => ({
                            id: option.code,
                            label: option.codenm,
                            value: option.code,
                          }))}
                          onChange={(val) => {
                            onChange(val);
                            handleOnIstInfoChange(val);
                          }}
                          defaultValue={value}
                          placeholder="소속 기관 선택"
                          isAutoComplete={true}
                          type={'type01'}
                          height={48}
                        />
                      )}
                    />
                    <Controller
                      control={step21Control}
                      name={'dept_cd'}
                      render={({ field: { onChange, name, value } }) => (
                        <WOComboBox
                          label={''}
                          optionList={deptCdList.map((option) => ({
                            id: option.code,
                            label: option.codenm,
                            value: option.code,
                          }))}
                          onChange={(val) => {
                            onChange(val);
                          }}
                          defaultValue={value}
                          placeholder="소속 부서 선택"
                          isAutoComplete={true}
                          type={'type01'}
                          height={48}
                        />
                      )}
                    />
                  </WOFormColumn>
                  <Controller
                    control={step21Control}
                    name={'cd_aut_yn'}
                    render={({ field: { onChange, name, value } }) => (
                      <WORadioGroup
                        radioItems={cdAutYnOptions}
                        defaultCheckedValue={cdAutYnOptions.find((item) => item.value === value)}
                        styleType={'type01'}
                        onChange={(val) => {
                          onChange(val.value);
                        }}
                      />
                    )}
                  />
                </S.SignFormArea>
                {/* 버튼 영역 */}
                <S.SignButtonArea $mgTop={36}>
                  <S.Container $direction={'row'} $gap={12}>
                    <WOButton variant="secondary" size="lg" isMargin={true} onClick={handleClick}>
                      이전
                    </WOButton>
                    {checkInfoFirstNextDisabled() ? (
                      <WOButton variant="primary" size="lg" customClass={'disabled'} onClick={checkEmptyInput}>
                        다음
                      </WOButton>
                    ) : (
                      <WOButton
                        variant="primary"
                        size="lg"
                        disabled={checkInfoFirstNextDisabled()}
                        onClick={() => step21Submit(handleNextClick)()}>
                        다음
                      </WOButton>
                    )}
                  </S.Container>
                </S.SignButtonArea>
              </>
            )}

            {selectedTab === 3 && (
              <>
                {/* form 영역 */}
                <S.SignFormArea $gap={20} $mgTop={36}>
                  {/* 아이디 입력 */}
                  <S.Container $gap={6}>
                    <Controller
                      control={step22Control}
                      name={'user_id'}
                      render={({ field: { onChange, name, value } }) => (
                        <WOLoginInput
                          id="input01-03"
                          label="아이디"
                          name="input01"
                          maxLength={13}
                          placeholder="아이디를 입력해 주세요."
                          error={errors.user_id}
                          button={
                            <AuthBtn type="button" className="auth-btn" onClick={handleCheckDup}>
                              중복확인
                            </AuthBtn>
                          }
                          value={value}
                          onChange={(val) => {
                            setPassDupUserId(false);
                            // handleOnChangeId(val);
                            onChange(val);
                          }}
                        />
                      )}
                    />
                    {/* <WOValidationCheck
                      itemList={[
                        {
                          id: '1',
                          title: '소문자',
                          isCheck: idValid.lowcase,
                        },
                        {
                          id: '2',
                          title: '특수문자',
                          isCheck: idValid.specialChar,
                        },
                        {
                          id: '3',
                          title: '숫자',
                          isCheck: idValid.number,
                        },
                        {
                          id: '4',
                          title: '6자 이상',
                          isCheck: idValid.length,
                        },
                      ]}
                    /> */}
                    {errors.user_id && (
                      <WOValidationCheck
                        itemList={[
                          {
                            id: '1',
                            title: errors.user_id.message,
                            isCheck: 'error',
                          },
                        ]}
                      />
                    )}
                  </S.Container>
                  <form name="signupForm" ref={setFormRef}>
                    {/* 비밀번호 입력 */}
                    <S.Container>
                      <S.Container $gap={6}>
                        <Controller
                          control={step22Control}
                          name={'pwd_no'}
                          render={({ field: { onChange, name, value } }) => (
                            <>
                              <WOLoginInput
                                type={'password'}
                                id="input02-06"
                                label="비밀번호"
                                name={name}
                                placeholder="비밀번호를 입력해주세요."
                                error={errors.pwd_no}
                                value={value}
                                maxLength={16}
                                // npkencrypt={'on'}
                                onChange={(val) => {
                                  onChange(val);
                                  handleOnChangePassword(val);
                                }}
                              />
                              <WOValidationCheck
                                itemList={[
                                  // {
                                  //   id: '1',
                                  //   title: '소문자',
                                  //   isCheck: passwordValid.lowcase,
                                  // },
                                  // {
                                  //   id: '2',
                                  //   title: '특수문자',
                                  //   isCheck: passwordValid.specialChar,
                                  // },
                                  {
                                    id: '3',
                                    title: '숫자',
                                    isCheck: passwordValid.number,
                                  },
                                  {
                                    id: '4',
                                    title: '8자 이상',
                                    isCheck: passwordValid.length,
                                  },
                                ]}
                              />
                            </>
                          )}
                        />
                      </S.Container>

                      <S.Container $gap={6}>
                        <Controller
                          control={step22Control}
                          name={'confirm_pwd_no'}
                          render={({ field: { onChange, name, value } }) => (
                            <>
                              <WOLoginInput
                                type={'password'}
                                id="input02-07"
                                label="비밀번호"
                                name="input02"
                                maxLength={16}
                                // npkencrypt={'on'}
                                placeholder="비밀번호를 한번 더 입력해주세요."
                                error={errors.confirm_pwd_no}
                                onChange={onChange}
                                value={value}
                              />
                              {errors.confirm_pwd_no && (
                                <TextCheckText $vailCheck={'error'}>{errors.confirm_pwd_no?.message}</TextCheckText>
                              )}
                            </>
                          )}
                        />
                      </S.Container>
                    </S.Container>
                  </form>
                </S.SignFormArea>
                {/* 버튼 영역 */}
                <S.SignButtonArea $mgTop={147}>
                  <S.Container $direction={'row'} $gap={12}>
                    <WOButton variant="secondary" size="lg" isMargin={true} onClick={() => handleClick('stepTwo')}>
                      이전
                    </WOButton>
                    <WOButton
                      variant="primary"
                      size="lg"
                      // disabled={checkInfoSecondNextDisabled() || !passDupUserId}
                      onClick={() => {
                        if (!passDupUserId) {
                          alert('아이디 중복확인을 해주세요.');
                          return;
                        }
                        step22Submit(handleNextClick)(getStep22Value());
                      }}>
                      다음
                    </WOButton>
                  </S.Container>
                </S.SignButtonArea>
              </>
            )}

            {/* 승인 */}
            {selectedTab === 4 && (
              <>
                {/* form 영역 */}
                <S.SignFormArea $position={'relative'} $mgTop={76}>
                  <S.SignAcceptBoxWrap>
                    <span>시구청 총괄 관리자 승인</span>
                    <p>{pass ? '승인 완료' : '진행중'}</p>
                    <ImageWrap>
                      <WOIcon icon={'document'} width={108} height={92} />
                    </ImageWrap>
                    <LoadingWrap isPass={pass}>
                      {pass && (
                        <img src="/assets/images/etc/shape.svg" className="checkmark-animation" alt="Checkmark" />
                      )}
                      {!pass && (
                        <img src="/assets/images/etc/rolling.svg" className="rolling-animation" alt="loading..." />
                      )}
                    </LoadingWrap>
                  </S.SignAcceptBoxWrap>
                </S.SignFormArea>
                {/* 버튼 영역 */}
                <S.SignButtonArea $mgTop={100}>
                  <S.Container>
                    <WOButton variant="primary" size="lg" onClick={handleSignupFin}>
                      확인
                    </WOButton>
                  </S.Container>
                </S.SignButtonArea>
              </>
            )}

            {/* 가입완료 */}
            {/* {selectedTab === 4 && (
              <>
                <S.SignFormArea $mgTop={42}>
                  <S.Container>
                    <WOIcon icon={'signup_complete'} width={166} height={180} />
                  </S.Container>
                </S.SignFormArea>
                <S.SignButtonArea $mgTop={27}>
                  {selectedTab >= 1 && selectedTab <= 3 && (
                    <>
                      <S.Container $direction={'row'} $gap={12}>
                        <WOButton variant="secondary" size="lg" isMargin={true} onClick={handleClick}>
                          이전
                        </WOButton>
                        <WOButton variant="primary" size="lg" onClick={handleNextClick}>
                          다음
                        </WOButton>
                      </S.Container>
                    </>
                  )}

                  {selectedTab === 4 && (
                    <>
                      <S.Container $direction={'row'} $gap={12}>
                        <WOButton variant="primary" size="lg" onClick={() => navigate('/page/login')}>
                          로그인
                        </WOButton>
                      </S.Container>
                    </>
                  )}
                </S.SignButtonArea>
              </>
            )} */}
          </S.Container>

          {selectedTab === 1 && (
            <S.Container $margin={'24px 0 0 0'} $gap={8}>
              <TextArea>
                <div className="bullet">•</div>
                <span>회원가입을 위해 최초 회원가입시 다음과 같은 개인정보를 수집하고 있습니다.</span>
              </TextArea>
              <TextArea>
                <div className="bullet">•</div>
                <span>수집한 개인정보는 회원자격 유지기간 동안 보관합니다.</span>
              </TextArea>
              <TextArea>
                <div className="bullet">•</div>
                <span>
                  회원께서는 개인정보 수집 동의를 거부하실 수 있으며, 다만 필수 항목 수집을 거부하실 경우 회원가입이
                  제한됩니다.
                </span>
              </TextArea>
            </S.Container>
          )}
        </div>
      </S.SignContentWrap>

      <MainFooter islogin={true} />

      {/* s: 개인정보 수집 이용 동의(필수) */}
      <WOModal
        isOpen={isAgreeOpen}
        direction="center"
        title="개인정보 수집 이용 동의(필수)"
        size={'md'}
        onClose={() => setIsAgreeOpen(false)}>
        <ModalContentArea>
          <S.TermsTop>
            <p className="title">
              [필수] 상품별 개인정보 수집 &middot; 이용 동의서
              <br />
              상품/서비스명 : 금고업무통합관리시스템
            </p>
            <div className="content">
              ㈜우리은행 귀중
              <br /> * 귀 행과의 금고업무통합관리시스템 이용과 관련하여 귀 행이 본인의 개인정보를 수집·이용하고자 하는
              경우에는 개인정보보호법 등 관계 법령에 따라 본인의 동의가 필요합니다.
            </div>
          </S.TermsTop>
          <S.TableBox className="modal-type">
            <WOEditTable>
              <caption>상품별 개인정보 수집 이용 동의서</caption>
              <tbody>
                <tr>
                  <th>수집 &middot; 이용 목적</th>
                  <td>
                    <ul>
                      <li>회원제 서비스 이용에 따른 본인확인, 개인식별, 가입의사 및 중복가입 확인, 회원관리</li>
                      <li>회원관리 금융사고 조사, 분쟁 해결, 민원 처리 </li>
                    </ul>
                  </td>
                </tr>
                <tr>
                  <th>보유 및 이용기간</th>
                  <td>
                    금고업무통합관리시스템 서비스 종료일 까지 보유·이용
                    <S.Highlight>
                      위 보유 기간에서의 서비스 종료일란 “금고업무통합관리시스템의 서비스가 종료되어 더 이상 이용 할 수
                      없게 된 날”을 말합니다
                    </S.Highlight>
                  </td>
                </tr>
                <tr>
                  <th>거부 권리 및 불이익</th>
                  <td>
                    귀하는 동의를 거부하실 수 있습니다. 다만, 위 개인정보 수집·이용에 관한 동의는
                    “금고업무통합관리시스템 이용 계약의 체결 및 이행을 위한“ 필수적 사항이므로, 위 사항에 동의하셔야만
                    금고업무통합관리시스템 이용이 가능합니다.
                  </td>
                </tr>
              </tbody>
            </WOEditTable>
          </S.TableBox>
          <S.TableBox className="modal-type">
            <p className="title">수집 &middot; 이용 항목</p>
            <WOEditTable>
              <caption>상품별 개인정보 수집 이용 동의서</caption>
              <tbody>
                <tr>
                  <th>개인정보(일반개인정보)</th>
                  <td>ID, 성명, 생년월일, 소속, 부서명, 휴대전화번호</td>
                </tr>
              </tbody>
            </WOEditTable>
          </S.TableBox>
          <S.GrayBox>
            <p className="title">위 개인정보 수집·이용에 동의하십니까?</p>
            <WORadioGroup
              radioItems={euraOptions}
              defaultCheckedValue={euraOptions.find((item) => item.value === watch('inf_col_agr_yn'))}
              onChange={(val) => {
                setValue('inf_col_agr_yn', val.value);
              }}
            />
          </S.GrayBox>
        </ModalContentArea>
        <ModalButtonArea>
          <ButtonRightArea>
            <WOButton
              variant={'primary'}
              size={'md'}
              onClick={() => {
                if (watch('inf_col_agr_yn') === '') {
                  alert('수집·이용 항목에 대한 동의하지 않음/동의함을 체크해주세요.');
                } else {
                  setIsAgreeOpen(false);
                }
              }}>
              확인
              {/* 
                동의 체크가 없을시 얼럿창
                수집·이용 항목 동의 - 제목
                수집·이용 항목에 대한 동의하지 않음/동의함을 체크해주세요. - 내용
              */}
            </WOButton>
          </ButtonRightArea>
        </ModalButtonArea>
      </WOModal>
      {/* e: 개인정보 수집 이용 동의(필수) */}
    </Wrap>
  );
};

export default Signup;

const Wrap = styled.div`
  width: 100%;
  height: calc(100% - 60px);
  background-color: #fff;
  min-width: 1268px;
  min-height: 700px;
  @media screen and (max-width: 1560px) {
    height: calc(100% - 82px);
  }
`;

const TabItem = styled.div`
  display: flex;
  gap: 0 4px;
  border-bottom: ${(props) => (props.$isActive ? '2px solid #0e213f' : '')};
  span {
    font-size: 12px;
    font-weight: 400;
    line-height: 18px;
    color: ${(props) => (props.$isActive ? 'var(--C-Dark-12)' : 'var(--C-Dark-06)')};
    position: relative;
    top: -3px;
  }

  p {
    color: ${(props) => (props.$isActive ? 'var(--C-Dark-12)' : 'var(--C-Dark-06)')};

    font-size: 14px;
    font-weight: 700;
    line-height: 21px;
  }
`;

const SignSubTitleArea = styled.div`
  display: flex;
  height: 30px;
  gap: 0 12px;
  margin-top: 24px;
  ${TabItem}
`;

const AuthBtn = styled.button`
  display: flex;
  align-items: center;
  width: fit-content;
  height: 32px;
  padding: 0px 16px;
  color: var(--C-Dark-10);
  font-size: 12px;
  font-weight: 700;
  border-radius: 6px;
  background: var(--C-Dark-03, #e5e5ed);
  &:disabled {
    color: var(--C-Dark-06, #9ea6b2);
  }
`;

const TextArea = styled.div`
  display: flex;
  gap: 0 4px;
  color: var(--C-Dark-06, #9ea6b2);
  font-size: 12px;
  font-weight: 400;
  line-height: 18px;
`;
const TextCheckWrap = styled.div`
  ${vaildationTextWrap}
`;

const TextCheckText = styled.div`
  ${vaildationTextStyle}
`;

const ImageWrap = styled.div`
  position: absolute;
  right: 22px;
`;

const LoadingWrap = styled.div`
  position: absolute;
  top: ${(props) => (props.isPass ? '20px' : '30px')};
  right: ${(props) => (props.isPass ? '16px' : '33px')};

  .checkmark-animation {
    animation: checkmark 0.5s ease forwards;
  }

  @keyframes checkmark {
    0% {
      transform: scale(0);
      opacity: 0;
    }
    50% {
      transform: scale(1.2);
      opacity: 1;
    }
    100% {
      transform: scale(1);
    }
  }

  .rolling-animation {
    animation: rotate360 2s linear infinite;
  }

  @keyframes rolling {
    from {
      transform: rotate(0deg);
    }
    to {
      transform: rotate(360deg);
    }
  }
`;
