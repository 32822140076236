import instance from '../../common/config/api';

/**
 * 부서이관 조회
 * @param {object} params
 * @returns
 */
export const fetchDeptChg = (params) =>
  instance.post(`${process.env.REACT_APP_SERVER_URL}/scq/scq/sys/0002/selectDeptChg`, params);

/**
 * 부서이관 저장
 * @param {array} dtos
 * @returns
 */
export const insertDeptChg = (dtos) =>
  instance.post(`${process.env.REACT_APP_SERVER_URL}/scq/scq/sys/0002/insertDeptChg `, {
    subDto: dtos,
  });

/**
 * 부서이관 PK 조회
 * @returns
 */
export const fetchDeptChgPkNmgt = (params) =>
  instance.post(`${process.env.REACT_APP_SERVER_URL}/scq/scq/sys/0002/selectDeptChgPkNmgt `, params);

/**
 * 집계대상별사용자로그 조회
 * @returns
 */
export const fetchUserLog = (params) =>
  instance.post(`${process.env.REACT_APP_SERVER_URL}/scq/scq/sys/0002/selectUserLog `, params);

/**
 * 사용자관리 조회
 * @returns
 */
export const fetchUserMngList = (params) =>
  instance.post(`${process.env.REACT_APP_SERVER_URL}/scq/scq/sys/0002/selectUserList`, params);

/**
 * 사용자 저장(추가/삭제/변경)
 * @returns
 */
export const insertUserMngList = (dtos) =>
  instance.post(`${process.env.REACT_APP_SERVER_URL}/scq/scq/sys/0002/insertUserList`, {
    main: [...dtos],
  });

/**
 * 사용자관리 - 가입/부서 변경 승인 - 사용자 미승인 목록
 * @returns
 */
export const fetchUserApvList = (params) =>
  instance.post(`${process.env.REACT_APP_SERVER_URL}/scq/scq/sys/0002/selectUserApvList`, params);

/**
 * 사용자관리 - 가입/부서 변경 승인 - 승인/반려 저장
 * @param {array} dtos
 * @returns
 */
export const insertTrdnRsn = (dtos) =>
  instance.post(`${process.env.REACT_APP_SERVER_URL}/scq/scq/sys/0002/insertTrdnRsn`, {
    main: [...dtos],
  });

/**
 * 비밀번호 초기화
 * @returns
 */
export const updateUserPwd = (params) =>
  instance.post(`${process.env.REACT_APP_SERVER_URL}/scq/scq/sys/0002/updateUserPwd`, params);

/**
 * 사용자관리 - 가입/부서 변경 승인 - 부서 미승인 목록
 * @returns
 */
export const fetchUserDeptChgApvList = (params) =>
  instance.post(`${process.env.REACT_APP_SERVER_URL}/scq/scq/sys/0002/selectUserDeptChgApvList`, params);
