import instance from '../../common/config/api';

/**
 * 탐지금액변경 목록 조회
 */
export const fetchDetAmtList = (params) =>
  instance.post(`${process.env.REACT_APP_SERVER_URL}/scq/scq/fds/0004/selectDetAmtList`, params);

/**
 * 탐지금액변경 상세 조회
 */
export const fetchDetAmtDtlList = (params) =>
  instance.post(`${process.env.REACT_APP_SERVER_URL}/scq/scq/fds/0004/selectDetAmtDtlList`, params);

/**
 * 탐지금액 변경 승인/반려 처리
 */
export const updateAprvDetAmtChgReq = (params) =>
  instance.post(`${process.env.REACT_APP_SERVER_URL}/scq/scq/fds/0004/updateAprvDetAmtChgReq`, params);

/**
 * 탐지금액 변경 취소 요청
 */
export const mergeDetAmtReq = (params) =>
  instance.post(`${process.env.REACT_APP_SERVER_URL}/scq/scq/fds/0004/mergeDetAmtReq`, params);

/**
 * 탐지금액변경 변경신청시 조회
 */
export const fetchLastChgAmt = (params) =>
  instance.post(`${process.env.REACT_APP_SERVER_URL}/scq/scq/fds/0004/selectLastChgAmt`, params);
