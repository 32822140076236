import { SUCCESS, REQUEST, FAILURE } from '../../../common/utils/action-type-utils';
import { fetchEtcAsstPrbtAnlsList } from '../../../lib/etc/etcAsstPrbtAnls';
import dayjs from 'dayjs';
import { fetchCommonComboList } from '../../../lib/cmm/combo.api';

// actions
const FETCH_ASSTPRBTANLS = 'etcAsstPrbtAnls/FETCH_ASSTPRBTANLS';
const RESET_STATE = 'etcAsstPrbtAnls/RESET_STATE';
const SAVE_SEARCH_FORM_VALUES = 'etcAsstPrbtAnls/SAVE_SEARCH_FORM_VALUES';
const FETCH_BR_CD_LIST = 'etcAsstPrbtAnls/SAVE_SEARCH_FORM_VALUES';

const allOption = { id: 'all', label: '전체' };

export const initFormValues = {
  ydcs8a1_t8a01_inq_seg2: '1', // 기간선택
  bas_ym: dayjs().add(-1, 'month').format('YYYY-MM'), // 기간
  ydcs8a1_t8a01_inq_seg1: 'all', // 상품구분
  br_cd: '', // 영업점코드 (점출력구분 값이 '개별영업점'인 경우 값 추가)
};

// initial state
const initialState = {
  loading: false,
  success: false,
  message: '',
  list: [],
  totalCount: 0,
  ydcs8a1T8a01InqSeg2List: [
    { id: '1', label: '월중' },
    { id: '2', label: '기중' },
  ],
  ydcs8a1T8a01InqSeg1List: [
    { ...allOption },
    { id: '2', label: '보조금관리통장' },
    { id: '3', label: '복지시설통장' },
    { id: '4', label: '보육시설통장' },
  ],
  ydcs8a1T8a01InqSegList: [{ ...allOption }, { id: '2', label: '시구청영업점' }, { id: '3', label: '개별영업점' }],
  brCdLoading: false,
  brCdList: [],
  brCdSuccess: false,
  searchFormValues: {
    init: true,
    ...initFormValues,
  },
};

// reducers
export default function etcAsstPrbtAnls(state = initialState, action) {
  switch (action.type) {
    case SUCCESS(FETCH_ASSTPRBTANLS): // 조회 성공
      return {
        ...state,
        success: true,
        loading: false,
        list: action.payload.data?.subDto,
        totalCount: action.payload?.subDtoCnt,
      };
    case REQUEST(FETCH_ASSTPRBTANLS): // 조회 요청
      return {
        ...state,
        loading: true,
        success: false,
      };
    case FAILURE(FETCH_ASSTPRBTANLS): // 조회실패
      return {
        ...initialState,
        loading: false,
        success: false,
        message: action.payload.response?.data?.message,
      };
    case SAVE_SEARCH_FORM_VALUES:
      return {
        ...state,
        // 콤보 - 기관
        brCdSuccess: false,
        brCdLoading: false,
        searchFormValues: action.payload,
      };
    case SUCCESS(FETCH_BR_CD_LIST): // 조회 성공
      return {
        ...state,
        brCdSuccess: true,
        brCdLoading: false,
        brCdList: action.payload.data?.subDto,
      };
    case REQUEST(FETCH_BR_CD_LIST): // 조회 요청
      return {
        ...state,
        brCdLoading: true,
        brCdSuccess: false,
      };
    case FAILURE(FETCH_BR_CD_LIST): // 조회실패
      return {
        ...state,
        brCdLoading: false,
        brCdSuccess: false,
        brCdList: [],
      };
    case RESET_STATE:
      return initialState;
    default:
      return state;
  }
}

/**
 * 기타 - 보조금 수익성 분석
 */
export const selectEtcAsstPrbtAnlsList = (searchParams) => {
  return {
    type: FETCH_ASSTPRBTANLS,
    payload: fetchEtcAsstPrbtAnlsList(searchParams),
  };
};

/**
 * 부서명 콤보 조회
 */
export const selectDeptCdComboList = (searchParams) => {
  return {
    type: FETCH_BR_CD_LIST,
    payload: fetchCommonComboList({ slt_box_no: '201', ...searchParams }),
  };
};

/**
 * 조회조건 저장
 */
export const saveSearchFormValues = (searchFormValues) => {
  return {
    type: SAVE_SEARCH_FORM_VALUES,
    payload: {
      ...searchFormValues,
      init: false,
    },
  };
};

/**
 *  상태초기화
 */
export const resetEtcAsstPrbtAnls = () => {
  return {
    type: RESET_STATE,
  };
};
