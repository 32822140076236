import instance from '../../common/config/api';

const prefix = `${process.env.REACT_APP_SERVER_URL}/scq/scq/rpt/0002`;

/**
 * 세입세출자금일계표 조회
 * @param {object} params
 * @returns
 */
export const fetchAnrvAnexFdDay = (params) => instance.post(`${prefix}/selectAnrvAnexFdDay`, params);

/**
 * 세입세출자금일계표 조회(기금)
 * @param {object} params
 * @returns
 */
export const fetchAnrvAnexFdDayGg = (params) => instance.post(`${prefix}/selectAnrvAnexFdDayGg`, params);

/**
 * 계좌별 세입세출자금일계표 조회
 * @param {object} params
 * @returns
 */
export const fetchByactAnrvAnexFdDay = (params) => instance.post(`${prefix}/selectByactAnrvAnexFdDay`, params);

/**
 * 세입세출외현금자금일계표 조회
 * @param {object} params
 * @returns
 */
export const fetchAnrvAnexCashFdDay = (params) => instance.post(`${prefix}/selectAnrvAnexCashFdDay`, params);

/**
 * 자금운용내역장 조회
 * @param {object} params
 * @returns
 */
export const fetchFdOpgHbokBkg = (params) => instance.post(`${prefix}/selectFdOpgHbokBkg`, params);

/**
 * 모계좌여부 구분 확인 조회
 * @param {object} params
 * @returns
 */
export const fetchMoActGbn = (params) => instance.post(`${prefix}/selectMoActGbn`, params);

/**
 * 세출 일〮월〮분기계표 조회
 * @param {object} params
 * @returns
 */
export const fetchAnexDayMmQr = (params) => instance.post(`${prefix}/selectAnexDayMmQr`, params);

/**
 * 강남구청 신한은행 과거내역 조회
 * @param {object} params
 * @returns
 */
export const fetchDayRpt680 = (params) => instance.post(`${prefix}/selectDayRpt680`, params);

/**
 * 용산구청 신한은행 과거내역 조회
 * @param {object} params
 * @returns
 */
export const fetchDayRpt170 = (params) => instance.post(`${prefix}/selectDayRpt170`, params);

/**
 * 용산구청 세입세출자금일계표 조회
 * @param {object} params
 * @returns
 */
export const fetchFdDayYs = (params) => instance.post(`${prefix}/selectFdDayYs`, params);

/**
 * HOST 거래여부 조회
 * @returns
 */
export const fetchFdDayHostYn = (params) => instance.post(`${prefix}/selectFdDayHostYn`, params);

/**
 * 본청 세입세출자금일계표 조회
 * @param {object} params
 * @returns
 */
export const fetchFdDaySr = (params) => instance.post(`${prefix}/selectFdDaySr`, params);

/**
 * 구청 세입세출자금일계표 조회
 * @param {object} params
 * @returns
 */
export const fetchFdDayGu = (params) => instance.post(`${prefix}/selectFdDayGu`, params);

/**
 * 세입세출자금일계표 기금 조회
 * @param {object} params
 * @returns
 */
export const fetchFdDayGg = (params) => instance.post(`${prefix}/selectFdDayGg`, params);

/**
 * 회계별 자금배정 및 지출현황
 * @param {object} params
 * @returns
 */
export const fetchAccdAnexRcexSts = (params) => instance.post(`${prefix}/selectAccdAnexRcexSts`, params);
