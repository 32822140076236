import instance from '../../common/config/api';

/**
 * 세입월계표(서울시세무과) 보고서 출력
 * @param {object} params
 * @returns
 */
export const fetchAnrvMmRptSulTxbn = (params) =>
  instance.post(`${process.env.REACT_APP_SERVER_URL}/scq/scq/rpt/0001/selectAnrvMmRptSulTxbn`, params);

/**
 * 세입월계표(서울시 세무과용) 서버 계좌 조회
 * @param {object} params
 * @returns
 */
export const fetchAnrvMmRptSulTxbnAct = (params) =>
  instance.post(`${process.env.REACT_APP_SERVER_URL}/scq/scq/rpt/0001/selectAnrvMmRptSulTxbnAct`, params);

/**
 * 세입월계표(서울시세무과) 보고서 조회 일자 조회
 * @param {object} params
 * @returns
 */
export const fetchAnrvMmRptSulTxbnInqDt = (params) =>
  instance.post(`${process.env.REACT_APP_SERVER_URL}/scq/scq/rpt/0001/selectAnrvMmRptSulTxbnInqDt`, params);
