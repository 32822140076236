import { SUCCESS, REQUEST, FAILURE } from '../../../common/utils/action-type-utils';
import { fetchUseLogSt, fetchUseLogStDtl } from '../../../lib/sys/SYS0208V01.api';
import dayjs from 'dayjs';

// actions
const FETCH_USE_LOG_ST = 'SYS0208V01/fetchUseLogSt';
const FETCH_USE_LOG_ST_DTL = 'SYS0208V01/fetchUseLogStDtl';
const SAVE_SEARCH_FORM_VALUES = 'SYS0208V01/SAVE_SEARCH_FORM_VALUES';
const RESET_STATE = 'SYS0208V01/RESET_STATE';

export const initFormValues = {
  // 검색 영역
  user_tp_cd: 'all', //기관구분
  sta_dy: dayjs().format('YYYY-MM-DD'), //조회기간
  end_dy: dayjs().format('YYYY-MM-DD'),
};

// initial state
const initialState = {
  loading: false,
  success: false,
  message: '',
  list: [],
  subLoading: false,
  subSuccess: false,
  subMessage: '',
  subList: [],
  // 검색조건
  searchFormValues: {
    init: true,
    ...initFormValues,
  },
};

// reducers
export default function SYS0208V01(state = initialState, action) {
  // 업무별 사용 현황
  switch (action.type) {
    case SUCCESS(FETCH_USE_LOG_ST): // 조회 성공
      return {
        ...state,
        success: true,
        loading: false,
        list: action.payload.data?.mian,
      };
    case REQUEST(FETCH_USE_LOG_ST): // 조회 요청
      return {
        ...state,
        loading: true,
        message: '',
      };
    case FAILURE(FETCH_USE_LOG_ST): // 조회실패
      return {
        ...initialState,
        loading: false,
        success: false,
        message: action.payload.response?.data?.message,
      };
    // 사용자별 사용 내역
    case SUCCESS(FETCH_USE_LOG_ST_DTL): // 조회 성공
      return {
        ...state,
        success: true,
        subLoading: false,
        subList: action.payload.data?.sub,
      };
    case REQUEST(FETCH_USE_LOG_ST_DTL): // 조회 요청
      return {
        ...state,
        subLoading: true,
        subMessage: '',
      };
    case FAILURE(FETCH_USE_LOG_ST_DTL): // 조회실패
      return {
        ...initialState,
        subLoading: false,
        subSuccess: false,
        subMessage: action.payload.response?.data?.message,
      };
    case SAVE_SEARCH_FORM_VALUES:
      return {
        ...state,
        searchFormValues: action.payload,
      };
    case RESET_STATE:
      return initialState;
    default:
      return { ...state };
  }
}

export const selectUseLogSt = (searchParams) => {
  return {
    type: FETCH_USE_LOG_ST,
    payload: fetchUseLogSt(searchParams),
  };
};
export const selectUseLogStDtl = (searchParams) => {
  return {
    type: FETCH_USE_LOG_ST_DTL,
    payload: fetchUseLogStDtl(searchParams),
  };
};

/**
 * 조회조건 저장
 */
export const saveSearchFormValues = (searchFormValues) => {
  return {
    type: SAVE_SEARCH_FORM_VALUES,
    payload: {
      ...searchFormValues,
      init: false,
    },
  };
};

/**
 * 상태초기화
 */
export const resetState = () => {
  return {
    type: RESET_STATE,
  };
};
