import { SUCCESS, REQUEST, FAILURE } from '../../../common/utils/action-type-utils';
import { fetchCrossSellList, fetchCrossSellDtl } from '../../../lib/brc/crossSellList.api';

// actions
const FETCH_CROSS_SELL_LIST = 'crossSellList/FETCH_CROSS_SELL_LIST';
const FETCH_CROSS_SELL_DTL = 'crossSellList/FETCH_CROSS_SELL_DTL';

// initial state
const initialState = {

  totalCount: 0,
  list: [],
  loading: false,
  success: true,
  message: '',

  details: [],
  detailsSuccess: false,
  detailsLoading: false,
  detailsMessage: false,
};

// reducers
export default function crossSellList(state = initialState, action) {
  switch (action.type) {
    case SUCCESS(FETCH_CROSS_SELL_LIST): // 조회 성공
      return {
        ...state,
        success: true,
        loading: false,
        list: action.payload.data?.main,
        totalCount: action.payload?.mainCnt,
        updateSuccess: false,
       
      };
    case REQUEST(FETCH_CROSS_SELL_LIST): // 조회 요청
      return {
        ...state,
        success: true,
        loading: true,
        updateSuccess: false,
      };
    case FAILURE(FETCH_CROSS_SELL_LIST): // 조회실패
      return {
        ...initialState,
        loading: false,
        success: false,
        message: action.payload.response?.data?.message || action.payload.message,
        updateSuccess: false,
      };
    case SUCCESS(FETCH_CROSS_SELL_DTL): // 성공
      return {
        ...state,
        detailsSuccess: true,
        detailsLoading: false,
        details: action.payload.data?.sub,
        totalCount: action.payload?.subCnt,
      };
    case REQUEST(FETCH_CROSS_SELL_DTL): // 요청
      return {
        ...state,
        updateSuccess: false,
        message: null,
        detailsSuccess: true,
        detailsLoading: true,
        updating: true,
      };
    case FAILURE(FETCH_CROSS_SELL_DTL): // 실패
      return {
        ...initialState,
        updating: false,
        updateSuccess: false,
        detailsMessage: action.payload.response?.data?.message,
      };
    default:
      return state;
  }
}

/**
 * 취소결제관리 내역조회
 */
export const selectCrossSellList = (searchParams) => {
  return {
    type: FETCH_CROSS_SELL_LIST,
    payload: fetchCrossSellList(searchParams),
  };
};

/**
 * 취소결제관리 수정
 */
export const selectCrossSellDtl = (searchParams) => {
  return {
    type: FETCH_CROSS_SELL_DTL,
    payload: fetchCrossSellDtl(searchParams),
  };
};