import { SUCCESS, REQUEST, FAILURE } from '../../../common/utils/action-type-utils';
import { fetchAppvPrg, fetchAppvPrgGuse } from '../../../lib/rpt/appvPrg.api';

// actions
const FETCH_APPV_PRG = 'appvPrg/FETCH_APPV_PRG';
const FETCH_APPV_PRG_GUSE = 'appvPrg/FETCH_APPV_PRG_GUSE';
const RESET_APPV_PRG = 'appvPrg/RESET_APPV_PRG';

// initial state
const initialState = {
  loading: false,
  success: false,
  message: '',
  list: [],
  totalCount: 0,
};

// reducers
export default function appvPrg(state = initialState, action) {
  switch (action.type) {
    case SUCCESS(FETCH_APPV_PRG): // 조회 성공
    case SUCCESS(FETCH_APPV_PRG_GUSE): // 조회 성공
      return {
        ...state,
        success: true,
        loading: false,
        list: action.payload.data?.main_find,
        totalCount: action.payload.data?.main_find_cnt,
      };
    case REQUEST(FETCH_APPV_PRG): // 조회 요청
    case REQUEST(FETCH_APPV_PRG_GUSE): // 조회 요청
      return {
        ...state,
        success: false,
        loading: true,
        message: '',
      };
    case FAILURE(FETCH_APPV_PRG): // 조회실패
    case FAILURE(FETCH_APPV_PRG_GUSE): // 조회실패
      return {
        ...initialState,
        loading: false,
        success: false,
        message: action.payload.response?.data?.message,
        list: [],
      };
    case RESET_APPV_PRG:
      return initialState;
    default:
      return state;
  }
}

/**
 * 보고서 결재진행 상태 조회
 * @param {object} searchParams
 * @returns
 */
export const selectAppvPrg = (searchParams) => {
  return {
    type: FETCH_APPV_PRG,
    payload: fetchAppvPrg(searchParams),
  };
};

/**
 * 보고서 결재진행 상태 조회 구세, 보고서 조회시
 * @param {object} searchParams
 * @returns
 */
export const selectAppvPrgGuse = (searchParams) => {
  return {
    type: FETCH_APPV_PRG_GUSE,
    payload: fetchAppvPrgGuse(searchParams),
  };
};

/**
 * 전자보고서 결재라인 초기화
 * @returns
 */
export const resetAppvPrg = () => {
  return {
    type: RESET_APPV_PRG,
  };
};
