import { SUCCESS, REQUEST, FAILURE } from '../../../common/utils/action-type-utils';
import { fetchAnrvAnexCashFdDayList } from '../../../lib/rpt/anrvAnexCashFdDay.api';

// actions
const FETCH_ANRV_ANEX_CASH_FD_DAY_LIST = 'anrvAnexCashFdDay/FETCH_ANRV_ANEX_CASH_FD_DAY_LIST';

// initial state
const initialState = {
  loading: false,
  success: true,
  message: '',
  list: [],
  totalCount: 0,
  common: {},
  head: {},
};

// reducers
export default function anrvAnexCashFdDay(state = initialState, action) {
  switch (action.type) {
    case SUCCESS(FETCH_ANRV_ANEX_CASH_FD_DAY_LIST): // 조회 성공
      return {
        ...state,
        success: true,
        loading: false,
        list: action.payload.data?.output_data,
        totalCount: action.payload?.data?.output_data_cnt,
        common: action.payload.data?.common,
        head: action.payload.data?.output_head,
      };
    case REQUEST(FETCH_ANRV_ANEX_CASH_FD_DAY_LIST): // 조회 요청
      return {
        ...state,
        loading: true,
        success: false,
      };
    case FAILURE(FETCH_ANRV_ANEX_CASH_FD_DAY_LIST): // 조회실패
      return {
        ...initialState,
        loading: false,
        success: false,
        message: action.payload.response?.data?.message,
      };
    default:
      return state;
  }
}

/**
 * 세출보고서 - 세입세출외현금자금일계표 조회
 */
export const selectAnrvAnexCashFdDayList = (searchParams) => {
  return {
    type: FETCH_ANRV_ANEX_CASH_FD_DAY_LIST,
    payload: fetchAnrvAnexCashFdDayList(searchParams),
  };
};
