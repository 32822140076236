import instance from '../../common/config/api';

const PREFIX = `${process.env.REACT_APP_SERVER_URL}/scq/scq/fxp/0004`;

/**
 * 전일 정보 조회
 * @returns
 */
export const fetchBfDt = (params) => instance.post(`${PREFIX}/selectBfDt`, params);

/**
 * 기준일자 정당성 검증 조회
 * @returns
 */
export const fetchBasDtVrfYn = (params) => instance.post(`${PREFIX}/selectBasDtVrfYn`, params);

/**
 * 대사현황 모니터링 조회
 * @returns
 */
export const fetchRcnPtsMntg = (params) => instance.post(`${PREFIX}/selectRcnPtsMntg`, params);

/**
 * 영업점별 대사현황 조회
 * @returns
 */
export const fetchEachBzbrRcnPts = (params) => instance.post(`${PREFIX}/selectEachBzbrRcnPts`, params);

/**
 * 제로페이 승인,정산대사현황
 * @returns
 */
export const fetchZpayApdtAndExuRcncPrts = (params) => instance.post(`${PREFIX}/selectZpayApdtAndExuRcncPrts`, params);
