import { SUCCESS, REQUEST, FAILURE } from '../../../common/utils/action-type-utils';
import {
  updateEsUserPwdForVDI,
  updateEsUserPwdPc,
  updateUserPwd,
  updateUserPwdForVDI,
} from '../../../lib/cmm/signup.api';

// actions
const UPDATE_USER_PWD = 'findPasswordState/UPDATE_USER_PWD';
const RESET_USER_PWD = 'findPasswordState/RESET_USER_PWD';

// initial state
const initialState = {
  updating: false,
  updatingSuccess: null,
  message: '',
};

// reducers
export default function findPasswordState(state = initialState, action) {
  switch (action.type) {
    case SUCCESS(UPDATE_USER_PWD): // 비밀번호 변경 성공
      return {
        ...state,
        updating: false,
        updatingSuccess: action.payload.data.resultCnt > 0,
      };
    case REQUEST(UPDATE_USER_PWD): // 비밀번호 변경 요청
      return {
        ...state,
        updating: true,
        updatingSuccess: null,
        message: '',
      };
    case FAILURE(UPDATE_USER_PWD): // 비밀번호 변경 실패
      return {
        ...state,
        updating: false,
        updatingSuccess: false,
        message: action.payload.response?.data?.message,
      };
    case RESET_USER_PWD:
      return initialState;
    default:
      return state;
  }
}

/**
 * 비밀번호 변경
 */
export const changeUserPwd = (searchParams) => {
  return {
    type: UPDATE_USER_PWD,
    payload: updateUserPwd(searchParams),
  };
};

/**
 * 비밀번호 변경 (VDI)
 */
export const changeUserPwdForVDI = (searchParams) => {
  return {
    type: UPDATE_USER_PWD,
    payload: updateUserPwdForVDI(searchParams),
  };
};

/**
 * 비밀번호 변경 (기존비밀번호)
 */
export const changeUserPwdNew = (searchParams) => {
  return {
    type: UPDATE_USER_PWD,
    payload: updateEsUserPwdPc(searchParams),
  };
};

/**
 * 비밀번호 변경 (기존비밀번호 VDI)
 */
export const changeUserPwdNewVDI = (searchParams) => {
  return {
    type: UPDATE_USER_PWD,
    payload: updateEsUserPwdForVDI(searchParams),
  };
};

/**
 * 초기화
 */
export const resetUserPwd = () => {
  return {
    type: RESET_USER_PWD,
  };
};
