import instance from '../../common/config/api';

/**
 * 일상경비 - 반납금입금 거래명세
 * @param {object} params
 * @returns
 */
export const fetchRtrnAmRcvTrhsList = (params) =>
  instance.post(`${process.env.REACT_APP_SERVER_URL}/scq/scq/ils/0001/selectEvdXpnRtrnAmRcvTrhs`, params);

export const fetchRtrnAmRcvTrhsDtl = (params) =>
  instance.post(`${process.env.REACT_APP_SERVER_URL}/scq/scq/ils/0001/selectEvdXpnTrhsDtl`, params);
