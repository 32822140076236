import { SUCCESS, REQUEST, FAILURE } from '../../../common/utils/action-type-utils';
import { fetchBrList, fetchLevyIstList, fetchRepIstList } from './../../../lib/etc/etc0501m01.api';

// actions
const FETCH_LEVY_IST = 'etc0501m01Combo/FETCH_LEVY_IST'; // 징수기관 콤보
const FETCH_REP_IST = 'etc0501m01Combo/FETCH_REP_IST'; // 수납기관 콤보
const FETCH_BR = 'etc0501m01Combo/FETCH_BR'; // 영업점 콤보

// initial state
const initialState = {
  levyIstLoading: false, // 징수기관 콤보
  levyIstSuccess: false, // 징수기관 콤보
  levyIstList: [], // 징수기관 콤보

  repIstLoading: false, // 수납기관 콤보
  repIstSuccess: false, // 수납기관 콤보
  repIstList: [], // 수납기관 콤보

  brLoading: false, // 영업점 콤보
  brSuccess: false, // 영업점 콤보
  brList: [], // 영업점 콤보
};

// reducers
export default function etc0501m01Combo(state = initialState, action) {
  switch (action.type) {
    case SUCCESS(FETCH_LEVY_IST): // 조회 성공 - 징수기관 콤보
      return {
        ...state,
        levyIstSuccess: true,
        levyIstLoading: false,
        levyIstList: action.payload.data?.main,
      };
    case REQUEST(FETCH_LEVY_IST): // 조회 요청 - 징수기관 콤보
      return {
        ...state,
        levyIstLoading: true,
        levyIstSuccess: false,
      };
    case FAILURE(FETCH_LEVY_IST): // 조회 실패 - 징수기관 콤보
      return {
        ...state,
        levyIstLoading: false,
        levyIstSuccess: false,
        levyIstList: [],
      };
    case SUCCESS(FETCH_REP_IST): // 조회 성공 - 수납기관 콤보
      return {
        ...state,
        repIstSuccess: true,
        repIstLoading: false,
        repIstList: action.payload.data?.main,
      };
    case REQUEST(FETCH_REP_IST): // 조회 요청 - 수납기관 콤보
      return {
        ...state,
        repIstLoading: true,
        repIstSuccess: false,
      };
    case FAILURE(FETCH_REP_IST): // 조회 실패 - 수납기관 콤보
      return {
        ...state,
        repIstLoading: false,
        repIstSuccess: false,
        repIstList: [],
      };
    case SUCCESS(FETCH_BR): // 조회 성공 - 영업점 콤보
      return {
        ...state,
        brSuccess: true,
        brLoading: false,
        brList: action.payload.data?.main,
      };
    case REQUEST(FETCH_BR): // 조회 요청 - 영업점 콤보
      return {
        ...state,
        brLoading: true,
        brSuccess: false,
      };
    case FAILURE(FETCH_BR): // 조회 실패 - 영업점 콤보
      return {
        ...state,
        brLoading: false,
        brSuccess: false,
        brList: [],
      };
    default:
      return state;
  }
}

/**
 * 기타 - 손해배상금 - Data Correction (징수기관 콤보)
 */
export const selectLevyIstList = (searchParams) => {
  return {
    type: FETCH_LEVY_IST,
    payload: fetchLevyIstList(searchParams),
  };
};

/**
 * 기타 - 손해배상금 - Data Correction (징수기관 콤보)
 */
export const selectRepIstList = (searchParams) => {
  return {
    type: FETCH_REP_IST,
    payload: fetchRepIstList(searchParams),
  };
};

/**
 * 기타 - 손해배상금 - Data Correction (징수기관 콤보)
 */
export const selectBrList = (searchParams) => {
  return {
    type: FETCH_BR,
    payload: fetchBrList(searchParams),
  };
};
