import { SUCCESS, REQUEST, FAILURE } from '../../../common/utils/action-type-utils';
import { checkAuthNumber, sendAuthNumber } from '../../../lib/cmm/signup.api';

// actions
const SEND_AUTH_NUMBER = 'authNumberState/SEND_AUTH_NUMBER';
const CHECK_AUTH_NUMBER = 'authNumberState/CHECK_AUTH_NUMBER';
const RESET_AUTH_NUMBER_STATE = 'authNumberState/RESET_AUTH_NUMBER_STATE';

// initial state
const initialState = {
  loadingByAuthSend: null,
  successByAuthSend: null,
  loadingByAuthCheck: false,
  successByAuthCheck: false,
  message: '',
  isAuth: false,
  isTimeout: false,
};

// reducers
export default function authNumberState(state = initialState, action) {
  switch (action.type) {
    case SUCCESS(SEND_AUTH_NUMBER): // 인증번호 전송 성공
      return {
        ...state,
        loadingByAuthSend: false,
        successByAuthSend: true,
      };
    case REQUEST(SEND_AUTH_NUMBER): // 인증번호 전송
      return {
        ...state,
        loadingByAuthSend: true,
        successByAuthSend: false,
        message: '',
      };
    case FAILURE(SEND_AUTH_NUMBER): // 인증번호 전송 실패
      return {
        ...state,
        loadingByAuthSend: false,
        successByAuthSend: false,
        message: action.payload.response?.data?.message,
      };
    case SUCCESS(CHECK_AUTH_NUMBER): // 인증번호 검증 요청 성공
      return {
        ...state,
        loadingByAuthCheck: false,
        successByAuthCheck: true,
        isAuth: action.payload.data.crtfNo !== '000000' && action.payload.data.crtfNo !== '000001',
        isTimeout: action.payload.data.crtfNo === '000001',
      };
    case REQUEST(CHECK_AUTH_NUMBER): // 인증번호 검증 요청
      return {
        ...state,
        loadingByAuthCheck: true,
        successByAuthCheck: false,
        isAuth: false,
        message: '',
      };
    case FAILURE(CHECK_AUTH_NUMBER): // 인증번호 검증 요청 실패
      return {
        ...state,
        loadingByAuthCheck: false,
        successByAuthCheck: false,
        isAuth: false,
        message: action.payload.response?.data?.message,
      };
    case RESET_AUTH_NUMBER_STATE: // 인증번호 검증 요청 성공
      return initialState;
    default:
      return state;
  }
}

/**
 * 인증번호 전송
 */
export const sendAuthNumberToHpNo = (searchParams) => {
  return {
    type: SEND_AUTH_NUMBER,
    payload: sendAuthNumber(searchParams),
  };
};

/**
 * 인증번호 검증
 */
export const checkAuthNumberByAuthNo = (searchParams) => {
  return {
    type: CHECK_AUTH_NUMBER,
    payload: checkAuthNumber(searchParams),
  };
};

/**
 * 초기화
 */
export const resetAuthNumberState = () => {
  return {
    type: RESET_AUTH_NUMBER_STATE,
  };
};
