import instance from '../../common/config/api';

/**
 * 법인카드-사용자권한 관리
 * @param {object} params
 * @returns
 */
export const fetchListUserAutMngList = (params) =>
  instance.post(`${process.env.REACT_APP_SERVER_URL}/scq/scq/cad/0004/selectListUserAutMng`, params);

/**
 * 법인카드-사용자권한 관리 수정
 * @param {object} params
 * @returns
 */
export const updateListUserAutMngList = (dtoList) =>
  instance.post(`${process.env.REACT_APP_SERVER_URL}/scq/scq/cad/0004/updateListUserAutMng`, {
    subDto: dtoList,
  });
