import instance from '../../common/config/api';

/**
 * 시스템관리 - 기관/부서 코드관리
 * @param {object} params
 * @returns
 */

export const fetchIstList = (params) =>
  instance.post(`${process.env.REACT_APP_SERVER_URL}/scq/scq/sys/0001/selectIst`, params);

export const insertIstDePt = (dtos) =>
  instance.post(`${process.env.REACT_APP_SERVER_URL}/scq/scq/sys/0001/insertIstDept`, {
    subDto: dtos,
  });

export const fetchDeptList = (params) =>
  instance.post(`${process.env.REACT_APP_SERVER_URL}/scq/scq/sys/0001/selectDept`, params);
