import instance from '../../common/config/api';

/**
 * 제로페이 - 집행내역
 * @param {object} params
 * @returns
 */
export const fetchZpbExuPrtsList = (params) =>
  instance.post(`${process.env.REACT_APP_SERVER_URL}/scq/scq/zpb/0001/selectZeroExuPrtsList`, params);

/**
 * 제로페이 - 집행내역 (카카오톡전송)
 * @param {array} params
 * @returns
 */

export const saveZpbExuPrtsKkoTms = (dtos) =>
  instance.post(`${process.env.REACT_APP_SERVER_URL}/scq/scq/zpb/0001/insertZeroKkoTms`, {
    subDto: dtos,
  });

/**
 * 제로페이 - 집행내역 (sms전송)
 * @param {array} params
 * @returns
 */

export const saveZpbExuPrtsSmsTms = (dtos) =>
  instance.post(`${process.env.REACT_APP_SERVER_URL}/scq/scq/zpb/0001/insertZeroSmsTms`, {
    subDto: dtos,
  });
