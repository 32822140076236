import React, { useEffect, useState } from 'react';
import WOToggleButton from '../common/WOToggleButton';
import styled, { css } from 'styled-components';
import WOButton from '../common/WOButton';
import WOIconButton from '../common/WOIconButton';
import WorkSelectModal from '../../views/main/WorkSelectModal';
import { useDispatch, useSelector } from 'react-redux';
import useAlert from '../../hooks/useAlert';
import { addSelectedWorkList, delSelectedWorkList } from '../../redux/modules/cmm/work.reducer';
import { useLocation, useNavigate } from 'react-router-dom';
import useSession from '../../hooks/useSession';
import { saveBizFavt } from '../../redux/modules/cmm/bookmark.reducer';
import WOIcon from '../common/WOIcon';

const Side = ({ onOpen, isOpen, menuList, active, user }) => {
  const session = useSession().getUserInfo();
  const [isOpenMoal, setIsOpenModal] = useState(false);
  const navigate = useNavigate();
  const location = useLocation();
  const dispatch = useDispatch();
  const { alert } = useAlert();
  const { /* loading, success, message, */ list: selectedWorkList } = useSelector((state) => state.workState);
  const { /* loading, success, message, */ list: favtList } = useSelector((state) => state.bookmarkState);

  const toggleIsOpen = () => {
    onOpen && onOpen(!isOpen);
  };

  /**
   * 선택된 업무 제거
   */
  const handleRemoveSelectWork = (id) => {
    dispatch(delSelectedWorkList(id));
  };

  /**
   * 즐겨찾기 토글
   */
  const handleToggleFavt = (checked, menu) => {
    // 저장
    dispatch(
      saveBizFavt({
        user_id: session.user_id,
        cre_user_id: session.user_id,
        favt_usg_yn: checked ? 'Y' : 'N',
        mnu_aut_cd: menu.mnu_aut_cd,
        mnu_seq_no: menu.mnu_seq_no,
        sys_seq_no: menu.sys_seq_no,
        use_yn: '1',
      })
    );
  };

  useEffect(() => {
    const blackList = ['/page/login', '/page/Signup', '/page/ReportPage', '/'];
    const lv3 = menuList.find((menu) => menu.scrn_url?.trim() === location.pathname?.trim());
    if (!lv3) {
      return;
    }
    const lv2 = menuList.find((menu) => menu.mnu_seq_no === lv3.higrk_mnu_seq_no);
    const lv1 = menuList.find((menu) => menu.mnu_seq_no === lv2.higrk_mnu_seq_no);
    if (!blackList.includes(location.pathname)) {
      const favoriteCount = favtList.length;
      const favoriteIds = favtList.map((favt) => favt.mnu_seq_no);
      const workCount = selectedWorkList.filter((work) => !favoriteIds.includes(work.mnu_seq_no)).length;

      // let includeCount = favtList.filter((item) => item.scrn_url === location.pathname).length;
      // includeCount = includeCount + selectedWorkList.filter((item) => item.scrn_url === location.pathname).length;

      // if (favoriteCount + workCount >= 30) {
      //   // 30개 제한
      //   if (includeCount > 0) {
      //   } else {
      //     alert('선택된 업무는 최대 30개까지 가능합니다.\n선택된 업무 삭제 후 추가해주세요');
      //     navigate(-1);
      //   }
      //   return;
      // }

      dispatch(
        addSelectedWorkList({
          ...lv3,
          sys_nm: lv1?.mnu_nm,
          higrk_mnu_nm: lv2?.mnu_nm,
        })
      );
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [dispatch, location.pathname]);

  return (
    <Wrapper $isOpen={isOpen}>
      <Inner $isOpen={isOpen}>
        <ContentWrap>
          <TopMenu>
            <div className="title-wrap">
              <h2 className="title">선택된 업무</h2>
              <WOButton
                variant="outlined"
                size={'sm'}
                startIcon={<WOIcon icon={'plus-black'} width={16} height={16} />}
                onClick={() => {
                  setIsOpenModal(true);
                }}>
                업무 추가
              </WOButton>
            </div>

            <div className="subTitle-wrap">
              <WOIcon icon={'star'} width={16} height={16} />
              <span>을 선택해 자주 쓰는 업무를 추가해 주세요.</span>
            </div>
          </TopMenu>
          <WorkList>
            {favtList
              .filter((item) => !selectedWorkList.map((work) => work.mnu_seq_no).includes(item.mnu_seq_no))
              .map((favt, index) => (
                <WorkItem key={`favt-${index}`} $active={location.pathname === favt.scrn_url}>
                  <ItemOption>
                    <div className="starred-tasks">
                      <WOToggleButton
                        width={16}
                        height={16}
                        icon="star"
                        checked={true}
                        onChange={(checked) => {
                          handleToggleFavt(checked, favt);
                        }}></WOToggleButton>
                      <span>자주 쓰는 업무</span>
                      {/* <span>{checked ? "자주 쓰는 업무" : "선택된 업무"}</span> */}
                    </div>
                    <WOIconButton width={16} height={16} icon="close" onClick={() => handleToggleFavt(false, favt)} />
                  </ItemOption>
                  <ItemDepth>
                    {favt.sys_nm && favt.mnu_lvl !== 1 && (
                      <span className="depth" onClick={() => navigate(favt.scrn_url)}>
                        {favt.sys_nm}
                      </span>
                    )}
                    {favt.higrk_mnu_nm && (
                      <>
                        {favt.sys_nm && favt.mnu_lvl !== 1 && <span className="arr"></span>}
                        <span className="depth" onClick={() => navigate(favt.scrn_url)}>
                          {favt.higrk_mnu_nm}
                        </span>
                      </>
                    )}
                    {favt.mnu_nm && (
                      <>
                        <span className="arr"></span>
                        <span className="depth" onClick={() => navigate(favt.scrn_url)}>
                          {favt.mnu_nm}
                        </span>
                      </>
                    )}
                  </ItemDepth>
                </WorkItem>
              ))}
            {selectedWorkList.map((selectedWork, index) => {
              const isFavt = favtList.map((favt) => favt.mnu_seq_no).includes(selectedWork.mnu_seq_no);
              return (
                <WorkItem key={`work-${index}`} $active={location.pathname === selectedWork.scrn_url}>
                  <ItemOption>
                    <div className="starred-tasks">
                      <WOToggleButton
                        width={16}
                        height={16}
                        icon="star"
                        checked={isFavt}
                        onChange={(checked) => {
                          handleToggleFavt(checked, selectedWork);
                        }}></WOToggleButton>
                      <span>자주 쓰는 업무</span>
                    </div>
                    <WOIconButton
                      width={16}
                      height={16}
                      icon="close"
                      onClick={() => {
                        handleRemoveSelectWork(selectedWork.mnu_seq_no);
                        if (isFavt) {
                          handleToggleFavt(false, selectedWork);
                        }
                      }}
                    />
                  </ItemOption>
                  <ItemDepth>
                    {selectedWork.sys_nm && (
                      <span
                        className="depth"
                        onClick={() => navigate(selectedWork.scrn_url, { state: { istNo: selectedWork.ist_no } })}>
                        {selectedWork.sys_nm}
                      </span>
                    )}
                    {selectedWork.higrk_mnu_nm && (
                      <>
                        {selectedWork.sys_nm && <span className="arr"></span>}
                        <span
                          className="depth"
                          onClick={() => navigate(selectedWork.scrn_url, { state: { istNo: selectedWork.ist_no } })}>
                          {selectedWork.higrk_mnu_nm}
                        </span>
                      </>
                    )}
                    {selectedWork.mnu_nm && (
                      <>
                        <span className="arr"></span>
                        <span
                          className="depth"
                          onClick={() => navigate(selectedWork.scrn_url, { state: { istNo: selectedWork.ist_no } })}>
                          {selectedWork.mnu_nm}
                        </span>
                      </>
                    )}
                  </ItemDepth>
                  <ItemBottom>
                    <span>{selectedWork.ist_nm}</span>
                    {/* <span className="item-divider"></span>
                    <span>지원팀</span> */}
                  </ItemBottom>
                </WorkItem>
              );
            })}
          </WorkList>
        </ContentWrap>
      </Inner>
      <OpenBtn type="button" $isOpen={isOpen} onClick={toggleIsOpen}></OpenBtn>

      <WorkSelectModal isOpen={isOpenMoal} user={user} onClose={() => setIsOpenModal(false)} />
    </Wrapper>
  );
};

export default Side;

const Wrapper = styled.div`
  position: fixed;
  top: 0;
  left: 80px;
  height: 100vh;
  z-index: 800;
`;
const Inner = styled.div`
  height: 100%;
  background: linear-gradient(180deg, #ebedff 0%, #ffeaea 100%);
  overflow: hidden;
  transition: 0.5s;

  ${(props) =>
    props.$isOpen === true
      ? css`
          width: 294px;
        `
      : css`
          width: 0px;
        `};
`;

const TopMenu = styled.div`
  display: flex;
  flex-direction: column;
  padding: 40px 24px 24px;

  .title-wrap {
    display: flex;
    align-items: center;
    justify-content: space-between;
    margin-bottom: 15px;
    .title {
      font-size: 18px;
      font-weight: 700;
      color: #333;
      line-height: 27px;
    }
  }

  .subTitle-wrap {
    display: flex;
    align-items: center;
    span {
      color: var(--C-Dark-08, #566479);
      font-size: 12px;
      font-weight: 500;
      line-height: 18px;
    }
  }
`;

const ContentWrap = styled.div`
  display: flex;
  flex-direction: column;
  width: 294px;
  height: 100%;
`;

const WorkList = styled.ul`
  height: 100%;
  background: rgba(255, 255, 255, 0.5);
  overflow-y: overlay;
`;
const WorkItem = styled.li`
  position: relative;
  padding: 16px 24px;
  & + & {
    margin-top: 1px;
    &::before {
      content: '';
      position: absolute;
      top: -1px;
      left: 24px;
      width: calc(100% - 48px);
      height: 1px;
      background-color: #0e213f;
      opacity: 0.1;
    }
  }
  &:hover,
  &:active,
  &.active {
    background: #fff;
  }
  ${(props) =>
    props.$active &&
    css`
      background: #fff;
    `}
`;

const ItemDepth = styled.div`
  display: flex;
  align-items: center;
  flex-wrap: wrap;
  cursor: pointer;
  .depth {
    display: block;
    color: #555;
    font-size: 14px;
    font-weight: 400;
    line-height: 21px;
  }
  .arr {
    display: block;
    width: 20px;
    height: 20px;
    background: url(/assets/images/icon/arrow-bread-gray.svg) no-repeat center / cover;
  }
`;

const ItemDiscription = styled.div`
  display: flex;
  align-items: center;
  margin-top: 4px;
  color: var(--C-Dark-06, #9ea6b2);
  font-size: 12px;
  font-weight: 400;
  line-height: 18px;

  .line {
    width: 1px;
    height: 9px;
    margin: 0 6px;
    background: var(--C-Dark-06, #9ea6b2);
  }
`;

const ItemOption = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 8px;
  .starred-tasks {
    display: flex;
    align-items: center;
    font-size: 12px;
    font-weight: 600;
    color: #555;
    gap: 4px;
    span {
      display: block;
      line-height: 24px;
    }
  }
`;

const OpenBtn = styled.button`
  position: absolute;
  top: 50%;
  right: -28px;
  transform: translateY(-50%);
  width: 28px;
  height: 100px;
  background: url(/assets/images/lnb_side_button_open.svg) no-repeat center / cover;
  ${(props) =>
    props.$isOpen &&
    css`
      background: url(/assets/images/lnb_side_button_close.svg) no-repeat center / cover;
    `}
`;

const ItemBottom = styled.div`
  margin-top: 4px;
  display: flex;
  align-items: center;
  gap: 6px;
  font-size: 12px;
  font-weight: 400;
  color: #9ea6b2;
  .item-divider {
    display: inline-block;
    width: 1px;
    height: 9px;
    vertical-align: top;
    background: #9ea6b2;
  }
`;
