import {
  fetchDbSysdate,
  fetchGndpActInf,
  fetchGndpActTrnPrts,
  fetchTrhs,
  fetchActOgbrMnbrNm,
} from '../../../lib/fxp/MmdaTrnPrtsInq.api';

import { SUCCESS, REQUEST, FAILURE } from '../../../common/utils/action-type-utils';
import dayjs from 'dayjs';
import { fetchCommonComboList } from '../../../lib/cmm/combo.api';

// actions
const FETCH_DB_SYSDATE = 'MmdaTrnPrtsInq/FETCH_DB_SYSDATE';
const FETCH_GNDP_ACT_INF = 'MmdaTrnPrtsInq/FETCH_GNDP_ACT_INF';
const FETCH_GNDP_ACT_TRN_PRTS = 'MmdaTrnPrtsInq/FETCH_GNDP_ACT_TRN_PRTS';
const FETCH_TRHS = 'MmdaTrnPrtsInq/FETCH_TRHS';
const FETCH_ACT_OGBR_MNBR_NM = 'MmdaTrnPrtsInq/FETCH_ACT_OGBR_MNBR_NM';

const FETCH_IST_INFO_LIST = 'MmdaTrnPrtsInq/FETCH_IST_INFO_LIST';
const FETCH_DEPT_INFO_LIST = 'MmdaTrnPrtsInq/FETCH_DEPT_INFO_LIST';
const FETCH_ACT_NO_LIST = 'MmdaTrnPrtsInq/FETCH_ACT_NO_LIST';
const FETCH_BIZ_NO_LIST = 'MmdaTrnPrtsInq/FETCH_BIZ_NO_LIST';

const RESET_INITIAL_STATE = 'MmdaTrnPrtsInq/RESET_INITIAL_STATE';
const SAVE_SEARCH_FORM_VALUES = 'MmdaTrnPrtsInq/SAVE_SEARCH_FORM_VALUES';

const CLEAR_MESSAGE = 'MmdaTrnPrtsInq/CLEAR_MESSAGE';
const SET_BEFORE_SEARCH_PARAM = 'MmdaTrnPrtsInq/SET_BEFORE_SEARCH_PARAM';

const RESET_LIST_STATE = 'MmdaTrnPrtsInq/RESET_LIST_STATE';

export const initFormValues = {
  cus_ist_cd: '6110000',
  cus_dept_cd: 'all',
  biz_no: 'all',
  ydcs8a1_t8a12_act_no: 'all',
  ydcs8a1_t8a12_inq_sdt: dayjs().add(-1, 'M').format('YYYYMMDD'),
  ydcs8a1_t8a12_inq_edt: dayjs().format('YYYYMMDD'),
  pmdp_act_dscd: '02',
  ydcs8a1_t8a01_ser_no: '00000000',
  pgm_dis: '',
  ydcs8a1_t8a12_npbok_yn: '',
};

// initial state
const initialState = {
  loading: false,
  success: false,
  message: '',
  list: [],

  dbSysdate: {},
  dbSysdateLoading: false,
  dbSysdateSuccess: false,

  gndpActInf: [],
  gndpActInfLoading: false,
  gndpActInfSuccess: false,
  gndpActInfMessage: '',

  gndpActTrnPrts: [],
  gndpActTrnPrtsLoading: false,
  gndpActTrnPrtsSuccess: false,
  gndpActTrnPrtsMessage: '',

  trhs: [],
  trhsLoading: false,
  trhsSuccess: false,
  trhsMessage: '',

  actOgbrMnbrNm: '',
  totalCount: 0,

  beforeSearchParam: {},

  //콤보 - 기관명 922
  istInfoSuccess: false,
  istInfoLoading: false,
  istInfoList: [],

  //콤보 - 부서명 174
  deptInfoSuccess: false,
  deptInfoLoading: false,
  deptInfoList: [],

  //콤보 - 계좌번호 925
  actNoSuccess: false,
  actNoLoading: false,
  actNoList: [],

  //콤보 - 사업자번호 044
  bizNoSuccess: false,
  bizNoLoading: false,
  bizNoList: [],

  // 검색조건
  searchFormValues: {
    init: true,
    ...initFormValues,
  },
};

// reducers
export default function MmdaTrnPrtsInq(state = initialState, action) {
  switch (action.type) {
    case SUCCESS(FETCH_DB_SYSDATE): // 조회 성공
      return {
        ...state,
        dbSysdateSuccess: true,
        dbSysdateLoading: false,
        dbSysdate: action.payload.data?.output_sysdate,
      };
    case REQUEST(FETCH_DB_SYSDATE): // 조회 요청
      return {
        ...state,
        dbSysdate: {},
        dbSysdateLoading: true,
      };
    case FAILURE(FETCH_DB_SYSDATE): // 조회실패
      return {
        ...state,
        dbSysdate: {},
        dbSysdateLoading: false,
        dbSysdateSuccess: false,
      };
    case SUCCESS(FETCH_GNDP_ACT_INF): // 조회 성공
      return {
        ...state,
        gndpActInfSuccess: true,
        gndpActInfLoading: false,
        gndpActInf: action.payload.data?.output_data_01,
      };
    case REQUEST(FETCH_GNDP_ACT_INF): // 조회 요청
      return {
        ...state,
        gndpActInfLoading: true,
      };
    case FAILURE(FETCH_GNDP_ACT_INF): // 조회실패
      return {
        ...state,
        gndpActInf: [],
        gndpActInfLoading: false,
        gndpActInfSuccess: false,
        gndpActInfMessage: action.payload.response?.data?.message,
      };
    case SUCCESS(FETCH_GNDP_ACT_TRN_PRTS): // 조회 성공
      return {
        ...state,
        gndpActTrnPrtsSuccess: true,
        gndpActTrnPrtsLoading: false,
        gndpActTrnPrts: action.payload.data?.output_data,
      };
    case REQUEST(FETCH_GNDP_ACT_TRN_PRTS): // 조회 요청
      return {
        ...state,
        gndpActTrnPrts: [],
        gndpActTrnPrtsLoading: true,
      };
    case FAILURE(FETCH_GNDP_ACT_TRN_PRTS): // 조회실패
      return {
        ...state,
        gndpActTrnPrts: [],
        gndpActTrnPrtsLoading: false,
        gndpActTrnPrtsSuccess: false,
        gndpActTrnPrtsMessage: action.payload.response?.data?.message,
      };
    case SUCCESS(FETCH_TRHS): // 조회 성공
      return {
        ...state,
        trhsSuccess: true,
        trhsLoading: false,
        trhs: action.payload.data?.output_data_s,
      };
    case REQUEST(FETCH_TRHS): // 조회 요청
      return {
        ...state,
        trhs: [],
        loading: true,
      };
    case FAILURE(FETCH_TRHS): // 조회실패
      return {
        ...state,
        trhs: [],
        loading: false,
        success: false,
        //message: action.payload.response?.data?.message,
      };
    case SUCCESS(FETCH_ACT_OGBR_MNBR_NM): // 조회 성공
      return {
        ...state,
        success: true,
        loading: false,
        list: action.payload.data?.subDto,
        totalCount: action.payload?.subDtoCnt,
      };
    case REQUEST(FETCH_ACT_OGBR_MNBR_NM): // 조회 요청
      return {
        ...state,
        loading: true,
      };
    case FAILURE(FETCH_ACT_OGBR_MNBR_NM): // 조회실패
      return {
        ...state,
        list: [],
        loading: false,
        success: false,
        message: action.payload.response?.data?.message,
      };
    // 기관명
    case SUCCESS(FETCH_IST_INFO_LIST): // 조회 성공
      return {
        ...state,
        istInfoSuccess: true,
        istInfoLoading: false,
        istInfoList: action.payload.data?.subDto,
      };
    case REQUEST(FETCH_IST_INFO_LIST): // 조회 요청
      return {
        ...state,
        istInfoLoading: true,
        istInfoSuccess: false,
      };
    case FAILURE(FETCH_IST_INFO_LIST): // 조회실패
      return {
        istInfoLoading: false,
        istInfoSuccess: false,
        istInfoList: [],
      };
    // 부서명
    case SUCCESS(FETCH_DEPT_INFO_LIST): // 조회 성공
      return {
        ...state,
        deptInfoSuccess: true,
        deptInfoLoading: false,
        deptInfoList: action.payload.data?.subDto,
      };
    case REQUEST(FETCH_DEPT_INFO_LIST): // 조회 요청
      return {
        ...state,
        deptInfoLoading: true,
        deptInfoSuccess: false,
      };
    case FAILURE(FETCH_DEPT_INFO_LIST): // 조회실패
      return {
        ...state,
        deptInfoLoading: false,
        deptInfoSuccess: false,
        deptInfoList: [],
      };
    // 계좌번호
    case SUCCESS(FETCH_ACT_NO_LIST): // 조회 성공
      return {
        ...state,
        actNoSuccess: true,
        actNoLoading: false,
        actNoList: action.payload.data?.subDto,
      };
    case REQUEST(FETCH_ACT_NO_LIST): // 조회 요청
      return {
        ...state,
        actNoLoading: true,
        actNoSuccess: false,
      };
    case FAILURE(FETCH_ACT_NO_LIST): // 조회실패
      return {
        ...state,
        actNoLoading: false,
        actNoSuccess: false,
        actNoList: [],
      };
    // 사업자번호
    case SUCCESS(FETCH_BIZ_NO_LIST): // 조회 성공
      return {
        ...state,
        bizNoSuccess: true,
        bizNoLoading: false,
        bizNoList: action.payload.data?.subDto,
      };
    case REQUEST(FETCH_BIZ_NO_LIST): // 조회 요청
      return {
        ...state,
        bizNoLoading: true,
        bizNoSuccess: false,
      };
    case FAILURE(FETCH_BIZ_NO_LIST): // 조회실패
      return {
        ...state,
        bizNoLoading: false,
        bizNoSuccess: false,
        bizNoList: [],
      };
    case SAVE_SEARCH_FORM_VALUES:
      return {
        ...state,
        istInfoSuccess: false,
        istInfoLoading: false,
        deptInfoSuccess: false,
        deptInfoLoading: false,
        actNoSuccess: false,
        actNoLoading: false,
        bizNoSuccess: false,
        bizNoLoading: false,
        searchFormValues: action.payload,
      };
    case RESET_INITIAL_STATE:
      return initialState;
    case SET_BEFORE_SEARCH_PARAM:
      return {
        ...state,
        beforeSearchParam: action.payload,
      };
    case RESET_LIST_STATE:
      return {
        ...state,
        list: [],
      };
    case CLEAR_MESSAGE:
      return {
        ...state,
        trhsSuccess: false,
        trhsMessage: '',
        gndpActTrnPrtsSuccess: false,
        gndpActTrnPrtsMessage: '',
        gndpActInfSuccess: false,
        gndpActInfMessage: '',
        success: false,
        message: '',
      };
    default:
      return state;
  }
}

/**
 * DB 서버시간 조회
 */
export const selectDbSysdate = (searchParams) => {
  return {
    type: FETCH_DB_SYSDATE,
    payload: fetchDbSysdate(searchParams),
  };
};

/**
 * 보통예금 계좌정보조회
 */
export const selectGndpActInf = (searchParams) => {
  return {
    type: FETCH_GNDP_ACT_INF,
    payload: fetchGndpActInf(searchParams),
  };
};

/**
 * 보통예금 계좌거래내역 조회
 */
export const selectGndpActTrnPrts = (searchParams) => {
  return {
    type: FETCH_GNDP_ACT_TRN_PRTS,
    payload: fetchGndpActTrnPrts(searchParams),
  };
};

/**
 * 거래명세 조회
 */
export const selectTrhs = (searchParams) => {
  return {
    type: FETCH_TRHS,
    payload: fetchTrhs(searchParams),
  };
};

/**
 * 계좌 개설점/관리점 조회
 */
export const selectActOgbrMnbrNm = (searchParams) => {
  return {
    type: FETCH_ACT_OGBR_MNBR_NM,
    payload: fetchActOgbrMnbrNm(searchParams),
  };
};

/**
 * 메시지초기화
 */
export const clearMessage = () => {
  return {
    type: CLEAR_MESSAGE,
  };
};

/**
 *
 */
export const setBeforeSearchParam = (searchParams) => {
  return {
    type: SET_BEFORE_SEARCH_PARAM,
    payload: searchParams,
  };
};

//기관명 콤보 조회
export const selectIstInfoList = (searchParam) => {
  return {
    type: FETCH_IST_INFO_LIST,
    payload: fetchCommonComboList({ slt_box_no: '922', ...searchParam }),
  };
};

//부서명 콤보 조회
export const selectDeptInfoList = (searchParam) => {
  return {
    type: FETCH_DEPT_INFO_LIST,
    payload: fetchCommonComboList({ slt_box_no: '174', ...searchParam }),
  };
};

//계좌번호 콤보 조회
export const selectActNoList = (searchParam) => {
  return {
    type: FETCH_ACT_NO_LIST,
    payload: fetchCommonComboList({ slt_box_no: '925', ...searchParam }),
  };
};

//사업자번호 콤보 조회
export const selectBizNoList = (searchParam) => {
  return {
    type: FETCH_BIZ_NO_LIST,
    payload: fetchCommonComboList({ slt_box_no: '044', ...searchParam }),
  };
};

/**
 * 조회조건 저장
 */
export const saveSearchFormValues = (searchFormValues) => {
  return {
    type: SAVE_SEARCH_FORM_VALUES,
    payload: {
      ...searchFormValues,
      init: false,
    },
  };
};

/**
 * 상태초기화
 */
export const resetInitialState = () => {
  return {
    type: RESET_INITIAL_STATE,
  };
};

/**
 * 법인카드 조회시 리스트 초기화
 */
export const resetListStlActtrnPrts = () => {
  return {
    type: RESET_LIST_STATE,
  };
};
