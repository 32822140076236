import { SUCCESS, REQUEST, FAILURE } from '../../../common/utils/action-type-utils';
import { fetchCommonComboList } from '../../../lib/cmm/combo.api';
import { fetchEtcActInq, saveEtcActInq } from '../../../lib/etc/ETC0401V01.api';

// actions
const FETCH_ETC_ACT_INQ_LIST = 'ETC0401V01/FETCH_ETC_ACT_INQ_LIST';
const SAVE_ETC_ACT_INQ_LIST = 'ETC0401V01/SAVE_ETC_ACT_INQ_LIST';
const FETCH_IST_INFO_LIST = 'ETC0401V01/FETCH_IST_INFO_LIST';
const SAVE_SEARCH_FORM_VALUES = 'ETC0401V01/SAVE_SEARCH_FORM_VALUES';
const RESET_STATE = 'ETC0401V01/RESET_STATE';

export const initFormValues = {};

// initial state
const initialState = {
  loading: false,
  success: true,
  message: '',
  list: [],
  totalCount: 0,
  updateSuccess: false,
  resultCnt: -1,
  // 콤보 - 기관명
  istInfoSuccess: false,
  istInfoLoading: false,
  istInfoList: [],
  searchFormValues: {
    init: true,
    ...initFormValues,
  },
};

// reducers
export default function ETC0401V01(state = initialState, action) {
  switch (action.type) {
    case SUCCESS(FETCH_ETC_ACT_INQ_LIST): // 조회 성공
      return {
        ...state,
        success: true,
        loading: false,
        list: action.payload.data?.subDto,
        totalCount: action.payload?.subDtoCnt,
        updateSuccess: false,
      };
    case REQUEST(FETCH_ETC_ACT_INQ_LIST): // 조회 요청
      return {
        ...state,
        message: '',
        loading: true,
        success: false,
        updateSuccess: false,
      };
    case FAILURE(FETCH_ETC_ACT_INQ_LIST): // 조회실패
      return {
        ...state,
        loading: false,
        success: false,
        message: action.payload.response?.data?.message,
        updateSuccess: false,
      };
    case SUCCESS(SAVE_ETC_ACT_INQ_LIST): // 저장 성공
      return {
        ...state,
        updateSuccess: true,
        loading: false,
        resultCnt: action.payload.data.resultCnt,
      };
    case REQUEST(SAVE_ETC_ACT_INQ_LIST): // 저장 요청
      return {
        ...state,
        message: '',
        loading: true,
        updateSuccess: false,
      };
    case FAILURE(SAVE_ETC_ACT_INQ_LIST): // 저장 실패
      return {
        ...state,
        loading: false,
        updateSuccess: false,
        resultCnt: -1,
        message: action.payload.response?.data?.message,
      };
    // 기관명
    case SUCCESS(FETCH_IST_INFO_LIST): // 조회 성공
      return {
        ...state,
        istInfoSuccess: true,
        istInfoLoading: false,
        istInfoList: action.payload.data?.subDto,
      };
    case REQUEST(FETCH_IST_INFO_LIST): // 조회 요청
      return {
        ...state,
        istInfoLoading: true,
        istInfoSuccess: false,
      };
    case FAILURE(FETCH_IST_INFO_LIST): // 조회실패
      return {
        ...state,
        istInfoLoading: false,
        istInfoSuccess: false,
        istInfoList: [],
      };
    case SAVE_SEARCH_FORM_VALUES:
      return {
        ...state,
        // 기관명
        istInfoLoading: false,
        istInfoSuccess: false,
        searchFormValues: action.payload,
      };
    case RESET_STATE:
      return initialState;
    default:
      return state;
  }
}

/**
 * 기타 - 서울페이플러스 - 계좌조회
 */
export const selectListEtcActInq = (searchParams) => {
  return {
    type: FETCH_ETC_ACT_INQ_LIST,
    payload: fetchEtcActInq(searchParams),
  };
};

/**
 * 기타 - 서울페이플러스 - 계좌 등록/수정/삭제
 */
export const saveListEtcActInq = (dtos) => {
  return {
    type: SAVE_ETC_ACT_INQ_LIST,
    payload: saveEtcActInq(dtos),
  };
};

/**
 * 기관명 콤보 조회
 */
export const selectIstInfoComboList = (searchParams) => {
  return {
    type: FETCH_IST_INFO_LIST,
    payload: fetchCommonComboList({ slt_box_no: '005', ...searchParams }),
  };
};

/**
 * 조회조건 저장
 */
export const saveSearchFormValues = (searchFormValues) => {
  return {
    type: SAVE_SEARCH_FORM_VALUES,
    payload: {
      ...searchFormValues,
      init: false,
    },
  };
};

/**
 * 법인카드 상태초기화
 */
export const resetState = () => {
  return {
    type: RESET_STATE,
  };
};
