import { SUCCESS, REQUEST, FAILURE } from '../../../common/utils/action-type-utils';
import { fetchFindUserId } from '../../../lib/cmm/signup.api';

// actions
const FETCH_FIND_USER_ID = 'findIdState/FETCH_FIND_USER_ID';
const RESET_FIND_USER_ID = 'findIdState/RESET_FIND_USER_ID';

// initial state
const initialState = {
  updating: false,
  updatingSuccess: null,
  message: '',
  pmnySafUserId: null,
};

// reducers
export default function findIdState(state = initialState, action) {
  switch (action.type) {
    case SUCCESS(FETCH_FIND_USER_ID): // 아이디찾기 요청성공
      return {
        ...state,
        updating: false,
        updatingSuccess: true,
        pmnySafUserId: action.payload.data?.pmnySafUserId,
      };
    case REQUEST(FETCH_FIND_USER_ID): // 아이디찾기 요청
      return {
        ...state,
        updating: true,
        updatingSuccess: null,
        message: '',
        pmnySafUserId: '',
      };
    case FAILURE(FETCH_FIND_USER_ID): // 아이디찾기 요청실패
      return {
        ...state,
        updating: false,
        updatingSuccess: false,
        message: action.payload.response?.data?.message,
        pmnySafUserId: '',
      };
    case RESET_FIND_USER_ID:
      return initialState;
    default:
      return state;
  }
}

/**
 * 아이디 찾기
 */
export const findUserId = (searchParams) => {
  return {
    type: FETCH_FIND_USER_ID,
    payload: fetchFindUserId(searchParams),
  };
};

/** 초기화 */
export const resetFindUserId = () => {
  return {
    type: RESET_FIND_USER_ID,
  };
};
