import { SUCCESS, REQUEST, FAILURE } from '../../../common/utils/action-type-utils';
import {
  fetchDeptMngListByCard,
  fetchDeptMngListByDept,
  updateDeptMngListByCard,
  updateDeptMngListByDept,
} from '../../../lib/cad/deptMng.api';
import { fetchCommonComboList } from '../../../lib/cmm/combo.api';

// actions
const FETCH_DEPT_MNG_BY_DEPT_LIST = 'deptMng/FETCH_DEPT_MNG_BY_DEPT_LIST';
const FETCH_DEPT_MNG_BY_CARD_LIST = 'deptMng/FETCH_DEPT_MNG_BY_CARD_LIST';
const UPDATE_DEPT_MNG_BY_DEPT_LIST = 'deptMng/UPDATE_DEPT_MNG_BY_DEPT_LIST';
const UPDATE_DEPT_MNG_BY_CARD_LIST = 'deptMng/UPDATE_DEPT_MNG_BY_CARD_LIST';

const FETCH_IST_INFO_LIST = 'deptMng/FETCH_IST_INFO_LIST';
const FETCH_DEPT_CD_LIST = 'deptMng/FETCH_DEPT_CD_LIST';
const FETCH_BIZ_NO_LIST = 'deptMng/FETCH_BIZ_NO_LIST';
const FETCH_CUS_IST_CD_LIST = 'deptMng/FETCH_CUS_IST_CD_LIST';
const FETCH_DEPT_INFO_LIST = 'deptMng/FETCH_DEPT_INFO_LIST';
const SAVE_SEARCH_FORM_VALUES = 'deptMng/SAVE_SEARCH_FORM_VALUES';
const RESET_STATE = 'deptMng/RESET_STATE';

export const initFormValues = {
  cd_use_yn: 'Y',
  cd_alrpt_dscd: 'all',
};

// initial state
const initialState = {
  loading: false,
  subLoading: false,
  mainUpdating: false,
  subUpdating: false,
  success: false,
  subSuccess: false,
  updateMainSuccess: false,
  updateSubSuccess: false,
  message: '',
  list: [],
  totalCount: 0,
  subList: [],
  subTotalCount: 0,
  // 콤보 - 기관명
  istInfoSuccess: false,
  istInfoLoading: false,
  istInfoList: [],
  // 콤보 - 부서명
  deptCdSuccess: false,
  deptCdLoading: false,
  deptCdList: [],
  // 콤보 - 사업자 번호
  bizNoSuccess: false,
  bizNoLoading: false,
  bizNoList: [],
  // 그리드 룩업 - 기관명
  cusIstCdSuccess: false,
  cusIstCdLoading: false,
  cusIstCdList: [],
  // 그리드 룩업 - 부서명
  deptInfoSuccess: false,
  deptInfoLoading: false,
  deptInfoList: [],
  // 검색조건
  searchFormValues: {
    init: true,
    ...initFormValues,
  },
};

// reducers
export default function deptMng(state = initialState, action) {
  switch (action.type) {
    case SUCCESS(FETCH_DEPT_MNG_BY_DEPT_LIST): // 조회 성공
      return {
        ...state,
        success: true,
        loading: false,
        list: action.payload.data?.main,
        totalCount: action.payload?.main_cnt,
      };
    case REQUEST(FETCH_DEPT_MNG_BY_DEPT_LIST): // 조회 요청
      return {
        ...state,
        loading: true,
        success: false,
        message: '',
        subList: [],
      };
    case FAILURE(FETCH_DEPT_MNG_BY_DEPT_LIST): // 조회실패
      return {
        ...initialState,
        loading: false,
        success: false,
        message: action.payload.response?.data?.message,
      };
    case SUCCESS(FETCH_DEPT_MNG_BY_CARD_LIST): // 조회 성공
      return {
        ...state,
        subSuccess: true,
        subLoading: false,
        subList: action.payload.data?.sub,
        subTotalCount: action.payload?.sub_cnt,
      };
    case REQUEST(FETCH_DEPT_MNG_BY_CARD_LIST): // 조회 요청
      return {
        ...state,
        subLoading: true,
        subSuccess: false,
      };
    case FAILURE(FETCH_DEPT_MNG_BY_CARD_LIST): // 조회실패
      return {
        ...state,
        subList: initialState.subList,
        subLoading: false,
        subSuccess: false,
        message: action.payload.response?.data?.message,
      };
    case SUCCESS(UPDATE_DEPT_MNG_BY_DEPT_LIST): // 수정 성공
      return {
        ...state,
        updateMainSuccess: true,
        loading: false,
      };
    case SUCCESS(UPDATE_DEPT_MNG_BY_CARD_LIST): // 수정 성공
      return {
        ...state,
        updateSubSuccess: true,
        loading: false,
      };
    case REQUEST(UPDATE_DEPT_MNG_BY_DEPT_LIST): // 수정 요청
      return {
        ...state,
        updateMainSuccess: false,
        message: null,
        loading: true,
      };
    case REQUEST(UPDATE_DEPT_MNG_BY_CARD_LIST): // 수정 요청
      return {
        ...state,
        updateSubSuccess: false,
        message: null,
        loading: true,
      };
    case FAILURE(UPDATE_DEPT_MNG_BY_DEPT_LIST): // 수정 실패
      return {
        ...initialState,
        loading: false,
        updateMainSuccess: false,
        message: action.payload.response?.data?.message,
      };
    case FAILURE(UPDATE_DEPT_MNG_BY_CARD_LIST): // 수정 실패
      return {
        ...initialState,
        loading: false,
        updateSubSuccess: false,
        message: action.payload.response?.data?.message,
      };
    // 기관명
    case SUCCESS(FETCH_IST_INFO_LIST): // 조회 성공
      return {
        ...state,
        istInfoSuccess: true,
        istInfoLoading: false,
        istInfoList: action.payload.data?.subDto,
      };
    case REQUEST(FETCH_IST_INFO_LIST): // 조회 요청
      return {
        ...state,
        istInfoLoading: true,
        istInfoSuccess: false,
      };
    case FAILURE(FETCH_IST_INFO_LIST): // 조회실패
      return {
        istInfoLoading: false,
        istInfoSuccess: false,
        istInfoList: [],
      };
    // 부서명
    case SUCCESS(FETCH_DEPT_CD_LIST): // 조회 성공
      return {
        ...state,
        deptCdSuccess: true,
        deptCdLoading: false,
        deptCdList: action.payload.data?.subDto,
      };
    case REQUEST(FETCH_DEPT_CD_LIST): // 조회 요청
      return {
        ...state,
        deptCdLoading: true,
        deptCdSuccess: false,
      };
    case FAILURE(FETCH_DEPT_CD_LIST): // 조회실패
      return {
        ...state,
        deptCdLoading: false,
        deptCdSuccess: false,
        deptCdList: [],
      };
    // 사업자 번호
    case SUCCESS(FETCH_BIZ_NO_LIST): // 조회 성공
      return {
        ...state,
        bizNoSuccess: true,
        bizNoLoading: false,
        bizNoList: action.payload.data?.subDto,
      };
    case REQUEST(FETCH_BIZ_NO_LIST): // 조회 요청
      return {
        ...state,
        bizNoLoading: true,
        bizNoSuccess: false,
      };
    case FAILURE(FETCH_BIZ_NO_LIST): // 조회실패
      return {
        ...state,
        bizNoLoading: false,
        bizNoSuccess: false,
        bizNoList: [],
      };
    // 기관명 그리드 룩업
    case SUCCESS(FETCH_CUS_IST_CD_LIST): // 조회 성공
      return {
        ...state,
        cusIstCdSuccess: true,
        cusIstCdLoading: false,
        cusIstCdList: action.payload.data?.subDto,
      };
    case REQUEST(FETCH_CUS_IST_CD_LIST): // 조회 요청
      return {
        ...state,
        cusIstCdLoading: true,
        cusIstCdSuccess: false,
      };
    case FAILURE(FETCH_CUS_IST_CD_LIST): // 조회실패
      return {
        ...state,
        cusIstCdLoading: false,
        cusIstCdSuccess: false,
        bizNoList: [],
      };
    // 부서명 그리드 룩업
    case SUCCESS(FETCH_DEPT_INFO_LIST): // 조회 성공
      return {
        ...state,
        deptInfoSuccess: true,
        deptInfoLoading: false,
        deptInfoList: action.payload.data?.subDto,
      };
    case REQUEST(FETCH_DEPT_INFO_LIST): // 조회 요청
      return {
        ...state,
        deptInfoLoading: true,
        deptInfoSuccess: false,
      };
    case FAILURE(FETCH_DEPT_INFO_LIST): // 조회실패
      return {
        ...state,
        deptInfoLoading: false,
        deptInfoSuccess: false,
        deptInfoList: [],
      };
    case SAVE_SEARCH_FORM_VALUES:
      return {
        ...state,
        // 콤보 - 기관
        istInfoSuccess: false,
        istInfoLoading: false,
        // 콤보 - 부서명
        deptCdSuccess: false,
        deptCdLoading: false,
        // 콤보 - 사업자 번호
        bizNoSuccess: false,
        bizNoLoading: false,
        // 콤보 - 계좌번호
        mbrNoSuccess: false,
        mbrNoLoading: false,
        // // 그리드 룩업 - 기관명
        // cusIstCdSuccess: false,
        // cusIstCdLoading: false,
        // // 그리드 룩업 - 부서명
        // deptInfoSuccess: false,
        // deptInfoLoading: false,
        searchFormValues: action.payload,
      };
    case RESET_STATE:
      return initialState;
    default:
      return state;
  }
}

/**
 * 법인카드 - 부서(계좌)명세조회
 */
export const selectListDeptMngByDept = (searchParams) => {
  return {
    type: FETCH_DEPT_MNG_BY_DEPT_LIST,
    payload: fetchDeptMngListByDept(searchParams),
  };
};

/**
 * 법인카드 - 카드명세조회
 */
export const selectListDeptMngByCard = (searchParams) => {
  return {
    type: FETCH_DEPT_MNG_BY_CARD_LIST,
    payload: fetchDeptMngListByCard(searchParams),
  };
};

/**
 * 법인카드 - 부서(계좌)명세조회
 */
export const updateListDeptMngByDept = (dtos) => {
  return {
    type: UPDATE_DEPT_MNG_BY_DEPT_LIST,
    payload: updateDeptMngListByDept(dtos),
  };
};

/**
 * 법인카드 - 카드명세수정
 */
export const updateListDeptMngByCard = (dtos) => {
  return {
    type: UPDATE_DEPT_MNG_BY_CARD_LIST,
    payload: updateDeptMngListByCard(dtos),
  };
};

/**
 * 기관명 콤보 조회
 */
export const selectIstInfoComboList = (searchParams) => {
  return {
    type: FETCH_IST_INFO_LIST,
    payload: fetchCommonComboList({ slt_box_no: '922', ...searchParams }),
  };
};

/**
 * 부서명 콤보 조회
 */
export const selectDeptCdComboList = (searchParams) => {
  return {
    type: FETCH_DEPT_CD_LIST,
    payload: fetchCommonComboList({ slt_box_no: '199', ...searchParams }),
  };
};

/**
 * 사업자 번호 콤보 조회
 */
export const selectBizNoComboList = (searchParams) => {
  return {
    type: FETCH_BIZ_NO_LIST,
    payload: fetchCommonComboList({ slt_box_no: '044', ...searchParams }),
  };
};

/**
 * 그리드 룩업 기관명 조회
 */
export const selectIstInfoGridLookupList = () => {
  return {
    type: FETCH_CUS_IST_CD_LIST,
    payload: fetchCommonComboList({ slt_box_no: '131', cus_ist_cd: '', dept_cd: '' }),
  };
};

/**
 * 그리드 룩업 부서명 조회
 */
export const selectBizNoGridLookupList = () => {
  return {
    type: FETCH_DEPT_INFO_LIST,
    payload: fetchCommonComboList({ slt_box_no: '914', cus_ist_cd: '', dept_cd: '' }),
  };
};

/**
 * 조회조건 저장
 */
export const saveSearchFormValues = (searchFormValues) => {
  return {
    type: SAVE_SEARCH_FORM_VALUES,
    payload: {
      ...searchFormValues,
      init: false,
    },
  };
};

/**
 * 법인카드 상태초기화
 */
export const resetDeptMng = () => {
  return {
    type: RESET_STATE,
  };
};
