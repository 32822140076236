import React from 'react';
import styled, { css } from 'styled-components';
import { dataFormat } from '../../common/utils/utils';

const WOAbnormalityCard = ({ title, count, severity, $user, dataList }) => {
  // logic

  // view
  return (
    <Wrapper>
      <Wrap className="abnornality-card">
        <Title>
          {title} <span className={severity}>{count >= 10 ? dataFormat.number(count) : `0${count}`}</span>건
        </Title>
        <List>
          {dataList.map((item) => (
            <Item key={item.id} user={$user}>
              <dl>
                <dt>기관</dt>
                <dd>{item.agency}</dd>
                <dt>계좌</dt>
                <dd>{item.account}</dd>
              </dl>

              <dl>
                <dt>금액</dt>
                <dd>{item.amount}</dd>
                <dt>발생일자</dt>
                <dd>{item.date}</dd>
              </dl>

              <dl>
                <dt>내용</dt>
                <dd>{item.briefs}</dd>
              </dl>
            </Item>
          ))}
        </List>
      </Wrap>
    </Wrapper>
  );
};

export default WOAbnormalityCard;

const Wrapper = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
  height: 100%;
  margin-left: auto;
  border: 1px solid transparent;
  border-radius: 12px;
  background-image: linear-gradient(#2d3658, #2d3658), linear-gradient(343deg, #b0ebcc -1.81%, #99a3ff 46.2%);
  background-origin: border-box;
  background-clip: content-box, border-box;
`;

const Wrap = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
  height: 100%;
  padding: 16px 0 0 20px;
`;

const Title = styled.div`
  color: var(--White);
  font-size: 14px;
  font-weight: 700;
  .caution {
    color: #fab530;
  }
  .advisory {
    color: #ff8f5f;
  }
  .warning {
    color: #ff5d5d;
  }
`;

const List = styled.ul`
  overflow: auto;
`;

const Item = styled.li`
  display: flex;
  flex-wrap: wrap;
  padding: 8px 0;
  &:first-child {
    margin-top: 7px;
  }
  + li {
    margin-top: 8px;
  }

  dl {
    width: 100%;
    color: var(--C-Dark-06);
    font-size: 12px;
    font-weight: 700;
    display: flex;
    align-items: center;

    dd + dt {
      &::before {
        content: '';
        display: inline-block;
        vertical-align: -2px;
        width: 1px;
        height: 12px;
        margin: 0 8px;
        background: var(--C-Dark-08);
      }
    }
    &: ${(props) => (props.user === 1 ? 'nth-child(odd)' : 'last-child')} {
      &::before {
        display: none;
      }
    }

    dd {
      font-weight: 400;
      padding-left: 8px;
    }
  }
`;
