import instance from '../../common/config/api';

/**
 * 시스템공통 - 문서위변조확인
 * @param {object} params
 * @returns
 */

export const fetchPapsCntlCnfCrypt = (params) =>
  instance.post(`${process.env.REACT_APP_SERVER_URL}/scq/scq/sys/0001/papsCntlCnfCrypt`, params);
