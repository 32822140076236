import { SUCCESS, REQUEST, FAILURE } from '../../../common/utils/action-type-utils';
import { fetchEtcPrcPrtsList } from './../../../lib/etc/etc0404v01.api';
import dayjs from 'dayjs';
import { fetchCommonComboList } from '../../../lib/cmm/combo.api';

// actions
const FETCH_PRCPRTS = 'etcPrcPrts/FETCH_PRCPRTS';
const RESET_STATE = 'etcPrcPrts/RESET_STATE';
const SAVE_SEARCH_FORM_VALUES = 'etcPrcPrts/SAVE_SEARCH_FORM_VALUES';
//기관
const FETCH_IST_INFO_LIST = 'etcPrcPrts/FETCH_IST_INFO_LIST';

export const initFormValues = {
  ydcs8a1_t8a01_inq_seg2: '1', // 기간선택
  bas_ym: dayjs().add(-1, 'month').format('YYYY-MM'), // 기간
  ydcs8a1_t8a01_inq_seg1: 'all', // 상품구분
  br_cd: '', // 영업점코드 (점출력구분 값이 '개별영업점'인 경우 값 추가)
};

// initial state
const initialState = {
  loading: false,
  success: false,
  message: '',
  list: [],
  totalCount: 0,
  gbOptions: [
    { code: 'E', codenm: '완료' },
    { code: 'R', codenm: '처리전' },
    { code: 'S', codenm: '처리요청' },
  ],
  initFormValues: initFormValues,
  //콤보 - 기관명
  istInfoList: [],
  istInfoLoading: false,
  istInfoSuccess: true,
  searchFormValues: {
    init: true,
    ...initFormValues,
  },
};

// reducers
export default function etcPrcPrts(state = initialState, action) {
  switch (action.type) {
    case SUCCESS(FETCH_PRCPRTS): // 조회 성공
      return {
        ...state,
        success: true,
        loading: false,
        list: action.payload.data?.subDto,
        totalCount: action.payload?.subDtoCnt,
      };
    case REQUEST(FETCH_PRCPRTS): // 조회 요청
      return {
        ...state,
        loading: true,
        success: false,
      };
    case FAILURE(FETCH_PRCPRTS): // 조회실패
      return {
        ...initialState,
        loading: false,
        success: false,
        message: action.payload.response?.data?.message,
      };
    case SUCCESS(FETCH_IST_INFO_LIST): // 조회 성공
      return {
        ...state,
        istInfoSuccess: true,
        istInfoLoading: false,
        istInfoList: action.payload.data?.subDto,
      };
    case REQUEST(FETCH_IST_INFO_LIST): // 조회 요청
      return {
        ...state,
        istInfoLoading: true,
        istInfoSuccess: false,
      };
    case FAILURE(FETCH_IST_INFO_LIST): // 조회실패
      return {
        istInfoLoading: false,
        istInfoSuccess: false,
        istInfoList: [],
      };
    case SAVE_SEARCH_FORM_VALUES:
      return {
        ...state,
        istInfoSuccess: false,
        istInfoLoading: false,
        searchFormValues: action.payload,
      };
    case RESET_STATE:
      return initialState;
    default:
      return state;
  }
}

/**
 * 기타 - 서울페이플러스 - 처리내역조회
 */
export const selectEtcPrcPrtsList = (searchParams) => {
  return {
    type: FETCH_PRCPRTS,
    payload: fetchEtcPrcPrtsList(searchParams),
  };
};

/**
 * 기관명 콤보 조회
 */
export const selectIstInfoComboList = (searchParams) => {
  return {
    type: FETCH_IST_INFO_LIST,
    payload: fetchCommonComboList({ slt_box_no: '005', ...searchParams }),
  };
};
/**

/**
 * 조회조건 저장
 */
export const saveSearchFormValues = (searchFormValues) => {
  return {
    type: SAVE_SEARCH_FORM_VALUES,
    payload: {
      ...searchFormValues,
      init: false,
    },
  };
};

/**
 * 처리내역 상태초기화
 */
export const resetEtcPrcPrts = () => {
  return {
    type: RESET_STATE,
  };
};
