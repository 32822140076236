import { fetchIntClsaPrrVrfList } from '../../../lib/arv/intClsaPrrVrf.api';
import { SUCCESS, REQUEST, FAILURE } from '../../../common/utils/action-type-utils';
import dayjs from 'dayjs';
import { fetchCommonComboList } from '../../../lib/cmm/combo.api';

// actions
const FETCH_INT_CLSA_PRR_VRF_LIST = 'intClsaPrrVrf/FETCH_INT_CLSA_PRR_VRF_LIST';
const FETCH_IST_INFO_LIST = 'intClsaPrrVrf/FETCH_IST_INFO_LIST';
const FETCH_BIZ_NO_LIST = 'intClsaPrrVrf/FETCH_BIZ_NO_LIST';
const SAVE_SEARCH_FORM_VALUES = 'intClsaPrrVrf/SAVE_SEARCH_FORM_VALUES';
const RESET_STATE = 'intClsaPrrVrf/RESET_STATE';
// 페이징
const SET_PAGE = 'intClsaPrrVrf/SET_PAGE';
const APPEND_LIST = 'intClsaPrrVrf/APPEND_LIST';
const RESET_APPEND_LIST = 'intClsaPrrVrf/RESET_APPEND_LIST';

export const initFormValues = {
  bas_ym: dayjs().format('YYYYMM'),
  biz_no: '',
  cus_ist_cd: '',
  // gubun: '0050000000700',
  gubun: '005000000',
  gubunP: '0',
  gubunE: '0',
  ydcs8a1_t8a01_acc_yr: dayjs().format('YYYY'),
  ydcs8a1_t8a02_inq_dt: dayjs().format('YYYY-MM-DD'),
};

const initPageState = {
  pageNum: 1,
  pageCount: 20,
  sumSelectedCount: 0,
};

// initial state
const initialState = {
  loading: false,
  success: true,
  message: '',
  list: [],
  appendList: [],
  totalCount: 0,
  // 콤보 - 기관명
  istInfoSuccess: false,
  istInfoLoading: false,
  istInfoList: [],
  // 콤보 - 사업자 번호
  bizNoSuccess: false,
  bizNoLoading: false,
  bizNoList: [],
  // 검색조건
  searchFormValues: {
    init: true,
    ...initFormValues,
  },
  // 페이징
  page: initPageState,
};

// reducers
export default function intClsaPrrVrf(state = initialState, action) {
  switch (action.type) {
    case SUCCESS(FETCH_INT_CLSA_PRR_VRF_LIST): // 조회 성공
      return {
        ...state,
        success: true,
        loading: false,
        list: action.payload.data?.main,
        totalCount: action.payload?.data?.totalCnt,
      };
    case REQUEST(FETCH_INT_CLSA_PRR_VRF_LIST): // 조회 요청
      return {
        ...state,
        loading: true,
        success: false,
      };
    case FAILURE(FETCH_INT_CLSA_PRR_VRF_LIST): // 조회실패
      return {
        ...state,
        loading: false,
        success: false,
        message: action.payload.response?.data?.message,
      };
    // 기관명
    case SUCCESS(FETCH_IST_INFO_LIST): // 조회 성공
      return {
        ...state,
        istInfoSuccess: true,
        istInfoLoading: false,
        istInfoList: action.payload.data?.subDto,
      };
    case REQUEST(FETCH_IST_INFO_LIST): // 조회 요청
      return {
        ...state,
        istInfoLoading: true,
        istInfoSuccess: false,
      };
    case FAILURE(FETCH_IST_INFO_LIST): // 조회실패
      return {
        ...state,
        istInfoLoading: false,
        istInfoSuccess: false,
        istInfoList: [],
      };
    // 사업자 번호
    case SUCCESS(FETCH_BIZ_NO_LIST): // 조회 성공
      return {
        ...state,
        bizNoSuccess: true,
        bizNoLoading: false,
        bizNoList: action.payload.data?.subDto,
      };
    case REQUEST(FETCH_BIZ_NO_LIST): // 조회 요청
      return {
        ...state,
        bizNoLoading: true,
        bizNoSuccess: false,
      };
    case FAILURE(FETCH_BIZ_NO_LIST): // 조회실패
      return {
        ...state,
        bizNoLoading: false,
        bizNoSuccess: false,
        bizNoList: [],
      };
    case SAVE_SEARCH_FORM_VALUES:
      return {
        ...state,
        // 콤보 - 기관
        istInfoSuccess: false,
        istInfoLoading: false,
        // 콤보 - 사업자 번호
        bizNoSuccess: false,
        bizNoLoading: false,
        searchFormValues: action.payload,
        list: [],
      };
    case RESET_STATE:
      return initialState;
    case SET_PAGE:
      return {
        ...state,
        page: action.payload,
      };
    case APPEND_LIST:
      return {
        ...state,
        appendList: [...state.appendList, ...action.payload],
      };
    case RESET_APPEND_LIST:
      return {
        ...state,
        appendList: [],
      };
    default:
      return state;
  }
}

/**
 * 이자지급결산 사전검증
 */
export const selectIntClsaPrrVrf = (searchParams) => {
  return {
    type: FETCH_INT_CLSA_PRR_VRF_LIST,
    payload: fetchIntClsaPrrVrfList(searchParams),
  };
};

/**
 * 기관명 콤보 조회
 */
export const selectIstInfoComboList = (searchParams) => {
  return {
    type: FETCH_IST_INFO_LIST,
    payload: fetchCommonComboList({ slt_box_no: '005', ...searchParams }),
  };
};

/**
 * 사업자 번호 콤보 조회
 */
export const selectBizNoComboList = (searchParams) => {
  return {
    type: FETCH_BIZ_NO_LIST,
    payload: fetchCommonComboList({ slt_box_no: '107', ...searchParams }),
  };
};

/**
 * 조회조건 저장
 */
export const saveSearchFormValues = (searchFormValues) => {
  return {
    type: SAVE_SEARCH_FORM_VALUES,
    payload: {
      ...searchFormValues,
      init: false,
    },
  };
};

/**
 * 상태초기화
 */
export const resetState = () => {
  return {
    type: RESET_STATE,
  };
};

export const setPaging = (page) => {
  return {
    type: SET_PAGE,
    payload: page,
  };
};

export const appendListState = (list) => {
  return {
    type: APPEND_LIST,
    payload: list,
  };
};

export const resetAppendList = () => {
  return {
    type: RESET_APPEND_LIST,
  };
};
