import { fetchUserDelLog } from '../../../lib/sys/userDelLog.api';
import { SUCCESS, REQUEST, FAILURE } from '../../../common/utils/action-type-utils';
import dayjs from 'dayjs';

// actions
const FETCH_USER_LOG_LIST = 'userDelLog/FETCH_USER_LOG_LIST';
const SAVE_SEARCH_FORM_VALUES = 'userDelLog/SAVE_SEARCH_FORM_VALUES';
const RESET_STATE = 'userDelLog/RESET_STATE';

export const initFormValues = {
  // 검색 영역
  user_tp_cd: 'all', //기관구분
  cus_ist_cd: 'all', //기관코드
  sta_dy: dayjs().format('YYYYMMDD'),
  end_dy: dayjs().format('YYYYMMDD'),
};

// initial state
const initialState = {
  loading: false,
  success: true,
  message: '',
  list: [],
  // 검색조건
  searchFormValues: {
    init: true,
    ...initFormValues,
  },
};

// reducers
export default function userDelLog(state = initialState, action) {
  switch (action.type) {
    case SUCCESS(FETCH_USER_LOG_LIST): // 조회 성공
      return {
        ...state,
        success: true,
        loading: false,
        list: action.payload.data?.main,
        totalCount: action.payload?.main_cnt,
        updateSuccess: false,
      };
    case REQUEST(FETCH_USER_LOG_LIST): // 조회 요청
      return {
        ...state,
        loading: true,
        success: false,
        updateSuccess: false,
      };
    case FAILURE(FETCH_USER_LOG_LIST): // 조회실패
      return {
        ...initialState,
        loading: false,
        success: false,
        message: action.payload.response?.data?.message,
        updateSuccess: false,
      };
    case SAVE_SEARCH_FORM_VALUES:
      return {
        ...state,
        searchFormValues: action.payload,
      };
    case RESET_STATE:
      return initialState;
    default:
      return { ...state, updateSuccess: false };
  }
}

/**
 * TODO: 사용자 삭제이력 목록 조회
 */
export const selectListUserDelLog = (searchParams) => {
  return {
    type: FETCH_USER_LOG_LIST,
    // TODO
    payload: fetchUserDelLog(searchParams),
  };
};

/**
 * 조회조건 저장
 */
export const saveSearchFormValues = (searchFormValues) => {
  return {
    type: SAVE_SEARCH_FORM_VALUES,
    payload: {
      ...searchFormValues,
      init: false,
    },
  };
};

/**
 * 상태초기화
 */
export const resetState = () => {
  return {
    type: RESET_STATE,
  };
};
