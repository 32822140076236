import instance from '../../common/config/api';

/**
 * 일상경비 - 계좌현황 조회
 * @param {object} params
 * @returns
 */
export const fetchEvdXpnAcnoList = (params) =>
  instance.post(`${process.env.REACT_APP_SERVER_URL}/scq/scq/ils/0001/selectEvdXpnAcnoList`, params);

export const fetchEvdXpnAcnoDtl = (params) =>
  instance.post(`${process.env.REACT_APP_SERVER_URL}/scq/scq/ils/0001/selectEvdXpnAcnoDtl`, params);
