import instance from '../../common/config/api';

/**
 * 전자보고서 관리 - 전자보고서 지점별 관리
 * @param {object} params
 * @returns
 */

export const fetchBrCdManage = (params) =>
  instance.post(`${process.env.REACT_APP_SERVER_URL}/scq/scq/rpt/0004/selectBrCdManage`, params);

export const updateRptDt = (dtos) =>
  instance.post(`${process.env.REACT_APP_SERVER_URL}/scq/scq/rpt/0004/updateRptDt`, dtos);
