import { SUCCESS, REQUEST, FAILURE } from '../../../common/utils/action-type-utils';
import { fetchZpbUserSmrInfo } from '../../../lib/zpb/zpbApvPrts.api';

// actions
const FETCH_APV_PRTS_DETAIL = 'zpbApvPrtsDetail/FETCH_APV_PRTS_DETAIL';

// initial state
const initialState = {
  loading: false,
  success: false,
  message: '',
  detail: {},
};

// reducers
export default function zpbApvPrtsDetail(state = initialState, action) {
  switch (action.type) {
    case SUCCESS(FETCH_APV_PRTS_DETAIL): // 조회 성공
      return {
        ...state,
        success: true,
        loading: false,
        detail: action.payload.data,
      };
    case REQUEST(FETCH_APV_PRTS_DETAIL): // 조회 요청
      return {
        ...state,
        loading: true,
        success: false,
      };
    case FAILURE(FETCH_APV_PRTS_DETAIL): // 조회실패
      return {
        ...initialState,
        loading: false,
        success: false,
        message: action.payload.response?.data?.message,
      };
    default:
      return state;
  }
}

/**
 * 제로페이 - 승인내역 - 사용자 요약 조회
 */
export const selectApvPrtsDetail = (searchParams) => {
  return {
    type: FETCH_APV_PRTS_DETAIL,
    payload: fetchZpbUserSmrInfo(searchParams),
  };
};
