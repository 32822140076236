import { useDispatch, useSelector } from 'react-redux';
import useSession from '../../hooks/useSession';
import WOModal, { ButtonRightArea, ModalButtonArea, ModalContentArea } from '../../components/common/WOModal';
import WOEditTable from '../../components/common/WOEditTable';
import { Controller, useForm } from 'react-hook-form';
import WOInput from '../../components/common/WOInput';
import { dataFormat } from '../../common/utils/utils';
import WOButton from '../../components/common/WOButton';
import ChangeDeptPopup from './changeDeptPopup';
import { useCallback, useEffect, useState } from 'react';
import * as S from '../../styles/subPage.styled';
import { selectUserEst } from '../../redux/modules/fds/MainFDS.reducer';
import dayjs from 'dayjs';
import { selectUserDeptChgCnt, resetCheckChangeCnt } from '../../redux/modules/cmm/changeDept.reducer';
import useAlert from '../../hooks/useAlert';
import LoginChangePasswordPopup from './LoginChangePasswordPopup';

const UserSettingPopup = ({ isOpen, onClose }) => {
  const dispatch = useDispatch();
  const { alert } = useAlert();

  const { user_id, cus_ist_cd } = useSession().getUserInfo();

  // 사용자 설정
  const [isOpen1, setIsOpen1] = useState(isOpen);

  // 비밀번호 재설정
  const [isOpen2, setIsOpen2] = useState(false);

  // 부서 변경
  const [isOpen3, setIsOpen3] = useState(false);

  // grid
  const { userEst } = useSelector((state) => state.MainFDS);

  const { checkChangeCnt, checkChangeLoading, checkChangeSuccess } = useSelector((state) => state.changeDeptState);

  const { control } = useForm({
    defaultValues: {
      cus_ist_cd: 'all',
      act_dis_cd: '',
      // 기본정보
      // 아이디
      // 성명
      // 생년월일
      // 휴대폰 번호
      // 소속구청/부서
      // 법인카드 권한
      // 비밀번호
    },
  });

  const handleClose = () => {
    setIsOpen1(false);
    onClose?.();
  };

  useEffect(() => {
    if (dispatch) {
      setIsOpen1(isOpen);
      if (isOpen) {
        dispatch(selectUserEst({}));
      }
    }
  }, [dispatch, isOpen, user_id]);

  const handleOnChangeDeptBtnClick = useCallback(() => {
    dispatch(selectUserDeptChgCnt({ user_id }));
  }, [dispatch, user_id]);

  useEffect(() => {
    if (!checkChangeLoading && checkChangeSuccess && checkChangeCnt === 0 && !isOpen) {
      setIsOpen3(true);
      dispatch(resetCheckChangeCnt());
    } else if (!checkChangeLoading && checkChangeSuccess && checkChangeCnt > 0) {
      alert('기 신청 건이 존재합니다.');
      dispatch(resetCheckChangeCnt());
    }
  }, [alert, checkChangeCnt, checkChangeLoading, checkChangeSuccess, dispatch, isOpen]);

  return (
    /* 사용자 설정 팝업 */
    <>
      <WOModal direction="center" size="md" isOpen={isOpen1} title={'사용자 설정'} onClose={handleClose}>
        {/* START: modalContent */}
        <ModalContentArea>
          {/* 기본 정보 */}
          <S.TableBox>
            <WOEditTable>
              <caption>사용자 설정</caption>
              <tbody>
                <tr>
                  <th>아이디</th>
                  <td>
                    <Controller
                      control={control}
                      name={''}
                      render={({ field: { onChange, value } }) => (
                        <WOInput value={userEst.pmnySafUserId} readonly={true} onChange={onChange} />
                      )}
                    />
                  </td>
                </tr>
                <tr>
                  <th>성명</th>
                  <td>
                    <Controller
                      control={control}
                      name={''}
                      render={({ field: { onChange, value } }) => (
                        <WOInput value={userEst.pmnySafUserNm} readonly={true} onChange={onChange} />
                      )}
                    />
                  </td>
                </tr>
                <tr>
                  <th>생년월일</th>
                  <td>
                    <Controller
                      control={control}
                      name={''}
                      render={({ field: { onChange, value } }) => (
                        <WOInput
                          value={userEst.brdt ? dayjs(userEst.brdt, 'YYMMDD').format('YY.MM.DD') : ''}
                          readonly={true}
                          onChange={onChange}
                        />
                      )}
                    />
                  </td>
                </tr>
                <tr>
                  <th>휴대폰 번호</th>
                  <td>
                    <Controller
                      control={control}
                      name={''}
                      render={({ field: { onChange, value } }) => (
                        <WOInput value={dataFormat.tel_no(userEst.hpNo)} readonly={true} onChange={onChange} />
                      )}
                    />
                  </td>
                </tr>
              </tbody>
            </WOEditTable>
          </S.TableBox>

          <S.TableBox>
            <WOEditTable>
              <tbody>
                <tr>
                  <th>
                    {userEst.pmnySafUserTpCd
                      ? userEst.pmnySafUserTpCd?.substr(0, 2) === 'WL'
                        ? '영업점'
                        : '소속구청/부서'
                      : '소속구청/부서'}
                  </th>
                  <td>
                    <S.Container $direction={'row'} $isAlignItem={'center'}>
                      <Controller
                        control={control}
                        name={''}
                        render={({ field: { onChange, value } }) => (
                          <WOInput
                            value={
                              userEst.pmnySafUserTpCd
                                ? userEst.pmnySafUserTpCd?.substr(0, 2) === 'WL'
                                  ? userEst.brNm
                                  : `${userEst.cusIstNm ? userEst.cusIstNm : ''}/${
                                      userEst.deptNm ? userEst.deptNm : ''
                                    }`
                                : `${userEst.cusIstNm ? userEst.cusIstNm : ''}/${userEst.deptNm ? userEst.deptNm : ''}`
                            }
                            readonly={true}
                            onChange={onChange}
                          />
                        )}
                      />
                      {userEst.pmnySafUserTpCd?.substr(0, 2) !== 'WL' && (
                        <WOButton onClick={handleOnChangeDeptBtnClick} variant="outlined">
                          부서변경
                        </WOButton>
                      )}
                    </S.Container>
                  </td>
                </tr>
                {userEst?.trdnRsn && (
                  <tr>
                    <th>부서변경 반려사유</th>
                    <td>{<span className="info-text red user-setting">{userEst.trdnRsn}</span>}</td>
                  </tr>
                )}
                <tr>
                  <th>법인카드 권한</th>
                  <td>
                    <Controller
                      control={control}
                      name={''}
                      render={({ field: { onChange, value } }) => (
                        <WOInput value={userEst.cdAutYn} readonly={true} onChange={onChange} />
                      )}
                    />
                  </td>
                </tr>
                <tr>
                  <th>비밀번호</th>
                  <td>
                    <Controller
                      control={control}
                      name={''}
                      render={({ field: { onChange, value } }) => (
                        <WOButton
                          onClick={() => {
                            setIsOpen2(true);
                          }}
                          variant="outlined">
                          재설정
                        </WOButton>
                      )}
                    />
                  </td>
                </tr>
              </tbody>
            </WOEditTable>
          </S.TableBox>
        </ModalContentArea>
        {/* END: modalContent */}
        <ModalButtonArea>
          <ButtonRightArea>
            <WOButton variant={'primary'} size={'md'} onClick={handleClose}>
              확인
            </WOButton>
          </ButtonRightArea>
        </ModalButtonArea>
      </WOModal>
      {/* 부서변경 팝업 */}
      {isOpen3 && <ChangeDeptPopup isOpen={isOpen3} onClose={() => setIsOpen3(false)} istCd={cus_ist_cd} />}
      {/* 비밀번호 변경 팝업 */}
      {isOpen2 && <LoginChangePasswordPopup isOpen={isOpen2} onClose={() => setIsOpen2(false)} />}
    </>
  );
};

export default UserSettingPopup;
