import {
  fetchDcmtList,
  fetchPapsMkPkNmgt,
  fetchPapsMkDeptNm,
  fetchWbkOrgt,
  fetchSulOrgt,
  insertPapsMk,
  insertPapsDpsDept,
  fetchPapsDtl,
  updateDpsPapsCnf,
  fetchPapsDpsDeptDtl,
} from '../../../lib/brc/BRC0209V01.api';
import { SUCCESS, REQUEST, FAILURE } from '../../../common/utils/action-type-utils';

// actions
const FETCH_DCMT_LIST = 'BRC0209V01/fetchDcmtList';
const FETCH_PAPS_MK_PK_NMGT = 'BRC0209P02/fetchPapsMkPkNmgt';
const FETCH_PAPS_MK_DEPT_NM = 'BRC0209P02/fetchPapsMkDeptNm';
const FETCH_WBK_ORGT = 'BRC0209P02/fetchWbkOrgt';
const FETCH_SUL_ORGT = 'BRC0209P02/fetchSulOrgt';
const INSERT_PAPS_MK = 'BRC0209P02/insertPapsMk';
const INSERT_PAPS_DPS_DEPT = 'BRC0209P02/insertPapsDpsDept';
const FETCH_PAPS_DTL = 'BRC0209P01/fetchPapsDtl';
const UPDATE_DPS_PAPS_CNF = 'BRC0209P01/updateDpsPapsCnf';
const FETCH_PAPS_DPS_DEPT_DTL = 'BRC0209P01/fetchPapsDpsDeptDtl';
const SAVE_SEARCH_FORM_VALUES = 'BRC0209P01/SAVE_SEARCH_FORM_VALUES';
const RESET_PK = 'BRC0209P01/RESET_PK';
const RESET_STATE = 'BRC0209P01/RESET_STATE';

export const initFormValues = {
  // 검색 영역
  su: 'all', //기간
  inq_dis: 'all', //상태
  prg_stcd: '', //진행상태코드
};

// initial state
const initialState = {
  loading: false,
  success: false,
  message: '',
  list: [],
  totalCount: 0,
  updateSuccess: false,
  updating: false,
  orgtSuccess: false,
  orgtLoading: false,
  orgtList: [],
  detail: {},
  dpsDeptDtlSuccess: false,
  dpsDeptDtlLoading: false,
  dpsDeptDtlList: [],
  mainPk: -1,
  brNm: '',
  pkLoading: false,
  pkSuccess: false,
  pkMessage: '',
  // 검색조건
  searchFormValues: {
    init: true,
    ...initFormValues,
  },
};

// reducers
export default function BRC0209V01(state = initialState, action) {
  switch (action.type) {
    case SUCCESS(FETCH_DCMT_LIST): // 조회 성공
      return {
        ...state,
        success: true,
        loading: false,
        list: action.payload.data?.main,
        updateSuccess: false,
        totalCount: action.payload?.main_cnt,
      };
    case REQUEST(FETCH_DCMT_LIST): // 조회 요청
      return {
        ...state,
        updateSuccess: false,
        loading: true,
      };
    case FAILURE(FETCH_DCMT_LIST): // 조회실패
      return {
        ...initialState,
        loading: false,
        success: false,
        updateSuccess: false,
        message: action.payload.response?.data?.message,
      };
    case SUCCESS(FETCH_PAPS_MK_PK_NMGT): // 채번 성공
      return {
        ...state,
        pkSuccess: true,
        pkLoading: false,
        mainPk: action.payload.data?.main_pk[0].pk_doc_id,
        mainPkCnt: action.payload?.main_cnt,
        updateSuccess: false,
      };
    case REQUEST(FETCH_PAPS_MK_PK_NMGT): // 채번 요청
      return {
        ...state,
        pkLoading: true,
        updateSuccess: false,
        pkSuccess: false,
        pkMessage: '',
      };
    case FAILURE(FETCH_PAPS_MK_PK_NMGT): // 채번 실패
      return {
        ...initialState,
        pkLoading: false,
        pkSuccess: false,
        pkMessage: action.payload.response?.data?.message,
        updateSuccess: false,
      };
    case SUCCESS(FETCH_PAPS_MK_DEPT_NM): // 부서명조회 성공
      return {
        ...state,
        success: true,
        // loading: false,
        brNm: action.payload.data.dept_res,
        updateSuccess: false,
      };
    case REQUEST(FETCH_PAPS_MK_DEPT_NM): // 부서명조회 요청
      return {
        ...state,
        // loading: true,
        updateSuccess: false,
      };
    case FAILURE(FETCH_PAPS_MK_DEPT_NM): // 부서명조회 실패
      return {
        ...initialState,
        // loading: false,
        success: false,
        message: action.payload.response?.data?.message,
        updateSuccess: false,
      };
    case SUCCESS(FETCH_WBK_ORGT): // 우리은행 조직도 조회 성공
      return {
        ...state,
        orgtSuccess: true,
        orgtLoading: false,
        orgtList: action.payload.data?.woori,
        updateSuccess: false,
      };
    case REQUEST(FETCH_WBK_ORGT): // 우리은행 조직도 조회 요청
      return {
        ...state,
        updateSuccess: false,
        orgtLoading: true,
      };
    case FAILURE(FETCH_WBK_ORGT): // 우리은행 조직도 조회실패
      return {
        ...initialState,
        orgtLoading: false,
        orgtSuccess: false,
        updateSuccess: false,
        message: action.payload.response?.data?.message,
      };
    case SUCCESS(FETCH_SUL_ORGT): // 서울시 조직도 조회 성공
      return {
        ...state,
        orgtSuccess: true,
        orgtLoading: false,
        orgtList: action.payload.data?.seoul,
        updateSuccess: false,
      };
    case REQUEST(FETCH_SUL_ORGT): // 서울시 조직도 조회 요청
      return {
        ...state,
        updateSuccess: false,
        orgtLoading: true,
      };
    case FAILURE(FETCH_SUL_ORGT): // 서울시 조직도 조회실패
      return {
        ...initialState,
        orgtLoading: false,
        orgtSuccess: false,
        updateSuccess: false,
        message: action.payload.response?.data?.message,
      };
    case SUCCESS(INSERT_PAPS_MK): // 등록/수정 성공
      return {
        ...state,
        updateSuccess: true,
        updating: false,
        resultCnt: action.payload.data.resultCnt,
      };
    case REQUEST(INSERT_PAPS_MK): // 등록/수정 요청
      return {
        ...state,
        updating: true,
        updateSuccess: false,
        mainPk: -1,
        pkLoading: false,
        pkSuccess: false,
        pkMessage: '',
      };
    case FAILURE(INSERT_PAPS_MK): // 등록/수정 실패
      return {
        ...initialState,
        updating: false,
        updateSuccess: false,
        resultCnt: -1,
        message: action.payload.response?.data?.message,
      };
    case SUCCESS(INSERT_PAPS_DPS_DEPT): // 문서 수신부서 등록 성공
      return {
        ...state,
        updateSuccess: true,
        updating: false,
        resultCnt: action.payload.data.resultCnt,
      };
    case REQUEST(INSERT_PAPS_DPS_DEPT): // 문서 수신부서 등록 요청
      return {
        ...state,
        updating: true,
        updateSuccess: false,
      };
    case FAILURE(INSERT_PAPS_DPS_DEPT): // 문서 수신부서 등록 실패
      return {
        ...initialState,
        updating: false,
        updateSuccess: false,
        resultCnt: -1,
        message: action.payload.response?.data?.message,
      };
    case SUCCESS(FETCH_PAPS_DTL): // 문서상세 조회성공
      return {
        ...state,
        success: true,
        // loading: false,
        detail: action.payload.data?.main[0], //확인 필요
        updateSuccess: false,
      };
    case REQUEST(FETCH_PAPS_DTL): // 문서상세 조회요청
      return {
        ...state,
        updateSuccess: false,
        // loading: true,
      };
    case FAILURE(FETCH_PAPS_DTL): // 문서상세 조회실패
      return {
        ...initialState,
        // loading: false,
        success: false,
        updateSuccess: false,
        message: action.payload.response?.data?.message,
      };
    case SUCCESS(UPDATE_DPS_PAPS_CNF): // 수신문서확인 성공
      return {
        ...state,
        // updateSuccess: true,
        // updating: false,
        // resultCnt: action.payload.data.resultCnt,
      };
    case REQUEST(UPDATE_DPS_PAPS_CNF): // 수신문서확인 요청
      return {
        ...state,
        // updating: true,
        // updateSuccess: false,
      };
    case FAILURE(UPDATE_DPS_PAPS_CNF): // 수신문서확인 실패
      return {
        ...initialState,
        // updating: false,
        // updateSuccess: false,
        // resultCnt: -1,
        message: action.payload.response?.data?.message,
      };
    case SUCCESS(FETCH_PAPS_DPS_DEPT_DTL): // 수신처 및 참조 리스트 조회성공
      return {
        ...state,
        dpsDeptDtlSuccess: true,
        dpsDeptDtlLoading: false,
        dpsDeptDtlList: action.payload.data?.dept,
        updateSuccess: false,
      };
    case REQUEST(FETCH_PAPS_DPS_DEPT_DTL): // 수신처 및 참조 리스트 조회요청
      return {
        ...state,
        updateSuccess: false,
        dpsDeptDtlLoading: true,
      };
    case FAILURE(FETCH_PAPS_DPS_DEPT_DTL): // 수신처 및 참조 리스트 조회실패
      return {
        ...initialState,
        dpsDeptDtlLoading: false,
        dpsDeptDtlSuccess: false,
        updateSuccess: false,
        message: action.payload.response?.data?.message,
      };
    case SAVE_SEARCH_FORM_VALUES:
      return {
        ...state,
        searchFormValues: action.payload,
      };
    case RESET_STATE:
      return initialState;
    case RESET_PK:
      return {
        ...state,
        mainPk: -1,
        pkLoading: false,
        pkSuccess: false,
      };
    default:
      return { ...state, updateSuccess: false };
  }
}

/**
 * 문서 조회
 */
export const selectDcmtList = (searchParams) => {
  return {
    type: FETCH_DCMT_LIST,
    payload: fetchDcmtList(searchParams),
  };
};

/**
 * 문서 pk채번
 */
export const selectPapsMkPkNmgt = (searchParams) => {
  return {
    type: FETCH_PAPS_MK_PK_NMGT,
    payload: fetchPapsMkPkNmgt(searchParams),
  };
};

/**
 * 문서 작성 부서명 조회
 */
export const selectPapsMkDeptNm = (searchParams) => {
  return {
    type: FETCH_PAPS_MK_DEPT_NM,
    payload: fetchPapsMkDeptNm(searchParams),
  };
};

/**
 * 우리은행 조직도 조회(W)
 */
export const selectWbkOrgt = (searchParams) => {
  return {
    type: FETCH_WBK_ORGT,
    payload: fetchWbkOrgt(searchParams),
  };
};

/**
 * 서울시 조직도 조회(S)
 */
export const selectSulOrgt = (searchParams) => {
  return {
    type: FETCH_SUL_ORGT,
    payload: fetchSulOrgt(searchParams),
  };
};

/**
 * 문서 등록/수정
 */
export const updatetPapsMk = (dtos) => {
  return {
    type: INSERT_PAPS_MK,
    payload: insertPapsMk(dtos),
  };
};

/**
 * 문서 수신부서 등록
 */
export const updatePapsDpsDept = (dtos) => {
  return {
    type: INSERT_PAPS_DPS_DEPT,
    payload: insertPapsDpsDept(dtos),
  };
};

/**
 * 문서상세조회
 */
export const selectPapsDtl = (searchParams) => {
  return {
    type: FETCH_PAPS_DTL,
    payload: fetchPapsDtl(searchParams),
  };
};

/**
 * 수신문서확인
 */
export const savePapsDpsDept = (dtos) => {
  return {
    type: UPDATE_DPS_PAPS_CNF,
    payload: updateDpsPapsCnf(dtos),
  };
};

/**
 * 문서수신부서 상세 조회
 */
export const selectPapsDpsDeptDtl = (searchParams) => {
  return {
    type: FETCH_PAPS_DPS_DEPT_DTL,
    payload: fetchPapsDpsDeptDtl(searchParams),
  };
};

/**
 * 조회조건 저장
 */
export const saveSearchFormValues = (searchFormValues) => {
  return {
    type: SAVE_SEARCH_FORM_VALUES,
    payload: {
      ...searchFormValues,
      init: false,
    },
  };
};

/**
 * 상태초기화
 */
export const resetState = () => {
  return {
    type: RESET_STATE,
  };
};
/**
 * 채번 상태초기화
 */
export const resetPk = () => {
  return {
    type: RESET_PK,
  };
};
