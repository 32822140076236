import {
  fetchAnexTrnPrtsCntOld,
  fetchAnexTrnPrtsListOld,
  fetchAnexTrnPrtsCnt,
  fetchAnexTrnPrtsList,
} from '../../../lib/axp/AnexTrnPrtsListOld.api';
import { SUCCESS, REQUEST, FAILURE } from '../../../common/utils/action-type-utils';
import dayjs from 'dayjs';
import { fetchCommonComboList } from '../../../lib/cmm/combo.api';

// actions
const FETCH_ANEX_TRN_PRTS_CNT = 'AnexTrnPrtsListOld/FETCH_ANEX_TRN_PRTS_CNT';
const FETCH_ANEX_TRN_PRTS_LIST = 'AnexTrnPrtsListOld/FETCH_ANEX_TRN_PRTS_LIST';
const FETCH_ANEX_TRN_PRTS_CNT_OLD = 'AnexTrnPrtsListOld/FETCH_ANEX_TRN_PRTS_CNT_OLD';
const FETCH_ANEX_TRN_PRTS_LIST_OLD = 'AnexTrnPrtsListOld/FETCH_ANEX_TRN_PRTS_LIST_OLD';

const FETCH_IST_INFO_LIST = 'AnexTrnPrtsListOld/FETCH_IST_INFO_LIST';
const FETCH_DEPT_INFO_LIST = 'AnexTrnPrtsListOld/FETCH_DEPT_INFO_LIST';
const FETCH_ACC_YR_LIST = 'AnexTrnPrtsListOld/FETCH_ACC_YR_LIST';
const FETCH_ACT_NO_LIST = 'AnexTrnPrtsListOld/FETCH_ACT_NO_LIST';
const AXPN_ACT_DSCD_LIST = 'AnexTrnPrtsListOld/AXPN_ACT_DSCD_LIST';
const AGN_ACT_DSCD_LIST = 'AnexTrnPrtsListOld/AGN_ACT_DSCD_LIST';
const FETCH_INQ_SEG_LIST = 'AnexTrnPrtsListOld/FETCH_INQ_SEG_LIST';

const RESET_INITIAL_STATE = 'AnexTrnPrtsListOld/RESET_INITIAL_STATE';
const SAVE_SEARCH_FORM_VALUES = 'AnexTrnPrtsListOld/SAVE_SEARCH_FORM_VALUES';

const CLEAR_MESSAGE = 'AnexTrnPrtsListOld/CLEAR_MESSAGE';
const SET_BEFORE_SEARCH_PARAM = 'AnexTrnPrtsListOld/SET_BEFORE_SEARCH_PARAM';

export const initFormValues = {
  cus_ist_cd: '', //기관명
  cus_dept_cd: 'all', //부서명
  axpn_act_dscd: 'all', //세출계좌구분
  ydcs8a1_t8a01_acc_yr: dayjs().format('YYYY'), //회계연도
  ydcs8a1_t8a01_act_no: '', //세출계좌
  agn_act_dscd: 'all', //대행계좌구분
  ydcs8a1_t8a01_inq_seg: '1', //조회구분
  ydcs8a1_t8a01_inq_sdt: dayjs(), //조회기간
  ydcs8a1_t8a01_inq_edt: dayjs(), //조회기간
};

// initial state
const initialState = {
  loading: false,
  success: false,
  message: '',
  list: [],
  totalCount: 0,
  countLoading: false,
  countSuccess: false,
  countMessage: '',
  beforeSearchParam: {},
  totalCnt: 0,

  //콤보 - 기관명 005
  istInfoSuccess: false,
  istInfoLoading: false,
  istInfoList: [],

  //콤보 - 부서명 039
  deptInfoSuccess: false,
  deptInfoLoading: false,
  deptInfoList: [],

  //콤보 - 계좌번호 923
  actNoSuccess: false,
  actNoLoading: false,
  actNoList: [],

  //콤보 - 회계연도 133
  accYrSuccess: false,
  accYrLoading: false,
  accYrList: [],

  /* 세출계좌구분코드 */
  axpnActDscdSuccess: false,
  axpnActDscdLoading: false,
  axpnActDscdList: [],

  /*대행계좌 구분 */
  agnActDscdLoading: false,
  agnActDscdSuccess: false,
  agnActDscdList: [],

  //콤보- 조회구분
  inqSegLoading: false,
  inqSegSuccess: false,
  inqSegList: [],

  // 검색조건
  searchFormValues: {
    init: true,
    ...initFormValues,
  },
};

// reducers
export default function AnexTrnPrtsListOld(state = initialState, action) {
  switch (action.type) {
    case REQUEST(FETCH_ANEX_TRN_PRTS_CNT): // 조회 요청
      return {
        ...state,
        countMessage: '',
        countLoading: true,
      };
    case SUCCESS(FETCH_ANEX_TRN_PRTS_CNT): // 조회 성공
      return {
        ...state,
        countLoading: false,
        countSuccess: true,
        totalCount: action.payload.data.count,
      };
    case FAILURE(FETCH_ANEX_TRN_PRTS_CNT): // 조회실패
      return {
        ...state,
        totalCount: 0,
        countLoading: false,
        countSuccess: false,
        countMessage: action.payload.response?.data?.message,
      };

    case SUCCESS(FETCH_ANEX_TRN_PRTS_LIST): // 조회 성공
      return {
        ...state,
        success: true,
        loading: false,
        list: action.payload.data?.output_data,
        totalCnt: action.payload?.data?.totalCnt,
      };
    case REQUEST(FETCH_ANEX_TRN_PRTS_LIST): // 조회 요청
      return {
        ...state,
        message: '',
        loading: true,
      };
    case FAILURE(FETCH_ANEX_TRN_PRTS_LIST): // 조회실패
      return {
        ...state,
        list: [],
        totalCnt: 0,
        loading: false,
        success: false,
        message: action.payload.response?.data?.message,
      };
    case REQUEST(FETCH_ANEX_TRN_PRTS_CNT_OLD): // 조회 요청
      return {
        ...state,
        loading: true,
      };
    case SUCCESS(FETCH_ANEX_TRN_PRTS_CNT_OLD): // 조회 성공
      return {
        ...state,
        countLoading: false,
        countSuccess: true,
        totalCount: action.payload.data.count,
      };
    case FAILURE(FETCH_ANEX_TRN_PRTS_CNT_OLD): // 조회실패
      return {
        ...state,
        totalCount: 0,
        countLoading: false,
        countSuccess: false,
        message: action.payload.response?.data?.message,
      };
    case SUCCESS(FETCH_ANEX_TRN_PRTS_LIST_OLD): // 조회 성공
      return {
        ...state,
        success: true,
        loading: false,
        list: action.payload.data?.output_data,
        totalCnt: action.payload?.data?.totalCnt,
      };
    case REQUEST(FETCH_ANEX_TRN_PRTS_LIST_OLD): // 조회 요청
      return {
        ...state,
        loading: true,
      };
    case FAILURE(FETCH_ANEX_TRN_PRTS_LIST_OLD): // 조회실패
      return {
        ...state,
        list: [],
        totalCnt: 0,
        loading: false,
        success: false,
        message: action.payload.response?.data?.message,
      };
    // 기관명
    case SUCCESS(FETCH_IST_INFO_LIST): // 조회 성공
      return {
        ...state,
        istInfoSuccess: true,
        istInfoLoading: false,
        istInfoList: action.payload.data?.subDto,
      };
    case REQUEST(FETCH_IST_INFO_LIST): // 조회 요청
      return {
        ...state,
        istInfoLoading: true,
        istInfoSuccess: false,
      };
    case FAILURE(FETCH_IST_INFO_LIST): // 조회실패
      return {
        ...state,
        istInfoLoading: false,
        istInfoSuccess: false,
        istInfoList: [],
      };
    // 부서명
    case SUCCESS(FETCH_DEPT_INFO_LIST): // 조회 성공
      return {
        ...state,
        deptInfoSuccess: true,
        deptInfoLoading: false,
        deptInfoList: action.payload.data?.subDto,
      };
    case REQUEST(FETCH_DEPT_INFO_LIST): // 조회 요청
      return {
        ...state,
        deptInfoLoading: true,
        deptInfoSuccess: false,
      };
    case FAILURE(FETCH_DEPT_INFO_LIST): // 조회실패
      return {
        ...state,
        deptInfoLoading: false,
        deptInfoSuccess: false,
        deptInfoList: [],
      };
    // 계좌번호
    case SUCCESS(FETCH_ACT_NO_LIST): // 조회 성공
      return {
        ...state,
        actNoSuccess: true,
        actNoLoading: false,
        actNoList: action.payload.data?.subDto,
      };
    case REQUEST(FETCH_ACT_NO_LIST): // 조회 요청
      return {
        ...state,
        actNoLoading: true,
        actNoSuccess: false,
      };
    case FAILURE(FETCH_ACT_NO_LIST): // 조회실패
      return {
        ...state,
        actNoLoading: false,
        actNoSuccess: false,
        actNoList: [],
      };
    // 회계연도
    case SUCCESS(FETCH_ACC_YR_LIST): // 조회 성공
      return {
        ...state,
        accYrSuccess: true,
        accYrLoading: false,
        accYrList: action.payload.data?.subDto,
      };
    case REQUEST(FETCH_ACC_YR_LIST): // 조회 요청
      return {
        ...state,
        accYrLoading: true,
        accYrSuccess: false,
      };
    case FAILURE(FETCH_ACC_YR_LIST): // 조회실패
      return {
        ...state,
        accYrLoading: false,
        accYrSuccess: false,
        accYrList: [],
      };
    // 세출계좌구분코드
    case SUCCESS(AXPN_ACT_DSCD_LIST): // 조회 성공
      return {
        ...state,
        axpnActDscdSuccess: true,
        axpnActDscdLoading: false,
        axpnActDscdList: action.payload.data?.subDto,
      };
    case REQUEST(AXPN_ACT_DSCD_LIST): // 조회 요청
      return {
        ...state,
        axpnActDscdLoading: true,
        axpnActDscdSuccess: false,
      };
    case FAILURE(AXPN_ACT_DSCD_LIST): // 조회실패
      return {
        ...state,
        axpnActDscdLoading: false,
        axpnActDscdSuccess: false,
        axpnActDscdList: [],
      };
    // 대행계좌구분
    case SUCCESS(AGN_ACT_DSCD_LIST): // 조회 성공
      return {
        ...state,
        agnActDscdSuccess: true,
        agnActDscdLoading: false,
        agnActDscdList: action.payload.data?.subDto,
      };
    case REQUEST(AGN_ACT_DSCD_LIST): // 조회 요청
      return {
        ...state,
        agnActDscdLoading: true,
        agnActDscdSuccess: false,
      };
    case FAILURE(AGN_ACT_DSCD_LIST): // 조회실패
      return {
        ...state,
        agnActDscdLoading: false,
        agnActDscdSuccess: false,
        agnActDscdList: [],
      };
    // 조회구분
    case SUCCESS(FETCH_INQ_SEG_LIST): // 조회 성공
      return {
        ...state,
        inqSegSuccess: true,
        inqSegLoading: false,
        inqSegList: action.payload.data?.subDto,
      };
    case REQUEST(FETCH_INQ_SEG_LIST): // 조회 요청
      return {
        ...state,
        inqSegLoading: true,
        inqSegSuccess: false,
      };
    case FAILURE(FETCH_INQ_SEG_LIST): // 조회실패
      return {
        ...state,
        inqSegLoading: false,
        inqSegSuccess: false,
        inqSegList: [],
      };
    case SAVE_SEARCH_FORM_VALUES:
      return {
        ...state,
        istInfoSuccess: false,
        istInfoLoading: false,
        deptInfoSuccess: false,
        deptInfoLoading: false,
        actNoSuccess: false,
        actNoLoading: false,
        accYrSuccess: false,
        accYrLoading: false,
        axpnActDscdLoading: false,
        axpnActDscdSuccess: false,
        agnActDscdLoading: false,
        agnActDscdSuccess: false,
        inqSegLoading: false,
        inqSegSuccess: false,

        searchFormValues: action.payload,
      };
    case RESET_INITIAL_STATE:
      return initialState;
    case SET_BEFORE_SEARCH_PARAM:
      return {
        ...state,
        beforeSearchParam: action.payload,
      };
    case CLEAR_MESSAGE:
      return {
        ...state,
        countMessage: '',
        message: '',
        success: false,
        countSuccess: false,
      };
    default:
      return state;
  }
}

/**
 * 세출-세출거래내역장 건수조회
 */
export const selectAnexTrnPrtsCnt = (searchParams) => {
  return {
    type: FETCH_ANEX_TRN_PRTS_CNT,
    payload: fetchAnexTrnPrtsCnt(searchParams),
  };
};

/**
 * 세출-세출거래내역장조회
 */
export const selectAnexTrnPrtsList = (searchParams) => {
  return {
    type: FETCH_ANEX_TRN_PRTS_LIST,
    payload: fetchAnexTrnPrtsList(searchParams),
  };
};

/**
 * 세출-세출거래내역장조회 건수조회(과거, 용산구청)
 */
export const selectAnexTrnPrtsCntOld = (searchParams) => {
  return {
    type: FETCH_ANEX_TRN_PRTS_CNT_OLD,
    payload: fetchAnexTrnPrtsCntOld(searchParams),
  };
};

/**
 * 세출-세출거래내역장조회(과거)
 */
export const selectAnexTrnPrtsListOld = (searchParams) => {
  return {
    type: FETCH_ANEX_TRN_PRTS_LIST_OLD,
    payload: fetchAnexTrnPrtsListOld(searchParams),
  };
};

export const clearMessage = () => {
  return {
    type: CLEAR_MESSAGE,
  };
};

/**
 *
 */
export const setBeforeSearchParam = (searchParams) => {
  return {
    type: SET_BEFORE_SEARCH_PARAM,
    payload: searchParams,
  };
};

//기관명 콤보 조회
export const selectIstInfoList = (searchParam) => {
  return {
    type: FETCH_IST_INFO_LIST,
    payload: fetchCommonComboList({ slt_box_no: '005', ...searchParam }),
  };
};

//부서명 콤보 조회
export const selectDeptInfoList = (searchParam) => {
  return {
    type: FETCH_DEPT_INFO_LIST,
    payload: fetchCommonComboList({ slt_box_no: '039', ...searchParam }),
  };
};

//회계연도 콤보 조회
export const selectAccYrList = () => {
  return {
    type: FETCH_ACC_YR_LIST,
    payload: fetchCommonComboList({ slt_box_no: '133' }),
  };
};

//계좌번호 콤보 조회
export const selectActNoList = (searchParam) => {
  return {
    type: FETCH_ACT_NO_LIST,
    payload: fetchCommonComboList({ slt_box_no: '923', ...searchParam }),
  };
};

//세출계좌 구분코드
export const selectAxpnActDscdList = () => {
  return {
    type: AXPN_ACT_DSCD_LIST,
    payload: fetchCommonComboList({
      slt_box_no: '003',
      cd_grp_nm: '세출계좌구분코드',
      sys_cd: 'GW',
    }),
  };
};

//대행계좌 구분코드
export const selectAgnActDscdList = () => {
  return {
    type: AGN_ACT_DSCD_LIST,
    payload: fetchCommonComboList({
      slt_box_no: '003',
      cd_grp_nm: '대행계좌구분코드',
      sys_cd: 'GW',
    }),
  };
};

//조회구분 콤보 조회
export const selectInqSegList = () => {
  return {
    type: FETCH_INQ_SEG_LIST,
    payload: fetchCommonComboList({
      slt_box_no: '003',
      cd_grp_nm: '거래명세조회구분(DW)',
      sys_cd: 'CC',
    }),
  };
};

/**
 * 조회조건 저장
 */
export const saveSearchFormValues = (searchFormValues) => {
  return {
    type: SAVE_SEARCH_FORM_VALUES,
    payload: {
      ...searchFormValues,
      init: false,
    },
  };
};

/**
 * 상태초기화
 */
export const resetInitialState = () => {
  return {
    type: RESET_INITIAL_STATE,
  };
};
