import instance from '../../common/config/api';

/**
 * 사용자 - 사용자 IP관리
 * @param {object} params
 * @returns
 */

/**
 * IP관리 조회
 * @param {object} params
 * @returns
 */
export const fetchUserIpMngList = (params) =>
  instance.post(`${process.env.REACT_APP_SERVER_URL}/scq/scq/sys/0002/selectUserIpMngList`, params);

/**
 * 수정내용 저장
 * @param {Array} dtos
 * @returns
 */
export const insertIpMng = (dtos) =>
  instance.post(`${process.env.REACT_APP_SERVER_URL}/scq/scq/sys/0002/saveUserIpMng`, {
    main: dtos,
  });
