import axios from 'axios';
import { nanoid } from 'nanoid';

const instance = axios.create({
  baseURL: process.env.REACT_APP_SERVER_URL,
  timeout: 300000,
  withCredentials: true, // JSESSION 사용
});

const idMap = new Set();

instance.interceptors.request.use(
  (config) => {
    // const accessToken = '';
    // config.headers["Content-Type"] = "application/json";
    // config.headers["Authorization"] = `Bearer ${accessToken}`;
    // config.headers['Access-Control-Allow-Origin'] = '*';

    config.headers['Scq-Referer'] = document.location.href;
    // loading 시 mouse 동작 안되도록
    config.requestId = nanoid();
    idMap.add(config.requestId);
    const searchArea = document.getElementById('searchArea');
    if (searchArea) {
      searchArea.style.pointerEvents = 'none';
    }
    document.body.style.cursor = 'wait';
    //
    return config;
  },
  (error) => {
    console.log(error);
    return Promise.reject(error);
  }
);

instance.interceptors.response.use(
  (response) => {
    // loading 시 mouse 동작 안되도록
    idMap.delete(response.config.requestId);
    if (idMap.size === 0) {
      document.body.style.cursor = 'default';
      const searchArea = document.getElementById('searchArea');
      if (searchArea) {
        searchArea.style.pointerEvents = 'all';
      }
    }

    return response;
  },
  async (error) => {
    idMap.clear();
    const searchArea = document.getElementById('searchArea');
    document.body.style.cursor = 'default';
    if (searchArea) {
      searchArea.style.pointerEvents = 'all';
    }

    // 새션 없는경우
    if (error?.response?.data?.messageId === 'PAPE0002' /*|| error?.response?.data?.messageId === 'WFWE1000' */) {
      if (window.location.pathname === '/') {
        window.location.href = `/page/login`;
      } else {
        window.location.href = `/page/login?redirect=${window.location.pathname}`;
      }
    }
    // 아이피 차단인경우
    if (error?.response?.data?.messageId === 'PAPE1038') {
      window.location.href = `/iperror.html`;
    }

    return Promise.reject(error);
  }
);

export default instance;
