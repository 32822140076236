import { SUCCESS, REQUEST, FAILURE } from '../../../common/utils/action-type-utils';
import { fetchZpbUserList, updateZpbUserDpsYn, updateUserCancDt } from '../../../lib/zpb/zpb0201v01.api';
import dayjs from 'dayjs';
import { fetchCommonComboList } from '../../../lib/cmm/combo.api';

// actions
const FETCH_USER_LIST = 'zpb0201v01/FETCH_USER_LIST';
const SAVE_USER_DPS_YN = 'zpb0201v01/SAVE_USER_DPS_YN';
const SAVE_USER_CANC_DT = 'zpb0201v01/SAVE_USER_CANC_DT';
const FETCH_IST_INFO_LIST = 'zpb0201v01/FETCH_IST_INFO_LIST';
const FETCH_IST_INFO_LIST_005 = 'zpb0201v01/FETCH_IST_INFO_LIST_005';
const RESET_STATE = 'zpb0201v01/RESET_STATE';
const SAVE_SEARCH_FORM_VALUES = 'zpb0201v01/SAVE_SEARCH_FORM_VALUES';
const FETCH_DEPT_INFO_LIST = 'zpb0201v01/FETCH_DEPT_INFO_LIST';

export const initFormValues = {
  dept_nm: 'all', // 부서명
  st_date: dayjs().add(-3, 'month').format('YYYY-MM-DD'), // 가입기간
  ed_date: dayjs().format('YYYY-MM-DD'), // 가입기간
  use_yn: 'all', // 사용유무
  // 사용자구분
  bzno: '', // 사업자번호
  zero_pay_user_no: '', // 제로페이번호
  dmd_acno: '', // 요구불계좌번호
  wdr_acno: '', // 일상경비계좌번호
  user_id: '', // 사용자ID
  co_cust_mst_id: '', // 마스터ID
  lgn_user_id: '', // co_cust_mst_id값 넣기
  rgs_dt_yn: '', // Y값 넣기
  sysRoleDscd: '1', // 권한구분 (1인경우 모두 조회, 아닌경우 user_id로 검색권한 검증)
};

// initial state
const initialState = {
  loading: false,
  success: false,
  message: '',
  list: [],
  totalCount: 0,
  updateLoading: false, // 저장
  updateSuccess: false, // 저장
  resultCnt: -1, // 저장
  updateCancLoading: false, // 저장
  updateCancSuccess: false, // 저장
  cancResultCnt: -1, // 저장
  //사용자구분
  zpbUpcheGbnCdComboList: [
    { id: '2', label: '법인(개인사업자 포함)' },
    { id: '3', label: '지자체(시청, 구청 등)' },
    { id: '4', label: '보조금사업자' },
    { id: '5', label: '공공기관' },
  ],
  //콤보 - 기관명
  bznmList: [],
  bznmLoading: false,
  bznmSuccess: true,
  bznmList005: [],
  bznmLoading005: false,
  bznmSuccess005: true,
  //qntjaud
  deptNmSuccess: false,
  deptNmLoading: false,
  deptNmList: [],
  useYnList: [
    { id: 'all', label: '전체' },
    { id: 'Y', label: 'Y' },
    { id: 'N', label: 'N' },
  ],
  searchFormValues: {
    init: true,
    ...initFormValues,
  },
};

// reducers
export default function zpb0201v01(state = initialState, action) {
  switch (action.type) {
    case SUCCESS(FETCH_USER_LIST): // 조회 성공
      return {
        ...state,
        success: true,
        loading: false,
        list: action.payload.data?.subDto,
        totalCount: action.payload.data?.subDtoCnt,
        updateSuccess: false,
      };
    case REQUEST(FETCH_USER_LIST): // 조회 요청
      return {
        ...state,
        message: '',
        loading: true,
        success: false,
        updateSuccess: false,
      };
    case FAILURE(FETCH_USER_LIST): // 조회실패
      return {
        ...initialState,
        loading: false,
        success: false,
        message: action.payload.response?.data?.message,
        updateSuccess: false,
      };
    case SUCCESS(SAVE_USER_DPS_YN): // 저장 성공
      return {
        ...state,
        updateSuccess: true,
        updateLoading: false,
        resultCnt: action.payload.data.resultCnt,
      };
    case REQUEST(SAVE_USER_DPS_YN): // 저장 요청
      return {
        ...state,
        message: '',
        updateLoading: true,
        updateSuccess: false,
      };
    case FAILURE(SAVE_USER_DPS_YN): // 저장 실패
      return {
        ...state,
        updateLoading: false,
        updateSuccess: false,
        resultCnt: -1,
        message: action.payload.response?.data?.message,
      };
    case SUCCESS(SAVE_USER_CANC_DT): // 저장 성공
      return {
        ...state,
        updateCancSuccess: true,
        updateCancLoading: false,
        cancResultCnt: action.payload.data.resultCnt,
      };
    case REQUEST(SAVE_USER_CANC_DT): // 저장 요청
      return {
        ...state,
        message: '',
        updateCancLoading: true,
        updateCancSuccess: false,
      };
    case FAILURE(SAVE_USER_CANC_DT): // 저장 실패
      return {
        ...state,
        updateCancLoading: false,
        updateCancSuccess: false,
        cancResultCnt: -1,
        message: action.payload.response?.data?.message,
      };
    case REQUEST(FETCH_IST_INFO_LIST): // 조회 요청
      return {
        ...state,
        bznmLoading: true,
        bznmSuccess: false,
      };
    case SUCCESS(FETCH_IST_INFO_LIST): // 조회 성공
      return {
        ...state,
        bznmSuccess: true,
        bznmLoading: false,
        bznmList: action.payload.data?.subDto,
      };
    case FAILURE(FETCH_IST_INFO_LIST): // 조회실패
      return {
        bznmLoading: false,
        bznmSuccess: false,
        bznmList: [],
      };
    case SUCCESS(FETCH_IST_INFO_LIST_005): // 조회 성공
      return {
        ...state,
        bznmSuccess005: true,
        bznmLoading005: false,
        bznmList005: action.payload.data?.subDto,
      };
    case REQUEST(FETCH_IST_INFO_LIST_005): // 조회 요청
      return {
        ...state,
        bznmLoading005: true,
        bznmSuccess005: false,
      };
    case FAILURE(FETCH_IST_INFO_LIST_005): // 조회실패
      return {
        bznmLoading005: false,
        bznmSuccess005: false,
        bznmList005: [],
      };
    // 부서명
    case SUCCESS(FETCH_DEPT_INFO_LIST): // 조회 성공
      return {
        ...state,
        deptNmSuccess: true,
        deptNmLoading: false,
        deptNmList: action.payload.data?.subDto,
      };
    case REQUEST(FETCH_DEPT_INFO_LIST): // 조회 요청
      return {
        ...state,
        deptNmLoading: true,
        deptNmSuccess: false,
      };
    case FAILURE(FETCH_DEPT_INFO_LIST): // 조회실패
      return {
        ...state,
        deptNmSuccess: false,
        deptNmLoading: false,
        deptNmList: [],
      };
    case SAVE_SEARCH_FORM_VALUES:
      return {
        ...state,
        // 콤보 - 기관
        bznmSuccess: false,
        bznmLoading: false,
        bznmSuccess005: false,
        bznmLoading005: false,
        // 콤보 - 부서명
        deptNmSuccess: false,
        deptNmLoading: false,
        searchFormValues: action.payload,
      };
    case RESET_STATE:
      return initialState;
    default:
      return state;
  }
}

/**
 * 제로페이(관리자) - 사용자조회
 */
export const selectZpbUserList = (searchParams) => {
  return {
    type: FETCH_USER_LIST,
    payload: fetchZpbUserList(searchParams),
  };
};

/**
 * 제로페이 - 권한 및 수신여부 설정
 */
export const saveZpbUserDpsYn = (params) => {
  return {
    type: SAVE_USER_DPS_YN,
    payload: updateZpbUserDpsYn(params),
  };
};

/**
 * 제로페이 - 해지정보현행화
 */
export const saveUserCancDt = (params) => {
  return {
    type: SAVE_USER_CANC_DT,
    payload: updateUserCancDt(params),
  };
};

/**
 * 기관명 콤보 조회
 */
export const selectIstInfoComboList = (searchParams) => {
  return {
    type: FETCH_IST_INFO_LIST,
    payload: fetchCommonComboList({ slt_box_no: '901', ...searchParams }),
  };
};

/**
 * 기관명 005콤보 조회
 */
export const selectIstInfoComboList005 = (searchParams) => {
  return {
    type: FETCH_IST_INFO_LIST_005,
    payload: fetchCommonComboList({ slt_box_no: '005', ...searchParams }),
  };
};

/**
 * 조회조건 저장
 */
export const saveSearchFormValues = (searchFormValues) => {
  return {
    type: SAVE_SEARCH_FORM_VALUES,
    payload: {
      ...searchFormValues,
      init: false,
    },
  };
};

/**
 * 부서명 콤보 조회
 */
export const selectDeptInfoComboList = (searchParams) => {
  return {
    type: FETCH_DEPT_INFO_LIST,
    payload: fetchCommonComboList({ slt_box_no: '902', ...searchParams }),
  };
};

/**
 * 초기화
 */
export const resetZpb0201v01 = () => {
  return {
    type: RESET_STATE,
  };
};
