import { SUCCESS, REQUEST, FAILURE } from '../../../common/utils/action-type-utils';
import { fetchFdsUmsUser, mergeFdsUmsUser } from '../../../lib/sys/CallMsgAdd.api';
import dayjs from 'dayjs';
import { fetchCommonComboList } from '../../../lib/cmm/combo.api';

// actions
const FETCH_FDS_UMS_USER = 'CallMsgAdd/FETCH_FDS_UMS_USER';
const SAVE_FDS_UMS_USER = 'CallMsgAdd/SAVE_FDS_UMS_USER';
const SAVE_SEARCH_FORM_VALUES = 'CallMsgAdd/SAVE_SEARCH_FORM_VALUES';
const RESET_STATE = 'CallMsgAdd/RESET_STATE';

const FETCH_IST_INFO_LIST = 'CallMsgAdd/FETCH_IST_INFO_LIST';
const FETCH_DEPT_INFO_LIST = 'CallMsgAdd/FETCH_DEPT_INFO_LIST';
const FETCH_ACC_YR_LIST = 'CallMsgAdd/FETCH_ACC_YR_LIST';
const FETCH_ACT_NO_LIST = 'CallMsgAdd/FETCH_ACT_NO_LIST';

export const initFormValues = {
  cus_ist_cd: '',
  act_no: '',
  dept_cd: '',
};

// initial state
const initialState = {
  loading: false,
  success: false,
  message: '',
  list: [],
  totalCount: 0,
  updateSuccess: false,
  resultCnt: -1,
  // 기관명
  istInfoSuccess: false,
  istInfoLoading: false,
  istInfoList: [],
  // 부서명
  deptInfoSuccess: false,
  deptInfoLoading: false,
  deptInfoList: [],
  // 회계연도
  accYrSuccess: false,
  accYrLoading: false,
  accYrList: [],
  // 일상경비계좌
  actNoSuccess: false,
  actNoLoading: false,
  actNoList: [],
  resultMessage: '',
  // 검색조건
  searchFormValues: {
    init: true,
    ...initFormValues,
  },
};

// reducers
export default function SYS0102M01(state = initialState, action) {
  switch (action.type) {
    case SUCCESS(FETCH_FDS_UMS_USER): // 조회 성공
      return {
        ...state,
        success: true,
        loading: false,
        list: action.payload.data?.main,
        totalCount: action.payload?.mainCnt,
        updateSuccess: false,
      };
    case REQUEST(FETCH_FDS_UMS_USER): // 조회 요청
      return {
        ...state,
        loading: true,
        message: '',
        updateSuccess: false,
      };
    case FAILURE(FETCH_FDS_UMS_USER): // 조회실패
      return {
        ...initialState,
        loading: false,
        success: false,
        message: action.payload.response?.data?.message,
        updateSuccess: false,
      };
    case SUCCESS(SAVE_FDS_UMS_USER): // 갱신 성공
      return {
        ...state,
        updateSuccess: true,
        loading: false,
        resultCnt: action.payload.data.resultCnt,
      };
    case REQUEST(SAVE_FDS_UMS_USER): // 갱신 요청
      return {
        ...state,
        loading: true,
        updateSuccess: false,
      };
    case FAILURE(SAVE_FDS_UMS_USER): // 갱신 실패
      return {
        ...initialState,
        loading: false,
        updateSuccess: false,
        resultCnt: -1,
        message: action.payload.response?.data?.message,
      };
    // 기관명
    case SUCCESS(FETCH_IST_INFO_LIST): // 조회 성공
      return {
        ...state,
        istInfoSuccess: true,
        istInfoLoading: false,
        istInfoList: action.payload.data?.subDto,
      };
    case REQUEST(FETCH_IST_INFO_LIST): // 조회 요청
      return {
        ...state,
        istInfoLoading: true,
        istInfoSuccess: false,
      };
    case FAILURE(FETCH_IST_INFO_LIST): // 조회실패
      return {
        istInfoLoading: false,
        istInfoSuccess: false,
        istInfoList: [],
      };
    // 부서명
    case SUCCESS(FETCH_DEPT_INFO_LIST): // 조회 성공
      return {
        ...state,
        deptInfoSuccess: true,
        deptInfoLoading: false,
        deptInfoList: action.payload.data?.subDto,
      };
    case REQUEST(FETCH_DEPT_INFO_LIST): // 조회 요청
      return {
        ...state,
        deptInfoLoading: true,
        deptInfoSuccess: false,
      };
    case FAILURE(FETCH_DEPT_INFO_LIST): // 조회실패
      return {
        ...state,
        deptInfoSuccess: false,
        deptInfoLoading: false,
        deptInfoList: [],
      };
    // 회계연도
    case SUCCESS(FETCH_ACC_YR_LIST): // 조회 성공
      return {
        ...state,
        accYrSuccess: true,
        accYrLoading: false,
        accYrList: action.payload.data?.subDto,
      };
    case REQUEST(FETCH_ACC_YR_LIST): // 조회 요청
      return {
        ...state,
        accYrLoading: true,
        accYrSuccess: false,
      };
    case FAILURE(FETCH_ACC_YR_LIST): // 조회실패
      return {
        ...state,
        accYrLoading: false,
        accYrSuccess: false,
        accYrList: [],
      };
    // 일상경비계좌
    case SUCCESS(FETCH_ACT_NO_LIST): // 조회 성공
      return {
        ...state,
        actNoSuccess: true,
        actNoLoading: false,
        actNoList: action.payload.data?.subDto,
      };
    case REQUEST(FETCH_ACT_NO_LIST): // 조회 요청
      return {
        ...state,
        actNoLoading: true,
        actNoSuccess: false,
      };
    case FAILURE(FETCH_ACT_NO_LIST): // 조회실패
      return {
        ...state,
        actNoLoading: false,
        actNoSuccess: false,
        actNoList: [],
      };
    case SAVE_SEARCH_FORM_VALUES:
      return {
        ...state,
        // 기관명
        istInfoSuccess: false,
        istInfoLoading: false,
        // 부서명
        deptInfoSuccess: false,
        deptInfoLoading: false,
        // 회계연도
        accYrSuccess: false,
        accYrLoading: false,
        // 일상경비계좌
        actNoSuccess: false,
        actNoLoading: false,
        searchFormValues: action.payload,
      };
    case RESET_STATE:
      return initialState;
    default:
      return { ...state, updateSuccess: false, endMoveSuccess: false, endCopySuccess: false };
  }
}

/**
 * FDS 알림수신 등록 목록 조회
 */
export const selectFdsUmsUser = (searchParams) => {
  return {
    type: FETCH_FDS_UMS_USER,
    payload: fetchFdsUmsUser(searchParams),
  };
};

/**
 * FDS 알림수신 등록
 */
export const saveFdsUmsUser = (dtos) => {
  return {
    type: SAVE_FDS_UMS_USER,
    payload: mergeFdsUmsUser(dtos),
  };
};

/**
 * 조회조건 저장
 */
export const saveSearchFormValues = (searchFormValues) => {
  return {
    type: SAVE_SEARCH_FORM_VALUES,
    payload: {
      ...searchFormValues,
      init: false,
    },
  };
};

/**
 * 기관명 콤보 조회
 */
export const selectIstInfoComboList = (searchParams) => {
  return {
    type: FETCH_IST_INFO_LIST,
    payload: fetchCommonComboList({ slt_box_no: '005', ...searchParams }),
  };
};

/**
 * 부서명 콤보 조회
 */
export const selectDeptInfoComboList = (searchParams) => {
  return {
    type: FETCH_DEPT_INFO_LIST,
    payload: fetchCommonComboList({ slt_box_no: '199', ...searchParams }),
  };
};

/**
 * 회계연도 콤보 조회
 */
export const selectAccYrComboList = (searchParams) => {
  return {
    type: FETCH_ACC_YR_LIST,
    payload: fetchCommonComboList({ slt_box_no: '025', ...searchParams }),
  };
};

/**
 * 일상경비계좌 콤보 조회
 */
export const selectActNoComboList = (searchParams) => {
  return {
    type: FETCH_ACT_NO_LIST,
    payload: fetchCommonComboList({ slt_box_no: '924', ...searchParams }),
  };
};

/**
 * 상태초기화
 */
export const resetState = () => {
  return {
    type: RESET_STATE,
  };
};
