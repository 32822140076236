import { SUCCESS, REQUEST, FAILURE } from '../../../common/utils/action-type-utils';
import { fetchSalaryTrnsIstAllList, fetchSalaryTrnsIstAllList2 } from '../../../lib/brc/brc0207v02.api';
import { brc0207brCdComboList } from '../../../data/const';
import dayjs from 'dayjs';

// actions
const FETCH_SALARYTRNSIST = 'brcSalaryTrnsIst/FETCH_SALARYTRNSIST';
const FETCH_SALARYTRNSIST2 = 'brcSalaryTrnsIst/FETCH_SALARYTRNSIST2';

const RESET_STATE = 'brcSalaryTrnsEachIst/RESET_STATE';
const SAVE_SEARCH_FORM_VALUES = 'brcSalaryTrnsEachIst/SAVE_SEARCH_FORM_VALUES';

export const initFormValues = {
  ydcs8a1_t8a01_inq_sdt: dayjs().format('YYYY-MM-DD'), // 기준일자
  ydcs8a1_t8a01_inq_edt: dayjs().format('YYYY-MM-DD'), // 기준일자
  br_cd: 'all',
};

// initial state
const initialState = {
  loading: false,
  loading2: false /**숨겨진그리드 */,
  success: false,
  success2: false,
  message: '',
  list: [],
  list2: [] /**숨겨진그리드 */,
  totalCount: 0,
  totalCount2: 0,
  brc0207brCdComboList: brc0207brCdComboList,
  searchFormValues: {
    init: true,
    ...initFormValues,
  },
};

// reducers
export default function brcSalaryTrnsIst(state = initialState, action) {
  switch (action.type) {
    case SUCCESS(FETCH_SALARYTRNSIST): // 조회 성공
      return {
        ...state,
        success: true,
        loading: false,
        list: action.payload.data?.main3,
        totalCount: action.payload.data?.totMain3Cnt,
      };
    case REQUEST(FETCH_SALARYTRNSIST): // 조회 요청
      return {
        ...state,
        loading: true,
        success: false,
      };
    case FAILURE(FETCH_SALARYTRNSIST): // 조회실패
      return {
        ...initialState,
        loading: false,
        success: false,
        message: action.payload.response?.data?.message,
      };
    case SUCCESS(FETCH_SALARYTRNSIST2): // 조회 성공
      return {
        ...state,
        success2: true,
        loading2: false,
        list2: action.payload.data?.main3,
        totalCount2: action.payload.data?.totMain3Cnt,
      };
    case REQUEST(FETCH_SALARYTRNSIST2): // 조회 요청
      return {
        ...state,
        loading2: true,
        success2: false,
        list2: [],
      };
    case FAILURE(FETCH_SALARYTRNSIST2): // 조회실패
      return {
        ...initialState,
        loading2: false,
        success2: false,
        list2: [],
        message2: action.payload.response?.data?.message,
      };
    case SAVE_SEARCH_FORM_VALUES:
      return {
        ...state,
        searchFormValues: action.payload,
      };
    case RESET_STATE:
      return initialState;
    default:
      return state;
  }
}

/**
 * 금고영업점 - 지자체 정보관리 - 급여이체 전체내역
 */
export const selectBrcSalaryTrnsIstList = (searchParams) => {
  return {
    type: FETCH_SALARYTRNSIST,
    payload: fetchSalaryTrnsIstAllList(searchParams),
  };
};

/**
 * 금고영업점 - 지자체 정보관리 - 급여이체 전체내역
 */
export const selectBrcSalaryTrnsIstList2 = (searchParams) => {
  return {
    type: FETCH_SALARYTRNSIST2,
    payload: fetchSalaryTrnsIstAllList2(searchParams),
  };
};

/**
 * 조회조건 저장
 */
export const saveSearchFormValues = (searchFormValues) => {
  return {
    type: SAVE_SEARCH_FORM_VALUES,
    payload: {
      ...searchFormValues,
      init: false,
    },
  };
};
/**
 * 초기화
 */
export const resetBrcSalaryTrnsIst = () => {
  return {
    type: RESET_STATE,
  };
};
