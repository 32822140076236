import { fetchGndpIntAtsList } from '../../../lib/arv/gndpIntAts.api';
import { SUCCESS, REQUEST, FAILURE } from '../../../common/utils/action-type-utils';
import { fetchGndpIntAtsRcvActList } from '../../../lib/arv/gndpIntAtsRcvAct.api';
import { fetchCommonComboList } from '../../../lib/cmm/combo.api';
import dayjs from 'dayjs';

// actions
const FETCH_GNDP_INT_ATS_LIST = 'gndpIntAts/FETCH_GNDP_INT_ATS_LIST';
const FETCH_GNDP_INT_ATS_RCV_ACT_LIST = 'gndpIntAts/FETCH_GNDP_INT_ATS_RCV_ACT_LIST';
const FETCH_IST_INFO_LIST = 'gndpIntAts/FETCH_IST_INFO_LIST';
const SAVE_SEARCH_FORM_VALUES = 'gndpIntAts/SAVE_SEARCH_FORM_VALUES';
const RESET_STATE = 'gndpIntAts/RESET_STATE';

export const initFormValues = {
  cus_ist_cd: '',
  yr: '0',
  mn: 'all',
  ats_cl: 'all',
  rcv_acno: 'all',
};

// initial state
const initialState = {
  loading: false,
  success: true,
  message: '',
  list: [],
  totalCount: 0,
  // 콤보 - 기관명
  istInfoSuccess: false,
  istInfoLoading: false,
  istInfoList: [],
  // 콤보 - 입금계좌
  rcvAcnoLoading: false,
  rcvAcnoSuccess: true,
  rcvAcnoList: [],
  // 검색조건
  searchFormValues: {
    init: true,
    ...initFormValues,
  },
};

// reducers
export default function gndpIntAts(state = initialState, action) {
  switch (action.type) {
    case SUCCESS(FETCH_GNDP_INT_ATS_LIST): // 조회 성공
      return {
        ...state,
        success: true,
        loading: false,
        list: action.payload.data?.main,
        totalCount: action.payload?.mainCnt,
      };
    case REQUEST(FETCH_GNDP_INT_ATS_LIST): // 조회 요청
      return {
        ...state,
        success: false,
        loading: true,
      };
    case FAILURE(FETCH_GNDP_INT_ATS_LIST): // 조회실패
      return {
        ...state,
        list: [],
        loading: false,
        success: false,
        message: action.payload.response?.data?.message,
      };
    case SUCCESS(FETCH_GNDP_INT_ATS_RCV_ACT_LIST): // 조회 성공
      return {
        ...state,
        rcvAcnoSuccess: true,
        rcvAcnoLoading: false,
        rcvAcnoList: action.payload.data?.subDto,
        rcvAcnoTotalCount: action.payload?.subDtoCnt,
      };
    case REQUEST(FETCH_GNDP_INT_ATS_RCV_ACT_LIST): // 조회 요청
      return {
        ...state,
        rcvAcnoSuccess: false,
        rcvAcnoLoading: true,
      };
    case FAILURE(FETCH_GNDP_INT_ATS_RCV_ACT_LIST): // 조회실패
      return {
        ...state,
        rcvAcnoList: [],
        rcvAcnoLoading: false,
        rcvAcnoSuccess: false,
        message: action.payload.response?.data?.message,
      };
    // 기관명
    case SUCCESS(FETCH_IST_INFO_LIST): // 조회 성공
      return {
        ...state,
        istInfoSuccess: true,
        istInfoLoading: false,
        istInfoList: action.payload.data?.subDto,
      };
    case REQUEST(FETCH_IST_INFO_LIST): // 조회 요청
      return {
        ...state,
        istInfoLoading: true,
        istInfoSuccess: false,
      };
    case FAILURE(FETCH_IST_INFO_LIST): // 조회실패
      return {
        istInfoLoading: false,
        istInfoSuccess: false,
        istInfoList: [],
      };
    case SAVE_SEARCH_FORM_VALUES:
      return {
        ...state,
        // 콤보 - 기관
        istInfoSuccess: false,
        istInfoLoading: false,
        // 콤보 - 입금계좌
        rcvAcnoSuccess: false,
        rcvAcnoLoading: false,
        searchFormValues: action.payload,
      };
    case RESET_STATE:
      return initialState;
    default:
      return state;
  }
}

/**
 * 세입 - 보통예금 이자 자동이체 조회
 */
export const selectGndpIntAts = (searchParams) => {
  return {
    type: FETCH_GNDP_INT_ATS_LIST,
    payload: fetchGndpIntAtsList(searchParams),
  };
};

/**
 * 세입 - 보통예금 이자 입금계좌 조회
 */
export const selectGndpIntAtsRcvActList = (searchParams) => {
  return {
    type: FETCH_GNDP_INT_ATS_RCV_ACT_LIST,
    payload: fetchGndpIntAtsRcvActList(searchParams),
  };
};

/**
 * 기관명 콤보 조회
 */
export const selectIstInfoComboList = (searchParams) => {
  return {
    type: FETCH_IST_INFO_LIST,
    payload: fetchCommonComboList({ slt_box_no: '005', ...searchParams }),
  };
};

/**
 * 조회조건 저장
 */
export const saveSearchFormValues = (searchFormValues) => {
  return {
    type: SAVE_SEARCH_FORM_VALUES,
    payload: {
      ...searchFormValues,
      init: false,
    },
  };
};

/**
 * 상태초기화
 */
export const resetState = () => {
  return {
    type: RESET_STATE,
  };
};
