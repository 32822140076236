import { fetchTXOUFpdpsOCTHAll } from '../../../lib/fxp/FXP0001.api';
import { SUCCESS, REQUEST, FAILURE } from '../../../common/utils/action-type-utils';
import dayjs from 'dayjs';
import { fetchCommonComboList } from '../../../lib/cmm/combo.api';

// actions
const FETCH_TXOU_FPDPS_OCTH_ALL = 'FXP0105V01/FETCH_TXOU_FPDPS_OCTH_ALL';

const FETCH_IST_INFO_LIST = 'FXP0105V01/FETCH_IST_INFO_LIST';
const FETCH_BIZ_NO_LIST = 'GndpPts/FETCH_BIZ_NO_LIST';

const RESET_INITIAL_STATE = 'FXP0105V01/RESET_INITIAL_STATE';
const SAVE_SEARCH_FORM_VALUES = 'FXP0105V01/SAVE_SEARCH_FORM_VALUES';

const CLEAR_MESSAGE = 'FXP0105V01/CLEAR_MESSAGE';
const SET_BEFORE_SEARCH_PARAM = 'FXP0105V01/SET_BEFORE_SEARCH_PARAM';

export const initFormValues = {
  cus_ist_cd: '',
  gubun: '1',
  sch_sta_dt: dayjs().add(-7, 'days'),
  sch_end_dt: dayjs().add(-1, 'days'),
  biz_no: '',
};

// initial state
const initialState = {
  loading: false,
  success: false,
  message: '',
  list: [],
  outputTitle: {},
  totalCount: 0,
  detailsLoading: false,
  detailsSuccess: false,
  detailsMessage: '',
  details: [],
  beforeSearchParam: {},

  //콤보 - 기관명 922
  istInfoSuccess: false,
  istInfoLoading: false,
  istInfoList: [],

  //콤보 - 사업자번호 044
  bizNoSuccess: false,
  bizNoLoading: false,
  bizNoList: [],

  // 검색조건
  searchFormValues: {
    init: true,
    ...initFormValues,
  },
};

// reducers
export default function FXP0105V01(state = initialState, action) {
  switch (action.type) {
    case SUCCESS(FETCH_TXOU_FPDPS_OCTH_ALL): // 조회 성공
      return {
        ...state,
        success: true,
        loading: false,
        list: action.payload.data?.main,
        totalCount: action.payload.data?.mainCnt,
      };
    case REQUEST(FETCH_TXOU_FPDPS_OCTH_ALL): // 조회 요청
      return {
        ...state,
        loading: true,
      };
    case FAILURE(FETCH_TXOU_FPDPS_OCTH_ALL): // 조회실패
      return {
        ...state,
        list: [],
        loading: false,
        success: false,
        message: action.payload.response?.data?.message,
      };
    // 기관명
    case SUCCESS(FETCH_IST_INFO_LIST): // 조회 성공
      return {
        ...state,
        istInfoSuccess: true,
        istInfoLoading: false,
        istInfoList: action.payload.data?.subDto,
      };
    case REQUEST(FETCH_IST_INFO_LIST): // 조회 요청
      return {
        ...state,
        istInfoLoading: true,
        istInfoSuccess: false,
      };
    case FAILURE(FETCH_IST_INFO_LIST): // 조회실패
      return {
        istInfoLoading: false,
        istInfoSuccess: false,
        istInfoList: [],
      };
    // 사업자번호
    case SUCCESS(FETCH_BIZ_NO_LIST): // 조회 성공
      return {
        ...state,
        bizNoSuccess: true,
        bizNoLoading: false,
        bizNoList: action.payload.data?.subDto,
      };
    case REQUEST(FETCH_BIZ_NO_LIST): // 조회 요청
      return {
        ...state,
        bizNoLoading: true,
        bizNoSuccess: false,
      };
    case FAILURE(FETCH_BIZ_NO_LIST): // 조회실패
      return {
        ...state,
        bizNoLoading: false,
        bizNoSuccess: false,
        bizNoList: [],
      };
    case SAVE_SEARCH_FORM_VALUES:
      return {
        ...state,
        istInfoSuccess: false,
        istInfoLoading: false,
        accNoSuccess: false,
        accNoLoading: false,

        searchFormValues: action.payload,
      };
    case RESET_INITIAL_STATE:
      return initialState;
    case SET_BEFORE_SEARCH_PARAM:
      return {
        ...state,
        beforeSearchParam: action.payload,
      };
    case CLEAR_MESSAGE:
      return {
        ...state,
        message: '',
        detailsMessage: '',
      };
    default:
      return state;
  }
}

/**
 * 서울시정기예금 신규/해지 전체조회
 */
export const selectTXOUFpdpsOCTHAll = (searchParams) => {
  return {
    type: FETCH_TXOU_FPDPS_OCTH_ALL,
    payload: fetchTXOUFpdpsOCTHAll(searchParams),
  };
};

/**
 * 메시지초기화
 */
export const clearMessage = () => {
  return {
    type: CLEAR_MESSAGE,
  };
};

/**
 *
 */
export const setBeforeSearchParam = (searchParams) => {
  return {
    type: SET_BEFORE_SEARCH_PARAM,
    payload: searchParams,
  };
};

//기관명 콤보 조회
export const selectIstInfoList = (searchParam) => {
  return {
    type: FETCH_IST_INFO_LIST,
    payload: fetchCommonComboList({ slt_box_no: '922', ...searchParam }),
  };
};

//사업자번호 콤보 조회
export const selectBizNoList = (searchParam) => {
  return {
    type: FETCH_BIZ_NO_LIST,
    payload: fetchCommonComboList({ slt_box_no: '044', ...searchParam }),
  };
};

/**
 * 조회조건 저장
 */
export const saveSearchFormValues = (searchFormValues) => {
  return {
    type: SAVE_SEARCH_FORM_VALUES,
    payload: {
      ...searchFormValues,
      init: false,
    },
  };
};

/**
 * 상태초기화
 */
export const resetInitialState = () => {
  return {
    type: RESET_INITIAL_STATE,
  };
};
