import instance from '../../common/config/api';

/**
 * 공통 파일 목록 조회
 * @param {object} params
 * @returns
 */
export const fetchCmmFileList = (params) =>
  instance.post(`${process.env.REACT_APP_SERVER_URL}/scq/scq/cmm/filemanage/selectFileList`, params);

/**
 * 공통 파일 업로드
 * @param {object} formData
 * @returns
 */
export const uploadCmmFileList = (formData, onUploadProg) =>
  instance.post(`${process.env.REACT_APP_SERVER_URL}/scq/scq/cmm/filemanage/insertFileInfoList`, formData, {
    headers: {
      'Content-Type': 'mulitipart/form-data',
    },
    onUploadProgress: (progressEvent) => {
      onUploadProg?.(progressEvent);
    },
  });

/**
 * 공통 파일 삭제
 * @param {object} dtos
 * @returns
 */
export const deleteCmmFileList = (dtos) =>
  instance.post(`${process.env.REACT_APP_SERVER_URL}/scq/scq/cmm/filemanage/removeFileList`, {
    fileInfo: dtos,
    fileInfo_cnt: dtos.length,
  });
