import instance from '../../common/config/api';

/**
 * 제로페이 - 승인•집행 불일치조회
 * @param {object} params
 * @returns
 */

export const fetchZpayApdtAndExuNacrd = (params) =>
  instance.post(`${process.env.REACT_APP_SERVER_URL}/scq/scq/zpb/0001/selectZpayApdtAndExuRcncPrts `, params);
