import { SUCCESS, REQUEST, FAILURE } from '../../../common/utils/action-type-utils';
import { fetchZpbUserXuseAppLginHstList } from '../../../lib/zpb/zpb0205v01';
import dayjs from 'dayjs';

// actions
const FETCH_USERXUSEAPPLGINHST = 'zpbUserXuseAppLginHst/FETCH_USERXUSEAPPLGINHST';
const RESET_STATE = 'zpbUserXuseAppLginHst/RESET_STATE';
const SAVE_SEARCH_FORM_VALUES = 'zpbUserXuseAppLginHst/SAVE_SEARCH_FORM_VALUES';

export const initFormValues = {
  apv_dis: '1', // 조회구분
  biz_no: '', // 기관명
  st_date: dayjs().format('YYYY-MM-DD'), // 조회 시작일
  ed_date: dayjs().format('YYYY-MM-DD'), // 조회 종료일
  cus_ist_cd: '',
};

const initialState = {
  loading: false,
  success: false,
  message: '',
  list: [],
  totalCount: 0,
  searchFormValues: {
    init: true,
    ...initFormValues,
  },
};

// reducers
export default function zpbUserXuseAppLginHst(state = initialState, action) {
  switch (action.type) {
    case SUCCESS(FETCH_USERXUSEAPPLGINHST): // 조회 성공
      return {
        ...state,
        success: true,
        loading: false,
        list: action.payload.data?.subDto,
        totalCount: action.payload?.subDtoCnt,
      };
    case REQUEST(FETCH_USERXUSEAPPLGINHST): // 조회 요청
      return {
        ...state,
        loading: true,
        success: false,
      };
    case FAILURE(FETCH_USERXUSEAPPLGINHST): // 조회실패
      return {
        ...initialState,
        loading: false,
        success: false,
        message: action.payload.response?.data?.message,
      };
    case SAVE_SEARCH_FORM_VALUES:
      return {
        ...state,
        // 콤보 - 기관
        bznmSuccess: false,
        bznmLoading: false,
        searchFormValues: action.payload,
      };
    case RESET_STATE:
      return initialState;
    default:
      return state;
  }
}

/**
 * 제로페이 - 사용자 전용앱 로그인 이력조회
 */
export const selectUserXuseAppLginHstList = (searchParams) => {
  return {
    type: FETCH_USERXUSEAPPLGINHST,
    payload: fetchZpbUserXuseAppLginHstList(searchParams),
  };
};
/**
 * 조회조건 저장
 */
export const saveSearchFormValues = (searchFormValues) => {
  return {
    type: SAVE_SEARCH_FORM_VALUES,
    payload: {
      ...searchFormValues,
      init: false,
    },
  };
};
/**
 * 초기화
 */
export const resetZpbUserXuseAppLginHst = () => {
  return {
    type: RESET_STATE,
  };
};
