import { fetchDeptChg, insertDeptChg, fetchDeptChgPkNmgt } from '../../../lib/sys/SYS0002.api';
import { SUCCESS, REQUEST, FAILURE } from '../../../common/utils/action-type-utils';

// actions
const FETCH_DEPT_CHG = 'SYS0205M01/FETCH_DEPT_CHG';
const INSERT_DEPT_CHG = 'SYS0205M01/INSERT_DEPT_CHG';
const FETCH_DEPT_CHG_PK_NMGT = 'SYS0205M01/FETCH_DEPT_CHG_PK_NMGT';
const SAVE_SEARCH_FORM_VALUES = 'SYS0205M01/SAVE_SEARCH_FORM_VALUES';
const RESET_STATE = 'SYS0205M01/RESET_STATE';

export const initFormValues = {
  // 검색 영역
  dept_chg_cd_search: 'all', //사용자구분
};

// initial state
const initialState = {
  loading: false,
  success: false,
  message: '',
  list: [],
  details: [],
  totalCount: 0,
  updating: false,
  updateSuccess: false,
  mainPk: '',
  pkLoading: false,
  pkSuccess: false,
  pkMessage: '',
  resultCnt: -1,
  // 검색조건
  searchFormValues: {
    init: true,
    ...initFormValues,
  },
};

// reducers
export default function SYS0205M01(state = initialState, action) {
  switch (action.type) {
    case SUCCESS(FETCH_DEPT_CHG): // 조회 성공
      return {
        ...state,
        success: true,
        loading: false,
        list: action.payload.data?.main,
        totalCount: action.payload?.main_cnt,
        updateSuccess: false,
      };
    case REQUEST(FETCH_DEPT_CHG): // 조회 요청
      return {
        ...state,
        loading: true,
        message: '',
        updateSuccess: false,
      };
    case FAILURE(FETCH_DEPT_CHG): // 조회실패
      return {
        ...initialState,
        loading: false,
        success: false,
        message: action.payload.response?.data?.message,
        updateSuccess: false,
      };
    case SUCCESS(INSERT_DEPT_CHG): // 갱신 성공
      return {
        ...state,
        updateSuccess: true,
        updating: false,
        resultCnt: action.payload.data.resultCnt,
      };
    case REQUEST(INSERT_DEPT_CHG): // 갱신 요청
      return {
        ...state,
        updating: true,
        updateSuccess: false,
      };
    case FAILURE(INSERT_DEPT_CHG): // 갱신 실패
      return {
        ...initialState,
        updating: false,
        updateSuccess: false,
        resultCnt: -1,
        message: action.payload.response?.data?.message,
      };
    case SUCCESS(FETCH_DEPT_CHG_PK_NMGT): // pk조회 성공
      return {
        ...state,
        pkSuccess: true,
        pkLoading: false,
        mainPk: action.payload.data?.main_pk[0].pk,
        updateSuccess: false,
      };
    case REQUEST(FETCH_DEPT_CHG_PK_NMGT): // pk조회 요청
      return {
        ...state,
        pkLoading: true,
        pkMessage: '',
        updateSuccess: false,
      };
    case FAILURE(FETCH_DEPT_CHG_PK_NMGT): // pk조회실패
      return {
        ...initialState,
        pkLoading: false,
        pkSuccess: false,
        pkMessage: action.payload.response?.data?.message,
        updateSuccess: false,
      };
    case SAVE_SEARCH_FORM_VALUES:
      return {
        ...state,
        searchFormValues: action.payload,
      };
    case RESET_STATE:
      return initialState;
    default:
      return { ...state, updateSuccess: false };
  }
}

/**
 * 부서이관 조회
 */
export const selectDeptChg = (searchParams) => {
  return {
    type: FETCH_DEPT_CHG,
    payload: fetchDeptChg(searchParams),
  };
};

/**
 * 부서이관 저장
 */
export const saveDeptChg = (dtos) => {
  return {
    type: INSERT_DEPT_CHG,
    payload: insertDeptChg(dtos),
  };
};

/**
 * 부서이관 PK 조회
 */
export const selectDeptChgPkNmgt = (searchParams) => {
  return {
    type: FETCH_DEPT_CHG_PK_NMGT,
    payload: fetchDeptChgPkNmgt(searchParams),
  };
};

/**
 * 조회조건 저장
 */
export const saveSearchFormValues = (searchFormValues) => {
  return {
    type: SAVE_SEARCH_FORM_VALUES,
    payload: {
      ...searchFormValues,
      init: false,
    },
  };
};

/**
 * 상태초기화
 */
export const resetState = () => {
  return {
    type: RESET_STATE,
  };
};
