import { SUCCESS, REQUEST, FAILURE } from '../../../common/utils/action-type-utils';
import { fetchPgmRgsList } from '../../../lib/sys/SYS0304M01.api';

// actions
const FETCH_PGM_LIST = 'program/FETCH_PGM_LIST';

// initial state
const initialState = {
  loading: false,
  success: false,
  message: '',
  list: [],
  totalCount: 0,
};

// reducers
export default function program(state = initialState, action) {
  switch (action.type) {
    case SUCCESS(FETCH_PGM_LIST): // 조회 성공
      return {
        ...state,
        success: true,
        loading: false,
        list: action.payload.data?.main,
        totalCount: action.payload?.main_cnt,
        updateSuccess: false,
      };
    case REQUEST(FETCH_PGM_LIST): // 조회 요청
      return {
        ...state,
        message: '',
        loading: true,
        success: false,
        updateSuccess: false,
      };
    case FAILURE(FETCH_PGM_LIST): // 조회실패
      return {
        ...initialState,
        loading: false,
        success: false,
        message: action.payload.response?.data?.message,
        updateSuccess: false,
      };
    default:
      return state;
  }
}

/**
 * 프로그램등록 조회
 */
export const selectListPgm = (searchParams) => {
  return {
    type: FETCH_PGM_LIST,
    payload: fetchPgmRgsList(searchParams),
  };
};
