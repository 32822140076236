import {
  fetchGenDmdActNoList,
  insertGenDmdActNo,
  selectDeptGndpActMngCombo,
} from '../../../lib/sys/GenDmdActNoMng.api';
import { SUCCESS, REQUEST, FAILURE } from '../../../common/utils/action-type-utils';

// actions
const FETCH_GEN_DMD_ACT_NO = 'GenDmdActNoMng/FETCH_GEN_DMD_ACT_NO';
const INSERT_GEN_DMD_ACT_NO = 'GenDmdActNoMng/INSERT_GEN_DMD_ACT_NO';
const SAVE_SEARCH_FORM_VALUES = 'GenDmdActNoMng/SAVE_SEARCH_FORM_VALUES';
const RESET_STATE = 'GenDmdActNoMng/RESET_STATE';
const FETCH_IST_INFO_LIST = 'GenDmdActNoMng/FETCH_IST_INFO_LIST';

export const initFormValues = {
  ist_cd: '000', //기관명
  gubun: '', //정렬순서
};

// initial state
const initialState = {
  loading: false,
  success: true,
  message: '',
  list: [],
  details: [],
  totalCount: 0,
  updateSuccess: false,
  resultCnt: -1,
  istInfoList: [],
  istInfoSuccess: false,
  istInfoLoading: false,
  // 검색조건
  searchFormValues: {
    init: true,
    ...initFormValues,
  },
};

// reducers
export default function GenDmdActNoMng(state = initialState, action) {
  switch (action.type) {
    case SUCCESS(FETCH_GEN_DMD_ACT_NO): // 조회 성공
      return {
        ...state,
        success: true,
        loading: false,
        list: action.payload.data?.main,
        totalCount: action.payload?.main_cnt,
        updateSuccess: false,
      };
    case REQUEST(FETCH_GEN_DMD_ACT_NO): // 조회 요청
      return {
        ...state,
        loading: true,
        updateSuccess: false,
      };
    case FAILURE(FETCH_GEN_DMD_ACT_NO): // 조회실패
      return {
        ...initialState,
        loading: false,
        success: false,
        message: action.payload.response?.data?.message,
        updateSuccess: false,
      };
    case SUCCESS(INSERT_GEN_DMD_ACT_NO): // 갱신 성공
      return {
        ...state,
        updateSuccess: true,
        loading: false,
        resultCnt: action.payload.data.resultCnt,
      };
    case REQUEST(INSERT_GEN_DMD_ACT_NO): // 갱신 요청
      return {
        ...state,
        loading: true,
        updateSuccess: false,
      };
    case FAILURE(INSERT_GEN_DMD_ACT_NO): // 갱신 실패
      return {
        ...initialState,
        loading: false,
        updateSuccess: false,
        resultCnt: -1,
        updateFailMessage: action.payload.response?.data?.message,
      };
    case SAVE_SEARCH_FORM_VALUES:
      return {
        ...state,
        searchFormValues: action.payload,
      };

    case SUCCESS(FETCH_IST_INFO_LIST): // 조회 성공 - 고객기관명
      return {
        ...state,
        istInfoSuccess: true,
        istInfoLoading: false,
        istInfoList: action.payload.data?.main,
      };
    case REQUEST(FETCH_IST_INFO_LIST): // 조회 요청 - 고객기관명
      return {
        ...state,
        istInfoLoading: true,
        istInfoSuccess: false,
      };
    case FAILURE(FETCH_IST_INFO_LIST): // 조회 실패 - 고객기관명
      return {
        ...state,
        istInfoLoading: false,
        istInfoSuccess: false,
        istInfoList: [],
      };
    case RESET_STATE:
      return initialState;
    default:
      return { ...state, updateSuccess: false };
  }
}

/**
 *  일반요구불계좌 조회
 */
export const selectGenDmdActNolList = (searchParams) => {
  return {
    type: FETCH_GEN_DMD_ACT_NO,
    payload: fetchGenDmdActNoList(searchParams),
  };
};

/**
 * 일반요구불계좌 저장
 */
export const updateGenDmdActNo = (dtos) => {
  return {
    type: INSERT_GEN_DMD_ACT_NO,
    payload: insertGenDmdActNo(dtos),
  };
};

/**
 * 고객기관명 조회
 */
export const selectDeptGndpActMng = (dtos) => {
  return {
    type: FETCH_IST_INFO_LIST,
    payload: selectDeptGndpActMngCombo(dtos),
  };
};

/**
 * 조회조건 저장
 */
export const saveSearchFormValues = (searchFormValues) => {
  return {
    type: SAVE_SEARCH_FORM_VALUES,
    payload: {
      ...searchFormValues,
      init: false,
    },
  };
};

/**
 * 상태초기화
 */
export const resetState = () => {
  return {
    type: RESET_STATE,
  };
};
