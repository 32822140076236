import { SUCCESS, REQUEST, FAILURE } from '../../../common/utils/action-type-utils';
import { fetchZpbApvPrtsListByIst, fetchZpbApvPrtsListByUninIst } from '../../../lib/zpb/zpbApvPrts.api';
import dayjs from 'dayjs';
import { fetchCommonComboList } from '../../../lib/cmm/combo.api';

// actions
const FETCH_APV_PRTS_BY_IST_LIST = 'zpbApvPrts/FETCH_APV_PRTS_BY_IST_LIST';
const FETCH_APV_PRTS_BY_UNIN_IST_LIST = 'zpbApvPrts/FETCH_APV_PRTS_BY_UNIN_IST_LIST';
const RESET_STATE = 'zpbApvPrts/RESET_STATE';

//기관
const FETCH_IST_INFO_LIST = 'zpbApvPrts/FETCH_IST_INFO_LIST';
//부서
const FETCH_DEPT_CD_LIST = 'zpbApvPrts/FETCH_DEPT_CD_LIST';
//사업자번호
const FETCH_BIZ_NO_LIST = 'zpbApvPrts/FETCH_BIZ_NO_LIST';
//회계연도
const FETCH_ACC_YR_LIST = 'zpbApvPrts/FETCH_ACC_YR_LIST';
//계좌번호
const FETCH_ACT_NO_LIST = 'zpbApvPrts/FETCH_ACT_NO_LIST';
//산하기관
const FETCH_SUB_IST_INFO_LIST = 'zpbApvPrts/FETCH_SUB_IST_INFO_LIST';
const SAVE_SEARCH_FORM_VALUES = 'zpbApvPrts/SAVE_SEARCH_FORM_VALUES';

export const initFormValues = {
  /*지자체*/
  cus_ist_cd: '', //기관명
  cus_dept_cd: 'all', //부서명
  biz_no: 'all', //사업자번호
  ydcs8a1_t8a01_acc_yr: dayjs().format('YYYY'), //회계연도
  ydcs8a1_t8a01_act_no: 'all', //계좌번호
  ydcs8a1_t8a01_inq_sdt: dayjs().format('YYYY-MM-DD'), //승인기간
  ydcs8a1_t8a01_inq_edt: dayjs().format('YYYY-MM-DD'), //승인기간
  apv_dis: 'all', //승인구분

  /*산하기관*/
  uninIst_cus_ist_cd: 'all', //기관명
  uninIst_ydcs8a1_t8a01_act_no: '', //출금계좌번호
  uninIst_ydcs8a1_t8a01_inq_sdt: dayjs().format('YYYY-MM-DD'), //승인기간
  uninIst_ydcs8a1_t8a01_inq_edt: dayjs().format('YYYY-MM-DD'), //승인기간
  uninIst_card_no: '', //카드번호
  uninIst_apv_dis: 'all', //승인구분
  uninIst_gb: '1',
};

// initial state
const initialState = {
  loading: false,
  success: false,
  message: '',
  istList: [], // 기관
  uninIstList: [], // 산하기관
  totalCount: 0,
  //콤보 - 기관명
  istInfoList: [],
  istInfoLoading: false,
  istInfoSuccess: true,
  // 부서명
  deptCdSuccess: false,
  deptCdLoading: false,
  deptCdList: [],
  // 사업자번호
  bizNoSuccess: false,
  bizNoLoading: false,
  bizNoList: [],
  // 회계연도
  accYrLoading: false,
  accYrList: [],
  accYrSuccess: false,
  //계좌번호
  actNoLoading: false,
  actNoList: [],
  actNoSuccess: false,
  //산하기관
  subIstInfoLoading: false,
  subIstInfoList: [],
  subIstInfoSuccess: false,
  searchFormValues: {
    init: true,
    ...initFormValues,
  },
};

// reducers
export default function zpbApvPrts(state = initialState, action) {
  switch (action.type) {
    case SUCCESS(FETCH_APV_PRTS_BY_IST_LIST): // 조회 성공 - 기관
      return {
        ...state,
        success: true,
        loading: false,
        istList: action.payload.data?.main,
        totalCount: action.payload?.main_cnt,
      };
    case REQUEST(FETCH_APV_PRTS_BY_IST_LIST): // 조회 요청 - 기관
      return {
        ...state,
        loading: true,
        success: false,
      };
    case FAILURE(FETCH_APV_PRTS_BY_IST_LIST): // 조회실패 - 기관
      return {
        ...state,
        loading: false,
        success: false,
        message: action.payload.response?.data?.message,
      };
    case SUCCESS(FETCH_APV_PRTS_BY_UNIN_IST_LIST): // 조회 성공 - 산하기관
      return {
        ...state,
        success: true,
        loading: false,
        uninIstList: action.payload.data?.subDto,
        totalCount: action.payload?.subDtoCnt,
      };
    case REQUEST(FETCH_APV_PRTS_BY_UNIN_IST_LIST): // 조회 요청 - 산하기관
      return {
        ...state,
        loading: true,
        success: false,
      };
    case FAILURE(FETCH_APV_PRTS_BY_UNIN_IST_LIST): // 조회실패 - 산하기관
      return {
        ...state,
        loading: false,
        success: false,
        message: action.payload.response?.data?.message,
      };
    case SUCCESS(FETCH_IST_INFO_LIST): // 조회 성공
      return {
        ...state,
        istInfoSuccess: true,
        istInfoLoading: false,
        istInfoList: action.payload.data?.subDto,
      };
    case REQUEST(FETCH_IST_INFO_LIST): // 조회 요청
      return {
        ...state,
        istInfoLoading: true,
        istInfoSuccess: false,
      };
    case FAILURE(FETCH_IST_INFO_LIST): // 조회실패
      return {
        istInfoLoading: false,
        istInfoSuccess: false,
        istInfoList: [],
      };
    // 부서명
    case SUCCESS(FETCH_DEPT_CD_LIST): // 조회 성공
      return {
        ...state,
        deptCdSuccess: true,
        deptCdLoading: false,
        deptCdList: action.payload.data?.subDto,
      };
    case REQUEST(FETCH_DEPT_CD_LIST): // 조회 요청
      return {
        ...state,
        deptCdLoading: true,
        deptCdSuccess: false,
      };
    case FAILURE(FETCH_DEPT_CD_LIST): // 조회실패
      return {
        ...state,
        deptCdLoading: false,
        deptCdSuccess: false,
        deptCdList: [],
      };
    // 사업자 번호
    case SUCCESS(FETCH_BIZ_NO_LIST): // 조회 성공
      return {
        ...state,
        bizNoSuccess: true,
        bizNoLoading: false,
        bizNoList: action.payload.data?.subDto,
      };
    case REQUEST(FETCH_BIZ_NO_LIST): // 조회 요청
      return {
        ...state,
        bizNoLoading: true,
        bizNoSuccess: false,
      };
    case FAILURE(FETCH_BIZ_NO_LIST): // 조회실패
      return {
        ...state,
        bizNoLoading: false,
        bizNoSuccess: false,
        bizNoList: [],
      };
    // 회계연도
    case SUCCESS(FETCH_ACC_YR_LIST): // 조회 성공
      return {
        ...state,
        accYrSuccess: true,
        accYrLoading: false,
        accYrList: action.payload.data?.subDto,
      };
    case REQUEST(FETCH_ACC_YR_LIST): // 조회 요청
      return {
        ...state,
        accYrLoading: true,
        accYrSuccess: false,
      };
    case FAILURE(FETCH_ACC_YR_LIST): // 조회실패
      return {
        ...state,
        accYrLoading: false,
        accYrSuccess: false,
        accYrList: [],
      };
    //계좌번호
    case SUCCESS(FETCH_ACT_NO_LIST): // 조회 성공
      return {
        ...state,
        actNoSuccess: true,
        actNoLoading: false,
        actNoList: action.payload.data?.subDto,
      };
    case REQUEST(FETCH_ACT_NO_LIST): // 조회 요청
      return {
        ...state,
        actNoLoading: true,
        actNoSuccess: false,
      };
    case FAILURE(FETCH_ACT_NO_LIST): // 조회실패
      return {
        ...state,
        actNoLoading: false,
        actNoSuccess: false,
        actNoList: [],
      };
    //산하기관
    case SUCCESS(FETCH_SUB_IST_INFO_LIST): // 조회 성공
      return {
        ...state,
        subIstInfoSuccess: true,
        subIstInfoLoading: false,
        subIstInfoList: action.payload.data?.subDto,
      };
    case REQUEST(FETCH_SUB_IST_INFO_LIST): // 조회 요청
      return {
        ...state,
        subIstInfoLoading: true,
        subIstInfoSuccess: false,
      };
    case FAILURE(FETCH_SUB_IST_INFO_LIST): // 조회실패
      return {
        ...state,
        subIstInfoLoading: false,
        subIstInfoSuccess: false,
        subIstInfoList: [],
      };
    case SAVE_SEARCH_FORM_VALUES:
      return {
        ...state,
        // 콤보 - 기관
        istInfoSuccess: false,
        istInfoLoading: false,
        // 콤보 - 부서명
        deptCdSuccess: false,
        deptCdLoading: false,
        // 콤보 - 사업자 번호
        bizNoSuccess: false,
        bizNoLoading: false,
        // 콤보 - 계좌번호
        mbrNoSuccess: false,
        mbrNoLoading: false,
        // 콤보 -회계연도
        accYrSuccess: false,
        actNoLoading: false,
        // 콤보 -산하기관
        subIstInfoSuccess: false,
        subIstInfoLoading: false,
        searchFormValues: action.payload,
      };
    case RESET_STATE:
      return initialState;
    default:
      return state;
  }
}

/**
 * 제로페이 - 승인내역(기관)
 */
export const selectListZpbApvPrtsByIst = (searchParams) => {
  return {
    type: FETCH_APV_PRTS_BY_IST_LIST,
    payload: fetchZpbApvPrtsListByIst(searchParams),
  };
};

/**
 * 제로페이 - 승인내역(산하기관)
 */
export const selectListZpbApvPrtsByUninIst = (searchParams) => {
  return {
    type: FETCH_APV_PRTS_BY_UNIN_IST_LIST,
    payload: fetchZpbApvPrtsListByUninIst(searchParams),
  };
};

/**
 * 기관명 콤보 조회
 */
export const selectIstInfoComboList = (searchParams) => {
  return {
    type: FETCH_IST_INFO_LIST,
    payload: fetchCommonComboList({ slt_box_no: '005', ...searchParams }),
  };
};
/**
 * 부서명 콤보 조회
 */
export const selectDeptCdComboList = (searchParams) => {
  return {
    type: FETCH_DEPT_CD_LIST,
    payload: fetchCommonComboList({ slt_box_no: '199', ...searchParams }),
  };
};
/**
 * 사업자 번호 콤보 조회
 */
export const selectBizNoComboList = (searchParams) => {
  return {
    type: FETCH_BIZ_NO_LIST,
    payload: fetchCommonComboList({ slt_box_no: '044', ...searchParams }),
  };
};
/**
 * 회계연도 콤보 조회
 */
export const selectAccYrComboList = (searchParams) => {
  return {
    type: FETCH_ACC_YR_LIST,
    payload: fetchCommonComboList({ slt_box_no: '025', ...searchParams }),
  };
};

/**
 * 산하기관 콤보 조회
 */
export const selectsubIstInfoList = (searchParams) => {
  return {
    type: FETCH_SUB_IST_INFO_LIST,
    payload: fetchCommonComboList({ slt_box_no: '204', ...searchParams }),
  };
};
/**
 * 계좌번호 콤보 조회
 */
export const selectActNoComboList = (searchParams) => {
  return {
    type: FETCH_ACT_NO_LIST,
    payload: fetchCommonComboList({ slt_box_no: '196', ...searchParams }),
  };
};

/**
 * 조회조건 저장
 */
export const saveSearchFormValues = (searchFormValues) => {
  return {
    type: SAVE_SEARCH_FORM_VALUES,
    payload: {
      ...searchFormValues,
      init: false,
    },
  };
};

/**
 * 승인내역 상태초기화
 */
export const resetZpbApvPrts = () => {
  return {
    type: RESET_STATE,
  };
};

// TODO
// export const reset();
