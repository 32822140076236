import instance from '../../common/config/api';

/**
 * 세입 - 공금예금 이자명세조회
 * @returns
 */
export const fetchPbamIntHisList = (params) =>
  instance.post(`${process.env.REACT_APP_SERVER_URL}/scq/scq/arv/0006/selectPbamIntHis`, params);

/**
 * 세입 - 공금예금 결산이자명세 조회
 * @returns
 */
export const fetchPbamClsaIntHisList = (params) =>
  instance.post(`${process.env.REACT_APP_SERVER_URL}/scq/scq/arv/0006/selectPbamClsaIntHis`, params);

/**
 * 세입 - 공금예금 결산이자 입금내역 조회
 * @returns
 */
export const fetchPbamClsaIntRcvPrtsList = (params) =>
  instance.post(`${process.env.REACT_APP_SERVER_URL}/scq/scq/arv/0006/selectPbamClsaIntRcvPrts`, params);
