import instance from '../../common/config/api';

/**
 * 법인카드 - 연체내역 조회
 * @param {object} params
 * @returns
 */
export const fetchCadDfrPrtsList = (params) =>
  instance.post(`${process.env.REACT_APP_SERVER_URL}/scq/scq/cad/0002/selectListDfrPrts`, params);

/**
 * 법인카드 - 연체내역 수정
 * @param {array} dtoList
 * @returns
 */
export const updateCadDfrPrtsList = (dtoList) =>
  instance.post(`${process.env.REACT_APP_SERVER_URL}/scq/scq/cad/0002/updateListDfrPrts`, {
    subDto: dtoList,
  });
