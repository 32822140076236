import instance from '../../common/config/api';

/**
 * 시스템공통 - 전자보고서 제공리스트 등록
 * @param {object} params
 * @returns
 */

export const fetchReportList = (params) =>
  instance.post(`${process.env.REACT_APP_SERVER_URL}/scq/scq/sys/0001/selectReportList`, params);

export const insertReportList = (dtos) =>
  instance.post(`${process.env.REACT_APP_SERVER_URL}/scq/scq/sys/0001/insertReportList`, {
    subDto: dtos,
  });
