import { fetchEvdXpnImpExpdMmSht, saveCnfYn } from '../../../lib/ils/EvdXpnImpExpdMmSht.api';
import { SUCCESS, REQUEST, FAILURE } from '../../../common/utils/action-type-utils';
import dayjs from 'dayjs';
import { fetchCommonComboList } from '../../../lib/cmm/combo.api';

// actions
const FETCH_EVD_XPN_IMP_EXPD_MM_SHT = 'EvdXpnImpExpdMmSht/FETCH_EVD_XPN_IMP_EXPD_MM_SHT';
const SAVE_CNF_YN = 'EvdXpnImpExpdMmSht/SAVE_CNF_YN';

const SAVE_SEARCH_FORM_VALUES = 'EvdXpnImpExpdMmSht/SAVE_SEARCH_FORM_VALUES';
const RESET_INITIAL_STATE = 'EvdXpnImpExpdMmSht/RESET_INITIAL_STATE';

const FETCH_IST_INFO_LIST = 'EvdXpnImpExpdMmSht/FETCH_IST_INFO_LIST';
const FETCH_DEPT_INFO_LIST = 'EvdXpnImpExpdMmSht/FETCH_DEPT_INFO_LIST';
const FETCH_ACT_NO_LIST = 'EvdXpnImpExpdMmSht/FETCH_ACT_NO_LIST';
const FETCH_ACC_YR_LIST = 'EvdXpnImpExpdMmSht/FETCH_ACC_YR_LIST';
const FETCH_ARVN_ACT_DSCD_LIST = 'EvdXpnImpExpdMmSht/FETCH_ARVN_ACT_DSCD_LIST';
const FETCH_DPACT_STCD_LIST = 'EvdXpnImpExpdMmSht/FETCH_DPACT_STCD_LIST';
const AGN_ACT_DSCD_LIST = 'EvdXpnImpExpdMmSht/AGN_ACT_DSCD_LIST';

const CLEAR_MESSAGE = 'EvdXpnImpExpdMmSht/CLEAR_MESSAGE';
const SET_BEFORE_SEARCH_PARAM = 'EvdXpnImpExpdMmSht/SET_BEFORE_SEARCH_PARAM';

export const initFormValues = {
  cus_ist_cd: '', //기관명
  cus_dept_cd: 'all', //부서명
  ydcs8a1_t8a02_act_no: '', //세출계좌
  ydcs8a1_t8a02_acc_yr: dayjs().format('YYYY'), //회계연도
  arvn_act_dscd: 'all', //시세/구세
  agn_act_dscd: 'all', //대행계좌구분
  ydcs8a1_t8a02_inq_mn: dayjs().add(-1, 'month'), //기준년월
  pmdp_act_dscd: '02', //01=세입, 02=세출
  ydcs8a1_t8a02_dt_mn_dis: '2', //1=일보, 2=월보
  hwakin_yn: 'N',
  exist_yn: 'Y',
  bas_dis_cd: 'D', // "M" 월, "D" 일
  ydcs8a1_t8a02_inq_sdt: dayjs(),
};

// initial state
const initialState = {
  loading: false,
  success: false,
  message: '',
  list: [],
  listTitle: {},
  listHead: {},
  listCommon: {},
  totalCount: 0,
  updateSuccess: false,
  updateMessage: '',
  resultCnt: -1,
  beforeSearchParam: {},

  //콤보 - 기관명 005
  istInfoSuccess: false,
  istInfoLoading: false,
  istInfoList: [],

  //콤보 - 부서명 041
  deptInfoSuccess: false,
  deptInfoLoading: false,
  deptInfoList: [],

  //콤보 - 계좌번호 032
  actNoSuccess: false,
  actNoLoading: false,
  actNoList: [],

  //콤보 - 회계연도 025
  accYrSuccess: false,
  accYrLoading: false,
  accYrList: [],

  //콤보 - 시세/구세 003
  arvnActDscdSuccess: false,
  arvnActDscdLoading: false,
  arvnActDscdList: [],

  /*대행계좌 구분 */
  agnActDscdLoading: false,
  agnActDscdSuccess: false,
  agnActDscdList: [],

  // 검색조건
  searchFormValues: {
    init: true,
    ...initFormValues,
  },
};

// reducers
export default function EvdXpnImpExpdMmSht(state = initialState, action) {
  switch (action.type) {
    case SUCCESS(FETCH_EVD_XPN_IMP_EXPD_MM_SHT): // 조회 성공
      return {
        ...state,
        success: true,
        loading: false,
        list: action.payload.data?.output_data,
        listTitle: action.payload.data?.output_title,
        listHead: action.payload.data?.output_head,
        listCommon: action.payload.data?.common,
        totalCount: action.payload?.main_cnt,
      };
    case REQUEST(FETCH_EVD_XPN_IMP_EXPD_MM_SHT): // 조회 요청
      return {
        ...state,
        listTitle: {},
        listHead: {},
        loading: true,
      };
    case FAILURE(FETCH_EVD_XPN_IMP_EXPD_MM_SHT): // 조회실패
      return {
        ...initialState,
        loading: false,
        success: false,
        message: action.payload.response?.data?.message,
      };
    case SUCCESS(SAVE_CNF_YN): // 저장 성공
      return {
        ...state,
        updateSuccess: true,
        loading: false,
        resultCnt: action.payload.data.resultCnt,
      };
    case REQUEST(SAVE_CNF_YN): // 저장 요청
      return {
        ...state,
        updateMessage: '',
        loading: true,
        updateSuccess: false,
      };
    case FAILURE(SAVE_CNF_YN): // 저장 실패
      return {
        ...state,
        loading: false,
        updateSuccess: false,
        resultCnt: -1,
        updateMessage: action.payload.response?.data?.message,
      };
    // 기관명
    case SUCCESS(FETCH_IST_INFO_LIST): // 조회 성공
      return {
        ...state,
        istInfoSuccess: true,
        istInfoLoading: false,
        istInfoList: action.payload.data?.subDto,
      };
    case REQUEST(FETCH_IST_INFO_LIST): // 조회 요청
      return {
        ...state,
        istInfoLoading: true,
        istInfoSuccess: false,
      };
    case FAILURE(FETCH_IST_INFO_LIST): // 조회실패
      return {
        istInfoLoading: false,
        istInfoSuccess: false,
        istInfoList: [],
      };
    // 부서명
    case SUCCESS(FETCH_DEPT_INFO_LIST): // 조회 성공
      return {
        ...state,
        deptInfoSuccess: true,
        deptInfoLoading: false,
        deptInfoList: action.payload.data?.subDto,
      };
    case REQUEST(FETCH_DEPT_INFO_LIST): // 조회 요청
      return {
        ...state,
        deptInfoLoading: true,
        deptInfoSuccess: false,
      };
    case FAILURE(FETCH_DEPT_INFO_LIST): // 조회실패
      return {
        ...state,
        deptInfoLoading: false,
        deptInfoSuccess: false,
        deptInfoList: [],
      };
    // 계좌번호
    case SUCCESS(FETCH_ACT_NO_LIST): // 조회 성공
      return {
        ...state,
        actNoSuccess: true,
        actNoLoading: false,
        actNoList: action.payload.data?.subDto,
      };
    case REQUEST(FETCH_ACT_NO_LIST): // 조회 요청
      return {
        ...state,
        actNoLoading: true,
        actNoSuccess: false,
      };
    case FAILURE(FETCH_ACT_NO_LIST): // 조회실패
      return {
        ...state,
        actNoLoading: false,
        actNoSuccess: false,
        actNoList: [],
      };
    // 계좌상태
    case SUCCESS(FETCH_DPACT_STCD_LIST): // 조회 성공
      return {
        ...state,
        dpactStcdSuccess: true,
        dpactStcdLoading: false,
        dpactStcdList: action.payload.data?.subDto,
      };
    case REQUEST(FETCH_DPACT_STCD_LIST): // 조회 요청
      return {
        ...state,
        dpactStcdLoading: true,
        dpactStcdSuccess: false,
      };
    case FAILURE(FETCH_DPACT_STCD_LIST): // 조회실패
      return {
        ...state,
        dpactStcdLoading: false,
        dpactStcdSuccess: false,
        dpactStcdList: [],
      };
    // 시세/구세
    case SUCCESS(FETCH_ARVN_ACT_DSCD_LIST): // 조회 성공
      return {
        ...state,
        arvnActDscdSuccess: true,
        arvnActDscdLoading: false,
        arvnActDscdList: action.payload.data?.subDto,
      };
    case REQUEST(FETCH_ARVN_ACT_DSCD_LIST): // 조회 요청
      return {
        ...state,
        arvnActDscdLoading: true,
        arvnActDscdSuccess: false,
      };
    case FAILURE(FETCH_ARVN_ACT_DSCD_LIST): // 조회실패
      return {
        ...state,
        arvnActDscdLoading: false,
        arvnActDscdSuccess: false,
        arvnActDscdList: [],
      };
    // 회계연도
    case SUCCESS(FETCH_ACC_YR_LIST): // 조회 성공
      return {
        ...state,
        accYrSuccess: true,
        accYrLoading: false,
        accYrList: action.payload.data?.subDto,
      };
    case REQUEST(FETCH_ACC_YR_LIST): // 조회 요청
      return {
        ...state,
        accYrLoading: true,
        accYrSuccess: false,
      };
    case FAILURE(FETCH_ACC_YR_LIST): // 조회실패
      return {
        ...state,
        accYrLoading: false,
        accYrSuccess: false,
        accYrList: [],
      };
    // 대행계좌구분
    case SUCCESS(AGN_ACT_DSCD_LIST): // 조회 성공
      return {
        ...state,
        agnActDscdSuccess: true,
        agnActDscdLoading: false,
        agnActDscdList: action.payload.data?.subDto,
      };
    case REQUEST(AGN_ACT_DSCD_LIST): // 조회 요청
      return {
        ...state,
        agnActDscdLoading: true,
        agnActDscdSuccess: false,
      };
    case FAILURE(AGN_ACT_DSCD_LIST): // 조회실패
      return {
        ...state,
        agnActDscdLoading: false,
        agnActDscdSuccess: false,
        agnActDscdList: [],
      };
    case SAVE_SEARCH_FORM_VALUES:
      return {
        ...state,
        istInfoSuccess: false,
        istInfoLoading: false,
        deptInfoSuccess: false,
        deptInfoLoading: false,
        actNoSuccess: false,
        actNoLoading: false,
        accYrSuccess: false,
        accYrLoading: false,
        arvnActDscdSuccess: false,
        arvnActDscdLoading: false,
        dpactStcdSuccess: false,
        dpactStcdLoading: false,
        agnActDscdLoading: false,
        agnActDscdSuccess: false,

        searchFormValues: action.payload,
      };
    case SET_BEFORE_SEARCH_PARAM:
      return {
        ...state,
        beforeSearchParam: action.payload,
      };
    case RESET_INITIAL_STATE:
      return initialState;
    case CLEAR_MESSAGE:
      return {
        ...state,
        message: '',
        updateMessage: '',
        updateSuccess: false,
        success: false,
      };
    default:
      return state;
  }
}

/**
 * 일상경비 수입지출 월계표 및 잔액조회
 */
export const selectEvdXpnImpExpdMmSht = (searchParams) => {
  return {
    type: FETCH_EVD_XPN_IMP_EXPD_MM_SHT,
    payload: fetchEvdXpnImpExpdMmSht(searchParams),
  };
};

/**
 * 확인여부 저장
 */
export const insertCnfYn = (searchParams) => {
  return {
    type: SAVE_CNF_YN,
    payload: saveCnfYn(searchParams),
  };
};

/**
 * 메시지초기화
 */
export const clearMessage = () => {
  return {
    type: CLEAR_MESSAGE,
  };
};

/**
 *
 */
export const setBeforeSearchParam = (searchParams) => {
  return {
    type: SET_BEFORE_SEARCH_PARAM,
    payload: searchParams,
  };
};

//기관명 콤보 조회
export const selectIstInfoList = (searchParam) => {
  return {
    type: FETCH_IST_INFO_LIST,
    payload: fetchCommonComboList({ slt_box_no: '005', ...searchParam }),
  };
};

//부서명 콤보 조회
export const selectDeptInfoList = (searchParam) => {
  return {
    type: FETCH_DEPT_INFO_LIST,
    payload: fetchCommonComboList({ slt_box_no: '041', ...searchParam }),
  };
};

//계좌번호 콤보 조회
export const selectActNoList = (searchParam) => {
  return {
    type: FETCH_ACT_NO_LIST,
    payload: fetchCommonComboList({ slt_box_no: '032', ...searchParam }),
  };
};

//회계연도 콤보 조회
export const selectAccYrList = () => {
  return {
    type: FETCH_ACC_YR_LIST,
    payload: fetchCommonComboList({ slt_box_no: '025' }),
  };
};

//시세/구세 콤보 조회
export const selectArvnActDscdList = () => {
  return {
    type: FETCH_ARVN_ACT_DSCD_LIST,
    payload: fetchCommonComboList({
      slt_box_no: '003',
      cd_grp_nm: '세입계좌구분코드',
      sys_cd: 'GW',
    }),
  };
};

//계좌상태 콤보 조회
export const selectDpactStcdList = () => {
  return {
    type: FETCH_DPACT_STCD_LIST,
    payload: fetchCommonComboList({
      slt_box_no: '003',
      cd_grp_nm: '수신계좌상태코드',
      sys_cd: 'GW',
    }),
  };
};

//대행계좌 구분코드
export const selectAgnActDscdList = () => {
  return {
    type: AGN_ACT_DSCD_LIST,
    payload: fetchCommonComboList({
      slt_box_no: '003',
      cd_grp_nm: '대행계좌구분코드',
      sys_cd: 'GW',
    }),
  };
};

/**
 * 조회조건 저장
 */
export const saveSearchFormValues = (searchFormValues) => {
  return {
    type: SAVE_SEARCH_FORM_VALUES,
    payload: {
      ...searchFormValues,
      init: false,
    },
  };
};

/**
 * 상태초기화
 */
export const resetInitialState = () => {
  return {
    type: RESET_INITIAL_STATE,
  };
};
