import { SUCCESS, REQUEST, FAILURE } from '../../../common/utils/action-type-utils';
import { checkDupUserId, fetchDeptCd, fetchIstCd, registerUser } from '../../../lib/cmm/signup.api';

// actions
const CHECK_DUP_USERID = 'signupState/CHECK_DUP_USERID';
const FETCH_IST_CD = 'signupState/FETCH_IST_CD';
const FETCH_DEPT_CD = 'signupState/FETCH_DEPT_CD';
const REGISTER_USER = 'signupState/REGISTER_USER';
const RESET_SIGNUP = 'signupState/RESET_SIGNUP';
const CLEAR_MESSAGE = 'signupState/CLEAR_MESSAGE';

// initial state
const initialState = {
  loadingByDupCheck: false,
  successByDupCheck: null,
  updating: false,
  updatingSuccess: false,
  message: '',
  list: [],
  totalCount: 0,
  istNoList: [],
  deptNoList: [],
  passDupId: false,
};

// reducers
export default function signupState(state = initialState, action) {
  switch (action.type) {
    case SUCCESS(CHECK_DUP_USERID): // 아이디중복체크 성공
      return {
        ...state,
        loadingByDupCheck: false,
        successByDupCheck: true,
        passDupId: action.payload.data.userIdYn === 'Y',
      };
    case REQUEST(CHECK_DUP_USERID): // 아이디중복체크 전송
      return {
        ...state,
        loadingByDupCheck: true,
        successByDupCheck: null,
        message: '',
        passDupId: false,
      };
    case FAILURE(CHECK_DUP_USERID): // 아이디중복체크 실패
      return {
        ...state,
        loadingByDupCheck: false,
        successByDupCheck: false,
        passDupId: false,
        message: action.payload.response?.data?.message,
      };
    case SUCCESS(FETCH_IST_CD): // 기관코드조회 성공
      return {
        ...state,
        // loadingByDupCheck: false,
        // successByDupCheck: true,
        istNoList: action.payload.data.subDto,
      };
    case REQUEST(FETCH_IST_CD): // 기관코드조회 전송
      return {
        ...state,
        // loadingByDupCheck: true,
        // successByDupCheck: null,
        message: '',
      };
    case FAILURE(FETCH_IST_CD): // 기관코드조회 실패
      return {
        ...state,
        // loadingByDupCheck: false,
        // successByDupCheck: false,
        message: action.payload.response?.data?.message,
      };
    case SUCCESS(FETCH_DEPT_CD): // 부서코드조회 성공
      return {
        ...state,
        // loadingByDupCheck: false,
        // successByDupCheck: true,
        deptNoList: action.payload.data.subDto,
      };
    case REQUEST(FETCH_DEPT_CD): // 부서코드조회 전송
      return {
        ...state,
        // loadingByDupCheck: true,
        // successByDupCheck: null,
        message: '',
      };
    case FAILURE(FETCH_DEPT_CD): // 부서코드조회 실패
      return {
        ...state,
        // loadingByDupCheck: false,
        // successByDupCheck: false,
        message: action.payload.response?.data?.message,
      };
    case SUCCESS(REGISTER_USER): // 회원가입 성공
      return {
        ...state,
        updating: false,
        updatingSuccess: true,
      };
    case REQUEST(REGISTER_USER): // 회원가입 전송
      return {
        ...state,
        updating: true,
        updatingSuccess: null,
        message: '',
      };
    case FAILURE(REGISTER_USER): // 회원가입 실패
      return {
        ...state,
        updating: false,
        updatingSuccess: false,
        message: action.payload.response?.data?.message,
      };
    case CLEAR_MESSAGE:
      return {
        ...state,
        message: '',
        updatingSuccess: false,
      };
    case RESET_SIGNUP:
      return initialState;
    default:
      return state;
  }
}

/**
 * 아이디 중복체크
 */
export const checkDupId = (searchParams) => {
  return {
    type: CHECK_DUP_USERID,
    payload: checkDupUserId(searchParams),
  };
};

/**
 * 기관코드 조회
 */
export const selectIstCd = (searchParams) => {
  return {
    type: FETCH_IST_CD,
    payload: fetchIstCd(searchParams),
  };
};

/**
 * 부서코드 조회
 */
export const selectDeptCd = (searchParams) => {
  return {
    type: FETCH_DEPT_CD,
    payload: fetchDeptCd(searchParams),
  };
};

/**
 * 회원가입
 */
export const signupUser = (dto) => {
  return {
    type: REGISTER_USER,
    payload: registerUser(dto),
  };
};

/** 초기화 */
export const resetSignup = () => {
  return {
    type: RESET_SIGNUP,
  };
};

/**
 * 메시지초기화
 */
export const clearMessage = () => {
  return {
    type: CLEAR_MESSAGE,
  };
};
