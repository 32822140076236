import instance from '../../common/config/api';

/**
 * FDS 알림수신 등록 목록 조회
 * @param {object} params
 * @returns
 */
export const fetchFdsUmsUser = (params) =>
  instance.post(`${process.env.REACT_APP_SERVER_URL}/scq/scq/fds/0002/selectFdsUmsUser`, params);

/**
 * FDS 알림수신 등록
 * @param {array} dtos
 * @returns
 */
export const mergeFdsUmsUser = (dtos) =>
  instance.post(`${process.env.REACT_APP_SERVER_URL}/scq/scq/fds/0002/saveFdsUmsUser`, {
    main: dtos,
    mainCnt: dtos.length,
  });
