import instance from '../../common/config/api';

/**
 * 금고영업점 - 공채 - 증서관리(전지점)
 * @param {object} params
 * @returns
 */
export const fetchDeepMngListByAllBranch = (params) =>
  instance.post(`${process.env.REACT_APP_SERVER_URL}/scq/scq/brc/0001/selectPmnyAllBranchList`, params);

/**
 * 금고영업점 - 공채 - 증서관리(지점별)
 * @param {object} params
 * @returns
 */
export const fetchDeepMngListByBranch = (params) =>
  instance.post(`${process.env.REACT_APP_SERVER_URL}/scq/scq/brc/0001/selectPmnyEachBranchList`, params);

/**
 * 금고영업점 - 공채 - 증서관리 저장
 * @param {object} params
 * @returns
 */
export const updateDeepMngBySave = (dto) =>
  instance.post(`${process.env.REACT_APP_SERVER_URL}/scq/scq/brc/0001/mergePmnyCustomerAprv`, {
    main: [dto],
    main_cnt: 1,
  });

/**
 * 금고영업점 - 공채 - 증서관리 확인
 * @param {object} params
 * @returns
 */
export const updateDeepMngByConfirm = (dto) =>
  instance.post(`${process.env.REACT_APP_SERVER_URL}/scq/scq/brc/0001/updatePmnyRespPersonAprv`, {
    main: [dto],
    main_cnt: 1,
  });

/**
 * 금고영업점 - 공채 - 증서관리 초기화
 * @param {object} params
 * @returns
 */
export const updateDeepMngByAprvInit = (dto) =>
  instance.post(`${process.env.REACT_APP_SERVER_URL}/scq/scq/brc/0001/updateRespPersonAprvInit`, {
    main: [dto],
    main_cnt: 1,
  });

/**
 * 운용상품 - 전 영입일 조회
 * @param {object} params
 * @returns
 */
export const fetchBbzdtInf = (params) =>
  instance.post(`${process.env.REACT_APP_SERVER_URL}/scq/scq/fxp/0002/selectBbzdtInf`, params);
