import instance from '../../common/config/api';

// (아답터) 서울시 세입월계표 조회
export const fetchSeoulAnrvMm = (params) =>
  instance.post(`${process.env.REACT_APP_SERVER_URL}/scq/scq/rpt/0001/selectSeoulAnrvMm`, params);

// (아답터) 서울시 계좌별 세입월계표 조회
export const fetchSeoulAnrvMmByAct = (params) =>
  instance.post(`${process.env.REACT_APP_SERVER_URL}/scq/scq/rpt/0001/selectSeoulAnrvMmByAct`, params);

// 서울시 일자별 세입월계표 조회
export const fetchSeoulAnrvMmByDay = (params) =>
  instance.post(`${process.env.REACT_APP_SERVER_URL}/scq/scq/rpt/0001/selectSeoulAnrvMmByDay`, params);

// 세입월계표(신한_강남구청) 조회
export const fetchAnrvMmGangnam = (params) =>
  instance.post(`${process.env.REACT_APP_SERVER_URL}/scq/scq/rpt/0001/selectAnrvMmGangnam`, params);

// 용산구청 (세입)세입월계표 조회
export const fetchAnrvMmYongsan = (params) =>
  instance.post(`${process.env.REACT_APP_SERVER_URL}/scq/scq/rpt/0001/selectAnrvMmYongsan`, params);
