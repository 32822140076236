import instance from '../../common/config/api';

/**
 * 일상경비 - 거래내역장 조회
 * @param {object} params
 * @returns
 */

export const fetchEvdXpnTrnPrtsList = (params) =>
  instance.post(`${process.env.REACT_APP_SERVER_URL}/scq/scq/ils/0001/selectEvdXpnTrnPrtsList`, params);
