import instance from '../../common/config/api';

/**
 * 세출보고서 - 자금운용내역장 조회
 * @returns
 */
export const fetchFdOpgHbokBkgList = (params) =>
  instance.post(`${process.env.REACT_APP_SERVER_URL}/scq/scq/rpt/0002/selectFdOpgHbokBkg`, params);

/**
 * 세출보고서 - 자금운용내역장(기금) 조회
 * @returns
 */
export const fetchFdOpgHbokBkgGg = (params) =>
  instance.post(`${process.env.REACT_APP_SERVER_URL}/scq/scq/rpt/0002/selectFdOpgHbokBkgGg`, params);
