import { SUCCESS, REQUEST, FAILURE } from '../../../common/utils/action-type-utils';
import {
  fetchEtcExchageDate,
  fetchEtcLosIdmnList,
  fetchEtcLosIdmnOupList,
  saveEtcLosIdmnInfo,
} from './../../../lib/etc/etc0501m01.api';
import { fetchBrList, fetchLevyIstList, fetchRepIstList } from './../../../lib/etc/etc0501m01.api';
import dayjs from 'dayjs';

// actions
const FETCH_EXCHAGE_DATE = 'etc0501m01/FETCH_EXCHAGE_DATE'; // 교환회부일자
const FETCH_LOS_IDMN = 'etc0501m01/FETCH_LOS_IDMN'; // 테이블 목록
const FETCH_LOS_IDMN_OUP = 'etc0501m01/FETCH_LOS_IDMN_OUP'; // 수납기관 - 영수증 출력
const SAVE_LOS_IDMN = 'etc0501m01/SAVE_LOS_IDMN'; // 등록/수정/삭제

const FETCH_LEVY_IST = 'etc0501m01/FETCH_LEVY_IST'; // 징수기관 콤보
const FETCH_REP_IST = 'etc0501m01/FETCH_REP_IST'; // 수납기관 콤보
const FETCH_BR = 'etc0501m01/FETCH_BR'; // 영업점 콤보

const SAVE_SEARCH_FORM_VALUES = 'etc0501m01/SAVE_SEARCH_FORM_VALUES';
const RESET_STATE = 'etc0501m01/RESET_STATE';

export const initFormValues = {
  kyowhandate: dayjs().format('YYYY-MM-DD'), // 교환회부일자
  seipindate: dayjs().format('YYYY-MM-DD'), // 세입입금일자
  ocryn: 'R', // 구분
  jingsugigwan: '', // 징수기관
};

// initial state
const initialState = {
  loading: false,
  success: false,
  message: '',
  list: [],
  totalCount: 0,

  exchageDateLoading: false, // 교환회부일자
  exchageDateSuccess: false, // 교환회부일자
  exchageDateList: [], // 교환회부일자

  receiptLoading: false, // 영수증
  receiptSuccess: false, // 영수증
  receiptList: [], // 영수증
  receiptCount: 0, // 영수증

  updateSuccess: false,
  resultCnt: -1,

  levyIstLoading: false, // 징수기관 콤보
  levyIstSuccess: false, // 징수기관 콤보
  levyIstList: [], // 징수기관 콤보

  repIstLoading: false, // 수납기관 콤보
  repIstSuccess: false, // 수납기관 콤보
  repIstList: [], // 수납기관 콤보

  brLoading: false, // 영업점 콤보
  brSuccess: false, // 영업점 콤보
  brList: [], // 영업점 콤보

  searchFormValues: {
    init: true,
    ...initFormValues,
  },
};

// reducers
export default function etc0501m01(state = initialState, action) {
  switch (action.type) {
    case SUCCESS(FETCH_EXCHAGE_DATE): // 조회 성공 - 교환회부일자
      return {
        ...state,
        exchageDateSuccess: true,
        exchageDateLoading: false,
        exchageDateList: action.payload.data?.chkdate,
      };
    case REQUEST(FETCH_EXCHAGE_DATE): // 조회 요청 - 교환회부일자
      return {
        ...state,
        exchageDateLoading: true,
        exchageDateSuccess: false,
      };
    case FAILURE(FETCH_EXCHAGE_DATE): // 조회 실패 - 교환회부일자
      return {
        ...state,
        exchageDateLoading: false,
        exchageDateSuccess: false,
      };
    case SUCCESS(FETCH_LOS_IDMN): // 조회 성공 - 테이블 목록
      return {
        ...state,
        success: true,
        loading: false,
        list: action.payload.data?.main,
        totalCount: action.payload.data?.main_cnt,
        updateSuccess: false,
      };
    case REQUEST(FETCH_LOS_IDMN): // 조회 요청 - 테이블 목록
      return {
        ...state,
        loading: true,
        success: false,
        updateSuccess: false,
      };
    case FAILURE(FETCH_LOS_IDMN): // 조회 실패 - 테이블 목록
      return {
        ...initialState,
        loading: false,
        success: false,
        message: action.payload.response?.data?.message,
        updateSuccess: false,
      };
    case SUCCESS(FETCH_LOS_IDMN_OUP): // 영수증 조회 성공
      return {
        ...state,
        receiptSuccess: true,
        receiptLoading: false,
        receiptList: action.payload.data?.main1,
        receiptCount: action.payload.data?.main1_cnt,
      };
    case REQUEST(FETCH_LOS_IDMN_OUP): // 영수증 조회 요청
      return {
        ...state,
        receiptLoading: true,
        receiptSuccess: false,
      };
    case FAILURE(FETCH_LOS_IDMN_OUP): // 영수증 조회 실패
      return {
        ...state,
        receiptList: [],
        receiptLoading: false,
        receiptSuccess: false,
        message: action.payload.response?.data?.message,
      };
    case SUCCESS(SAVE_LOS_IDMN): // 저장 성공
      return {
        ...state,
        updateSuccess: true,
        loading: false,
        resultCnt: action.payload.data.resultCnt,
      };
    case REQUEST(SAVE_LOS_IDMN): // 저장 요청
      return {
        ...state,
        message: '',
        loading: true,
        updateSuccess: false,
      };
    case FAILURE(SAVE_LOS_IDMN): // 저장 실패
      return {
        ...initialState,
        loading: false,
        updateSuccess: false,
        resultCnt: -1,
        message: action.payload.response?.data?.message,
      };
    case SUCCESS(FETCH_LEVY_IST): // 조회 성공 - 징수기관 콤보
      return {
        ...state,
        levyIstSuccess: true,
        levyIstLoading: false,
        levyIstList: action.payload.data?.main,
      };
    case REQUEST(FETCH_LEVY_IST): // 조회 요청 - 징수기관 콤보
      return {
        ...state,
        levyIstLoading: true,
        levyIstSuccess: false,
      };
    case FAILURE(FETCH_LEVY_IST): // 조회 실패 - 징수기관 콤보
      return {
        ...state,
        levyIstLoading: false,
        levyIstSuccess: false,
        levyIstList: [],
      };
    case SUCCESS(FETCH_REP_IST): // 조회 성공 - 수납기관 콤보
      return {
        ...state,
        repIstSuccess: true,
        repIstLoading: false,
        repIstList: action.payload.data?.main,
      };
    case REQUEST(FETCH_REP_IST): // 조회 요청 - 수납기관 콤보
      return {
        ...state,
        repIstLoading: true,
        repIstSuccess: false,
      };
    case FAILURE(FETCH_REP_IST): // 조회 실패 - 수납기관 콤보
      return {
        ...state,
        repIstLoading: false,
        repIstSuccess: false,
        repIstList: [],
      };
    case SUCCESS(FETCH_BR): // 조회 성공 - 영업점 콤보
      return {
        ...state,
        brSuccess: true,
        brLoading: false,
        brList: action.payload.data?.main,
      };
    case REQUEST(FETCH_BR): // 조회 요청 - 영업점 콤보
      return {
        ...state,
        brLoading: true,
        brSuccess: false,
      };
    case FAILURE(FETCH_BR): // 조회 실패 - 영업점 콤보
      return {
        ...state,
        brLoading: false,
        brSuccess: false,
        brList: [],
      };
    case SAVE_SEARCH_FORM_VALUES:
      return {
        ...state,
        // 기관명
        istInfoLoading: false,
        istInfoSuccess: false,
        searchFormValues: action.payload,
      };
    case RESET_STATE:
      return initialState;
    default:
      return state;
  }
}

/**
 * 기타 - 손해배상금 - Data Correction (교환회부일자)
 */
export const selectEtcExchageDate = () => {
  return {
    type: FETCH_EXCHAGE_DATE,
    payload: fetchEtcExchageDate(),
  };
};

/**
 * 기타 - 손해배상금 - Data Correction (테이블 목록)
 */
export const selectEtcLosIdmnList = (searchParams) => {
  return {
    type: FETCH_LOS_IDMN,
    payload: fetchEtcLosIdmnList(searchParams),
  };
};

/**
 * 기타 - 손해배상금 - Data Correction (영수증 출력)
 */
export const selectEtcLosIdmnOupList = (searchParams) => {
  return {
    type: FETCH_LOS_IDMN_OUP,
    payload: fetchEtcLosIdmnOupList(searchParams),
  };
};

/**
 * 기타 - 손해배상금 - Data Correction - 등록/수정/삭제
 */
export const saveEtcLosIdmnList = (dtos) => {
  return {
    type: SAVE_LOS_IDMN,
    payload: saveEtcLosIdmnInfo(dtos),
  };
};

/**
 * 기타 - 손해배상금 - Data Correction (징수기관 콤보)
 */
export const selectLevyIstList = (searchParams) => {
  return {
    type: FETCH_LEVY_IST,
    payload: fetchLevyIstList(searchParams),
  };
};

/**
 * 기타 - 손해배상금 - Data Correction (징수기관 콤보)
 */
export const selectRepIstList = (searchParams) => {
  return {
    type: FETCH_REP_IST,
    payload: fetchRepIstList(searchParams),
  };
};

/**
 * 기타 - 손해배상금 - Data Correction (징수기관 콤보)
 */
export const selectBrList = (searchParams) => {
  return {
    type: FETCH_BR,
    payload: fetchBrList(searchParams),
  };
};

/**
 * 조회조건 저장
 */
export const saveSearchFormValues = (searchFormValues) => {
  return {
    type: SAVE_SEARCH_FORM_VALUES,
    payload: {
      ...searchFormValues,
      init: false,
    },
  };
};

/**
 * 법인카드 상태초기화
 */
export const resetEtc0501m01 = () => {
  return {
    type: RESET_STATE,
  };
};
