import React from 'react';
import styled from 'styled-components';
import WOIcon from './WOIcon';

// type severityType = 'warning' | 'advisory'(추가) | 'caution' / 위험(상), 경고(중), 주의(하)
// severityType color = 'warning(상, red, 다각형)', 'advisory(중, orange, 세모)', 'caution(하, yellow, 네모)'

const WOAlarm = ({ severity, label, count, onClick, type, total }) => {
  return (
    <Alarm
      type="button"
      className={`alarm  ${severity} ${total === true ? 'alarm-total' : ''} alarm-${type}`}
      onClick={onClick}>
      <WOIcon icon={severity} />
      <div className="txt-box">
        <span className="txt">{label}</span>
        <span className="count">{count}</span>
      </div>
    </Alarm>
  );
};

export default WOAlarm;

const Alarm = styled.button`
  min-width: 108px;
  height: 34px;
  display: inline-flex;
  align-items: center;
  justify-content: space-between;
  color: #fff;
  padding: 8px 8px 8px 4px;
  border: 0;
  column-gap: 1px;
  .txt-box {
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: space-between;
    font-size: 12px;
    font-weight: 700;
    gap: 0 4px;

    > span {
      display: inline-block;
    }
  }

  &.alarm {
    vertical-align: top;
    cursor: unset;
    &:nth-last-of-type(1) {
      border-radius: 0 8px 8px 0;
    }

    &:first-of-type {
      border-radius: 8px 0 0 8px;
    }

    > .icon {
      width: 24px;
      height: 24px;
    }

    &.alarm-table {
      margin-top: -10px;
      margin-bottom: 6px;
      // border-radius: 8px;
      // margin-right: 4px;
      min-width: 92px;
      height: 30px;
      // &:last-child {
      //   margin-right: 0;
      // }
    }

    &.alarm-total {
      min-width: 70px;
      background: linear-gradient(0deg, rgba(0, 0, 0, 0.1) 0%, rgba(0, 0, 0, 0.1) 100%),
        linear-gradient(6deg, #8fb7ea -0.26%, #8e99ff 89.59%);
      .icon {
        display: none;
      }
    }
  }

  // severity
  &.warning {
    background: linear-gradient(180deg, #ff4f4f 0%, #ff5858 100%);
    > .icon {
      background-size: 18px 19px;
    }
  }

  &.advisory {
    background: linear-gradient(180deg, #ff814b 0%, #ff8b59 100%);
  }

  &.caution {
    background: linear-gradient(180deg, #ffc31a 0%, #ffc555 100%);
    > .icon {
      background-size: 18px 17px;
    }
  }
`;
