import { SUCCESS, REQUEST, FAILURE } from '../../../common/utils/action-type-utils';
import { fetchCpcadIsuLdgList } from '../../../lib/cad/cpcadIsuLdg.api';
import { fetchCommonComboList } from '../../../lib/cmm/combo.api';

// actions
const FETCH_CPCAD_ISU_LDG_LIST = 'cpcadIsuLdg/FETCH_CPCAD_ISU_LDG_LIST';
const FETCH_IST_INFO_LIST = 'cpcadIsuLdg/FETCH_IST_INFO_LIST';
const FETCH_DEPT_CD_LIST = 'cpcadIsuLdg/FETCH_DEPT_CD_LIST';
const FETCH_BIZ_NO_LIST = 'cpcadIsuLdg/FETCH_BIZ_NO_LIST';
const FETCH_MBR_NO_LIST = 'cpcadIsuLdg/FETCH_MBR_NO_LIST';
const SAVE_SEARCH_FORM_VALUES = 'cpcadIsuLdg/SAVE_SEARCH_FORM_VALUES';
const RESET_STATE = 'cpcadIsuLdg/RESET_STATE';

export const initFormValues = {
  apv_dis: 'Y',
  biz_no: 'all',
  cus_dept_cd: 'all',
  stl_acno: 'all',
};

// initial state
const initialState = {
  loading: false,
  success: false,
  message: '',
  list: [],
  totalCount: 0,
  // 콤보 - 기관명
  istInfoSuccess: false,
  istInfoLoading: false,
  istInfoList: [],
  // 콤보 - 부서명
  deptCdSuccess: false,
  deptCdLoading: false,
  deptCdList: [],
  // 콤보 - 사업자 번호
  bizNoSuccess: false,
  bizNoLoading: false,
  bizNoList: [],
  // 콤보 - 계좌번호
  mbrNoSuccess: false,
  mbrNoLoading: false,
  mbrNoList: [],
  // 검색조건
  searchFormValues: {
    init: true,
    ...initFormValues,
  },
};

// reducers
export default function cpcadIsuLdg(state = initialState, action) {
  switch (action.type) {
    case SUCCESS(FETCH_CPCAD_ISU_LDG_LIST): // 조회 성공
      return {
        ...state,
        success: true,
        loading: false,
        list: action.payload.data?.main,
        totalCount: action.payload?.main_cnt,
      };
    case REQUEST(FETCH_CPCAD_ISU_LDG_LIST): // 조회 요청
      return {
        ...state,
        loading: true,
        success: false,
        message: '',
      };
    case FAILURE(FETCH_CPCAD_ISU_LDG_LIST): // 조회실패
      return {
        ...initialState,
        loading: false,
        success: false,
        message: action.payload.response?.data?.message,
      };
    // 기관명
    case SUCCESS(FETCH_IST_INFO_LIST): // 조회 성공
      return {
        ...state,
        istInfoSuccess: true,
        istInfoLoading: false,
        istInfoList: action.payload.data?.subDto,
      };
    case REQUEST(FETCH_IST_INFO_LIST): // 조회 요청
      return {
        ...state,
        istInfoLoading: true,
        istInfoSuccess: false,
      };
    case FAILURE(FETCH_IST_INFO_LIST): // 조회실패
      return {
        istInfoLoading: false,
        istInfoSuccess: false,
        istInfoList: [],
      };
    // 부서명
    case SUCCESS(FETCH_DEPT_CD_LIST): // 조회 성공
      return {
        ...state,
        deptCdSuccess: true,
        deptCdLoading: false,
        deptCdList: action.payload.data?.subDto,
      };
    case REQUEST(FETCH_DEPT_CD_LIST): // 조회 요청
      return {
        ...state,
        deptCdLoading: true,
        deptCdSuccess: false,
      };
    case FAILURE(FETCH_DEPT_CD_LIST): // 조회실패
      return {
        ...state,
        deptCdLoading: false,
        deptCdSuccess: false,
        deptCdList: [],
      };
    // 사업자 번호
    case SUCCESS(FETCH_BIZ_NO_LIST): // 조회 성공
      return {
        ...state,
        bizNoSuccess: true,
        bizNoLoading: false,
        bizNoList: action.payload.data?.subDto,
      };
    case REQUEST(FETCH_BIZ_NO_LIST): // 조회 요청
      return {
        ...state,
        bizNoLoading: true,
        bizNoSuccess: false,
      };
    case FAILURE(FETCH_BIZ_NO_LIST): // 조회실패
      return {
        ...state,
        bizNoLoading: false,
        bizNoSuccess: false,
        bizNoList: [],
      };
    // 계좌 번호
    case SUCCESS(FETCH_MBR_NO_LIST): // 조회 성공
      return {
        ...state,
        mbrNoSuccess: true,
        mbrNoLoading: false,
        mbrNoList: action.payload.data?.subDto,
      };
    case REQUEST(FETCH_MBR_NO_LIST): // 조회 요청
      return {
        ...state,
        mbrNoLoading: true,
        mbrNoSuccess: false,
      };
    case FAILURE(FETCH_MBR_NO_LIST): // 조회실패
      return {
        ...state,
        mbrNoLoading: false,
        mbrNoSuccess: false,
        mbrNoList: [],
      };
    case SAVE_SEARCH_FORM_VALUES:
      return {
        ...state,
        // 콤보 - 기관
        istInfoSuccess: false,
        istInfoLoading: false,
        // 콤보 - 부서명
        deptCdSuccess: false,
        deptCdLoading: false,
        // 콤보 - 사업자 번호
        bizNoSuccess: false,
        bizNoLoading: false,
        // 콤보 - 계좌번호
        mbrNoSuccess: false,
        mbrNoLoading: false,
        searchFormValues: action.payload,
      };
    case RESET_STATE:
      return initialState;
    default:
      return state;
  }
}

/**
 * 법인카드 - 발급대장
 */
export const selectListCpcadIsuLdg = (searchParams) => {
  return {
    type: FETCH_CPCAD_ISU_LDG_LIST,
    payload: fetchCpcadIsuLdgList(searchParams),
  };
};

/**
 * 기관명 콤보 조회
 */
export const selectIstInfoComboList = (searchParams) => {
  return {
    type: FETCH_IST_INFO_LIST,
    payload: fetchCommonComboList({ slt_box_no: '922', ...searchParams }),
  };
};

/**
 * 부서명 콤보 조회
 */
export const selectDeptCdComboList = (searchParams) => {
  return {
    type: FETCH_DEPT_CD_LIST,
    payload: fetchCommonComboList({ slt_box_no: '199', ...searchParams }),
  };
};

/**
 * 사업자 번호 콤보 조회
 */
export const selectBizNoComboList = (searchParams) => {
  return {
    type: FETCH_BIZ_NO_LIST,
    payload: fetchCommonComboList({ slt_box_no: '044', ...searchParams }),
  };
};

/**
 * 계좌번호 콤보 조회
 */
export const selectMbrNoComboList = (searchParams) => {
  return {
    type: FETCH_MBR_NO_LIST,
    payload: fetchCommonComboList({ slt_box_no: '045', ...searchParams }),
  };
};

/**
 * 조회조건 저장
 */
export const saveSearchFormValues = (searchFormValues) => {
  return {
    type: SAVE_SEARCH_FORM_VALUES,
    payload: {
      ...searchFormValues,
      init: false,
    },
  };
};

/**
 * 법인카드 상태초기화
 */
export const resetDeptUsgPts = () => {
  return {
    type: RESET_STATE,
  };
};
