import instance from '../../common/config/api';

/**
 * 제로페이 - 서무담당 번호 등록(조회)
 * @param {object} params
 * @returns
 */

export const fetchSMChrgNoRgList = (params) =>
  instance.post(`${process.env.REACT_APP_SERVER_URL}/scq/scq/zpb/0001/selectSMChrgNoRg`, params);

/**
 * 제로페이 - 서무담당 번호 저장
 * @param {object} dtos
 * @returns
 */
export const updateSMChrgNoRgList = (dtos) =>
  instance.post(`${process.env.REACT_APP_SERVER_URL}/scq/scq/zpb/0001/saveSMChrgNoRg`, {
    subDto: dtos,
    subDtoCnt: dtos.length,
  });
