import dayjs from 'dayjs';
import { SUCCESS, REQUEST, FAILURE } from '../../../common/utils/action-type-utils';
import { fetchCommonComboList } from '../../../lib/cmm/combo.api';
import { fetchEachBzbrRcnPts } from '../../../lib/fxp/FXP0004.api';

// actions
const FETCH_EACH_BZBR_RCNPTS = 'FXP0402V01/FETCH_EACH_BZBR_RCNPTS';

const FETCH_BR_INFO_LIST = 'FXP0402V01/FETCH_IST_INFO_LIST';

const RESET_INITIAL_STATE = 'FXP0402V01/RESET_INITIAL_STATE';
const SAVE_SEARCH_FORM_VALUES = 'FXP0402V01/SAVE_SEARCH_FORM_VALUES';

const CLEAR_MESSAGE = 'FXP0402V01/CLEAR_MESSAGE';
const SET_BEFORE_SEARCH_PARAM = 'FXP0402V01/SET_BEFORE_SEARCH_PARAM';

export const initFormValues = {
  br_cd: '',
  br_nm: '',
  inq_dt: dayjs().add(-1, 'day').format('YYYYMMDD'),
};

// initial state
const initialState = {
  loading: false,
  success: false,
  message: '',
  list: [],
  list2: [],
  beforeSearchParam: {},

  /*콤보 - 영업점검색903*/
  brInfoLoading: false,
  brInfoSuccess: false,
  brInfoList: [],

  // 검색조건
  searchFormValues: {
    init: true,
    ...initFormValues,
  },
};

// reducers
export default function FXP0402V01(state = initialState, action) {
  switch (action.type) {
    case SUCCESS(FETCH_EACH_BZBR_RCNPTS): // 조회 성공
      return {
        ...state,
        success: true,
        loading: false,
        list: action.payload.data?.main,
        list2: action.payload.data?.output_data,
      };
    case REQUEST(FETCH_EACH_BZBR_RCNPTS): // 조회 요청
      return {
        ...state,
        loading: true,
        success: false,
      };
    case FAILURE(FETCH_EACH_BZBR_RCNPTS): // 조회실패
      return {
        ...state,
        list: [],
        list2: [],
        loading: false,
        success: false,
        message: action.payload.response?.data?.message,
      };
    // 영업점검색
    case SUCCESS(FETCH_BR_INFO_LIST): // 조회 성공
      return {
        ...state,
        brInfoSuccess: true,
        brInfoLoading: false,
        brInfoList: action.payload.data?.subDto,
      };
    case REQUEST(FETCH_BR_INFO_LIST): // 조회 요청
      return {
        ...state,
        brInfoLoading: true,
        brInfoSuccess: false,
      };
    case FAILURE(FETCH_BR_INFO_LIST): // 조회실패
      return {
        ...state,
        brInfoLoading: false,
        brInfoSuccess: false,
        brInfoList: [],
      };
    case SAVE_SEARCH_FORM_VALUES:
      return {
        ...state,
        brInfoSuccess: false,
        brInfoLoading: false,
        searchFormValues: action.payload,
      };
    case RESET_INITIAL_STATE:
      return initialState;
    case SET_BEFORE_SEARCH_PARAM:
      return {
        ...state,
        beforeSearchParam: action.payload,
      };
    case CLEAR_MESSAGE:
      return {
        ...state,
        message: '',
        success: false,
      };
    default:
      return state;
  }
}

/**
 * 영업점별 대사현황 조회
 */
export const selectEachBzbrRcnPts = (searchParams) => {
  return {
    type: FETCH_EACH_BZBR_RCNPTS,
    payload: fetchEachBzbrRcnPts(searchParams),
  };
};

//영업점 콤보 조회
export const selectBrInfoList = (searchParam) => {
  return {
    type: FETCH_BR_INFO_LIST,
    payload: fetchCommonComboList({ slt_box_no: '903', ...searchParam }),
  };
};

/**
 * 조회조건 저장
 */
export const saveSearchFormValues = (searchFormValues) => {
  return {
    type: SAVE_SEARCH_FORM_VALUES,
    payload: {
      ...searchFormValues,
      init: false,
    },
  };
};

/**
 * 상태초기화
 */
export const resetInitialState = () => {
  return {
    type: RESET_INITIAL_STATE,
  };
};

/**
 * 메시지초기화
 */
export const clearMessage = () => {
  return {
    type: CLEAR_MESSAGE,
  };
};

/**
 *
 */
export const setBeforeSearchParam = (searchParams) => {
  return {
    type: SET_BEFORE_SEARCH_PARAM,
    payload: searchParams,
  };
};
