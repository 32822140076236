import { SUCCESS, REQUEST, FAILURE } from '../../../common/utils/action-type-utils';
import {
  fetchRptMngRgrList,
  fetchRptMngRgrSub,
  fetchRptAcnoMapping,
  fetchRptMngRgrPkNmgt,
  updateRptMngRgr,
} from '../../../lib/rpt/RPT0004.api';

// actions
const FETCH_RPT_MNG_RGR_LIST = 'RPT0401M01/FETCH_RPT_MNG_RGR_LIST';
const FETCH_RPT_MNG_RGR_SUB = 'RPT0401M01/FETCH_RPT_MNG_RGR_SUB';
const FETCH_RPT_ACNO_MAPPING = 'RPT0401M01/FETCH_RPT_ACNO_MAPPING';
const FETCH_RPT_MNG_RGR_PK_MNGT = 'RPT0401M01/FETCH_RPT_MNG_RGR_PK_MNGT';
const UPDATE_RPT_MNG_RGR = 'RPT0401M01/UPDATE_RPT_MNG_RGR';
const SAVE_SEARCH_FORM_VALUES = 'SYS0203V01/SAVE_SEARCH_FORM_VALUES';
const RESET_STATE = 'SYS0203V01/RESET_STATE';

export const initFormValues = {
  // 검색 영역
  cus_ist_cd: '', //기관명
  cus_dept_cd: 'all', //부서명
  br_cd: '', //영업점
};

// initial state
const initialState = {
  mngRgrLoading: false,
  mngRgrSuccess: false,
  mngRgrMessage: '',
  mngRgrList: [],
  mngSubLoading: false,
  mngSubSuccess: false,
  mngSubMessage: '',
  mngSubList: [],
  actnoLoading: false,
  actnoSuccess: false,
  actnoMessage: '',
  actnoList: [],
  rptPkLoading: false,
  rptPkSuccess: false,
  rptPkMessage: false,
  rptPk: {},
  updating: false,
  updateSuccess: false,
  updateMessage: '',
  // 검색조건
  searchFormValues: {
    init: true,
    ...initFormValues,
  },
};

// reducers
export default function RPT0401M01(state = initialState, action) {
  switch (action.type) {
    case SUCCESS(FETCH_RPT_MNG_RGR_LIST): // 조회 성공
      return {
        ...state,
        mngRgrSuccess: true,
        mngRgrLoading: false,
        mngRgrList: action.payload.data?.main,
      };
    case REQUEST(FETCH_RPT_MNG_RGR_LIST): // 조회 요청
      return {
        ...state,
        mngRgrMessage: '',
        mngRgrLoading: true,
        mngRgrSuccess: false,
      };
    case FAILURE(FETCH_RPT_MNG_RGR_LIST): // 조회 실패
      return {
        ...initialState,
        mngRgrLoading: false,
        mngRgrSuccess: false,
        mngRgrMessage: action.payload.response?.data?.message,
      };
    case SUCCESS(FETCH_RPT_MNG_RGR_SUB): // 상세조회 성공
      return {
        ...state,
        mngSubSuccess: true,
        mngSubLoading: false,
        mngSubList: action.payload.data?.sub01,
      };
    case REQUEST(FETCH_RPT_MNG_RGR_SUB): // 상세조회 요청
      return {
        ...state,
        mngSubMessage: '',
        mngSubLoading: true,
        mngSubSuccess: false,
      };
    case FAILURE(FETCH_RPT_MNG_RGR_SUB): // 상세조회 실패
      return {
        ...initialState,
        mngSubLoading: false,
        mngSubSuccess: false,
        mngSubMessage: action.payload.response?.data?.message,
        mngRgrList: state.mngRgrList,
      };
    case SUCCESS(FETCH_RPT_ACNO_MAPPING): // 계좌 매핑내역 조회 성공
      return {
        ...state,
        actnoSuccess: true,
        actnoLoading: false,
        actnoList: action.payload.data?.sub02,
      };
    case REQUEST(FETCH_RPT_ACNO_MAPPING): // 계좌 매핑내역 조회 요청
      return {
        ...state,
        actnoMessage: '',
        actnoLoading: true,
        actnoSuccess: false,
      };
    case FAILURE(FETCH_RPT_ACNO_MAPPING): // 계좌 매핑내역 조회 실패
      return {
        ...initialState,
        actnoLoading: false,
        actnoSuccess: false,
        actnoMessage: action.payload.response?.data?.message,
        mngRgrList: state.mngRgrList,
        mngSubList: state.mngSubList,
      };
    case SUCCESS(FETCH_RPT_MNG_RGR_PK_MNGT): // pk조회 성공
      return {
        ...state,
        rptPkSuccess: true,
        rptPkLoading: false,
        rptPk: action.payload.data?.main,
      };
    case REQUEST(FETCH_RPT_MNG_RGR_PK_MNGT): // pk조회 요청
      return {
        ...state,
        rptPkMessage: '',
        rptPkLoading: true,
        rptPkSuccess: false,
      };
    case FAILURE(FETCH_RPT_MNG_RGR_PK_MNGT): // pk조회 실패
      return {
        ...initialState,
        rptPkLoading: false,
        rptPkSuccess: false,
        rptPkMessage: action.payload.response?.data?.message,
      };
    case SUCCESS(UPDATE_RPT_MNG_RGR): // 저장 성공
      return {
        ...state,
        updating: false,
        updateSuccess: true,
      };
    case REQUEST(UPDATE_RPT_MNG_RGR): // 저장 요청
      return {
        ...state,
        updating: true,
        updateSuccess: false,
      };
    case FAILURE(UPDATE_RPT_MNG_RGR): // 저장 실패
      return {
        ...initialState,
        updating: false,
        updateSuccess: false,
        updateMessage: action.payload.response?.data?.message,
      };
    case SAVE_SEARCH_FORM_VALUES:
      return {
        ...state,
        searchFormValues: action.payload,
      };
    case RESET_STATE:
      return initialState;
    default:
      return state;
  }
}

/**
 * 보고서 관리대장 조회
 */
export const selectRptMngRgrList = (searchParams) => {
  return {
    type: FETCH_RPT_MNG_RGR_LIST,
    payload: fetchRptMngRgrList(searchParams),
  };
};

/**
 * 보고서 관리대장 상세 조회
 */
export const selectRptMngRgrSub = (searchParams) => {
  return {
    type: FETCH_RPT_MNG_RGR_SUB,
    payload: fetchRptMngRgrSub(searchParams),
  };
};

/**
 * 보고서별 계좌 맵핑내역 조회
 */
export const selectRptAcnoMapping = (searchParams) => {
  return {
    type: FETCH_RPT_ACNO_MAPPING,
    payload: fetchRptAcnoMapping(searchParams),
  };
};

/**
 * 보고서 관리대장 PK 조회
 */
export const selectRptMngRgrPkNmgt = (searchParams) => {
  return {
    type: FETCH_RPT_MNG_RGR_PK_MNGT,
    payload: fetchRptMngRgrPkNmgt(searchParams),
  };
};

/**
 * 보고서관리 전체 저장
 */
export const saveRptMngRgr = (searchParams) => {
  return {
    type: UPDATE_RPT_MNG_RGR,
    payload: updateRptMngRgr(searchParams),
  };
};

/**
 * 조회조건 저장
 */
export const saveSearchFormValues = (searchFormValues) => {
  return {
    type: SAVE_SEARCH_FORM_VALUES,
    payload: {
      ...searchFormValues,
      init: false,
    },
  };
};

/**
 * 상태초기화
 */
export const resetState = () => {
  return {
    type: RESET_STATE,
  };
};
