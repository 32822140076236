import { fetchIstList, insertIstDePt, fetchDeptList } from '../../../lib/sys/IstDeptCdMng.api';
import { SUCCESS, REQUEST, FAILURE } from '../../../common/utils/action-type-utils';

// actions
const FETCH_IST_LIST = 'IstDeptCdMng/FETCH_IST_LIST';
const INSERT_IST_DEPT = 'IstDeptCdMng/INSERT_IST_DEPT';
const FETCH_DEPT_LIST = 'IstDeptCdMng/FETCH_DEPT_LIST';
const SAVE_SEARCH_FORM_VALUES = 'IstDeptCdMng/SAVE_SEARCH_FORM_VALUES';
const RESET_STATE = 'IstDeptCdMng/RESET_STATE';

export const initFormValues = {
  cus_ist_nm: '', //고객기관명
  cus_ist_cd_search: '', //고객기관코드
};
// initial state
const initialState = {
  loading: false,
  success: true,
  message: '',
  list: [],
  details: [],
  totalCount: 0,
  updateSuccess: false,
  resultCnt: -1,
  subList: [],
  deptSuccess: true,
  deptMessage: '',
  // 검색조건
  searchFormValues: {
    init: true,
    ...initFormValues,
  },
};

// reducers
export default function IstDeptCdMng(state = initialState, action) {
  switch (action.type) {
    case SUCCESS(FETCH_IST_LIST): // 조회 성공
      return {
        ...state,
        success: true,
        loading: false,
        list: action.payload.data?.main,
        totalCount: action.payload?.main_cnt,
        updateSuccess: false,
      };
    case REQUEST(FETCH_IST_LIST): // 조회 요청
      return {
        ...state,
        loading: true,
        updateSuccess: false,
      };
    case FAILURE(FETCH_IST_LIST): // 조회실패
      return {
        ...initialState,
        loading: false,
        success: false,
        message: action.payload.response?.data?.message,
        updateSuccess: false,
      };
    case SUCCESS(INSERT_IST_DEPT): // 갱신 성공
      return {
        ...state,
        updateSuccess: true,
        loading: false,
        resultCnt: action.payload.data.resultCnt,
      };
    case REQUEST(INSERT_IST_DEPT): // 갱신 요청
      return {
        ...state,
        loading: true,
        updateSuccess: false,
      };
    case FAILURE(INSERT_IST_DEPT): // 갱신 실패
      return {
        ...initialState,
        loading: false,
        updateSuccess: false,
        resultCnt: -1,
        message: action.payload.response?.data?.message,
      };
    case SUCCESS(FETCH_DEPT_LIST): // 기관별 부서코드 조회 성공
      return {
        ...state,
        deptSuccess: true,
        deptLoading: false,
        subList: action.payload.data?.sub,
        totalCount: action.payload?.sub_cnt,
        updateSuccess: false,
      };
    case REQUEST(FETCH_DEPT_LIST): // 기관별 부서코드 조회 요청
      return {
        ...state,
        deptLoading: true,
        updateSuccess: false,
      };
    case FAILURE(FETCH_DEPT_LIST): // 기관별 부서코드 조회실패
      return {
        ...initialState,
        deptLoading: false,
        deptSuccess: false,
        deptMessage: action.payload.response?.data?.message,
        updateSuccess: false,
      };
    case SAVE_SEARCH_FORM_VALUES:
      return {
        ...state,
        searchFormValues: action.payload,
      };
    case RESET_STATE:
      return initialState;
    default:
      return { ...state, updateSuccess: false, deptSuccess: true };
  }
}

/**
 * 고객 기관 목록 조회
 */
export const selectIstList = (searchParams) => {
  return {
    type: FETCH_IST_LIST,
    payload: fetchIstList(searchParams),
  };
};

/**
 * 고객 기관 목록 갱신
 */
export const updateIstDePt = (dtos) => {
  return {
    type: INSERT_IST_DEPT,
    payload: insertIstDePt(dtos),
  };
};

/**
 * 기관별 부서목록 조회
 */
export const selectDeptList = (searchParams) => {
  return {
    type: FETCH_DEPT_LIST,
    payload: fetchDeptList(searchParams),
  };
};

/**
 * 조회조건 저장
 */
export const saveSearchFormValues = (searchFormValues) => {
  return {
    type: SAVE_SEARCH_FORM_VALUES,
    payload: {
      ...searchFormValues,
      init: false,
    },
  };
};

/**
 * 상태초기화
 */
export const resetState = () => {
  return {
    type: RESET_STATE,
  };
};
