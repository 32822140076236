import dayjs from 'dayjs';
import { SUCCESS, REQUEST, FAILURE } from '../../../common/utils/action-type-utils';
import { fetchCadApvPrtsList } from '../../../lib/cad/cadApvPrts.api';
import { fetchCommonComboList } from '../../../lib/cmm/combo.api';

// actions
const FETCH_APV_PRTS_LIST = 'cadApvPrts/FETCH_APV_PRTS_LIST';
const FETCH_IST_INFO_LIST = 'cadApvPrts/FETCH_IST_INFO_LIST';
const FETCH_DEPT_CD_LIST = 'cadApvPrts/FETCH_DEPT_CD_LIST';
const FETCH_BIZ_NO_LIST = 'cadApvPrts/FETCH_BIZ_NO_LIST';
const FETCH_MBR_NO_LIST = 'cadApvPrts/FETCH_MBR_NO_LIST';
const FETCH_CD_NO_LIST = 'cadApvPrts/FETCH_CD_NO_LIST';
const FETCH_BZCTG_CD_LIST = 'cadApvPrts/FETCH_BZCTG_CD_LIST';
const SAVE_SEARCH_FORM_VALUES = 'cadApvPrts/SAVE_SEARCH_FORM_VALUES';
const RESET_APV_PRTS = 'cadAvpPrts/RESET_APV_PRTS';

export const initFormValues = {
  apv_edt: dayjs().add(-1, 'd').format('YYYY-MM-DD'),
  apv_sam: 0,
  apv_sdt: dayjs().add(-8, 'd').format('YYYY-MM-DD'),
  apv_tm_start: '',
  apv_tm_end: '',
  biz_no: '',
  bzctg_cd: 'all',
  cd_no: 'all',
  cus_ist_cd: '6110000',
  dept_cd: '',
  frg_use_yn: 'all',
  hldy_yn: false,
  mbr_no: 'all',
  notseoul_yn: false,
  sal_can_yn: '',
};

// initial state
const initialState = {
  loading: false,
  success: false,
  message: '',
  list: [],
  totalCount: 0,
  // 콤보 - 기관명
  istInfoSuccess: false,
  istInfoLoading: false,
  istInfoList: [],
  // 콤보 - 부서명
  deptCdSuccess: false,
  deptCdLoading: false,
  deptCdList: [],
  // 콤보 - 사업자 번호
  bizNoSuccess: false,
  bizNoLoading: false,
  bizNoList: [],
  // 콤보 - 계좌번호
  mbrNoSuccess: false,
  mbrNoLoading: false,
  mbrNoList: [],
  // 콤보 - 카드번호
  cdNoSuccess: false,
  cdNoLoading: false,
  cdNoList: [],
  // 콤보 - 업종
  bzctgCdSuccess: false,
  bzctgCdLoading: false,
  bzctgCdList: [],
  // 검색조건
  searchFormValues: {
    init: true,
    ...initFormValues,
  },
};

// reducers
export default function cadAvpPrts(state = initialState, action) {
  switch (action.type) {
    case SUCCESS(FETCH_APV_PRTS_LIST): // 조회 성공
      return {
        ...state,
        success: true,
        loading: false,
        list: action.payload.data?.main,
        totalCount: action.payload?.main_cnt,
      };
    case REQUEST(FETCH_APV_PRTS_LIST): // 조회 요청
      return {
        ...state,
        loading: true,
      };
    case FAILURE(FETCH_APV_PRTS_LIST): // 조회실패
      return {
        ...state,
        loading: false,
        success: false,
        message: action.payload.response?.data?.message,
      };
    // 기관명
    case SUCCESS(FETCH_IST_INFO_LIST): // 조회 성공
      return {
        ...state,
        istInfoSuccess: true,
        istInfoLoading: false,
        istInfoList: action.payload.data?.subDto,
      };
    case REQUEST(FETCH_IST_INFO_LIST): // 조회 요청
      return {
        ...state,
        istInfoLoading: true,
        istInfoSuccess: false,
      };
    case FAILURE(FETCH_IST_INFO_LIST): // 조회실패
      return {
        istInfoLoading: false,
        istInfoSuccess: false,
        istInfoList: [],
      };
    // 부서명
    case SUCCESS(FETCH_DEPT_CD_LIST): // 조회 성공
      return {
        ...state,
        deptCdSuccess: true,
        deptCdLoading: false,
        deptCdList: action.payload.data?.subDto,
      };
    case REQUEST(FETCH_DEPT_CD_LIST): // 조회 요청
      return {
        ...state,
        deptCdLoading: true,
        deptCdSuccess: false,
      };
    case FAILURE(FETCH_DEPT_CD_LIST): // 조회실패
      return {
        ...state,
        deptCdLoading: false,
        deptCdSuccess: false,
        deptCdList: [],
      };
    // 사업자 번호
    case SUCCESS(FETCH_BIZ_NO_LIST): // 조회 성공
      return {
        ...state,
        bizNoSuccess: true,
        bizNoLoading: false,
        bizNoList: action.payload.data?.subDto,
      };
    case REQUEST(FETCH_BIZ_NO_LIST): // 조회 요청
      return {
        ...state,
        bizNoLoading: true,
        bizNoSuccess: false,
      };
    case FAILURE(FETCH_BIZ_NO_LIST): // 조회실패
      return {
        ...state,
        bizNoLoading: false,
        bizNoSuccess: false,
        bizNoList: [],
      };
    // 계좌 번호
    case SUCCESS(FETCH_MBR_NO_LIST): // 조회 성공
      return {
        ...state,
        mbrNoSuccess: true,
        mbrNoLoading: false,
        mbrNoList: action.payload.data?.subDto,
      };
    case REQUEST(FETCH_MBR_NO_LIST): // 조회 요청
      return {
        ...state,
        mbrNoLoading: true,
        mbrNoSuccess: false,
      };
    case FAILURE(FETCH_MBR_NO_LIST): // 조회실패
      return {
        ...state,
        mbrNoLoading: false,
        mbrNoSuccess: false,
        mbrNoList: [],
      };
    // 카드 번호
    case SUCCESS(FETCH_CD_NO_LIST): // 조회 성공
      return {
        ...state,
        cdNoSuccess: true,
        cdNoLoading: false,
        cdNoList: action.payload.data?.subDto,
      };
    case REQUEST(FETCH_CD_NO_LIST): // 조회 요청
      return {
        ...state,
        cdNoLoading: true,
        cdNoSuccess: false,
      };
    case FAILURE(FETCH_CD_NO_LIST): // 조회실패
      return {
        ...state,
        cdNoLoading: false,
        cdNoSuccess: false,
        cdNoList: [],
      };
    // 업종
    case SUCCESS(FETCH_BZCTG_CD_LIST): // 조회 성공
      return {
        ...state,
        bzctgCdSuccess: true,
        bzctgCdLoading: false,
        bzctgCdList: action.payload.data?.subDto,
      };
    case REQUEST(FETCH_BZCTG_CD_LIST): // 조회 요청
      return {
        ...state,
        bzctgCdLoading: true,
        bzctgCdSuccess: false,
      };
    case FAILURE(FETCH_BZCTG_CD_LIST): // 조회실패
      return {
        ...state,
        bzctgCdLoading: false,
        bzctgCdSuccess: false,
        bzctgCdList: [],
      };
    case SAVE_SEARCH_FORM_VALUES:
      return {
        ...state,
        // 콤보 - 기관
        istInfoSuccess: false,
        istInfoLoading: false,
        // 콤보 - 부서명
        deptCdSuccess: false,
        deptCdLoading: false,
        // 콤보 - 사업자 번호
        bizNoSuccess: false,
        bizNoLoading: false,
        // 콤보 - 계좌번호
        mbrNoSuccess: false,
        mbrNoLoading: false,
        // 콤보 - 카드번호
        cdNoSuccess: false,
        cdNoLoading: false,
        // 콤보 - 업종
        bzctgCdSuccess: false,
        bzctgCdLoading: false,
        searchFormValues: action.payload,
      };
    case RESET_APV_PRTS:
      return initialState;
    default:
      return state;
  }
}

/**
 * 법인카드 - 승인내역
 */
export const selectListApvPrts = (searchParams) => {
  return {
    type: FETCH_APV_PRTS_LIST,
    payload: fetchCadApvPrtsList(searchParams),
  };
};

/**
 * 기관명 콤보 조회
 */
export const selectIstInfoComboList = (searchParams) => {
  return {
    type: FETCH_IST_INFO_LIST,
    payload: fetchCommonComboList({ slt_box_no: '922', ...searchParams }),
  };
};

/**
 * 부서명 콤보 조회
 */
export const selectDeptCdComboList = (searchParams) => {
  return {
    type: FETCH_DEPT_CD_LIST,
    payload: fetchCommonComboList({ slt_box_no: '199', ...searchParams }),
  };
};

/**
 * 사업자 번호 콤보 조회
 */
export const selectBizNoComboList = (searchParams) => {
  return {
    type: FETCH_BIZ_NO_LIST,
    payload: fetchCommonComboList({ slt_box_no: '044', ...searchParams }),
  };
};

/**
 * 계좌번호 콤보 조회
 */
export const selectMbrNoComboList = (searchParams) => {
  return {
    type: FETCH_MBR_NO_LIST,
    payload: fetchCommonComboList({ slt_box_no: '045', ...searchParams }),
  };
};

/**
 * 카드번호 콤보 조회
 */
export const selectCdNoComboList = (searchParams) => {
  return {
    type: FETCH_CD_NO_LIST,
    payload: fetchCommonComboList({ slt_box_no: '012', ...searchParams }),
  };
};

/**
 * 업종 콤보 조회
 */
export const selectBzctgCdComboList = (searchParams) => {
  return {
    type: FETCH_BZCTG_CD_LIST,
    payload: fetchCommonComboList({ slt_box_no: '003', sys_cd: 'CD', cd_grp_nm: '가맹점업종코드', ...searchParams }),
  };
};

/**
 * 조회조건 저장
 */
export const saveSearchFormValues = (searchFormValues) => {
  return {
    type: SAVE_SEARCH_FORM_VALUES,
    payload: {
      ...searchFormValues,
      init: false,
    },
  };
};

/**
 * 법인카드 상태초기화
 */
export const resetApvPrts = () => {
  return {
    type: RESET_APV_PRTS,
  };
};
