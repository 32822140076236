import { fetchAsstmHndvPrts } from '../../../lib/arv/ARV0003.api';
import { SUCCESS, REQUEST, FAILURE } from '../../../common/utils/action-type-utils';
import dayjs from 'dayjs';
import { fetchCommonComboList } from '../../../lib/cmm/combo.api';

// actions
const FETCH_ASSTM_HNDV_PRTS = 'ARV0301V01/FETCH_ASSTM_HNDV_PRTS';

const RESET_INITIAL_STATE = 'ARV0301V01/RESET_INITIAL_STATE';
const SAVE_SEARCH_FORM_VALUES = 'ARV0301V01/SAVE_SEARCH_FORM_VALUES';

const FETCH_IST_INFO_LIST = 'ARV0301V01/FETCH_IST_INFO_LIST';
const FETCH_ACC_YR_LIST = 'ARV0301V01/FETCH_ACC_YR_LIST';

const SET_BEFORE_SEARCH_PARAM = 'ARV0301V01/SET_BEFORE_SEARCH_PARAM';
const CLEAR_MESSAGE = 'ARV0301V01/CLEAR_MESSAGE';

export const initFormValues = {
  vtrnx_ymdF: dayjs().add(-3, 'month').format('YYYYMMDD'),
  vtrnx_ymdT: dayjs().format('YYYYMMDD'),
  cus_ist_cd: '',
  vin_acct_acct_no: '',
  vfis_year: dayjs().format('YYYY'),
  vreq_dept_nm: '',
  vtrnx_amt: '',
};

// initial state
const initialState = {
  loading: false,
  success: false,
  message: '',
  list: [],
  totalCount: 0,
  beforeSearchParam: {},

  //콤보 - 기관명 005
  istInfoSuccess: false,
  istInfoLoading: false,
  istInfoList: [],

  //콤보 - 회계연도 025
  accYrSuccess: false,
  accYrLoading: false,
  accYrList: [],

  // 검색조건
  searchFormValues: {
    init: true,
    ...initFormValues,
  },
};

// reducers
export default function ARV0301V01(state = initialState, action) {
  switch (action.type) {
    case SUCCESS(FETCH_ASSTM_HNDV_PRTS): // 조회 성공
      return {
        ...state,
        success: true,
        loading: false,
        list: action.payload.data?.main,
        totalCount: action.payload?.output_data_cnt,
      };
    case REQUEST(FETCH_ASSTM_HNDV_PRTS): // 조회 요청
      return {
        ...state,
        loading: true,
      };
    case FAILURE(FETCH_ASSTM_HNDV_PRTS): // 조회실패
      return {
        ...state,
        list: [],
        loading: false,
        success: false,
        message: action.payload.response?.data?.message,
      };
    // 기관명
    case SUCCESS(FETCH_IST_INFO_LIST): // 조회 성공
      return {
        ...state,
        istInfoSuccess: true,
        istInfoLoading: false,
        istInfoList: action.payload.data?.subDto?.filter((item) => item.code !== '6110000'),
      };
    case REQUEST(FETCH_IST_INFO_LIST): // 조회 요청
      return {
        ...state,
        istInfoLoading: true,
        istInfoSuccess: false,
      };
    case FAILURE(FETCH_IST_INFO_LIST): // 조회실패
      return {
        istInfoLoading: false,
        istInfoSuccess: false,
        istInfoList: [],
      };
    // 회계연도
    case SUCCESS(FETCH_ACC_YR_LIST): // 조회 성공
      return {
        ...state,
        accYrSuccess: true,
        accYrLoading: false,
        accYrList: action.payload.data?.subDto,
      };
    case REQUEST(FETCH_ACC_YR_LIST): // 조회 요청
      return {
        ...state,
        accYrLoading: true,
        accYrSuccess: false,
      };
    case FAILURE(FETCH_ACC_YR_LIST): // 조회실패
      return {
        ...state,
        accYrLoading: false,
        accYrSuccess: false,
        accYrList: [],
      };
    case SAVE_SEARCH_FORM_VALUES:
      return {
        ...state,
        istInfoSuccess: false,
        istInfoLoading: false,
        accYrSuccess: false,
        accYrLoading: false,

        searchFormValues: action.payload,
      };
    case RESET_INITIAL_STATE:
      return initialState;
    case SET_BEFORE_SEARCH_PARAM:
      return {
        ...state,
        beforeSearchParam: action.payload,
      };
    case CLEAR_MESSAGE:
      return {
        ...state,
        message: '',
        success: false,
      };
    default:
      return state;
  }
}

/**
 * 세입 - 보조금 교부내역 조회
 */
export const selectAsstmHndvPrts = (searchParams) => {
  return {
    type: FETCH_ASSTM_HNDV_PRTS,
    payload: fetchAsstmHndvPrts(searchParams),
  };
};

/**
 * 메시지초기화
 */
export const clearMessage = () => {
  return {
    type: CLEAR_MESSAGE,
  };
};

/**
 *
 */
export const setBeforeSearchParam = (searchParams) => {
  return {
    type: SET_BEFORE_SEARCH_PARAM,
    payload: searchParams,
  };
};

//기관명 콤보 조회
export const selectIstInfoList = (searchParam) => {
  return {
    type: FETCH_IST_INFO_LIST,
    payload: fetchCommonComboList({ slt_box_no: '005', ...searchParam }),
  };
};

//회계연도 콤보 조회
export const selectAccYrList = () => {
  return {
    type: FETCH_ACC_YR_LIST,
    payload: fetchCommonComboList({ slt_box_no: '025' }),
  };
};

/**
 * 조회조건 저장
 */
export const saveSearchFormValues = (searchFormValues) => {
  return {
    type: SAVE_SEARCH_FORM_VALUES,
    payload: {
      ...searchFormValues,
      init: false,
    },
  };
};

/**
 * 상태초기화
 */
export const resetInitialState = () => {
  return {
    type: RESET_INITIAL_STATE,
  };
};
