import { SUCCESS, REQUEST, FAILURE } from '../../../common/utils/action-type-utils';
import { fetchMenuBySite, saveMenuBySite } from '../../../lib/sys/SYS0307M01.api';
import { fetchMenuAutByUserTpList, fetchUserTpList, mergeMenuAutByUserTp } from '../../../lib/sys/SYS0309M01.api';

// actions
const FETCH_MENU_AUT_BY_USER_TP_LIST = 'SYS0309M01/FETCH_MENU_AUT_BY_USER_TP_LIST';
const FETCH_USER_TP_LIST = 'SYS0309M01/FETCH_USER_TP_LIST';
const SAVE_MENU_AUT_BY_USER_TP = 'SYS0309M01/SAVE_MENU_AUT_BY_USER_TP';

// initial state
const initialState = {
  loading: false,
  success: false,
  message: '',
  list: [],
  totalCount: 0,
  userTpList: [],
  menuList: [],
  totalCountByUserTpList: 0,
  loadingByUserTpList: false,
  successByUserTpList: false,
  updating: false,
  updateSuccess: false,
  resultCnt: -1,
};

// reducers
export default function SYS0309M01(state = initialState, action) {
  switch (action.type) {
    case SUCCESS(FETCH_MENU_AUT_BY_USER_TP_LIST): // 조회 성공
      return {
        ...state,
        success: true,
        loading: false,
        menuList: action.payload.data?.main,
        totalCount: action.payload?.data?.main_cnt,
      };
    case REQUEST(FETCH_MENU_AUT_BY_USER_TP_LIST): // 조회 요청
      return {
        ...state,
        message: '',
        loading: true,
        success: false,
        updateSuccess: false,
      };
    case FAILURE(FETCH_MENU_AUT_BY_USER_TP_LIST): // 조회실패
      return {
        ...initialState,
        loading: false,
        success: false,
        message: action.payload.response?.data?.message,
      };
    case SUCCESS(FETCH_USER_TP_LIST): // 조회 성공
      return {
        ...state,
        successByUserTpList: true,
        loadingByUserTpList: false,
        userTpList: action.payload.data?.main,
        totalCountByUserTpList: action.payload?.data?.mainCnt,
      };
    case REQUEST(FETCH_USER_TP_LIST): // 조회 요청
      return {
        ...state,
        message: '',
        loadingByUserTpList: true,
        successByUserTpList: false,
      };
    case FAILURE(FETCH_USER_TP_LIST): // 조회실패
      return {
        ...initialState,
        loadingByUserTpList: false,
        successByUserTpList: false,
        message: action.payload.response?.data?.message,
      };
    case SUCCESS(SAVE_MENU_AUT_BY_USER_TP): // 저장 성공
      return {
        ...state,
        updateSuccess: true,
        updating: false,
        resultCnt: action.payload.data.resultCnt,
      };
    case REQUEST(SAVE_MENU_AUT_BY_USER_TP): // 저장 요청
      return {
        ...state,
        message: '',
        updating: true,
        updateSuccess: false,
      };
    case FAILURE(SAVE_MENU_AUT_BY_USER_TP): // 저장 실패
      return {
        ...initialState,
        updating: false,
        updateSuccess: false,
        resultCnt: -1,
        message: action.payload.response?.data?.message,
      };
    default:
      return state;
  }
}

/**
 * 사용자유형별 메뉴권한 조회
 */
export const selectMenuAutByUserTpList = (searchParams) => {
  return {
    type: FETCH_MENU_AUT_BY_USER_TP_LIST,
    payload: fetchMenuAutByUserTpList(searchParams),
  };
};

/**
 * 사용자유형 조회
 */
export const selectUserTpList = () => {
  return {
    type: FETCH_USER_TP_LIST,
    payload: fetchUserTpList(),
  };
};

/**
 * 사용자유형별 메뉴권한 갱신
 */
export const saveMenuAutByUserTp = (dtos) => {
  return {
    type: SAVE_MENU_AUT_BY_USER_TP,
    payload: mergeMenuAutByUserTp(dtos),
  };
};
