import instance from '../../common/config/api';

/**
 * e뱅킹 - 공금계좌잔액 조회
 * @param {object} params
 * @returns
 */
export const fetchPmnyAcnoBalAm = (params) =>
  instance.post(`${process.env.REACT_APP_SERVER_URL}/scq/scq/ebk/0002/selectPmnyAcnoBalAm`, params);

export const fetchbankCls = (params) =>
  instance.post(`${process.env.REACT_APP_SERVER_URL}/scq/scq/ebk/0002/selectBankCls`, params);
