import instance from '../../common/config/api';

/**
 * 제로페이 - 일자/결제방식별 거래량 조회
 * @param {object} params
 * @returns
 */

export const fetchZpbTrnFmItStlPtsList = (params) =>
  instance.post(`${process.env.REACT_APP_SERVER_URL}/scq/scq/zpb/0001/selectZeroByTrnFmItStlPts`, params);
