import {
  fetchAbvTrnPcptGatTotCnt,
  fetchAbvTrnPcptGatTop3,
  fetchAbvTrnPcptGatLevCnt,
  fetchAbvTrnPcptGatDtls,
  fetchAbvTrnPcptGatEvt,
  fetchAbvTrnPcptGatRltmEvt,
  fetchAbvTrnPcptGatItemDtls,
} from '../../../lib/fds/FDS0001.api';
import { fetchUserEst } from '../../../lib/cmm/CMMUser.api';
import { SUCCESS, REQUEST, FAILURE } from '../../../common/utils/action-type-utils';

// actions
const FETCH_ABV_TRN_PCPT_GAT_TOT_CNT = 'MainFDS/FETCH_ABV_TRN_PCPT_GAT_TOT_CNT';
const FETCH_ABV_TRN_PCPT_GAT_TOP3 = 'MainFDS/FETCH_ABV_TRN_PCPT_GAT_TOP3';
const FETCH_ABV_TRN_PCPT_GAT_LEV_CNT = 'MainFDS/FETCH_ABV_TRN_PCPT_GAT_LEV_CNT';
// const FETCH_ABV_TRN_PCPT_GAT_BYMN_PSG = 'MainFDS/FETCH_ABV_TRN_PCPT_GAT_BYMN_PSG';
const FETCH_ABV_TRN_PCPT_GAT_DTLS = 'MainFDS/FETCH_ABV_TRN_PCPT_GAT_DTLS';
const FETCH_ABV_TRN_PCPT_GAT_EVT = 'MainFDS/FETCH_ABV_TRN_PCPT_GAT_EVT';
const FETCH_ABV_TRN_PCPT_GAT_RLTM_EVT = 'MainFDS/FETCH_ABV_TRN_PCPT_GAT_RLTM_EVT';
const FETCH_ABV_TRN_PCPT_GAT_ITEM_DTLS = 'MainFDS/FETCH_ABV_TRN_PCPT_GAT_ITEM_DTLS';

const CHANGE_CUS_IST_CD = 'MainFDS/CHANGE_CUS_IST_CD';
const FETCH_USER_EST = 'MainFDS/FETCH_USER_EST';

// initial state
const initialState = {
  loading: false,
  success: false,
  message: '',
  loading2: false,
  success2: false,
  message2: '',
  loading3: false,
  success3: false,
  message3: '',
  loading4: false,
  success4: false,
  message4: '',
  loading5: false,
  success5: false,
  message5: '',
  loading6: false,
  success6: false,
  message6: '',
  loading7: false,
  success7: false,
  message7: '',
  list: [],
  totalCount: 0,
  totCnt: 0,
  top3: [],
  chartData: [],
  charDataSort: [],
  levelCnt: [],
  detailList: [],
  liveEventList: [],
  todaysEventList: [],
  events: [],
  userEst: '',
  cus_ist_cd: '',

  dataList: [],
  dataListSuccess: false,
  dataListLoading: false,
  dataListMessage: '',
};

// reducers
export default function MainFDS(state = initialState, action) {
  switch (action.type) {
    case REQUEST(FETCH_ABV_TRN_PCPT_GAT_TOT_CNT): // 조회 요청
      return {
        ...state,
        message: '',
        loading: true,
      };
    case SUCCESS(FETCH_ABV_TRN_PCPT_GAT_TOT_CNT): // 조회 성공
      return {
        ...state,
        loading: false,
        success: true,
        totCnt: action.payload.data?.cnt,
      };
    case FAILURE(FETCH_ABV_TRN_PCPT_GAT_TOT_CNT): // 조회 실패
      return {
        ...state,
        loading2: false,
        success2: false,
        message2: action.payload.response?.data?.message,
      };
    case REQUEST(FETCH_ABV_TRN_PCPT_GAT_TOP3): // 조회 요청
      return {
        ...state,
        message2: '',
        loading2: true,
      };
    case SUCCESS(FETCH_ABV_TRN_PCPT_GAT_TOP3): // 조회 성공
      return {
        ...state,
        loading2: false,
        success2: true,
        top3: action.payload.data?.output_data,
        chartData: action.payload.data?.sub_data,
        charDataSort: (() => {
          const top3 = action.payload.data?.output_data;
          const chartData = action.payload.data?.sub_data;
          let arr = [];
          top3.forEach((item, index) => {
            const topCnt = `top${index + 1}_cnt`;
            const json = {
              type: 'column',
              name: item?.fds_grp_nm,
              data: (() => {
                let counts = [];
                chartData.forEach((item, index) => {
                  const count = item[topCnt] ? Number(item[topCnt]) : 0;
                  counts.push(count);
                });
                return counts;
              })(),
            };
            arr.push(json);
          });
          return arr;
        })(),
      };
    case FAILURE(FETCH_ABV_TRN_PCPT_GAT_TOP3): // 조회 실패
      return {
        ...state,
        loading3: false,
        success3: false,
        message3: action.payload.response?.data?.message,
      };
    case REQUEST(FETCH_ABV_TRN_PCPT_GAT_LEV_CNT): // 조회 요청
      return {
        ...state,
        message3: '',
        loading3: true,
      };
    case SUCCESS(FETCH_ABV_TRN_PCPT_GAT_LEV_CNT): // 조회 성공
      return {
        ...state,
        loading3: false,
        success3: true,
        levelCnt: action.payload.data?.output_data,
      };
    case FAILURE(FETCH_ABV_TRN_PCPT_GAT_LEV_CNT): // 조회 실패
      return {
        ...state,
        countLoading: false,
        countSuccess: false,
        message: action.payload.response?.data?.message,
      };
    case REQUEST(FETCH_ABV_TRN_PCPT_GAT_DTLS): // 조회 요청
      return {
        ...state,
        message5: '',
        loading5: true,
      };
    case SUCCESS(FETCH_ABV_TRN_PCPT_GAT_DTLS): // 조회 성공
      return {
        ...state,
        loading5: false,
        success5: true,
        // dataList: (() =>
        //   action.payload.data?.output_data_01?.reduce((acc, curr) => {
        //     const { fds_policy_cd } = curr;
        //     if (curr) {
        //       if (acc[fds_policy_cd]) {
        //         acc[fds_policy_cd].push(curr);
        //       } else {
        //         acc[fds_policy_cd] = [curr];
        //       }
        //     }
        //     return acc;
        //   }, {}))(),
        details: (() =>
          action.payload.data?.output_data?.reduce((acc, curr) => {
            const { grp_cd } = curr;
            if (curr) {
              if (acc[grp_cd]) {
                acc[grp_cd].push(curr);
              } else {
                acc[grp_cd] = [curr];
              }
            }
            return acc;
          }, {}))(),
      };
    case FAILURE(FETCH_ABV_TRN_PCPT_GAT_DTLS): // 조회 실패
      return {
        ...state,
        loading5: false,
        success5: false,
        message5: action.payload.response?.data?.message,
      };
    case REQUEST(FETCH_ABV_TRN_PCPT_GAT_EVT): // 조회 요청
      return {
        ...state,
        message6: '',
        loading6: true,
      };
    case SUCCESS(FETCH_ABV_TRN_PCPT_GAT_EVT): // 조회 성공
      return {
        ...state,
        loading6: false,
        success6: true,
        todaysEventList: action.payload.data?.output_data,
      };
    case FAILURE(FETCH_ABV_TRN_PCPT_GAT_EVT): // 조회 실패
      return {
        ...state,
        loading6: false,
        success6: false,
        message6: action.payload.response?.data?.message,
      };
    case REQUEST(FETCH_ABV_TRN_PCPT_GAT_RLTM_EVT): // 조회 요청
      return {
        ...state,
        message4: '',
        loading4: true,
      };
    case SUCCESS(FETCH_ABV_TRN_PCPT_GAT_RLTM_EVT): // 조회 성공
      return {
        ...state,
        loading4: false,
        success4: true,
        liveEventList: action.payload.data?.output_data,
      };
    case FAILURE(FETCH_ABV_TRN_PCPT_GAT_RLTM_EVT): // 조회 실패
      return {
        ...state,
        loading4: false,
        success4: false,
        message4: action.payload.response?.data?.message,
      };
    case REQUEST(FETCH_USER_EST): // 조회 요청
      return {
        ...state,
        message7: '',
        loading7: true,
      };
    case SUCCESS(FETCH_USER_EST): // 조회 성공
      return {
        ...state,
        loading7: false,
        success7: true,
        userEst: action.payload.data,
      };
    case FAILURE(FETCH_USER_EST): // 조회 실패
      return {
        ...state,
        loading7: false,
        success7: false,
        message7: action.payload.response?.data?.message,
      };
    case REQUEST(FETCH_ABV_TRN_PCPT_GAT_ITEM_DTLS): // 조회 요청
      return {
        ...state,
        dataListMessage: '',
        dataListLoading: true,
      };
    case SUCCESS(FETCH_ABV_TRN_PCPT_GAT_ITEM_DTLS): // 조회 성공
      return {
        ...state,
        dataListLoading: false,
        dataListSuccess: true,
        dataList: action.payload.data?.output_data,
      };
    case FAILURE(FETCH_ABV_TRN_PCPT_GAT_ITEM_DTLS): // 조회 실패
      return {
        ...state,
        dataListLoading: false,
        dataListSuccess: false,
        dataListMessage: action.payload.response?.data?.message,
      };
    case CHANGE_CUS_IST_CD: // 기관명변경
      return {
        ...state,
        cus_ist_cd: action.payload,
      };
    default:
      return state;
  }
}

/**
 * 이상감지 건수
 */
export const selectAbvTrnPcptGatTotCnt = (searchParams) => {
  return {
    type: FETCH_ABV_TRN_PCPT_GAT_TOT_CNT,
    payload: fetchAbvTrnPcptGatTotCnt(searchParams),
  };
};

/**
 * TOP3
 */
export const selectAbvTrnPcptGatTop3 = (searchParams) => {
  return {
    type: FETCH_ABV_TRN_PCPT_GAT_TOP3,
    payload: fetchAbvTrnPcptGatTop3(searchParams),
  };
};

/**
 * 상중하 건수 조회
 */
export const selectAbvTrnPcptGatLevCnt = (searchParams) => {
  return {
    type: FETCH_ABV_TRN_PCPT_GAT_LEV_CNT,
    payload: fetchAbvTrnPcptGatLevCnt(searchParams),
  };
};

// /**
//  * 일별 추이_30일기준 (차트)
//  */
// export const selectAbvTrnPcptGatBymnPsg = (searchParams) => {
//   return {
//     type: FETCH_ABV_TRN_PCPT_GAT_BYMN_PSG,
//     payload: fetchAbvTrnPcptGatBymnPsg(searchParams),
//   };
// };

/**
 * 세부항목발생건수 및 상세내역
 */
export const selectAbvTrnPcptGatDtls = (searchParams) => {
  return {
    type: FETCH_ABV_TRN_PCPT_GAT_DTLS,
    payload: fetchAbvTrnPcptGatDtls(searchParams),
  };
};

/**
 * 금일기준거래 이벤트
 */
export const selectAbvTrnPcptGatEvt = (searchParams) => {
  return {
    type: FETCH_ABV_TRN_PCPT_GAT_EVT,
    payload: fetchAbvTrnPcptGatEvt(searchParams),
  };
};

/**
 * 실시간 이벤트
 */
export const selectAbvTrnPcptGatRltmEvt = (searchParams) => {
  return {
    type: FETCH_ABV_TRN_PCPT_GAT_RLTM_EVT,
    payload: fetchAbvTrnPcptGatRltmEvt(searchParams),
  };
};

/**
 * 기관명 변경
 */
export const changeCusIstCd = (cusIstCd) => {
  return {
    type: CHANGE_CUS_IST_CD,
    payload: cusIstCd,
  };
};

/**
 * 사용자 설정 조회
 */
export const selectUserEst = (searchParams) => {
  return {
    type: FETCH_USER_EST,
    payload: fetchUserEst(searchParams),
  };
};

/**
 * 세부발생항목 상세내역 조회
 */
export const selectAbvTrnPcptGatItemDtls = (searchParams) => {
  return {
    type: FETCH_ABV_TRN_PCPT_GAT_ITEM_DTLS,
    payload: fetchAbvTrnPcptGatItemDtls(searchParams),
  };
};
