import { SUCCESS, REQUEST, FAILURE } from '../../../common/utils/action-type-utils';
import { fetchSystemMngList, saveSystemMngList } from '../../../lib/sys/sysMng.api';

// actions
const FETCH_SYSTEM_MNG_LIST = 'systemMng/FETCH_SYSTEM_MNG_LIST';
const SAVE_SYSTEM_MNG_LIST = 'systemMng/SAVE_SYSTEM_MNG_LIST';

// initial state
const initialState = {
  loading: false,
  success: true,
  message: '',
  list: [],
  details: [],
  totalCount: 0,
  updateSuccess: false,
  resultCnt: -1,
};

// reducers
export default function systemMng(state = initialState, action) {
  switch (action.type) {
    case SUCCESS(FETCH_SYSTEM_MNG_LIST): // 조회 성공
      return {
        ...state,
        success: true,
        loading: false,
        list: action.payload.data?.main,
        totalCount: action.payload?.main_cnt,
        updateSuccess: false,
      };
    case REQUEST(FETCH_SYSTEM_MNG_LIST): // 조회 요청
      return {
        ...state,
        message: '',
        loading: true,
        success: false,
        updateSuccess: false,
      };
    case FAILURE(FETCH_SYSTEM_MNG_LIST): // 조회실패
      return {
        ...initialState,
        loading: false,
        success: false,
        message: action.payload.response?.data?.message,
        updateSuccess: false,
      };
    case SUCCESS(SAVE_SYSTEM_MNG_LIST): // 저장 성공
      return {
        ...state,
        updateSuccess: true,
        loading: false,
        resultCnt: action.payload.data.resultCnt,
      };
    case REQUEST(SAVE_SYSTEM_MNG_LIST): // 저장 요청
      return {
        ...state,
        message: '',
        loading: true,
        updateSuccess: false,
      };
    case FAILURE(SAVE_SYSTEM_MNG_LIST): // 저장 실패
      return {
        ...initialState,
        loading: false,
        updateSuccess: false,
        resultCnt: -1,
        message: action.payload.response?.data?.message,
      };
    default:
      return { ...state, updateSuccess: false };
  }
}

/**
 * 시스템 목록 조회
 */
export const selectListSystemMng = (searchParams) => {
  return {
    type: FETCH_SYSTEM_MNG_LIST,
    payload: fetchSystemMngList(searchParams),
  };
};

/**
 * 시스템 목록 갱신
 */
export const saveListSystemMng = (dtos) => {
  return {
    type: SAVE_SYSTEM_MNG_LIST,
    payload: saveSystemMngList(dtos),
  };
};
