import { SUCCESS, REQUEST, FAILURE } from '../../../common/utils/action-type-utils';
import { fetchSalaryTrnsEachIstAllList } from '../../../lib/brc/brc0207v01.api';
import dayjs from 'dayjs';
import { brc0207brCdComboList } from '../../../data/const';
// actions
const FETCH_SALARYTRNSEACHIST = 'brcSalaryTrnsEachIst/FETCH_SALARYTRNSEACHIST';
const RESET_STATE = 'brcSalaryTrnsEachIst/RESET_STATE';
const SAVE_SEARCH_FORM_VALUES = 'brcSalaryTrnsEachIst/SAVE_SEARCH_FORM_VALUES';

export const initFormValues = {
  ydcs8a1_t8a01_inq_sdt: dayjs().format('YYYY-MM-DD'), // 기준일자
  ydcs8a1_t8a01_inq_edt: dayjs().format('YYYY-MM-DD'), // 기준일자
};

// initial state
const initialState = {
  loading: false,
  success: false,
  message: '',
  list: [],
  totalCount: 0,
  brc0207brCdComboList: brc0207brCdComboList,
  searchFormValues: {
    init: true,
    ...initFormValues,
  },
};

// reducers
export default function brcSalaryTrnsEachIst(state = initialState, action) {
  switch (action.type) {
    case SUCCESS(FETCH_SALARYTRNSEACHIST): // 조회 성공
      return {
        ...state,
        success: true,
        loading: false,
        list: action.payload.data?.main,
        totalCount: action.payload.data?.totMainCnt,
      };
    case REQUEST(FETCH_SALARYTRNSEACHIST): // 조회 요청
      return {
        ...state,
        loading: true,
        success: false,
        list: [],
      };
    case FAILURE(FETCH_SALARYTRNSEACHIST): // 조회실패
      return {
        ...initialState,
        loading: false,
        success: false,
        message: action.payload.response?.data?.message,
      };
    case SAVE_SEARCH_FORM_VALUES:
      return {
        ...state,
        searchFormValues: action.payload,
      };
    case RESET_STATE:
      return initialState;
    default:
      return state;
  }
}

/**
 * 금고영업점 - 지자체 정보관리 - 급여이체 점별 전체내역
 */
export const selectBrcSalaryTrnsEachIstList = (searchParams) => {
  return {
    type: FETCH_SALARYTRNSEACHIST,
    payload: fetchSalaryTrnsEachIstAllList(searchParams),
  };
};

/**
 * 조회조건 저장
 */
export const saveSearchFormValues = (searchFormValues) => {
  return {
    type: SAVE_SEARCH_FORM_VALUES,
    payload: {
      ...searchFormValues,
      init: false,
    },
  };
};
/**
 * 초기화
 */
export const resetBrcSalaryTrnsEachIst = () => {
  return {
    type: RESET_STATE,
  };
};
