import React, { useEffect, useState } from 'react';
import { styled, css } from 'styled-components';
import NoticeBar from './NoticeBar';
import WOButton from '../common/WOButton';
import WOIcon from '../common/WOIcon';
import { useNavigate } from 'react-router-dom';
import dayjs from 'dayjs';

const Notice = ({ text, noticeList }) => {
  // logic
  const [isOpen, setIsOpen] = useState(false);
  const [selectedId, setSelectedId] = useState();
  const navigate = useNavigate();
  const handleOnclick = () => {
    navigate('/page/NtMng');
  };

  const handleDrap = (rnum) => {
    
    if (selectedId === rnum) {
      setSelectedId(null);
    } else {
      setSelectedId(rnum);
    }
  };

  // view
  return (
    <>
      {/* START: 공지사항 */}
      <NoticeBar text={text} onClick={() => setIsOpen(!isOpen)} />
      {isOpen && (
        <NoticeBox>
          <div className="notice">
            <TopArea>
              <p className="notice_title">공지사항</p>
              <WOButton
                variant={'outlined'}
                onClick={handleOnclick}
                endIcon={<WOIcon icon={'arrow'} width={9} height={10} />}>
                모두보기
              </WOButton>
            </TopArea>

            <ListBox >
              {noticeList.map((noticeItem) => (
                <ListItem key={noticeItem.rnum}>
                  <button type="button" className="button-wrap" onClick={() => handleDrap(noticeItem.rnum)}>
                    <div className="content">
                      <span className="title">{noticeItem.anc_titl}</span>
                      <span className="date">{dayjs(noticeItem.cre_dh, 'YYYYMMDD HH:mm:ss').format('YYYY-MM-DD')}</span>
                    </div>
                    <RotateIcon
                      rotate={selectedId === noticeItem.rnum}
                      width="16"
                      height="16"
                      icon="notice-arrow-button"
                    />
                  </button>
                  {selectedId === noticeItem.rnum && (
                    <div className="disciption">
                      <span>{noticeItem.anc_txt}</span>
                    </div>
                  )}
                </ListItem>
              ))}
            </ListBox>
          </div>
        </NoticeBox>
      )}
      {/* END: 공지사항 */}
    </>
  );
};

export default Notice;

const NoticeBox = styled.div`
  position: absolute;
  top: 58px;
  right: 86px;
  width: 717px;
  z-index: 1101;
  border-radius: 16px;
  box-shadow: 0px 16px 42px 0px rgba(0, 0, 0, 0.1);
  backdrop-filter: blur(6px);
  border: 1px solid transparent;
  background-image: linear-gradient(#fff, #fff), linear-gradient(0deg, #b0ebcc 0%, #99a3ff 100%);
  background-origin: border-box;
  background-clip: content-box, border-box;
  .notice {
    display: flex;
    flex-direction: column;
    gap: 4px 0;
    padding: 24px 0;
    &_title {
      color: #333;
      font-size: 20px;
      font-weight: 700;
    }
  }
`;

const TopArea = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 0 24px;
`;

const ListBox = styled.ul`
  overflow-y: auto;
  height: 315px;
`;

const ListItem = styled.li`
  width: 100%;
  padding: 16px 24px;
  border-bottom: 1px solid #ededf3;
  &:last-child {
    border: none;
  }
  .button-wrap {
    width: 100%;
    display: flex;
    align-items: flex-start;
    justify-content: space-between;

    .content {
      display: flex;
      flex-direction: column;
      align-items: flex-start;
      gap: 4px 0;
      .title {
        color: #333;
        font-size: 16px;
        font-weight: 500;
      }

      .date {
        color: #999;
        font-size: 12px;
        font-weight: 400;
      }
    }

    
  }

  .disciption {
    margin-top: 12px;
    color : #555;
    font-size : 12px;
    font-weight : 400;
    line-height : 18px; 
  }
  ${props => props.$open &&css`
  margin-right:-8px;
  `}
`;

const RotateIcon = styled(WOIcon)`
  transition: transform 0.3s ease-in-out;
  ${({ rotate }) =>
    rotate &&
    css`
      transform: rotate(180deg);
    `}
`;
