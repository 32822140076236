import instance from '../../common/config/api';

/**
 * 시스템관리 - 부서별 보통예금계좌 관리
 * @param {object} params
 * @returns
 */

export const fetchGenDmdActNoList = (params) =>
  instance.post(`${process.env.REACT_APP_SERVER_URL}/scq/scq/sys/0001/selectGenDmdActNo`, params);

export const insertGenDmdActNo = (dtos) =>
  instance.post(`${process.env.REACT_APP_SERVER_URL}/scq/scq/sys/0001/insertGenDmdActNo`, {
    subDto: dtos,
  });

export const selectDeptGndpActMngCombo = (params) =>
  instance.post(`${process.env.REACT_APP_SERVER_URL}/scq/scq/sys/0001/selectDeptGndpActMngCombo`, params);
