import {
  fetchGndpActPayReq,
  fetchDeptUser,
  updateGndpActPayReq,
  updateBkAppvReq,
  updateWdwReq,
  updateBatAppv,
} from '../../../lib/axp/ILS0003.api';

import { SUCCESS, REQUEST, FAILURE } from '../../../common/utils/action-type-utils';

// actions
const FETCH_GNDP_ACT_PAY_REQ = 'ILS0301V01/FETCH_GNDP_ACT_PAY_REQ';
const UPDATE_GNDP_ACT_PAY_REQ = 'ILS0301V01/UPDATE_GNDP_ACT_PAY_REQ';
const UPDATE_BK_APPV_REQ = 'ILS0301V01/UPDATE_BK_APPV_REQ';
const FETCH_DEPT_USER = 'ILS0301V01/FETCH_DEPT_USER';
const UPDATE_WDW_REQ = 'ILS0301V01/UPDATE_WDW_REQ';
const UPDATE_BAT_APPV = 'ILS0301V01/UPDATE_BAT_APPV';

// initial state
const initialState = {
  loading: false,
  success: false,
  message: '',
  list: [],
  details: [],
  detailsHead: {},
  detailsSuccess: false,
  detailsLoading: false,
  detailsMessage: false,
  totalCount: 0,
  updateSuccess: false,
  updateLoading: false,
  resultCnt: -1,
  deptUserList: [],
  deptUserLoading: false,
  deptUserSuccess: false,
  deptUserMessage: false,
};

// reducers
export default function ILS0301V01(state = initialState, action) {
  switch (action.type) {
    case SUCCESS(FETCH_GNDP_ACT_PAY_REQ): // 조회 성공
      return {
        ...state,
        success: true,
        loading: false,
        list: action.payload.data?.main,
      };
    case REQUEST(FETCH_GNDP_ACT_PAY_REQ): // 조회 요청
      return {
        ...state,
        message: '',
        loading: true,
      };
    case FAILURE(FETCH_GNDP_ACT_PAY_REQ): // 조회실패
      return {
        ...state,
        list: [],
        loading: false,
        success: false,
        message: action.payload.response?.data?.message,
      };
    case SUCCESS(FETCH_DEPT_USER): // 조회 성공
      return {
        ...state,
        deptUserSuccess: true,
        deptUserLoading: false,
        deptUserList: action.payload.data?.main,
      };
    case REQUEST(FETCH_DEPT_USER): // 조회 요청
      return {
        ...state,
        deptUserMessage: '',
        deptUserLoading: true,
      };
    case FAILURE(FETCH_DEPT_USER): // 조회실패
      return {
        ...state,
        deptUserList: [],
        deptUserLoading: false,
        deptUserSuccess: false,
        deptUserMessage: action.payload.response?.data?.message,
      };
    case SUCCESS(UPDATE_GNDP_ACT_PAY_REQ): // 갱신 성공
    case SUCCESS(UPDATE_BAT_APPV): // 갱신 성공
    case SUCCESS(UPDATE_WDW_REQ): // 갱신 성공
    case SUCCESS(UPDATE_BK_APPV_REQ): // 갱신 성공
      return {
        ...state,
        updateSuccess: true,
        updateLoading: false,
        resultCnt: action.payload.data.resultCnt,
      };
    case REQUEST(UPDATE_GNDP_ACT_PAY_REQ): // 갱신 요청
    case REQUEST(UPDATE_BAT_APPV): // 갱신 요청
    case REQUEST(UPDATE_WDW_REQ): // 갱신 요청
    case REQUEST(UPDATE_BK_APPV_REQ): // 갱신 요청
      return {
        ...state,
        updateLoading: true,
        updateSuccess: false,
      };
    case FAILURE(UPDATE_GNDP_ACT_PAY_REQ): // 갱신 실패
    case FAILURE(UPDATE_BAT_APPV): // 갱신 실패
    case FAILURE(UPDATE_WDW_REQ): // 갱신 실패
    case FAILURE(UPDATE_BK_APPV_REQ): // 갱신 실패
      return {
        ...initialState,
        updateLoading: false,
        updateSuccess: false,
        resultCnt: -1,
        message: action.payload.response?.data?.message,
      };
    default:
      return state;
  }
}

/**
 * 보통예금계좌 지급 요청공문 조회
 */
export const selectGndpActPayReq = (searchParams) => {
  return {
    type: FETCH_GNDP_ACT_PAY_REQ,
    payload: fetchGndpActPayReq(searchParams),
  };
};

/**
 * 요청공문데이터 저장
 */
export const saveGndpActPayReq = (searchParams) => {
  return {
    type: UPDATE_GNDP_ACT_PAY_REQ,
    payload: updateGndpActPayReq(searchParams),
  };
};

/**
 * 은행결재요청 저장
 */
export const saveBkAppvReq = (searchParams) => {
  return {
    type: UPDATE_BK_APPV_REQ,
    payload: updateBkAppvReq(searchParams),
  };
};

/**
 * 결재자목록 조회
 */
export const selectDeptUser = (searchParams) => {
  return {
    type: FETCH_DEPT_USER,
    payload: fetchDeptUser(searchParams),
  };
};

/**
 * 회수요청
 */
export const saveWdwReq = (searchParams) => {
  return {
    type: UPDATE_WDW_REQ,
    payload: updateWdwReq(searchParams),
  };
};

/**
 * 일괄결재 저장
 */
export const saveBatAppv = (searchParams) => {
  return {
    type: UPDATE_BAT_APPV,
    payload: updateBatAppv(searchParams),
  };
};
