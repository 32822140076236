import instance from '../../common/config/api';

/**
 * e뱅킹 - 지출명령 반려조회
 * @param {object} params
 * @returns
 */
export const fetchExpdCmdTrdn = (params) =>
  instance.post(`${process.env.REACT_APP_SERVER_URL}/scq/scq/ebk/0001/selectExpdCmdTrdn`, params);

/**
 * e뱅킹 - 지출명령 반려조회
 * @param {object} params
 * @returns
 */
export const fetchExpdCmdTrdnDtl = (params) =>
  instance.post(`${process.env.REACT_APP_SERVER_URL}/scq/scq/ebk/0001/selectExpdCmdTrdnDtl`, params);
