import instance from '../../common/config/api';

/**
 * 공통코드 목록 조회
 * @param {object} params
 * @returns
 */
export const fetchCodeGroupList = (params) =>
  instance.post(`${process.env.REACT_APP_SERVER_URL}/scq/scq/sys/0003/selectCodeGroupList`, params);

/**
 * 공통코드 그룹 저장
 * @param {array} dtos
 * @returns
 */
export const saveCodeGroupMainList = (dtos) =>
  instance.post(`${process.env.REACT_APP_SERVER_URL}/scq/scq/sys/0003/saveCodeGroupList`, {
    main: dtos,
    mainCnt: dtos.length,
  });

/**
 * 공통코드 상세 목록 조회
 * @param {object} params
 * @returns
 */
export const fetchCodeGroupDetailList = (params) =>
  instance.post(`${process.env.REACT_APP_SERVER_URL}/scq/scq/sys/0003/selectCodeGroupDtlList`, params);

/**
 * 공통코드 상세 저장
 * @param {array} dtos
 * @returns
 */
export const saveCodeGroupSubList = (dtos) =>
  instance.post(`${process.env.REACT_APP_SERVER_URL}/scq/scq/sys/0003/saveCodeGroupList`, {
    sub: dtos,
    subCnt: dtos.length,
  });
