import { fetchListUserAutMngList, updateListUserAutMngList } from '../../../lib/cad/listUserAutMng.api';
import { SUCCESS, REQUEST, FAILURE } from '../../../common/utils/action-type-utils';
import { fetchCommonComboList } from '../../../lib/cmm/combo.api';

// actions
const FETCH_GVCM_PRTS_INQ_LIST = 'listUserAutMng/FETCH_GVCM_PRTS_LIST';
const UPDATE_GVCM_PRTS_INQ_LIST = 'listUserAutMng/UPDATE_GVCM_PRTS_LIST';

const FETCH_IST_INFO_LIST = 'listUserAutMng/FETCH_IST_INFO_LIST';
const FETCH_SPECIAL_AUTH_LIST = 'listUserAutMng/FETCH_SPECIAL_AUTH_LIST';

const SAVE_SEARCH_FORM_VALUES = 'listUserAutMng/SAVE_SEARCH_FORM_VALUES';
const RESET_STATE = 'listUserAutMng/RESET_STATE';

export const initFormValues = {
  user_nm: '',
};

// initial state
const initialState = {
  loading: false,
  updating: false,
  success: false,
  updateSuccess: false,
  message: '',
  list: [],
  totalCount: 0,
  // 콤보 - 기관명
  istInfoSuccess: false,
  istInfoLoading: false,
  istInfoList: [],
  // 콤보 - 특화권한유형
  specialAuthSuccess: false,
  specialAuthLoading: false,
  specialAuthList: [],
  // 검색조건
  searchFormValues: {
    init: true,
    ...initFormValues,
  },
};

// reducers
export default function listUserAutMng(state = initialState, action) {
  switch (action.type) {
    case SUCCESS(FETCH_GVCM_PRTS_INQ_LIST): // 조회 성공
      return {
        ...state,
        success: true,
        loading: false,
        list: action.payload.data?.main,
        totalCount: action.payload.data?.main_cnt,
      };
    case REQUEST(FETCH_GVCM_PRTS_INQ_LIST): // 조회 요청
      return {
        ...state,
        loading: true,
        success: false,
        message: '',
      };
    case FAILURE(FETCH_GVCM_PRTS_INQ_LIST): // 조회실패
      return {
        ...initialState,
        loading: false,
        success: false,
        message: action.payload.response?.data?.message,
      };
    case SUCCESS(UPDATE_GVCM_PRTS_INQ_LIST): // 수정 성공
      return {
        ...state,
        updateSuccess: true,
        loading: false,
      };
    case REQUEST(UPDATE_GVCM_PRTS_INQ_LIST): // 수정 요청
      return {
        ...state,
        updateSuccess: false,
        message: null,
        loading: true,
      };
    case FAILURE(UPDATE_GVCM_PRTS_INQ_LIST): // 수정 실패
      return {
        ...initialState,
        loading: false,
        updateSuccess: false,
        message: action.payload.response?.data?.message,
      };
    // 기관명
    case SUCCESS(FETCH_IST_INFO_LIST): // 조회 성공
      return {
        ...state,
        istInfoSuccess: true,
        istInfoLoading: false,
        istInfoList: action.payload.data?.subDto,
      };
    case REQUEST(FETCH_IST_INFO_LIST): // 조회 요청
      return {
        ...state,
        istInfoLoading: true,
        istInfoSuccess: false,
      };
    case FAILURE(FETCH_IST_INFO_LIST): // 조회실패
      return {
        istInfoLoading: false,
        istInfoSuccess: false,
        istInfoList: [],
      };
    // 특화권한유형
    case SUCCESS(FETCH_SPECIAL_AUTH_LIST): // 조회 성공
      return {
        ...state,
        specialAuthSuccess: true,
        specialAuthLoading: false,
        specialAuthList: action.payload.data?.subDto,
      };
    case REQUEST(FETCH_SPECIAL_AUTH_LIST): // 조회 요청
      return {
        ...state,
        specialAuthLoading: true,
        specialAuthSuccess: false,
      };
    case FAILURE(FETCH_SPECIAL_AUTH_LIST): // 조회실패
      return {
        ...state,
        specialAuthLoading: false,
        specialAuthSuccess: false,
        specialAuthList: [],
      };
    case SAVE_SEARCH_FORM_VALUES:
      return {
        ...state,
        // 콤보 - 기관
        istInfoSuccess: false,
        istInfoLoading: false,
        // 콤보 - 특화권한유형
        specialAuthSuccess: false,
        specialAuthLoading: false,
        searchFormValues: action.payload,
      };
    case RESET_STATE:
      return initialState;
    default:
      return state;
  }
}

/**
 * 법인카드 - 사용자 권한관리
 */
export const selectListUserAutMng = (searchParams) => {
  return {
    type: FETCH_GVCM_PRTS_INQ_LIST,
    payload: fetchListUserAutMngList(searchParams),
  };
};

/**
 * 법인카드 - 사용자 권한관리 수정
 */
export const updateListUserAutMng = (dtoList) => {
  return {
    type: UPDATE_GVCM_PRTS_INQ_LIST,
    payload: updateListUserAutMngList(dtoList),
  };
};

/**
 * 기관명 콤보 조회
 */
export const selectIstInfoComboList = (searchParams) => {
  return {
    type: FETCH_IST_INFO_LIST,
    payload: fetchCommonComboList({ slt_box_no: '049', ...searchParams }),
  };
};

/**
 * 특화권한유형
 */
export const selectSpecialAuthComboList = () => {
  return {
    type: FETCH_SPECIAL_AUTH_LIST,
    payload: fetchCommonComboList({ slt_box_no: '115', spc_grp_cd: 'SPC02' }),
  };
};

/**
 * 조회조건 저장
 */
export const saveSearchFormValues = (searchFormValues) => {
  return {
    type: SAVE_SEARCH_FORM_VALUES,
    payload: {
      ...searchFormValues,
      init: false,
    },
  };
};

/**
 * 법인카드 상태초기화
 */
export const resetListUserAutMng = () => {
  return {
    type: RESET_STATE,
  };
};
