import instance from '../../common/config/api';

/**
 * 기타 - 손해배상금 - 집계표 (당행지점별, 수납기관, 징수기관)
 * @param {object} params
 * @returns
 */

// 세입일자
export const fetchEtcExchageDateList = () =>
  instance.post(`${process.env.REACT_APP_SERVER_URL}/scq/scq/etc/0005/selectExchageDateByBranch`);

// 당행지점별 목록
// export const fetchEtcEachBranchList = (params) =>
//   instance.post(`${process.env.REACT_APP_SERVER_URL}/scq/scq/etc/0005/selectEachBranchList`, params);

export const fetchEtcEachBranchList = (params) =>
  instance.post(`${process.env.REACT_APP_SERVER_URL}/scq/scq/etc/0005/selectTbkBr`, params);

// 수납기관 목록
export const fetchEtcEachSunapList = (params) =>
  instance.post(`${process.env.REACT_APP_SERVER_URL}/scq/scq/etc/0005/selectEachSunapList`, params);

// 징수기관 목록 (상세)
export const fetchEtcEachJingsusDtlList = (params) =>
  instance.post(`${process.env.REACT_APP_SERVER_URL}/scq/scq/etc/0005/selectEachJingsusDtl`, params);

// 징수기관 목록 (집계)
export const fetchEtcEachJingsusSumrList = (params) =>
  instance.post(`${process.env.REACT_APP_SERVER_URL}/scq/scq/etc/0005/selectEachJingsusSumr`, params);

// 수납기관 - 영수증 출력
export const fetchEtcEachSunapSlipOupList = (params) =>
  instance.post(`${process.env.REACT_APP_SERVER_URL}/scq/scq/etc/0005/selectEachSunapSlipOup`, params);

// 당행지점별 - 영수증 출력
export const fetchEtcEachBranchSlipOupList = (params) =>
  instance.post(`${process.env.REACT_APP_SERVER_URL}/scq/scq/etc/0005/selectTbkBrSlipOup`, params);

//연간
export const fetchEtcEachYearList = (params) =>
  instance.post(`${process.env.REACT_APP_SERVER_URL}/scq/scq/etc/0005/selectEachBranchList`, params);
