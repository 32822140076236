import instance from '../../common/config/api';

/**
 * 사이트별 메뉴 조회
 * @param {object} params
 * @returns
 */
export const fetchMenuBySite = (params) =>
  instance.post(`${process.env.REACT_APP_SERVER_URL}/scq/scq/sys/0003/selectMenuBySite`, params);

/**
 * 사이트별 메뉴 등록
 * @param {array} dtos
 * @returns
 */
export const saveMenuBySite = (dtos) =>
  instance.post(`${process.env.REACT_APP_SERVER_URL}/scq/scq/sys/0003/saveMenuBySite`, {
    main: dtos,
    mainCnt: dtos.length,
  });
