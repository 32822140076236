import instance from '../../common/config/api';

/**
 * 제로페이 - 승인내역(기관)
 * @param {object} params
 * @returns
 */
export const fetchZpbApvPrtsListByIst = (params) =>
  instance.post(`${process.env.REACT_APP_SERVER_URL}/scq/scq/zpb/0001/selectZeroApvPrtsList`, params);

/**
 * 제로페이 - 승인내역(산하기관)
 * @param {object} params
 * @returns
 */
export const fetchZpbApvPrtsListByUninIst = (params) =>
  instance.post(`${process.env.REACT_APP_SERVER_URL}/scq/scq/zpb/0001/selectZeroApvSHPrtsList`, params);

/**
 * 제로페이 - 승인내역 - 사용자 요약 조회
 * @param {object} params
 * @returns
 */
export const fetchZpbUserSmrInfo = (params) =>
  instance.post(`${process.env.REACT_APP_SERVER_URL}/scq/scq/zpb/0001/selectZeroUserSmrInfo`, params);
