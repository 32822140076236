import dayjs from 'dayjs';
import { SUCCESS, REQUEST, FAILURE } from '../../../common/utils/action-type-utils';
import { fetchOtherDayCancelList, updateEaryRepayRequest } from '../../../lib/brc/notTdyCan.api';

// actions
const FETCH_NOT_TDY_CAN_LIST = 'notTdyCan/FETCH_NOT_TDY_CAN_LIST';
const UPDATE_NOT_TDY_CAN_LIST = 'notTdyCan/UPDATE_NOT_TDY_CAN_LIST';

const SAVE_SEARCH_FORM_VALUES = 'notTdyCan/SAVE_SEARCH_FORM_VALUES';
const RESET_STATE = 'notTdyCan/RESET_STATE';

export const initFormValues = {
  edt: dayjs().add(-1, 'day').format('YYYY-MM-DD'),
  sdt: dayjs().add(-1, 'day').format('YYYY-MM-DD'),
  gubun: 'all',
  car_no_txt: '',
  bnd_issu_no: '',
  bnd_buy_am: '',
};

// initial state
const initialState = {
  loading: false,
  updating: false,
  success: true,
  updateSuccess: false,
  message: '',
  list: [],
  totalCount: 0,
  // 검색조건
  searchFormValues: {
    init: true,
    ...initFormValues,
  },
};

// reducers
export default function notTdyCan(state = initialState, action) {
  switch (action.type) {
    case SUCCESS(FETCH_NOT_TDY_CAN_LIST): // 조회 성공
      return {
        ...state,
        success: true,
        loading: false,
        list: action.payload.data?.main,
        totalCount: action.payload?.mainCnt,
      };
    case REQUEST(FETCH_NOT_TDY_CAN_LIST): // 조회 요청
      return {
        ...state,
        success: true,
        loading: true,
      };
    case FAILURE(FETCH_NOT_TDY_CAN_LIST): // 조회실패
      return {
        ...initialState,
        loading: false,
        success: false,
        message: action.payload.response?.data?.message || action.payload.message,
      };
    case SUCCESS(UPDATE_NOT_TDY_CAN_LIST): // 성공
      return {
        ...state,
        updateSuccess: true,
        updating: false,
      };
    case REQUEST(UPDATE_NOT_TDY_CAN_LIST): // 요청
      return {
        ...state,
        updating: true,
        updateSuccess: false,
        message: null,
      };
    case FAILURE(UPDATE_NOT_TDY_CAN_LIST): // 실패
      return {
        ...initialState,
        updating: false,
        updateSuccess: false,
        message: action.payload.response?.data?.message,
      };
    case SAVE_SEARCH_FORM_VALUES:
      return {
        ...state,
        loading: false,
        updating: false,
        success: true,
        updateSuccess: false,
        message: '',
        searchFormValues: action.payload,
      };
    case RESET_STATE:
      return initialState;
    default:
      return state;
  }
}
/**
 * 금고영업점 - 공채 - 당일자 이외 취소내역 조회
 */
export const selectNotTdyCanList = (searchParams) => {
  return {
    type: FETCH_NOT_TDY_CAN_LIST,
    payload: fetchOtherDayCancelList(searchParams),
  };
};

/**
 * 금고영업점 - 공채 - 당일자 이외 취소신청 등록
 */
export const updateNotTdyCan = (dto) => {
  return {
    type: UPDATE_NOT_TDY_CAN_LIST,
    payload: updateEaryRepayRequest(dto),
  };
};

/**
 * 조회조건 저장
 */
export const saveSearchFormValues = (searchFormValues) => {
  return {
    type: SAVE_SEARCH_FORM_VALUES,
    payload: {
      ...searchFormValues,
      init: false,
    },
  };
};

/**
 * 화면 초기화
 */
export const resetState = () => {
  return {
    type: RESET_STATE,
  };
};
