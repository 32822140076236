import React, { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { resetState, sendLoginForAdmin } from '../redux/modules/cmm/login.reducer';
import useAlert from '../hooks/useAlert';
import { useNavigate, useSearchParams } from 'react-router-dom';
import WOLoading from '../components/common/WOLoading';

function SsoLogin() {
  const [searchParams] = useSearchParams();
  const { alert } = useAlert();
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const { loading, success, message, messageId, cmSession, menuList } = useSelector((state) => state.loginState);
  const [queryString] = useSearchParams();
  //
  useEffect(() => {
    const user_nm = encodeURIComponent(queryString.get('user_nm'));
    const dept_cd = encodeURIComponent(queryString.get('dept_cd'));
    const psno_pt_txt = encodeURIComponent(queryString.get('psno_pt_txt'));
    if (user_nm && psno_pt_txt) {
      dispatch(
        sendLoginForAdmin({
          user_nm,
          dept_cd,
          psno_pt_txt,
        })
      );
    }
  }, [dispatch, queryString]);

  /** 로그인 콜백 */
  useEffect(() => {
    if (!loading && success) {
      const userInfo = cmSession?.at(0);
      console.log('userinfo', userInfo);
      localStorage.setItem('user_info', JSON.stringify(userInfo));
      localStorage.setItem('menu_list', JSON.stringify(menuList.filter((menu) => menu.mnu_aut_cd !== 'X')));
      const redirectUrl = searchParams.get('redirect');
      const search = searchParams.get('search');
      if (redirectUrl) {
        if (search) {
          navigate(`${redirectUrl}${search ? '?search=auto' : ''}`, { state: { search: 'auto' } });
        } else {
          navigate(redirectUrl);
        }
      } else {
        navigate('/');
      }
    }
  }, [cmSession, loading, menuList, navigate, searchParams, success]);

  useEffect(() => {
    if (!success && message) {
      if (messageId === 'PAPE1037') {
        // 회원가입 화면으로 이동
        alert(message).then(() => navigate('/page/signup'));
      } else {
        // 로그인 화면으로 이동
        alert(message).then(() => navigate('/page/login'));
      }
      dispatch(resetState());
    }
  }, [alert, dispatch, message, messageId, navigate, success]);

  useEffect(() => {
    return () => {
      dispatch(resetState());
    };
  }, [dispatch]);

  return <WOLoading />;
}

export default SsoLogin;
