import {
  fetchAnrvTrnPrtsCnt,
  fetchAnrvTrnPrtsList,
  fetchAnrvTrnPrtsOldCnt,
  fetchAnrvTrnPrtsOldList,
} from '../../../lib/arv/AnrvTrnPrtsOld.api';
import { fetchBrNmList } from '../../../lib/cmm/brNm.api';
import { SUCCESS, REQUEST, FAILURE } from '../../../common/utils/action-type-utils';
import dayjs from 'dayjs';
import { fetchCommonComboList } from '../../../lib/cmm/combo.api';

// actions
const FETCH_ANRV_TRN_PRTS_CNT = 'anrvTrnPrtsOld/FETCH_ANRV_TRN_PRTS_CNT';
const FETCH_ANRV_TRN_PRTS_LIST = 'anrvTrnPrtsOld/FETCH_ANRV_TRN_PRTS_LIST';
const FETCH_ANRV_TRN_PRTS_OLD_CNT = 'anrvTrnPrtsOld/FETCH_ANRV_TRN_PRTS_OLD_CNT';
const FETCH_ANRV_TRN_PRTS_OLD_LIST = 'anrvTrnPrtsOld/FETCH_ANRV_TRN_PRTS_OLD_LIST';
const FETCH_BR_NM_LIST = 'anrvTrnPrtsOld/FETCH_BR_NM_LIST';

const RESET_INITIAL_STATE = 'anrvTrnPrtsOld/RESET_INITIAL_STATE';
const SAVE_SEARCH_FORM_VALUES = 'anrvTrnPrtsOld/SAVE_SEARCH_FORM_VALUES';

const FETCH_IST_INFO_LIST = 'anrvTrnPrtsOld/FETCH_IST_INFO_LIST';
const FETCH_DEPT_INFO_LIST = 'anrvTrnPrtsOld/FETCH_DEPT_INFO_LIST';
const FETCH_ACC_YR_LIST = 'anrvTrnPrtsOld/FETCH_ACC_YR_LIST';
const FETCH_ACT_NO_LIST = 'anrvTrnPrtsOld/FETCH_ACT_NO_LIST';
const FETCH_INQ_SEG_LIST = 'anrvTrnPrtsOld/FETCH_INQ_SEG_LIST';

const SET_BEFORE_SEARCH_PARAM = 'anrvTrnPrtsOld/SET_BEFORE_SEARCH_PARAM';
const CLEAR_MESSAGE = 'anrvTrnPrtsOld/CLEAR_MESSAGE';

export const initFormValues = {
  cus_ist_cd: '', // 기관명
  cus_dept_cd: 'all', // 부서명
  ydcs8a1_t8a01_acc_yr: dayjs().format('YYYY'), // 회계연도
  ydcs8a1_t8a01_inq_seg: '1', // 조회구분
  ydcs8a1_t8a01_act_no: '', // 세입계좌
  ydcs8a1_t8a01_inq_sdt: dayjs().format('YYYYMMDD'), // 조회시작일
  ydcs8a1_t8a01_inq_edt: dayjs().format('YYYYMMDD'), // 조회종료일
};

// initial state
const initialState = {
  loading: false,
  success: false,
  message: '',
  list: [],
  totalCount: 0,
  brNmList: [],
  countLoading: false,
  countSuccess: false,
  beforeSearchParam: {},

  //콤보 - 기관명 005
  istInfoSuccess: false,
  istInfoLoading: false,
  istInfoList: [],

  //콤보 - 부서명 039
  deptInfoSuccess: false,
  deptInfoLoading: false,
  deptInfoList: [],

  //콤보 - 계좌번호 134
  actNoSuccess: false,
  actNoLoading: false,
  actNoList: [],

  //콤보 - 회계연도 025
  accYrSuccess: false,
  accYrLoading: false,
  accYrList: [],

  //콤보- 조회구분
  inqSegLoading: false,
  inqSegSuccess: false,
  inqSegList: [],

  // 검색조건
  searchFormValues: {
    init: true,
    ...initFormValues,
  },
};

// reducers
export default function anrvTrnPrtsOld(state = initialState, action) {
  switch (action.type) {
    // 세입거래내역장 건수 조회(과거, 용산구청 2023년 이전)
    case REQUEST(FETCH_ANRV_TRN_PRTS_CNT): // 조회 요청
      return {
        ...state,
        countLoading: true,
        message: '',
      };
    case SUCCESS(FETCH_ANRV_TRN_PRTS_CNT): // 조회 성공
      return {
        ...state,
        countLoading: false,
        countSuccess: true,
        totalCount: action.payload.data.rsltCnt,
      };
    case FAILURE(FETCH_ANRV_TRN_PRTS_CNT): // 조회 실패
      return {
        ...state,
        totalCount: 0,
        countLoading: false,
        countSuccess: false,
        message: action.payload.response?.data?.message,
      };
    // 세입거래내역장 조회(과거, 용산구청 2023년 이전)
    case REQUEST(FETCH_ANRV_TRN_PRTS_LIST): // 조회 요청
      return {
        ...state,
        loading: true,
        message: '',
      };
    case SUCCESS(FETCH_ANRV_TRN_PRTS_LIST): // 조회 성공
      return {
        ...state,
        success: true,
        loading: false,
        list: action.payload.data?.subDto,
        totalCount: action.payload?.subDtoCnt,
      };
    case FAILURE(FETCH_ANRV_TRN_PRTS_LIST): // 조회실패
      return {
        ...state,
        list: [],
        loading: false,
        success: false,
        message: action.payload.response?.data?.message,
      };
    // 세입거래내역장 건수 조회(과거)
    case REQUEST(FETCH_ANRV_TRN_PRTS_OLD_CNT): // 조회 요청
      return {
        ...state,
        countLoading: true,
        message: '',
      };
    case SUCCESS(FETCH_ANRV_TRN_PRTS_OLD_CNT): // 조회 성공
      return {
        ...state,
        countLoading: false,
        countSuccess: true,
        totalCount: action.payload.data.rsltCnt,
      };
    case FAILURE(FETCH_ANRV_TRN_PRTS_OLD_CNT): // 조회 실패
      return {
        ...state,
        count: 0,
        countLoading: false,
        countSuccess: false,
        message: action.payload.response?.data?.message,
      };
    // 세입거래내역장 조회(과거)
    case REQUEST(FETCH_ANRV_TRN_PRTS_OLD_LIST): // 조회 요청
      return {
        ...state,
        loading: true,
        message: '',
      };
    case SUCCESS(FETCH_ANRV_TRN_PRTS_OLD_LIST): // 조회 성공
      return {
        ...state,
        success: true,
        loading: false,
        list: action.payload.data?.output_data,
        totalCount: action.payload?.output_data_cnt,
      };
    case FAILURE(FETCH_ANRV_TRN_PRTS_OLD_LIST): // 조회실패
      return {
        ...initialState,
        loading: false,
        success: false,
        message: action.payload.response?.data?.message,
      };
    // 거래점명 조회
    case SUCCESS(FETCH_BR_NM_LIST): // 조회 성공
      return {
        ...state,
        brNmList: action.payload.data?.subDto,
      };
    case REQUEST(FETCH_BR_NM_LIST): // 조회 요청
      return {
        ...state,
        message: '',
      };
    case FAILURE(FETCH_BR_NM_LIST): // 조회실패
      return {
        ...state,
        brNmList: [],
        message: action.payload.response?.data?.message,
      };
    // 기관명
    case SUCCESS(FETCH_IST_INFO_LIST): // 조회 성공
      return {
        ...state,
        istInfoSuccess: true,
        istInfoLoading: false,
        istInfoList: action.payload.data?.subDto,
      };
    case REQUEST(FETCH_IST_INFO_LIST): // 조회 요청
      return {
        ...state,
        istInfoLoading: true,
        istInfoSuccess: false,
      };
    case FAILURE(FETCH_IST_INFO_LIST): // 조회실패
      return {
        istInfoLoading: false,
        istInfoSuccess: false,
        istInfoList: [],
      };
    // 부서명
    case SUCCESS(FETCH_DEPT_INFO_LIST): // 조회 성공
      return {
        ...state,
        deptInfoSuccess: true,
        deptInfoLoading: false,
        deptInfoList: action.payload.data?.subDto,
      };
    case REQUEST(FETCH_DEPT_INFO_LIST): // 조회 요청
      return {
        ...state,
        deptInfoLoading: true,
        deptInfoSuccess: false,
      };
    case FAILURE(FETCH_DEPT_INFO_LIST): // 조회실패
      return {
        ...state,
        deptInfoLoading: false,
        deptInfoSuccess: false,
        deptInfoList: [],
      };
    // 계좌번호
    case SUCCESS(FETCH_ACT_NO_LIST): // 조회 성공
      return {
        ...state,
        actNoSuccess: true,
        actNoLoading: false,
        actNoList: action.payload.data?.subDto,
      };
    case REQUEST(FETCH_ACT_NO_LIST): // 조회 요청
      return {
        ...state,
        actNoLoading: true,
        actNoSuccess: false,
      };
    case FAILURE(FETCH_ACT_NO_LIST): // 조회실패
      return {
        ...state,
        actNoLoading: false,
        actNoSuccess: false,
        actNoList: [],
      };
    // 회계연도
    case SUCCESS(FETCH_ACC_YR_LIST): // 조회 성공
      return {
        ...state,
        accYrSuccess: true,
        accYrLoading: false,
        accYrList: action.payload.data?.subDto,
      };
    case REQUEST(FETCH_ACC_YR_LIST): // 조회 요청
      return {
        ...state,
        accYrLoading: true,
        accYrSuccess: false,
      };
    case FAILURE(FETCH_ACC_YR_LIST): // 조회실패
      return {
        ...state,
        accYrLoading: false,
        accYrSuccess: false,
        accYrList: [],
      };
    // 조회구분
    case SUCCESS(FETCH_INQ_SEG_LIST): // 조회 성공
      return {
        ...state,
        inqSegSuccess: true,
        inqSegLoading: false,
        inqSegList: action.payload.data?.subDto,
      };
    case REQUEST(FETCH_INQ_SEG_LIST): // 조회 요청
      return {
        ...state,
        inqSegLoading: true,
        inqSegSuccess: false,
      };
    case FAILURE(FETCH_INQ_SEG_LIST): // 조회실패
      return {
        ...state,
        inqSegLoading: false,
        inqSegSuccess: false,
        inqSegList: [],
      };
    case SAVE_SEARCH_FORM_VALUES:
      return {
        ...state,

        istInfoSuccess: false,
        istInfoLoading: false,
        deptInfoSuccess: false,
        deptInfoLoading: false,
        actNoLoading: false,
        actNoSuccess: false,
        accYrSuccess: false,
        accYrLoading: false,

        searchFormValues: action.payload,
      };
    case RESET_INITIAL_STATE:
      return initialState;
    case SET_BEFORE_SEARCH_PARAM:
      return {
        ...state,
        beforeSearchParam: action.payload,
      };
    case CLEAR_MESSAGE:
      return {
        ...state,
        message: '',
        success: false,
      };
    default:
      return state;
  }
}

/**
 * 세입거래내역장 건수 조회(과거, 용산구청 2023년 이전)
 */
export const selectAnrvTrnPrtsCnt = (searchParams) => {
  return {
    type: FETCH_ANRV_TRN_PRTS_CNT,
    payload: fetchAnrvTrnPrtsCnt(searchParams),
  };
};

/**
 * 세입거래내역장 조회(과거, 용산구청 2023년 이전)
 */
export const selectAnrvTrnPrtsList = (searchParams) => {
  return {
    type: FETCH_ANRV_TRN_PRTS_LIST,
    payload: fetchAnrvTrnPrtsList(searchParams),
  };
};

/**
 * 세입거래내역장 건수 조회(과거)
 */
export const selectAnrvTrnPrtsOldCnt = (searchParams) => {
  return {
    type: FETCH_ANRV_TRN_PRTS_OLD_CNT,
    payload: fetchAnrvTrnPrtsOldCnt(searchParams),
  };
};

/**
 * 세입거래내역장 조회(과거)
 */
export const selectAnrvTrnPrtsOldList = (searchParams) => {
  return {
    type: FETCH_ANRV_TRN_PRTS_OLD_LIST,
    payload: fetchAnrvTrnPrtsOldList(searchParams),
  };
};

/**
 * 거래점명조회
 */
export const selectBrNmList = () => {
  return {
    type: FETCH_BR_NM_LIST,
    payload: fetchBrNmList(),
  };
};

/**
 * 메시지초기화
 */
export const clearMessage = () => {
  return {
    type: CLEAR_MESSAGE,
  };
};

/**
 *
 */
export const setBeforeSearchParam = (searchParams) => {
  return {
    type: SET_BEFORE_SEARCH_PARAM,
    payload: searchParams,
  };
};

//기관명 콤보 조회
export const selectIstInfoList = (searchParam) => {
  return {
    type: FETCH_IST_INFO_LIST,
    payload: fetchCommonComboList({ slt_box_no: '005', ...searchParam }),
  };
};

//부서명 콤보 조회
export const selectDeptInfoList = (searchParam) => {
  return {
    type: FETCH_DEPT_INFO_LIST,
    payload: fetchCommonComboList({ slt_box_no: '039', ...searchParam }),
  };
};

//회계연도 콤보 조회
export const selectAccYrList = () => {
  return {
    type: FETCH_ACC_YR_LIST,
    payload: fetchCommonComboList({ slt_box_no: '025' }),
  };
};

//조회구분 콤보 조회
export const selectInqSegList = () => {
  return {
    type: FETCH_INQ_SEG_LIST,
    payload: fetchCommonComboList({
      slt_box_no: '003',
      cd_grp_nm: '거래명세조회구분(DW)',
      sys_cd: 'CC',
    }),
  };
};

//계좌번호 콤보 조회
export const selectActNoList = (searchParam) => {
  return {
    type: FETCH_ACT_NO_LIST,
    payload: fetchCommonComboList({ slt_box_no: '134', ...searchParam }),
  };
};

/**
 * 조회조건 저장
 */
export const saveSearchFormValues = (searchFormValues) => {
  return {
    type: SAVE_SEARCH_FORM_VALUES,
    payload: {
      ...searchFormValues,
      init: false,
    },
  };
};

/**
 * 상태초기화
 */
export const resetInitialState = () => {
  return {
    type: RESET_INITIAL_STATE,
  };
};
