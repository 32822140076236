import instance from '../../common/config/api';

/**
 * 금고영업점 - 공채 - 당일자 이외 취소내역 조회
 * @param {object} params
 * @returns
 */
export const fetchCrossSellList = (params) =>
  instance.post(`${process.env.REACT_APP_SERVER_URL}/scq/scq/brc/0002/selectCrossSellList`, params);

/**
 * 금고영업점 - 공채 - 당일자 이외 취소신청 등록
 * @param {object} params
 * @returns
 */
export const fetchCrossSellDtl = (params) =>
  instance.post(`${process.env.REACT_APP_SERVER_URL}/scq/scq/brc/0002/selectCrossSellDtl`, params);
