import { SUCCESS, REQUEST, FAILURE } from '../../../common/utils/action-type-utils';
import { fetchZpbLimBzctList } from '../../../lib/zpb/zpbLimBzct';

// actions
const FETCH_LIMBZCT = 'zpbLimBzct/FETCH_LIMBZCT';

// initial state
const initialState = {
  loading: false,
  success: false,
  message: '',
  list: [],
  totalCount: 0,
};

// reducers
export default function zpbLimBzct(state = initialState, action) {
  switch (action.type) {
    case SUCCESS(FETCH_LIMBZCT): // 조회 성공
      return {
        ...state,
        success: true,
        loading: false,
        list: action.payload.data?.subDto,
        totalCount: action.payload?.subDtoCnt,
      };
    case REQUEST(FETCH_LIMBZCT): // 조회 요청
      return {
        ...state,
        loading: true,
        success: false,
      };
    case FAILURE(FETCH_LIMBZCT): // 조회실패
      return {
        ...initialState,
        loading: false,
        success: false,
        message: action.payload.response?.data?.message,
      };
    default:
      return state;
  }
}

/**
 * 제로페이 - 제한업종조회
 */
export const selectLimBzctList = (searchParams) => {
  return {
    type: FETCH_LIMBZCT,
    payload: fetchZpbLimBzctList(searchParams),
  };
};
