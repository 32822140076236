import { SUCCESS, REQUEST, FAILURE } from '../../../common/utils/action-type-utils';
import { fetchUserList } from '../../../lib/sys/SYS0310M01.api';
import { fetchMenuByUser, mergeMenuAutByUser } from '../../../lib/sys/SYS0312M01.api';
// import { fetchSpcMenuAuthList, saveSpcMenuAuthList } from '../../../lib/sys/SYS0311M01.api';

// actions
const FETCH_USER_LIST = 'SYS0312M01/FETCH_USER_LIST';
const FETCH_MENU_BY_USER = 'SYS0312M01/FETCH_MENU_BY_USER';
const SAVE_MENU_AUT_BY_USER = 'SYS0312M01/SAVE_MENU_AUT_BY_USER';

// initial state
const initialState = {
  loading: false,
  success: true,
  message: '',
  list: [],
  totalCount: 0,
  updateSuccess: false,
  resultCnt: -1,

  menuList: [],
  totalCountByMenuList: 0,
  loadingByMenuList: false,
  successByMenuList: false,
};

// reducers
export default function SYS0312M01(state = initialState, action) {
  switch (action.type) {
    case SUCCESS(FETCH_USER_LIST): // 조회 성공
      return {
        ...state,
        success: true,
        loading: false,
        list: action.payload.data?.main,
        totalCount: action.payload?.data?.mainCnt,
        updateSuccess: false,
      };
    case REQUEST(FETCH_USER_LIST): // 조회 요청
      return {
        ...state,
        message: '',
        loading: true,
        success: false,
        updateSuccess: false,
      };
    case FAILURE(FETCH_USER_LIST): // 조회실패
      return {
        ...initialState,
        loading: false,
        success: false,
        message: action.payload.response?.data?.message,
        updateSuccess: false,
      };

    case SUCCESS(FETCH_MENU_BY_USER): // 메뉴조회 성공
      return {
        ...state,
        successByMenuList: true,
        loadingByMenuList: false,
        menuList: action.payload.data?.main,
        totalCountByMenuList: action.payload?.data?.mainCnt,
        updateSuccess: false,
      };
    case REQUEST(FETCH_MENU_BY_USER): // 메뉴조회 요청
      return {
        ...state,
        message: '',
        loadingByMenuList: true,
        successByMenuList: false,
        updateSuccess: false,
      };
    case FAILURE(FETCH_MENU_BY_USER): // 메뉴조회 실패
      return {
        ...initialState,
        loadingByMenuList: false,
        successByMenuList: false,
        message: action.payload.response?.data?.message,
        updateSuccess: false,
      };
    case SUCCESS(SAVE_MENU_AUT_BY_USER): // 저장 성공
      return {
        ...state,
        updateSuccess: true,
        loading: false,
        resultCnt: action.payload.data.resultCnt,
      };
    case REQUEST(SAVE_MENU_AUT_BY_USER): // 저장 요청
      return {
        ...state,
        message: '',
        loading: true,
        updateSuccess: false,
      };
    case FAILURE(SAVE_MENU_AUT_BY_USER): // 저장 실패
      return {
        ...initialState,
        loading: false,
        updateSuccess: false,
        resultCnt: -1,
        message: action.payload.response?.data?.message,
      };
    default:
      return state;
  }
}

/**
 *  메뉴권한관리 사용자 조회
 */
export const selectUserList = (searchParams) => {
  return {
    type: FETCH_USER_LIST,
    payload: fetchUserList(searchParams),
  };
};

/**
 * 메뉴권한 - 사용자별  메뉴조회
 */
export const selectMenuByUser = (searchParams) => {
  return {
    type: FETCH_MENU_BY_USER,
    payload: fetchMenuByUser(searchParams),
  };
};

/**
 * 메뉴권한 - 저장
 */
export const saveMenuAutByUser = (dtos) => {
  return {
    type: SAVE_MENU_AUT_BY_USER,
    payload: mergeMenuAutByUser(dtos),
  };
};
