import {
  fetchMenuListByHelp,
  fetchMenuHelpDtl,
  insertMenuHelpDtl,
  fetchMenuDisList,
} from '../../../lib/sys/SYS0003.api';
import { SUCCESS, REQUEST, FAILURE } from '../../../common/utils/action-type-utils';
import { fetchCommonComboList } from '../../../lib/cmm/combo.api';

// actions
const FETCH_MENU_DIS_LIST = 'MenuHelp/FETCH_MENU_DIS_LIST';
const FETCH_MENU_LIST_BY_HELP = 'MenuHelp/FETCH_MENU_LIST_BY_HELP';
const FETCH_MENU_HELP_DTL = 'MenuHelp/FETCH_MENU_HELP_DTL';
const INSERT_MENU_HELP_DTL = 'MenuHelp/INSERT_MENU_HELP_DTL';

const CLEAR_MESSAGE = 'MenuHelp/CLEAR_MESSAGE';

const RESET_INITIAL_STATE = 'MenuHelp/RESET_INITIAL_STATE';
const SAVE_SEARCH_FORM_VALUES = 'MenuHelp/SAVE_SEARCH_FORM_VALUES';

export const initFormValues = {
  mchr_dis: 'PC',
  sys_seq_no: 'all',
  mnu_seq_no: '',
};

// initial state
const initialState = {
  loading: false,
  success: false,
  message: '',
  list: [],
  totalCount: 0,
  details: {},
  detailsLoading: false,
  detailsSuccess: false,
  detailsMessage: '',
  updating: false,
  updateSuccess: false,
  updateMessage: '',

  //콤보 - 기관명 005
  menuDisSuccess: false,
  menuDisLoading: false,
  menuDisList: [],

  // 검색조건
  searchFormValues: {
    init: true,
    ...initFormValues,
  },
};

// reducers
export default function MenuHelp(state = initialState, action) {
  switch (action.type) {
    case SUCCESS(FETCH_MENU_LIST_BY_HELP): // 조회 성공
      return {
        ...state,
        success: true,
        loading: false,
        list: action.payload.data?.output,
      };
    case REQUEST(FETCH_MENU_LIST_BY_HELP): // 조회 요청
      return {
        ...state,
        list: [],
        message: '',
        loading: true,
      };
    case FAILURE(FETCH_MENU_LIST_BY_HELP): // 조회실패
      return {
        ...state,
        list: [],
        loading: false,
        success: false,
        message: action.payload.response?.data?.message,
      };
    case SUCCESS(FETCH_MENU_HELP_DTL): // 조회 성공
      return {
        ...state,
        detailsSuccess: true,
        detailsLoading: false,
        details: action.payload.data?.output?.at(0),
      };
    case REQUEST(FETCH_MENU_HELP_DTL): // 조회 요청
      return {
        ...state,
        details: {},
        detailsMessage: '',
        detailsLoading: true,
      };
    case FAILURE(FETCH_MENU_HELP_DTL): // 조회실패
      return {
        ...state,
        details: {},
        detailsLoading: false,
        detailsSuccess: false,
        detailsMessage: action.payload.response?.data?.message,
      };
    case SUCCESS(INSERT_MENU_HELP_DTL): // 성공
      return {
        ...state,
        updateSuccess: true,
        updating: false,
      };
    case REQUEST(INSERT_MENU_HELP_DTL): // 요청
      return {
        ...state,
        updating: true,
        updateSuccess: false,
        message: null,
      };
    case FAILURE(INSERT_MENU_HELP_DTL): // 실패
      return {
        ...state,
        updating: false,
        updateSuccess: false,
        message: action.payload.response?.data?.message,
      };
    // 메뉴구분
    case SUCCESS(FETCH_MENU_DIS_LIST): // 조회 성공
      return {
        ...state,
        menuDisSuccess: true,
        menuDisLoading: false,
        menuDisList: action.payload.data?.output,
      };
    case REQUEST(FETCH_MENU_DIS_LIST): // 조회 요청
      return {
        ...state,
        menuDisLoading: true,
        menuDisSuccess: false,
      };
    case FAILURE(FETCH_MENU_DIS_LIST): // 조회실패
      return {
        ...state,
        menuDisLoading: false,
        menuDisSuccess: false,
        menuDisList: [],
      };
    case SAVE_SEARCH_FORM_VALUES:
      return {
        ...state,
        menuDisSuccess: false,
        menuDisLoading: false,
        searchFormValues: action.payload,
      };
    case RESET_INITIAL_STATE:
      return initialState;
    case CLEAR_MESSAGE:
      return {
        ...state,
        message: '',
        success: false,
        updateSuccess: false,
        updateMessage: '',
        detailsSuccess: false,
        detailsMessage: '',
      };
    default:
      return state;
  }
}

/**
 *
 */
export const selectMenuListByHelp = (searchParams) => {
  return {
    type: FETCH_MENU_LIST_BY_HELP,
    payload: fetchMenuListByHelp(searchParams),
  };
};

/**
 *
 */
export const selectMenuHelpDtl = (searchParams) => {
  return {
    type: FETCH_MENU_HELP_DTL,
    payload: fetchMenuHelpDtl(searchParams),
  };
};

/**
 * 세출거래명세 조회
 */
export const saveMenuHelpDtl = (searchParams) => {
  return {
    type: INSERT_MENU_HELP_DTL,
    payload: insertMenuHelpDtl(searchParams),
  };
};

/**
 * 메시지초기화
 */
export const clearMessage = () => {
  return {
    type: CLEAR_MESSAGE,
  };
};

//기관명 콤보 조회
export const selectMenuDisList = (searchParam) => {
  return {
    type: FETCH_MENU_DIS_LIST,
    payload: fetchMenuDisList(searchParam),
  };
};

/**
 * 조회조건 저장
 */
export const saveSearchFormValues = (searchFormValues) => {
  return {
    type: SAVE_SEARCH_FORM_VALUES,
    payload: {
      ...searchFormValues,
      init: false,
    },
  };
};

/**
 * 상태초기화
 */
export const resetInitialState = () => {
  return {
    type: RESET_INITIAL_STATE,
  };
};
