import instance from '../../common/config/api';

/**
 * 시스템관리 - 반납가상계좌 모계좌 관리
 * @param {object} params
 * @returns
 */

export const fetchDeptGenActNo = (params) =>
  instance.post(`${process.env.REACT_APP_SERVER_URL}/scq/scq/sys/0001/selectDeptGenActNo`, params);

export const insertDeptGenActNo = (dtos) =>
  instance.post(`${process.env.REACT_APP_SERVER_URL}/scq/scq/sys/0001/insertDeptGenActNo`, {
    subDto: dtos,
  });
