import { SUCCESS, REQUEST, FAILURE } from '../../../common/utils/action-type-utils';
import { fetchApvMng, updateRptApv, fetchApvMngPage } from '../../../lib/rpt/RPT0402M01.api';
import {
  fetchYearBjYn,
  fetchRptMgtCb,
  fetchApvMngDt,
  updateYearBj,
  updateBatClear,
  updateBatApn,
  fetchRptMngRgr,
} from '../../../lib/rpt/RPT0004.api';
import dayjs from 'dayjs';

// actions
const FETCH_APV_MNG_LIST = 'RPT0402M01/FETCH_APV_MNG_LIST';
const FETCH_APV_MNG_PAGE = 'RPT0402M01/FETCH_APV_MNG_PAGE';
const UPDATE_APV_MNG_LIST = 'RPT0402M01/UPDATE_APV_MNG_LIST';
const RESET_APV_MNG = 'RPT0402M01/RESET_APV_MNG';
const FETCH_YEAR_BJ_YN = 'RPT0402M01/FETCH_YEAR_BJ_YN';
const FETCH_RPT_MGT_CB = 'RPT0402M01/FETCH_RPT_MGT_CB';
const FETCH_APV_MNG_DT = 'RPT0402M01/FETCH_APV_MNG_DT';
const UPDATE_YEAR_BJ = 'RPT0402M01/UPDATE_YEAR_BJ';
const UPDATE_BAT_CLEAR = 'RPT0402M01/UPDATE_BAT_CLEAR';
const UPDATE_BAT_APN = 'RPT0402M01/UPDATE_BAT_APN';
const FETCH_RPT_MNG_RGR = 'RPT0402M01/FETCH_RPT_MNG_RGR';

const SAVE_SEARCH_FORM_VALUES = 'SYS0203V01/SAVE_SEARCH_FORM_VALUES';
const RESET_STATE = 'SYS0203V01/RESET_STATE';
const RESET_APPV_STATE = 'SYS0203V01/RESET_APPV_STATE';

export const initFormValues = {
  // 검색 영역
  cus_ist_cd: '', //기관명
  cus_dept_cd: 'all', //부서명
  cre_dy_fr: dayjs().format('YYYY-MM-DD'), //보고일자
  cre_dy_to: dayjs().format('YYYY-MM-DD'), //보고일자
  acc_yr: '', //회계연도
  sncorptprgtm_seq: 'all', //보고서종류
  apv_stp_cd: 'all', //보고단계
};

// initial state
const initialState = {
  loading: false,
  success: true,
  message: '',
  list: [],
  updating: false,
  updateSuccess: false,
  resultCnt: 0,
  rptMngLoading: false,
  rptMngSuccess: false,
  rptMngList: [],
  rptMngMessage: '',
  apvMngLoading: false,
  apvMngSuccess: false,
  apvMngList: [],
  apvMngMessage: '',
  yearBjYnLoading: false,
  yearBjYnSuccess: false,
  yearBjYn: '',
  yearBjYnMessage: '',
  mgtCbLoading: false,
  mgtCbSuccess: false,
  mgtCbList: [],
  mgtCbMessage: '',
  apvMngDtLoading: false,
  apvMngDtSuccess: false,
  apvMngDtList: [],
  apvMngDtTotalCnt: 0,
  apvMngDtMessage: '',
  mngRgrLoading: false,
  mngRgrSuccess: false,
  mngRgrList: [],
  mngRgrMessage: '',
  totalCount: 0,
  // 검색조건
  searchFormValues: {
    init: true,
    ...initFormValues,
  },
};

// reducers
export default function RPT0402M01(state = initialState, action) {
  switch (action.type) {
    case SUCCESS(FETCH_APV_MNG_PAGE): // 조회 성공
      return {
        ...state,
        rptMngSuccess: true,
        rptMngLoading: false,
        rptMngList: action.payload.data?.main,
        totalCount: action.payload?.data?.totalCnt,
      };
    case REQUEST(FETCH_APV_MNG_PAGE): // 조회 요청
      return {
        ...state,
        rptMngLoading: true,
        rptMngSuccess: false,
        rptMngMessage: '',
        apvMngDtTotalCnt: 0,
      };
    case FAILURE(FETCH_APV_MNG_PAGE): // 조회실패
      return {
        ...initialState,
        rptMngLoading: false,
        rptMngSuccess: false,
        rptMngMessage: action.payload.response?.data?.message,
      };

    case SUCCESS(FETCH_APV_MNG_LIST): // 조회 성공
      return {
        ...state,
        rptMngList: [],
        apvMngSuccess: true,
        apvMngLoading: false,
        apvMngList: action.payload.data?.main,
        // totalCount: action.payload?.data?.totalCnt,
      };
    case REQUEST(FETCH_APV_MNG_LIST): // 조회 요청
      return {
        ...state,
        apvMngLoading: true,
        apvMngSuccess: false,
        apvMngMessage: '',
      };
    case FAILURE(FETCH_APV_MNG_LIST): // 조회실패
      return {
        ...initialState,
        apvMngLoading: false,
        apvMngSuccess: false,
        apvMngMessage: action.payload.response?.data?.message,
      };
    case SUCCESS(UPDATE_APV_MNG_LIST): // 갱신 성공
    case SUCCESS(UPDATE_YEAR_BJ): // 연초보정 성공
    case SUCCESS(UPDATE_BAT_CLEAR): // 일괄초기화 성공
    case SUCCESS(UPDATE_BAT_APN): // 일괄승인 성공
      return {
        ...state,
        updating: false,
        updateSuccess: true,
        resultCnt: action.payload.data.resultCnt,
      };
    case REQUEST(UPDATE_APV_MNG_LIST): // 갱신 요청
    case REQUEST(UPDATE_YEAR_BJ): // 연초보정 요청
    case REQUEST(UPDATE_BAT_CLEAR): // 일괄초기화 요청
    case REQUEST(UPDATE_BAT_APN): // 일괄승인 요청
      return {
        ...state,
        updating: true,
        updateSuccess: false,
      };
    case FAILURE(UPDATE_APV_MNG_LIST): // 갱신 실패
    case FAILURE(UPDATE_YEAR_BJ): // 연초보정 실패
    case FAILURE(UPDATE_BAT_CLEAR): // 일괄초기화 실패
    case FAILURE(UPDATE_BAT_APN): // 일괄승인 실패
      return {
        ...initialState,
        updating: false,
        updateSuccess: false,
        resultCnt: -1,
        message: action.payload.response?.data?.message,
      };
    case RESET_APV_MNG:
      return {
        ...initialState,
        totalCount: state.totalCount,
      };
    case SUCCESS(FETCH_YEAR_BJ_YN): // 활성화여부 조회 성공
      return {
        ...state,
        yearBjYnSuccess: true,
        yearBjYnLoading: false,
        yearBjYn: action.payload.data?.yearBjYn,
      };
    case REQUEST(FETCH_YEAR_BJ_YN): // 활성화여부 조회 요청
      return {
        ...state,
        yearBjYnLoading: true,
        yearBjYnSuccess: false,
        yearBjYnMessage: '',
      };
    case FAILURE(FETCH_YEAR_BJ_YN): // 활성화여부 조회 실패
      return {
        ...initialState,
        yearBjYnLoading: false,
        yearBjYnSuccess: false,
        yearBjYnMessage: action.payload.response?.data?.message,
      };
    case SUCCESS(FETCH_RPT_MGT_CB): // 기관조회 성공
      return {
        ...state,
        mgtCbSuccess: true,
        mgtCbLoading: false,
        mgtCbList: action.payload.data?.ist_cd,
      };
    case REQUEST(FETCH_RPT_MGT_CB): // 기관조회 요청
      return {
        ...state,
        mgtCbLoading: true,
        mgtCbSuccess: false,
        mgtCbMessage: '',
      };
    case FAILURE(FETCH_RPT_MGT_CB): // 기관조회 실패
      return {
        ...initialState,
        mgtCbLoading: false,
        mgtCbSuccess: false,
        mgtCbMessage: action.payload.response?.data?.message,
      };
    case SUCCESS(FETCH_APV_MNG_DT): // 보고서 상세조회 성공
      return {
        ...state,
        apvMngDtSuccess: true,
        apvMngDtLoading: false,
        apvMngDtList: action.payload.data?.sub01_data,
        apvMngDtTotalCnt: action.payload?.data?.totalCnt,
      };
    case REQUEST(FETCH_APV_MNG_DT): // 보고서 상세조회 요청
      return {
        ...state,
        apvMngDtLoading: true,
        apvMngDtSuccess: false,
        apvMngDtMessage: '',
      };
    case FAILURE(FETCH_APV_MNG_DT): // 보고서 상세조회 실패
      return {
        ...initialState,
        apvMngDtLoading: false,
        apvMngDtSuccess: false,
        apvMngDtMessage: action.payload.response?.data?.message,
      };
    case SUCCESS(FETCH_RPT_MNG_RGR): // 보고서 관리대장 조회 성공
      return {
        ...state,
        mngRgrSuccess: true,
        mngRgrLoading: false,
        mngRgrList: action.payload.data?.output_data,
      };
    case REQUEST(FETCH_RPT_MNG_RGR): // 보고서 관리대장 조회 요청
      return {
        ...state,
        mngRgrLoading: true,
        mngRgrSuccess: false,
        mngRgrMessage: '',
      };
    case FAILURE(FETCH_RPT_MNG_RGR): // 보고서 관리대장 조회 실패
      return {
        ...initialState,
        mngRgrLoading: false,
        mngRgrSuccess: false,
        mngRgrMessage: action.payload.response?.data?.message,
      };
    case SAVE_SEARCH_FORM_VALUES:
      return {
        ...state,
        searchFormValues: action.payload,
      };
    case RESET_APPV_STATE:
      return {
        ...state,
        apvMngLoading: false,
        apvMngSuccess: false,
      };
    case RESET_STATE:
      return initialState;
    default:
      return state;
  }
}

/**
 * 보고서 관리 내역 조회
 */
export const selectApvMngList = (searchParams) => {
  return {
    type: FETCH_APV_MNG_LIST,
    payload: fetchApvMng(searchParams),
  };
};

/**
 * 보고서 관리 내역 조회(페이징처리용)
 */
export const selectApvMngPage = (searchParams) => {
  return {
    type: FETCH_APV_MNG_PAGE,
    payload: fetchApvMngPage(searchParams),
  };
};

/**
 * 보고서 결재 저장
 */
export const saveRptApv = (dtos) => {
  return {
    type: UPDATE_APV_MNG_LIST,
    payload: updateRptApv(dtos),
  };
};

/**
 * 초기화
 */
export const resetRptApv = () => {
  return {
    type: RESET_APV_MNG,
  };
};

/**
 * 연초보정 버튼 활성화여부 조회
 */
export const selectYearBjYn = () => {
  return {
    type: FETCH_YEAR_BJ_YN,
    payload: fetchYearBjYn(),
  };
};

/**
 * 보고서 관리대장 기관 조회
 */
export const selectRptMgtCb = (searchParams) => {
  return {
    type: FETCH_RPT_MGT_CB,
    payload: fetchRptMgtCb(searchParams),
  };
};

/**
 * 보고서 상세 내역 조회
 */
export const selectApvMngDt = (searchParams) => {
  return {
    type: FETCH_APV_MNG_DT,
    payload: fetchApvMngDt(searchParams),
  };
};

/**
 * 연초보정
 */
export const saveYearBj = () => {
  return {
    type: UPDATE_YEAR_BJ,
    payload: updateYearBj(),
  };
};

/**
 * 일괄초기화
 */
export const saveBatClear = (dtos) => {
  return {
    type: UPDATE_BAT_CLEAR,
    payload: updateBatClear(dtos),
  };
};

/**
 * 일괄승인
 */
export const saveBatApn = (dtos) => {
  return {
    type: UPDATE_BAT_APN,
    payload: updateBatApn(dtos),
  };
};

/**
 * 전자보고서 관리대장
 */
export const selectRptMngRgr = (searchParams) => {
  return {
    type: FETCH_RPT_MNG_RGR,
    payload: fetchRptMngRgr(searchParams),
  };
};

/**
 * 조회조건 저장
 */
export const saveSearchFormValues = (searchFormValues) => {
  return {
    type: SAVE_SEARCH_FORM_VALUES,
    payload: {
      ...searchFormValues,
      init: false,
    },
  };
};

/**
 * 상태초기화
 */
export const resetState = () => {
  return {
    type: RESET_STATE,
  };
};

/**
 * 진행상태 초기화
 */
export const resetAppvState = () => {
  return {
    type: RESET_APPV_STATE,
  };
};
