import instance from '../../common/config/api';

/**
 * 사용자별 메뉴권한 조회
 * @param {object} params
 * @returns
 */
export const fetchMenuPrsnByUserList = (params) =>
  instance.post(`${process.env.REACT_APP_SERVER_URL}/scq/scq/sys/0003/selectMenuPrsnByUserList`, params);

/**
 * 사용자목록 조회
 * @param {object} params
 * @returns
 */
export const fetchUserList = (params) =>
  instance.post(`${process.env.REACT_APP_SERVER_URL}/scq/scq/sys/0003/selectUserList`, params);

/**
 * 사용자별메뉴관리 저장
 * @param {object} params
 * @returns
 */
export const saveMenuPrsnByUserList = (dtos) =>
  instance.post(`${process.env.REACT_APP_SERVER_URL}/scq/scq/sys/0003/saveMenuPrsnByUserList`, {
    main: dtos,
    main_cnt: dtos.length,
  });

/**
 * 사용자목록 수정
 * @param {object} params
 * @returns
 */
export const updateUserList = (dtos) =>
  instance.post(`${process.env.REACT_APP_SERVER_URL}/scq/scq/sys/0002/insertUserListMenu`, {
    main: dtos,
    mainCnt: dtos.length,
  });
