import instance from '../../common/config/api';

//일상경비
const preifx = `${process.env.REACT_APP_SERVER_URL}/scq/scq/ils/0003`;

/**
 * 보통예금계좌 지급 요청공문 조회
 * @param {object} params
 * @returns
 */
export const fetchGndpActPayReq = (params) => instance.post(`${preifx}/selectGndpActPayReq`, params);

/**
 * 결재자목록 조회
 * @param {object} params
 * @returns
 */
export const fetchDeptUser = (params) => instance.post(`${preifx}/selectDeptUser`, params);

/**
 * 요청공문데이터 저장
 * @param {object} params
 * @returns
 */
export const updateGndpActPayReq = (params) => instance.post(`${preifx}/saveGndpActPayReq`, params);

/**
 * 일괄결재 저장
 * @param {object} params
 * @returns
 */
export const updateBatAppv = (params) => instance.post(`${preifx}/saveBatAppv`, params);

/**
 * 회수요청
 * @param {object} params
 * @returns
 */
export const updateWdwReq = (params) => instance.post(`${preifx}/saveWdwReq`, params);

/**
 * 은행결재요청 저장
 * @param {object} params
 * @returns
 */
export const updateBkAppvReq = (params) => instance.post(`${preifx}/saveBkAppvReq`, params);
