import { fetchAnexTrnPrtsList } from '../../../lib/axp/AnexTrnPrtsList.api';
import { SUCCESS, REQUEST, FAILURE } from '../../../common/utils/action-type-utils';
import dayjs from 'dayjs';
import { fetchCommonComboList } from '../../../lib/cmm/combo.api';

// actions
const FETCH_ANEX_TRN_PRTS_LIST = 'AnexTrnPrtsList/FETCH_ANEX_TRN_PRTS_LIST';
const FETCH_ANEX_TRN_PRTS_LIST_CONTINUE = 'AnexTrnPrtsList/FETCH_ANEX_TRN_PRTS_LIST_CONTINUE';

const FETCH_IST_INFO_LIST = 'AnexTrnPrtsList/FETCH_IST_INFO_LIST';
const FETCH_DEPT_INFO_LIST = 'AnexTrnPrtsList/FETCH_DEPT_INFO_LIST';
const FETCH_ACC_YR_LIST = 'AnexTrnPrtsList/FETCH_ACC_YR_LIST';
const FETCH_ARVN_ACT_DSCD_LIST = 'AnexTrnPrtsList/FETCH_ARVN_ACT_DSCD_LIST';
const FETCH_ACT_NO_LIST = 'AnexTrnPrtsList/FETCH_ACT_NO_LIST';

const RESET_INITIAL_STATE = 'AnexTrnPrtsList/RESET_INITIAL_STATE';
const SAVE_SEARCH_FORM_VALUES = 'AnexTrnPrtsList/SAVE_SEARCH_FORM_VALUES';

const CLEAR_MESSAGE = 'AnexTrnPrtsList/CLEAR_MESSAGE';

export const initFormValues = {
  cus_ist_cd: '', //기관명
  cus_dept_cd: 'all', //부서명
  ydcs8a1_t8a01_inq_sdt: dayjs(), //조회기간
  ydcs8a1_t8a01_inq_edt: dayjs(), //조회기간
  ydcs8a1_t8a01_acc_yr: dayjs().format('YYYY'), //회계연도
  ydcs8a1_t8a01_act_no: '', //세출계좌
  arvn_act_dscd: 'all', //시세/구세
  pmdp_act_dscd: '02', //01=세입, 02=세출
};

// initial state
const initialState = {
  loading: false,
  success: false,
  message: '',
  list: [],
  listTitle: {},
  listHead: {},
  listCommon: {},
  totalCount: 0,

  //콤보 - 기관명 005
  istInfoSuccess: false,
  istInfoLoading: false,
  istInfoList: [],

  //콤보 - 부서명 040
  deptInfoSuccess: false,
  deptInfoLoading: false,
  deptInfoList: [],

  //콤보 - 계좌번호 921
  actNoSuccess: false,
  actNoLoading: false,
  actNoList: [],

  //콤보 - 회계연도 025
  accYrSuccess: false,
  accYrLoading: false,
  accYrList: [],

  //콤보 - 시세/구세 003
  arvnActDscdSuccess: false,
  arvnActDscdLoading: false,
  arvnActDscdList: [],

  // 검색조건
  searchFormValues: {
    init: true,
    ...initFormValues,
  },
};

// reducers
export default function AnexTrnPrtsList(state = initialState, action) {
  switch (action.type) {
    case SUCCESS(FETCH_ANEX_TRN_PRTS_LIST): // 조회 성공
      return {
        ...state,
        success: true,
        loading: false,
        list: action.payload.data?.output_data,
        listTitle: action.payload.data?.output_title,
        listHead: action.payload.data?.output_head,
        listCommon: action.payload.data?.common,
        totalCount: action.payload?.output_data_cnt,
      };
    case REQUEST(FETCH_ANEX_TRN_PRTS_LIST): // 조회 요청
      return {
        ...state,
        list: [],
        message: '',
        loading: true,
      };
    case FAILURE(FETCH_ANEX_TRN_PRTS_LIST): // 조회실패
      return {
        ...state,
        list: [],
        loading: false,
        success: false,
        message: action.payload.response?.data?.message,
      };
    case SUCCESS(FETCH_ANEX_TRN_PRTS_LIST_CONTINUE): // 조회 성공
      return {
        ...state,
        success: true,
        loading: false,
        list: [...state.list, ...action.payload.data?.output_data],
        listTitle: action.payload.data?.output_title,
        listHead: action.payload.data?.output_head,
        listCommon: action.payload.data?.common,
        totalCount: action.payload?.output_data_cnt,
      };
    case REQUEST(FETCH_ANEX_TRN_PRTS_LIST_CONTINUE): // 조회 요청
      return {
        ...state,
        message: '',
        loading: true,
      };
    case FAILURE(FETCH_ANEX_TRN_PRTS_LIST_CONTINUE): // 조회실패
      return {
        ...state,
        loading: false,
        success: false,
        message: action.payload.response?.data?.message,
      };
    // 기관명
    case SUCCESS(FETCH_IST_INFO_LIST): // 조회 성공
      return {
        ...state,
        istInfoSuccess: true,
        istInfoLoading: false,
        istInfoList: action.payload.data?.subDto,
      };
    case REQUEST(FETCH_IST_INFO_LIST): // 조회 요청
      return {
        ...state,
        istInfoLoading: true,
        istInfoSuccess: false,
      };
    case FAILURE(FETCH_IST_INFO_LIST): // 조회실패
      return {
        istInfoLoading: false,
        istInfoSuccess: false,
        istInfoList: [],
      };
    // 부서명
    case SUCCESS(FETCH_DEPT_INFO_LIST): // 조회 성공
      return {
        ...state,
        deptInfoSuccess: true,
        deptInfoLoading: false,
        deptInfoList: action.payload.data?.subDto,
      };
    case REQUEST(FETCH_DEPT_INFO_LIST): // 조회 요청
      return {
        ...state,
        deptInfoLoading: true,
        deptInfoSuccess: false,
      };
    case FAILURE(FETCH_DEPT_INFO_LIST): // 조회실패
      return {
        ...state,
        deptInfoLoading: false,
        deptInfoSuccess: false,
        deptInfoList: [],
      };
    // 계좌번호
    case SUCCESS(FETCH_ACT_NO_LIST): // 조회 성공
      return {
        ...state,
        actNoSuccess: true,
        actNoLoading: false,
        actNoList: action.payload.data?.subDto,
      };
    case REQUEST(FETCH_ACT_NO_LIST): // 조회 요청
      return {
        ...state,
        actNoLoading: true,
        actNoSuccess: false,
      };
    case FAILURE(FETCH_ACT_NO_LIST): // 조회실패
      return {
        ...state,
        actNoLoading: false,
        actNoSuccess: false,
        actNoList: [],
      };
    // 시세/구세
    case SUCCESS(FETCH_ARVN_ACT_DSCD_LIST): // 조회 성공
      return {
        ...state,
        arvnActDscdSuccess: true,
        arvnActDscdLoading: false,
        arvnActDscdList: action.payload.data?.subDto,
      };
    case REQUEST(FETCH_ARVN_ACT_DSCD_LIST): // 조회 요청
      return {
        ...state,
        arvnActDscdLoading: true,
        arvnActDscdSuccess: false,
      };
    case FAILURE(FETCH_ARVN_ACT_DSCD_LIST): // 조회실패
      return {
        ...state,
        arvnActDscdLoading: false,
        arvnActDscdSuccess: false,
        arvnActDscdList: [],
      };
    // 회계연도
    case SUCCESS(FETCH_ACC_YR_LIST): // 조회 성공
      return {
        ...state,
        accYrSuccess: true,
        accYrLoading: false,
        accYrList: action.payload.data?.subDto,
      };
    case REQUEST(FETCH_ACC_YR_LIST): // 조회 요청
      return {
        ...state,
        accYrLoading: true,
        accYrSuccess: false,
      };
    case FAILURE(FETCH_ACC_YR_LIST): // 조회실패
      return {
        ...state,
        accYrLoading: false,
        accYrSuccess: false,
        accYrList: [],
      };
    case SAVE_SEARCH_FORM_VALUES:
      return {
        ...state,
        istInfoSuccess: false,
        istInfoLoading: false,
        deptInfoSuccess: false,
        deptInfoLoading: false,
        actNoSuccess: false,
        actNoLoading: false,
        accYrSuccess: false,
        accYrLoading: false,
        arvnActDscdSuccess: false,
        arvnActDscdLoading: false,

        searchFormValues: action.payload,
      };
    case RESET_INITIAL_STATE:
      return initialState;
    case CLEAR_MESSAGE:
      return {
        ...state,
        message: '',
        success: false,
        listCommon: {},
      };
    default:
      return state;
  }
}

/**
 * 세출거래내역장
 */
export const selectAnexTrnPrtsList = (searchParams) => {
  return {
    type: searchParams?.isContinue ? FETCH_ANEX_TRN_PRTS_LIST_CONTINUE : FETCH_ANEX_TRN_PRTS_LIST,
    payload: fetchAnexTrnPrtsList(searchParams),
  };
};

//기관명 콤보 조회
export const selectIstInfoList = (searchParam) => {
  return {
    type: FETCH_IST_INFO_LIST,
    payload: fetchCommonComboList({ slt_box_no: '005', ...searchParam }),
  };
};

//부서명 콤보 조회
export const selectDeptInfoList = (searchParam) => {
  return {
    type: FETCH_DEPT_INFO_LIST,
    payload: fetchCommonComboList({ slt_box_no: '040', ...searchParam }),
  };
};

//회계연도 콤보 조회
export const selectAccYrList = () => {
  return {
    type: FETCH_ACC_YR_LIST,
    payload: fetchCommonComboList({ slt_box_no: '025' }),
  };
};

//계좌번호 콤보 조회
export const selectActNoList = (searchParam) => {
  return {
    type: FETCH_ACT_NO_LIST,
    payload: fetchCommonComboList({ slt_box_no: '921', ...searchParam }),
  };
};

//시세/구세 콤보 조회
export const selectArvnActDscdList = () => {
  return {
    type: FETCH_ARVN_ACT_DSCD_LIST,
    payload: fetchCommonComboList({
      slt_box_no: '003',
      cd_grp_nm: '세입계좌구분코드',
      sys_cd: 'GW',
    }),
  };
};

/**
 * 조회조건 저장
 */
export const saveSearchFormValues = (searchFormValues) => {
  return {
    type: SAVE_SEARCH_FORM_VALUES,
    payload: {
      ...searchFormValues,
      init: false,
    },
  };
};

/**
 * 상태초기화
 */
export const resetInitialState = () => {
  return {
    type: RESET_INITIAL_STATE,
  };
};

/**
 * 메시지초기화
 */
export const clearMessage = () => {
  return {
    type: CLEAR_MESSAGE,
  };
};
