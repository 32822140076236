import instance from '../../common/config/api';

/**
 * 시스템관리 - 부서별 계좌번호 관리
 * @param {object} params
 * @returns
 */

export const fetchActNoList = (params) =>
  instance.post(`${process.env.REACT_APP_SERVER_URL}/scq/scq/sys/0001/selectActNo`, params);

export const insertActNo = (dtos) =>
  instance.post(`${process.env.REACT_APP_SERVER_URL}/scq/scq/sys/0001/insertActNo`, {
    subDto: dtos,
  });

export const updateEndMoveAut = (dtos) =>
  instance.post(`${process.env.REACT_APP_SERVER_URL}/scq/scq/sys/0001/updateEndwMove`, {
    subDto: dtos,
  });

export const updateEndwCopyAut = (dtos) =>
  instance.post(`${process.env.REACT_APP_SERVER_URL}/scq/scq/sys/0001/updateEndwCopy`, {
    subDto: dtos,
  });
