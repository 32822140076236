import instance from '../../common/config/api';

/**
 * 프로그램등록 조회
 * @param {object} params
 * @returns
 */
export const fetchPgmRgsList = (params) =>
  instance.post(`${process.env.REACT_APP_SERVER_URL}/scq/scq/sys/0003/selectPgmRgs`, params);

/**
 * 프로그램등록
 * @param {array} dtos
 * @returns
 */
export const savePgmRgsList = (dtos) =>
  instance.post(`${process.env.REACT_APP_SERVER_URL}/scq/scq/sys/0003/insertPgmRgs`, {
    main: dtos,
    main_cnt: dtos.length,
  });
