import React, { useEffect, useRef, useState } from 'react';
import ClipReportViewer from '../../components/report/ClipReportViewer';

function ReportPage() {
  const reportRef = useRef(null);

  // 양식명
  const [templateName, setTemplateName] = useState('');
  // 데이터
  const [jsonData, setJsonData] = useState({});
  // report params
  const [reportParams, setReportParams] = useState({});

  useEffect(() => {
    const reportDataStr = localStorage.getItem('report_data');
    const reportData = JSON.parse(reportDataStr);
    setTemplateName(reportData.templateName);
    setJsonData(reportData.jsonData);
    setReportParams(reportData.reportParams);
  }, []);

  return (
    <>
      <ClipReportViewer
        height={'100%'}
        ref={reportRef}
        templateName={templateName}
        jsonData={jsonData}
        reportParams={reportParams}
      />
    </>
  );
}

export default ReportPage;
