import { SUCCESS, REQUEST, FAILURE } from '../../../common/utils/action-type-utils';
import {
  fetchAnrvAnexFdDay,
  fetchFdDayYs,
  fetchFdDayHostYn,
  fetchFdDaySr,
  fetchFdDayGu,
  fetchFdDayGg,
  fetchAnrvAnexFdDayGg,
} from '../../../lib/rpt/RPT0002.api';

// actions
const FETCH_ANRV_ANEX_FD_DAY = 'RPT0201V01/FETCH_ANRV_ANEX_FD_DAY';
const FETCH_ANRV_ANEX_FD_DAY_GG = 'RPT0201V01/FETCH_ANRV_ANEX_FD_DAY_GG';
const FETCH_FD_DAY_HOST_YN = 'RPT0201V01/FETCH_FD_DAY_HOST_YN';
const FETCH_FD_DAY_YS = 'RPT0201V01/FETCH_FD_DAY_YS';
const FETCH_FD_DAY_SR = 'RPT0201V01/FETCH_FD_DAY_SR';
const FETCH_FD_DAY_GU = 'RPT0201V01/FETCH_FD_DAY_GU';
const FETCH_FD_DAY_GG = 'RPT0201V01/FETCH_FD_DAY_GG';

// initial state
const initialState = {
  loading: false,
  success: false,
  message: '',
  list: [],
  hostYn: '',
  hostYnLoading: false,
  hostYnSuccess: false,
  hostYnMessage: '',
  accYr: '',
  br_nm: '',
};

// reducers
export default function RPT0201V01(state = initialState, action) {
  switch (action.type) {
    case SUCCESS(FETCH_FD_DAY_GG): // 조회 성공
    case SUCCESS(FETCH_FD_DAY_YS):
    case SUCCESS(FETCH_FD_DAY_SR):
    case SUCCESS(FETCH_FD_DAY_GU):
    case SUCCESS(FETCH_ANRV_ANEX_FD_DAY):
    case SUCCESS(FETCH_ANRV_ANEX_FD_DAY_GG):
      return {
        ...state,
        success: true,
        loading: false,
        list: action.payload.data?.output_data,
        accYr: action.payload.data?.acc_yr,
        br_nm: action.payload.data?.br_nm,
      };
    case REQUEST(FETCH_FD_DAY_GG): // 조회 요청
    case REQUEST(FETCH_FD_DAY_YS):
    case REQUEST(FETCH_FD_DAY_SR):
    case REQUEST(FETCH_FD_DAY_GU):
    case REQUEST(FETCH_ANRV_ANEX_FD_DAY):
    case REQUEST(FETCH_ANRV_ANEX_FD_DAY_GG):
      return {
        ...state,
        message: '',
        loading: true,
        success: false,
      };
    case FAILURE(FETCH_FD_DAY_GG): // 조회실패
    case FAILURE(FETCH_FD_DAY_YS):
    case FAILURE(FETCH_FD_DAY_SR):
    case FAILURE(FETCH_FD_DAY_GU):
    case FAILURE(FETCH_ANRV_ANEX_FD_DAY):
    case FAILURE(FETCH_ANRV_ANEX_FD_DAY_GG):
      return {
        ...state,
        list: [],
        loading: false,
        success: false,
        message: action.payload.response?.data?.message,
      };
    case SUCCESS(FETCH_FD_DAY_HOST_YN): // 조회 성공
      return {
        ...state,
        hostYnSuccess: true,
        hostYnLoading: false,
        hostYn: action.payload.data?.host_yn.at(0).host_yn,
      };
    case REQUEST(FETCH_FD_DAY_HOST_YN): // 조회 요청
      return {
        ...state,
        hostYnMessage: '',
        hostYnLoading: true,
        hostYnSuccess: false,
      };
    case FAILURE(FETCH_FD_DAY_HOST_YN): // 조회실패
      return {
        ...state,
        hostYn: '',
        hostYnLoading: false,
        hostYnSuccess: false,
        hostYnMessage: action.payload.response?.data?.message,
      };
    default:
      return state;
  }
}

/**
 * 계좌별 세입세출자금일계표 조회 ( 전문통신 )
 */
export const selectAnrvAnexFdDay = (searchParams) => {
  return {
    type: FETCH_ANRV_ANEX_FD_DAY,
    payload: fetchAnrvAnexFdDay(searchParams),
  };
};

/**
 * 계좌별 세입세출자금일계표 조회(기금) ( 전문통신 )
 */
export const selectAnrvAnexFdDayGg = (searchParams) => {
  return {
    type: FETCH_ANRV_ANEX_FD_DAY_GG,
    payload: fetchAnrvAnexFdDayGg(searchParams),
  };
};

/**
 *  용산구청 세입세출자금일계표 조회
 */
export const selectFdDayYs = (searchParams) => {
  return {
    type: FETCH_FD_DAY_YS,
    payload: fetchFdDayYs(searchParams),
  };
};

/**
 *  호스트거래여부조회
 */
export const selectFdDayHostYn = (searchParams) => {
  return {
    type: FETCH_FD_DAY_HOST_YN,
    payload: fetchFdDayHostYn(searchParams),
  };
};

/**
 *  본청 세입세출자금일계표 조회 ( 테이블조회 )
 */
export const selectFdDaySr = (searchParams) => {
  return {
    type: FETCH_FD_DAY_SR,
    payload: fetchFdDaySr(searchParams),
  };
};

/**
 *  구청 세입세출자금일계표 조회 ( 테이블조회 )
 */
export const selectFdDayGu = (searchParams) => {
  return {
    type: FETCH_FD_DAY_GU,
    payload: fetchFdDayGu(searchParams),
  };
};

/**
 *  세입세출자금일계표 조회(기금) ( 테이블조회 )
 */
export const selectFdDayGg = (searchParams) => {
  return {
    type: FETCH_FD_DAY_GU,
    payload: fetchFdDayGg(searchParams),
  };
};
