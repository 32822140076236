import instance from '../../common/config/api';

/**
 * 제로페이(관리자) - 사용자 전용앱 로그인 이력조회
 * @param {object} params
 * @returns
 */

export const fetchZpbUserXuseAppLginHstList = (params) =>
  instance.post(`${process.env.REACT_APP_SERVER_URL}/scq/scq/zpb/0002/selectUserXuseAppLginHst`, params);
