import { SUCCESS, REQUEST, FAILURE } from '../../../common/utils/action-type-utils';
import {
  fetchEtcEachBranchList,
  fetchEtcEachJingsusDtlList,
  fetchEtcEachJingsusSumrList,
  fetchEtcEachSunapList,
  fetchEtcEachSunapSlipOupList,
  fetchEtcExchageDateList,
  fetchEtcEachBranchSlipOupList,
  fetchEtcEachYearList,
} from '../../../lib/etc/etc0501v01.api';
import dayjs from 'dayjs';
import { fetchCommonComboList } from '../../../lib/cmm/combo.api';
import { fetchBrList } from './../../../lib/etc/etc0501m01.api';

// actions
const FETCH_EACH_BRANCH = 'etc0501v01/FETCH_EACH_BRANCH'; // 당행지점별 목록
const FETCH_EACH_SUNAP = 'etc0501v01/FETCH_EACH_SUNAP'; // 수납기관 목록
const FETCH_EACH_JINGSUSDTL = 'etc0501v01/FETCH_EACH_JINGSUSDTL'; // 징수기관 목록 (상세)
const FETCH_EACH_JINGSUSSUMR = 'etc0501v01/FETCH_EACH_JINGSUSSUMR'; // 징수기관 목록 (집계)
const FETCH_EXCHAGE_DATE = 'etc0501v01/FETCH_EXCHAGE_DATE'; // 징수기관 목록 (집계)
const FETCH_EACH_SUNAP_SLIPOUP = 'etc0501v01/FETCH_EACH_SUNAP_SLIPOUP'; // 수납기관 - 영수증 출력
const FETCH_EACH_BRANCH_SLIPOUP = 'etc0501v01/FETCH_EACH_BRANCH_SLIPOUP'; // 당행지점별 - 영수증 출력
const FETCH_EACH_YEAR = 'etc0501v01/FETCH_EACH_YEAR'; // 년간 목록

const FETCH_S_GIGWAN_LIST = 'etc0501v01/FETCH_S_GIGWAN_LIST';
const FETCH_J_GIGWAN_LIST = 'etc0501v01/FETCH_J_GIGWAN_LIST';
const FETCH_BR = 'etc0501v01/FETCH_BR'; // 영업점 콤보

const SAVE_SEARCH_FORM_VALUES = 'etc0501v01/SAVE_SEARCH_FORM_VALUES';
const RESET_STATE = 'etc0501v01/RESET_STATE';

export const initFormValues = {
  category: '1', // 라디오
  bas_ym1: dayjs().add(-1, 'month').format('YYYY-MM-DD'), // 세입일자
  bas_ym2: dayjs().format('YYYY-MM-DD'), // 세입일자
  gbn1: '1', // 구분1 (당행)
  ocryn: 'all', // 구분2
  s_gigwanid: 'all', // 수납기관 (수납기관)
  j_gigwanid: 'all', // 징수기관 (수납기관, 징수기관)
  gb: '1', // 출력구분(징수기관)
  sunapjijeom: 'all',
};

// initial state
const initialState = {
  loading: false,
  success: false,
  message: '',
  branchList: [], // 당행지점별 목록
  sunapList: [], // 수납기관 목록
  jingsusDtlList: [], // 징수기관 목록 (상세)
  jingsusSumrList: [], // 징수기관 목록 (집계)
  yearList: [], //년간 목록

  exchageDateLoading: false, // 세입일자
  exchageDateSuccess: false, // 세입일자
  exchageDateList: [], // 세입일자

  totalCount: 0,

  receiptLoading: false, // 영수증
  receiptSuccess: false, // 영수증
  receiptList: [], // 영수증
  receiptCount: 0, // 영수증
  // 콤보 - 수납기관
  sGigwanidLoading: false,
  sGigwanidList: [],
  sGigwanidSuccess: false,
  // 콤보 - 징수기관
  jGigwanidLoading: false,
  jGigwanidList: [],
  jGigwanidSuccess: false,
  brLoading: false, // 영업점 콤보
  brSuccess: false, // 영업점 콤보
  brList: [], // 영업점 콤보
  searchFormValues: {
    init: true,
    ...initFormValues,
  },
};

// reducers
export default function etc0501v01(state = initialState, action) {
  switch (action.type) {
    case SUCCESS(FETCH_EXCHAGE_DATE): // 조회 성공 - 세입일자
      return {
        ...state,
        exchageDateSuccess: true,
        exchageDateLoading: false,
        exchageDateList: action.payload.data?.chkdate,
      };
    case REQUEST(FETCH_EXCHAGE_DATE): // 조회 요청 - 세입일자
      return {
        ...state,
        exchageDateLoading: true,
        exchageDateSuccess: false,
      };
    case FAILURE(FETCH_EXCHAGE_DATE): // 조회 실패 - 세입일자
      return {
        ...initialState,
        exchageDateLoading: false,
        exchageDateSuccess: false,
        message: action.payload.response?.data?.message,
      };

    case SUCCESS(FETCH_EACH_BRANCH): // 조회 성공 - 당행지점별 목록
      return {
        ...state,
        success: true,
        loading: false,
        branchList: action.payload.data?.main,
        totalCount: action.payload.data?.main_cnt,
      };
    case REQUEST(FETCH_EACH_BRANCH): // 조회 요청 - 당행지점별 목록
      return {
        ...state,
        loading: true,
        success: false,
      };
    case FAILURE(FETCH_EACH_BRANCH): // 조회 실패 - 당행지점별 목록
      return {
        ...initialState,
        loading: false,
        success: false,
        message: action.payload.response?.data?.message,
      };
    case SUCCESS(FETCH_EACH_SUNAP): // 조회 성공 - 수납기관 목록
      return {
        ...state,
        success: true,
        loading: false,
        sunapList: action.payload.data?.main,
        totalCount: action.payload.data?.main_cnt,
      };
    case REQUEST(FETCH_EACH_SUNAP): // 조회 요청 - 수납기관 목록
      return {
        ...state,
        loading: true,
        success: false,
      };
    case FAILURE(FETCH_EACH_SUNAP): // 조회 실패 - 수납기관 목록
      return {
        ...initialState,
        loading: false,
        success: false,
        message: action.payload.response?.data?.message,
      };
    case SUCCESS(FETCH_EACH_JINGSUSDTL): // 조회 성공 - 징수기관 목록 (상세)
      return {
        ...state,
        success: true,
        loading: false,
        jingsusDtlList: action.payload.data?.main,
        totalCount: action.payload.data?.main_cnt,
      };
    case REQUEST(FETCH_EACH_JINGSUSDTL): // 조회 요청 - 징수기관 목록 (상세)
      return {
        ...state,
        loading: true,
        success: false,
      };
    case FAILURE(FETCH_EACH_JINGSUSDTL): // 조회 실패 - 징수기관 목록 (상세)
      return {
        ...initialState,
        loading: false,
        success: false,
        message: action.payload.response?.data?.message,
      };
    case SUCCESS(FETCH_EACH_JINGSUSSUMR): // 조회 성공 - 징수기관 목록 (집계)
      return {
        ...state,
        success: true,
        loading: false,
        jingsusSumrList: action.payload.data?.main2,
        totalCount: action.payload.data?.main2_cnt,
      };
    case REQUEST(FETCH_EACH_JINGSUSSUMR): // 조회 요청 - 징수기관 목록 (집계)
      return {
        ...state,
        loading: true,
        success: false,
      };
    case FAILURE(FETCH_EACH_JINGSUSSUMR): // 조회 실패 - 징수기관 목록 (집계)
      return {
        ...initialState,
        loading: false,
        success: false,
        message: action.payload.response?.data?.message,
      };
    case SUCCESS(FETCH_EACH_SUNAP_SLIPOUP): // 영수증 조회 성공
      return {
        ...state,
        receiptSuccess: true,
        receiptLoading: false,
        receiptList: action.payload.data?.main1,
        receiptCount: action.payload.data?.main1_cnt,
      };
    case REQUEST(FETCH_EACH_SUNAP_SLIPOUP): // 영수증 조회 요청
      return {
        ...state,
        receiptLoading: true,
        receiptSuccess: false,
      };
    case FAILURE(FETCH_EACH_SUNAP_SLIPOUP): // 영수증 조회 실패
      return {
        ...state,
        receiptList: [],
        receiptLoading: false,
        receiptSuccess: false,
        message: action.payload.response?.data?.message,
      };
    case SUCCESS(FETCH_EACH_BRANCH_SLIPOUP): // 당행지점별 영수증 조회 성공
      return {
        ...state,
        receiptSuccess: true,
        receiptLoading: false,
        receiptList: action.payload.data?.main1,
        receiptCount: action.payload.data?.main1_cnt,
      };
    case REQUEST(FETCH_EACH_BRANCH_SLIPOUP): //당행지점별 영수증 조회 요청
      return {
        ...state,
        receiptLoading: true,
        receiptSuccess: false,
      };
    case FAILURE(FETCH_EACH_BRANCH_SLIPOUP): //당행지점별 영수증 조회 실패
      return {
        ...state,
        receiptList: [],
        receiptLoading: false,
        receiptSuccess: false,
        message: action.payload.response?.data?.message,
      };
    case SUCCESS(FETCH_S_GIGWAN_LIST): // 조회 성공
      return {
        ...state,
        sGigwanidSuccess: true,
        sGigwanidLoading: false,
        sGigwanidList: action.payload.data?.subDto,
      };
    case REQUEST(FETCH_S_GIGWAN_LIST): // 조회 요청
      return {
        ...state,
        sGigwanidLoading: true,
        sGigwanidSuccess: false,
      };
    case FAILURE(FETCH_S_GIGWAN_LIST): // 조회실패
      return {
        ...state,
        sGigwanidLoading: false,
        sGigwanidSuccess: false,
        sGigwanidList: [],
      };
    case SUCCESS(FETCH_J_GIGWAN_LIST): // 조회 성공
      return {
        ...state,
        jGigwanidSuccess: true,
        jGigwanidLoading: false,
        jGigwanidList: action.payload.data?.subDto,
      };
    case REQUEST(FETCH_J_GIGWAN_LIST): // 조회 요청
      return {
        ...state,
        jGigwanidLoading: true,
        jGigwanidSuccess: false,
      };
    case FAILURE(FETCH_J_GIGWAN_LIST): // 조회실패
      return {
        ...state,
        jGigwanidLoading: false,
        jGigwanidSuccess: false,
        jGigwanidList: [],
      };
    case SUCCESS(FETCH_EACH_YEAR): // 조회 성공 - 년간 목록
      return {
        ...state,
        success: true,
        loading: false,
        yearList: action.payload.data?.main,
        totalCount: action.payload.data?.main_cnt,
      };
    case REQUEST(FETCH_EACH_YEAR): // 조회 요청 - 년간 목록
      return {
        ...state,
        loading: true,
        success: false,
      };
    case FAILURE(FETCH_EACH_YEAR): // 조회 실패 - 년간 목록
      return {
        ...initialState,
        loading: false,
        success: false,
        message: action.payload.response?.data?.message,
      };
    case SUCCESS(FETCH_BR): // 조회 성공 - 영업점 콤보
      return {
        ...state,
        brSuccess: true,
        brLoading: false,
        brList: action.payload.data?.main,
      };
    case REQUEST(FETCH_BR): // 조회 요청 - 영업점 콤보
      return {
        ...state,
        brLoading: true,
        brSuccess: false,
      };
    case FAILURE(FETCH_BR): // 조회 실패 - 영업점 콤보
      return {
        ...state,
        brLoading: false,
        brSuccess: false,
        brList: [],
      };
    case SAVE_SEARCH_FORM_VALUES:
      return {
        ...state,
        searchFormValues: action.payload,
      };
    case RESET_STATE:
      return initialState;
    default:
      return state;
  }
}

/**
 * 기타 - 손해배상금 - 집계표 (세입일자)
 */
export const selectEtcExchageDateList = () => {
  return {
    type: FETCH_EXCHAGE_DATE,
    payload: fetchEtcExchageDateList(),
  };
};

/**
 * 기타 - 손해배상금 - 집계표 (당행지점별 목록)
 */
export const selectEtcEachBranchList = (searchParams) => {
  return {
    type: FETCH_EACH_BRANCH,
    payload: fetchEtcEachBranchList(searchParams),
  };
};

/**
 * 기타 - 손해배상금 - 집계표 (수납기관 목록)
 */
export const selectEtcEachSunapList = (searchParams) => {
  return {
    type: FETCH_EACH_SUNAP,
    payload: fetchEtcEachSunapList(searchParams),
  };
};

/**
 * 기타 - 손해배상금 - 집계표 (징수기관 목록 (상세))
 */
export const selectEtcEachJingsusDtlList = (searchParams) => {
  return {
    type: FETCH_EACH_JINGSUSDTL,
    payload: fetchEtcEachJingsusDtlList(searchParams),
  };
};

/**
 * 기타 - 손해배상금 - 집계표 (징수기관 목록 (집계))
 */
export const selectEtcEachJingsusSumrList = (searchParams) => {
  return {
    type: FETCH_EACH_JINGSUSSUMR,
    payload: fetchEtcEachJingsusSumrList(searchParams),
  };
};

/**
 * 기타 - 손해배상금 - 집계표 (수납기관 - 영수증 출력)
 */
export const selectEtcEachSunapSlipOupList = (searchParams) => {
  return {
    type: FETCH_EACH_SUNAP_SLIPOUP,
    payload: fetchEtcEachSunapSlipOupList(searchParams),
  };
};

/**
 * 기타 - 손해배상금 - 집계표 (당행지점별 - 영수증 출력)
 */
export const selectEtcEachBranchSlipOupList = (searchParams) => {
  return {
    type: FETCH_EACH_BRANCH_SLIPOUP,
    payload: fetchEtcEachBranchSlipOupList(searchParams),
  };
};

/**
 * 기타 - 손해배상금 - 년간
 */
export const selectEtcEachYearList = (searchParams) => {
  return {
    type: FETCH_EACH_YEAR,
    payload: fetchEtcEachYearList(searchParams),
  };
};

/**
 * 수납기관 콤보 조회
 */
export const selectSGigwanIdComboList = (searchParams) => {
  return {
    type: FETCH_S_GIGWAN_LIST,
    payload: fetchCommonComboList({ slt_box_no: '010', ...searchParams }),
  };
};

/**
 * 징수기관 콤보 조회
 */
export const selectJGigwanIdComboList = (searchParams) => {
  return {
    type: FETCH_J_GIGWAN_LIST,
    payload: fetchCommonComboList({ slt_box_no: '026', ...searchParams }),
  };
};

/**
 * 기타 - 손해배상금 - Data Correction (징수기관 콤보)
 */
export const selectBrList = (searchParams) => {
  return {
    type: FETCH_BR,
    payload: fetchBrList(searchParams),
  };
};


/**
 * 조회조건 저장
 */
export const saveSearchFormValues = (searchFormValues) => {
  return {
    type: SAVE_SEARCH_FORM_VALUES,
    payload: {
      ...searchFormValues,
      init: false,
    },
  };
};

/**
 * 집계표 상태 초기화
 */
export const resetEtc0501v01 = () => {
  return {
    type: RESET_STATE,
  };
};
