import { SUCCESS, REQUEST, FAILURE } from '../../../common/utils/action-type-utils';
import {
  fetchYearAvbl,
  fetchSeoulAvbl,
  fetchYearAvblMain,
  fetchMmAvblMain1,
  fetchMmAvblMain2,
  fetchYearAvblMainHq,
  fetchMmAvblMainHq1,
  fetchMmAvblMainHq2,
} from '../../../lib/rpt/RPT0006.api';
import { fetchBrNmList } from '../../../lib/cmm/brNm.api';

// actions
const FETCH_YEAR_AVBL = 'RPT0603V01/FETCH_YEAR_AVBL';
const FETCH_SEOUL_AVBL = 'RPT0603V01/FETCH_SEOUL_AVBL';
const FETCH_YEAR_AVBL_MAIN = 'RPT0603V01/FETCH_YEAR_AVBL_MAIN';
const FETCH_MM_AVBL_MAIN_1 = 'RPT0603V01/FETCH_MM_AVBL_MAIN_1';
const FETCH_MM_AVBL_MAIN_2 = 'RPT0603V01/FETCH_MM_AVBL_MAIN_2';
const FETCH_YEAR_AVBL_MAIN_HG = 'RPT0603V01/FETCH_YEAR_AVBL_MAIN_HG';
const FETCH_MM_AVBL_MAIN_HG1 = 'RPT0603V01/FETCH_MM_AVBL_MAIN_HG1';
const FETCH_MM_AVBL_MAIN_HG2 = 'RPT0603V01/FETCH_MM_AVBL_MAIN_HG2';
const FETCH_BR_NM_LIST = 'RPT0603V01/FETCH_BR_NM_LIST';

// initial state
const initialState = {
  loading: false,
  success: false,
  message: '',
  result: [],

  accList: {},
  accLoading: false,
  accSuccess: false,
  accMessage: '',

  brNmList: [],
  brNmLoading: false,
  brNmSuccess: false,
  brNmMessage: '',
};

// reducers
export default function RPT0603V01(state = initialState, action) {
  switch (action.type) {
    //서울시청 회계조회
    case SUCCESS(FETCH_SEOUL_AVBL): // 조회 성공
      return {
        ...state,
        accSuccess: true,
        accLoading: false,
        //accList: action.payload.data?.acc_no_000,
        accList: action.payload.data,
      };
    case REQUEST(FETCH_SEOUL_AVBL): // 조회 요청
      return {
        ...state,
        accMessage: '',
        accLoading: true,
        accSuccess: false,
      };
    case FAILURE(FETCH_SEOUL_AVBL): // 조회실패
      return {
        ...state,
        accList: [],
        accLoading: false,
        accSuccess: false,
        accMessage: action.payload.response?.data?.message,
      };
    //FETCH_YEAR_AVBL_MAIN_HG

    //서울시청외 회계조회
    case SUCCESS(FETCH_YEAR_AVBL): // 조회 성공
      return {
        ...state,
        accSuccess: true,
        accLoading: false,
        //accList: action.payload.data?.acc_no,
        accList: action.payload.data,
      };
    case REQUEST(FETCH_YEAR_AVBL): // 조회 요청
      return {
        ...state,
        accMessage: '',
        accLoading: true,
        accSuccess: false,
      };
    case FAILURE(FETCH_YEAR_AVBL): // 조회실패
      return {
        ...state,
        accList: [],
        accLoading: false,
        accSuccess: false,
        accMessage: action.payload.response?.data?.message,
      };
    //서울시청외 회계조회
    case SUCCESS(FETCH_YEAR_AVBL_MAIN):
    case SUCCESS(FETCH_MM_AVBL_MAIN_1):
    case SUCCESS(FETCH_MM_AVBL_MAIN_2):
    case SUCCESS(FETCH_YEAR_AVBL_MAIN_HG):
    case SUCCESS(FETCH_MM_AVBL_MAIN_HG1):
    case SUCCESS(FETCH_MM_AVBL_MAIN_HG2): // 조회 성공
      return {
        ...state,
        success: true,
        loading: false,
        //accList: action.payload.data?.acc_no,
        result: action.payload.data,
      };
    case REQUEST(FETCH_YEAR_AVBL_MAIN):
    case REQUEST(FETCH_MM_AVBL_MAIN_1):
    case REQUEST(FETCH_MM_AVBL_MAIN_2):
    case REQUEST(FETCH_YEAR_AVBL_MAIN_HG):
    case REQUEST(FETCH_MM_AVBL_MAIN_HG1):
    case REQUEST(FETCH_MM_AVBL_MAIN_HG2): // 조회 요청
      return {
        ...state,
        message: '',
        loading: true,
        success: false,
      };
    case FAILURE(FETCH_YEAR_AVBL_MAIN):
    case FAILURE(FETCH_MM_AVBL_MAIN_1):
    case FAILURE(FETCH_MM_AVBL_MAIN_2):
    case FAILURE(FETCH_YEAR_AVBL_MAIN_HG):
    case FAILURE(FETCH_MM_AVBL_MAIN_HG1):
    case FAILURE(FETCH_MM_AVBL_MAIN_HG2): // 조회실패
      return {
        ...state,
        result: [],
        loading: false,
        success: false,
        message: action.payload.response?.data?.message,
      };

    default:
      return state;
  }
}

/**
 * 연/월평잔 회계구하기
 */
export const selectYearAvbl = (searchParams) => {
  return {
    type: FETCH_YEAR_AVBL,
    payload: fetchYearAvbl(searchParams),
  };
};

/**
 * 본청 평잔 회계구하기
 */
export const selectSeoulAvbl = (searchParams) => {
  return {
    type: FETCH_SEOUL_AVBL,
    payload: fetchSeoulAvbl(searchParams),
  };
};

/**
 * 연평잔 메인쿼리
 */
export const selectYearAvblMain = (searchParams) => {
  return {
    type: FETCH_YEAR_AVBL_MAIN,
    payload: fetchYearAvblMain(searchParams),
  };
};

/**
 * 월평잔 메인쿼리
 */
export const selectMmAvblMain1 = (searchParams) => {
  return {
    type: FETCH_MM_AVBL_MAIN_1,
    payload: fetchMmAvblMain1(searchParams),
  };
};

/**
 * 월평잔 메인쿼리2
 */
export const selectMmAvblMain2 = (searchParams) => {
  return {
    type: FETCH_MM_AVBL_MAIN_2,
    payload: fetchMmAvblMain2(searchParams),
  };
};

/**
 * 연평잔 메인 본청
 */
export const selectYearAvblMainHq = (searchParams) => {
  return {
    type: FETCH_YEAR_AVBL_MAIN_HG,
    payload: fetchYearAvblMainHq(searchParams),
  };
};

/**
 * 월평잔 메인 본청1
 */
export const selectMmAvblMainHq1 = (searchParams) => {
  return {
    type: FETCH_MM_AVBL_MAIN_HG1,
    payload: fetchMmAvblMainHq1(searchParams),
  };
};

/**
 * 월평잔 메인 본청2
 */
export const selectMmAvblMainHq2 = (searchParams) => {
  return {
    type: FETCH_MM_AVBL_MAIN_HG2,
    payload: fetchMmAvblMainHq2(searchParams),
  };
};

/**
 * 거래점명조회
 */
export const selectBrNmList = () => {
  return {
    type: FETCH_BR_NM_LIST,
    payload: fetchBrNmList(),
  };
};
