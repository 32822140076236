import instance from '../../common/config/api';

/**
 * 기타 - 지급통지서 출력
 * @param {object} params
 * @returns
 */

export const fetchEtcPayNtcdList = (params) =>
  instance.post(`${process.env.REACT_APP_SERVER_URL}/scq/scq/etc/0001/selectPayNtcd`, params);
