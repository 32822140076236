import instance from '../../common/config/api';

/**
 * 금고영업점 - 지자체 정보관리 - 지자체 정보 등록 (조회)
 * @param {object} params
 * @returns
 */

export const fetchIstInfoList = (params) =>
  instance.post(`${process.env.REACT_APP_SERVER_URL}/scq/scq/brc/0002/selectIstInfoList`, params);

/**
 * 금고영업점 - 지자체 정보관리 - 지자체 정보 등록 (등록, 수정)
 * @param {object} params
 * @returns
 */

export const insertIstInfoList = (dto) =>
  instance.post(`${process.env.REACT_APP_SERVER_URL}/scq/scq/brc/0002/insertIstInfo`, {
    subDto: [dto],
  });

/**
 * 금고영업점 - 지자체 정보관리 - 지자체 정보 등록 pk채번
 */
export const fetchIstInfoPkNmgt = () =>
  instance.post(`${process.env.REACT_APP_SERVER_URL}/scq/scq/brc/0002/selectIstInfoPkNmgt `);
