import { SUCCESS, REQUEST, FAILURE } from '../../../common/utils/action-type-utils';
import { fetchZpayApdtAndExuNacrd } from '../../../lib/zpb/zbp0102v01.api';
import dayjs from 'dayjs';
import { fetchCommonComboList } from '../../../lib/cmm/combo.api';

// actions
const FETCH_ZPAY_APDT_AND_EXU_NARCD = 'zpb0102v01/FETCH_ZPAY_APDT_AND_EXU_NARCD';
//기관
const FETCH_IST_INFO_LIST = 'zpb0102v01/FETCH_IST_INFO_LIST';
//계좌번호
const FETCH_ACT_NO_LIST = 'zpb0102v01/FETCH_ACT_NO_LIST';
//회계연도
const FETCH_ACC_YR_LIST = 'zpb0102v01/FETCH_ACC_YR_LIST';
const RESET_STATE = 'zpb0102v01/RESET_STATE';
const SAVE_SEARCH_FORM_VALUES = 'zpb0102v01/SAVE_SEARCH_FORM_VALUES';

export const initFormValues = {
  ydcs8a1_t8a01_br_cd: 'all', //기관명
  ydcs8a1_t8a01_act_no: 'all', //일상경비계좌
  ydcs8a1_t8a01_cmpl_yn: '3', //일치/불일치 1 전체 2 일치 3 불일치
  ydcs8a1_t8a01_inq_sdt: dayjs().format('YYYY-MM-DD'), //승인일자(시작)
  ydcs8a1_t8a01_inq_edt: dayjs().format('YYYY-MM-DD'), //승인일자(종료)
  ydcs8a1_t8a01_acc_yr: dayjs().format('YYYY'), //회계연도
};

// initial state
const initialState = {
  loading: false,
  success: false,
  message: '',
  list: [],
  totalCount: 0,
  //콤보 - 기관명
  istInfoList: [],
  istInfoLoading: false,
  istInfoSuccess: true,
  //계좌번호
  actNoLoading: false,
  actNoList: [],
  actNoSuccess: false,
  // 회계연도
  accYrLoading: false,
  accYrList: [],
  accYrSuccess: false,
  searchFormValues: {
    init: true,
    ...initFormValues,
  },
};

// reducers
export default function zpb0102v01(state = initialState, action) {
  switch (action.type) {
    case SUCCESS(FETCH_ZPAY_APDT_AND_EXU_NARCD): // 조회 성공
      return {
        ...state,
        success: true,
        loading: false,
        list: action.payload.data?.output_data,
        totalCount: action.payload?.output_data_cnt,
      };
    case REQUEST(FETCH_ZPAY_APDT_AND_EXU_NARCD): // 조회 요청
      return {
        ...state,
        loading: true,
        success: false,
      };
    case FAILURE(FETCH_ZPAY_APDT_AND_EXU_NARCD): // 조회실패
      return {
        ...initialState,
        loading: false,
        success: false,
        message: action.payload.response?.data?.message,
      };
    case SUCCESS(FETCH_IST_INFO_LIST): // 조회 성공
      return {
        ...state,
        istInfoSuccess: true,
        istInfoLoading: false,
        istInfoList: action.payload.data?.subDto,
      };
    case REQUEST(FETCH_IST_INFO_LIST): // 조회 요청
      return {
        ...state,
        istInfoLoading: true,
        istInfoSuccess: false,
      };
    case FAILURE(FETCH_IST_INFO_LIST): // 조회실패
      return {
        istInfoLoading: false,
        istInfoSuccess: false,
        istInfoList: [],
      };
    case SUCCESS(FETCH_ACC_YR_LIST): // 조회 성공
      return {
        ...state,
        accYrSuccess: true,
        accYrLoading: false,
        accYrList: action.payload.data?.subDto,
      };
    case REQUEST(FETCH_ACC_YR_LIST): // 조회 요청
      return {
        ...state,
        accYrLoading: true,
        accYrSuccess: false,
      };
    case FAILURE(FETCH_ACC_YR_LIST): // 조회실패
      return {
        ...state,
        accYrLoading: false,
        accYrSuccess: false,
        accYrList: [],
      };
    //계좌번호
    case SUCCESS(FETCH_ACT_NO_LIST): // 조회 성공
      return {
        ...state,
        actNoSuccess: true,
        actNoLoading: false,
        actNoList: action.payload.data?.subDto,
      };
    case REQUEST(FETCH_ACT_NO_LIST): // 조회 요청
      return {
        ...state,
        actNoLoading: true,
        actNoSuccess: false,
      };
    case FAILURE(FETCH_ACT_NO_LIST): // 조회실패
      return {
        ...state,
        actNoLoading: false,
        actNoSuccess: false,
        actNoList: [],
      };
    case SAVE_SEARCH_FORM_VALUES:
      return {
        ...state,
        // 콤보 - 기관
        istInfoSuccess: false,
        istInfoLoading: false,
        // 콤보 - 계좌번호
        mbrNoSuccess: false,
        mbrNoLoading: false,
        // 콤보 -회계연도
        accYrSuccess: false,
        actNoLoading: false,
        searchFormValues: action.payload,
      };
    case RESET_STATE:
      return initialState;
    default:
      return state;
  }
}

/**
 * 제로페이 - 승인•집행 불일치조회
 */
export const selectZpayApdtAndExuNacrd = (searchParams) => {
  return {
    type: FETCH_ZPAY_APDT_AND_EXU_NARCD,
    payload: fetchZpayApdtAndExuNacrd(searchParams),
  };
};
/**
 * 기관명 콤보 조회
 */
export const selectIstInfoComboList = (searchParams) => {
  return {
    type: FETCH_IST_INFO_LIST,
    payload: fetchCommonComboList({ slt_box_no: '005', ...searchParams }),
  };
};
/**
 * 회계연도 콤보 조회
 */
export const selectAccYrComboList = (searchParams) => {
  return {
    type: FETCH_ACC_YR_LIST,
    payload: fetchCommonComboList({ slt_box_no: '025', ...searchParams }),
  };
};
/**
 * 계좌번호 콤보 조회
 */
export const selectActNoComboList = (searchParams) => {
  return {
    type: FETCH_ACT_NO_LIST,
    payload: fetchCommonComboList({ slt_box_no: '196', ...searchParams }),
  };
};
/**
 * 조회조건 저장
 */
export const saveSearchFormValues = (searchFormValues) => {
  return {
    type: SAVE_SEARCH_FORM_VALUES,
    payload: {
      ...searchFormValues,
      init: false,
    },
  };
};

/**
 * 승인·집행 불일치 상태초기화
 */
export const resetZpbApvExuNacrd = () => {
  return {
    type: RESET_STATE,
  };
};
