import { fetchAllHis, fetchItpyPts, fetchItpyHis } from '../../../lib/fxp/FXP0001.api';
import { SUCCESS, REQUEST, FAILURE } from '../../../common/utils/action-type-utils';
import dayjs from 'dayjs';
import { fetchCommonComboList } from '../../../lib/cmm/combo.api';

// actions
const FETCH_All_HIS = 'FXP0101V02/FETCH_All_HIS';
// const FETCH_ITPY_PTS = 'FXP0101V02/FETCH_ITPY_PTS';
const FETCH_ITPY_HIS = 'FXP0101V02/FETCH_ITPY_HIS';

const FETCH_IST_INFO_LIST = 'RtrnAmRcvTrhs/FETCH_IST_INFO_LIST';
const FETCH_DEPT_INFO_LIST = 'RtrnAmRcvTrhs/FETCH_DEPT_INFO_LIST';
const FETCH_ACC_YR_LIST = 'RtrnAmRcvTrhs/FETCH_ACC_YR_LIST';
const FETCH_ACT_NO_LIST = 'RtrnAmRcvTrhs/FETCH_ACT_NO_LIST';

const RESET_INITIAL_STATE = 'RtrnAmRcvTrhs/RESET_INITIAL_STATE';
const SAVE_SEARCH_FORM_VALUES = 'RtrnAmRcvTrhs/SAVE_SEARCH_FORM_VALUES';

const CLEAR_MESSAGE = 'FXP0101V02/CLEAR_MESSAGE';
const SET_BEFORE_SEARCH_PARAM = 'RtrnAmRcvTrhs/SET_BEFORE_SEARCH_PARAM';

export const initFormValues = {
  cus_ist_cd: '6110000',
  cus_dept_cd: 'all',
  ydcs8a1_t8a11_act_dscd: '1',
  ydcs8a1_t8a11_act_no: 'all',
  ydcs8a1_t8a11_inq_sdt: dayjs().add(-28, 'day').format('YYYY.MM.DD'),
  ydcs8a1_t8a11_inq_edt: dayjs().format('YYYY.MM.DD'),
  ydcs8a1_t8a01_acc_yr: dayjs().format('YYYY'),
  pmdp_act_dscd: '02',
  pgm_dis: '138K-02', //IF ID
};

// initial state
const initialState = {
  loading: false,
  success: false,
  message: '',
  list: [],
  outputTitle: {},
  totalCount: 0,
  detailsLoading: false,
  detailsSuccess: false,
  detailsMessage: '',
  details: [],
  beforeSearchParam: {},

  //콤보 - 기관명 005
  istInfoSuccess: false,
  istInfoLoading: false,
  istInfoList: [],

  //콤보 - 부서명 039
  deptInfoSuccess: false,
  deptInfoLoading: false,
  deptInfoList: [],

  //콤보 - 계좌번호 065
  actNoSuccess: false,
  actNoLoading: false,
  actNoList: [],

  //콤보 - 회계연도 025
  accYrSuccess: false,
  accYrLoading: false,
  accYrList: [],

  // 검색조건
  searchFormValues: {
    init: true,
    ...initFormValues,
  },
};

// reducers
export default function FXP0101V02(state = initialState, action) {
  switch (action.type) {
    case SUCCESS(FETCH_All_HIS): // 조회 성공
      return {
        ...state,
        success: true,
        loading: false,
        list: action.payload.data?.output_data,
        outputTitle: action.payload.data?.output_title,
        totalCount: action.payload.data?.output_data_cnt,
      };
    case REQUEST(FETCH_All_HIS): // 조회 요청
      return {
        ...state,
        loading: true,
      };
    case FAILURE(FETCH_All_HIS): // 조회실패
      return {
        ...state,
        list: [],
        loading: false,
        success: false,
        message: action.payload.response?.data?.message,
      };
    case SUCCESS(FETCH_ITPY_HIS): // 조회 성공
      return {
        ...state,
        detailsSuccess: true,
        detailsLoading: false,
        details: action.payload.data?.output_data,
      };
    case REQUEST(FETCH_ITPY_HIS): // 조회 요청
      return {
        ...state,
        detailsLoading: true,
        detailsMessage: '',
      };
    case FAILURE(FETCH_ITPY_HIS): // 조회실패
      return {
        ...state,
        details: [],
        detailsLoading: false,
        detailsSuccess: false,
        detailsMessage: action.payload.response?.data?.message,
      };
    // 기관명
    case SUCCESS(FETCH_IST_INFO_LIST): // 조회 성공
      return {
        ...state,
        istInfoSuccess: true,
        istInfoLoading: false,
        istInfoList: action.payload.data?.subDto,
      };
    case REQUEST(FETCH_IST_INFO_LIST): // 조회 요청
      return {
        ...state,
        istInfoLoading: true,
        istInfoSuccess: false,
      };
    case FAILURE(FETCH_IST_INFO_LIST): // 조회실패
      return {
        istInfoLoading: false,
        istInfoSuccess: false,
        istInfoList: [],
      };
    // 부서명
    case SUCCESS(FETCH_DEPT_INFO_LIST): // 조회 성공
      return {
        ...state,
        deptInfoSuccess: true,
        deptInfoLoading: false,
        deptInfoList: action.payload.data?.subDto,
      };
    case REQUEST(FETCH_DEPT_INFO_LIST): // 조회 요청
      return {
        ...state,
        deptInfoLoading: true,
        deptInfoSuccess: false,
      };
    case FAILURE(FETCH_DEPT_INFO_LIST): // 조회실패
      return {
        ...state,
        deptInfoLoading: false,
        deptInfoSuccess: false,
        deptInfoList: [],
      };
    // 계좌번호
    case SUCCESS(FETCH_ACT_NO_LIST): // 조회 성공
      return {
        ...state,
        actNoSuccess: true,
        actNoLoading: false,
        actNoList: action.payload.data?.subDto,
      };
    case REQUEST(FETCH_ACT_NO_LIST): // 조회 요청
      return {
        ...state,
        actNoLoading: true,
        actNoSuccess: false,
      };
    case FAILURE(FETCH_ACT_NO_LIST): // 조회실패
      return {
        ...state,
        actNoLoading: false,
        actNoSuccess: false,
        actNoList: [],
      };
    // 회계연도
    case SUCCESS(FETCH_ACC_YR_LIST): // 조회 성공
      return {
        ...state,
        accYrSuccess: true,
        accYrLoading: false,
        accYrList: action.payload.data?.subDto,
      };
    case REQUEST(FETCH_ACC_YR_LIST): // 조회 요청
      return {
        ...state,
        accYrLoading: true,
        accYrSuccess: false,
      };
    case FAILURE(FETCH_ACC_YR_LIST): // 조회실패
      return {
        ...state,
        accYrLoading: false,
        accYrSuccess: false,
        accYrList: [],
      };
    case SAVE_SEARCH_FORM_VALUES:
      return {
        ...state,
        istInfoSuccess: false,
        istInfoLoading: false,
        deptInfoSuccess: false,
        deptInfoLoading: false,
        actNoSuccess: false,
        actNoLoading: false,
        accYrSuccess: false,
        accYrLoading: false,

        searchFormValues: action.payload,
      };
    case RESET_INITIAL_STATE:
      return initialState;
    case SET_BEFORE_SEARCH_PARAM:
      return {
        ...state,
        beforeSearchParam: action.payload,
      };
    case CLEAR_MESSAGE:
      return {
        ...state,
        message: '',
        detailsMessage: '',
      };
    default:
      return state;
  }
}

/**
 * 전체명세 조회
 */
export const selectAllHis = (searchParams) => {
  return {
    type: FETCH_All_HIS,
    payload: fetchAllHis(searchParams),
  };
};

/**
 * 이자지급명세 조회
 */
export const selectItpyHis = (searchParams) => {
  return {
    type: FETCH_ITPY_HIS,
    payload: fetchItpyHis(searchParams),
  };
};

/**
 * 메시지초기화
 */
export const clearMessage = () => {
  return {
    type: CLEAR_MESSAGE,
  };
};

/**
 *
 */
export const setBeforeSearchParam = (searchParams) => {
  return {
    type: SET_BEFORE_SEARCH_PARAM,
    payload: searchParams,
  };
};

//기관명 콤보 조회
export const selectIstInfoList = (searchParam) => {
  return {
    type: FETCH_IST_INFO_LIST,
    payload: fetchCommonComboList({ slt_box_no: '005', ...searchParam }),
  };
};

//부서명 콤보 조회
export const selectDeptInfoList = (searchParam) => {
  return {
    type: FETCH_DEPT_INFO_LIST,
    payload: fetchCommonComboList({ slt_box_no: '039', ...searchParam }),
  };
};

//회계연도 콤보 조회
export const selectAccYrList = () => {
  return {
    type: FETCH_ACC_YR_LIST,
    payload: fetchCommonComboList({ slt_box_no: '025' }),
  };
};

//계좌번호 콤보 조회
export const selectActNoList = (searchParam) => {
  return {
    type: FETCH_ACT_NO_LIST,
    payload: fetchCommonComboList({ slt_box_no: '065', ...searchParam }),
  };
};

/**
 * 조회조건 저장
 */
export const saveSearchFormValues = (searchFormValues) => {
  return {
    type: SAVE_SEARCH_FORM_VALUES,
    payload: {
      ...searchFormValues,
      init: false,
    },
  };
};

/**
 * 상태초기화
 */
export const resetInitialState = () => {
  return {
    type: RESET_INITIAL_STATE,
  };
};
