import { fetchCommonComboList } from '../../../lib/cmm/combo.api';
import { SUCCESS, REQUEST, FAILURE } from '../../../common/utils/action-type-utils';

// actions
const FETCH_COMBO = (sltBoxNo) => `combo/FETCH_COMMON_COMBO_${sltBoxNo}`;

// initial state
const initialState = {
  options: {},
  loading: {},
  success: {},
};

// reducers
export default function combo(state = initialState, action) {
  let sltBoxNo;
  if (action.type.startsWith('combo')) {
    sltBoxNo = action.type.substring(25, 28);
  }
  switch (action.type) {
    case REQUEST(FETCH_COMBO(sltBoxNo)): // 조회 요청
      return {
        ...state,
        success: { ...state.loading, [`CB${sltBoxNo}`]: false },
        loading: { ...state.loading, [`CB${sltBoxNo}`]: true },
        options: { ...state.options, [`CB${sltBoxNo}`]: [] },
      };
    case SUCCESS(FETCH_COMBO(sltBoxNo)): // 조회 성공
      return {
        ...state,
        success: { ...state.success, [`CB${sltBoxNo}`]: true },
        loading: { ...state.loading, [`CB${sltBoxNo}`]: false },
        options: {
          ...state.options,
          [`CB${sltBoxNo}`]: (() => {
            const options = action.payload.data?.subDto;
            const filterDuplicateOptions = options?.filter(
              (item, index) => options.findIndex((item2) => item.code === item2.code) === index
            );

            return filterDuplicateOptions;
          })(),
        },
      };
    case FAILURE(FETCH_COMBO(sltBoxNo)): // 조회 실패
      return {
        ...state,
        success: { ...state.success, [`CB${sltBoxNo}`]: false },
        loading: { ...state.loading, [`CB${sltBoxNo}`]: false },
        options: { ...state.options, [`CB${sltBoxNo}`]: [] },
      };

    default:
      return state;
  }
}

/**
 * 공통콤보 조회
 */
export const selectCommonComboList = (searchParams) => async (dispatch) => {
  return dispatch({
    type: FETCH_COMBO(searchParams.slt_box_no),
    payload: fetchCommonComboList(searchParams),
  });
};
