import instance from '../../common/config/api';

/**
 * 사용자 유형별 사이트 권한 조회
 * @param {object} params
 * @returns
 */
export const fetchSitePrsnMngByUserTypeList = (params) =>
  instance.post(`${process.env.REACT_APP_SERVER_URL}/scq/scq/sys/0003/selectSitePrsnMngByUserTypeList`, params);

/**
 * 사용자 유형별 사이트 권한 등록
 * @param {array} dtos
 * @returns
 */
export const updateSitePrsnMngByUserType = (dtos) =>
  instance.post(`${process.env.REACT_APP_SERVER_URL}/scq/scq/sys/0003/insertSitePrsnMngByUserType`, {
    subDto: dtos,
  });

/**
 * 사용자 유형별 사이트 권한 등록
 * @param {array} dtos
 * @returns
 */
export const fetchSitePrsnMngByUserTypePkNmgt = (dtos) =>
  instance.post(`${process.env.REACT_APP_SERVER_URL}/scq/scq/sys/0003/selectSitePrsnMngByUserTypePkNmgt`);
