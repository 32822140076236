import instance from '../../common/config/api';

/**
 * 제로페이 - 공공기관 회계담당 번호등록
 * @param {object} params
 * @returns
 */

export const fetchZpbPbinAcctChrgNoRgsList = (params) =>
  instance.post(`${process.env.REACT_APP_SERVER_URL}/scq/scq/zpb/0002/selectPbinAcctChrgNoRgs`, params);

/**
 * 제로페이 - 공공기관 회계담당 번호등록 - 등록/수정/삭제
 * @param {object} params
 * @returns
 */

export const saveZpbPbinAcctChrgNoRgsList = (dtos) =>
  instance.post(`${process.env.REACT_APP_SERVER_URL}/scq/scq/zpb/0002/savePbinAcctChrgNoRgs`, {
    subDto: dtos,
    subDtoCnt: dtos.length,
  });
