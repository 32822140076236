import instance from '../../common/config/api';

/**
 * 특화 메뉴 조회
 * @param {object} params
 * @returns
 */
export const fetchSpcMenuAuthList = (params) =>
  instance.post(`${process.env.REACT_APP_SERVER_URL}/scq/scq/sys/0003/selectSpcMenuAuthList`, params);

/**
 * 특화 메뉴 상세 조회
 * @param {object} params
 * @returns
 */
export const fetchSpcMenuAuthTypeList = (params) =>
  instance.post(`${process.env.REACT_APP_SERVER_URL}/scq/scq/sys/0003/selectSpcMenuAuthTypeList`, params);

/**
 * 특화 메뉴 저장
 * @param {array} dtos
 * @param {array} subDtos
 * @returns
 */
export const saveSpcMenuAuthList = (dtos, subDtos) =>
  instance.post(`${process.env.REACT_APP_SERVER_URL}/scq/scq/sys/0003/saveSpcMenuAuthList`, {
    main: dtos,
    mainCnt: dtos.length,
    sub: subDtos,
    subCnt: subDtos.length,
  });
