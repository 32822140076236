import { fetchCardBiz, insertCardBiz } from '../../../lib/sys/SYS0110M01.api';
import { SUCCESS, REQUEST, FAILURE } from '../../../common/utils/action-type-utils';

// actions
const FETCH_CARD_BIZ = 'SYS0110M01/FETCH_CARD_BIZ';
const INSERT_CARD_BIZ = 'SYS0110M01/INSERT_CARD_BIZ';
const SAVE_SEARCH_FORM_VALUES = 'SYS0110M01/SAVE_SEARCH_FORM_VALUES';
const RESET_STATE = 'SYS0110M01/RESET_STATE';

export const initFormValues = {
  sys_nm: '', //프로그램명
};

// initial state
const initialState = {
  loading: false,
  success: true,
  message: '',
  list: [],
  details: [],
  totalCount: 0,
  updateSuccess: false,
  resultCnt: -1,
  // 검색조건
  searchFormValues: {
    init: true,
    ...initFormValues,
  },
};

// reducers
export default function SYS0110M01(state = initialState, action) {
  switch (action.type) {
    case SUCCESS(FETCH_CARD_BIZ): // 조회 성공
      return {
        ...state,
        success: true,
        loading: false,
        list: action.payload.data?.main,
        totalCount: action.payload?.main_cnt,
        updateSuccess: false,
      };
    case REQUEST(FETCH_CARD_BIZ): // 조회 요청
      return {
        ...state,
        loading: true,
        updateSuccess: false,
      };
    case FAILURE(FETCH_CARD_BIZ): // 조회실패
      return {
        ...initialState,
        loading: false,
        success: false,
        message: action.payload.response?.data?.message,
        updateSuccess: false,
      };
    case SUCCESS(INSERT_CARD_BIZ): // 갱신 성공
      return {
        ...state,
        updateSuccess: true,
        loading: false,
        resultCnt: action.payload.data.resultCnt,
      };
    case REQUEST(INSERT_CARD_BIZ): // 갱신 요청
      return {
        ...state,
        loading: true,
        updateSuccess: false,
      };
    case FAILURE(INSERT_CARD_BIZ): // 갱신 실패
      return {
        ...initialState,
        loading: false,
        updateSuccess: false,
        resultCnt: -1,
        message: action.payload.response?.data?.message,
      };
    case SAVE_SEARCH_FORM_VALUES:
      return {
        ...state,
        searchFormValues: action.payload,
      };
    case RESET_STATE:
      return initialState;
    default:
      return { ...state, updateSuccess: false };
  }
}

/**
 * 법인카드 사업자 조회
 */
export const selectCardBiz = (searchParams) => {
  return {
    type: FETCH_CARD_BIZ,
    payload: fetchCardBiz(searchParams),
  };
};

/**
 * 법인카드 사업자 입력/수정
 */
export const updateCardBiz = (dtos) => {
  return {
    type: INSERT_CARD_BIZ,
    payload: insertCardBiz(dtos),
  };
};

/**
 * 조회조건 저장
 */
export const saveSearchFormValues = (searchFormValues) => {
  return {
    type: SAVE_SEARCH_FORM_VALUES,
    payload: {
      ...searchFormValues,
      init: false,
    },
  };
};

/**
 * 상태초기화
 */
export const resetState = () => {
  return {
    type: RESET_STATE,
  };
};
