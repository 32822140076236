import instance from '../../common/config/api';

/**
 * 세입 - 세입거래명세
 * @param {object} params
 * @returns
 */
export const fetchAnexTrhsList = (params) =>
  instance.post(`${process.env.REACT_APP_SERVER_URL}/scq/scq/axp/0001/selectAnexTrhsList`, params);

export const fetchAnexTrhsDtl = (params) =>
  instance.post(`${process.env.REACT_APP_SERVER_URL}/scq/scq/axp/0001/selectAnexTrhsDtl`, params);
