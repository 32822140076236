import { SUCCESS, REQUEST, FAILURE } from '../../../common/utils/action-type-utils';
import { fetchBizChcMenuList } from '../../../lib/cmm/main.api';

// actions
const FETCH_BIZ_CHC_MENU_LIST = 'mainState/FETCH_BIZ_CHC_MENU_LIST';
const RESET_SESSION_EXPIRE_TIME = 'mainState/RESET_SESSION_EXPIRE_TIME';

// initial state
const initialState = {
  loading: false,
  success: false,
  message: '',
  list: [],
  sessionExpireTime: { time: process.env.REACT_APP_SESSION_EXPIRE_TIME },
  totalCount: 0,
};

// reducers
export default function mainState(state = initialState, action) {
  switch (action.type) {
    case SUCCESS(FETCH_BIZ_CHC_MENU_LIST): // 조회 성공
      return {
        ...state,
        success: true,
        loading: false,
        list: action.payload.data?.output_data,
        totalCount: action.payload?.data.output_data_cnt,
      };
    case REQUEST(FETCH_BIZ_CHC_MENU_LIST): // 조회 요청
      return {
        ...state,
        loading: true,
        success: false,
        message: '',
      };
    case FAILURE(FETCH_BIZ_CHC_MENU_LIST): // 조회실패
      return {
        ...initialState,
        loading: false,
        success: false,
        message: action.payload.response?.data?.message,
      };
    case RESET_SESSION_EXPIRE_TIME:
      return {
        ...state,
        sessionExpireTime: { time: initialState.sessionExpireTime.time },
      };
    default:
      return state;
  }
}

/**
 * 업무선택 메뉴조회
 */
export const selectBizChcMenuList = (searchParams) => {
  return {
    type: FETCH_BIZ_CHC_MENU_LIST,
    payload: fetchBizChcMenuList(searchParams),
  };
};

export const resetSessionExpireTime = () => {
  return {
    type: RESET_SESSION_EXPIRE_TIME,
  };
};
