import dayjs from 'dayjs';
import { SUCCESS, REQUEST, FAILURE } from '../../../common/utils/action-type-utils';
import { fetchCommonComboList } from '../../../lib/cmm/combo.api';
import {
  fetchAnexRcexSts,
  fetchBgtCtAm,
  fetchBgtCtAmGigum,
  fetchOldYyAnexRcexSts,
  fetchOldYyAnexRcexStsGigum,
} from '../../../lib/rpt/RPT0006.api';

// actions
const FETCH_ANEX_RCEX_STS = 'RPT0601V01/FETCH_ANEX_RCEX_STS';
const FETCH_BGT_CT_AM = 'RPT0601V01/FETCH_BGT_CT_AM';
const FETCH_BGT_CT_AM_GIGUM = 'RPT0601V01/FETCH_BGT_CT_AM_GIGUM';
const FETCH_OLD_YY_ANEX_RCEX_STS = 'RPT0601V01/FETCH_OLD_YY_ANEX_RCEX_STS';
const FETCH_OLD_YY_ANEX_RCEX_STS_GIGUM = 'RPT0601V01/FETCH_OLD_YY_ANEX_RCEX_STS_GIGUM';

const FETCH_IST_INFO_LIST = 'RPT0601V01/FETCH_IST_INFO_LIST';
const FETCH_ACC_YR_LIST = 'RPT0601V01/FETCH_ACC_YR_LIST';
const FETCH_IST_NO_LIST = 'RPT0601V01/FETCH_IST_NO_LIST';
const SAVE_SEARCH_FORM_VALUES = 'RPT0601V01/SAVE_SEARCH_FORM_VALUES';
const RESET_STATE = 'RPT0601V01/RESET_STATE';

export const initFormValues = {
  cus_ist_cd: '',
  ydcs8a1_t8a02_ist_no: '',
  ydcs8a1_t8a02_acc_yr: dayjs().format('YYYY'),
  ydcs8a1_t8a02_dt_mn_dis: '1',
  ydcs8a1_t8a02_inq_mn: dayjs().format('YYYY-MM'),
  ydcs8a1_t8a02_inq_dt: dayjs().format('YYYY-MM-DD'),
  ydcs8a1_t8a02_act_no: '',
  report_type: '1',
};

// initial state
const initialState = {
  loading: false,
  success: false,
  message: '',
  list: [],
  title: {},
  head: {},
  loadingBySub: false,
  successBySub: false,
  sub: [],
  messageBySub: '',
  // 기관명
  istInfoSuccess: false,
  istInfoLoading: false,
  istInfoList: [],
  // 회계연도
  accYrSuccess: false,
  accYrLoading: false,
  accYrList: [],
  // 기관조회
  istNoSuccess: false,
  istNoLoading: false,
  istNoList: [],
  // 검색조건
  searchFormValues: {
    init: true,
    ...initFormValues,
  },
};

// reducers
export default function RPT0601V01(state = initialState, action) {
  switch (action.type) {
    case SUCCESS(FETCH_OLD_YY_ANEX_RCEX_STS_GIGUM): // 조회 성공
    case SUCCESS(FETCH_OLD_YY_ANEX_RCEX_STS): // 조회 성공
    case SUCCESS(FETCH_ANEX_RCEX_STS): // 조회 성공
      return {
        ...state,
        success: true,
        loading: false,
        list: action.payload.data?.output_data,
        title: action.payload.data?.output_title,
      };
    case REQUEST(FETCH_OLD_YY_ANEX_RCEX_STS_GIGUM): // 조회 성공
    case REQUEST(FETCH_OLD_YY_ANEX_RCEX_STS): // 조회 요청
    case REQUEST(FETCH_ANEX_RCEX_STS): // 조회 요청
      return {
        ...state,
        loading: true,
        success: false,
        message: '',
      };
    case FAILURE(FETCH_OLD_YY_ANEX_RCEX_STS_GIGUM): // 조회 성공
    case FAILURE(FETCH_OLD_YY_ANEX_RCEX_STS): // 조회실패
    case FAILURE(FETCH_ANEX_RCEX_STS): // 조회실패
      return {
        ...state,
        list: [],
        loading: false,
        success: false,
        message: action.payload.response?.data?.message,
      };
    case SUCCESS(FETCH_BGT_CT_AM_GIGUM): // 조회 성공
    case SUCCESS(FETCH_BGT_CT_AM): // 조회 성공
      return {
        ...state,
        successBySub: true,
        loadingBySub: false,
        sub: action.payload.data?.sub,
      };
    case REQUEST(FETCH_BGT_CT_AM_GIGUM): // 조회 요청
    case REQUEST(FETCH_BGT_CT_AM): // 조회 요청
      return {
        ...state,
        loadingBySub: true,
        successBySub: false,
        messageBySub: '',
      };
    case FAILURE(FETCH_BGT_CT_AM_GIGUM): // 조회실패
    case FAILURE(FETCH_BGT_CT_AM): // 조회실패
      return {
        ...state,
        sub: [],
        loadingBySub: false,
        successBySub: false,
        messageBySub: action.payload.response?.data?.message,
      };
    // 기관명
    case SUCCESS(FETCH_IST_INFO_LIST): // 조회 성공
      return {
        ...state,
        istInfoSuccess: true,
        istInfoLoading: false,
        istInfoList: action.payload.data?.subDto,
      };
    case REQUEST(FETCH_IST_INFO_LIST): // 조회 요청
      return {
        ...state,
        istInfoLoading: true,
        istInfoSuccess: false,
      };
    case FAILURE(FETCH_IST_INFO_LIST): // 조회실패
      return {
        ...state,
        istInfoLoading: false,
        istInfoSuccess: false,
        istInfoList: [],
      };
    // 회계연도
    case SUCCESS(FETCH_ACC_YR_LIST): // 조회 성공
      return {
        ...state,
        accYrSuccess: true,
        accYrLoading: false,
        accYrList: action.payload.data?.subDto,
      };
    case REQUEST(FETCH_ACC_YR_LIST): // 조회 요청
      return {
        ...state,
        accYrLoading: true,
        accYrSuccess: false,
      };
    case FAILURE(FETCH_ACC_YR_LIST): // 조회실패
      return {
        ...state,
        accYrLoading: false,
        accYrSuccess: false,
        accYrList: [],
      };
    // 기관조회
    case SUCCESS(FETCH_IST_NO_LIST): // 조회 성공
      return {
        ...state,
        istNoSuccess: true,
        istNoLoading: false,
        istNoList: action.payload.data?.subDto,
      };
    case REQUEST(FETCH_IST_NO_LIST): // 조회 요청
      return {
        ...state,
        istNoLoading: true,
        istNoSuccess: false,
      };
    case FAILURE(FETCH_IST_NO_LIST): // 조회실패
      return {
        ...state,
        istNoLoading: false,
        istNoSuccess: false,
        istNoList: [],
      };
    case SAVE_SEARCH_FORM_VALUES:
      return {
        ...state,
        // 기관명
        istInfoSuccess: false,
        istInfoLoading: false,
        // 회계번호목록
        accYrSuccess: false,
        accYrLoading: false,
        // 기관조회
        istNoSuccess: false,
        istNoLoading: false,
        searchFormValues: action.payload,
      };
    case RESET_STATE:
      return initialState;
    default:
      return state;
  }
}

/**
 * 회계별 세출자금수지 현황 조회
 */
export const selectEcadBiztAnexFdRcexSts = (searchParams) => {
  return {
    type: FETCH_ANEX_RCEX_STS,
    payload: fetchAnexRcexSts(searchParams),
  };
};

/**
 * 예산현액 조회
 */
export const selectBgtCtAm = (searchParams) => {
  return {
    type: FETCH_BGT_CT_AM,
    payload: fetchBgtCtAm(searchParams),
  };
};

/**
 * 예산현액 조회(기금)
 */
export const selelctBgtCtAmGigum = (searchParams) => {
  return {
    type: FETCH_BGT_CT_AM_GIGUM,
    payload: fetchBgtCtAmGigum(searchParams),
  };
};

/**
 * 구년도 세출자금수지현황 (세출) 조회
 */
export const selectOldYyAnexRcexSts = (searchParams) => {
  return {
    type: FETCH_OLD_YY_ANEX_RCEX_STS,
    payload: fetchOldYyAnexRcexSts(searchParams),
  };
};

/**
 * 구년도 세출자금수지현황 (기금) 조회
 */
export const selectOldYyAnexRcexStsGigum = (searchParams) => {
  return {
    type: FETCH_OLD_YY_ANEX_RCEX_STS_GIGUM,
    payload: fetchOldYyAnexRcexStsGigum(searchParams),
  };
};

/**
 * 기관명 콤보 조회
 */
export const selectIstInfoComboList = (searchParams) => {
  return {
    type: FETCH_IST_INFO_LIST,
    payload: fetchCommonComboList({ slt_box_no: '005', ...searchParams }),
  };
};

/**
 * 회계연도 콤보 조회
 */
export const selectAccYrComboList = (searchParams) => {
  return {
    type: FETCH_ACC_YR_LIST,
    payload: fetchCommonComboList({ slt_box_no: '141', ...searchParams }),
  };
};

/**
 * 기관 콤보 조회
 */
export const selectIstNoComboList = (searchParams) => {
  return {
    type: FETCH_IST_NO_LIST,
    payload: fetchCommonComboList({ slt_box_no: '023', ...searchParams }),
  };
};

/**
 * 조회조건 저장
 */
export const saveSearchFormValues = (searchFormValues) => {
  return {
    type: SAVE_SEARCH_FORM_VALUES,
    payload: {
      ...searchFormValues,
      init: false,
    },
  };
};

/**
 * 상태초기화
 */
export const resetState = () => {
  return {
    type: RESET_STATE,
  };
};
