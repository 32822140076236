import instance from '../../common/config/api';

/**
 * 시스템공통 - 비공금영업점사용자관리
 * @param {object} params
 * @returns
 */

/**
 * 비공금영업점사용자 조회
 * @param {object} params
 * @returns
 */
export const fetchSpcUser = (params) =>
  instance.post(`${process.env.REACT_APP_SERVER_URL}/scq/scq/sys/0002/selectSpcUser`, params);

/**
 * 비공금영업점사용자 저장
 * @param {array} dtos
 * @returns
 */
export const insertSpcUser = (dtos) =>
  instance.post(`${process.env.REACT_APP_SERVER_URL}/scq/scq/sys/0002/insertSpcUser`, {
    subDto: dtos,
  });
