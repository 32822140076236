/**
 * 비동기 요청
 * @param {string} actionType
 * @returns
 */
export const REQUEST = (actionType) => `${actionType}_PENDING`;

/**
 * 비동기 성공
 * @param {string} actionType
 * @returns
 */
export const SUCCESS = (actionType) => `${actionType}_FULFILLED`;

/**
 * 비동기 실패
 * @param {string} actionType
 * @returns
 */
export const FAILURE = (actionType) => `${actionType}_REJECTED`;
