import { fetchEvdXpnAcnoList, fetchEvdXpnAcnoDtl } from '../../../lib/ils/EvdXpnAcnoList.api';
import { fetchBrNmList } from '../../../lib/cmm/brNm.api';
import { SUCCESS, REQUEST, FAILURE } from '../../../common/utils/action-type-utils';
import dayjs from 'dayjs';
import { fetchCommonComboList } from '../../../lib/cmm/combo.api';

// actions
const FETCH_EVD_XPN_ACNO_LIST = 'EvdXpnAcnoList/FETCH_EVD_XPN_ACNO_LIST';
const FETCH_EVD_XPN_ACNO_DTL = 'EvdXpnAcnoList/FETCH_EVD_XPN_ACNO_DTL';
const FETCH_BR_NM_LIST = 'EvdXpnAcnoList/FETCH_BR_NM_LIST';

const FETCH_IST_INFO_LIST = 'EvdXpnAcnoList/FETCH_IST_INFO_LIST';
const FETCH_DEPT_INFO_LIST = 'EvdXpnAcnoList/FETCH_DEPT_INFO_LIST';
const FETCH_ACC_YR_LIST = 'EvdXpnAcnoList/FETCH_ACC_YR_LIST';
const FETCH_DPACT_STCD_LIST = 'EvdXpnAcnoList/FETCH_DPACT_STCD_LIST';
const FETCH_EVD_XPN_GBN_LIST = 'EvdXpnAcnoList/FETCH_EVD_XPN_GBN_LIST';

const SAVE_SEARCH_FORM_VALUES = 'EvdXpnAcnoList/SAVE_SEARCH_FORM_VALUES';
const RESET_INITIAL_STATE = 'EvdXpnAcnoList/RESET_INITIAL_STATE';

const CLEAR_MESSAGE = 'EvdXpnAcnoList/CLEAR_MESSAGE';

export const initFormValues = {
  cus_ist_cd: '6110000', //기관명
  cus_dept_cd: 'all', //부서명
  dpact_stcd: '01', //계좌상태
  ydcs8a1_t8a01_acc_yr: dayjs().format('YYYY'), //회계연도
  axpn_act_dscd: 'all', //일상경비구분
};

// initial state
const initialState = {
  loading: false,
  success: true,
  message: '',
  list: [],
  listTitle: {},
  listHead: {},
  listCommon: {},
  details: [],
  detailsHead: {},
  detailsCommon: {},
  detailsSuccess: false,
  detailsLoading: false,
  detailsMessage: '',
  brNmList: [],
  totalCount: 0,

  //콤보 - 기관명 005
  istInfoSuccess: false,
  istInfoLoading: false,
  istInfoList: [],

  //콤보 - 부서명 041
  deptInfoSuccess: false,
  deptInfoLoading: false,
  deptInfoList: [],

  //콤보 - 회계연도 025
  accYrSuccess: false,
  accYrLoading: false,
  accYrList: [],

  //콤보 - 계좌상태 003
  dpactStcdSuccess: false,
  dpactStcdLoading: false,
  dpactStcdList: [],

  //콤보 - 일상경비구분
  evdXpnGbnSuccess: false,
  evdXpnGbnLoading: false,
  evdXpnGbnList: [],

  // 검색조건
  searchFormValues: {
    init: true,
    ...initFormValues,
  },
};

// reducers
export default function EvdXpnAcnoList(state = initialState, action) {
  switch (action.type) {
    case SUCCESS(FETCH_EVD_XPN_ACNO_LIST): // 조회 성공
      return {
        ...state,
        success: true,
        loading: false,
        list: action.payload.data?.subDto,
        listTitle: action.payload.data?.output_title,
        listHead: action.payload.data?.output_head,
        listCommon: action.payload.data?.common,
        totalCount: action.payload?.subDtoCnt,
      };
    case REQUEST(FETCH_EVD_XPN_ACNO_LIST): // 조회 요청
      return {
        ...state,
        loading: true,
      };
    case FAILURE(FETCH_EVD_XPN_ACNO_LIST): // 조회실패
      return {
        ...state,
        list: [],
        loading: false,
        success: false,
        message: action.payload.response?.data?.message,
      };
    case SUCCESS(FETCH_EVD_XPN_ACNO_DTL): // 조회 성공
      return {
        ...state,
        details: action.payload.data?.output_data[0],
        detailsHead: action.payload.data?.output_head,
        detailsCommon: action.payload.data?.common,
        detailsSuccess: true,
        detailsLoading: false,
      };
    case REQUEST(FETCH_EVD_XPN_ACNO_DTL): // 조회 요청
      return {
        ...state,
        detailsLoading: true,
      };
    case FAILURE(FETCH_EVD_XPN_ACNO_DTL): // 조회실패
      return {
        ...state,
        details: [],
        detailsMessage: action.payload.response?.data?.message,
        detailsSuccess: false,
        detailsLoading: false,
      };
    case SUCCESS(FETCH_BR_NM_LIST): // 조회 성공
      return {
        ...state,
        brNmList: action.payload.data?.subDto,
      };
    case REQUEST(FETCH_BR_NM_LIST): // 조회 요청
      return {
        ...state,
      };
    case FAILURE(FETCH_BR_NM_LIST): // 조회실패
      return {
        ...state,
        brNmList: [],
        message: action.payload.response?.data?.message,
      };
    // 기관명
    case SUCCESS(FETCH_IST_INFO_LIST): // 조회 성공
      return {
        ...state,
        istInfoSuccess: true,
        istInfoLoading: false,
        istInfoList: action.payload.data?.subDto,
      };
    case REQUEST(FETCH_IST_INFO_LIST): // 조회 요청
      return {
        ...state,
        istInfoLoading: true,
        istInfoSuccess: false,
      };
    case FAILURE(FETCH_IST_INFO_LIST): // 조회실패
      return {
        ...state,
        istInfoLoading: false,
        istInfoSuccess: false,
        istInfoList: [],
      };
    // 부서명
    case SUCCESS(FETCH_DEPT_INFO_LIST): // 조회 성공
      return {
        ...state,
        deptInfoSuccess: true,
        deptInfoLoading: false,
        deptInfoList: action.payload.data?.subDto,
      };
    case REQUEST(FETCH_DEPT_INFO_LIST): // 조회 요청
      return {
        ...state,
        deptInfoLoading: true,
        deptInfoSuccess: false,
      };
    case FAILURE(FETCH_DEPT_INFO_LIST): // 조회실패
      return {
        ...state,
        deptInfoLoading: false,
        deptInfoSuccess: false,
        deptInfoList: [],
      };
    // 계좌상태
    case SUCCESS(FETCH_DPACT_STCD_LIST): // 조회 성공
      return {
        ...state,
        dpactStcdSuccess: true,
        dpactStcdLoading: false,
        dpactStcdList: action.payload.data?.subDto,
      };
    case REQUEST(FETCH_DPACT_STCD_LIST): // 조회 요청
      return {
        ...state,
        dpactStcdLoading: true,
        dpactStcdSuccess: false,
      };
    case FAILURE(FETCH_DPACT_STCD_LIST): // 조회실패
      return {
        ...state,
        dpactStcdLoading: false,
        dpactStcdSuccess: false,
        dpactStcdList: [],
      };
    // 회계연도
    case SUCCESS(FETCH_ACC_YR_LIST): // 조회 성공
      return {
        ...state,
        accYrSuccess: true,
        accYrLoading: false,
        accYrList: action.payload.data?.subDto,
      };
    case REQUEST(FETCH_ACC_YR_LIST): // 조회 요청
      return {
        ...state,
        accYrLoading: true,
        accYrSuccess: false,
      };
    case FAILURE(FETCH_ACC_YR_LIST): // 조회실패
      return {
        ...state,
        accYrLoading: false,
        accYrSuccess: false,
        accYrList: [],
      };
    // 일상경비구분
    case SUCCESS(FETCH_EVD_XPN_GBN_LIST): // 조회 성공
      return {
        ...state,
        evdXpnGbnSuccess: true,
        evdXpnGbnLoading: false,
        evdXpnGbnList: action.payload.data?.subDto,
      };
    case REQUEST(FETCH_EVD_XPN_GBN_LIST): // 조회 요청
      return {
        ...state,
        evdXpnGbnLoading: true,
        evdXpnGbnSuccess: false,
      };
    case FAILURE(FETCH_EVD_XPN_GBN_LIST): // 조회실패
      return {
        ...state,
        evdXpnGbnLoading: false,
        evdXpnGbnSuccess: false,
        evdXpnGbnList: [],
      };
    case SAVE_SEARCH_FORM_VALUES:
      return {
        ...state,
        istInfoSuccess: false,
        istInfoLoading: false,
        deptInfoSuccess: false,
        deptInfoLoading: false,
        accYrSuccess: false,
        accYrLoading: false,
        arvnActDscdSuccess: false,
        arvnActDscdLoading: false,
        dpactStcdSuccess: false,
        dpactStcdLoading: false,
        axpnActDscdLoading: false,
        axpnActDscdSuccess: false,
        agnActDscdLoading: false,
        agnActDscdSuccess: false,

        searchFormValues: action.payload,
      };
    case RESET_INITIAL_STATE:
      return initialState;
    case CLEAR_MESSAGE:
      return {
        ...state,
        message: '',
        success: false,
        detailsMessage: '',
        detailsSuccess: false,
      };
    default:
      return state;
  }
}

/**
 * 세입계좌현황 조회
 */
export const selectEvdXpnAcnoList = (searchParams) => {
  return {
    type: FETCH_EVD_XPN_ACNO_LIST,
    payload: fetchEvdXpnAcnoList(searchParams),
  };
};

/**
 * 세입계좌 상세정보 조회
 */
export const selectEvdXpnAcnoDtl = (searchParams) => {
  return {
    type: FETCH_EVD_XPN_ACNO_DTL,
    payload: fetchEvdXpnAcnoDtl(searchParams),
  };
};

/**
 * 거래점명조회
 */
export const selectBrNmList = () => {
  return {
    type: FETCH_BR_NM_LIST,
    payload: fetchBrNmList(),
  };
};

/**
 * 메시지초기화
 */
export const clearMessage = () => {
  return {
    type: CLEAR_MESSAGE,
  };
};

//기관명 콤보 조회
export const selectIstInfoList = (searchParam) => {
  return {
    type: FETCH_IST_INFO_LIST,
    payload: fetchCommonComboList({ slt_box_no: '005', ...searchParam }),
  };
};

//부서명 콤보 조회
export const selectDeptInfoList = (searchParam) => {
  return {
    type: FETCH_DEPT_INFO_LIST,
    payload: fetchCommonComboList({ slt_box_no: '041', ...searchParam }),
  };
};

//회계연도 콤보 조회
export const selectAccYrList = () => {
  return {
    type: FETCH_ACC_YR_LIST,
    payload: fetchCommonComboList({ slt_box_no: '025' }),
  };
};

//계좌상태 콤보 조회
export const selectDpactStcdList = () => {
  return {
    type: FETCH_DPACT_STCD_LIST,
    payload: fetchCommonComboList({
      slt_box_no: '003',
      cd_grp_nm: '수신계좌상태코드',
      sys_cd: 'GW',
    }),
  };
};

//일상경비구분
export const selectEvdXpnGbnList = () => {
  return {
    type: FETCH_EVD_XPN_GBN_LIST,
    payload: fetchCommonComboList({
      slt_box_no: '003',
      cd_grp_nm: '세출계좌구분코드(일상경비)',
      sys_cd: 'GW',
    }),
  };
};

/**
 * 조회조건 저장
 */
export const saveSearchFormValues = (searchFormValues) => {
  return {
    type: SAVE_SEARCH_FORM_VALUES,
    payload: {
      ...searchFormValues,
      init: false,
    },
  };
};

/**
 * 상태초기화
 */
export const resetInitialState = () => {
  return {
    type: RESET_INITIAL_STATE,
  };
};
