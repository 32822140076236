import instance from '../../common/config/api';

// ================================================ 차트

/**
 * 제로페이 - 결제현황조회 (기간별 차트 데이터)
 * @param {object} params
 * @returns
 */

export const fetchByPrdStlPts = (params) =>
  instance.post(`${process.env.REACT_APP_SERVER_URL}/scq/scq/zpb/0001/selectZeroByPrdStlPts`, params);

// 요일별 차트 데이터
export const fetchByWkdStlPts = (params) =>
  instance.post(`${process.env.REACT_APP_SERVER_URL}/scq/scq/zpb/0001/selectZeroByWkdStlPts`, params);

// 시간별 차트 데이터
export const fetchByTimStlPts = (params) =>
  instance.post(`${process.env.REACT_APP_SERVER_URL}/scq/scq/zpb/0001/selectZeroByTimStlPts`, params);

// 금액별 차트 데이터
export const fetchByAmStlPts = (params) =>
  instance.post(`${process.env.REACT_APP_SERVER_URL}/scq/scq/zpb/0001/selectZeroByAmStlPts`, params);

// 업종별 차트 데이터
export const fetchByBzctStlPts = (params) =>
  instance.post(`${process.env.REACT_APP_SERVER_URL}/scq/scq/zpb/0001/selectZeroByBzctStlPts`, params);

// 기관/부서별 차트 데이터
export const fetchByIstStlPts = (params) =>
  instance.post(`${process.env.REACT_APP_SERVER_URL}/scq/scq/zpb/0001/selectZeroByIstStlPts`, params);

// 지역별 차트 데이터
export const fetchByRgnStlPts = (params) =>
  instance.post(`${process.env.REACT_APP_SERVER_URL}/scq/scq/zpb/0001/selectZeroByRgnStlPts`, params);

// ================================================ 요약

/**
 * 제로페이 - 결제현황조회 (기간별 요약 데이터)
 * @param {object} params
 * @returns
 */

export const fetchByPrdStlPtsSmor = (params) =>
  instance.post(`${process.env.REACT_APP_SERVER_URL}/scq/scq/zpb/0001/selectZeroByPrdStlPtsSmor`, params);

// 요일별 차트 데이터
export const fetchByWkdStlPtsSmor = (params) =>
  instance.post(`${process.env.REACT_APP_SERVER_URL}/scq/scq/zpb/0001/selectZeroByWkdStlPtsSmor`, params);

// 시간별 차트 데이터
export const fetchByTimStlPtsSmor = (params) =>
  instance.post(`${process.env.REACT_APP_SERVER_URL}/scq/scq/zpb/0001/selectZeroByTimStlPtsSmor`, params);

// 금액별 차트 데이터
export const fetchByAmStlPtsSmor = (params) =>
  instance.post(`${process.env.REACT_APP_SERVER_URL}/scq/scq/zpb/0001/selectZeroByAmStlPtsSmor`, params);

// 업종별 차트 데이터
export const fetchByBzctStlPtsSmor = (params) =>
  instance.post(`${process.env.REACT_APP_SERVER_URL}/scq/scq/zpb/0001/selectZeroByBzctStlPtsSmor`, params);

// 기관/부서별 차트 데이터
export const fetchByIstStlPtsSmor = (params) =>
  instance.post(`${process.env.REACT_APP_SERVER_URL}/scq/scq/zpb/0001/selectZeroByIstStlPtsSmor`, params);

// 지역별 차트 데이터
export const fetchByRgnStlPtsSmor = (params) =>
  instance.post(`${process.env.REACT_APP_SERVER_URL}/scq/scq/zpb/0001/selectZeroByRgnStlPtsSmor`, params);

// ================================================ 랭킹

/**
 * 제로페이 - 결제현황조회 (랭킹)
 * @param {object} params
 * @returns
 */

export const fetchByPrdStlPtsRk = (params) =>
  instance.post(`${process.env.REACT_APP_SERVER_URL}/scq/scq/zpb/0001/selectZeroByPrdStlPtsRk`, params);

// ================================================ 테이블 데이터 조회

/**
 * 제로페이 - 결제현황조회 (기관/부서별 목록)
 * @param {object} params
 * @returns
 */

export const fetchByIstStlPtsList = (params) =>
  instance.post(`${process.env.REACT_APP_SERVER_URL}/scq/scq/zpb/0001/selectZeroByIstStlPtsList`, params);

/**
 * 제로페이 - 결제현황조회 (기관/부서별 사용현황)
 * @param {object} params
 * @returns
 */

export const fetchIstUseStlPtsList = (params) =>
  instance.post(`${process.env.REACT_APP_SERVER_URL}/scq/scq/zpb/0001/selectZeroByIstUseStlPts`, params);
