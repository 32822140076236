export const Severity = {
  getSeverity: (flag) =>
    flag === '상'
      ? Severity.WARNING
      : flag === '중'
      ? Severity.ADVISORY
      : flag === '하'
      ? Severity.CAUTION
      : Severity.CAUTION,
  WARNING: 'warning', //상
  ADVISORY: 'advisory', //중
  CAUTION: 'caution', //하
};

export const OccurenceTitle = {
  getTitle: (flag) =>
    flag === OccurenceTitle.LMT_UPTN_RQ
      ? '한도상향신청'
      : OccurenceTitle.ALAC_TRN
      ? '제사고(입출금제한)거래'
      : OccurenceTitle.SMRT_INET_BNKG_RQ
      ? '스마트.인터넷뱅킹 신청'
      : OccurenceTitle.NEW_CANC_HIS
      ? '신규,해지명세'
      : OccurenceTitle.HAM_REPT_WDR_CNT
      ? '고액/반복적 출금 거래'
      : OccurenceTitle.NNOML_ETC_TRN
      ? '비정상/기타 거래'
      : '',
  LMT_UPTN_RQ: '01',
  ALAC_TRN: '02',
  SMRT_INET_BNKG_RQ: '03',
  NEW_CANC_HIS: '04',
  HAM_REPT_WDR_CNT: '05',
  NNOML_ETC_TRN: '06',
};
