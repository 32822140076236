import { TextField } from '@mui/material';
import React, { forwardRef, useCallback, useEffect, useImperativeHandle, useRef, useState } from 'react';
import styled, { css } from 'styled-components';
import { lpad } from '../../common/utils/utils';

const WOLoginInputCustom = forwardRef(function WOLoginInput(
  { id, name, label, type, placeholder, value, button, error, time, styleType, onChange, maxLength },
  ref
) {
  const [inputValue, setInputValue] = useState(value || '');
  const [labelShrink, setLabelShrink] = useState(false);
  const [capsLock, setCapsLock] = useState(null); // 캡스락 상태 관리
  const [inputTime, setInputTime] = useState(time);
  // 인증만료시간
  const timer = useRef(null);
  // 5분
  const defaultInterval = 300;

  const setTimer = () => {
    if (timer.current) {
      clearInterval(timer.current);
    }
    setInputTime(defaultInterval);
    timer.current = setInterval(() => {
      setInputTime((prev) => prev - 1);
    }, 1000);
  };

  useEffect(() => {
    if (inputTime < 0) {
      if (timer.current) {
        clearInterval(timer.current);
        setInputTime(0);
      }
    }
  }, [inputTime]);

  useImperativeHandle(ref, () => {
    return {
      startTimer() {
        setTimer();
      },
    };
  });

  const handleInputChange = useCallback(
    (event) => {
      if (type === 'number') {
        if (event.target.value.match(/[^0-9]/)) {
          event.preventDefault();
          return;
        }
      }
      if (type === 'password') {
        if (event.target.value.match(/[ㄱ-힣]/)) {
          event.preventDefault();
          return;
        }
      }
      setInputValue(event.target.value);
      onChange?.(event.target.value);
    },
    [onChange, type]
  );

  const handleInputFocus = () => {
    setLabelShrink(true);
  };

  const handleInputBlur = (e) => {
    if (inputValue === '') {
      setLabelShrink(false);
    }
  };

  const handleInputClear = () => {
    setInputValue('');
    onChange?.('');
    setLabelShrink(false);
  };

  const handleCapsLock = (e) => {
    let capslock = e.getModifierState('CapsLock');
    setCapsLock(capslock);
  };

  useEffect(() => {
    value && inputValue && setLabelShrink(true);
  }, [value, inputValue]);

  useEffect(() => {
    setInputValue(value);
  }, [value]);

  useEffect(() => {
    setInputTime(time);
  }, [time]);

  return (
    <Wrapper $labelShrink={labelShrink} $styleType={styleType}>
      <>
        <CustomInput
          type={(type === 'number' ? 'text' : type) || 'text'}
          id={id}
          name={name}
          label={labelShrink ? label : placeholder}
          value={inputValue}
          onChange={handleInputChange}
          onFocus={handleInputFocus}
          onBlur={handleInputBlur}
          error={error}
          $hasButton={button}
          $hasTime={inputTime}
          inputProps={{
            maxLength: maxLength ?? 524288,
            ...(type === 'number' ? { inputMode: 'numeric', pattern: '[0-9]' } : {}),
          }}
          fullWidth={true}
          ref={ref}
        />
        {button && button}
        {inputTime > 0 && <Time>{`${lpad(Math.floor(inputTime / 60), 2, '0')}:${lpad(inputTime % 60, 2, '0')}`}</Time>}
        {inputValue !== '' && labelShrink !== false ? (
          <ClearBtn type="button" $hasButton={button} $hasTime={inputTime} onClick={handleInputClear} />
        ) : (
          ''
        )}
      </>
    </Wrapper>
  );
});

export default WOLoginInputCustom;

const ClearBtn = styled.button`
  position: absolute;
  top: 50%;
  right: 12px;
  transform: translateY(-50%);
  display: none;
  width: 18px;
  height: 18px;
  border-radius: 50%;
  background: url(/assets/images/icon/clear-grey-18.svg) no-repeat center / cover;
  ${(props) =>
    props.$hasButton &&
    css`
      right: 116px;
    `}
  ${(props) =>
    props.$hasTime &&
    css`
      right: 65px;
    `}
`;

const Wrapper = styled.div`
  position: relative;
  transition: 0.3s;
  & + & {
    /* margin-top: 16px; */
  }
  .auth-btn {
    position: absolute;
    right: 7px;
    top: 50%;
    transform: translateY(-50%);
  }
  &:focus-within {
    ${ClearBtn} {
      display: block;
    }
  }
  ${(props) =>
    props.$styleType === 'popup' &&
    props.$labelShrink &&
    css`
      margin-top: 8px;
    `}
`;

const CustomInput = styled(TextField)`
  .MuiFormLabel-root {
    color: var(--C-Dark-06, #9ea6b2);
    font-family: Pretendard;
    font-size: 14px;
    font-weight: 400;
    line-height: 100%;
    top: 3px;
    &.Mui-focused {
      color: var(--C-Dark-09, #3c4b63);
      border-color: var(--C-Dark-06, #9ea6b2);
      ${(props) =>
        props.error &&
        css`
          color: var(--Red-02, #fc8686);
        `}
    }
    &.Mui-error {
      color: var(--Red-02, #fc8686);
    }
  }
  .MuiFormLabel-root[data-shrink='true'] {
    top: 3px;
    left: 2px;
  }

  .MuiInputBase-root {
    line-height: 100%;

    &.MuiOutlinedInput-root {
      border-radius: 4px;
      .MuiOutlinedInput-notchedOutline {
        border-width: 1px;
      }
      .MuiOutlinedInput-notchedOutline {
        border-color: var(--Red-02, #fc8686);
      }
      ${(props) =>
        props.error !== true &&
        css`
          .MuiOutlinedInput-notchedOutline {
            border-color: var(--C-Dark-03, #e5e5ed);
          }
          &:hover {
            .MuiOutlinedInput-notchedOutline {
              border-color: var(--C-Dark-03, #e5e5ed);
            }
          }
        `}
    }
    &.MuiOutlinedInput-root {
      &.Mui-focused {
        .MuiOutlinedInput-notchedOutline {
          border-width: 1px;
        }
        ${(props) =>
          props.error !== true &&
          css`
            .MuiOutlinedInput-notchedOutline {
              border-color: var(--C-Dark-06, #9ea6b2);
              &:hover {
                .MuiOutlinedInput-notchedOutline {
                  border-color: var(--C-Dark-06, #9ea6b2);
                }
              }
            }
          `}
      }
    }

    .MuiInputBase-input {
      padding: 16px 16px;
      padding-right: 42px;
      color: var(--C-Dark-11, #1a2c49);
      font-family: Pretendard;
      font-size: 14px;
      font-weight: 400;
      line-height: 130%; /* 18.2px */
      text-overflow: ellipsis;
      ${(props) =>
        props.$hasButton &&
        css`
          padding-right: 146px;
        `}

      ${(props) =>
        props.$hasTime &&
        css`
          padding-right: 95px;
        `}
    }
  }
`;

const Time = styled.div`
  position: absolute;
  top: 50%;
  right: 12px;
  transform: translateY(-50%);
  color: var(--Red-01, #ff5d5d);
  font-size: 12px;
  font-weight: 700;
`;

const Tooltip = styled.div`
  position: absolute;
  left: 4px;
  bottom: -30px;
  color: #566479;
  padding: 8px;
  border-radius: 4px;
  box-shadow: 0px 2px 8px 0px rgba(0, 0, 0, 0.1), 0px 8px 20px 0px rgba(0, 0, 0, 0.1);
  background-color: #fff;
  z-index: 900;

  svg {
    position: absolute;
    top: -7px;
    left: 8px;
  }
`;
