import { fetchNtPkNmgt, saveMenuList } from '../../../lib/sys/NtMng.api';
import { SUCCESS, REQUEST, FAILURE } from '../../../common/utils/action-type-utils';
import { fetchMenuList } from '../../../lib/sys/SYS0003.api';

// actions
const FETCH_MENU_LIST = 'menuMngState/fetchNtList';
const FETCH_NT_PK_NMGT = 'menuMngState/fetchNtPkNmgt';
const INSERT_NOTICE = 'menuMngState/insertNotice';
const SAVE_MENU = 'menuMngState/saveMenuList';

// initial state
const initialState = {
  loading: false,
  success: true,
  message: '',
  list: [],
  details: [],
  totalCount: 0,
  updateSuccess: false,
  updating: false,
  mainPk: '',
};

// reducers
export default function menuMngState(state = initialState, action) {
  switch (action.type) {
    case SUCCESS(FETCH_MENU_LIST): // 조회 성공
      return {
        ...state,
        success: true,
        loading: false,
        list: action.payload.data?.main,
        updateSuccess: false,
        totalCount: action.payload?.main_cnt,
      };
    case REQUEST(FETCH_MENU_LIST): // 조회 요청
      return {
        ...state,
        updateSuccess: false,
        loading: true,
      };
    case FAILURE(FETCH_MENU_LIST): // 조회실패
      return {
        ...initialState,
        loading: false,
        success: false,
        updateSuccess: false,
        message: action.payload.response?.data?.message,
      };
    // case SUCCESS(FETCH_NT_PK_NMGT): // 채번 성공
    //   return {
    //     ...state,
    //     success: true,
    //     loading: false,
    //     mainPk: action.payload.data?.main_pk[0].pk,
    //     mainPkCnt: action.payload?.main_cnt,
    //     updateSuccess: false,
    //   };
    // case REQUEST(FETCH_NT_PK_NMGT): // 채번 요청
    //   return {
    //     ...state,
    //     loading: true,
    //     updateSuccess: false,
    //   };
    // case FAILURE(FETCH_NT_PK_NMGT): // 채번 실패
    //   return {
    //     ...initialState,
    //     loading: false,
    //     success: false,
    //     message: action.payload.response?.data?.message,
    //     updateSuccess: false,
    //   };
    case SUCCESS(SAVE_MENU): // 등록/수정 성공
      return {
        ...state,
        updateSuccess: true,
        updating: false,
        resultCnt: action.payload.data.resultCnt,
      };
    case REQUEST(SAVE_MENU): // 등록/수정 요청
      return {
        ...state,
        updating: true,
        updateSuccess: false,
        message: '',
      };
    case FAILURE(SAVE_MENU): // 등록/수정 실패
      return {
        ...initialState,
        updating: false,
        updateSuccess: false,
        resultCnt: -1,
        message: action.payload.response?.data?.message,
      };
    default:
      return { ...state, updateSuccess: false };
  }
}

/**
 * 메뉴 조회
 */
export const selectMenuList = (searchParams) => {
  return {
    type: FETCH_MENU_LIST,
    payload: fetchMenuList(searchParams),
  };
};

/**
 * 메뉴 pk채번
 */
export const selectNtPkNmgt = (searchParams) => {
  return {
    type: FETCH_NT_PK_NMGT,
    payload: fetchNtPkNmgt(searchParams),
  };
};

/**
 * 메뉴 등록/수정
 */
export const updateMenu = (dtos) => {
  return {
    type: SAVE_MENU,
    payload: saveMenuList(dtos),
  };
};
