import { fetcQnaList, fetchQnaPkNmgt, insertQna } from '../../../lib/sys/SYS0107M01.api';
import { SUCCESS, REQUEST, FAILURE } from '../../../common/utils/action-type-utils';
import dayjs from 'dayjs';

// actions
const FETCH_QNA_LIST = 'SYS0107M01/fetcQnaList';
const FETCH_QNA_PK_NMGT = 'SYS0107M01/fetchQnaPkNmgt';
const INSERT_QNA = 'SYS0107M01/insertQna';
const SAVE_SEARCH_FORM_VALUES = 'SYS0107M01/SAVE_SEARCH_FORM_VALUES';
const RESET_STATE = 'SYS0107M01/RESET_STATE';

export const initFormValues = {
  sch_gubun: '1', //검색구분
  sch_txt: '', //검색 text
  sch_sta_dt: dayjs().add(-6, 'M').format('YYYY-MM-DD'), //게시시간 시작일
  sch_end_dt: dayjs().format('YYYY-MM-DD'), //게시기간 종료일
  asw_sts_cd: 'all', //답변상태
};

// initial state
const initialState = {
  loading: false,
  success: true,
  message: '',
  list: [],
  details: [],
  totalCount: 0,
  updateSuccess: false,
  updating: false,
  mainPk: '',
  // 검색조건
  searchFormValues: {
    init: true,
    ...initFormValues,
  },
};

// reducers
export default function SYS0107M01(state = initialState, action) {
  switch (action.type) {
    case SUCCESS(FETCH_QNA_LIST): // 조회 성공
      return {
        ...state,
        success: true,
        loading: false,
        list: action.payload.data?.main,
        updateSuccess: false,
        totalCount: action.payload?.main_cnt,
      };
    case REQUEST(FETCH_QNA_LIST): // 조회 요청
      return {
        ...state,
        updateSuccess: false,
        loading: true,
      };
    case FAILURE(FETCH_QNA_LIST): // 조회실패
      return {
        ...initialState,
        loading: false,
        success: false,
        updateSuccess: false,
        message: action.payload.response?.data?.message,
      };
    case SUCCESS(FETCH_QNA_PK_NMGT): // 채번 성공
      return {
        ...state,
        success: true,
        loading: false,
        mainPk: action.payload.data?.main_pk[0].pk,
        mainPkCnt: action.payload?.main_cnt,
        updateSuccess: false,
      };
    case REQUEST(FETCH_QNA_PK_NMGT): // 채번 요청
      return {
        ...state,
        loading: true,
        updateSuccess: false,
      };
    case FAILURE(FETCH_QNA_PK_NMGT): // 채번 실패
      return {
        ...initialState,
        loading: false,
        success: false,
        message: action.payload.response?.data?.message,
        updateSuccess: false,
      };
    case SUCCESS(INSERT_QNA): // 등록/수정 성공
      return {
        ...state,
        updateSuccess: true,
        updating: false,
        resultCnt: action.payload.data.resultCnt,
      };
    case REQUEST(INSERT_QNA): // 등록/수정 요청
      return {
        ...state,
        updating: true,
        updateSuccess: false,
      };
    case FAILURE(INSERT_QNA): // 등록/수정 실패
      return {
        ...initialState,
        updating: false,
        updateSuccess: false,
        resultCnt: -1,
        message: action.payload.response?.data?.message,
      };
    case SAVE_SEARCH_FORM_VALUES:
      return {
        ...state,
        searchFormValues: action.payload,
      };
    case RESET_STATE:
      return initialState;
    default:
      return { ...state, updateSuccess: false };
  }
}

/**
 * 질문답변게시판 조회
 */
export const selectQnaList = (searchParams) => {
  return {
    type: FETCH_QNA_LIST,
    payload: fetcQnaList(searchParams),
  };
};

/**
 * 질문답변게시판 pk채번
 */
export const selectQnaPkNmgt = (searchParams) => {
  return {
    type: FETCH_QNA_PK_NMGT,
    payload: fetchQnaPkNmgt(searchParams),
  };
};

/**
 * 질문답변게시판 등록/수정
 */
export const updateQna = (dtos) => {
  return {
    type: INSERT_QNA,
    payload: insertQna(dtos),
  };
};

/**
 * 조회조건 저장
 */
export const saveSearchFormValues = (searchFormValues) => {
  return {
    type: SAVE_SEARCH_FORM_VALUES,
    payload: {
      ...searchFormValues,
      init: false,
    },
  };
};

/**
 * 상태초기화
 */
export const resetState = () => {
  return {
    type: RESET_STATE,
  };
};
