import instance from '../../common/config/api';

/**
 * 기타 - 서울페이플러스 - 계좌조회
 * @param {object} params
 * @returns
 */
export const fetchEtcActInq = (params) =>
  instance.post(`${process.env.REACT_APP_SERVER_URL}/scq/scq/etc/0004/selectEtcActInq`, params);

/**
 * 기타 - 서울페이플러스 - 계좌 등록/수정/삭제
 * @param {object} params
 * @returns
 */
export const saveEtcActInq = (dtos) =>
  instance.post(`${process.env.REACT_APP_SERVER_URL}/scq/scq/etc/0004/saveEtcActInq`, {
    subDto: dtos,
    subDtoCnt: dtos.length,
  });
