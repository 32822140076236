import { SUCCESS, REQUEST, FAILURE } from '../../../common/utils/action-type-utils';
import { fetchEcadBiztAnexFdRcexSts } from '../../../lib/rpt/RPT0006.api';

// actions
const FETCH_ECAD_BIZT_ANEX_FD_RCEX_STS = 'RPT0602V01/FETCH_ECAD_BIZT_ANEX_FD_RCEX_STS';

// initial state
const initialState = {
  loading: false,
  success: true,
  message: '',
  list: [],
};

// reducers
export default function RPT0602V01(state = initialState, action) {
  switch (action.type) {
    case SUCCESS(FETCH_ECAD_BIZT_ANEX_FD_RCEX_STS): // 조회 성공
      return {
        ...state,
        success: true,
        loading: false,
        list: action.payload.data?.output_data,
      };
    case REQUEST(FETCH_ECAD_BIZT_ANEX_FD_RCEX_STS): // 조회 요청
      return {
        ...state,
        loading: true,
        success: false,
      };
    case FAILURE(FETCH_ECAD_BIZT_ANEX_FD_RCEX_STS): // 조회실패
      return {
        ...state,
        list: [],
        loading: false,
        success: false,
        message: action.payload.response?.data?.message,
      };
    default:
      return state;
  }
}

/**
 * 회계별 세출자금수지 현황 조회
 */
export const selectEcadBiztAnexFdRcexSts = (searchParams) => {
  return {
    type: FETCH_ECAD_BIZT_ANEX_FD_RCEX_STS,
    payload: fetchEcadBiztAnexFdRcexSts(searchParams),
  };
};
