import instance from '../../common/config/api';

/**
 * 세입 - 보통예금 이자 자동이체 조회
 * @param {object} params
 * @returns
 */
export const fetchGndpIntAtsList = (params) =>
  instance.post(`${process.env.REACT_APP_SERVER_URL}/scq/scq/arv/0008/selectGndpIntAts`, params);

/**
 * 세입 - 보통예금 이자 입금계좌 조회
 * @param {object} params
 * @returns
 */
export const fetchGndpIntAtsRcvActList = (params) =>
  instance.post(`${process.env.REACT_APP_SERVER_URL}/scq/scq/arv/0008/selectGndpIntAtsRcvActList`, params);
