import { useCallback } from 'react';
import Swal from 'sweetalert2';

function useAlert() {
  /**
   * Alert 팝업 호출
   * @param {string} text
   * @param {string} title
   * @returns
   */
  const alert = useCallback(async (text, title = '확인') => {
    try {
      const htmlText = text.replace(/\n/g, '<br>');
      const result = await Swal.fire({
        title,
        html: htmlText,
        showCloseButton: true,
        showCancelButton: false,
        confirmButtonText: '확인',
        reverseButtons: true,
        closeButtonHtml: '<img src="/assets/images/icon/close.svg" alt="close" />',
      });

      return result.isConfirmed;
    } catch (error) {
      console.error('다이얼로그 오류:', error);
      return false;
    }
  }, []);

  return { alert };
}

export default useAlert;
