import { SUCCESS, REQUEST, FAILURE } from '../../../common/utils/action-type-utils';
import { fetchListStlActTrhs, fetchListStlActTrnPrts } from '../../../lib/cad/cadStlActtrnPrts.api';
import dayjs from 'dayjs';
import { fetchCommonComboList } from '../../../lib/cmm/combo.api';

// actions
const FETCH_STL_ACT_TRN_PRTS_LIST = 'zpbStlActTrnPrts/FETCH_STL_ACT_TRN_PRTS_LIST';
const FETCH_STL_ACT_TRHS_LIST = 'zpbStlActTrnPrts/FETCH_STL_ACT_TRHS_LIST';
//기관
const FETCH_IST_INFO_LIST = 'zpbStlActTrnPrts/FETCH_IST_INFO_LIST';
//부서
const FETCH_DEPT_CD_LIST = 'zpbStlActTrnPrts/FETCH_DEPT_CD_LIST';
//사업자번호
const FETCH_BIZ_NO_LIST = 'zpbStlActTrnPrts/FETCH_BIZ_NO_LIST';
//회계연도
const FETCH_ACC_YR_LIST = 'zpbStlActTrnPrts/FETCH_ACC_YR_LIST';
//계좌번호
const FETCH_ACT_NO_LIST = 'zpbStlActTrnPrts/FETCH_ACT_NO_LIST';

const SAVE_SEARCH_FORM_VALUES = 'zpbStlActTrnPrts/SAVE_SEARCH_FORM_VALUES';
const RESET_STATE = 'zpbStlActTrnPrts/RESET_STATE';

export const initFormValues = {
  /*지자체*/
  cus_ist_cd: '', // 기관명
  cus_dept_cd: '', // 부서명
  biz_no: '', // 사업자번호
  ydcs8a1_t8a12_act_no: 'all', // 계좌번호
  ydcs8a1_t8a12_inq_sdt: dayjs().format('YYYY-MM-DD'), // 조회기간
  ydcs8a1_t8a12_inq_edt: dayjs().format('YYYY-MM-DD'), // 조회기간
};

// initial state
const initialState = {
  loading: false,
  success: false,
  message: '',
  list: [],
  list2: [],
  totalCount: 0,
  //콤보 - 기관명
  istInfoList: [],
  istInfoLoading: false,
  istInfoSuccess: true,
  // 부서명
  deptCdSuccess: false,
  deptCdLoading: false,
  deptCdList: [],
  // 사업자번호
  bizNoSuccess: false,
  bizNoLoading: false,
  bizNoList: [],
  //계좌번호
  actNoLoading: false,
  actNoList: [],
  actNoSuccess: false,
  searchFormValues: {
    init: true,
    ...initFormValues,
  },
};

// reducers
export default function zpbStlActTrnPrts(state = initialState, action) {
  switch (action.type) {
    case SUCCESS(FETCH_STL_ACT_TRN_PRTS_LIST): // 조회 성공
      return {
        ...state,
        success: true,
        loading: false,
        list: action.payload.data?.output_data,
        totalCount: action.payload?.data?.output_data_cnt,
        message: false,
      };
    case REQUEST(FETCH_STL_ACT_TRN_PRTS_LIST): // 조회 요청
      return {
        ...state,
        success: false,
        loading: true,
        message: '',
      };
    case FAILURE(FETCH_STL_ACT_TRN_PRTS_LIST): // 조회실패
      return {
        ...state,
        loading: false,
        success: false,
        message: action.payload.response?.data?.message,
      };
    case SUCCESS(FETCH_STL_ACT_TRHS_LIST): // 조회 성공
      return {
        ...state,
        success: true,
        loading: false,
        list2: action.payload.data?.output_data,
        totalCount: action.payload?.data?.output_data_cnt,
        message: false,
      };
    case REQUEST(FETCH_STL_ACT_TRHS_LIST): // 조회 요청
      return {
        ...state,
        success: false,
        loading: true,
      };
    case FAILURE(FETCH_STL_ACT_TRHS_LIST): // 조회실패
      return {
        ...state,
        loading: false,
        success: false,
        message: action.payload.response?.data?.message,
      };
    case SUCCESS(FETCH_IST_INFO_LIST): // 조회 성공
      return {
        ...state,
        istInfoSuccess: true,
        istInfoLoading: false,
        istInfoList: action.payload.data?.subDto,
      };
    case REQUEST(FETCH_IST_INFO_LIST): // 조회 요청
      return {
        ...state,
        istInfoLoading: true,
        istInfoSuccess: false,
      };
    case FAILURE(FETCH_IST_INFO_LIST): // 조회실패
      return {
        istInfoLoading: false,
        istInfoSuccess: false,
        istInfoList: [],
      };
    // 부서명
    case SUCCESS(FETCH_DEPT_CD_LIST): // 조회 성공
      return {
        ...state,
        deptCdSuccess: true,
        deptCdLoading: false,
        deptCdList: action.payload.data?.subDto,
      };
    case REQUEST(FETCH_DEPT_CD_LIST): // 조회 요청
      return {
        ...state,
        deptCdLoading: true,
        deptCdSuccess: false,
      };
    case FAILURE(FETCH_DEPT_CD_LIST): // 조회실패
      return {
        ...state,
        deptCdLoading: false,
        deptCdSuccess: false,
        deptCdList: [],
      };
    // 사업자 번호
    case SUCCESS(FETCH_BIZ_NO_LIST): // 조회 성공
      return {
        ...state,
        bizNoSuccess: true,
        bizNoLoading: false,
        bizNoList: action.payload.data?.subDto,
      };
    case REQUEST(FETCH_BIZ_NO_LIST): // 조회 요청
      return {
        ...state,
        bizNoLoading: true,
        bizNoSuccess: false,
      };
    case FAILURE(FETCH_BIZ_NO_LIST): // 조회실패
      return {
        ...state,
        bizNoLoading: false,
        bizNoSuccess: false,
        bizNoList: [],
      };
    //계좌번호
    case SUCCESS(FETCH_ACT_NO_LIST): // 조회 성공
      return {
        ...state,
        actNoSuccess: true,
        actNoLoading: false,
        actNoList: action.payload.data?.subDto,
      };
    case REQUEST(FETCH_ACT_NO_LIST): // 조회 요청
      return {
        ...state,
        actNoLoading: true,
        actNoSuccess: false,
      };
    case FAILURE(FETCH_ACT_NO_LIST): // 조회실패
      return {
        ...state,
        actNoLoading: false,
        actNoSuccess: false,
        actNoList: [],
      };
    case SAVE_SEARCH_FORM_VALUES:
      return {
        ...state,
        // 콤보 - 기관
        istInfoSuccess: false,
        istInfoLoading: false,
        // 콤보 - 부서명
        deptCdSuccess: false,
        deptCdLoading: false,
        // 콤보 - 사업자 번호
        bizNoSuccess: false,
        bizNoLoading: false,
        // 콤보 - 계좌번호
        mbrNoSuccess: false,
        mbrNoLoading: false,
        searchFormValues: action.payload,
      };
    case RESET_STATE:
      return initialState;
    default:
      return state;
  }
}

/**
 * 제로페이 - 결제계좌 거래내역조회  (결제 계좌거래내역 조회)
 */
export const selectListStlActTrnPrts = (searchParams) => {
  return {
    type: FETCH_STL_ACT_TRN_PRTS_LIST,
    payload: fetchListStlActTrnPrts(searchParams),
  };
};

/**
 * 제로페이 - 결제계좌 거래내역조회 (거래명세 건수 및 거래명세 조회)
 */
export const selectListStlActTrhs = (searchParams) => {
  return {
    type: FETCH_STL_ACT_TRHS_LIST,
    payload: fetchListStlActTrhs(searchParams),
  };
};
/**
 * 기관명 콤보 조회
 */
export const selectIstInfoComboList = (searchParams) => {
  return {
    type: FETCH_IST_INFO_LIST,
    payload: fetchCommonComboList({ slt_box_no: '005', ...searchParams }),
  };
};
/**
 * 부서명 콤보 조회
 */
export const selectDeptCdComboList = (searchParams) => {
  return {
    type: FETCH_DEPT_CD_LIST,
    payload: fetchCommonComboList({ slt_box_no: '199', ...searchParams }),
  };
};
/**
 * 사업자 번호 콤보 조회
 */
export const selectBizNoComboList = (searchParams) => {
  return {
    type: FETCH_BIZ_NO_LIST,
    payload: fetchCommonComboList({ slt_box_no: '044', ...searchParams }),
  };
};
/**
 * 계좌번호 콤보 조회
 */
export const selectActNoComboList = (searchParams) => {
  return {
    type: FETCH_ACT_NO_LIST,
    //206->211
    payload: fetchCommonComboList({ slt_box_no: '211', ...searchParams }),
  };
};

/**
 * 조회조건 저장
 */
export const saveSearchFormValues = (searchFormValues) => {
  return {
    type: SAVE_SEARCH_FORM_VALUES,
    payload: {
      ...searchFormValues,
      init: false,
    },
  };
};

/**
 * 승인내역 상태초기화
 */
export const resetZpbStlActTrnPrts = () => {
  return {
    type: RESET_STATE,
  };
};
