import dayjs from 'dayjs';
import { SUCCESS, REQUEST, FAILURE } from '../../../common/utils/action-type-utils';
import { fetchRcnPtsMntg } from '../../../lib/fxp/FXP0004.api';

// actions
const FETCH_RCN_PTS_MNTG = 'FXP0401V01/FETCH_RCN_PTS_MNTG';

const RESET_INITIAL_STATE = 'FXP0401V01/RESET_INITIAL_STATE';
const SAVE_SEARCH_FORM_VALUES = 'FXP0401V01/SAVE_SEARCH_FORM_VALUES';

const CLEAR_MESSAGE = 'FXP0401V01/CLEAR_MESSAGE';

export const initFormValues = {
  inq_dt: dayjs().add(-1, 'day').format('YYYYMMDD'),
};

// initial state
const initialState = {
  loading: false,
  success: false,
  message: '',
  list: [],
  listTitle: {},
  totalCount: 0,

  // 검색조건
  searchFormValues: {
    init: true,
    ...initFormValues,
  },
};

// reducers
export default function FXP0401V01(state = initialState, action) {
  switch (action.type) {
    case SUCCESS(FETCH_RCN_PTS_MNTG): // 조회 성공
      return {
        ...state,
        success: true,
        loading: false,
        list: action.payload.data?.main,
        listTitle: action.payload.data?.output_title,
        totalCount: action.payload?.data?.mainCnt,
      };
    case REQUEST(FETCH_RCN_PTS_MNTG): // 조회 요청
      return {
        ...state,
        loading: true,
        success: false,
      };
    case FAILURE(FETCH_RCN_PTS_MNTG): // 조회실패
      return {
        ...state,
        list: [],
        loading: false,
        success: false,
        message: action.payload.response?.data?.message,
      };
    case SAVE_SEARCH_FORM_VALUES:
      return {
        ...state,
        searchFormValues: action.payload,
      };
    case RESET_INITIAL_STATE:
      return initialState;
    case CLEAR_MESSAGE:
      return {
        ...state,
        message: '',
        success: false,
      };
    default:
      return state;
  }
}

/**
 * 대사현황 모니터링 조회
 */
export const selectRcnPtsMntg = (searchParams) => {
  return {
    type: FETCH_RCN_PTS_MNTG,
    payload: fetchRcnPtsMntg(searchParams),
  };
};

/**
 * 메시지초기화
 */
export const clearMessage = () => {
  return {
    type: CLEAR_MESSAGE,
  };
};

/**
 * 조회조건 저장
 */
export const saveSearchFormValues = (searchFormValues) => {
  return {
    type: SAVE_SEARCH_FORM_VALUES,
    payload: {
      ...searchFormValues,
      init: false,
    },
  };
};

/**
 * 상태초기화
 */
export const resetInitialState = () => {
  return {
    type: RESET_INITIAL_STATE,
  };
};
