import instance from '../../common/config/api';

/**
 * 세출 - 반납(여입) 거래명세
 * @param {object} params
 * @returns
 */
export const fetchAnexRtrnRmpiTrhs = (params) =>
  instance.post(`${process.env.REACT_APP_SERVER_URL}/scq/scq/axp/0003/selectAnexRtrnRmpiTrhs`, params);

export const fetchAnexRtrnRmpiTrhsDtl = (params) =>
  instance.post(`${process.env.REACT_APP_SERVER_URL}/scq/scq/ils/0001/selectEvdXpnTrhsDtl`, params);
