import { memo, useCallback, useEffect, useRef, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { lpad } from '../../common/utils/utils';
import WOBadge from '../../components/common/WOBadge';
import WOIconButton from '../../components/common/WOIconButton';
import useAlert from '../../hooks/useAlert';
import { sendLogout } from '../../redux/modules/cmm/login.reducer';

function LogoutArea({ onLogout }) {
  const { sessionExpireTime } = useSelector((state) => state.mainState);
  const [time, setTime] = useState(sessionExpireTime.time);
  const { alert } = useAlert();
  const dispatch = useDispatch();
  // 인증만료시간
  const timer = useRef(null);
  const setTimer = useCallback(() => {
    if (timer.current) {
      clearInterval(timer.current);
    }
    setTime(sessionExpireTime.time);
    timer.current = setInterval(() => {
      setTime((prev) => prev - 1);
    }, 1000);
  }, [sessionExpireTime.time]);

  useEffect(() => {
    if (sessionExpireTime.time > 0) {
      setTimer(sessionExpireTime.time);
    }
  }, [sessionExpireTime, setTimer]);

  useEffect(() => {
    const sessionExpireAlert = async () => {
      if (await alert('세션 만료로 자동 로그아웃 되셨습니다.')) {
        dispatch(sendLogout());
      }
    };
    if (time < 0) {
      if (timer.current) {
        clearInterval(timer.current);
        setTime(0);
        sessionExpireAlert();
      }
    }
  }, [alert, dispatch, time]);

  useEffect(() => {
    return () => {
      if (timer.current) {
        clearInterval(timer.current);
      }
    };
  }, []);

  return (
    <div className="info__noti">
      {/* hh:mm:ss */}
      {`${lpad(Math.floor(time / 60), 2, '0')}:${lpad(time % 60, 2, '0')}`}
      <WOBadge>
        <WOIconButton icon={'logout'} width={24} height={24} onClick={onLogout} />
      </WOBadge>
    </div>
  );
}

export default memo(LogoutArea);
