import instance from '../../common/config/api';

/**
 * 아이디 중복체크
 * @param {object} params
 * @returns
 */
export const checkDupUserId = (params) =>
  instance.post(`${process.env.REACT_APP_SERVER_URL}/scq/scq/cmm/user/selectDupUser`, params);

/**
 * 기관코드 조회
 * @param {object} params
 * @returns
 */
export const fetchIstCd = () =>
  instance.post(`${process.env.REACT_APP_SERVER_URL}/scq/scq/cmm/Logout/CmmComboList`, {
    slt_box_no: '131',
    cus_ist_cd: '',
    dept_cd: '',
  });

/**
 * 부서코드 조회
 * @param {object} params
 * @returns
 */
export const fetchDeptCd = (istCd) =>
  instance.post(`${process.env.REACT_APP_SERVER_URL}/scq/scq/cmm/Logout/CmmComboList`, {
    slt_box_no: '053',
    cus_ist_cd: istCd,
    dept_cd: '',
  });

/**
 * 회원가입
 * @param {object} params
 * @returns
 */
export const registerUser = (params) =>
  instance.post(`${process.env.REACT_APP_SERVER_URL}/scq/scq/cmm/user/registerUser`, params);

/**
 * 인증번호 전송
 * @param {object} params
 * @returns
 */
export const sendAuthNumber = (params) =>
  instance.post(`${process.env.REACT_APP_SERVER_URL}/scq/scq/cmm/crtfno/send`, params);

/**
 * 인증번호 검증
 * @param {object} params
 * @returns
 */
export const checkAuthNumber = (params) =>
  instance.post(`${process.env.REACT_APP_SERVER_URL}/scq/scq/cmm/crtfno/chk`, params);

/**
 * 비밀번호 찾기
 * @param {object} params
 * @returns
 */
export const updateUserPwd = (params) =>
  instance.post(`${process.env.REACT_APP_SERVER_URL}/scq/scq/cmm/user/updateUserPwdPc`, params);

/**
 * 비밀번호 찾기 VDI
 * @param {object} params
 * @returns
 */
export const updateUserPwdForVDI = (params) =>
  instance.post(`${process.env.REACT_APP_SERVER_URL}/scq/scq/cmm/user/updateUserPwd`, params);

/**
 * 비밀번호 변경(로그인이후)
 * @param {object} params
 * @returns
 */
export const updateEsUserPwdPc = (params) =>
  instance.post(`${process.env.REACT_APP_SERVER_URL}/scq/scq/cmm/user/updateEsUserPwdPc`, params);

/**
 * 비밀번호 변경(로그인이후 VDI용)
 * @param {object} params
 * @returns
 */
export const updateEsUserPwdForVDI = (params) =>
  instance.post(`${process.env.REACT_APP_SERVER_URL}/scq/scq/cmm/user/updateEsUserPwd`, params);

/**
 * 아이디 찾기
 * @param {object} params
 * @returns
 */
export const fetchFindUserId = (params) =>
  instance.post(`${process.env.REACT_APP_SERVER_URL}/scq/scq/cmm/user/selectUserId`, params);

/**
 * 사용자 부서변경
 * @param {object} params
 * @returns
 */
export const fetchUserDeptChg = (params) =>
  instance.post(`${process.env.REACT_APP_SERVER_URL}/scq/scq/cmm/user/saveUserDeptChg`, params);

/**
 * 사용자 부서변경 진행중 건수
 * @param {object} params
 * @returns
 */
export const fetchUserDeptChgCnt = (params) =>
  instance.post(`${process.env.REACT_APP_SERVER_URL}/scq/scq/cmm/user/selectUserDeptChgCnt`, params);
