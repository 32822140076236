import instance from '../../common/config/api';

/**
 * 시스템별 메뉴 조회
 * @param {object} params
 * @returns
 */
export const fetchMenuBySystemList = (params) =>
  instance.post(`${process.env.REACT_APP_SERVER_URL}/scq/scq/sys/0003/selectMenuBySystemList`, params);

/**
 * 시스템별 메뉴 등록
 * @param {array} dtos
 * @returns
 */
export const saveMenuBySystemList = (mainDtos, sub01Dtos) =>
  instance.post(`${process.env.REACT_APP_SERVER_URL}/scq/scq/sys/0003/saveMenuBySystemList`, {
    main: mainDtos,
    mainCnt: mainDtos.length,
    sub01: sub01Dtos,
    sub01Cnt: sub01Dtos.length,
    sub02: [],
    sub02Cnt: 0,
  });

/**
 * 시스템별 메뉴 PK 조회
 * @returns
 */
export const fetchMenuBySystemPk = () =>
  instance.post(`${process.env.REACT_APP_SERVER_URL}/scq/scq/sys/0003/selectMenuBySystemPk`);
