import React, { useEffect, useState } from 'react';
import styled from 'styled-components';
import { Link } from 'react-router-dom';
import { useSelector } from 'react-redux';

function Error404() {
  const { loading: menuLoading } = useSelector((state) => state.menuState);
  const [loading, setLoading] = useState(true);

  // 404 너무 빨리 표시되는 문제 수정
  useEffect(() => {
    if (!menuLoading) {
      setTimeout(() => {
        setLoading(menuLoading);
      }, 3000);
    }
  }, [menuLoading]);

  return (
    <>
      <Wrapper>
        {!loading && (
          <ErrorArea>
            <div className="error-img">
              <img src="/assets/images/etc/404.svg" alt="404 에러 이미지" />
            </div>
            <div className="title-wrap">
              <div className="title">페이지를 찾을 수 없습니다.</div>
              <div className="text">현재 페이지가 존재하지 않거나, 이용할 수 없는 페이지입니다.</div>
            </div>
            <MainBtn to="/">메인으로</MainBtn>
          </ErrorArea>
        )}
      </Wrapper>
    </>
  );
}

export default Error404;

const Wrapper = styled.div`
  width: 100%;
  min-width: 600px;
  height: 100%;
  padding: 294px 0 0 80px;
  background: #fff;
`;

const ErrorArea = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  width: fit-content;
  margin: 0 auto;
  .error-img {
    width: 512px;
    height: 212px;
    margin-bottom: 36px;
    img {
      width: 100%;
      height: auto;
    }
  }
  .title-wrap {
    margin-bottom: 40px;
    text-align: center;
    .title {
      margin-bottom: 8px;
      color: #333;
      font-size: 28px;
      font-weight: 700;
      line-height: 42px;
    }
    .text {
      color: var(--C-Dark-06);
      font-size: 18px;
      font-weight: 700;
      line-height: 27px;
    }
  }
`;

const MainBtn = styled(Link)`
  display: flex;
  justify-content: center;
  align-items: center;
  width: 400px;
  height: 52px;
  border-radius: 4px;
  background: var(--C-Dark-12);
  color: #fff;
  font-size: 18px;
  font-weight: 700;
  line-height: 27px;
`;
