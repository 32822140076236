import instance from '../../common/config/api';

/**
 * 운용상품 - 보통계좌 현황
 * @param {object} params
 * @returns
 */

export const fetchGndpPtsList = (params) =>
  instance.post(`${process.env.REACT_APP_SERVER_URL}/scq/scq/fxp/0002/selectDtaGndpAc`, params);

/**
 * 운용상품 - 보통계좌 현황.보통예금계좌 건수 상세조회
 * @param {object} params
 * @returns
 */
export const fetchDtaGndpAcCnt = (params) =>
  instance.post(`${process.env.REACT_APP_SERVER_URL}/scq/scq/fxp/0002/selectDtaGndpAcCnt`, params);

/**
 * 운용상품 - 전 영입일 조회
 * @param {object} params
 * @returns
 */
export const fetchBbzdtInf = (params) =>
  instance.post(`${process.env.REACT_APP_SERVER_URL}/scq/scq/fxp/0002/selectBbzdtInf`, params);
