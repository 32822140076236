import { SUCCESS, REQUEST, FAILURE } from '../../../common/utils/action-type-utils';
import { fetchEtcAsstAtcntPtsList } from '../../../lib/etc/etcAsstAtcntPts';
import dayjs from 'dayjs';
// actions
const FETCH_ASSTATCNTPTS = 'etcAsstAtcntPts/FETCH_ASSTATCNTPTS';
const RESET_STATE = 'etcAsstAtcntPts/RESET_STATE';
const SAVE_SEARCH_FORM_VALUES = 'etcAsstAtcntPts/SAVE_SEARCH_FORM_VALUES';
const allOption = { id: 'all', label: '전체' };
// initial state

export const initFormValues = {
  ydcs8a1_t8a02_inq_dt: dayjs().add(-1, 'day').format('YYYY-MM-DD'), // 기준일자
  ydcs8a1_t8a01_inq_seg1: '2', // 조회구분
  ydcs8a1_t8a01_inq_seg: '2', // 해지구분
  br_cd: '', // 영업점코드
};

const initialState = {
  loading: false,
  success: false,
  message: '',
  list: [],
  totalCount: 0,
  ydcs8a1_t8a01_inq_seg: [
    { id: '1', label: '해지분 제외' },
    { id: '2', label: '해지분 포함' },
  ],
  ydcs8a1_t8a01_inq_seg1: [{ ...allOption }, { id: '2', label: '시구청영업점' }],
  searchFormValues: {
    init: true,
    ...initFormValues,
  },
};

// reducers
export default function etcAsstAtcntPts(state = initialState, action) {
  switch (action.type) {
    case SUCCESS(FETCH_ASSTATCNTPTS): // 조회 성공
      return {
        ...state,
        success: true,
        loading: false,
        list: action.payload.data?.subDto,
        totalCount: action.payload?.subDtoCnt,
      };
    case REQUEST(FETCH_ASSTATCNTPTS): // 조회 요청
      return {
        ...state,
        loading: true,
        success: false,
      };
    case FAILURE(FETCH_ASSTATCNTPTS): // 조회실패
      return {
        ...initialState,
        loading: false,
        success: false,
        message: action.payload.response?.data?.message,
      };
    case RESET_STATE:
      return initialState;
    case SAVE_SEARCH_FORM_VALUES:
      return {
        ...state,
        // 콤보 - 기관
        brCdSuccess: false,
        brCdLoading: false,
        searchFormValues: action.payload,
      };
    default:
      return state;
  }
}

/**
 * 기타 - 보조금 좌수 현황
 */
export const selectEtcAsstAtcntPtsList = (searchParams) => {
  return {
    type: FETCH_ASSTATCNTPTS,
    payload: fetchEtcAsstAtcntPtsList(searchParams),
  };
};

/**
 * 조회조건 저장
 */
export const saveSearchFormValues = (searchFormValues) => {
  return {
    type: SAVE_SEARCH_FORM_VALUES,
    payload: {
      ...searchFormValues,
      init: false,
    },
  };
};

/**
 * 보조금 좌수 현황 상태초기화
 */
export const resetEtcAsstAtcntPts = () => {
  return {
    type: RESET_STATE,
  };
};
