import { SUCCESS, REQUEST, FAILURE } from '../../../common/utils/action-type-utils';
import { fetchAbvTrnPcptGatList } from '../../../lib/fds/FDS0001.api';
import dayjs from 'dayjs';

// actions
const FETCH_ABV_TRN_PCPT_GAT_LIST = 'FDS0101V01/FETCH_ABV_TRN_PCPT_GAT_LIST';
const CLEAR_LIST = 'FDS0101V01/CLEAR_LIST';
const SAVE_SEARCH_FORM_VALUES = 'FDS0101V01/SAVE_SEARCH_FORM_VALUES';
const RESET_STATE = 'FDS0101V01/RESET_STATE';

export const initFormValues = {
  // 검색 영역
  fdsLevVal: 'all', //등급
  fdsIstCd: 'all', //기관구분
  fdsDeptCd: 'all', //부서명
  fdsPolicyType: 'all', //분야
  startTrnDt: dayjs().add(-7, 'days').format('YYYY-MM-DD'), //조회기간
  endTrnDt: dayjs().format('YYYY-MM-DD'), //조회기간
  fdsAccType: 'all', //계좌구분
};

// initial state
const initialState = {
  loading: false,
  success: false,
  message: '',
  list: [],
  totalCount: 0,
  fdsLevVal01: 0,
  fdsLevVal02: 0,
  fdsLevVal03: 0,
  // 검색조건
  searchFormValues: {
    init: true,
    ...initFormValues,
  },
};

// reducers
export default function FDS0101V01(state = initialState, action) {
  switch (action.type) {
    case SUCCESS(FETCH_ABV_TRN_PCPT_GAT_LIST): // 조회 성공
      return {
        ...state,
        success: true,
        loading: false,
        list: action.payload.data?.main,
        totalCount: action.payload?.data?.totalCnt,
        fdsLevVal01: action.payload?.data?.lev01Cnt,
        fdsLevVal02: action.payload?.data?.lev02Cnt,
        fdsLevVal03: action.payload?.data?.lev03Cnt,
      };
    case REQUEST(FETCH_ABV_TRN_PCPT_GAT_LIST): // 조회 요청
      return {
        ...state,
        loading: true,
        success: false,
        message: '',
      };
    case FAILURE(FETCH_ABV_TRN_PCPT_GAT_LIST): // 조회실패
      return {
        ...initialState,
        loading: false,
        success: false,
        message: action.payload.response?.data?.message,
      };
    case SAVE_SEARCH_FORM_VALUES:
      return {
        ...state,
        searchFormValues: action.payload,
      };
    case RESET_STATE:
      return initialState;
    default:
      return state;
  }
}

/**
 * 검출현황 조회
 */
export const selectAbvTrnPcptGatList = (searchParams) => {
  return {
    type: FETCH_ABV_TRN_PCPT_GAT_LIST,
    payload: fetchAbvTrnPcptGatList(searchParams),
  };
};

/**
 * 리스트 초기화
 */
export const clearList = () => {
  return {
    type: CLEAR_LIST,
  };
};

/**
 * 조회조건 저장
 */
export const saveSearchFormValues = (searchFormValues) => {
  return {
    type: SAVE_SEARCH_FORM_VALUES,
    payload: {
      ...searchFormValues,
      init: false,
    },
  };
};

/**
 * 상태초기화
 */
export const resetState = () => {
  return {
    type: RESET_STATE,
  };
};
