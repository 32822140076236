/**
 * 금고영업점 지자체정보관리
 */
import instance from '../../common/config/api';

const prefix = `${process.env.REACT_APP_SERVER_URL}/scq/scq/brc/0002`;

/**
 * 입찰관련 우수사례 조회(다건 조회)
 * @param {object} params
 * @returns
 */
export const fetchBestCaseList = (params) => instance.post(`${prefix}/selectBestCaseList`, params);
/**
 * 입찰관련Qna 조회(다건 조회)
 * @param {object} params
 * @returns
 */
export const fetchQnaList = (params) => instance.post(`${prefix}/selectQnaList`, params);
/**
 * 섭외이력 조회(다건 조회)
 * @param {object} params
 * @returns
 */
export const fetchRecHistList = (params) => instance.post(`${prefix}/selectRecHistList`, params);
/**
 * 급여이체 점별 집계
 * @param {object} params
 * @returns
 */
export const fetchSalaryTrnsEachIstList = (params) => instance.post(`${prefix}/selectSalaryTrnsEachIstList`, params);
/**
 * 급여이체 점별 전체내역
 * @param {object} params
 * @returns
 */
export const fetchSalaryTrnsEachIstAllList = (params) =>
  instance.post(`${prefix}/selectSalaryTrnsEachIstAllList`, params);
/**
 * 급여이체 점별 전체내역
 * @param {object} params
 * @returns
 */
export const fetchSalaryTrnsIstAllList = (params) => instance.post(`${prefix}/selectSalaryTrnsIstAllList`, params);
/**
 * 예산수혜업체 관리자 조회
 * @param {object} params
 * @returns
 */
export const fetchBnfCoDtenAllList = (params) => instance.post(`${prefix}/selectBnfCoDtenAllList`, params);
/**
 * 예산수혜업체 시/구청 조회
 * @param {object} params
 * @returns
 */
export const fetchBnfCoDtenIstList = (params) => instance.post(`${prefix}/selectBnfCoDtenIstList`, params);
/**
 * 지자체 담당자 지원 - 기관원장 조회 ( 다건 조회 )
 * @param {object} params
 * @returns
 */
export const fetchIstChrgPersonIstLdgList = (params) =>
  instance.post(`${prefix}/selectIstChrgPersonIstLdgList`, params);
/**
 * 지자체 담당자 지원 - 영업담당자 내역 조회
 * @param {object} params
 * @returns
 */
export const fetchIstChrgPersonList = (params) => instance.post(`${prefix}/selectIstChrgPersonList`, params);
/**
 * 지자체 담당자 지원 - 인원수 ( 단건 조회 )
 * @param {object} params
 * @returns
 */
export const fetchIstChrgPersonPct = (params) => instance.post(`${prefix}/selectIstChrgPersonPct`, params);
/**
 * 지자체 담당자 지원 - 관련대상자 조회 ( 다건 조회 )
 * @param {object} params
 * @returns
 */
export const fetchIstChrgPersonRelTgpeNmList = (params) =>
  instance.post(`${prefix}/selectIstChrgPersonRelTgpeNmList`, params);
/**
 * 지자체 담당자 지원 - 섭외이력 조회 ( 다건 조회 )
 * @param {object} params
 * @returns
 */
export const fetchIstChrgPersonLiasHstList = (params) =>
  instance.post(`${prefix}/selectIstChrgPersonLiasHstList`, params);
/**
 * 지자체 담당자 지원 - 가족정보 조회 ( 다건 조회 )
 * @param {object} params
 * @returns
 */
export const fetchIstChrgPersonFmlInfList = (params) =>
  instance.post(`${prefix}/selectIstChrgPersonFmlInfList`, params);
/**
 * 지자체 담당자 저장 ( 다건 등록 )
 * @param {object} params
 * @returns
 */
export const saveCharPersonInfo = (params) => instance.post(`${prefix}/saveCharPersonInfo`, params);
/**
 * 지자체 담당자 관련대상자 저장 ( 다건 등록 )
 * @param {object} params
 * @returns
 */
export const saveCharPersonRelTgpeNmInfo = (params) => instance.post(`${prefix}/saveCharPersonRelTgpeNmInfo`, params);
/**
 * 지자체 담당자 섭외이력 저장 ( 다건 등록 )
 * @param {object} params
 * @returns
 */
export const saveCharPersonLiasHstInfo = (params) => instance.post(`${prefix}/saveCharPersonLiasHstInfo`, params);
/**
 * 지자체 담당자 입력 PK 채번 ( 단건 조회 )
 * @param {object} params
 * @returns
 */
export const fetchIstChrgPersonPkNmg = (params) => instance.post(`${prefix}/selectIstChrgPersonPkNmg`, params);
/**
 * 지자체 담당자 입력 PK 채번 ( 단건 조회 )
 * @param {object} params
 * @returns
 */
export const fetchIstChrgPersonLiasHstPkNmg = (params) =>
  instance.post(`${prefix}/selectIstChrgPersonLiasHstPkNmg`, params);
/**
 * 지자체 담당자 지원 - 사용자 ist_cd 조회 ( 단건 조회 )
 * @param {object} params
 * @returns
 */
export const fetchIstChrgPersonUserIstCd = (params) => instance.post(`${prefix}/selectIstChrgPersonUserIstCd`, params);
/**
 * 지자체 담당자 지원 - 사용자 ist_cd 조회 ( 단건 조회 )
 * @param {object} params
 * @returns
 */
export const fetchIstChrgPersonUserChk = (params) => instance.post(`${prefix}/selectIstChrgPersonUserChk`, params);
/**
 * 크로스 셀 점별 집계 조회 ( 다건 조회 )
 * @param {object} params
 * @returns
 */
export const fetchCrossSellList = (params) => instance.post(`${prefix}/selectCrossSellList`, params);
/**
 * 크로스 셀 상세 내역 조회 ( 다건 조회 )
 * @param {object} params
 * @returns
 */
export const fetchCrossSellDtl = (params) => instance.post(`${prefix}/selectCrossSellDtl`, params);
/**
 * 지자체 정보 조회 ( 다건 조회 )
 * @param {object} params
 * @returns
 */
export const fetchIstInfoList = (params) => instance.post(`${prefix}/selectIstInfoList`, params);
/**
 * 지자체 정보 저장 ( 다건 등록 )
 * @param {object} params
 * @returns
 */
export const insertIstInfo = (params) => instance.post(`${prefix}/insertIstInfo`, params);
/**
 * 지자체 정보 PK 조회 ( 단건 조회 )
 * @param {object} params
 * @returns
 */
export const fetchIstInfoPkNmgt = (params) => instance.post(`${prefix}/selectIstInfoPkNmgt`, params);
/**
 * 기관별 사업자번호 조회 ( 다건 조회 )
 * @param {object} params
 * @returns
 */
export const fetchBizNoMng = (params) => instance.post(`${prefix}/selectBizNoMng`, params);
/**
 * 기관별 사업자번호 중복리스트 조회 ( 다건 조회 )
 * @param {object} params
 * @returns
 */
export const fetchBizNoDupMng = (params) => instance.post(`${prefix}/selectBizNoDupMng`, params);
/**
 * 기관별 사업자번호 저장 ( 단건 등록 )
 * @param {object} params
 * @returns
 */
export const saveBizInfo = (params) => instance.post(`${prefix}/saveBizInfo`, params);

export const removeBizInfo = (params) => instance.post(`${prefix}/deleteBizInfo`, params);
