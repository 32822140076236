import { SUCCESS, REQUEST, FAILURE } from '../../../common/utils/action-type-utils';
import { fetchUserList, updateUserList } from '../../../lib/sys/SYS0310M01.api';
import {
  fetchUserMngList,
  insertUserMngList,
  fetchUserApvList,
  insertTrdnRsn,
  updateUserPwd,
  fetchUserDeptChgApvList,
} from '../../../lib/sys/SYS0002.api';
import dayjs from 'dayjs';

// actions
const FETCH_USER_LIST = 'SYS0201M01/FETCH_USER_LIST';
const SAVE_USER_LIST = 'SYS0201M01/INSERT_IP_MNG';
const FETCH_USER_MNG_LIST = 'SYS0201M01/FETCH_USER_MNG_LIST';
const UPDATE_USER_MNG_LIST = 'SYS0201M01/UPDATE_USER_MNG_LIST';
const FETCH_USER_APV_LIST = 'SYS0201M01/FETCH_USER_APV_LIST';
const INSERT_TRDN_RSN = 'SYS0201M01/INSERT_TRDN_RSN';
const UPDATE_USER_PWD = 'SYS0201M01/UPDATE_USER_PWD';
const FETCH_USER_DEPT_CHG_APV_LIST = 'SYS0201M01/FETCH_USER_DEPT_CHG_APV_LIST';
const SAVE_SEARCH_FORM_VALUES = 'SYS0201M01/SAVE_SEARCH_FORM_VALUES';
const RESET_STATE = 'SYS0201M01/RESET_STATE';

export const initFormValues = {
  // 검색 영역
  sch_gubun: 'S', //검색구분(기관 or 영업점)
  cus_ist_cd_search: 'all', //기관명
  dept_cd_search: 'all', //부서명
  br_cd_search: 'all', //영업점명
  user_nm: '', //사용자명
  user_tp_cd_search: 'all', //사용자유형
  log_date: dayjs().format('YYYY-MM-DD'), //최종로그인일자
  log_date_chk: '0', //최종로그인일자 옵션(1: 이하, 2: 이상, 3:미사용, '': 전체)
  jnng_apv_cd: '00', //승인여부
};

// initial state
const initialState = {
  loading: false,
  success: false,
  message: '',
  list: [],
  details: [],
  totalCount: 0,
  updating: false,
  updateSuccess: false,
  resultCnt: -1,
  userMngLoading: false,
  userMngSuccess: false,
  userMngMessage: '',
  userMngList: [],
  userApvLoading: false,
  userApvSuccess: false,
  userApvMessage: '',
  userApvList: [],
  deptChgApvLoading: false,
  deptChgApvSuccess: false,
  deptChgApvMessage: '',
  deptChgApvList: [],
  // 검색조건
  searchFormValues: {
    init: true,
    ...initFormValues,
  },
};

// reducers
export default function SYS0201M01(state = initialState, action) {
  switch (action.type) {
    case SUCCESS(FETCH_USER_LIST): // 조회 성공
      return {
        ...state,
        success: true,
        loading: false,
        list: action.payload.data?.main,
        totalCount: action?.payload?.data?.totalCnt,
        updateSuccess: false,
      };
    case REQUEST(FETCH_USER_LIST): // 조회 요청
      return {
        ...state,
        loading: true,
        message: '',
        updateSuccess: false,
      };
    case FAILURE(FETCH_USER_LIST): // 조회실패
      return {
        ...initialState,
        loading: false,
        success: false,
        message: action.payload.response?.data?.message,
        updateSuccess: false,
      };
    case SUCCESS(SAVE_USER_LIST): // 갱신 성공
    case SUCCESS(UPDATE_USER_MNG_LIST): // 사용자관리 갱신 성공
    case SUCCESS(INSERT_TRDN_RSN): // 승인/반려 저장 성공
    case SUCCESS(UPDATE_USER_PWD): // 비밀번호 초기화 성공
      return {
        ...state,
        updateSuccess: true,
        updating: false,
        resultCnt: action.payload.data.resultCnt,
      };
    case REQUEST(SAVE_USER_LIST): // 갱신 요청
    case REQUEST(UPDATE_USER_MNG_LIST): // 사용자관리 갱신 요청
    case REQUEST(INSERT_TRDN_RSN): // 승인/반려 저장 요청
    case REQUEST(UPDATE_USER_PWD): // 비밀번호 초기화 요청
      return {
        ...state,
        updating: true,
        updateSuccess: false,
        message: '',
      };
    case FAILURE(SAVE_USER_LIST): // 갱신 실패
    case FAILURE(UPDATE_USER_MNG_LIST): // 사용자관리 갱신 실패
    case FAILURE(INSERT_TRDN_RSN): // 승인/반려 저장 실패
    case FAILURE(UPDATE_USER_PWD): // 비밀번호 초기화 실패
      return {
        ...initialState,
        updating: false,
        updateSuccess: false,
        resultCnt: -1,
        message: action.payload.response?.data?.message,
      };
    case SUCCESS(FETCH_USER_MNG_LIST): // 사용자 관리 조회 성공
      return {
        ...state,
        userMngSuccess: true,
        userMngLoading: false,
        userMngList: action.payload.data?.main,
        totalCount: action?.payload?.data?.totalCnt,
        updateSuccess: false,
      };
    case REQUEST(FETCH_USER_MNG_LIST): // 사용자 관리 조회 요청
      return {
        ...state,
        userMngLoading: true,
        userMngMessage: '',
        updateSuccess: false,
      };
    case FAILURE(FETCH_USER_MNG_LIST): // 사용자 관리 조회실패
      return {
        ...initialState,
        userMngLoading: false,
        userMngSuccess: false,
        userMngMessage: action.payload.response?.data?.message,
        updateSuccess: false,
      };
    case SUCCESS(FETCH_USER_APV_LIST): // 가입 미승인 목록 조회 성공
      return {
        ...state,
        userApvSuccess: true,
        userApvLoading: false,
        userApvList: action.payload.data?.main,
        updateSuccess: false,
      };
    case REQUEST(FETCH_USER_APV_LIST): // 가입 미승인 목록 조회 요청
      return {
        ...state,
        userApvLoading: true,
        userApvMessage: '',
        updateSuccess: false,
      };
    case FAILURE(FETCH_USER_APV_LIST): // 가입 미승인 목록 조회실패
      return {
        ...initialState,
        userApvLoading: false,
        userApvSuccess: false,
        userApvMessage: action.payload.response?.data?.message,
        updateSuccess: false,
      };
    case SUCCESS(FETCH_USER_DEPT_CHG_APV_LIST): // 부서 미승인 목록 조회 성공
      return {
        ...state,
        deptChgApvSuccess: true,
        deptChgApvLoading: false,
        deptChgApvList: action.payload.data?.main,
        updateSuccess: false,
      };
    case REQUEST(FETCH_USER_DEPT_CHG_APV_LIST): // 부서 미승인 목록 조회 요청
      return {
        ...state,
        deptChgApvLoading: true,
        deptChgApvMessage: '',
        updateSuccess: false,
      };
    case FAILURE(FETCH_USER_DEPT_CHG_APV_LIST): // 부서 미승인 목록 조회실패
      return {
        ...initialState,
        deptChgApvLoading: false,
        deptChgApvSuccess: false,
        deptChgApvMessage: action.payload.response?.data?.message,
        updateSuccess: false,
      };
    case SAVE_SEARCH_FORM_VALUES:
      return {
        ...state,
        searchFormValues: action.payload,
      };
    case RESET_STATE:
      return initialState;
    default:
      return { ...state, updateSuccess: false };
  }
}

/**
 * 사용자 조회
 */
export const selectUserList = (searchParams) => {
  return {
    type: FETCH_USER_LIST,
    payload: fetchUserList(searchParams),
  };
};

/**
 * 사용자 수정
 */
export const saveUserList = (dtos) => {
  return {
    type: SAVE_USER_LIST,
    payload: updateUserList(dtos),
  };
};

/**
 * 사용자관리 조회
 */
export const selectUserMngList = (searchParams) => {
  return {
    type: FETCH_USER_MNG_LIST,
    payload: fetchUserMngList(searchParams),
  };
};

/**
 * 사용자관리 수정
 */
export const saveUserMngList = (dtos) => {
  return {
    type: UPDATE_USER_MNG_LIST,
    payload: insertUserMngList(dtos),
  };
};

/**
 * 사용자관리 - 가입/부서 변경 승인 - 사용자 미승인 목록
 */
export const selectUserApvList = (searchParams) => {
  return {
    type: FETCH_USER_APV_LIST,
    payload: fetchUserApvList(searchParams),
  };
};

/**
 * 사용자관리 - 가입/부서 변경 승인 - 승인/반려 저장
 */
export const saveTrdnRsn = (dto) => {
  return {
    type: INSERT_TRDN_RSN,
    payload: insertTrdnRsn(dto),
  };
};

/**
 * 사용자관리 - 비밀번호 초기화
 */
export const saveUserPwd = (dto) => {
  return {
    type: UPDATE_USER_PWD,
    payload: updateUserPwd(dto),
  };
};

/**
 * 사용자관리 - 가입/부서 변경 승인 - 부서 미승인 목록
 */
export const selectUserDeptChgApvList = (searchParams) => {
  return {
    type: FETCH_USER_DEPT_CHG_APV_LIST,
    payload: fetchUserDeptChgApvList(searchParams),
  };
};

/**
 * 조회조건 저장
 */
export const saveSearchFormValues = (searchFormValues) => {
  return {
    type: SAVE_SEARCH_FORM_VALUES,
    payload: {
      ...searchFormValues,
      init: false,
    },
  };
};

/**
 * 상태초기화
 */
export const resetState = () => {
  return {
    type: RESET_STATE,
  };
};
