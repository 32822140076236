import { SUCCESS, REQUEST, FAILURE } from '../../../common/utils/action-type-utils';
import { fetchListStlActTrhs, fetchListStlActTrnPrts } from '../../../lib/cad/cadStlActtrnPrts.api';
import dayjs from 'dayjs';
import { fetchCommonComboList } from '../../../lib/cmm/combo.api';

// actions
const FETCH_STL_ACT_TRN_PRTS_LIST = 'etc0403v01/FETCH_STL_ACT_TRN_PRTS_LIST';
const FETCH_STL_ACT_TRHS_LIST = 'etc0403v01/FETCH_STL_ACT_TRHS_LIST';
const RESET_STATE = 'etc0403v01/RESET_STATE';
const SAVE_SEARCH_FORM_VALUES = 'etc0403v01/SAVE_SEARCH_FORM_VALUES';
//기관
const FETCH_IST_INFO_LIST = 'etc0403v01/FETCH_IST_INFO_LIST';
//계좌번호
const FETCH_ACT_NO_LIST = 'etc0403v01/FETCH_ACT_NO_LIST';

export const initFormValues = {
  br_cd: '', // 기관명
  ydcs8a1_t8a12_act_no: '', // 계좌번호
  ydcs8a1_t8a12_inq_sdt: dayjs().format('YYYY-MM-DD'), // 조회기간
  ydcs8a1_t8a12_inq_edt: dayjs().format('YYYY-MM-DD'), // 조회기간
};

// initial state
const initialState = {
  loading: false,
  success: false,
  message: '',
  list: [],
  list2: [],
  totalCount: 0,
  //콤보 - 기관명
  istInfoList: [],
  istInfoLoading: false,
  istInfoSuccess: true,
  //계좌번호
  actNoLoading: false,
  actNoList: [],
  actNoSuccess: false,
  // 기타 - 서울페이플러스 - 계좌거래명세
  etc0403v01brCdComboList: [
    { id: '020689', label: '종로구청', cusIstCd: '3000000' },
    { id: '020661', label: '중구청', cusIstCd: '3010000' },
    { id: '020688', label: '용산구청', cusIstCd: '3020000' },
    { id: '020828', label: '중랑구청', cusIstCd: '3060000' },
    { id: '020691', label: '성북구청', cusIstCd: '3070000' },
    { id: '020815', label: '서대문구청', cusIstCd: '3120000' },
    { id: '020549', label: '마포구청', cusIstCd: '3130000' },
    { id: '020824', label: '양천구청', cusIstCd: '3140000' },
    { id: '020823', label: '강서구청', cusIstCd: '3150000' },
    { id: '020822', label: '영등포구청', cusIstCd: '3180000' },
    { id: '020821', label: '관악구청', cusIstCd: '3200000' },
    { id: '020774', label: '송파구청', cusIstCd: '3230000' },
    { id: '020379', label: '강동구청', cusIstCd: '3240000' },
    { id: '020853', label: '금천구청', cusIstCd: '3170000' },
    { id: '020812', label: '시청', cusIstCd: '6110000' },
  ],
  searchFormValues: {
    init: true,
    ...initFormValues,
  },
};

// reducers
export default function etc0403v01(state = initialState, action) {
  switch (action.type) {
    case SUCCESS(FETCH_STL_ACT_TRN_PRTS_LIST): // 조회 성공
      return {
        ...state,
        success: true,
        loading: false,
        list: action.payload.data?.output_data,
        totalCount: action.payload?.data?.output_data_cnt,
      };
    case REQUEST(FETCH_STL_ACT_TRN_PRTS_LIST): // 조회 요청
      return {
        ...state,
        success: false,
        loading: true,
        message: '',
      };
    case FAILURE(FETCH_STL_ACT_TRN_PRTS_LIST): // 조회실패
      return {
        ...initialState,
        loading: false,
        success: false,
        message: action.payload.response?.data?.message,
      };
    case SUCCESS(FETCH_STL_ACT_TRHS_LIST): // 조회 성공
      return {
        ...state,
        success: true,
        loading: false,
        list2: action.payload.data?.output_data,
        totalCount: action.payload?.data?.output_data_cnt,
      };
    case REQUEST(FETCH_STL_ACT_TRHS_LIST): // 조회 요청
      return {
        ...state,
        success: false,
        loading: true,
      };
    case FAILURE(FETCH_STL_ACT_TRHS_LIST): // 조회실패
      return {
        ...state,
        loading: false,
        success: false,
        message: action.payload.response?.data?.message,
      };
    case SUCCESS(FETCH_IST_INFO_LIST): // 조회 성공
      return {
        ...state,
        istInfoSuccess: true,
        istInfoLoading: false,
        istInfoList: action.payload.data?.subDto,
      };
    case REQUEST(FETCH_IST_INFO_LIST): // 조회 요청
      return {
        ...state,
        istInfoLoading: true,
        istInfoSuccess: false,
      };
    case FAILURE(FETCH_IST_INFO_LIST): // 조회실패
      return {
        istInfoLoading: false,
        istInfoSuccess: false,
        istInfoList: [],
      };
    case SUCCESS(FETCH_ACT_NO_LIST): // 조회 성공
      return {
        ...state,
        actNoSuccess: true,
        actNoLoading: false,
        actNoList: action.payload.data?.subDto,
      };
    case REQUEST(FETCH_ACT_NO_LIST): // 조회 요청
      return {
        ...state,
        actNoLoading: true,
        actNoSuccess: false,
      };
    case FAILURE(FETCH_ACT_NO_LIST): // 조회실패
      return {
        ...state,
        actNoLoading: false,
        actNoSuccess: false,
        actNoList: [],
      };
    case SAVE_SEARCH_FORM_VALUES:
      return {
        ...state,
        // 콤보 - 기관
        istInfoSuccess: false,
        istInfoLoading: false,
        // 콤보 - 계좌번호
        mbrNoSuccess: false,
        mbrNoLoading: false,
        searchFormValues: action.payload,
      };
    case RESET_STATE:
      return initialState;
    default:
      return state;
  }
}

/**
 * 서울페이플러스 - 계좌거래명세  (결제 계좌거래내역 조회)
 */
export const selectEtcListStlActTrnPrts = (searchParams) => {
  return {
    type: FETCH_STL_ACT_TRN_PRTS_LIST,
    payload: fetchListStlActTrnPrts(searchParams),
  };
};

/**
 * 서울페이플러스 - 계좌거래명세 (거래명세 건수 및 거래명세 조회)
 */
export const selectEtcListStlActTrhs = (searchParams) => {
  return {
    type: FETCH_STL_ACT_TRHS_LIST,
    payload: fetchListStlActTrhs(searchParams),
  };
};

/**
 * 제로페이 - 승인내역(기관)
 */
export const selectIstInfoComboList = (searchParams) => {
  return {
    type: FETCH_IST_INFO_LIST,
    payload: fetchCommonComboList({ slt_box_no: '005', ...searchParams }),
  };
};
/**
 * 계좌번호 콤보 조회
 */
export const selectActNoComboList = (searchParams) => {
  return {
    type: FETCH_ACT_NO_LIST,
    payload: fetchCommonComboList({ slt_box_no: '210', ...searchParams }),
  };
};

/**
 * 조회조건 저장
 */
export const saveSearchFormValues = (searchFormValues) => {
  return {
    type: SAVE_SEARCH_FORM_VALUES,
    payload: {
      ...searchFormValues,
      init: false,
    },
  };
};

/**
 * 집계내역 상태초기화
 */
export const resetEtc0403v01 = () => {
  return {
    type: RESET_STATE,
  };
};
