import instance from '../../common/config/api';

const PREFIX = `${process.env.REACT_APP_SERVER_URL}/scq/scq/arv/0003`;

/**
 * 세입 - 보조금 교부내역 조회
 * @param {object} params
 * @returns
 */
export const fetchAsstmHndvPrts = (params) => instance.post(`${PREFIX}/selectAsstmHndvPrts`, params);
