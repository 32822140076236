import instance from '../../common/config/api';

const prefix = `${process.env.REACT_APP_SERVER_URL}/scq/scq/rpt/0003`;

/**
 * 세입세출자금일계표 기금 조회
 * @param {object} params
 * @returns
 */
export const fetchAnexDayMmEvdXpn = (params) => instance.post(`${prefix}/selectAnexDayMmEvdXpn`, params);
