import WOModal, { ButtonRightArea, ModalButtonArea, ModalContentArea } from '../../components/common/WOModal';
import WOButton from '../../components/common/WOButton';
import * as S from '../../styles/subPage.styled';
import { useState } from 'react';
import { css, styled } from 'styled-components';
import WOIcon from '../../components/common/WOIcon';
import { Link } from 'react-router-dom';

const SecurityCenterPopup = ({ isOpen, onClose }) => {
  // 사용자 설정
  const [isOpen1, setIsOpen1] = useState(isOpen);

  const handleClose = () => {
    setIsOpen1(false);
    onClose?.();
  };

  return (
    /* 사용자 설정 팝업 */
    <>
      <WOModal direction="center" size="lg" height={495} isOpen={isOpen1} title={'보안센터'} onClose={handleClose}>
        {/* START: modalContent */}
        <ModalContentArea $nonePadding={true}>
          <Wrapper>
            <div className="title-wrap">
              <div className="title">프로그램 설치 통합 안내</div>
              <div className="text">고객님의 안전한 서비스 이용을 위한 보안프로그램 설치 현황입니다.</div>
            </div>
            <div className="content-area">
              {/* 설치된 case */}
              <div className="content">
                <div className="title-wrap">
                  <div className="tit">공인인증솔루션(VestSign)</div>
                  <div className="text">공동인증서 전자서명을 지원해주는 프로그램입니다.</div>
                </div>
                <BtnWrap>
                  <div className="text">설치됨</div>
                  <Btn to="https://download.yettiesoft.com/VestCert/VestCerSetup.exe" target="_blank">
                    <WOIcon icon={'download'} width={16} height={16} />
                    재설치
                  </Btn>
                </BtnWrap>
              </div>
              {/* 미설치 case */}
              <div className="content">
                <div className="title-wrap">
                  <div className="tit">개인방화벽(ASTx2)</div>
                  <div className="text">실시간 해킹차단 및 바이러스 검색 치료 프로그램입니다.</div>
                </div>
                <BtnWrap $notInstall={true}>
                  <div className="text">미설치</div>
                  <Btn to="https://safetx.ahnlab.com/master/win/default/all/astx_setup.exe" target="_blank">
                    <WOIcon icon={'download-white'} width={16} height={16} />
                    설치
                  </Btn>
                </BtnWrap>
              </div>
              {/* 설치된 case */}
              <div className="content">
                <div className="title-wrap">
                  <div className="tit">키보드보안(nProtect Online Security)</div>
                  <div className="text">키보드로 입력되는 중요데이터 암호화 및 위/변조 방지 프로그램입니다.</div>
                </div>
                <BtnWrap>
                  <div className="text">설치됨</div>
                  <Btn
                    to="https://supdate.nprotect.net/nprotect/nos_service/windows/install/nos_setup.exe"
                    target="_blank">
                    <WOIcon icon={'download'} width={16} height={16} />
                    재설치
                  </Btn>
                </BtnWrap>
              </div>
            </div>
          </Wrapper>
        </ModalContentArea>
        {/* END: modalContent */}
      </WOModal>
    </>
  );
};

export default SecurityCenterPopup;

const Wrapper = styled.div`
  width: 100%;
  height: 100% !important;
  padding: 40px;

  background: linear-gradient(180deg, #e6f0ff 49.94%, #fdf8ff 100%);
  .title-wrap {
    margin-bottom: 20px;
    .title {
      margin-bottom: 4px;
      font-size: 24px;
      font-weight: 700;
      line-height: 150%;
      color: #333;
    }
    .text {
      font-size: 14px;
      font-weight: 700;
      line-height: 150%;
      color: #9ea6b2;
    }
  }
  .content-area {
    margin-top: 32px;
    .content {
      display: flex;
      align-items: center;
      justify-content: space-between;
      padding: 20px;
      background: #fff;
      border-radius: 16px;
      overflow: hidden;
      & + .content {
        margin-top: 8px;
      }
      .title-wrap {
        margin-bottom: 0;
        .tit {
          margin-bottom: 3px;
          font-size: 14px;
          font-weight: 700;
          color: #263752;
        }
        .text {
          font-size: 12px;
          font-weight: 400;
          color: #9ea6b2;
        }
      }
    }
  }
`;
const Btn = styled(Link)`
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 0 4px;
  width: 66px;
  height: 24px;
  border-radius: 4px;
  background: #e5e5ed;
  font-size: 12px;
  font-weight: 700;
  color: #263752;
`;

const BtnWrap = styled.div`
  display: flex;
  align-items: center;
  gap: 0 12px;
  .text {
    font-size: 12px;
    font-weight: 400;
    color: #3c4b63;
  }
  ${(props) =>
    props.$notInstall &&
    `
    .text{
      color:#ff5d5d;
    }
    ${Btn}{
      background:#0e213F;
      color:#fff;
    }

  `}
`;
