import { useCallback, useEffect, useRef, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import styled from 'styled-components';
import WOButton from '../../components/common/WOButton';
import WOModal, { ButtonRightArea, ModalButtonArea, ModalContentArea } from '../../components/common/WOModal';
import * as yup from 'yup';
import { yupResolver } from '@hookform/resolvers/yup';
import { Controller, useForm } from 'react-hook-form';
import * as S from '../../styles/subPage.styled';
import WOLoginInput from '../../components/common/WOLoginInput';
import {
  changeUserPwd,
  changeUserPwdNew,
  changeUserPwdNewVDI,
  resetUserPwd,
} from '../../redux/modules/cmm/findPassword.reducer';
import { resetAuthNumberState, sendAuthNumberToHpNo } from '../../redux/modules/cmm/authNumber.reducer';
import WOValidationCheck from '../../components/common/WOValidationCheck';
import { vaildationTextStyle, vaildationTextWrap } from '../../styles/common.styled';
import useAlert from '../../hooks/useAlert';
import { lpad } from '../../common/utils/utils';

function LoginChangePasswordPopup({ isOpen, onClose, title = '기존 비밀번호 재설정' }) {
  const { alert } = useAlert();
  // 비밀번호 재설정
  const dispatch = useDispatch();
  const [passwordValid, setPasswordValid] = useState({ lowcase: '', number: '', specialChar: '', length: '' });
  const [isOpen2, setIsOpen2] = useState(isOpen);
  // 인증만료시간
  const timer = useRef(null);
  // 5분
  const defaultInterval = 300;
  const [time, setTime] = useState(defaultInterval);
  // 비밀번호 불일치 문구
  const [passError] = useState(true);
  const passwordSchema = yup.object().shape({
    ch_pwd_no: yup
      .string()
      .matches(
        /^(?=.*[a-z])(?=.*[0-9])(?=.*[~!@#$%])[A-Za-z0-9~!@#$%]{8,}$/,
        '비밀번호 조건을 확인하세요.\n- 8자 이상\n- 소문자, 특수문자, 숫자 포함\n- 특수문자는 ~ ! @ # $ % 만 가능'
      )
      .required('비밀번호 조건을 확인하세요.'),
    confirm_pwd_no: yup
      .string()
      .oneOf([yup.ref('ch_pwd_no'), null], '비밀번호가 일치하지 않습니다.')
      .required('비밀번호가 일치하지 않습니다.'),
  });

  // 중복 아이디 통과여부
  const {
    // updating: updateByPassword,
    updatingSuccess: updatingSuccessByPassword,
    message: messageByPassword,
  } = useSelector((state) => state.findPasswordState);
  // 인증번호
  const {
    loadingByAuthSend,
    successByAuthSend,
    message: messageByAuthSend,
  } = useSelector((state) => state.authNumberState);
  const {
    handleSubmit: handlePasswordSubmit,
    control: passwordControl,
    formState: { errors: passwordErrors, dirtyFields: passwordDirtyFields },
    getValues: getPasswordValues,
    setError,
    clearErrors,
    reset,
  } = useForm({
    defaultValues: {
      user_nm: '',
      user_id: '',
      brdt: '',
      hp_no: '',
      pwd_no: '',
      ch_pwd_no: '',
      confirm_pwd_no: '',
      auth_no: '',
    },
    resolver: yupResolver(passwordSchema),
  });

  useEffect(() => {
    if (passwordErrors.ch_pwd_no) {
      alert(passwordErrors.ch_pwd_no.message, '비밀번호');
      clearErrors('ch_pwd_no');
    } else if (passwordErrors.confirm_pwd_no) {
      alert(passwordErrors.confirm_pwd_no.message, '비밀번호 확인');
      clearErrors('confirm_pwd_no');
    }
  }, [alert, clearErrors, passwordErrors]);

  const setTimer = () => {
    if (timer.current) {
      clearInterval(timer.current);
    }
    setTime(defaultInterval);
    timer.current = setInterval(() => {
      setTime((prev) => prev - 1);
    }, 1000);
  };

  useEffect(() => {
    if (time < 0) {
      if (timer.current) {
        clearInterval(timer.current);
        setTime(0);
      }
    }
  }, [time]);

  /**
   * 정보입력 다음버튼 활성화 확인
   */
  const checkPasswordChangeDisabled = () => {
    const fields = ['user_nm', 'user_id', 'brdt', 'hp_no', 'pwd_no', 'confirm_pwd_no', 'auth_no'];
    const dirtyFields = [];
    for (let key in passwordDirtyFields) {
      dirtyFields.push(key);
    }
    return fields.length !== dirtyFields.length;
  };

  /**
   * 비밀번호 재설정
   */
  const handleChangePassword = async (dto) => {
    if (checkEmptyInput()) {
      const json = window.npPfsCtrl.toJson('changePasswordForm');
      // 비밀번호 재설정 요청
      if (window.isVirtualMachine === 'VDI') {
        dispatch(changeUserPwdNewVDI(dto));
      } else {
        dispatch(changeUserPwdNew(`s=${encodeURI(JSON.stringify({ ...dto, crtf_no: dto.auth_no, ...json }))}`));
      }
    }
  };

  /**
   * 인증번호 전송
   */
  const handleSendAuthNo = useCallback(
    (searchParams) => {
      dispatch(sendAuthNumberToHpNo(searchParams));
      // setTimer();
      // alert('인증번호가 전송되었습니다.\n전송된 인증번호를 입력해주세요.', '인증번호 전송');
    },
    [dispatch]
  );

  /**
   * 정보입력 얼럿 유효성 검사
   */
  const checkEmptyInput = () => {
    let msg = '';

    const json = window.npPfsCtrl.toJson('changePasswordForm');
    if (json.pwd_no === '') {
      msg = '비밀번호를 입력해 주세요.';
    } else if (json.ch_pwd_no === '') {
      msg = '새 비밀번호을 입력해 주세요.';
    } else if (json.confirm_pwd_no === '') {
      msg = '비밀번호 확인을 입력해 주세요.';
    }
    if (msg !== '') {
      alert(msg);
      return false;
    } else {
      return true;
    }
  };

  useEffect(() => {
    if (!loadingByAuthSend && successByAuthSend) {
      alert('인증번호가 전송되었습니다.\n전송된 인증번호를 입력해주세요.', '인증번호 전송');
      setTimer();
    } else if (!loadingByAuthSend && !successByAuthSend && messageByAuthSend) {
      alert(messageByAuthSend, '인증번호 전송');
    }
  }, [loadingByAuthSend, successByAuthSend, messageByAuthSend, alert]);

  /**
   * 팝업 닫기
   */
  const handleOnClose = () => {
    reset();
    dispatch(resetAuthNumberState());
    setIsOpen2(false);
    onClose?.();
  };

  /**
   * 패스워드 확인
   */
  const handleOnChangePassword = (text) => {
    const valid = {};
    const matchLowcase = /[a-z]/;
    const matchNumber = /[1234567890]/;
    const matchSpecialChar = /[!@#$%^&*()_+\-=\[\]{};':"\\|,.<>\/?\s]/;
    valid.lowcase = matchLowcase.test(text) ? 'pass' : 'error';
    valid.number = matchNumber.test(text) ? 'pass' : 'error';
    valid.specialChar = matchSpecialChar.test(text) ? 'pass' : 'error';
    valid.length = text.length >= 8 ? 'pass' : 'error';
    setPasswordValid(valid);
  };

  useEffect(() => {
    if (updatingSuccessByPassword !== null) {
      if (updatingSuccessByPassword) {
        alert('비밀번호 재설정 되었습니다.');
        handleOnClose();
        dispatch(resetAuthNumberState());
        dispatch(resetUserPwd());
      } else {
        alert(messageByPassword);
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [alert, updatingSuccessByPassword]);

  useEffect(() => {
    setIsOpen2(isOpen);
  }, [isOpen]);

  useEffect(() => {
    return () => {
      dispatch(resetUserPwd());
      if (timer.current) {
        clearInterval(timer.current);
      }
    };
  }, [dispatch]);

  // 키보드 보안 설정
  const [formRef, setFormRef] = useState(null);
  useEffect(() => {
    if (formRef && window.isVirtualMachine === 'NONE') {
      window.npPfsCtrl.RescanField();
    }
  }, [formRef]);

  return (
    <>
      {/* 부서 변경 모달 */}
      <WOModal
        direction="center"
        height={passError ? 434 : 410}
        isOpen={isOpen2}
        title={title}
        // title={'초기 비밀번호 재설정'} 초기 로그인시 title 초기 비밀번호 재설정
        onClose={handleOnClose}>
        <ModalContentArea $gap={8}>
          {/* 비밀번호 입력 영역 */}
          <form name="changePasswordForm" ref={setFormRef}>
            <S.Container $gap={12}>
              <S.Container $gap={8}>
                <S.LoginModalDescript>* 정보보호를 위해 비밀번호 변경이 필요합니다.</S.LoginModalDescript>
                {/* <S.LoginModalDescript>* 초기 비밀번호는 필수 변경사항 입니다.</S.LoginModalDescript> 초기 로그인시
              Descript 변경 */}
                <Controller
                  control={passwordControl}
                  name={'pwd_no'}
                  render={({ field: { onChange, name, value } }) => (
                    <WOLoginInput
                      type={'password'}
                      id="input12"
                      label="기존 비밀번호"
                      // label="초기 비밀번호"
                      name={name}
                      maxLength={16}
                      styleType={'popup'}
                      //npkencrypt={'on'}
                      placeholder="기존 비밀번호를 입력해주세요."
                      onChange={(val) => {
                        onChange(val);
                      }}
                      value={value}
                      // 초기 로그인시 기존 비밀번호 입력된 상태로 진입 및 Readonly 상태
                    />
                  )}
                />
              </S.Container>
              <S.PasswordModalLine />
              <S.Container $gap={8}>
                <Controller
                  control={passwordControl}
                  name={'ch_pwd_no'}
                  render={({ field: { onChange, name, value } }) => (
                    <>
                      <WOLoginInput
                        type={'password'}
                        id="input12"
                        label="새 비밀번호"
                        name={name}
                        maxLength={16}
                        styleType={'popup'}
                        //npkencrypt={'on'}
                        placeholder="새 비밀번호를 입력해주세요."
                        onChange={(val) => {
                          onChange(val);
                          handleOnChangePassword(val);
                        }}
                        value={value}
                        error={passwordErrors.ch_pwd_no}
                      />
                      <WOValidationCheck
                        itemList={[
                          {
                            id: '3',
                            title: '숫자',
                            isCheck: passwordValid.number,
                          },
                          {
                            id: '4',
                            title: '8자 이상',
                            isCheck: passwordValid.length,
                          },
                        ]}
                      />
                    </>
                  )}
                />

                <Controller
                  control={passwordControl}
                  name={'confirm_pwd_no'}
                  render={({ field: { onChange, name, value } }) => (
                    <>
                      <WOLoginInput
                        type={'password'}
                        id="input13"
                        label="새 비밀번호"
                        name={name}
                        maxLength={16}
                        styleType={'popup'}
                        //npkencrypt={'on'}
                        placeholder="새 비밀번호를 한번 더 입력해주세요."
                        error={passwordErrors.confirm_pwd_no}
                        onChange={onChange}
                        value={value}
                      />
                      {passwordErrors.confirm_pwd_no && (
                        <TextCheckText $vailCheck={'error'}>{passwordErrors.confirm_pwd_no?.message}</TextCheckText>
                      )}
                    </>
                  )}
                />
              </S.Container>
            </S.Container>
          </form>
        </ModalContentArea>
        {/* 버튼 영역 */}
        <ModalButtonArea $nonBoxShadow={true}>
          <ButtonRightArea>
            {/* 90일 이후 비밀번호 변경 alert로 진입시 취소버튼 보이지 않음 */}
            <WOButton variant={'secondary'} size={'md'} onClick={handleOnClose}>
              취소
            </WOButton>
            {/* {checkPasswordChangeDisabled() ? (
              <WOButton
                variant={'primary'}
                size={'md'}
                customClass={'disabled'}
                onClick={() => {
                  checkEmptyInput();
                }}>
                비밀번호 재설정
              </WOButton>
            ) : ( */}
            <WOButton
              variant={'primary'}
              size={'md'}
              // disabled={checkPasswordChangeDisabled()}
              onClick={() => {
                handlePasswordSubmit(handleChangePassword)(getPasswordValues());
              }}>
              비밀번호 재설정
            </WOButton>
            {/* )} */}
          </ButtonRightArea>
        </ModalButtonArea>
      </WOModal>
    </>
  );
}

export default LoginChangePasswordPopup;

const TextCheckText = styled.div`
  ${vaildationTextStyle}
`;
