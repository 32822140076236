import { createGlobalStyle } from 'styled-components';

const GlobalStyleCustom = createGlobalStyle`

// error overlay 제거
body > iframe {
  display: none;
}

.documentStyle {
  text-align: center;
  font-size: 28px;
  #background-color: rgba(231, 134, 77, 0.3);
}

.documentSubtitleStyle {
  text-align: left;
  font-size: 14px;
  #background-color: rgba(231, 134, 77, 0.3);
}

`;

export default GlobalStyleCustom;
