import { SUCCESS, REQUEST, FAILURE } from '../../../common/utils/action-type-utils';
import { fetchZpbKataSmsTmsPrstList } from '../../../lib/zpb/zpb0204v01.api';
import dayjs from 'dayjs';
import { fetchCommonComboList } from '../../../lib/cmm/combo.api';
// actions
const FETCH_KATA_SMS_TMS_PRST = 'zpbKataSmsTmsPrst/FETCH_KATA_SMS_TMS_PRST';
const FETCH_IST_INFO_LIST = 'zpbKataSmsTmsPrst/FETCH_IST_INFO_LIST';
const RESET_STATE = 'zpbKataSmsTmsPrst/RESET_STATE';
const SAVE_SEARCH_FORM_VALUES = 'zpbKataSmsTmsPrst/SAVE_SEARCH_FORM_VALUES';

export const initFormValues = {
  gb: '0', // 구분
  bznm: '', // 기관명
  user_id: '', // 사용자 ID
  hp_no: '', // SMS/카카오톡 수신번호
  zero_no: '', // 제로페이승인번호
  sdt: dayjs().format('YYYY-MM-DD'), // 기준일자
  edt: dayjs().format('YYYY-MM-DD'), // 기준일자
};

// initial state
const initialState = {
  loading: false,
  success: false,
  message: '',
  list: [],
  totalCount: 0,
  bznmList: [],
  bznmLoading: false,
  bznmSuccess: true,
  totalCount: 0,
  gbList: [
    { id: '0', label: '전체' },
    { id: '1', label: '승인내역' },
    { id: '2', label: '미집행내역' },
  ],
  searchFormValues: {
    init: true,
    ...initFormValues,
  },
};

// reducers
export default function zpbKataSmsTmsPrst(state = initialState, action) {
  switch (action.type) {
    case SUCCESS(FETCH_KATA_SMS_TMS_PRST): // 조회 성공
      return {
        ...state,
        success: true,
        loading: false,
        list: action.payload.data?.subDto,
        totalCount: action.payload.data?.totMainCnt,
      };
    case REQUEST(FETCH_KATA_SMS_TMS_PRST): // 조회 요청
      return {
        ...state,
        loading: true,
        success: false,
      };
    case FAILURE(FETCH_KATA_SMS_TMS_PRST): // 조회실패
      return {
        ...initialState,
        loading: false,
        success: false,
        message: action.payload.response?.data?.message,
      };
    case REQUEST(FETCH_IST_INFO_LIST): // 조회 요청
      return {
        ...state,
        bznmLoading: true,
        bznmSuccess: false,
      };
    case SUCCESS(FETCH_IST_INFO_LIST): // 조회 성공
      return {
        ...state,
        bznmSuccess: true,
        bznmLoading: false,
        bznmList: action.payload.data?.subDto,
      };
    case FAILURE(FETCH_IST_INFO_LIST): // 조회실패
      return {
        bznmLoading: false,
        bznmSuccess: false,
        bznmList: [],
      };
    case SAVE_SEARCH_FORM_VALUES:
      return {
        ...state,
        // 콤보 - 기관
        bznmSuccess: false,
        bznmLoading: false,
        searchFormValues: action.payload,
      };
    case RESET_STATE:
      return initialState;
    default:
      return state;
  }
}

/**
 * 제로페이(관리자) - 카카오톡 전송내역 조회
 */
export const selectKataSmsTmsPrstList = (searchParams) => {
  return {
    type: FETCH_KATA_SMS_TMS_PRST,
    payload: fetchZpbKataSmsTmsPrstList(searchParams),
  };
};
/**
 * 기관명 콤보 조회
 */
export const selectIstInfoComboList = (searchParams) => {
  return {
    type: FETCH_IST_INFO_LIST,
    payload: fetchCommonComboList({ slt_box_no: '901', ...searchParams }),
  };
};
/**
 * 조회조건 저장
 */
export const saveSearchFormValues = (searchFormValues) => {
  return {
    type: SAVE_SEARCH_FORM_VALUES,
    payload: {
      ...searchFormValues,
      init: false,
    },
  };
};
/**
 * 초기화
 */
export const resetZpbKataSmsTmsPrst = () => {
  return {
    type: RESET_STATE,
  };
};
