import { SUCCESS, REQUEST, FAILURE } from '../../../common/utils/action-type-utils';
import { fetchUserDeptChg, fetchUserDeptChgCnt } from '../../../lib/cmm/signup.api';

// actions
const FETCH_USER_DEPT_CHG = 'changeDeptState/FETCH_USER_DEPT_CHG';
const RESET_USER_DEPT_CHG = 'changeDeptState/RESET_USER_DEPT_CHG';
const FETCH_USER_DEPT_CHG_CNT = 'changeDeptState/FETCH_USER_DEPT_CHG_CNT';
const RESET_CHECK_CHANGE_CNT = 'changeDeptState/RESET_CHECK_CHANGE_CNT';

// initial state
const initialState = {
  updating: false,
  updatingSuccess: null,
  message: '',
  messageId: '',
  checkChangeCnt: -1,
  checkChangeLoading: false,
  checkChangeSuccess: false,
  checkChangeMessage: '',
};

// reducers
export default function changeDeptState(state = initialState, action) {
  switch (action.type) {
    case SUCCESS(FETCH_USER_DEPT_CHG): // 사용자부서변경 성공
      return {
        ...state,
        updating: false,
        updatingSuccess: action.payload.data.updateCnt > 0,
      };
    case REQUEST(FETCH_USER_DEPT_CHG): // 사용자부서변경 요청
      return {
        ...state,
        updating: true,
        updatingSuccess: null,
        message: '',
        messageId: '',
      };
    case FAILURE(FETCH_USER_DEPT_CHG): // 사용자부서변경 실패
      return {
        ...state,
        updating: false,
        updatingSuccess: false,
        message: action.payload.response?.data?.message,
        messageId: action.payload.response?.data?.messageId,
      };
    case RESET_USER_DEPT_CHG: // 사용자부서변경 실패
      return initialState;
    case SUCCESS(FETCH_USER_DEPT_CHG_CNT): // 사용자 부서변경 진행중 건수 조회성공
      return {
        ...state,
        checkChangeLoading: false,
        checkChangeSuccess: true,
        checkChangeCnt: action.payload.data?.progress_ext,
      };
    case REQUEST(FETCH_USER_DEPT_CHG_CNT): // 사용자 부서변경 진행중 건수 조회요청
      return {
        ...state,
        checkChangeLoading: true,
        checkChangeSuccess: null,
        checkChangeMessage: '',
        checkChangeCnt: -1,
      };
    case FAILURE(FETCH_USER_DEPT_CHG_CNT): // 사용자 부서변경 진행중 건수 조회실패
      return {
        ...state,
        checkChangeLoading: false,
        checkChangeSuccess: false,
        checkChangeCnt: -1,
        message: action.payload.response?.data?.message,
        messageId: action.payload.response?.data?.messageId,
      };
    case RESET_CHECK_CHANGE_CNT:
      return {
        checkChangeCnt: -1,
        checkChangeLoading: false,
        checkChangeSuccess: false,
        checkChangeMessage: '',
      };
    default:
      return state;
  }
}

/**
 * 사용자부서변경
 */
export const changeUserDeptChg = (searchParams) => {
  return {
    type: FETCH_USER_DEPT_CHG,
    payload: fetchUserDeptChg(searchParams),
  };
};

export const resetChangeDeptState = () => {
  return {
    type: RESET_USER_DEPT_CHG,
  };
};

/**
 * 사용자부서변경
 */
export const selectUserDeptChgCnt = (searchParams) => {
  return {
    type: FETCH_USER_DEPT_CHG_CNT,
    payload: fetchUserDeptChgCnt(searchParams),
  };
};

export const resetCheckChangeCnt = () => {
  return {
    type: RESET_CHECK_CHANGE_CNT,
  };
};
