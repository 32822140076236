import { SUCCESS, REQUEST, FAILURE } from '../../../common/utils/action-type-utils';
import { fetchSiteMngList, saveSiteMngList } from '../../../lib/sys/siteMng.api';
import { fetchMenuBySystemList, saveMenuBySystemList } from '../../../lib/sys/SYS0306M01.api';

// actions
const FETCH_MENU_LV1_BY_SYSTEM_LIST = 'SYS0306M01/FETCH_MENU_LV1_BY_SYSTEM_LIST';
const FETCH_MENU_LV2_BY_SYSTEM_LIST = 'SYS0306M01/FETCH_MENU_LV2_BY_SYSTEM_LIST';
const SAVE_MENU_BY_SYSTEM_LIST = 'SYS0306M01/SAVE_MENU_BY_SYSTEM_LIST';

// initial state
const initialState = {
  loading: false,
  loadingByDetail: false,
  success: true,
  message: '',
  list: [],
  detailList: [],
  totalCount: 0,
  totalCountByDetail: -0,
  updating: false,
  updateSuccess: false,
  resultCnt: -1,
};

// reducers
export default function SYS0306M01(state = initialState, action) {
  switch (action.type) {
    case SUCCESS(FETCH_MENU_LV1_BY_SYSTEM_LIST): // 조회 요청
      return {
        ...state,
        success: true,
        loading: false,
        list: action.payload.data?.main,
        totalCount: action.payload?.data?.mainCnt,
        detailList: [],
        totalCountByDetail: 0,
        updateSuccess: false,
      };
    case SUCCESS(FETCH_MENU_LV2_BY_SYSTEM_LIST): // 조회 성공
      return {
        ...state,
        success: true,
        loadingByDetail: false,
        detailList: action.payload.data?.main,
        totalCountByDetail: action.payload?.data?.mainCnt,
        updateSuccess: false,
      };
    case REQUEST(FETCH_MENU_LV1_BY_SYSTEM_LIST): // 조회 요청
      return {
        ...state,
        message: '',
        loading: true,
        success: false,
        updateSuccess: false,
      };
    case REQUEST(FETCH_MENU_LV2_BY_SYSTEM_LIST): // 조회 요청
      return {
        ...state,
        message: '',
        loadingByDetail: true,
        success: false,
        updateSuccess: false,
      };
    case FAILURE(FETCH_MENU_LV1_BY_SYSTEM_LIST): // 조회실패
      return {
        ...initialState,
        loading: false,
        success: false,
        message: action.payload.response?.data?.message,
        updateSuccess: false,
      };
    case FAILURE(FETCH_MENU_LV2_BY_SYSTEM_LIST): // 조회실패
      return {
        ...initialState,
        loadingByDetail: false,
        success: false,
        message: action.payload.response?.data?.message,
        updateSuccess: false,
      };
    case SUCCESS(SAVE_MENU_BY_SYSTEM_LIST): // 저장 성공
      return {
        ...state,
        updateSuccess: true,
        updating: false,
        resultCnt: action.payload.data.resultCnt,
      };
    case REQUEST(SAVE_MENU_BY_SYSTEM_LIST): // 저장 요청
      return {
        ...state,
        message: '',
        updating: true,
        updateSuccess: false,
      };
    case FAILURE(SAVE_MENU_BY_SYSTEM_LIST): // 저장 실패
      return {
        ...initialState,
        updating: false,
        updateSuccess: false,
        resultCnt: -1,
        message: action.payload.response?.data?.message,
      };
    default:
      return state;
  }
}

/**
 * 시스템별 메뉴 레벨1 목록 조회
 */
export const selectMenuLv1BySystemList = (searchParams) => {
  return {
    type: FETCH_MENU_LV1_BY_SYSTEM_LIST,
    payload: fetchMenuBySystemList({ ...searchParams, mnu_lvl: 1 }),
  };
};

/**
 * 시스템별 메뉴 레벨2 목록 조회
 */
export const selectMenuLv2BySystemList = (searchParams) => {
  return {
    type: FETCH_MENU_LV2_BY_SYSTEM_LIST,
    payload: fetchMenuBySystemList({ ...searchParams, mnu_lvl: 2 }),
  };
};

/**
 * 사이트 목록 갱신
 */
export const saveListMenuBySystem = (menuLv1Dtos, menuLv2Dtos) => {
  return {
    type: SAVE_MENU_BY_SYSTEM_LIST,
    payload: saveMenuBySystemList(menuLv1Dtos, menuLv2Dtos),
  };
};
