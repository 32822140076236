import instance from '../../common/config/api';

/**
 * 업무선택 메뉴조회
 * @param {object} params
 * @returns
 */
export const fetchBizChcMenuList = (params) =>
  instance.post(`${process.env.REACT_APP_SERVER_URL}/scq/scq/man/0001/selectBizChcMenuList`, {
    ...params,
    mchrBycl: 'PC',
  });

/**
 * 자주 쓰는 업무(즐겨찾기) 조회
 * @param {object} params
 * @returns
 */
export const fetchBizFavt = (params) =>
  instance.post(`${process.env.REACT_APP_SERVER_URL}/scq/scq/man/0001/selectBizFavt`, { ...params, mchr_dis: 'PC' });

/**
 * 자주 쓰는 업무(즐겨찾기) 수정
 * @param {object} params
 * @returns
 */
export const mergeBizFavt = (params) =>
  instance.post(`${process.env.REACT_APP_SERVER_URL}/scq/scq/man/0001/saveBizFavt`, { ...params, mchr_dis: 'PC' });
