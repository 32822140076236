import { SUCCESS, REQUEST, FAILURE } from '../../../common/utils/action-type-utils';
import { fetchByactAnrvAnexFdDay } from '../../../lib/rpt/RPT0002.api';

// actions
const FETCH_BY_ACT_ANRV_ANEX_FD_DAY = 'RPT0202V01/FETCH_BY_ACT_ANRV_ANEX_FD_DAY';

// initial state
const initialState = {
  loading: false,
  success: false,
  message: '',
  list: [],
};

// reducers
export default function RPT0202V01(state = initialState, action) {
  switch (action.type) {
    case SUCCESS(FETCH_BY_ACT_ANRV_ANEX_FD_DAY): // 조회 성공
      return {
        ...state,
        success: true,
        loading: false,
        list: action.payload.data?.output_data,
      };
    case REQUEST(FETCH_BY_ACT_ANRV_ANEX_FD_DAY): // 조회 요청
      return {
        ...state,
        message: '',
        loading: true,
        success: false,
      };
    case FAILURE(FETCH_BY_ACT_ANRV_ANEX_FD_DAY): // 조회실패
      return {
        ...state,
        list: [],
        loading: false,
        success: false,
        message: action.payload.response?.data?.message,
      };
    default:
      return state;
  }
}

/**
 * 계좌별 세입세출자금일계표 조회
 */
export const selectByactAnrvAnexFdDay = (searchParams) => {
  return {
    type: FETCH_BY_ACT_ANRV_ANEX_FD_DAY,
    payload: fetchByactAnrvAnexFdDay(searchParams),
  };
};
