import { fetchCancFrcsAndFrcsInt } from '../../../lib/fxp/FXP0001.api';
import { SUCCESS, REQUEST, FAILURE } from '../../../common/utils/action-type-utils';
import dayjs from 'dayjs';

// actions
const FETCH_CANC_FRCS_AND_FRCS_INT = 'FXP0101V03/FETCH_CANC_FRCS_AND_FRCS_INT';

const RESET_INITIAL_STATE = 'FXP0101V03/RESET_INITIAL_STATE';
const SAVE_SEARCH_FORM_VALUES = 'FXP0101V03/SAVE_SEARCH_FORM_VALUES';

const CLEAR_MESSAGE = 'FXP0101V03/CLEAR_MESSAGE';
const SET_BEFORE_SEARCH_PARAM = 'FXP0101V03/SET_BEFORE_SEARCH_PARAM';

export const initFormValues = {
  ydcs8a1_t8a11_act_no: '',
  ydcs8a1_t8a11_inq_sdt: dayjs().format('YYYY.MM.DD'),
  pgm_dis: '138K-07',
  ydcs8a1_t8a11_act_dscd: '1', //조회구분 1-해지예상조회 , 2 - 예상이자 조회
  pmdp_act_dscd: '02', // 01=세입 , 02=세출
};

// initial state
const initialState = {
  loading: false,
  success: false,
  message: '',
  totalCount: 0,
  details: [],
  beforeSearchParam: {},

  // 검색조건
  searchFormValues: {
    init: true,
    ...initFormValues,
  },
};

// reducers
export default function FXP0101P01(state = initialState, action) {
  switch (action.type) {
    case SUCCESS(FETCH_CANC_FRCS_AND_FRCS_INT): // 조회 성공
      return {
        ...state,
        success: true,
        loading: false,
        details: action.payload.data?.output_data[0],
        totalCount: action.payload.data?.output_cnt,
      };
    case REQUEST(FETCH_CANC_FRCS_AND_FRCS_INT): // 조회 요청
      return {
        ...state,
        message: '',
        loading: true,
      };
    case FAILURE(FETCH_CANC_FRCS_AND_FRCS_INT): // 조회실패
      return {
        ...state,
        details: [],
        loading: false,
        success: false,
        message: action.payload.response?.data?.message,
      };
    case SAVE_SEARCH_FORM_VALUES:
      return {
        ...state,
        searchFormValues: action.payload,
      };
    case RESET_INITIAL_STATE:
      return initialState;
    case SET_BEFORE_SEARCH_PARAM:
      return {
        ...state,
        beforeSearchParam: action.payload,
      };
    case CLEAR_MESSAGE:
      return {
        ...state,
        message: '',
        success: false,
      };
    default:
      return state;
  }
}

/**
 * 해지예상 조회
 */
export const selectCancFrcsAndFrcsInt = (searchParams) => {
  return {
    type: FETCH_CANC_FRCS_AND_FRCS_INT,
    payload: fetchCancFrcsAndFrcsInt(searchParams),
  };
};

/**
 * 메시지초기화
 */
export const clearMessage = () => {
  return {
    type: CLEAR_MESSAGE,
  };
};

/**
 *
 */
export const setBeforeSearchParam = (searchParams) => {
  return {
    type: SET_BEFORE_SEARCH_PARAM,
    payload: searchParams,
  };
};

/**
 * 조회조건 저장
 */
export const saveSearchFormValues = (searchFormValues) => {
  return {
    type: SAVE_SEARCH_FORM_VALUES,
    payload: {
      ...searchFormValues,
      init: false,
    },
  };
};

/**
 * 상태초기화
 */
export const resetInitialState = () => {
  return {
    type: RESET_INITIAL_STATE,
  };
};
