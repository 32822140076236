import instance from '../../common/config/api';

/**
 * 세입계좌 현황 - 세입이체일정표 전체 조회
 * @param {object} params
 * @returns
 */
export const fetchAnrvTsDscAllList = (params) =>
  instance.post(`${process.env.REACT_APP_SERVER_URL}/scq/scq/arv/0002/selectAnrvTsDscAll`, params);

/**
 * 세입계좌 현황 - 세입이체일정표 조회
 * @param {object} params
 * @returns
 */
export const fetchAnrvTsDscList = (params) =>
  instance.post(`${process.env.REACT_APP_SERVER_URL}/scq/scq/arv/0002/selectAnrvTsDsc`, params);

/**
 * 세입계좌 현황 - 세입이체일정 상세일정 조회
 * @param {object} params
 * @returns
 */
export const fetchBymnAnrvTsDtlDscList = (params) =>
  instance.post(`${process.env.REACT_APP_SERVER_URL}/scq/scq/arv/0002/selectBymnAnrvTsDtlDsc`, params);

/**
 * 세입계좌 현황 - 초기일정 생성
 * @param {object} params
 * @returns
 */
export const insertListErdsDsc = (params) =>
  instance.post(`${process.env.REACT_APP_SERVER_URL}/scq/scq/arv/0002/InsertErdsDsc`, params);

/**
 * 세입계좌 현황 - 초기일정 삭제
 * @param {object} params
 * @returns
 */
export const deleteListErdsDsc = (params) =>
  instance.post(`${process.env.REACT_APP_SERVER_URL}/scq/scq/arv/0002/DeleteErdsDsc`, params);

/**
 * 세입계좌 현황 - 세입이체일정 상세일정 저장
 * @param {array} dtos
 * @returns
 */
export const saveListAnrvTsDtlDsc = (dtos) =>
  instance.post(`${process.env.REACT_APP_SERVER_URL}/scq/scq/arv/0002/saveAnrvTsDtlDscList`, {
    main: dtos,
  });
