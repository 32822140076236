import { SUCCESS, REQUEST, FAILURE } from '../../../common/utils/action-type-utils';
import { fetchUserLogSt, fetchUserLogStDtl } from '../../../lib/sys/SYS0209V01.api';

// actions
const FETCH_USER_LOG_ST = 'SYS0209V01/fetchUserLogSt';
const FETCH_USER_LOG_ST_DTL = 'SYS0209V01/fetchUserLogStDtl';

// initial state
const initialState = {
  loading: false,
  success: true,
  message: '',
  list: [],
};

// reducers
export default function SYS0209V01(state = initialState, action) {
  // 부서,사용자별 사용 현황
  switch (action.type) {
    case SUCCESS(FETCH_USER_LOG_ST): // 조회 성공
      return {
        ...state,
        success: true,
        loading: false,
        list: action.payload.data?.main,
      };
    case REQUEST(FETCH_USER_LOG_ST): // 조회 요청
      return {
        ...state,
        loading: true,
      };
    case FAILURE(FETCH_USER_LOG_ST): // 조회실패
      return {
        ...initialState,
        loading: false,
        success: false,
        message: action.payload.response?.data?.message,
      };
    // 사용자별 사용 내역
    case SUCCESS(FETCH_USER_LOG_ST_DTL): // 조회 성공
      return {
        ...state,
        success: true,
        subLoading: false,
        subList: action.payload.data?.sub,
      };
    case REQUEST(FETCH_USER_LOG_ST_DTL): // 조회 요청
      return {
        ...state,
        subLoading: true,
      };
    case FAILURE(FETCH_USER_LOG_ST_DTL): // 조회실패
      return {
        ...initialState,
        subLoading: false,
        success: false,
        message: action.payload.response?.data?.message,
      };
    default:
      return { ...state };
  }
}

export const selectUserLogSt = (searchParams) => {
  return {
    type: FETCH_USER_LOG_ST,
    payload: fetchUserLogSt(searchParams),
  };
};
export const selectUserLogStDtl = (searchParams) => {
  return {
    type: FETCH_USER_LOG_ST_DTL,
    payload: fetchUserLogStDtl(searchParams),
  };
};
