import instance from '../../common/config/api';

const PREFIX = `${process.env.REACT_APP_SERVER_URL}/scq/scq/rpt/0006`;

/**
 * 회계별 세출자금수지 현황 조회
 * @returns
 */
export const fetchEcadBiztAnexFdRcexSts = (params) => instance.post(`${PREFIX}/selectEcadBiztAnexFdRcexSts`, params);

/**
 * HOST 거래여부 조회(세출, 기금)
 * @returns
 */
export const fetchHostTrnYn = (params) => instance.post(`${PREFIX}/selectHostTrnYn`, params);

/**
 * 예산현액 조회
 * @returns
 */
export const fetchBgtCtAm = (params) => instance.post(`${PREFIX}/selectBgtCtAm`, params);

/**
 * 예산현액 조회(기금)
 * @returns
 */
export const fetchBgtCtAmGigum = (params) => instance.post(`${PREFIX}/selectBgtCtAmGigum`, params);

/**
 * 세출자금수지현황 (세출, 기금) 조회(세출자금 수지현황 보고서)
 * @returns
 */
export const fetchAnexRcexSts = (params) => instance.post(`${PREFIX}/selectAnexRcexSts`, params);

/**
 * 구년도 세출자금수지현황 (세출) 조회
 * @returns
 */
export const fetchOldYyAnexRcexSts = (params) => instance.post(`${PREFIX}/selectOldYyAnexRcexSts`, params);

/**
 * 구년도 세출자금수지현황 (기금) 조회
 * @returns
 */
export const fetchOldYyAnexRcexStsGigum = (params) => instance.post(`${PREFIX}/selectOldYyAnexRcexStsGigum`, params);

/**
 * 연/월평잔 회계구하기
 * @returns
 */
export const fetchYearAvbl = (params) => instance.post(`${PREFIX}/selectYearAvbl`, params);

/**
 * 본청 평잔 회계구하기
 * @returns
 */
export const fetchSeoulAvbl = (params) => instance.post(`${PREFIX}/selectSeoulAvbl`, params);

/**
 * 연평잔 메인쿼리
 * @returns
 */
export const fetchYearAvblMain = (params) => instance.post(`${PREFIX}/selectYearAvblMain`, params);

/**
 * 월평잔 메인쿼리
 * @returns
 */
export const fetchMmAvblMain1 = (params) => instance.post(`${PREFIX}/selectMmAvblMain1`, params);

/**
 * 월평잔 메인쿼리2
 * @returns
 */
export const fetchMmAvblMain2 = (params) => instance.post(`${PREFIX}/selectMmAvblMain2`, params);

/**
 * 연평잔 메인 본청
 * @returns
 */
export const fetchYearAvblMainHq = (params) => instance.post(`${PREFIX}/selectYearAvblMainHq`, params);

/**
 * 월평잔 메인 본청1
 * @returns
 */
export const fetchMmAvblMainHq1 = (params) => instance.post(`${PREFIX}/selectMmAvblMainHq1`, params);

/**
 * 월평잔 메인 본청2
 * @returns
 */
export const fetchMmAvblMainHq2 = (params) => instance.post(`${PREFIX}/selectMmAvblMainHq2`, params);
