import instance from '../../common/config/api';

/**
 * 세출-세출거래내역장조회(과거)
 * @param {object} params
 * @returns
 */
export const fetchAnexTrnPrtsList = (params) =>
  instance.post(`${process.env.REACT_APP_SERVER_URL}/scq/scq/axp/0006/selectAnexTrnPrtsList`, params);

/**
 * 세출-세출거래내역장 건수조회(과거)
 * @param {object} params
 * @returns
 */
export const fetchAnexTrnPrtsCnt = (params) =>
  instance.post(`${process.env.REACT_APP_SERVER_URL}/scq/scq/axp/0006/selectAnexTrnPrtsCnt`, params);

/**
 * 세출-세출거래내역장조회(과거) - 2023년 이전 용산구청
 * @param {object} params
 * @returns
 */
export const fetchAnexTrnPrtsListOld = (params) =>
  instance.post(`${process.env.REACT_APP_SERVER_URL}/scq/scq/axp/0006/selectAnexTrnPrtsListOld`, params);

/**
 * 세출-세출거래내역장 건수조회(과거) - 2023년 이전 용산구청
 * @param {object} params
 * @returns
 */
export const fetchAnexTrnPrtsCntOld = (params) =>
  instance.post(`${process.env.REACT_APP_SERVER_URL}/scq/scq/axp/0006/selectAnexTrnPrtsOldCnt`, params);