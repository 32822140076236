import dayjs from 'dayjs';
import { SUCCESS, REQUEST, FAILURE } from '../../../common/utils/action-type-utils';
import { fetchCommonComboList } from '../../../lib/cmm/combo.api';
import { fetchAnrvMmRptHqGnrn, fetchAnrvMmRptHqGnrnPrts } from '../../../lib/rpt/RPT0104V01.api';

// actions
const FETCH_ANRV_MM_RPT_HQ_GNRN = 'RPT0104V01/FETCH_ANRV_MM_RPT_HQ_GNRN';
const FETCH_ANRV_MM_RPT_HQ_GNRN_PRTS = 'RPT0104V01/FETCH_ANRV_MM_RPT_HQ_GNRN_PRTS';
const FETCH_ACC_YR_LIST = 'RPT0104V01/FETCH_ACC_YR_LIST';
const SAVE_SEARCH_FORM_VALUES = 'RPT0104V01/SAVE_SEARCH_FORM_VALUES';
const RESET_STATE = 'RPT0104V01/RESET_STATE';

export const initFormValues = {
  pgm_dis: '1382-95',
  ydcs8a1_t8a02_acc_no: '00010', // 회계조회구분
  ydcs8a1_t8a02_acc_yr: dayjs().format('YYYY'),
  ydcs8a1_t8a02_inq_dt: dayjs().format('YYYY-MM-DD'),
  ydcs8a1_t8a02_inq_edt: dayjs().format('YYYY-MM-DD'),
  ydcs8a1_t8a02_inq_mn: dayjs().format('YYYY-MM'),
  ydcs8a1_t8a02_ist_no: '001',
  ydcs8a1_t8a02_rep_dscd: '1', // 조회구분 (1: 월계표, 3: 거래내역장)
  pmdp_act_dscd: '01', // 01=세입, 02=세출
  activeTab: 'tab1', // 보고서
};

// initial state
const initialState = {
  loading: false,
  success: null,
  message: '',
  list: [],
  list2: [],
  title: {},
  totalCount: 0,
  updateSuccess: false,
  updating: false,
  // 회계연도
  accYrSuccess: false,
  accYrLoading: false,
  accYrList: [],
  // 검색조건
  searchFormValues: {
    init: true,
    ...initFormValues,
  },
};

// reducers
export default function RPT0104V01(state = initialState, action) {
  switch (action.type) {
    case SUCCESS(FETCH_ANRV_MM_RPT_HQ_GNRN): // 조회 성공
      return {
        ...state,
        success: true,
        loading: false,
        list: action.payload.data?.output_data,
        title: action.payload.data?.output_title,
        totalCount: action.payload?.data?.output_data_cnt,
      };
    case REQUEST(FETCH_ANRV_MM_RPT_HQ_GNRN): // 조회 요청
      return {
        ...state,
        success: false,
        loading: true,
        message: '',
        list: [],
        totalCount: 0,
      };
    case FAILURE(FETCH_ANRV_MM_RPT_HQ_GNRN): // 조회실패
      return {
        ...state,
        list: [],
        loading: false,
        success: false,
        message: action.payload.response?.data?.message,
      };
    case SUCCESS(FETCH_ANRV_MM_RPT_HQ_GNRN_PRTS): // 조회 성공
      return {
        ...state,
        success: true,
        loading: false,
        list2: action.payload.data?.output_data,
        // title: action.payload.data?.output_title,
        totalCount: action.payload?.data?.output_data_cnt,
      };
    case REQUEST(FETCH_ANRV_MM_RPT_HQ_GNRN_PRTS): // 조회 요청
      return {
        ...state,
        success: false,
        loading: true,
        message: '',
        list2: [],
        totalCount: 0,
      };
    case FAILURE(FETCH_ANRV_MM_RPT_HQ_GNRN_PRTS): // 조회실패
      return {
        ...state,
        list2: [],
        loading: false,
        success: false,
        message: action.payload.response?.data?.message,
      };
    // 회계연도
    case SUCCESS(FETCH_ACC_YR_LIST): // 조회 성공
      return {
        ...state,
        accYrSuccess: true,
        accYrLoading: false,
        accYrList: action.payload.data?.subDto,
      };
    case REQUEST(FETCH_ACC_YR_LIST): // 조회 요청
      return {
        ...state,
        accYrLoading: true,
        accYrSuccess: false,
      };
    case FAILURE(FETCH_ACC_YR_LIST): // 조회실패
      return {
        ...state,
        accYrLoading: false,
        accYrSuccess: false,
        accYrList: [],
      };
    case SAVE_SEARCH_FORM_VALUES:
      return {
        ...state,
        // 회계연도
        accYrSuccess: false,
        accYrLoading: false,
        message: '',
        searchFormValues: action.payload,
      };
    case RESET_STATE:
      return initialState;
    default:
      return state;
  }
}

/**
 * 보고서 조회
 */
export const selectAnrvMmRptHqGnrn = (searchParams) => {
  return {
    type: FETCH_ANRV_MM_RPT_HQ_GNRN,
    payload: fetchAnrvMmRptHqGnrn(searchParams),
  };
};

/**
 * 내역 조회
 */
export const selectAnrvMmRptHqGnrnPrts = (searchParams) => {
  return {
    type: FETCH_ANRV_MM_RPT_HQ_GNRN_PRTS,
    payload: fetchAnrvMmRptHqGnrnPrts(searchParams),
  };
};

/**
 * 회계연도 콤보 조회
 */
export const selectAccYrComboList = () => {
  return {
    type: FETCH_ACC_YR_LIST,
    payload: fetchCommonComboList({ slt_box_no: '025' }),
  };
};

/**
 * 조회조건 저장
 */
export const saveSearchFormValues = (searchFormValues) => {
  return {
    type: SAVE_SEARCH_FORM_VALUES,
    payload: {
      ...searchFormValues,
      init: false,
    },
  };
};

/**
 * 상태초기화
 */
export const resetState = () => {
  return {
    type: RESET_STATE,
  };
};
