import React, { useCallback, useEffect, useRef, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import styled from 'styled-components';
import WOButton from '../../components/common/WOButton';
import WOModal, { ButtonRightArea, ModalButtonArea, ModalContentArea } from '../../components/common/WOModal';
import { Controller, useForm } from 'react-hook-form';
import * as S from '../../styles/subPage.styled';
import WOLoginInput from '../../components/common/WOLoginInput';
import {
  checkAuthNumberByAuthNo,
  resetAuthNumberState,
  sendAuthNumberToHpNo,
} from '../../redux/modules/cmm/authNumber.reducer';
import useAlert from '../../hooks/useAlert';
import { useNavigate, useSearchParams } from 'react-router-dom';
import { lpad } from '../../common/utils/utils';

function AdminAuthPopup({ isOpen, onClose }) {
  const { alert } = useAlert();
  const dispatch = useDispatch();
  const [isOpen2, setIsOpen2] = useState(isOpen);
  // 인증만료시간
  const timer = useRef(null);
  // 5분
  const defaultInterval = 300;
  const [time, setTime] = useState(defaultInterval);
  const { hpNo, cmSession, menuList } = useSelector((state) => state.loginState);
  const [searchParams] = useSearchParams();
  const navigate = useNavigate();

  // 인증번호
  const {
    loadingByAuthSend,
    successByAuthSend,
    message: messageByAuthSend,
    loadingByAuthCheck,
    successByAuthCheck,
    isAuth,
    isTimeout,
  } = useSelector((state) => state.authNumberState);
  const { reset, setValue, getValues, control } = useForm({
    defaultValues: {
      hp_no: hpNo,
      auth_no: '',
    },
  });

  const setTimer = () => {
    if (timer.current) {
      clearInterval(timer.current);
    }
    setTime(defaultInterval);
    timer.current = setInterval(() => {
      setTime((prev) => prev - 1);
    }, 1000);
  };

  useEffect(() => {
    if (time < 0) {
      if (timer.current) {
        clearInterval(timer.current);
        setTime(0);
      }
    }
  }, [time]);

  /**
   * 인증번호 전송
   */
  const handleSendAuthNo = useCallback(() => {
    const searchParams = getValues();
    dispatch(sendAuthNumberToHpNo(searchParams));
  }, [dispatch, getValues]);

  /**
   * 인증번호 확인
   */
  const handleCheckAuthNo = useCallback(() => {
    const searchParams = getValues();
    dispatch(checkAuthNumberByAuthNo(searchParams));
  }, [dispatch, getValues]);

  useEffect(() => {
    setValue('hp_no', hpNo);
  }, [hpNo, setValue]);

  useEffect(() => {
    if (!loadingByAuthSend && successByAuthSend) {
      alert('인증번호가 전송되었습니다.\n전송된 인증번호를 입력해주세요.', '인증번호 전송');
      setTimer();
    } else if (!loadingByAuthSend && !successByAuthSend && messageByAuthSend) {
      alert(messageByAuthSend, '인증번호 전송');
    }
  }, [loadingByAuthSend, successByAuthSend, messageByAuthSend, alert]);

  useEffect(() => {
    if (!loadingByAuthCheck && successByAuthCheck && isAuth) {
      const userInfo = cmSession?.at(0);
      localStorage.setItem('user_info', JSON.stringify(userInfo));
      localStorage.setItem('menu_list', JSON.stringify(menuList.filter((menu) => menu.mnu_aut_cd !== 'X')));

      // 세션 만료시 다시 로그인 하면 전 화면으로
      const redirectUrl = searchParams.get('redirect');
      if (redirectUrl) {
        navigate(redirectUrl);
      } else {
        navigate('/');
      }
    }
  }, [loadingByAuthCheck, successByAuthCheck, isAuth, cmSession, menuList, searchParams, navigate]);

  useEffect(() => {
    setIsOpen2(isOpen);
  }, [isOpen]);

  useEffect(() => {
    return () => {
      dispatch(resetAuthNumberState());
      if (timer.current) {
        clearInterval(timer.current);
      }
    };
  }, [dispatch]);

  return (
    <>
      {/* 관리자 인증번호 확인 */}
      <WOModal direction="center" height={300} isOpen={isOpen2} title={'관리자 인증번호 확인'} onClose={onClose}>
        <ModalContentArea $gap={8}>
          <S.LoginModalDescript>* 관리자 확인을 위한 인증번호를 입력해 주세요.</S.LoginModalDescript>
          <Controller
            control={control}
            name={'hp_no'}
            render={({ field: { onChange, name, value } }) => (
              <WOLoginInput
                disabled
                label="핸드폰번호"
                placeholder="핸드폰번호"
                value={value}
                onChange={onChange}
                button={
                  <AuthBtn type="button" className="auth-btn" onClick={handleSendAuthNo}>
                    인증번호 전송
                  </AuthBtn>
                }
              />
            )}
          />
          <Controller
            control={control}
            name={'crtf_no'}
            render={({ field: { onChange, name, value } }) => (
              <WOLoginInput
                id="input01-01"
                label="인증번호"
                name={name}
                styleType={'popup'}
                placeholder="인증번호를 입력해 주세요."
                time={`${lpad(Math.floor(time / 60), 2, '0')}:${lpad(time % 60, 2, '0')}`}
                value={value}
                onChange={onChange}
              />
            )}
          />
        </ModalContentArea>
        <ModalButtonArea $nonBoxShadow={true}>
          <ButtonRightArea>
            <WOButton variant={'secondary'} size={'md'} onClick={onClose}>
              취소
            </WOButton>
            <WOButton variant={'primary'} size={'md'} onClick={handleCheckAuthNo}>
              확인
            </WOButton>
          </ButtonRightArea>
        </ModalButtonArea>
      </WOModal>
    </>
  );
}

export default AdminAuthPopup;

const AuthBtn = styled.button`
  display: flex;
  align-items: center;
  width: fit-content;
  height: 32px;
  padding: 0px 16px;
  color: var(--C-Dark-10);
  font-size: 12px;
  font-weight: 700;
  border-radius: 6px;
  background: var(--C-Dark-03, #e5e5ed);
  &:disabled {
    color: var(--C-Dark-06, #9ea6b2);
  }
`;
