import React, { useState } from 'react';
import { Link, useNavigate } from 'react-router-dom';
import styled, { css } from 'styled-components';
import WOIcon from '../common/WOIcon';

const WOMainTotalSearchForm = ({ id, name, value, onChange, placeholder, onClick, type, menuList }) => {
  const [inputText, setInputText] = useState(value || '');
  const navigate = useNavigate();
  const handleOnChange = (e) => {
    const value = e.target.value;
    setInputText(value);
    if (onChange) {
      onChange(value);
    }
  };

  const handleOnClick = (e) => {
    if (onClick && e.key === 'Enter') {
      onClick(inputText);
    }
  };

  const clearText = () => {
    setInputText(''); // 입력 필드를 비우는 함수
    onChange?.('');
  };

  // 특수문자 이스케이프 처리
  function escapeRegExp(string) {
    return string.replace(/[.*+?^${}()|[\]\\]/g, '\\$&');
  }

  // 검색어 하이라이트를 위한 컴포넌트
  const HighlightText = ({ text, highlight }) => {
    highlight = highlight?.toUpperCase();
    // highlight가 null이거나 빈 문자열인 경우, 처리하지 않고 반환
    if (!highlight) {
      return <span>{text}</span>;
    }

    // 검색어에 특수 문자가 포함되어 있을 경우 이스케이프 처리
    const escapedHighlight = escapeRegExp(highlight);

    // text가 escapedHighlight를 포함하지 않는 경우, 원래 text를 반환
    if (!text?.includes(highlight)) {
      return <span>{text}</span>;
    }

    const parts = text.split(new RegExp(`(${escapedHighlight})`, 'gi'));
    return (
      <span>
        {parts.map((part, index) =>
          part.toLowerCase() === highlight.toLowerCase() ? (
            <span key={index} className="highlight">
              {part}
            </span>
          ) : (
            part
          )
        )}
      </span>
    );
  };

  return (
    <Wrapper className="search-form" $isActive={inputText} $type={type}>
      <Form
        type="text"
        id={id}
        name={name}
        value={inputText}
        placeholder={placeholder}
        onChange={handleOnChange}
        onKeyDown={(e) => handleOnClick(e)}
        $isActive={inputText}
      />
      <WOIcon icon="main_search_icon" width={16} height={16} />
      <SearchResultWrap $isActive={inputText}>
        {menuList
          .filter((menu) => menu.mnu_aut_cd !== 'X')
          .filter((menu) => menu.scrn_url)
          .map((menu) => {
            const lv2 = menuList.find((item) => item.mnu_seq_no === menu?.higrk_mnu_seq_no);
            const lv1 = menuList.find((item) => item.mnu_seq_no === lv2?.higrk_mnu_seq_no);
            return { ...menu, lv1MenuNm: lv1?.mnu_nm, lv2MenuNm: lv2?.mnu_nm };
          })
          .filter(
            (menu) =>
              menu.mnu_nm.includes(inputText.toUpperCase()) ||
              menu.lv1MenuNm?.includes(inputText.toUpperCase()) ||
              menu.lv2MenuNm?.includes(inputText.toUpperCase())
          )
          .map((menu) => (
            // 검색결과
            <ResultItem key={menu.mnu_seq_no} to={menu.scrn_url} className="search-link-group">
              {menu?.lv1MenuNm && (
                <>
                  <HighlightText text={menu.lv1MenuNm} highlight={inputText} /> &gt;
                </>
              )}
              {menu?.lv2MenuNm && (
                <>
                  <HighlightText text={menu.lv2MenuNm} highlight={inputText} /> &gt;
                </>
              )}
              <HighlightText text={menu.mnu_nm} highlight={inputText} />
            </ResultItem>
          ))}

        {/* <ResultItem onClick={() => alert('aa')}>
          제로페이 &gt; 제로페이 <span className="highlight">조회</span> &gt; 집행내역
          <span className="highlight">조회</span>
        </ResultItem>
        <ResultItem>
          제로페이 &gt; 제로페이 <span className="highlight">조회</span> &gt; 집행내역
          <span className="highlight">조회</span>
        </ResultItem> */}
      </SearchResultWrap>
      {(inputText + '')?.length > 0 && <ClearBtn className="clear-btn" type="button" onClick={clearText} />}
    </Wrapper>
  );
};

export default WOMainTotalSearchForm;

const Wrapper = styled.div`
  position: relative;
  ${(props) =>
    props.$isActive &&
    css`
      border-radius: 8px;
      box-shadow: 0px 4px 10px 0px rgba(0, 0, 0, 0.15);
      overflow: hidden;
    `}
  .icon {
    position: absolute;
    top: 10px;
    right: 12px;
  }
`;
const SearchResultWrap = styled.div`
  display: none;
  width: 432px;
  height: 240px;
  padding: 12px;
  max-height: 240px;
  background: #fff;
  overflow-y: auto;
  ${(props) =>
    props.$isActive &&
    css`
      display: block;
    `};
`;
const ResultItem = styled(Link)`
  display: block;
  font-size: 14px;
  font-weight: 400;
  color: #0e213f;
  & + & {
    margin-top: 8px;
  }
  .highlight {
    color: #3284ff;
  }
`;
const Form = styled.input`
  display: flex;
  width: 432px;
  height: 36px;
  padding: 0 30px 0 12px;
  border-radius: 8px;
  background: var(--C-Dark-01);
  font-size: 14px;
  font-weight: 400;
  line-height: 18px;
  color: #333;
  border: 1px solid transparent;
  &:focus {
    border: 1px solid var(--C-Dark-06);
    outline: none;
  }
  &::placeholder {
    height: '#9ea6b2';
  }
  ${(props) =>
    props.$isActive &&
    css`
      background: #fff;
      &:focus {
        border: 1px solid transparent;
      }
    `}
`;
const ClearBtn = styled.button`
  position: absolute;
  top: 10px;
  right: 36px;
  width: 16px;
  height: 16px;
  background: url(/assets/images/icon/input-clear-gray.svg) no-repeat center / cover;
`;
