import { fetchBizNoMng, fetchBizNoDupMng, saveBizInfo, removeBizInfo } from '../../../lib/brc/BRC0002.api';
import { SUCCESS, REQUEST, FAILURE } from '../../../common/utils/action-type-utils';

// actions
const FETCH_BIZ_NO_MNG = 'BRC0204M01/FETCH_BIZ_NO_MNG';
const FETCH_BIZ_NO_DUP_MNG = 'BRC0204M01/FETCH_BIZ_NO_DUP_MNG';
const SAVE_BIZ_INFO = 'BRC0204M01/SAVE_BIZ_INFO';
const DELETE_BIZ_INFO = 'BRC0204M01/DELETE_BIZ_INFO';

const CLEAR_MESSAGE = 'BRC0204M01/CLEAR_MESSAGE';

// initial state
const initialState = {
  loading: false,
  success: true,
  message: '',
  list: [],
  details: [],
  detailsSuccess: false,
  detailsLoading: false,
  detailsMessage: false,
  totalCount: 0,
  updateLoading: false, // 등록/수정/삭제
  updateSuccess: false, // 등록/수정/삭제
  updateCount: 0, // 등록/수정/삭제
};

// reducers
export default function BRC0204M01(state = initialState, action) {
  switch (action.type) {
    case SUCCESS(FETCH_BIZ_NO_MNG): // 조회 성공
      return {
        ...state,
        success: true,
        loading: false,
        list: action.payload.data?.main,
        totalCount: action.payload?.mainCnt,
      };
    case REQUEST(FETCH_BIZ_NO_MNG): // 조회 요청
      return {
        ...state,
        loading: true,
      };
    case FAILURE(FETCH_BIZ_NO_MNG): // 조회실패
      return {
        ...state,
        loading: false,
        success: false,
        message: action.payload.response?.data?.message,
      };
    case SUCCESS(SAVE_BIZ_INFO): // 등록 성공
      return {
        ...state,
        updateSuccess: true,
        updateLoading: false,
        updateCount: action.payload.data?.resultCnt,
      };
    case REQUEST(SAVE_BIZ_INFO): // 등록 요청
      return {
        ...state,
        updateLoading: true,
        updateSuccess: false,
      };
    case FAILURE(SAVE_BIZ_INFO): // 등록 실패
      return {
        ...state,
        updateLoading: false,
        updateSuccess: false,
        message: action.payload.response?.data?.message,
      };
    case SUCCESS(DELETE_BIZ_INFO): // 삭제 성공
      return {
        ...state,
        updateSuccess: true,
        updateLoading: false,
        updateCount: action.payload.data?.resultCnt,
      };
    case REQUEST(DELETE_BIZ_INFO): // 삭제 요청
      return {
        ...state,
        updateLoading: true,
        updateSuccess: false,
      };
    case FAILURE(DELETE_BIZ_INFO): // 삭제 실패
      return {
        ...state,
        updateLoading: false,
        updateSuccess: false,
        message: action.payload.response?.data?.message,
      };
    case CLEAR_MESSAGE:
      return {
        ...state,
        message: '',
        detailsMessage: '',
        success: false,
        detailsSuccess: false,
        updateCount: 0,
      };
    default:
      return state;
  }
}

/**
 * 기관별 사업자번호 조회
 */
export const selectBizNoMng = (searchParams) => {
  return {
    type: FETCH_BIZ_NO_MNG,
    payload: fetchBizNoMng(searchParams),
  };
};

/**
 * 기관별 사업자번호 중복리스트 조회
 */
export const selectBizNoDupMng = (searchParams) => {
  return {
    type: FETCH_BIZ_NO_DUP_MNG,
    payload: fetchBizNoDupMng(searchParams),
  };
};

/**
 * 기관별 사업자번호 저장 ( INSERT )
 */
export const insertBizInfo = (searchParams) => {
  return {
    type: SAVE_BIZ_INFO,
    payload: saveBizInfo({ rowtype: 'INSERT', ...searchParams }),
  };
};

/**
 * 기관별 사업자번호 수정 ( UPDATE )
 */
export const updateBizInfo = (searchParams) => {
  return {
    type: SAVE_BIZ_INFO,
    payload: saveBizInfo({ rowtype: 'UPDATE', ...searchParams }),
  };
};

/**
 * 기관별 사업자번호 삭제 ( DELETE )
 */
export const deleteBizInfo = (searchParams) => {
  return {
    type: DELETE_BIZ_INFO,
    payload: removeBizInfo(searchParams),
  };
};

/**
 * 메시지초기화
 */
export const clearMessage = () => {
  return {
    type: CLEAR_MESSAGE,
  };
};
