import { SUCCESS, REQUEST, FAILURE } from '../../../common/utils/action-type-utils';
import { fetchEtcPayNtcdList } from '../../../lib/etc/etc0102v01.api';
import dayjs from 'dayjs';

// actions
const FETCH_PAYNTCD = 'etcPayNtcd/FETCH_PAYNTCD';
const RESET_STATE = 'etcFdtsPrts/RESET_STATE';
const SAVE_SEARCH_FORM_VALUES = 'etcFdtsPrts/SAVE_SEARCH_FORM_VALUES';

export const initFormValues = {
  inq_seg: '1', // 일자구분
  inq_dt: dayjs().format('YYYY-MM-DD'), // 일자
  iche_gbn: '0', // 이체구분
  inq_sdt: dayjs().format('YYYY-MM-DD'),
  inq_edt: dayjs().format('YYYY-MM-DD'),
};

// initial state
const initialState = {
  loading: false,
  success: false,
  message: '',
  list: [],
  totalCount: 0,
  acgubunList: [
    { id: 'A', label: '서울' },
    { id: 'C', label: '지방' },
  ],
  searchFormValues: {
    init: true,
    ...initFormValues,
  },
};

// reducers
export default function etcPayNtcd(state = initialState, action) {
  switch (action.type) {
    case SUCCESS(FETCH_PAYNTCD): // 조회 성공
      return {
        ...state,
        success: true,
        loading: false,
        list: action.payload.data?.main,
        totalCount: action.payload?.main_cnt,
      };
    case REQUEST(FETCH_PAYNTCD): // 조회 요청
      return {
        ...state,
        loading: true,
        success: false,
      };
    case FAILURE(FETCH_PAYNTCD): // 조회실패
      return {
        ...initialState,
        loading: false,
        success: false,
        message: action.payload.response?.data?.message,
      };
    case SAVE_SEARCH_FORM_VALUES:
      return {
        ...state,
        searchFormValues: action.payload,
      };
    case RESET_STATE:
      return initialState;
    default:
      return state;
  }
}

/**
 * 기타 - 지급통지서 출력
 */
export const selectEtcPayNtcdList = (searchParams) => {
  return {
    type: FETCH_PAYNTCD,
    payload: fetchEtcPayNtcdList(searchParams),
  };
};

/**
 * 조회조건 저장
 */
export const saveSearchFormValues = (searchFormValues) => {
  return {
    type: SAVE_SEARCH_FORM_VALUES,
    payload: {
      ...searchFormValues,
      init: false,
    },
  };
};

/**
 * 승인·집행 불일치 상태초기화
 */
export const resetEtcPayNtcd = () => {
  return {
    type: RESET_STATE,
  };
};
