import instance from '../../common/config/api';

/**
 * 기타 - 공금계좌 집계조회
 * @param {object} params
 * @returns
 */

export const fetchEtcPmnyActSumtsList = (params) =>
  instance.post(`${process.env.REACT_APP_SERVER_URL}/scq/scq/etc/0002/selectListPmnyActSum`, params);
