import instance from '../../common/config/api';

/**
 * 시스템관리 - 사용자 - 업무별 사용량 집계 
 * @param {object} params
 * @returns
 */

export const fetchUseLogSt = (params) =>
  instance.post(`${process.env.REACT_APP_SERVER_URL}/scq/scq/sys/0002/selectUseLogSt`, params);
export const fetchUseLogStDtl = (params) =>
  instance.post(`${process.env.REACT_APP_SERVER_URL}/scq/scq/sys/0002/selectUseLogStDtl`, params);

