import instance from '../../common/config/api';

/**
 * 금고영업점 - 취소결제관리 조회
 * @param {object} params
 * @returns
 */
export const fetchCanStlMngList = (params) =>
  instance.post(`${process.env.REACT_APP_SERVER_URL}/scq/scq/brc/0001/selectPublicEarlyRepayRequestList`, params);

/**
 * 금고영업점 - 취소결제관리 수정
 * @param {object} dtos
 * @returns
 */
export const updateCanStlMngList = (dtos) =>
  instance.post(`${process.env.REACT_APP_SERVER_URL}/scq/scq/brc/0001/updateEarlyRepayInfo`, {
    main: dtos,
    mainCnt: dtos.length,
  });

/**
 * 금고영업점 - 중도상환정보 2단계 일괄결재
 * @param {object} dtos
 * @returns
 */
export const update2ndAprvEarlyRepayInfo = (dtos) =>
  instance.post(`${process.env.REACT_APP_SERVER_URL}/scq/scq/brc/0001/update2ndAprvEarlyRepayInfo`, {
    main: dtos,
  });
