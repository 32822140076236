import { SUCCESS, REQUEST, FAILURE } from '../../../common/utils/action-type-utils';
import {
  deleteListErdsDsc,
  fetchBymnAnrvTsDtlDscList,
  insertListErdsDsc,
  saveListAnrvTsDtlDsc,
} from '../../../lib/arv/ARV0201V01.api';

// actions
const FETCH_BYMN_ANRV_TS_DTL_DSC_LIST = 'ARV0201P01/FETCH_BYMN_ANRV_TS_DTL_DSC_LIST';
const RESET_BYMN_ANRV_TS_DTL_DSC_LIST = 'ARV0201P01/RESET_BYMN_ANRV_TS_DTL_DSC_LIST';
const INSERT_ERDS_DSC_LIST = 'ARV0201P01/INSERT_ERDS_DSC_LIST';
const DELETE_ERDS_DSC_LIST = 'ARV0201P01/DELETE_ERDS_DSC_LIST';
const SAVE_ANRV_TS_DTL_DSC_LIST = 'ARV0201P01/SAVE_ANRV_TS_DTL_DSC_LIST';

// initial state
const initialState = {
  loading: false,
  success: false,
  updating: false,
  updateSuccess: false,
  message: '',
  list: [],
  totalCount: 0,
};

// reducers
export default function ARV0201P01(state = initialState, action) {
  switch (action.type) {
    case SUCCESS(FETCH_BYMN_ANRV_TS_DTL_DSC_LIST): // 조회 성공
      return {
        ...state,
        success: true,
        loading: false,
        list: action.payload.data?.output_data,
        totalCount: action.payload?.data?.output_data_cnt,
      };
    case REQUEST(FETCH_BYMN_ANRV_TS_DTL_DSC_LIST): // 조회 요청
      return {
        ...state,
        loading: true,
        success: false,
      };
    case FAILURE(FETCH_BYMN_ANRV_TS_DTL_DSC_LIST): // 조회실패
      return {
        ...state,
        list: [],
        loading: false,
        success: false,
        message: action.payload.response?.data?.message,
      };
    case SUCCESS(SAVE_ANRV_TS_DTL_DSC_LIST): // 저장 성공
    case SUCCESS(INSERT_ERDS_DSC_LIST): // 저장 성공
    case SUCCESS(DELETE_ERDS_DSC_LIST): // 저장 성공
      return {
        ...state,
        updateSuccess: true,
        updating: false,
      };
    case REQUEST(SAVE_ANRV_TS_DTL_DSC_LIST): // 저장 요청
    case REQUEST(INSERT_ERDS_DSC_LIST): // 저장 요청
    case REQUEST(DELETE_ERDS_DSC_LIST): // 저장 요청
      return {
        ...state,
        updating: true,
        updateSuccess: false,
      };
    case FAILURE(SAVE_ANRV_TS_DTL_DSC_LIST): // 저장 실패
    case FAILURE(INSERT_ERDS_DSC_LIST): // 저장 실패
    case FAILURE(DELETE_ERDS_DSC_LIST): // 저장 실패
      return {
        ...state,
        list: [],
        updateSuccess: false,
        updating: false,
        message: action.payload.response?.data?.message,
      };
    case RESET_BYMN_ANRV_TS_DTL_DSC_LIST:
      return {
        ...initialState,
      };
    default:
      return state;
  }
}

/**
 * 세입계좌 현황 - 세입이체일정 상세일정 조회
 */
export const selectBymnAnrvTsDtlDscList = (searchParams) => {
  return {
    type: FETCH_BYMN_ANRV_TS_DTL_DSC_LIST,
    payload: fetchBymnAnrvTsDtlDscList(searchParams),
  };
};

/**
 * 세입계좌 현황 - 초기일정 생성
 */
export const insertErdsDscList = (searchParams) => {
  return {
    type: INSERT_ERDS_DSC_LIST,
    payload: insertListErdsDsc(searchParams),
  };
};

/**
 * 세입계좌 현황 - 초기일정 삭제
 */
export const deleteErdsDscList = (searchParams) => {
  return {
    type: DELETE_ERDS_DSC_LIST,
    payload: deleteListErdsDsc(searchParams),
  };
};

/**
 * 세입계좌 현황 - 세입이체일정 상세일정 저장
 */
export const saveAnrvTsDtlDscList = (dtos) => {
  return {
    type: SAVE_ANRV_TS_DTL_DSC_LIST,
    payload: saveListAnrvTsDtlDsc(dtos),
  };
};

export const reset = () => {
  return {
    type: RESET_BYMN_ANRV_TS_DTL_DSC_LIST,
  };
};
