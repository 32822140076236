// actions
const SET_SELECTED_WORK_LIST = 'workState/SET_SELECTED_WORK_LIST';
const ADD_SELECTED_WORK_LIST = 'workState/ADD_SELECTED_WORK_LIST';
const DEL_SELECTED_WORK_LIST = 'workState/DEL_SELECTED_WORK_LIST';

// initial state
const initialState = {
  list: [],
};

// reducers
export default function workState(state = initialState, action) {
  switch (action.type) {
    case SET_SELECTED_WORK_LIST:
      return {
        ...state,
        list: action.payload,
      };
    case ADD_SELECTED_WORK_LIST:
      return {
        ...state,
        list:
          state.list.findIndex((work) => work.scrn_url === action.payload.scrn_url) < 0
            ? [...state.list, action.payload]
            : state.list,
      };
    case DEL_SELECTED_WORK_LIST:
      return {
        ...state,
        list: state.list.filter((item) => item.mnu_seq_no !== action.payload),
      };
    default:
      return state;
  }
}

/**
 * 선택된 업무 설정
 */
export const setSelectedWorkList = (workList) => {
  return {
    type: SET_SELECTED_WORK_LIST,
    payload: workList,
  };
};

/**
 * 선택된 업무 삭제
 */
export const delSelectedWorkList = (id) => {
  return {
    type: DEL_SELECTED_WORK_LIST,
    payload: id,
  };
};

/**
 * 선택된 업무 추가
 */
export const addSelectedWorkList = (work) => {
  return {
    type: ADD_SELECTED_WORK_LIST,
    payload: work,
  };
};
