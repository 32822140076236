import dayjs from 'dayjs';
import { SUCCESS, REQUEST, FAILURE } from '../../../common/utils/action-type-utils';
import { fetchCanStlMngList, update2ndAprvEarlyRepayInfo, updateCanStlMngList } from '../../../lib/brc/canStlMng.api';

// actions
const FETCH_CAN_STL_LIST = 'canStlMng/FETCH_CAN_STL_LIST';
const UPDATE_CAN_STL_LIST = 'canStlMng/UPDATE_CAN_STL_LIST';
const UPDATE_ALL_CAN_STL_LIST = 'canStlMng/UPDATE_ALL_CAN_STL_LIST';

const SAVE_SEARCH_FORM_VALUES = 'canStlMng/SAVE_SEARCH_FORM_VALUES';
const RESET_STATE = 'canStlMng/RESET_STATE';

export const initFormValues = {
  all_gb: 'all',
  doc: 'all',
  gubun: 'all',
  rfnd: 'all',
  sdt: dayjs().add(-7, 'days').format('YYYY-MM-DD'),
  edt: dayjs().format('YYYY-MM-DD'),
  sign: 'all',
  today: 'all',
};

// initial state
const initialState = {
  loading: false,
  updating: false,
  success: true,
  updateSuccess: false,
  message: '',
  list: [],
  totalCount: 0,
  // 검색조건
  searchFormValues: {
    init: true,
    ...initFormValues,
  },
};

// reducers
export default function canStlMng(state = initialState, action) {
  switch (action.type) {
    case SUCCESS(FETCH_CAN_STL_LIST): // 조회 성공
      return {
        ...state,
        success: true,
        loading: false,
        list: action.payload.data?.subDto,
        totalCount: action.payload?.subDtoCnt,
      };
    case REQUEST(FETCH_CAN_STL_LIST): // 조회 요청
      return {
        ...state,
        success: true,
        loading: true,
      };
    case FAILURE(FETCH_CAN_STL_LIST): // 조회실패
      return {
        ...initialState,
        loading: false,
        success: false,
        message: action.payload.response?.data?.message || action.payload.message,
      };

    case SUCCESS(UPDATE_ALL_CAN_STL_LIST): // 성공
    case SUCCESS(UPDATE_CAN_STL_LIST): // 성공
      return {
        ...state,
        updateSuccess: true,
        updating: false,
      };
    case REQUEST(UPDATE_ALL_CAN_STL_LIST): // 요청
    case REQUEST(UPDATE_CAN_STL_LIST): // 요청
      return {
        ...state,
        updateSuccess: false,
        message: null,
        updating: true,
      };
    case FAILURE(UPDATE_ALL_CAN_STL_LIST): // 실패
    case FAILURE(UPDATE_CAN_STL_LIST): // 실패
      return {
        ...initialState,
        updating: false,
        updateSuccess: false,
        message: action.payload.response?.data?.message,
      };
    case SAVE_SEARCH_FORM_VALUES:
      return {
        ...state,
        loading: false,
        updating: false,
        success: true,
        updateSuccess: false,
        message: '',
        searchFormValues: action.payload,
      };
    case RESET_STATE:
      return initialState;
    default:
      return state;
  }
}

/**
 * 취소결제관리 내역조회
 */
export const selectCanStlMng = (searchParams) => {
  return {
    type: FETCH_CAN_STL_LIST,
    payload: fetchCanStlMngList(searchParams),
  };
};

/**
 * 취소결제관리 수정
 */
export const updateCanStlMng = (dtos) => {
  return {
    type: UPDATE_CAN_STL_LIST,
    payload: updateCanStlMngList(dtos),
  };
};

/**
 * 조회조건 저장
 */
export const saveSearchFormValues = (searchFormValues) => {
  return {
    type: SAVE_SEARCH_FORM_VALUES,
    payload: {
      ...searchFormValues,
      init: false,
    },
  };
};

/**
 * 일괄결제
 */
export const saveAll = (dtos) => {
  return {
    type: UPDATE_ALL_CAN_STL_LIST,
    payload: update2ndAprvEarlyRepayInfo(dtos),
  };
};

/**
 * 화면 초기화
 */
export const resetState = () => {
  return {
    type: RESET_STATE,
  };
};
