import { SUCCESS, REQUEST, FAILURE } from '../../../common/utils/action-type-utils';
import { fetchBnfCoDtenAllList, fetchBnfCoDtenIstList } from '../../../lib/brc/bnfCoDtenList.api';

// actions
const FETCH_BNFCODTEN_ALL_LIST = 'bnfCoDtenList/FETCH_BNFCODTEN_ALL_LIST';
const FETCH_BNFCODTEN_IST_LIST = 'bnfCoDtenList/FETCH_BNFCODTEN_IST_LIST';

// initial state
const initialState = {
  loading: false,
  success: true,
  message: '',
  list: [],
  totalCount: 0,
};

// reducers
export default function bnfCoDtenList(state = initialState, action) {
  switch (action.type) {
    // 예산수혜업체 유치 - 관리자 조회
    case REQUEST(FETCH_BNFCODTEN_ALL_LIST): // 조회 요청
      return {
        ...state,
        loading: true,
      };
    case SUCCESS(FETCH_BNFCODTEN_ALL_LIST): // 조회 성공
      return {
        ...state,
        success: true,
        loading: false,
        list: action.payload.data?.main,
        totalCount: action.payload?.mainCnt,
      };
    case FAILURE(FETCH_BNFCODTEN_ALL_LIST): // 조회실패
      return {
        ...initialState,
        loading: false,
        success: false,
        message: action.payload.response?.data?.message,
      };
    // 예산수혜업체 유치 - 시/구청 조회
    case REQUEST(FETCH_BNFCODTEN_IST_LIST): // 조회 요청
      return {
        ...state,
        loading: true,
      };
    case SUCCESS(FETCH_BNFCODTEN_IST_LIST): // 조회 성공
      return {
        ...state,
        success: true,
        loading: false,
        list: action.payload.data?.main,
        totalCount: action.payload?.mainCnt,
      };
    case FAILURE(FETCH_BNFCODTEN_IST_LIST): // 조회실패
      return {
        ...initialState,
        loading: false,
        success: false,
        message: action.payload.response?.data?.message,
      };
    default:
      return state;
  }
}

/**
 * 예산수혜업체 유치 - 관리자 조회
 */
export const selectBnfCoDtenAllList = (searchParams) => {
  return {
    type: FETCH_BNFCODTEN_ALL_LIST,
    payload: fetchBnfCoDtenAllList(searchParams),
  };
};

/**
 * 예산수혜업체 유치 - 시/구청 조회
 */
export const selectBnfCoDtenIstList = (searchParams) => {
  return {
    type: FETCH_BNFCODTEN_IST_LIST,
    payload: fetchBnfCoDtenIstList(searchParams),
  };
};
