import dayjs from 'dayjs';
import { SUCCESS, REQUEST, FAILURE } from '../../../common/utils/action-type-utils';
import { fetchCommonComboList } from '../../../lib/cmm/combo.api';
import { fetchAppvPrg, fetchAppvPrgGuse } from '../../../lib/rpt/appvPrg.api';
import { fetchImpMmGnrnRpt, fetchImpMmGnrnRptPrts } from '../../../lib/rpt/RPT0102V01.api';

// actions
const FETCH_IMP_MM_GNRN_RPT_LIST = 'RPT0102V01/FETCH_IMP_MM_GNRN_RPT_LIST';
const FETCH_IMP_MM_GNRN_RPT_PRTS_LIST = 'RPT0102V01/FETCH_IMP_MM_GNRN_RPT_PRTS_LIST';
const RESET_IMP_MM_GNRN_RPT = 'RPT0102V01/RESET_IMP_MM_GNRN_RPT';
// actions - combo
const FETCH_HGRN_IST_INFO_LIST = 'RPT0102V01/FETCH_HGRN_IST_INFO_LIST';
const FETCH_IST_INFO_LIST = 'RPT0102V01/FETCH_IST_INFO_LIST';
const FETCH_DEPT_INFO_LIST = 'RPT0102V01/FETCH_DEPT_INFO_LIST';
const FETCH_ACC_YR_LIST = 'RPT0102V01/FETCH_ACC_YR_LIST';
const FETCH_ACC_DSCD_LIST = 'RPT0102V01/FETCH_ACC_DSCD_LIST';
const SAVE_SEARCH_FORM_VALUES = 'RPT0102V01/SAVE_SEARCH_FORM_VALUES';
// action - 결제진행상태
const FETCH_APPV_PRG = 'RPT0102V01/FETCH_APPV_PRG';
const FETCH_APPV_PRG_GUSE = 'RPT0102V01/FETCH_APPV_PRG_GUSE';

export const initFormValues = {
  cd_ist_cd: '',
  cus_ist_cd: '',
  cus_dept_cd: '',
  arvn_act_dscd: 'all',
  ydcs8a1_t8a02_rep_dscd: '1', // 보고서, 내역
  ydcs8a1_t8a02_dt_mn_dis: '1',
  ydcs8a1_t8a02_inq_bas_cd: '',
  ydcs8a1_t8a02_sigu_dscd: '',
  ydcs8a1_t8a02_acc_dscd: '',
  ydcs8a1_t8a02_ist_no: '',
  ydcs8a1_t8a02_acc_yr: '',
  ydcs8a1_t8a02_act_no: '',
  ydcs8a1_t8a02_quar_or: '',
  ydcs8a1_t8a02_inq_mn: dayjs().format('YYYY-MM'),
  ydcs8a1_t8a02_inq_dt: '20090112', //dayjs().format('YYYYMMDD'),
  ydcs8a1_t8a02_inq_edt: '',
  ydcs8a1_t8a02_brcd: '',
  ydcs8a1_t8a02_spar: '',
};

// initial state
const initialState = {
  loading: false,
  success: true,
  message: '',
  common: {},
  head: {},
  list: [],
  totalCount: 0,
  // 기관구분
  hgrnIstInfoSuccess: false,
  hgrnIstInfoLoading: false,
  hgrnIstInfoList: [],
  // 기관명
  istInfoSuccess: false,
  istInfoLoading: false,
  istInfoList: [],
  // 부서명
  deptInfoSuccess: false,
  deptInfoLoading: false,
  deptInfoList: [],
  // 회계연도
  accYrSuccess: false,
  accYrLoading: false,
  accYrList: [],
  // 세입회계
  accDscdSuccess: false,
  accDscdLoading: false,
  accDscdList: [],
  // 검색조건
  searchFormValues: {
    init: true,
    ...initFormValues,
  },
  // 결제진행상태
  appvPrgSuccess: false,
  appvPrgLoading: false,
  appvPrgList: [],
};

// reducers
export default function RPT0102V01(state = initialState, action) {
  switch (action.type) {
    case SUCCESS(FETCH_IMP_MM_GNRN_RPT_LIST): // 조회 성공
    case SUCCESS(FETCH_IMP_MM_GNRN_RPT_PRTS_LIST): // 조회 성공
      return {
        ...state,
        success: true,
        loading: false,
        list: action.payload.data?.output_data,
        totalCount: action.payload?.data?.output_data_cnt,
        common: action.payload.data?.common,
        head: action.payload.data?.output_head,
      };
    case REQUEST(FETCH_IMP_MM_GNRN_RPT_LIST): // 조회 요청
    case REQUEST(FETCH_IMP_MM_GNRN_RPT_PRTS_LIST): // 조회 요청
      return {
        ...state,
        loading: true,
        success: false,
        message: '',
      };
    case FAILURE(FETCH_IMP_MM_GNRN_RPT_LIST): // 조회실패
    case FAILURE(FETCH_IMP_MM_GNRN_RPT_PRTS_LIST): // 조회실패
      return {
        ...state,
        loading: false,
        success: false,
        message: action.payload.response?.data?.message,
      };
    // 기관구분
    case SUCCESS(FETCH_HGRN_IST_INFO_LIST): // 조회 성공
      return {
        ...state,
        hgrnIstInfoSuccess: true,
        hgrnIstInfoLoading: false,
        hgrnIstInfoList: action.payload.data?.subDto,
      };
    case REQUEST(FETCH_HGRN_IST_INFO_LIST): // 조회 요청
      return {
        ...state,
        hgrnIstInfoLoading: true,
        hgrnIstInfoSuccess: false,
      };
    case FAILURE(FETCH_HGRN_IST_INFO_LIST): // 조회실패
      return {
        ...state,
        hgrnIstInfoLoading: false,
        hgrnIstInfoSuccess: false,
        hgrnIstInfoList: [],
      };
    // 기관명
    case SUCCESS(FETCH_IST_INFO_LIST): // 조회 성공
      return {
        ...state,
        istInfoSuccess: true,
        istInfoLoading: false,
        istInfoList: action.payload.data?.subDto,
      };
    case REQUEST(FETCH_IST_INFO_LIST): // 조회 요청
      return {
        ...state,
        istInfoLoading: true,
        istInfoSuccess: false,
      };
    case FAILURE(FETCH_IST_INFO_LIST): // 조회실패
      return {
        ...state,
        istInfoLoading: false,
        istInfoSuccess: false,
        istInfoList: [],
      };
    // 부서명
    case SUCCESS(FETCH_DEPT_INFO_LIST): // 조회 성공
      return {
        ...state,
        deptInfoSuccess: true,
        deptInfoLoading: false,
        deptInfoList: action.payload.data?.subDto,
      };
    case REQUEST(FETCH_DEPT_INFO_LIST): // 조회 요청
      return {
        ...state,
        deptInfoLoading: true,
        deptInfoSuccess: false,
      };
    case FAILURE(FETCH_DEPT_INFO_LIST): // 조회실패
      return {
        ...state,
        deptInfoSuccess: false,
        deptInfoLoading: false,
        deptInfoList: [],
      };
    // 회계연도
    case SUCCESS(FETCH_ACC_YR_LIST): // 조회 성공
      return {
        ...state,
        accYrSuccess: true,
        accYrLoading: false,
        accYrList: action.payload.data?.subDto,
      };
    case REQUEST(FETCH_ACC_YR_LIST): // 조회 요청
      return {
        ...state,
        accYrLoading: true,
        accYrSuccess: false,
      };
    case FAILURE(FETCH_ACC_YR_LIST): // 조회실패
      return {
        ...state,
        accYrLoading: false,
        accYrSuccess: false,
        accYrList: [],
      };
    // 세입회계
    case SUCCESS(FETCH_ACC_DSCD_LIST): // 조회 성공
      return {
        ...state,
        accDscdSuccess: true,
        accDscdLoading: false,
        accDscdList: action.payload.data?.subDto,
      };
    case REQUEST(FETCH_ACC_DSCD_LIST): // 조회 요청
      return {
        ...state,
        accDscdLoading: true,
        accDscdSuccess: false,
      };
    case FAILURE(FETCH_ACC_DSCD_LIST): // 조회실패
      return {
        ...state,
        accDscdLoading: false,
        accDscdSuccess: false,
        accDscdList: [],
      };
    case SAVE_SEARCH_FORM_VALUES:
      return {
        ...state,
        // 기관구분
        hgrnIstInfoSuccess: false,
        hgrnIstInfoLoading: false,
        // 기관명
        istInfoSuccess: false,
        istInfoLoading: false,
        // 부서명
        deptInfoSuccess: false,
        deptInfoLoading: false,
        // 회계연도
        accYrSuccess: false,
        accYrLoading: false,
        // 세입회계
        accDscdSuccess: false,
        accDscdLoading: false,
        searchFormValues: action.payload,
      };
    case RESET_IMP_MM_GNRN_RPT:
      return initialState;
    // 결제상태
    case SUCCESS(FETCH_APPV_PRG): // 조회 성공
    case SUCCESS(FETCH_APPV_PRG_GUSE): // 조회 성공
      return {
        ...state,
        appvPrgSuccess: true,
        appvPrgLoading: false,
        appvPrgList: action.payload.data?.main_find,
      };
    case REQUEST(FETCH_APPV_PRG): // 조회 요청
    case REQUEST(FETCH_APPV_PRG_GUSE): // 조회 요청
      return {
        ...state,
        appvPrgSuccess: false,
        appvPrgLoading: true,
        message: '',
        appvPrgList: [],
      };
    case FAILURE(FETCH_APPV_PRG): // 조회실패
    case FAILURE(FETCH_APPV_PRG_GUSE): // 조회실패
      return {
        ...initialState,
        appvPrgLoading: false,
        appvPrgSuccess: false,
        // message: action.payload.response?.data?.message,
      };
    default:
      return state;
  }
}

/**
 * 수입금월중총괄보고서 보고서 조회
 */
export const selectImpMmGnrnRptList = (searchParams) => {
  return {
    type: FETCH_IMP_MM_GNRN_RPT_LIST,
    payload: fetchImpMmGnrnRpt(searchParams),
  };
};

/**
 * 수입금월중총괄보고서 내역 조회
 */
export const selectImpMmGnrnRptPrtsList = (searchParams) => {
  return {
    type: FETCH_IMP_MM_GNRN_RPT_PRTS_LIST,
    payload: fetchImpMmGnrnRptPrts(searchParams),
  };
};

/**
 * 기관구분 콤보 조회
 */
export const selectHgrnIstInfoComboList = (searchParams) => {
  return {
    type: FETCH_HGRN_IST_INFO_LIST,
    payload: fetchCommonComboList({ slt_box_no: '072', ...searchParams }),
  };
};

/**
 * 기관명 콤보 조회
 */
export const selectIstInfoComboList = (searchParams) => {
  return {
    type: FETCH_IST_INFO_LIST,
    payload: fetchCommonComboList({ slt_box_no: '074', ...searchParams }),
  };
};

/**
 * 부서명 콤보 조회
 */
export const selectDeptInfoComboList = (searchParams) => {
  return {
    type: FETCH_DEPT_INFO_LIST,
    payload: fetchCommonComboList({ slt_box_no: '039', ...searchParams }),
  };
};

/**
 * 회계연도 콤보 조회
 */
export const selectAccYrComboList = (searchParams) => {
  return {
    type: FETCH_ACC_YR_LIST,
    payload: fetchCommonComboList({ slt_box_no: '025', ...searchParams }),
  };
};

/**
 * 세입회계 콤보 조회
 */
export const selectAccDscdComboList = (searchParams) => {
  return {
    type: FETCH_ACC_DSCD_LIST,
    payload: fetchCommonComboList({ slt_box_no: '016', ...searchParams }),
  };
};

/**
 * 조회조건 저장
 */
export const saveSearchFormValues = (searchFormValues) => {
  return {
    type: SAVE_SEARCH_FORM_VALUES,
    payload: {
      ...searchFormValues,
      init: false,
    },
  };
};

/**
 * 초기화
 */
export const resetImpMmGnrnRpt = () => {
  return {
    type: RESET_IMP_MM_GNRN_RPT,
  };
};

/**
 * 보고서 결재진행 상태 조회
 * @param {object} searchParams
 * @returns
 */
export const selectAppvPrg = (searchParams) => {
  return {
    type: FETCH_APPV_PRG,
    payload: fetchAppvPrg(searchParams),
  };
};

/**
 * 보고서 결재진행 상태 조회 구세, 보고서 조회시
 * @param {object} searchParams
 * @returns
 */
export const selectAppvPrgGuse = (searchParams) => {
  return {
    type: FETCH_APPV_PRG_GUSE,
    payload: fetchAppvPrgGuse(searchParams),
  };
};
