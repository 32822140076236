import React, { forwardRef, memo, useEffect, useImperativeHandle, useMemo, useState } from 'react';

import styled, { css } from 'styled-components';
import WOButton from '../common/WOButton';
import WOIcon from '../common/WOIcon';

/**
 * 리포트 뷰어
 */
const ClipReportViewer = forwardRef(function ClipReportViewer(
  {
    templateName = 'TXGW/TXGW761Main',
    height = '500px',
    jsonData = {},
    reportParams = {},
    onReportLoaded,
    displayStateMent,
  },
  ref
) {
  const [loading, setLoading] = useState({
    jquery: true,
    clip: true,
    userConfig: true,
  });

  const [isExpanded, setIsExpanded] = useState(false);

  const cssList = useMemo(
    () => ['/assets/report/css/clipreport5.css', '/assets/report/css/UserConfig5.css', '/assets/report/css/font.css'],
    []
  );

  const scriptList = useMemo(
    () => [
      '/assets/report/js/jquery-1.11.1.js',
      '/assets/report/js/clipreport5.js',
      '/assets/report/js/UserConfig5.js',
    ],
    []
  );

  const handleLoad = (src) => () => {
    if (src.includes('jquery-1.11.1')) {
      setLoading((prev) => ({ ...prev, jquery: false }));
    } else if (src.includes('clipreport5')) {
      setLoading((prev) => ({ ...prev, clip: false }));
    } else {
      setLoading((prev) => ({ ...prev, userConfig: false }));
    }
  };

  const objectToParams = (obj) => {
    const params = [];
    for (let key in obj) {
      params.push(`${key}=${encodeURI(obj[key])}`);
    }
    return params.join('&');
  };

  useEffect(() => {
    const escKeyModalClose = (e) => {
      if (e.key === 'Escape') {
        setIsExpanded(false);
      }
    };
    window.addEventListener('keydown', escKeyModalClose);
    return () => window.removeEventListener('keydown', escKeyModalClose);
  }, [setIsExpanded]);

  // javascript imports
  useEffect(() => {
    let javascriptList = [];
    const body = document.body;
    scriptList?.forEach((src) => {
      const script = document.createElement('script');
      script.type = 'text/javascript';
      script.async = true;
      script.src = src;
      script.addEventListener('load', handleLoad(src));
      body.appendChild(script);
      javascriptList.push(script);
    });
    return () => {
      if (javascriptList && javascriptList.length > 0) {
        javascriptList.forEach((script) => body.removeChild(script));
      }
    };
  }, [scriptList]);

  // css imports
  useEffect(() => {
    let linkList = [];
    const head = document.head;
    cssList?.forEach((href) => {
      const link = document.createElement('link');
      link.type = 'text/css';
      link.rel = 'stylesheet';
      link.href = href;
      head.appendChild(link);
      linkList.push(link);
    });
    return () => {
      if (linkList && linkList.length > 0) {
        linkList.forEach((link) => head.removeChild(link));
      }
    };
  }, [cssList]);

  useEffect(() => {
    if (!loading.clip && !loading.jquery && !loading.userConfig) {
      let formData = `template=${templateName}&jsonData=${encodeURI(
        JSON.stringify(jsonData).replaceAll('%', '^^').replaceAll('&', '@@')
      )}`;
      const ozParams = objectToParams({ bgcolor: process.env.REACT_APP_REPORT_BG_COLOR, ...reportParams });
      if (ozParams.length > 0) {
        formData = formData + `&${ozParams}`;
      }

      const report = window.createJSPReport?.(
        `${process.env.REACT_APP_REPORT_SERVER_URL}/rpt/report_server.jsp`,
        `${process.env.REACT_APP_REPORT_SERVER_URL}/rpt/createJSPReport.jsp`,
        formData,
        document.getElementById('reportDiv')
      );
      // X 버튼 제거
      report?.setStyle('close_button', 'display:none');
      report?.setEndReportEvent(onReportLoaded);

      // save 버튼 숨기기
      if (reportParams?.noSave) {
        report?.setStyle('save_button', 'display:none');
      }
      //커스텀버튼으로 확대축소
      const arr = ['75%', '100%', '150%', '200%'];
      report.setCustomButtonInfo(
        'report_ratio_plus_button',
        function () {
          //var ra = document.getElementById("report_zoom_select");
          const ra =
            document.querySelector('.report_zoom_select').options[
              document.querySelector('.report_zoom_select').selectedIndex
            ].value;
          if (ra === '0.75') {
            report.callChangeRatioCombo(arr[1]);
          } else if (ra === '1') {
            report.callChangeRatioCombo(arr[2]);
          } else if (ra === '1.5') {
            report.callChangeRatioCombo(arr[3]);
          }
        },
        false,
        '확대'
      );
      report.setCustomButtonInfo(
        'report_ratio_minus_button',
        function () {
          const ra =
            document.querySelector('.report_zoom_select').options[
              document.querySelector('.report_zoom_select').selectedIndex
            ].value;
          if (ra === '2') {
            report.callChangeRatioCombo(arr[2]);
          } else if (ra === '1.5') {
            report.callChangeRatioCombo(arr[1]);
          } else if (ra === '1') {
            report.callChangeRatioCombo(arr[0]);
          }
        },
        false,
        '축소'
      );
      // 리포트 인쇄버튼 클릭시 바로 PDF인쇄 설정창 오픈
      // report.setCrossDomainPDFPrint(true);
      // report.setStartPrintButtonEvent(function () {
      //   report.callPDFPrint();
      // });
      // 뷰어 메뉴바 커스텀모드
      report.setCustomDesign(true);
      // 리포트 뷰어 실행
      report?.view();
    }
  }, [loading, templateName, jsonData, reportParams, onReportLoaded]);

  useImperativeHandle(ref, () => {
    return {
      /**
       * report viewer 실행
       * @param {object} jsonData
       */
      showReport(jsonData) {
        let formData = `template=${templateName}&jsonData=${encodeURI(
          JSON.stringify(jsonData).replaceAll('%', '^^').replaceAll('&', '@@')
        )}`;
        const params = [];
        for (let key in reportParams) {
          params.push(`${key}=${encodeURI(reportParams[key])}`);
        }
        const ozParams = params.join('&');
        if (ozParams.length > 0) {
          formData = formData + `&${ozParams}`;
        }

        const report = window.createJSPReport?.(
          `${process.env.REACT_APP_REPORT_SERVER_URL}/rpt/report_server.jsp`,
          `${process.env.REACT_APP_REPORT_SERVER_URL}/rpt/createJSPReport.jsp`,
          formData,
          document.getElementById('reportDiv')
        );
        // X 버튼 제거
        report?.setStyle('close_button', 'display:none');
        // save 버튼 숨기기
        if (reportParams?.noSave) {
          report?.setStyle('save_button', 'display:none');
        }
        //커스텀버튼으로 확대축소
        const arr = ['75%', '100%', '150%', '200%'];
        report.setCustomButtonInfo(
          'report_ratio_plus_button',
          function () {
            //var ra = document.getElementById("report_zoom_select");
            const ra =
              document.querySelector('.report_zoom_select').options[
                document.querySelector('.report_zoom_select').selectedIndex
              ].value;
            if (ra === '0.75') {
              report.callChangeRatioCombo(arr[1]);
            } else if (ra === '1') {
              report.callChangeRatioCombo(arr[2]);
            } else if (ra === '1.5') {
              report.callChangeRatioCombo(arr[3]);
            }
          },
          false,
          '확대'
        );
        report.setCustomButtonInfo(
          'report_ratio_minus_button',
          function () {
            const ra =
              document.querySelector('.report_zoom_select').options[
                document.querySelector('.report_zoom_select').selectedIndex
              ].value;
            if (ra === '2') {
              report.callChangeRatioCombo(arr[2]);
            } else if (ra === '1.5') {
              report.callChangeRatioCombo(arr[1]);
            } else if (ra === '1') {
              report.callChangeRatioCombo(arr[0]);
            }
          },
          false,
          '축소'
        );
        // 리포트 인쇄버튼 클릭시 바로 PDF인쇄 설정창 오픈
        // report.setCrossDomainPDFPrint(true);
        // report.setStartPrintButtonEvent(function () {
        //   report.callPDFPrint();
        // });
        // 뷰어 메뉴바 커스텀모드
        report.setCustomDesign(true);
        // 리포트 뷰어 실행
        report?.view();
      },
    };
  });

  return (
    <>
      {/* <div id="targetDiv1" style="position:absolute;top:50px;left:5px;right:5px;bottom:5px;"> */}
      <ReportWrap className={isExpanded ? 'expanded-table' : ''}>
        <div id="reportDiv" style={{ position: 'relative', height: height }}>
          <span style={{ visibility: 'hidden', fontFamily: '나눔고딕' }}>.</span>
          <span style={{ visibility: 'hidden', fontFamily: 'NanumGothic' }}>.</span>
          <span style={{ visibility: 'hidden', fontFamily: '맑은 고딕' }}>.</span>
          <span style={{ visibility: 'hidden', fontFamily: 'Malgun Gothic' }}>.</span>
        </div>

        <ButtonWrap className="btn-wrap" $displayStateMent={displayStateMent}>
          <WOButton
            variant="expand"
            startIcon={<WOIcon icon={isExpanded ? 'zoom-out-wh' : 'zoom-in-wh'} width={16} height={16} />}
            onClick={() => setIsExpanded(!isExpanded)}>
            {isExpanded ? '축소하기' : '확대하기'}
          </WOButton>
        </ButtonWrap>
      </ReportWrap>
    </>
  );
});

export default memo(ClipReportViewer);

const ReportWrap = styled.div`
  height: calc(100% - 35px);

  ${(props) =>
    props.$displayStateMent &&
    css`
      #reportDiv {
        height: calc(100% + 36px);
      }
    `}

  &.expanded-table {
    position: fixed;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    width: 100%;
    height: 100%;
    z-index: 9999999;
    background: #fff;
    padding: 5px 10px 46px;

    #reportDiv {
      height: 100% !important;
    }
  }
`;

const ButtonWrap = styled.div`
  margin-top: 11px;
  display: flex;
  align-items: center;
  justify-content: flex-end;
  gap: 8px;
  background: #fff;

  ${(props) =>
    props.$displayStateMent &&
    css`
      position: absolute;
      right: 20px;
      bottom: 6px;
      margin-top: 0;
      background: none;

      > .btn {
        background: #fff;
      }
    `}
`;
