import { SUCCESS, REQUEST, FAILURE } from '../../../common/utils/action-type-utils';
import { fetchWeakCopRgsList, insertCadStlPrtsList } from '../../../lib/cad/weakCopRgs.api';

// actions
const FETCH_WEAK_COP_RGS_LIST = 'weakCopRgs/FETCH_WEAK_COP_RGS_LIST';
const INSERT_WEAK_COP_RGS_LIST = 'weakCopRgs/INSERT_WEAK_COP_RGS_LIST';
const SAVE_SEARCH_FORM_VALUES = 'weakCopRgs/SAVE_SEARCH_FORM_VALUES';
const RESET_STATE = 'weakCopRgs/RESET_STATE';

export const initFormValues = {
  biz_no: '',
};

// initial state
const initialState = {
  loading: false,
  success: false,
  updateSuccess: false,
  message: '',
  list: [],
  totalCount: 0,
  // 검색조건
  searchFormValues: {
    init: true,
    ...initFormValues,
  },
};

// reducers
export default function weakCopRgs(state = initialState, action) {
  switch (action.type) {
    case SUCCESS(FETCH_WEAK_COP_RGS_LIST): // 조회 성공
      return {
        ...state,
        success: true,
        loading: false,
        list: action.payload.data?.main,
        totalCount: action.payload?.main_cnt,
      };
    case REQUEST(FETCH_WEAK_COP_RGS_LIST): // 조회 요청
      return {
        ...state,
        loading: true,
        success: false,
        message: '',
      };
    case FAILURE(FETCH_WEAK_COP_RGS_LIST): // 조회실패
      return {
        ...initialState,
        loading: false,
        success: false,
        message: action.payload.response?.data?.message,
      };
    case SUCCESS(INSERT_WEAK_COP_RGS_LIST): // 수정 성공
      return {
        ...state,
        updateSuccess: true,
        updating: false,
      };
    case REQUEST(INSERT_WEAK_COP_RGS_LIST): // 수정 요청
      return {
        ...state,
        updating: false,
        updateSuccess: false,
        message: null,
      };
    case FAILURE(INSERT_WEAK_COP_RGS_LIST): // 수정 실패
      return {
        ...initialState,
        updating: false,
        updateSuccess: false,
        message: action.payload.response?.da3ta?.message,
      };
    case SAVE_SEARCH_FORM_VALUES:
      return {
        ...state,
        // 콤보 - 기관
        istInfoSuccess: false,
        istInfoLoading: false,
        // 콤보 - 부서명
        deptCdSuccess: false,
        deptCdLoading: false,
        // 콤보 - 사업자 번호
        bizNoSuccess: false,
        bizNoLoading: false,
        // 콤보 - 계좌번호
        mbrNoSuccess: false,
        mbrNoLoading: false,
        // 콤보 - 카드번호
        cdNoSuccess: false,
        cdNoLoading: false,
        // 콤보 - 업종
        bzctgCdSuccess: false,
        bzctgCdLoading: false,
        searchFormValues: action.payload,
      };
    case RESET_STATE:
      return initialState;
    default:
      return state;
  }
}

/**
 * 법인카드 - 부서별 사용현황
 */
export const selectListWeakCopRgs = (searchParams) => {
  return {
    type: FETCH_WEAK_COP_RGS_LIST,
    payload: fetchWeakCopRgsList(searchParams),
  };
};

/**
 * 법인카드 - 부서별 사용현황 다건등록
 */
export const insertListWeakCopRgs = (dtos) => {
  return {
    type: INSERT_WEAK_COP_RGS_LIST,
    payload: insertCadStlPrtsList(dtos),
  };
};

/**
 * 조회조건 저장
 */
export const saveSearchFormValues = (searchFormValues) => {
  return {
    type: SAVE_SEARCH_FORM_VALUES,
    payload: {
      ...searchFormValues,
      init: false,
    },
  };
};

/**
 * 법인카드 상태초기화
 */
export const resetWeakCopRgs = () => {
  return {
    type: RESET_STATE,
  };
};
