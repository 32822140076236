import instance from '../../common/config/api';

/**
 * 결재
 * @param {object} params
 * @returns
 */
export const updateRptApv = (dtos) =>
  instance.post(`${process.env.REACT_APP_SERVER_URL}/scq/scq/rpt/0001/updateRptApv`, {
    subDto: dtos,
    subDtoCnt: dtos.length,
  });

/**
 * 보고서 관리 내역 조회
 * @param {object} params
 * @returns
 */
export const fetchApvMng = (params) =>
  instance.post(`${process.env.REACT_APP_SERVER_URL}/scq/scq/rpt/0001/selectApvMng`, params);

/**
 * 보고서 관리 내역 조회(페이징처리용)
 * @param {object} params
 * @returns
 */
export const fetchApvMngPage = (params) =>
  instance.post(`${process.env.REACT_APP_SERVER_URL}/scq/scq/rpt/0001/selectApvMngPage`, params);

/**
 * 승인관리 pk 찾기
 * @param {object} params
 * @returns
 */
export const fetchApvMngPk = () => instance.post(`${process.env.REACT_APP_SERVER_URL}/scq/scq/rpt/0001/selectApvMngPk`);

/**
 * 연초보정 버튼 활성화여부
 */
export const fetchYearBjYn = () =>
  instance.post(`${process.env.REACT_APP_SERVER_URL}/scq/scq/rpt/0004/selectYearBjYn `);
