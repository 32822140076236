import { SUCCESS, REQUEST, FAILURE } from '../../../common/utils/action-type-utils';
import { fetchSitePrsnMngByUserTypeList, updateSitePrsnMngByUserType } from '../../../lib/sys/SYS0308M01.api';

// actions
const FETCH_SITE_PRSN_MNG_BY_USER_TYPE_LIST = 'SYS0308M01/FETCH_SITE_PRSN_MNG_BY_USER_TYPE_LIST';
const UPDATE_SITE_PRSN_MNG_BY_USER_TYPE = 'SYS0308M01/UPDATE_SITE_PRSN_MNG_BY_USER_TYPE';

// initial state
const initialState = {
  loading: false,
  success: true,
  message: '',
  list: [],
  totalCount: 0,
  updating: false,
  updateSuccess: false,
  resultCnt: -1,
};

// reducers
export default function SYS0308M01(state = initialState, action) {
  switch (action.type) {
    case SUCCESS(FETCH_SITE_PRSN_MNG_BY_USER_TYPE_LIST): // 조회 성공
      return {
        ...state,
        success: true,
        loading: false,
        list: action.payload.data?.main,
        totalCount: action.payload?.data?.mainCnt,
        updateSuccess: false,
      };
    case REQUEST(FETCH_SITE_PRSN_MNG_BY_USER_TYPE_LIST): // 조회 요청
      return {
        ...state,
        message: '',
        loading: true,
        success: false,
        updateSuccess: false,
      };
    case FAILURE(FETCH_SITE_PRSN_MNG_BY_USER_TYPE_LIST): // 조회실패
      return {
        ...initialState,
        loading: false,
        success: false,
        message: action.payload.response?.data?.message,
        updateSuccess: false,
      };
    case SUCCESS(UPDATE_SITE_PRSN_MNG_BY_USER_TYPE): // 저장 성공
      return {
        ...state,
        updateSuccess: true,
        updating: false,
        resultCnt: action.payload.data.resultCnt,
      };
    case REQUEST(UPDATE_SITE_PRSN_MNG_BY_USER_TYPE): // 저장 요청
      return {
        ...state,
        message: '',
        updating: true,
        updateSuccess: false,
      };
    case FAILURE(UPDATE_SITE_PRSN_MNG_BY_USER_TYPE): // 저장 실패
      return {
        ...initialState,
        updating: false,
        updateSuccess: false,
        resultCnt: -1,
        message: action.payload.response?.data?.message,
      };
    default:
      return state;
  }
}

/**
 * 사용자 유형별 사이트 권한 조회
 */
export const selectSitePrsnMngByUserTypeList = (searchParams) => {
  return {
    type: FETCH_SITE_PRSN_MNG_BY_USER_TYPE_LIST,
    payload: fetchSitePrsnMngByUserTypeList(searchParams),
  };
};

/**
 * 사용자 유형별 사이트 권한 등록
 */
export const saveSitePrsnMngByUserType = (dtos) => {
  return {
    type: UPDATE_SITE_PRSN_MNG_BY_USER_TYPE,
    payload: updateSitePrsnMngByUserType(dtos),
  };
};
