import dayjs from 'dayjs';
import { SUCCESS, REQUEST, FAILURE } from '../../../common/utils/action-type-utils';
import { fetchBuyCfmReceiptList, fetchTheDayCancleReqList, updateThdDayCancel } from '../../../lib/brc/brcTdyCan';

// actions
const FETCH_TDY_CAN_LIST = 'brcTdyCan/FETCH_TDY_CAN_LIST';
const UPDATE_TDY_CAN_LIST = 'brcTdyCan/UPDATE_TDY_CAN_LIST';
const FETCH_THE_DAY_CANCLE_REQ_LIST = 'brcTdyCan/FETCH_THE_DAY_CANCLE_REQ_LIST';
const SAVE_SEARCH_FORM_VALUES = 'brcTdyCan/SAVE_SEARCH_FORM_VALUES';
const RESET_TDY_CAN_STATE = 'brcTdyCan/RESET_TDY_CAN_STATE';

export const initFormValues = {
  bndIssuNo: '',
  carNoTxt: '',
  collIscd: '',
  trnDt: dayjs().format('YYYY-MM-DD'),
  inqDis: '2',
};

// initial state
const initialState = {
  loading: false,
  updating: false,
  success: false,
  updateSuccess: false,
  message: '',
  list: [],
  loadingBySub: false,
  successBySub: false,
  subList: [],
  totalCount: 0,
  // 검색조건
  searchFormValues: {
    init: true,
    ...initFormValues,
  },
};

// reducers
export default function brcTdyCan(state = initialState, action) {
  switch (action.type) {
    case SUCCESS(FETCH_TDY_CAN_LIST): // 조회 성공
      return {
        ...state,
        success: true,
        loading: false,
        list: action.payload.data?.subDto,
        totalCount: action.payload?.subDtoCnt,
      };
    case REQUEST(FETCH_TDY_CAN_LIST): // 조회 요청
      return {
        ...state,
        success: false,
        loading: true,
        message: '',
      };
    case FAILURE(FETCH_TDY_CAN_LIST): // 조회실패
      return {
        ...initialState,
        loading: false,
        success: false,
        list: [],
        message: action.payload.response?.data?.message || action.payload.message,
      };
    case SUCCESS(UPDATE_TDY_CAN_LIST): // 성공
      return {
        ...state,
        updateSuccess: true,
        updating: false,
      };
    case REQUEST(UPDATE_TDY_CAN_LIST): // 요청
      return {
        ...state,
        updating: true,
        updateSuccess: false,
        message: null,
      };
    case FAILURE(UPDATE_TDY_CAN_LIST): // 실패
      return {
        ...initialState,
        updating: false,
        updateSuccess: false,
        message: action.payload.response?.data?.message,
      };
    case SUCCESS(FETCH_THE_DAY_CANCLE_REQ_LIST): // 조회 성공
      return {
        ...state,
        successBySub: true,
        loadingBySub: false,
        subList: action.payload.data?.main_today,
        // totalCount: action.payload?.subDtoCnt,
      };
    case REQUEST(FETCH_THE_DAY_CANCLE_REQ_LIST): // 조회 요청
      return {
        ...state,
        success: false,
        loadingBySub: true,
      };
    case FAILURE(FETCH_THE_DAY_CANCLE_REQ_LIST): // 조회실패
      return {
        ...initialState,
        loadingBySub: false,
        successBySub: false,
        subList: [],
        message: action.payload.response?.data?.message || action.payload.message,
      };
    case SAVE_SEARCH_FORM_VALUES:
      return {
        ...state,
        loading: false,
        updating: false,
        success: false,
        updateSuccess: false,
        message: '',
        loadingBySub: false,
        successBySub: false,
        searchFormValues: action.payload,
      };
    case RESET_TDY_CAN_STATE:
      return initialState;
    default:
      return state;
  }
}
/**
 * 공채 - 당일취소내역 조회
 */
export const selectBuyCfmReceiptList = (searchParams) => {
  return {
    type: FETCH_TDY_CAN_LIST,
    payload: fetchBuyCfmReceiptList(searchParams),
  };
};

/**
 * 공채 - 당일자 취소신청 등록
 */
export const updateTdyCan = (dto) => {
  return {
    type: UPDATE_TDY_CAN_LIST,
    payload: updateThdDayCancel(dto),
  };
};

/**
 * 조회조건 저장
 */
export const saveSearchFormValues = (searchFormValues) => {
  return {
    type: SAVE_SEARCH_FORM_VALUES,
    payload: {
      ...searchFormValues,
      init: false,
    },
  };
};

/**
 * 화면 초기화
 */
export const resetState = () => {
  return {
    type: RESET_TDY_CAN_STATE,
  };
};

/**
 * 공채 - 공채 당일자 취소 신청 내역 조회
 */
export const selectTheDayCancleReqList = (searchParams) => {
  return {
    type: FETCH_THE_DAY_CANCLE_REQ_LIST,
    payload: fetchTheDayCancleReqList(searchParams),
  };
};
