import instance from '../../common/config/api';

/**
 * 금고영업점 - 공금업무 제안광장 (조회)
 * @param {object} params
 * @returns
 */

export const fetchProposalList = (params) =>
  instance.post(`${process.env.REACT_APP_SERVER_URL}/scq/scq/brc/0004/selectProposalList`, params);

/**
 * 금고영업점 - 공금업무 제안광장 (등록, 수정)
 * @param {object} params
 * @returns
 */

export const insertProposalList = (dto) =>
  instance.post(`${process.env.REACT_APP_SERVER_URL}/scq/scq/brc/0004/insertProposal`, {
    subDto: [dto],
  });

/**
 * 금고영업점 - 공금업무 제안광장 pk채번
 */
export const fetchProposalPkNmgt = () =>
  instance.post(`${process.env.REACT_APP_SERVER_URL}/scq/scq/brc/0004/selectProposalPkNmgt`);
