import { SUCCESS, REQUEST, FAILURE } from '../../../common/utils/action-type-utils';
import { fetchSpcMenuAuthList, saveSpcMenuAuthList } from '../../../lib/sys/SYS0311M01.api';

// actions
const FETCH_SPC_MENU_AUTH_LIST = 'SYS0311M01/FETCH_SPC_MENU_AUTH_LIST';
const SAVE_SPC_MENU_AUTH_LIST = 'SYS0311M01/SAVE_SPC_MENU_AUTH_LIST';

// initial state
const initialState = {
  loading: false,
  success: true,
  message: '',
  list: [],
  totalCount: 0,
  updateSuccess: false,
  resultCnt: -1,
};

// reducers
export default function SYS0311M01(state = initialState, action) {
  switch (action.type) {
    case SUCCESS(FETCH_SPC_MENU_AUTH_LIST): // 조회 성공
      return {
        ...state,
        success: true,
        loading: false,
        list: action.payload.data?.main,
        totalCount: action.payload?.data?.mainCnt,
        updateSuccess: false,
      };
    case REQUEST(FETCH_SPC_MENU_AUTH_LIST): // 조회 요청
      return {
        ...state,
        message: '',
        loading: true,
        success: false,
        updateSuccess: false,
      };
    case FAILURE(FETCH_SPC_MENU_AUTH_LIST): // 조회실패
      return {
        ...initialState,
        loading: false,
        success: false,
        message: action.payload.response?.data?.message,
        updateSuccess: false,
      };
    case SUCCESS(SAVE_SPC_MENU_AUTH_LIST): // 저장 성공
      return {
        ...state,
        updateSuccess: true,
        loading: false,
        resultCnt: action.payload.data.resultCnt,
      };
    case REQUEST(SAVE_SPC_MENU_AUTH_LIST): // 저장 요청
      return {
        ...state,
        message: '',
        loading: true,
        updateSuccess: false,
      };
    case FAILURE(SAVE_SPC_MENU_AUTH_LIST): // 저장 실패
      return {
        ...initialState,
        loading: false,
        updateSuccess: false,
        resultCnt: -1,
        message: action.payload.response?.data?.message,
      };
    default:
      return state;
  }
}

/**
 * 특화 메뉴 조회
 */
export const selectSpcMenuAuthList = (searchParams) => {
  return {
    type: FETCH_SPC_MENU_AUTH_LIST,
    payload: fetchSpcMenuAuthList(searchParams),
  };
};

/**
 * 특화 메뉴 갱신
 */
export const saveListSpcMenuAuth = (mainDtos) => {
  return {
    type: SAVE_SPC_MENU_AUTH_LIST,
    payload: saveSpcMenuAuthList(mainDtos, []),
  };
};
