import dayjs from 'dayjs';
import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import styled from 'styled-components';
import { selectNoticeForMain } from '../../redux/modules/cmm/notice.reducer';
import Hamburger from './Hamburger';
import MenuList from './MenuList';
import Notice from './Notice';

const MainHeader = () => {
  const dispatch = useDispatch();
  // logic
  const [noticeText, setNoticeText] = useState();
  const [isMenuOpen, setIsMenuOpen] = useState(false);
  const handleMenuOpen = () => {
    setIsMenuOpen(true);
  };

  const { loading, list, success, message } = useSelector((state) => state.noticeState);
  useEffect(() => {
    if (list.length > 0) {
      setNoticeText(list?.at(0)?.anc_titl);
    }
  }, [list]);

  useEffect(() => {
    // 공지사항 조회
    dispatch(
      selectNoticeForMain({
        anc_end_dt: dayjs().format('YYYYMMDD'),
        anc_sta_dt: dayjs().add(-1, 'year').format('YYYYMMDD'),
        sch_cnt: '4',
      })
    );
  }, [dispatch]);

  // view
  return (
    <>
      <HeaderWrap className="header">
        {/* START: 공지사항 */}
        <Notice text={noticeText} noticeList={list} />
        {/* END: 공지사항 */}
        {/* START: 햄버거 메뉴 */}
        <HamburgerWrap>
          <Hamburger color="black" onClick={handleMenuOpen} />
        </HamburgerWrap>

        {/* END: 햄버거 메뉴 */}
      </HeaderWrap>
      <MenuList isOpen={isMenuOpen} isClose={() => setIsMenuOpen(false)} />
    </>
  );
};

export default MainHeader;

export const HeaderWrap = styled.header`
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  display: flex;
  justify-content: end;
  align-items: flex-start;
  height: 60px;
  z-index: 800;
`;

const HamburgerWrap = styled.div`
  margin: 18px 18px 0 44px;
`;
