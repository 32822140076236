import { SUCCESS, REQUEST, FAILURE } from '../../../common/utils/action-type-utils';
import { fetchZpayApdtAndExuRcncPrts } from '../../../lib/fxp/FXP0004.api';

// actions
const FETCH_ZPAY_APDT_AND_EXU_RCNC_PRTS = 'FXP0403V01/FETCH_ZPAY_APDT_AND_EXU_RCNC_PRTS';

// initial state
const initialState = {
  loading: false,
  success: false,
  message: '',
  list: [],
  totalCount: 0,
};

// reducers
export default function FXP0403V01(state = initialState, action) {
  switch (action.type) {
    case SUCCESS(FETCH_ZPAY_APDT_AND_EXU_RCNC_PRTS): // 조회 성공
      return {
        ...state,
        success: true,
        loading: false,
        list: action.payload.data?.output_data,
        totalCount: action.payload.data?.output_data_cnt,
      };
    case REQUEST(FETCH_ZPAY_APDT_AND_EXU_RCNC_PRTS): // 조회 요청
      return {
        ...state,
        loading: true,
        success: false,
        message: '',
      };
    case FAILURE(FETCH_ZPAY_APDT_AND_EXU_RCNC_PRTS): // 조회실패
      return {
        ...initialState,
        loading: false,
        success: false,
        message: action.payload.response?.data?.message,
      };
    default:
      return state;
  }
}

/**
 * 제로페이 승인,정산대사현황
 */
export const selectZpayApdtAndExuRcncPrts = (searchParams) => {
  return {
    type: FETCH_ZPAY_APDT_AND_EXU_RCNC_PRTS,
    payload: fetchZpayApdtAndExuRcncPrts(searchParams),
  };
};
