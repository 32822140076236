import { SUCCESS, REQUEST, FAILURE } from '../../../common/utils/action-type-utils';
import { fetchEtcFdSumrPrtsaList } from './../../../lib/etc/etc0406v01.api';
import dayjs from 'dayjs';
import { fetchCommonComboList } from '../../../lib/cmm/combo.api';

// actions
const FETCH_FDSUMRPRTSA = 'etcFdSumrPrtsa/FETCH_FDSUMRPRTSA';
//기관
const FETCH_IST_INFO_LIST = 'etcPrcPrts/FETCH_IST_INFO_LIST';
const RESET_STATE = 'etcPrcPrts/RESET_STATE';
const SAVE_SEARCH_FORM_VALUES = 'etcPrcPrts/SAVE_SEARCH_FORM_VALUES';

export const initFormValues = {
  ydcs8a1_t8a01_inq_sdt: dayjs().add(-4, 'day').format('YYYY-MM-DD'), // 조회기간
  ydcs8a1_t8a01_inq_edt: dayjs().add(-3, 'day').format('YYYY-MM-DD'), // 조회기간
};

// initial state
const initialState = {
  loading: false,
  success: false,
  message: '',
  list: [],
  totalCount: 0,
  searchFormValues: {
    init: true,
    ...initFormValues,
  },
  istInfoSuceess: false,
  istInfoLoading: false,
  istInfoList: [],
};

// reducers
export default function etcFdSumrPrtsa(state = initialState, action) {
  switch (action.type) {
    case SUCCESS(FETCH_FDSUMRPRTSA): // 조회 성공
      return {
        ...state,
        success: true,
        loading: false,
        list: action.payload.data?.subDto,
        totalCount: action.payload?.subDtoCnt,
      };
    case REQUEST(FETCH_FDSUMRPRTSA): // 조회 요청
      return {
        ...state,
        loading: true,
        success: false,
      };
    case FAILURE(FETCH_FDSUMRPRTSA): // 조회실패
      return {
        ...initialState,
        loading: false,
        success: false,
        message: action.payload.response?.data?.message,
      };
    case SUCCESS(FETCH_IST_INFO_LIST): // 조회 성공
      return {
        ...state,
        istInfoSuccess: true,
        istInfoLoading: false,
        istInfoList: action.payload.data?.subDto,
      };
    case REQUEST(FETCH_IST_INFO_LIST): // 조회 요청
      return {
        ...state,
        istInfoLoading: true,
        istInfoSuccess: false,
      };
    case FAILURE(FETCH_IST_INFO_LIST): // 조회실패
      return {
        istInfoLoading: false,
        istInfoSuccess: false,
        istInfoList: [],
      };
    case SAVE_SEARCH_FORM_VALUES:
      return {
        ...state,
        istInfoSuccess: false,
        istInfoLoading: false,
        searchFormValues: action.payload,
      };
    case RESET_STATE:
      return initialState;
    default:
      return state;
  }
}

/**
 * 기타 - 서울페이플러스 - 자금집계현황
 */
export const selectEtcFdSumrPrtsaList = (searchParams) => {
  return {
    type: FETCH_FDSUMRPRTSA,
    payload: fetchEtcFdSumrPrtsaList(searchParams),
  };
};
/**
 * 기관명 콤보 조회
 */
export const selectIstInfoComboList = (searchParams) => {
  return {
    type: FETCH_IST_INFO_LIST,
    payload: fetchCommonComboList({ slt_box_no: '005', ...searchParams }),
  };
};
/**

/**
 * 조회조건 저장
 */
export const saveSearchFormValues = (searchFormValues) => {
  return {
    type: SAVE_SEARCH_FORM_VALUES,
    payload: {
      ...searchFormValues,
      init: false,
    },
  };
};

/**
 * 처리내역 상태초기화
 */
export const resetEtcFdSumrPrtsa = () => {
  return {
    type: RESET_STATE,
  };
};
