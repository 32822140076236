import instance from '../../common/config/api';

/**
 * 법인카드 - 약자기업 조회
 * @param {object} params
 * @returns
 */
export const fetchWeakCopRgsList = (params) =>
  instance.post(`${process.env.REACT_APP_SERVER_URL}/scq/scq/cad/0005/selectListWeakCop`, params);

/**
 * 법인카드 - 약자기업 다건등록
 * @param {object} dtos
 * @returns
 */
export const insertCadStlPrtsList = (dtos) =>
  instance.post(`${process.env.REACT_APP_SERVER_URL}/scq/scq/cad/0005/insertWeakCopList`, { subDto: dtos });
