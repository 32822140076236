import { fetchTsNaPrts } from '../../../lib/ebk/TsNaPrts.api';
import { SUCCESS, REQUEST, FAILURE } from '../../../common/utils/action-type-utils';
import dayjs from 'dayjs';
import { fetchCommonComboList } from '../../../lib/cmm/combo.api';

// actions
const FETCH_TS_NA_PRTS = 'TsNaPrts/FETCH_ANRV_TRHS_LIST';

const FETCH_IST_INFO_LIST = 'TsNaPrts/FETCH_IST_INFO_LIST';
const FETCH_DEPT_INFO_LIST = 'TsNaPrts/FETCH_DEPT_INFO_LIST';
const FETCH_ACC_YR_LIST = 'TsNaPrts/FETCH_ACC_YR_LIST';
const FETCH_ACT_NO_LIST = 'TsNaPrts/FETCH_ACT_NO_LIST';

const RESET_INITIAL_STATE = 'TsNaPrts/RESET_INITIAL_STATE';
const SAVE_SEARCH_FORM_VALUES = 'TsNaPrts/SAVE_SEARCH_FORM_VALUES';

const CLEAR_MESSAGE = 'TsNaPrts/CLEAR_MESSAGE';
const SET_BEFORE_SEARCH_PARAM = 'TsNaPrts/SET_BEFORE_SEARCH_PARAM';

export const initFormValues = {
  fnd_dis: 'all',
  dat_dis: '00',
  cmd_dt_start: dayjs(),
  cmd_dt_end: dayjs(),
  cmd_no_start: '',
  cmd_no_end: '',
  acc_yr: dayjs().format('YYYY'), //회계연도
  req_inst_fg: '00',
  cus_ist_cd: '6110000', //기관명
  cus_dept_cd: 'all', //부서명
  ydcs8a1_t8a14_act_no: 'all', //지급계좌
  pmdp_act_dscd: '02',
  trn_kd: 'GEN',
  ipTotalPay: 0,
  vgubun: '0',
};

// initial state
const initialState = {
  loading: false,
  success: false,
  message: '',
  list: [],
  totalCount: 0,
  beforeSearchParam: {},

  //콤보 - 기관명 005
  istInfoSuccess: false,
  istInfoLoading: false,
  istInfoList: [],

  //콤보 - 부서명 158
  deptInfoSuccess: false,
  deptInfoLoading: false,
  deptInfoList: [],

  //콤보 - 계좌번호 157
  actNoSuccess: false,
  actNoLoading: false,
  actNoList: [],

  //콤보 - 회계연도 025
  accYrSuccess: false,
  accYrLoading: false,
  accYrList: [],

  // 검색조건
  searchFormValues: {
    init: true,
    ...initFormValues,
  },
};

// reducers
export default function TsNaPrts(state = initialState, action) {
  switch (action.type) {
    case SUCCESS(FETCH_TS_NA_PRTS): // 조회 성공
      return {
        ...state,
        success: true,
        loading: false,
        list: action.payload.data?.main,
        totalCount: action.payload.data?.mainCnt,
      };
    case REQUEST(FETCH_TS_NA_PRTS): // 조회 요청
      return {
        ...state,
        loading: true,
      };
    case FAILURE(FETCH_TS_NA_PRTS): // 조회실패
      return {
        ...state,
        list: [],
        loading: false,
        success: false,
        message: action.payload.response?.data?.message,
      };
    // 기관명
    case SUCCESS(FETCH_IST_INFO_LIST): // 조회 성공
      return {
        ...state,
        istInfoSuccess: true,
        istInfoLoading: false,
        istInfoList: action.payload.data?.subDto,
      };
    case REQUEST(FETCH_IST_INFO_LIST): // 조회 요청
      return {
        ...state,
        istInfoLoading: true,
        istInfoSuccess: false,
      };
    case FAILURE(FETCH_IST_INFO_LIST): // 조회실패
      return {
        istInfoLoading: false,
        istInfoSuccess: false,
        istInfoList: [],
      };
    // 부서명
    case SUCCESS(FETCH_DEPT_INFO_LIST): // 조회 성공
      return {
        ...state,
        deptInfoSuccess: true,
        deptInfoLoading: false,
        deptInfoList: action.payload.data?.subDto,
      };
    case REQUEST(FETCH_DEPT_INFO_LIST): // 조회 요청
      return {
        ...state,
        deptInfoLoading: true,
        deptInfoSuccess: false,
      };
    case FAILURE(FETCH_DEPT_INFO_LIST): // 조회실패
      return {
        ...state,
        deptInfoLoading: false,
        deptInfoSuccess: false,
        deptInfoList: [],
      };
    // 계좌번호
    case SUCCESS(FETCH_ACT_NO_LIST): // 조회 성공
      return {
        ...state,
        actNoSuccess: true,
        actNoLoading: false,
        actNoList: action.payload.data?.subDto,
      };
    case REQUEST(FETCH_ACT_NO_LIST): // 조회 요청
      return {
        ...state,
        actNoLoading: true,
        actNoSuccess: false,
      };
    case FAILURE(FETCH_ACT_NO_LIST): // 조회실패
      return {
        ...state,
        actNoLoading: false,
        actNoSuccess: false,
        actNoList: [],
      };
    // 회계연도
    case SUCCESS(FETCH_ACC_YR_LIST): // 조회 성공
      return {
        ...state,
        accYrSuccess: true,
        accYrLoading: false,
        accYrList: action.payload.data?.subDto,
      };
    case REQUEST(FETCH_ACC_YR_LIST): // 조회 요청
      return {
        ...state,
        accYrLoading: true,
        accYrSuccess: false,
      };
    case FAILURE(FETCH_ACC_YR_LIST): // 조회실패
      return {
        ...state,
        accYrLoading: false,
        accYrSuccess: false,
        accYrList: [],
      };
    case SAVE_SEARCH_FORM_VALUES:
      return {
        ...state,
        istInfoSuccess: false,
        istInfoLoading: false,
        deptInfoSuccess: false,
        deptInfoLoading: false,
        actNoSuccess: false,
        actNoLoading: false,
        accYrSuccess: false,
        accYrLoading: false,

        searchFormValues: action.payload,
      };
    case SET_BEFORE_SEARCH_PARAM:
      return {
        ...state,
        beforeSearchParam: action.payload,
      };
    case RESET_INITIAL_STATE:
      return initialState;
    case CLEAR_MESSAGE:
      return {
        ...state,
        message: '',
        success: false,
      };
    default:
      return state;
  }
}

/**
 * 이체불능구 조회
 */
export const selectTsNaPrts = (searchParams) => {
  return {
    type: FETCH_TS_NA_PRTS,
    payload: fetchTsNaPrts(searchParams),
  };
};

/**
 * 메시지초기화
 */
export const clearMessage = () => {
  return {
    type: CLEAR_MESSAGE,
  };
};

/**
 *
 */
export const setBeforeSearchParam = (searchParams) => {
  return {
    type: SET_BEFORE_SEARCH_PARAM,
    payload: searchParams,
  };
};

//기관명 콤보 조회
export const selectIstInfoList = (searchParam) => {
  return {
    type: FETCH_IST_INFO_LIST,
    payload: fetchCommonComboList({ slt_box_no: '005', ...searchParam }),
  };
};

//부서명 콤보 조회
export const selectDeptInfoList = (searchParam) => {
  return {
    type: FETCH_DEPT_INFO_LIST,
    payload: fetchCommonComboList({ slt_box_no: '158', ...searchParam }),
  };
};

//회계연도 콤보 조회
export const selectAccYrList = () => {
  return {
    type: FETCH_ACC_YR_LIST,
    payload: fetchCommonComboList({ slt_box_no: '025' }),
  };
};

//계좌번호 콤보 조회
export const selectActNoList = (searchParam) => {
  return {
    type: FETCH_ACT_NO_LIST,
    payload: fetchCommonComboList({ slt_box_no: '157', ...searchParam }),
  };
};

/**
 * 조회조건 저장
 */
export const saveSearchFormValues = (searchFormValues) => {
  return {
    type: SAVE_SEARCH_FORM_VALUES,
    payload: {
      ...searchFormValues,
      init: false,
    },
  };
};

/**
 * 상태초기화
 */
export const resetInitialState = () => {
  return {
    type: RESET_INITIAL_STATE,
  };
};
