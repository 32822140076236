import WOButton from '../../components/common/WOButton';
import WOComboBox from '../../components/common/WOComboBox';
import WOMap from '../../components/main/WOMap';
import WOOccurrenceItem from '../../components/main/WOOccurrenceItem';
import WOBorderBox from '../../components/common/WOBorderBox';
import styled, { css } from 'styled-components';
import WOIconButton from '../../components/common/WOIconButton';
import { Swiper, SwiperSlide } from 'swiper/react';
import WOSlider from '../../components/common/WOSlider';
import WOEventItem from '../../components/main/WOEventItem';
import { useCallback, useEffect, useMemo, useState } from 'react';
import { fdsComboStyle } from '../../styles/common.styled';
import { Link, useNavigate } from 'react-router-dom';
import dayjs from 'dayjs';
import { useDispatch, useSelector } from 'react-redux';
import { sendLogout } from '../../redux/modules/cmm/login.reducer';
import useSession from '../../hooks/useSession';
import { dataFormat } from '../../common/utils/utils';
import { Controller, useForm } from 'react-hook-form';
import { Severity } from '../../common/enum/cmm/occurence';
import { selectAppvCnt, selectPapsCnt } from '../../redux/modules/cmm/person.reducer';
import LogoutArea from './LogoutArea';
import UserSettingPopup from '../../pages/cmm/userSettingPopup';

const WOCollapseFDS = ({ user, istInfoList, handleOnIstInfoChange, type, mapInfo }) => {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  //const { alert } = useAlert();
  const today = dayjs().format('YYYY.MM.DD');
  const { list: menuList } = useSelector((state) => state.menuState);
  const {
    user_nm,
    br_nm,
    dept_nm,
    qna_cnt,
    user_tp_cd,
    user_id,
    cus_ist_cd: istCd,
    ist_nm,
  } = useSession().getUserInfo();

  // 사용자 설정
  const [isOpen, setIsOpen] = useState(false);

  // 부서 변경
  const [isOpen3, setIsOpen3] = useState(false);

  // // grid
  const { totCnt, levelCnt, cus_ist_cd, details, liveEventList } = useSelector((state) => state.MainFDS);

  const allOption = useMemo(() => ({ code: 'all', codenm: '전체' }), []);

  /**
   * 로그아웃
   */
  const handleLogout = useCallback(() => {
    dispatch(sendLogout());
    navigate('/page/login');
  }, [dispatch, navigate]);

  const { control } = useForm({
    defaultValues: {
      cus_ist_cd: cus_ist_cd,
      act_dis_cd: '',
      // 기본정보
      // 아이디
      // 성명
      // 생년월일
      // 휴대폰 번호
      // 소속구청/부서
      // 법인카드 권한
      // 비밀번호
    },
  });

  const occurrenceList = useMemo(
    () =>
      (() => {
        let arr = [];
        let index = 1;
        for (let key in details) {
          const data = details[key];

          let totalInfo = data.find((item) => item.fds_ist_cd === '');
          let level = totalInfo?.fds_lev_val;
          let cnt = totalInfo?.grp_cnt;

          const json = {
            id: index,
            title: data?.at(0).grp_cd_nm,
            count: cnt,
            severity: Severity.getSeverity(level ? level : '하'),
            detailList: (() => {
              let arr = [];
              const filterData = data.filter((item) => item.fds_ist_cd !== '');
              for (let i = 0; i < filterData?.length; i++) {
                if (i >= 5) {
                  break;
                } else {
                  const json = {
                    id: i + 1,
                    location: filterData.at(i).ist_nm,
                    count: dataFormat.number(filterData.at(i).fds_ist_cnt),
                  };
                  arr.push(json);
                }
              }
              return arr;
            })(),
          };
          arr.push(json);
          index++;
        }
        return arr;
      })(),
    [details]
  );

  const occurrenceNoAreaList = useMemo(() => {
    let arr = [];
    let index = 1;
    for (let key in details) {
      const data = details[key];
      const detailsData = details[key];

      let totalInfo = detailsData.find((item) => item.fds_ist_cd === '');
      let level = totalInfo?.fds_lev_val;
      let cnt = totalInfo?.grp_cnt;

      const json = {
        id: index,
        title: detailsData?.at(0).grp_cd_nm,
        count: cnt,
        severity: Severity.getSeverity(level),
        dataList: (() => {
          let arr = [];
          let idx = 0;
          for (let i = 0; i < data?.length; i++) {
            const json = {
              id: idx + 1,
              agency: data.at(i).ist_nm ? data.at(i).ist_nm : '-',
              account: dataFormat.account1(data.at(i).fds_acno),
              amount: dataFormat.number(data.at(i).pay_am),
              date: dayjs(data.at(i).fds_dtct_dt).format('YYYY.MM.DD'),
              briefs: data.at(i).pay_rmk,
            };
            arr.push(json);
          }
          return arr;
        })(),
      };
      arr.push(json);
      index++;
    }
    return arr;
  }, [details]);

  const liveEventListTable = useMemo(
    () =>
      liveEventList.map((item, index) => ({
        id: index + 1,
        title: item.fds_policy_nm,
        source: item.pay_rmk,
        time: item.fds_dtct_dt ? dayjs(item.fds_dtct_dt).format('YYYY.MM.DD HH:mm:ss') : '',
        isRead: false,
        severity: Severity.getSeverity(item.fds_lev_nm),
        fds_ist_cd: item.fds_ist_cd,
      })),
    [liveEventList]
  );

  const allAreaSliderOption = {
    spaceBetween: 8,
    slidesPerView: 'auto',
    slidesPerGroup: 2,
  };
  const eachSliderOption = {
    spaceBetween: 8,
    slidesPerView: 1,
    slidesPerGroup: 1,
  };
  const [sliderTitle, setSliderTitle] = useState('세부 발생 항목');

  const [sliderOption, setSliderOption] = useState(allAreaSliderOption);

  const handleSlideChange = (data) => {
    //setSliderTitle(data.isEnd ? '계좌현황' : '세부 발생 항목');
  };

  const handleUserPopupOpen = () => {
    setIsOpen(true);
  };

  useEffect(() => {
    setSliderOption(user?.id === 1 ? allAreaSliderOption : eachSliderOption);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [user]);

  const { appvCnt, docCnt } = useSelector((state) => state.personState);
  useState(() => {
    // // 승인건수
    // dispatch(selectAppvCnt());
    // 문서건수
    dispatch(selectPapsCnt());
  }, [dispatch]);

  // view
  return (
    <>
      <InfoWrap>
        <div className="info__name">
          <span className="bold">{user_nm}님</span>

          <span className="area">
            {/* 20240329 : 
            - 우리은행 총괄로 접속했을때는 우리은행 "이름"
            - 기관으로 접속시 기관명 "이름"
          */}
            {user_tp_cd?.substring(0, 1) === 'W' ? br_nm : `${ist_nm} ${dept_nm}`}
          </span>
        </div>
        <LogoutArea onLogout={handleLogout} />
        <div className="info__document">
          <ul className="info__document-list">
            {menuList
              .filter((menu) => menu.mnu_aut_cd !== 'X')
              .find((menu) => menu.scrn_url.includes('SYS0107M01')) && (
              <li className="info__document-item" onClick={() => navigate('/page/SYS0107M01')}>
                Q&A {user?.id === 1 && <>{dataFormat.number(qna_cnt)}건</>}
              </li>
            )}
            {menuList
              .filter((menu) => menu.mnu_aut_cd !== 'X')
              .find((menu) => menu.scrn_url.includes('BRC0209V01')) && (
              <li className="info__document-item" onClick={() => navigate('/page/BRC0209V01')}>
                문서 {dataFormat.number(docCnt)}건
              </li>
            )}
            {menuList
              .filter((menu) => menu.mnu_aut_cd !== 'X')
              .find((menu) => menu.scrn_url.includes('RPT0402M01')) && (
              <li className="info__document-item" onClick={() => navigate('/page/RPT0402M01')}>
                결재 {dataFormat.number(appvCnt)}건
              </li>
            )}
          </ul>
          <WOButton variant="personal" onClick={handleUserPopupOpen}>
            사용자 설정
          </WOButton>
        </div>
      </InfoWrap>
      <ContentWrap>
        {/* START: map영역 */}
        <Content>
          <MapArea>
            <div className="status">
              <div className="status__text">FDS 현황</div>
              {/* {user?.id === 1 ? (
                <WOComboBox optionList={areaOptionList} width={160} defaultValue={user && areaOptionList[0].id} />
              ) : (
                <WOComboBox
                  optionList={areaOptionList}
                  width={160}
                  readOnly={true}
                  defaultValue={user && areaOptionList[1].id}
                />
              )} */}
              <span className="sub-text">Today. {today}</span>
            </div>
            <Controller
              /* 기관명 */
              control={control}
              name={'cus_ist_cd'}
              render={({ field: { onChange, value } }) => (
                <WOComboBox
                  type={'fds'}
                  optionList={[allOption, ...istInfoList]?.map((option) => ({
                    id: option.code,
                    label: option.codenm,
                    value: option.code,
                  }))}
                  onChange={(val) => {
                    onChange(val);
                    handleOnIstInfoChange(val);
                  }}
                  defaultValue={cus_ist_cd}
                  isAutoComplete={true}
                  readOnly={user?.id !== 1}
                />
              )}
            />
            <WOMap
              isExpand={false}
              totCnt={totCnt}
              levelCnt={levelCnt}
              mapInfo={mapInfo}
              handleOnIstInfoChange={handleOnIstInfoChange}
              user={user}
              istInfoList={istInfoList}
            />
          </MapArea>
        </Content>
        {/* END: map영역 */}
        {/* START: 세부 발생항목 영역 */}
        <Content>
          <OccurrenceArea $user={user}>
            <SubTitle>
              {sliderTitle}
              <span className="sub-text">최근 7일 기준</span>
            </SubTitle>
            <WOSlider
              isNavigation={user?.id === 1 && true}
              {...sliderOption}
              height={250}
              onSlideChange={handleSlideChange}>
              {user?.id === 1 ? (
                occurrenceList.map((occurrence) => (
                  <CustomSwiperSlide type={'occurrence'} $user={user} key={occurrence.id}>
                    <WOOccurrenceItem item={occurrence} $user={user?.id} />
                  </CustomSwiperSlide>
                ))
              ) : (
                <CustomSwiperSlide type={'occurrence'} $user={user}>
                  {occurrenceNoAreaList.map((occurrence) => (
                    <WOOccurrenceItem key={occurrence.id} item={occurrence} $user={user?.id} type={'type01'} />
                  ))}
                </CustomSwiperSlide>
              )}
              {/* <CustomSwiperSlide type={'account'} $user={user}>
                <ul>
                  {accountList.map((account) => (
                    <WOAccountItem key={account.id} account={account} />
                  ))}
                </ul>
              </CustomSwiperSlide> */}
            </WOSlider>
            <Swiper></Swiper>
          </OccurrenceArea>
        </Content>
        {/* END: 세부 발생항목 영역 */}
        {/* START: 실시간 이벤트 영역 */}
        <Content>
          <LiveEventArea>
            <div className="top_area">
              <SubTitle>
                실시간 이벤트<span className="sub-text">최근 3일 기준</span>
              </SubTitle>
              <Link
                to={'/page/FDS0101V01'}
                className="more"
                state={{ mainProps: { fds_ist_cd: cus_ist_cd, type: 'live' } }}>
                더보기
                <WOIconButton icon={'more'} width={16} height={16} onClick={() => {}} />
              </Link>
            </div>
            <WOBorderBox height="264" marginTop={12}>
              <ul>
                {liveEventListTable.map((event) => (
                  <WOEventItem key={event.id} event={event} type="live" />
                ))}
              </ul>
            </WOBorderBox>
          </LiveEventArea>
        </Content>
        {/* END: 실시간 이벤트 영역 */}
      </ContentWrap>

      {/* 사용자 설정 팝업 */}
      <UserSettingPopup isOpen={isOpen} onClose={() => setIsOpen(false)} />
    </>
  );
};

export default WOCollapseFDS;

const InfoWrap = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  position: relative;
  min-width: 400px;
  padding: 32px;
  /* height: 134px; */
  font-size: 14px;
  font-weight: 400;
  background-color: #1a2c49;
  .info {
    &__name {
      display: flex;
      flex-direction: column;
      width: 260px;
      font-size: 20px;
      font-weight: 400;
      line-height: 24px;
      gap: 4px 0;
      .area {
        word-break: break-all;
        font-size: 14px;
        font-weight: 400;
        color: #ededf3;
        line-height: 21px;
      }
      .bold {
        font-weight: 700;
      }
    }
    &__noti {
      position: absolute;
      right: 28px;
      top: 60px;
      line-height: 30px;
      display: flex;
      align-items: center;
      gap: 8px;
      color: #99a3ff;
    }
    &__document {
      margin-top: 4px;
      display: flex;
      justify-content: space-between;
      align-items: center;
      &-list {
        display: flex;
        font-size: 14px;
      }
      &-item {
        display: flex;
        align-items: center;
        cursor: pointer;
        font-size: 14px;
        font-weight: 400;
        color: #9ea6b2;
        & + .info__document-item {
          &::before {
            content: '';
            display: block;
            width: 1px;
            height: 12px;
            margin: 0 10px;
            background-color: #86909f;
          }
        }
      }
    }
  }
  .btn {
    padding: 2px 8px;
    color: #c9c9d0;
    font-weight: 400;
  }
`;

const ContentWrap = styled.div`
  position: relative;
  height: calc(100% - 134px);
  padding: 24px 32px 40px;
  overflow: hidden;
  overflow-y: auto;
  &::-webkit-scrollbar-track {
    background: rgba(237, 237, 243, 0); /* 스크롤바 트랙 색상 */
  }
`;

const MapArea = styled.div`
  .sub-text {
    font-size: 14px;
    font-weight: 400;
    color: #c9c9d0;
  }

  .status {
    display: flex;
    align-items: flex-end;
    justify-content: space-between;
    margin-bottom: 12px;
    &__text {
      font-size: 24px;
      font-weight: 700;
    }
  }
  ${fdsComboStyle}

  .map-wrap {
    margin-top: 12px;
  }
`;

const SubTitle = styled.h3`
  // margin-bottom: 12px;
  font-size: 18px;
  font-weight: 600;
  line-height: 27px;
  .sub-text {
    color: var(--Blue-04);
    font-size: 12px;
    font-weight: 400;
    margin-left: 4px;
  }
`;

const OccurrenceArea = styled.div`
  width: 100%;
  position: relative;
`;

const CustomSwiperSlide = styled(SwiperSlide)`
  width: calc(50% - 8px);

  ${(props) =>
    props.type === 'account' &&
    css`
      width: 100%;
    `}
  /* ${(props) =>
    props.$user?.id === 1 &&
    props.type === 'occurrence' &&
    css`
      width: 33.4%;
    `} */
  ${(props) =>
    props.$user?.id !== 1 && props.type === 'occurrence'
      ? css`
          width: 100%;
          padding-right: 0;
          display: flex;
          flex-wrap: wrap;
          gap: 8px;
          .occurrence-item {
            width: calc(50% - 4px);
            padding: 13px 12px;
            height: 78px;
          }
        `
      : css``}
`;

const LiveEventArea = styled.div`
  .top_area {
    display: flex;
    align-items: center;
    justify-content: space-between;
    .more {
      display: flex;
      color: #bdd7ff;
      font-size: 12px;
      font-weight: 400;
      line-height: 18px;
    }
  }
`;

const Content = styled.div`
  & + & {
    padding-top: 24px;
  }
`;

// const ChartWrap = styled.div``;
