import instance from '../../common/config/api';

/**
 * 금고영업점 - 지자체 정보관리 - 예산수혜업체 유치(관리자 조회)
 * @param {object} params
 * @returns
 */
export const fetchBnfCoDtenAllList = (params) =>
  instance.post(`${process.env.REACT_APP_SERVER_URL}/scq/scq/brc/0002/selectBnfCoDtenAllList`, params);

/**
 * 금고영업점 - 지자체 정보관리 - 예산수혜업체 유치(시/구청 조회)
 * @param {object} params
 * @returns
 */
export const fetchBnfCoDtenIstList = (params) =>
  instance.post(`${process.env.REACT_APP_SERVER_URL}/scq/scq/brc/0002/selectBnfCoDtenIstList`, params);
