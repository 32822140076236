import { SUCCESS, REQUEST, FAILURE } from '../../../common/utils/action-type-utils';
import { fetchBizFavt, mergeBizFavt } from '../../../lib/cmm/main.api';

const FETCH_BIZ_FAVT = 'bookmarkState/FETCH_BIZ_FAVT';
const SAVE_BIZ_FAVT = 'bookmarkState/SAVE_BIZ_FAVT';
const RESET_BIZ_FAVT_MSG = 'bookmarkState/RESET_BIZ_FAVT_MSG';

// initial state
const initialState = {
  loading: false,
  success: false,
  message: '',
  list: [],
  totalCount: 0,
  updating: false,
  updatingSuccess: null,
};

// reducers
export default function bookmarkState(state = initialState, action) {
  switch (action.type) {
    case SUCCESS(FETCH_BIZ_FAVT): // 조회 성공
      return {
        ...state,
        success: true,
        loading: false,
        list: action.payload.data?.subDto,
        totalCount: action.payload?.data.subDtoCnt,
      };
    case REQUEST(FETCH_BIZ_FAVT): // 조회 요청
      return {
        ...state,
        loading: true,
        success: false,
      };
    case FAILURE(FETCH_BIZ_FAVT): // 조회실패
      return {
        ...initialState,
        loading: false,
        success: false,
        message: action.payload.response?.data?.message,
      };
    case SUCCESS(SAVE_BIZ_FAVT): // 저장 성공
      return {
        ...state,
        updatingSuccess: action.payload.data === 0 ? false : true,
        updating: false,
        message:
          action.payload.data === 0
            ? '자주 쓰는 업무는 최대 10개까지 가능합니다. 자주 쓰는 업무 삭제 후 추가해 주세요 '
            : true,
      };
    case REQUEST(SAVE_BIZ_FAVT): // 저장 요청
      return {
        ...state,
        updating: true,
        updatingSuccess: null,
      };
    case FAILURE(SAVE_BIZ_FAVT): // 저장 실패
      return {
        ...initialState,
        updating: false,
        updatingSuccess: false,
        message: action.payload.response?.data?.message,
      };
    case RESET_BIZ_FAVT_MSG: // 메시지 초기화
      return {
        ...state,
        loading: false,
        success: null,
        message: '',
        updating: false,
        updatingSuccess: null,
      };
    default:
      return state;
  }
}

/**
 * 자주 쓰는 업무(즐겨찾기) 조회
 */
export const selectBizFavt = (searchParams) => {
  return {
    type: FETCH_BIZ_FAVT,
    payload: fetchBizFavt(searchParams),
  };
};

/**
 * 자주 쓰는 업무(즐겨찾기) 수정
 */
export const saveBizFavt = (dto) => {
  return {
    type: SAVE_BIZ_FAVT,
    payload: mergeBizFavt(dto),
  };
};

/**
 *  메시지 초기화
 */
export const resetBizFavtMsg = () => {
  return {
    type: RESET_BIZ_FAVT_MSG,
  };
};
