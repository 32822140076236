import instance from '../../common/config/api';
/**
 * 세입 - 세입거래 내역장 조회(과거)
 * @param {object} params
 * @returns
 */

// 세입거래내역장 건수 조회(과거, 용산구청 2023년 이전)
export const fetchAnrvTrnPrtsCnt = (params) =>
  instance.post(`${process.env.REACT_APP_SERVER_URL}/scq/scq/arv/0009/selectAnrvTrnPrtsCnt`, params);

// 세입거래내역장 조회(과거, 용산구청 2023년 이전)
export const fetchAnrvTrnPrtsList = (params) =>
  instance.post(`${process.env.REACT_APP_SERVER_URL}/scq/scq/arv/0009/selectAnrvTrnPrtsList`, params);

// 세입거래내역장 건수 조회(과거)
export const fetchAnrvTrnPrtsOldCnt = (params) =>
  instance.post(`${process.env.REACT_APP_SERVER_URL}/scq/scq/arv/0009/selectAnrvTrnPrtsOldCnt`, params);

// 세입거래내역장 조회(과거)
export const fetchAnrvTrnPrtsOldList = (params) =>
  instance.post(`${process.env.REACT_APP_SERVER_URL}/scq/scq/arv/0009/selectAnrvTrnPrtsOldList`, params);
