import { SUCCESS, REQUEST, FAILURE } from '../../../common/utils/action-type-utils';
import { fetchMoActGbn } from '../../../lib/rpt/RPT0002.api';
import { fetchAnexDayMmEvdXpn } from '../../../lib/rpt/RPT0003.api';

// actions
const FETCH_ANEX_DAY_MM_EVD_XPN = 'RPT0301V01/FETCH_ANEX_DAY_MM_EVD_XPN';
const FETCH_MO_ACT_GBN = 'RPT0301V01/FETCH_MO_ACT_GBN';
const CLEAR_MESSAGE = 'RPT0301V01/CLEAR_MESSAGE';

// initial state
const initialState = {
  loading: false,
  success: false,
  message: '',
  listTitle: {},
  listTitle01: {},
  list01: [],
  list02: [],
  list03: [],
  listTail: {},
  common: {},
  totalCount: 0,
  head: {},
  moYn: '',
  moYnLoading: false,
  moYnSuccess: false,
  moYnMessage: '',
};

// reducers
export default function RPT0301V01(state = initialState, action) {
  switch (action.type) {
    case SUCCESS(FETCH_ANEX_DAY_MM_EVD_XPN): // 조회 성공
      return {
        ...state,
        success: true,
        loading: false,
        list01: action.payload.data?.output_data_01,
        list02: action.payload.data?.output_data_02,
        list03: action.payload.data?.output_data_03,
        listTitle: action.payload.data?.output_title,
        listTitle01: action.payload.data?.output_title_01,
        common: action.payload.data?.common,
        head: action.payload.data?.output_head,
      };
    case REQUEST(FETCH_ANEX_DAY_MM_EVD_XPN): // 조회 요청
      return {
        ...state,
        message: '',
        loading: true,
        success: false,
      };
    case FAILURE(FETCH_ANEX_DAY_MM_EVD_XPN): // 조회실패
      return {
        ...state,
        listTitle: {},
        listTitle01: {},
        list01: [],
        list02: [],
        list03: [],
        listTail: {},
        common: {},
        head: {},
        loading: false,
        success: false,
        message: action.payload.response?.data?.message,
      };
    case SUCCESS(FETCH_MO_ACT_GBN): // 조회 성공
      return {
        ...state,
        moYn: action.payload.data?.mo_yn,
        moYnSuccess: true,
        moYnLoading: false,
      };
    case REQUEST(FETCH_MO_ACT_GBN): // 조회 요청
      return {
        ...state,
        moYnMessage: '',
        moYnLoading: true,
        moYnSuccess: false,
      };
    case FAILURE(FETCH_MO_ACT_GBN): // 조회실패
      return {
        ...state,
        moYn: '',
        moYnLoading: false,
        moYnSuccess: false,
        moYnMessage: action.payload.response?.data?.message,
      };
    case CLEAR_MESSAGE:
      return {
        ...state,
        moYnSuccess: false,
        moYnMessage: '',
        success: false,
        message: '',
      };
    default:
      return state;
  }
}

/**
 * 세출보고서 - 세출 일〮월〮분기계표 조회
 */
export const selectAnexDayMmEvdXpn = (searchParams) => {
  return {
    type: FETCH_ANEX_DAY_MM_EVD_XPN,
    payload: fetchAnexDayMmEvdXpn(searchParams),
  };
};

/**
 * 세출보고서 - 모계좌여부 구분 확인 조회
 */
export const selectMoActGbn = (searchParams) => {
  return {
    type: FETCH_MO_ACT_GBN,
    payload: fetchMoActGbn(searchParams),
  };
};

/**
 * 메시지초기화
 */
export const clearMessage = () => {
  return {
    type: CLEAR_MESSAGE,
  };
};
