import instance from '../../common/config/api';

/**
 * 제로페이(관리자) - 사용자조회
 * @param {object} params
 * @returns
 */

export const fetchZpbUserList = (params) =>
  instance.post(`${process.env.REACT_APP_SERVER_URL}/scq/scq/zpb/0002/selectZeroUserList`, params);

/**
 * 제로페이(관리자) - 권한 및 수신여부 설정
 * @param {object} params
 * @returns
 */

export const updateZpbUserDpsYn = (params) =>
  instance.post(`${process.env.REACT_APP_SERVER_URL}/scq/scq/zpb/0002/saveUserDpsYn`, params);

/**
 * 제로페이(관리자) - 해지현행화
 * @param {object} params
 * @returns
 */

export const updateUserCancDt = (params) =>
  instance.post(`${process.env.REACT_APP_SERVER_URL}/scq/scq/zpb/0002/saveUserCancDt`, { subDto: params });
