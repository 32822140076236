import instance from '../../common/config/api';

const PREFIX = `${process.env.REACT_APP_SERVER_URL}/scq/scq/arv/0001`;

/**
 * 세입 - 세입계좌현황 조회
 * @param {object} params
 * @returns
 */
export const fetchAnrvAcnoList = (params) => instance.post(`${PREFIX}/selectAnrvAcnoList`, params);

/**
 * 세입 - 세입계좌 상세내역 조회
 * @param {object} params
 * @returns
 */
export const fetchAnrvAcnoDtl = (params) => instance.post(`${PREFIX}/selectAnrvAcnoDtl`, params);

/**
 * 세입 - 세입거래내역장 조회
 * @param {object} params
 * @returns
 */
export const fetchAnrvTrnPrtsList = (params) => instance.post(`${PREFIX}/selectAnrvTrnPrtsList`, params);

/**
 * 세입 - 세입거래명세 조회
 * @param {object} params
 * @returns
 */
export const fetchAnrvTrhsList = (params) => instance.post(`${PREFIX}/selectAnrvTrhsList`, params);

/**
 * 세입 - 세입거래명세 상세내역 조회
 * @param {object} params
 * @returns
 */
export const fetchAnrvTrhsDtl = (params) => instance.post(`${PREFIX}/selectAnrvTrhsDtl`, params);

/**
 * 세입 - 구좌경정/과오납
 * @param {object} params
 * @returns
 */
export const fetchActCrctOwrp = (params) => instance.post(`${PREFIX}/selectActCrctOwrp`, params);

/**
 * 세입 - 입출금집계
 * @param {object} params
 * @returns
 */
export const fetchAcnoInotSumrList = (params) => instance.post(`${PREFIX}/selectAcnoInotSumr`, params);
