import instance from '../../common/config/api';

/**
 * 수입금월중총괄보고서 보고서 조회
 * @param {object} params
 * @returns
 */
export const fetchImpMmGnrnRpt = (params) =>
  instance.post(`${process.env.REACT_APP_SERVER_URL}/scq/scq/rpt/0001/selectImpMmGnrnRpt`, params);

/**
 * 수입금월중총괄보고서 내역 조회
 * @param {object} params
 * @returns
 */
export const fetchImpMmGnrnRptPrts = (params) =>
  instance.post(`${process.env.REACT_APP_SERVER_URL}/scq/scq/rpt/0001/selectImpMmGnrnRptPrts`, params);
