import instance from '../../common/config/api';

/**
 * 금고영업점 - 공채 - 당일취소내역 조회
 * @param {object} params
 * @returns
 */
export const fetchBuyCfmReceiptList = (params) =>
  instance.post(`${process.env.REACT_APP_SERVER_URL}/scq/scq/brc/0001/selectBuyCfmReceiptList`, params);

/**
 * 금고영업점 - 공채 - 당일자 취소신청 등록
 * @param {object} dto
 * @returns
 */
export const updateThdDayCancel = (dto) =>
  instance.post(`${process.env.REACT_APP_SERVER_URL}/scq/scq/brc/0001/insertThdDayCancel`, dto);

/**
 * 금고영업점 - 공채 - 공채 당일자 취소 신청 내역 조회
 * @param {object} params
 * @returns
 */
export const fetchTheDayCancleReqList = (params) =>
  instance.post(`${process.env.REACT_APP_SERVER_URL}/scq/scq/brc/0001/selectTheDayCancleReqList`, params);
