import { SUCCESS, REQUEST, FAILURE } from '../../../common/utils/action-type-utils';
import { fetchUmsKkoInfo } from '../../../lib/fds/FDS0002.api';
import dayjs from 'dayjs';

// actions
const FETCH_UMS_KKO_INFO = 'FDS0104V01/FETCH_UMS_KKO_INFO';
const SAVE_SEARCH_FORM_VALUES = 'FDS0104V01/SAVE_SEARCH_FORM_VALUES';
const RESET_STATE = 'FDS0104V01/RESET_STATE';

export const initFormValues = {
  // 검색 영역
  cus_ist_cd: 'all', //기관구분
  zpb_id: '', //사용자ID
  sn_id: 'all', //분야
  hp_no: '', //수신번호
  sdt: dayjs().format('YYYY-MM-DD'),
  edt: dayjs().format('YYYY-MM-DD'),
};

// initial state
const initialState = {
  loading: false,
  success: false,
  message: '',
  list: [],
  totalCount: 0,
  // 검색조건
  searchFormValues: {
    init: true,
    ...initFormValues,
  },
};

// reducers
export default function FDS0104V01(state = initialState, action) {
  switch (action.type) {
    case SUCCESS(FETCH_UMS_KKO_INFO): // 조회 성공
      return {
        ...state,
        success: true,
        loading: false,
        list: action.payload.data?.main,
        totalCount: action.payload?.data?.main_cnt,
      };
    case REQUEST(FETCH_UMS_KKO_INFO): // 조회 요청
      return {
        ...state,
        loading: true,
        success: false,
        message: '',
      };
    case FAILURE(FETCH_UMS_KKO_INFO): // 조회실패
      return {
        ...initialState,
        loading: false,
        success: false,
        message: action.payload.response?.data?.message,
      };
    case SAVE_SEARCH_FORM_VALUES:
      return {
        ...state,
        searchFormValues: action.payload,
      };
    case RESET_STATE:
      return initialState;
    default:
      return state;
  }
}

/**
 * 발송 내역 조회
 */
export const selectUmsKkoInfo = (searchParams) => {
  return {
    type: FETCH_UMS_KKO_INFO,
    payload: fetchUmsKkoInfo(searchParams),
  };
};

/**
 * 조회조건 저장
 */
export const saveSearchFormValues = (searchFormValues) => {
  return {
    type: SAVE_SEARCH_FORM_VALUES,
    payload: {
      ...searchFormValues,
      init: false,
    },
  };
};

/**
 * 상태초기화
 */
export const resetState = () => {
  return {
    type: RESET_STATE,
  };
};
