import instance from '../../common/config/api';

/**
 * 기타 - 자금이체 - 자금이체내역표
 * @param {object} params
 * @returns
 */
export const fetchEtcFdtsPrtsList = (params) =>
  instance.post(`${process.env.REACT_APP_SERVER_URL}/scq/scq/etc/0001/selectListFdtsPrts`, params);

/**
 * 기타 - 자금이체 - 자금이체내역표 (수정)
 * @param {array} dtoList
 * @returns
 */
export const updateEtcFdtsPrtsList = (dtoList) =>
  instance.post(`${process.env.REACT_APP_SERVER_URL}/scq/scq/etc/0001/updateListFdtsPrts`, {
    subDto: dtoList,
  });

/**
 * 기타 - 자금이체 - 자금이체내역표 (영수증 출력)
 * @param {object} params
 * @returns
 */
export const fetchEtcFdtsPrtsWbkList = (params) =>
  instance.post(`${process.env.REACT_APP_SERVER_URL}/scq/scq/etc/0001/selectListFdtsPrtsWbk`, params);
