import instance from '../../common/config/api';

/**
 * 법인카드 - 사회적 약자기업 구매 부서별 집계 조회
 * @param {object} params
 * @returns
 */
export const fetchListPurDeptSumList = (params) =>
  instance.post(`${process.env.REACT_APP_SERVER_URL}/scq/scq/cad/0005/selectListPurDeptSum`, params);

/**
 * 법인카드 - 사회적 약자기업 구매 약자별/사업자별 집계 조회
 * @param {object} params
 * @returns
 */
export const fetchListPurWeakDeptSumList = (params) =>
  instance.post(`${process.env.REACT_APP_SERVER_URL}/scq/scq/cad/0005/selectListPurWeakDeptSum`, params);

/**
 * 법인카드 - 사회적 약자기업 구매 상세내역 조회
 * @param {object} params
 * @returns
 */
export const fetchListPurDetailList = (params) =>
  instance.post(`${process.env.REACT_APP_SERVER_URL}/scq/scq/cad/0005/selectListPurList`, params);
